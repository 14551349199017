import { ReactElement, ReactNode } from 'react';
import ReactDOM from 'react-dom';

export const MenuPortal = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const menuRoot = document.getElementById('nav-menu') as HTMLElement;
  return ReactDOM.createPortal(children, menuRoot);
};
