import { ProductReferences } from 'common/constants/enums';

import { VisualState } from '../types';

export const getVisualStateFromNotifState = (
  alreadyAskedOSNotif: boolean, // negative of permission enabled
  isOSNotifTurnedOn: boolean, // permission enabled notifee
  isAppNotifTurnedOn: boolean,
  productReference: string
): VisualState => {
  const visualStateKey = `${alreadyAskedOSNotif}-${isOSNotifTurnedOn}-${isAppNotifTurnedOn}`;

  if (productReference === ProductReferences.SLEEPIO) {
    return getVisualStateForSleepio(visualStateKey);
  }

  return getVisualStateForDaylight(visualStateKey);
};

// Get visual state for Sleepio
// The updated multi-prompt local notification flow differs from Daylight and has differing scenarios for visual states.
const getVisualStateForSleepio = (visualStateKey: string) => {
  switch (visualStateKey) {
    case 'false-false-true': // permissions not requested, device does not authorize, notification config exists
    case 'false-false-false':
    case 'false-true-false':
    case 'false-true-true':
      return VisualState.A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT;
    case 'true-true-false':
    case 'true-true-true':
      return VisualState.C__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF;
    case 'true-false-true':
    case 'true-false-false':
      return VisualState.B__SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT;
    default:
      return VisualState.E__EXCEPTION;
  }
};

// Get visual state for Daylight
const getVisualStateForDaylight = (visualStateKey: string) => {
  switch (visualStateKey) {
    case 'false-false-true': // permissions not requested, device does not authorize, notification config exists
    case 'false-false-false':
      return VisualState.A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT;
    case 'true-false-true':
    case 'true-false-false':
      return VisualState.B__SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT;
    case 'false-true-false':
    case 'true-true-false':
      return VisualState.C__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF;
    case 'false-true-true':
    case 'true-true-true':
      return VisualState.D__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON;
    default:
      return VisualState.E__EXCEPTION;
  }
};
