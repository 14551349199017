import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/native';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { usePostponeSleepDiaryNotifications } from 'components/LocalNotifications/NotificationsView/hooks/usePostponeNotifications';
import { useProgressReviewRedirect } from 'components/ProgressReview/hooks/useProgressReviewRedirect';
import { roles } from 'cross-platform/utils/roleProps';
import { WithState } from 'sleepio/components/screens/SessionsScreen/components/WithState';

import { Loading } from './components/Loading';
import { useSessionsDataQuery } from './hooks/useQuerySessionsData';

const Wrapper = styled.View`
  flex-grow: 1;
`;
/**
 * SessionScreen: Handles all network logic
 */
const WithNetworkAndState = (): React.ReactElement => {
  const { isLoading, data } = useSessionsDataQuery();
  const isTodaysSessionDone = useMemo(() => {
    return (
      (data?.result as get_user_session_screen_data.ResultStandard)
        ?.sleep_diary_data?.[0]?.type === 'efficiency'
    );
  }, [data?.result]);
  const { postponeDailyNotifications } = usePostponeSleepDiaryNotifications();

  useProgressReviewRedirect(data?.result);

  useEffect(() => {
    if (isTodaysSessionDone) {
      postponeDailyNotifications();
    }
  }, [isTodaysSessionDone, postponeDailyNotifications]);

  return (
    <Wrapper>
      {isLoading ? (
        <Loading {...roles('Loading')} />
      ) : typeof data?.result !== 'undefined' ? (
        <WithState {...roles('Data')} data={data?.result} />
      ) : null}
    </Wrapper>
  );
};

export { WithNetworkAndState };
