import { Query } from 'react-query';

enum ThrobberBehavior {
  ALWAYS_HIDDEN = `ThrobberBehavior.ALWAYS_HIDDEN`,
  ALWAYS_SHOWN = `ThrobberBehavior.ALWAYS_SHOWN`,
}
type ThrobberBehaviorMeta = { throbberBehavior?: ThrobberBehavior };
/**
 * @see {@link ./README.md} for more info
 */
const checkThrobberBehavior = (query: Query): boolean | undefined => {
  const meta: ThrobberBehaviorMeta | undefined = query?.meta;
  if (typeof meta?.throbberBehavior !== 'undefined') {
    if (meta.throbberBehavior === ThrobberBehavior.ALWAYS_HIDDEN) {
      return false;
    } else if (meta.throbberBehavior === ThrobberBehavior.ALWAYS_SHOWN) {
      return true;
    }
  }
  return undefined;
};

export { checkThrobberBehavior, ThrobberBehavior, ThrobberBehaviorMeta };
