export enum SleepDairyModalActionTypes {
  SET_VISIBLE = 'sleepDiaryModal/SET_VISIBLE',
}

export type SleepDiaryModalAction = {
  type: SleepDairyModalActionTypes;
  payload: boolean;
};

export const setVisible = (isVisible: boolean): SleepDiaryModalAction => ({
  type: SleepDairyModalActionTypes.SET_VISIBLE,
  payload: isVisible,
});
