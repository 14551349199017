import styled from 'styled-components/native';

import { Text } from 'components/sceneset-components/Text';
import Image from 'cross-platform/Image';
import {
  scaler,
  ScaleValueProps,
  ScaleYValueProps,
  yScalerWithOptions,
} from 'sleepio/components/screens/SessionsScreen/utils/scaler';

const DOMAIN = 'DiaryEfficiency';

export const EfficiencyHeadingText = styled(Text).attrs({
  // See getTextAlignmentConfig
  alignment: { horizontal: 'middle' },
})<HeadingTextProps>`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.theme.color.white};
`;
EfficiencyHeadingText.displayName = `${DOMAIN}.EfficiencyHeadingText`;

export const ImageContainer = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  align-self: flex-end;
`;
ImageContainer.displayName = `${DOMAIN}.ImageContainer`;

export const scalerVertical = yScalerWithOptions({ minScale: 0.6 });
export type HeadingTextProps = React.ComponentProps<typeof Text> &
  ScaleValueProps &
  ScaleYValueProps;

export const HeadingText = styled(Text)<
  HeadingTextProps & { isEfficiencyValue: boolean }
>`
  margin-top: 16px;
  font-size: 22px;
  max-width: ${scaler(720)}px;
  margin-left: 10px;
  font-weight: ${props => (props.isEfficiencyValue ? 'bold' : '400')};
  color: ${props => props.theme.color.white};
`;
HeadingText.displayName = `${DOMAIN}.HeadingText`;
