import React from 'react';

import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { mockRes17Sep2020 } from 'components/SleepDiaryForm/__mocks__/17Sep2020';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

import { Form } from '..';

const payload = mockRes17Sep2020.result;

const Story = () => {
  const sceneSets = useFakeStorybookSceneSet();
  if (!sceneSets) {
    return null;
  }
  const fieldProps = { highlight: true };
  return (
    <Form
      fieldProps={{
        sleep_efficiency_time_into_bed: fieldProps,
        sleep_efficiency_time_try_to_sleep: fieldProps,
        sleep_efficiency_to_fall_asleep_total_time: fieldProps,
        sleep_efficiency_awakenings_in_night: fieldProps,
        sleep_efficiency_awakenings_total_time: fieldProps,
        sleep_efficiency_time_final_awakening: fieldProps,
        sleep_efficiency_time_get_out_of_bed: fieldProps,
        sleep_efficiency_sleep_quality: fieldProps,
      }}
      {...payload}
    />
  );
};

addStory('sleep-diary/Form highlight', () => (
  <EphemeralStateProvider>
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <Story />
    </CrossPlatformThemeProvider>
  </EphemeralStateProvider>
));
