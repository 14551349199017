import React, { ReactElement, useCallback } from 'react';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

import { SceneSetGraph } from '@bighealth/types';

import { useSafeParams } from 'components/Routes/useSafeParams';
import {
  ButtonColorSchemes,
  UniversalButton,
} from 'components/UniveralButtons';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import {
  queryClientTransitionSceneSet,
  useQueryProduct,
  useQueryProgram,
} from 'lib/api/reactQueryHelpers';
import getPathForParams from 'lib/player/getPathForParams';
import * as reporter from 'lib/reporter';

import { MenuProps } from '../index';

const Container = styled.View`
  height: 300px;
  width: 100%;
  max-width: 480px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ListMenuItem = ({
  sceneSetGraph,
}: {
  sceneSetGraph: SceneSetGraph;
}): ReactElement => {
  const history = useHistory();
  const { productReference } = useSafeParams();
  const sceneSetGraphId = sceneSetGraph.id;
  const programId = useQueryProgram()?.data?.result.id;
  const productId = useQueryProduct()?.data?.result.id;

  const handleFetch = useCallback(async (): Promise<void> => {
    // No try/catch: the hook handles it
    const sceneSet = (
      await queryClientTransitionSceneSet({
        current_graph_id: sceneSetGraphId,
        current_product_id: productId as number,
        current_program_id: programId as number,
        current_scene_set_id: undefined,
        enable_inter_graph_transitions: true,
      })
    )?.result;
    if (sceneSet) {
      const sceneSetId = sceneSet.id;
      history.push(
        getPathForParams({
          productReference,
          sceneSetGraphId,
          sceneSetId,
        })
      );
    } else {
      const message = 'Expected SceneSet from API but got none';
      reporter.log(message, TypeError(message));
    }
  }, [history, productId, productReference, programId, sceneSetGraphId]);
  return (
    <UniversalButton
      {...roles(`ListMenuItem-${sceneSetGraph.name}`)}
      colorScheme={ButtonColorSchemes.Primary}
      style={{ marginTop: 10 }}
      text={sceneSetGraph.name}
      onPress={handleFetch}
    />
  );
};

const StyledScrollView = styled(ScrollView)``;

const ListMenu = ({ items }: MenuProps): ReactElement => {
  return (
    <Container>
      <StyledScrollView horizontal={false}>
        {items.map(({ sceneSetGraph }) => (
          <ListMenuItem
            {...roles('ListItem')}
            sceneSetGraph={sceneSetGraph}
            key={sceneSetGraph.id}
          />
        ))}
      </StyledScrollView>
    </Container>
  );
};

export default ListMenu;
