import {
  Media,
  ViewStylesObject,
} from '@bighealth/types/src/scene-components/client';

const isNumber = (value: any): value is number => {
  return typeof value === 'number';
};

export const getStylesFromProps = (props: Media): ViewStylesObject => {
  const { sourceProps, style } = props;
  let height: number;
  let width: number;
  const styleHeight = style?.height;
  const styleWidth = style?.width;
  const aspectRatio = sourceProps.width / sourceProps.height;
  if (isNumber(styleHeight)) {
    height = styleHeight;
    if (isNumber(styleWidth)) {
      width = styleWidth;
    } else {
      width = styleHeight * aspectRatio;
    }
  } else if (isNumber(styleWidth)) {
    width = styleWidth;
    height = styleWidth / aspectRatio;
  } else {
    height = sourceProps?.height;
    width = sourceProps?.width;
  }

  return {
    ...style,
    height,
    width,
  };
};
