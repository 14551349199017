import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { Scene } from '@bighealth/types/dist/scene-component';
import { SceneAction } from '@bighealth/types/dist/scene-components/client';
import { StyleObject } from '@bighealth/types/src/scene-components/client';

import { PressHandler } from 'components/Button';
import { Text } from 'components/sceneset-components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import useActionHandler from 'lib/player/useActionHandler';

type ButtonProps = {
  action?: SceneAction;
  style?: ViewStyle;
  isDisabled?: boolean;
  onPress?: PressHandler;
};

type TextProps = {
  text: string;
  alignment?: Scene.Presentational.Alignment;
  color: string;
  fontSize: number;
  fontWeight?: StyleObject['fontWeight'];
  lineHeight?: number;
  icon?: ReactNode;
};

// We could not use the UniversalActionButton because we can't customise the styles
// TODO: use the UniversalActionButton
// WHEN: we move the button in the shared package and we can customize the styles
export const ActionButton = (props: ButtonProps & TextProps): ReactElement => {
  const actionHandler = useActionHandler(props.action);
  const handlePress = async (): Promise<void> => {
    if (typeof actionHandler === 'function') {
      await actionHandler();
    }
  };
  const flatProps = Array.isArray(props.style)
    ? StyleSheet.flatten(props.style)
    : props.style;

  return (
    <View>
      <TouchableOpacity
        {...roles('ActionButton')}
        style={{
          ...(flatProps as Record<string, unknown>),
          borderStyle: 'solid',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        onPress={handlePress}
      >
        {props.icon && props.icon}
        <Text
          style={{
            color: props.color,
            fontSize: props.fontSize,
            lineHeight: props.lineHeight,
            fontWeight: props.fontWeight,
          }}
          text={props.text}
        />
      </TouchableOpacity>
    </View>
  );
};
