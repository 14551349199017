import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';

import { getDisplayText } from './getDisplayText';

/**
 * Takes the selected value and creates a DropdownItem based on it.
 * @param selectedValue - the selected value of the picker.
 */
const valueToDropdownItem = (
  selectedValue?: Value
): DropdownItem[] | undefined => {
  if (typeof selectedValue === 'undefined') {
    return undefined;
  }
  const displayText =
    selectedValue instanceof Date
      ? getDisplayText(selectedValue)
      : `${selectedValue}`;

  const value =
    selectedValue instanceof Date ? `${selectedValue}` : selectedValue;
  return [
    {
      value,
      displayText,
      isSelected: true,
    },
  ];
};

export { valueToDropdownItem };
