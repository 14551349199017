import { AuthorizationStatus } from '@notifee/react-native';

export const getStringFromAuthCode = (status?: number | null): string =>
  status === AuthorizationStatus.NOT_DETERMINED
    ? 'NOT_DETERMINED'
    : status === AuthorizationStatus.DENIED
    ? 'DENIED'
    : status === AuthorizationStatus.AUTHORIZED
    ? 'AUTHORIZED'
    : status === AuthorizationStatus.PROVISIONAL
    ? 'PROVISIONAL'
    : 'UNKNOWN';
