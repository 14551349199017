import React, { ReactElement, useContext } from 'react';

import { NotificationCenter } from 'components/notifications/NotificationCenter';
import {
  ScalingContext,
  ScalingContextProvider,
  ScreenResponsiveLayoutContext,
} from 'components/ResponsiveLayout';

import { useNavigationBarContext } from './providers/NavigationBarProvider';
import { CollapsedNav } from './CollapsedNav';
import { NavBarStyles } from './constants';
import { ExpandedNav } from './ExpandedNav';

export const NavigationWithProvider = (): ReactElement => {
  const { isCollapsed } = useNavigationBarContext();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);

  // NOTE: we are moving to a responsive layout not dependant device detection for navigation
  if (isCollapsed(screenWidth)) {
    return (
      <>
        <CollapsedNav />
        <NotificationCenter />
      </>
    );
  }

  return (
    <>
      <ExpandedNav />
      <NotificationCenter style={{ top: NavBarStyles.navBarHeightPx }} />
    </>
  );
};

export const GlobalNavigationBar = (): ReactElement => {
  return (
    <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
      <NavigationWithProvider />
    </ScalingContextProvider>
  );
};
