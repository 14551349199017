import { APIResponse } from '@bighealth/api';
import { DeepWritable } from '@bighealth/types';

import { transformToJavaScriptDate } from '../transformToJavaScriptDate';

/**
 * Transforms payloads as they are received by the client app
 *
 * Usually client code is expected to treat response payloads as read-only.
 *
 * But this special "middleware" code mimics backend logic
 * @see {@link https://expressjs.com/en/guide/using-middleware.html}
 *
 * It thus has a higher "permission" than normal client-app code,
 * which is why we use DeepWritable to allow changes to the payloads.
 */
const transformResponseResult = (response: APIResponse): APIResponse => {
  const responseWritable = response as DeepWritable<APIResponse>;
  if (responseWritable.result === null) {
    return responseWritable as APIResponse;
  }
  responseWritable.result = transformToJavaScriptDate<
    DeepWritable<APIResponse>['result']
  >(responseWritable.result);
  return responseWritable as APIResponse;
};

export { transformResponseResult };
