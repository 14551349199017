import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_next_session_available_datetime = async (
  args: get_next_session_available_datetime.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_next_session_available_datetime.Response> => {
  const request: get_next_session_available_datetime.Request = {
    service_name: 'SessionProgress',
    service_version: '1',
    service_method: 'get_next_session_available_datetime',
    args,
  };

  return await api(request, requestConfig);
};

get_next_session_available_datetime.queryKey =
  'SessionProgress/get_next_session_available_datetime';

export declare namespace get_next_session_available_datetime {
  export type Args = {
    program_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SessionProgress';
    service_version: '1';
    service_method: 'get_next_session_available_datetime';
    args: Args;
  }

  export type Result = { $datetime: string };

  export type Response = APIResponse<Result>;
}
