import React, { ComponentProps, ReactElement } from 'react';
import { useWindowDimensions, View, ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import { AVErrorCallback, AVPlayer } from '@bighealth/avplayer';

import { ProductReferences, ProgramIds } from 'common/constants/enums';
import { isAndroid } from 'common/utils/utils';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { CC_ENABLED, CCMENU_ENABLED } from 'config/envVars/feature-flags';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';
import { useMediaNodeOrchestrator } from 'lib/player/media/MediaPlayerOrchestrator';
import * as Reporter from 'lib/reporter';
import {
  getLocalCaptions,
  getLocalURIForRemoteURL,
} from 'state/downloader/selectors';
import { CaptionState } from 'state/player/reducer';
import { getCaptionLanguage, getCaptionState } from 'state/player/selectors';

import { ComponentMediaProps } from '../types';

import { getIsSeamless } from './helpers/getIsSeamless';
import { setPlayerKey } from './helpers/setPlayerKey';

export const CommonPlayer = ({
  src,
  captionSrc,
  captions,
  from,
  delay,
  to,
  action,
  isContinuous,
  style,
  seamless,
}: ComponentMediaProps & {
  style?: ViewStyle;
}): ReactElement => {
  const node = useMediaNodeOrchestrator(action);
  const localSrc = useSelector(getLocalURIForRemoteURL(src));
  const localCaptionSrc = useSelector(getLocalURIForRemoteURL(captionSrc));
  const captionState = useSelector(getCaptionState);
  const captionLanguage = useSelector(getCaptionLanguage);
  const programId = useQueryProgram()?.data?.result?.id;
  const localCaptions = useSelector(getLocalCaptions(captions));
  const { productReference } = useSafeParams();
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const insets = useSafeArea();

  const playerCaptions =
    localCaptions && localCaptions.length > 0 && CCMENU_ENABLED
      ? localCaptions
      : localCaptionSrc
      ? [
          {
            lang_label: 'English',
            lang_tag: 'en',
            storage_url: `${localCaptionSrc}`,
          },
        ]
      : [];
  const playerCaptionLanguage =
    playerCaptions.findIndex(
      c => c.lang_tag.toLowerCase() === captionLanguage.toLowerCase()
    ) !== -1
      ? captionLanguage
      : 'en';

  // We do not want closed captions to show on clinical research programs or pivotal clinical research programs.
  // TODO: Add a VWO feature flag
  const captionsEnabled =
    CC_ENABLED &&
    captionState === CaptionState.ON &&
    programId !== ProgramIds.DAYLIGHT_CLINICAL_RESEARCH_PROGRAM &&
    programId !== ProgramIds.DAYLIGHT_PIVOTAL_CLINICAL_RESEARCH_PROGRAM;

  // Try to capture video playback errors where possible.
  const onError = (err: AVErrorCallback) => {
    Reporter.log('Video Player Error:', err, { silent: true });
  };

  // SLEEPIO-4267 - We want a KEY for NATIVE but not for Web
  // Otherwise we get NotAllowedError in browswer.
  const key = setPlayerKey(src);

  const isSleepioProductReference =
    productReference === ProductReferences.SLEEPIO;

  // Added more padding on small devices in Daylight
  const getPadding = () => {
    if (screenWidth < 360 && !isSleepioProductReference) {
      return 100;
    }
    if (screenWidth < 480 && !isSleepioProductReference) {
      return 80;
    }
    return 16; // Default Value
  };

  // Note:  This will only affect the CCs on Android
  const getPaddingTop = () => {
    if (isAndroid() && !isSleepioProductReference) {
      let multiplier;
      if (screenHeight <= 682) {
        multiplier = insets.bottom < 15 ? 11 : 13;
      } else if (screenHeight <= 725) {
        multiplier = insets.bottom < 15 ? 10 : 12;
      } else {
        multiplier = insets.bottom < 25 ? 5 : 7;
      }
      return insets.top * multiplier;
    }
    return 0;
  };

  return (
    <View pointerEvents={'none'}>
      <AVPlayer
        style={style as ComponentProps<typeof AVPlayer>['style']}
        action={action}
        testID={`AVPlayer-${src}`}
        from={from}
        to={to}
        delay={delay}
        key={key}
        src={localSrc as string}
        ref={node}
        isContinuous={isContinuous}
        captions={playerCaptions}
        captionLanguage={playerCaptionLanguage}
        seamless={getIsSeamless(to, from, { seamless })}
        captionsEnabled={captionsEnabled}
        onError={onError}
        subtitleStyle={{
          fontSize: 18,
          paddingTop: getPaddingTop(),
          paddingLeft: getPadding(),
          paddingRight: getPadding(),
        }}
      />
    </View>
  );
};
