import React, { useMemo } from 'react';
import { LayoutRectangle } from 'react-native';
import { splitEvery } from 'ramda';

import { qaConfigFlags } from 'lib/showQAMenu/qaConfigFlags';
import { throwIfIsNumber } from 'lib/type-guarded';

import { byTime } from '../../../lib/fp/predicateFactory/byTime';
import { DropdownTimePickerProps } from '../DropdownTimePicker';
import { DEFAULT_TIME_INTERVAL } from '../DropdownTimePicker/constants';
import { createValueChangeHandler } from '../DropdownTimePicker/helpers/createValueChangeHandler';
import { filterInvalidOptions } from '../DropdownTimePicker/helpers/filterInvalidOptions';
import { getOutOfBoundsOptionsIfNeeded } from '../DropdownTimePicker/helpers/getOutOfBoundsOptionsIfNeeded';
import { deriveTimeResponseOptions } from '../DropdownTimePicker/helpers/getResponseOptions';
import { getDisplayText } from '../DropdownTimePicker/helpers/valueToDropdownItem/getDisplayText';
import { FIXED_WIDTH } from '../SelectHorizontal/constants';

import { usePagination } from './hooks/usePagination';
import { getDataSlices } from './utils/getDataSlices';
import { transformInitialVisibleValue } from './utils/transformInitialVisibleValue';
import {
  ColNavButton,
  ColOptions,
  NavButton,
  Option,
  RowOption,
  Spacer,
  Wrapper,
} from './styled';

type SliceComponent = (props: SelectHorizontalTimeProps) => React.ReactElement;

export type SelectHorizontalTimeProps = DropdownTimePickerProps & {
  initialVisibleValue?: Date;
  start?: SliceComponent;
  end?: SliceComponent;
  isDropdownOverflow?: boolean;
  scrollToTarget?: (layoutRectangle: LayoutRectangle) => void;
  rows?: number;
};

/**
 *
 * @returns
 */
const SelectHorizontalTime = ({
  min,
  max,
  selectedValue,
  onValueChange,
  highlight,
  state,
  initialVisibleValue: initialVisibleValueOriginal,
  interval = DEFAULT_TIME_INTERVAL,
  // isDropdownOverflow,
  scrollToTarget,
  rows = 2,
}: SelectHorizontalTimeProps): React.ReactElement => {
  const { date } = state || {};
  const derivedOptions = useMemo(
    () =>
      filterInvalidOptions(
        deriveTimeResponseOptions({
          min,
          max,
          interval,
        }),
        date
      ),
    [date, interval, max, min]
  );
  const outOfBoundsOptions = getOutOfBoundsOptionsIfNeeded(
    derivedOptions,
    derivedOptions.find(byTime(selectedValue)) || selectedValue
  );
  const all = [...derivedOptions, ...outOfBoundsOptions].sort(
    (a, b) => +a - +b
  );
  const initialVisibleValueOriginalInAll =
    all.find(byTime(initialVisibleValueOriginal)) ||
    initialVisibleValueOriginal;
  const selectedDate = all.find(byTime(selectedValue));
  const initialVisibleValue = transformInitialVisibleValue({
    initialVisibleValueOriginal: initialVisibleValueOriginalInAll,
    selectedDate,
    all,
  });
  const pages = usePagination({
    isPaginated: scrollToTarget === undefined,
    rows,
    all,
    initialVisibleValue,
    triggerCursorUpdateHash: JSON.stringify({ date, interval, min, max }),
  });

  /*

    ┌───┐ ┌───┐ ┌───┐ ┌───┐   ┌───┐ ┌───┐ ┌───┐ ┌───┐   ┌───┐ ┌───┐ ┌───┐ ┌───┐
all=|   | |   | |   | |   |   |   | |   | |   | |   |   |   | |   | |   | |   | ...
    └───┘ └───┘ └───┘ └───┘   └───┘ └───┘ └───┘ └───┘   └───┘ └───┘ └───┘ └───┘ 


       BEFORE VISIBLE       │         VISIBLE         │      AFTER VISIBLE
          Slices[0]         |        Slices[1]        |        Slice[2]
                            │                         │
     = all.slice(0, cursor) │= all.slice(cursor, page)│   = all.slice(cursor + page)
                            │                         │
                            │                         │
                            │                         │
 */
  const slices = getDataSlices({
    selectedValue,
    cursorValue: pages.cursor.value,
    visibleCount: pages.visibleCount,
    all,
  });
  const rowLength = Math.ceil(pages.visibleCount / rows);
  const onChange = createValueChangeHandler(onValueChange);
  return (
    <Wrapper>
      {/*
             
         BEFORE VISIBLE 
    ┌───┐ ┌───┐ ┌───┐ ┌───┐ │                         │
... |   | |   | |   | |   | |
    └───┘ └───┘ └───┘ └───┘ │                         │
                            │                         │
           Slices[0]
 */}
      <ColNavButton>
        <NavButton
          scaleX={1}
          onPress={pages.cursor.setPageBack}
          disabled={slices.optionSlices[0].length == 0}
          rows={rows}
        />
      </ColNavButton>
      {/*
                            │        VISIBLE          │
                            │ ┌───┐ ┌───┐ ┌───┐ ┌───┐ │
                            | |   | |   | |   | |   | |
                            │ └───┘ └───┘ └───┘ └───┘ │
                            │                         │
                                   Slices[1]
 */}
      <ColOptions>
        {splitEvery(rowLength, slices.optionSlices[1]).map(
          (optionRow, rowIndex) => (
            <RowOption
              key={rowIndex}
              onLayout={rowIndex === 0 ? pages.measure.page : undefined}
              isFirst={rowIndex === 0}
            >
              {optionRow.map((date, optionIndex) => (
                <Option
                  key={`${date}/${optionIndex}`}
                  value={`${date}`}
                  onSelect={value => onChange(throwIfIsNumber(value, 'value'))}
                  style={{
                    flexBasis: qaConfigFlags.getValue('SD: Time on 2 lines')
                      ? FIXED_WIDTH * 0.85
                      : FIXED_WIDTH,
                  }}
                  displayText={
                    qaConfigFlags.getValue('SD: Time on 2 lines')
                      ? getDisplayText(date)
                          ?.split(' ')
                          .join('\n')
                      : getDisplayText(date)
                  }
                  selected={selectedDate === date}
                  highlight={highlight && selectedDate === date}
                  onLayout={optionIndex === 0 ? pages.measure.item : undefined}
                  isFirst={optionIndex === 0}
                  isLast={optionIndex === rowLength - 1}
                  isFixed
                />
              ))}
              {Array.from(Array(rowLength - optionRow.length)).map((_el, i) => (
                <Spacer key={i} />
              ))}
            </RowOption>
          )
        )}
      </ColOptions>
      <ColNavButton>
        {/*
                            │                         │      AFTER VISIBLE
                            │                         │ ┌───┐ ┌───┐ ┌───┐ ┌───┐
                            │                         │ |   | |   | |   | |   | ...
                            │                         │ └───┘ └───┘ └───┘ └───┘
                                                               Slices[2]
                  */}
        <NavButton
          scaleX={-1}
          onPress={pages.cursor.setPageForward}
          disabled={slices.optionSlices[2].length == 0}
          rows={rows}
        />
      </ColNavButton>
    </Wrapper>
  );
};

export { SelectHorizontalTime };
