import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_resources_screen_data } from '@bighealth/api/SleepioResources/v1';

export type useQueryGetResourcesScreenDataReturn = UseQueryResult<
  get_resources_screen_data.Response,
  APIErrorResponse
>;

export const useQueryGetResourcesScreenData = (): useQueryGetResourcesScreenDataReturn => {
  const result = useQuery<get_resources_screen_data.Response, APIErrorResponse>(
    {
      queryKey: get_resources_screen_data.queryKey,
      queryFn: () => get_resources_screen_data(),
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
    }
  );
  return result;
};
