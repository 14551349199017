import React, { ReactElement, ReactNode } from 'react';

export enum SceneComponentContextType {
  Scene = 'SCENE',
  MediaContainer = 'MEDIA_CONTAINER',
  Modal = 'MODAL',
}

const defaultSceneComponentContext = SceneComponentContextType.Scene;

export const SceneComponentContext = React.createContext<
  SceneComponentContextType
>(defaultSceneComponentContext);

export const SceneComponentContextProvider = ({
  sceneComponentContext: drivenComponentContext,
  children,
}: {
  sceneComponentContext?: SceneComponentContextType;
  children: ReactNode;
}): ReactElement => {
  const scalingContext = drivenComponentContext || defaultSceneComponentContext;
  return (
    <SceneComponentContext.Provider value={scalingContext}>
      {children}
    </SceneComponentContext.Provider>
  );
};
