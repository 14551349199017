type RequestBody =
  | {
      service_name: string;
      service_version: string;
      service_method: string;
      args?: string;
    }
  | Record<string, unknown>;

const tryGetCleanedRequestObjFromString = (
  requestStr?: string | Array<unknown>
): RequestBody | undefined => {
  let obj: Record<string, unknown> = {};
  if (typeof requestStr === 'string') {
    try {
      obj = JSON.parse(requestStr);
    } catch (error) {
      // Note: Do not fail hard; Some logging is better than none
      console.error(`Could not JSON.parse "${requestStr}"`);
    }
  }
  if (
    typeof obj?.service_name === 'undefined' ||
    typeof obj?.service_version === 'undefined' ||
    typeof obj?.service_method === 'undefined'
  ) {
    return undefined;
  }
  // TODO Sentry breadcrumbs add args
  // https://bighealth.atlassian.net/browse/FEG-108
  if (typeof obj?.args !== 'undefined') {
    obj.args = '<removed in client as may be PII>';
  }
  return obj;
};

export default tryGetCleanedRequestObjFromString;
