import React, { ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components/native';

import { roles } from 'cross-platform/utils/roleProps';
import { Settings } from 'daylight/components/screens/TodayScreen/components/Settings';

import { CollapsedNav } from './CollapsedNav';

const Wrapper = styled.View``;

const ContainerSettings = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export type SettingsIconType =
  | {
      size: number;
      color: string;
    }
  | undefined;

export const WithSettings = ({
  settingsIcon,
  children,
  openSettingsNav,
}: {
  settingsIcon: SettingsIconType;
  children: ReactNode;
  openSettingsNav?: boolean;
}): ReactElement => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(openSettingsNav);

  const onSettingsPress = async (): Promise<void> => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  return (
    <Wrapper
      {...roles('GlobalDaylightSettings')}
      style={{
        flex: 1,
      }}
    >
      {typeof settingsIcon !== 'undefined' ? (
        <ContainerSettings style={{}}>
          {children}
          {isSettingsOpen ? (
            <CollapsedNav setSettingsScreen={onSettingsPress} />
          ) : (
            <Settings
              size={settingsIcon.size}
              color={settingsIcon.color}
              action={onSettingsPress}
            />
          )}
        </ContainerSettings>
      ) : (
        children
      )}
    </Wrapper>
  );
};
