import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

export const WarningIcon = ({ size }: { size: number }): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      <G>
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M 10.0001 0.00836182 C 9.60569 0.00836182 9.21895 0.117202 8.88245 0.322896 C 8.54596 0.52859 8.27275 0.823157 8.09293 1.17416 L 8.09289 1.17415 L 8.08976 1.18041 L 0.233625 16.8927 L 0.233048 16.8938 C 0.0690632 17.2197 -0.00899769 17.5821 0.00627238 17.9467 C 0.0215603 18.3117 0.12989 18.6667 0.320973 18.978 C 0.512058 19.2893 0.779552 19.5466 1.09805 19.7254 C 1.41655 19.9043 1.77549 19.9987 2.14076 19.9998 H 2.14293 H 17.8572 H 17.8593 C 18.2246 19.9987 18.5836 19.9043 18.902 19.7254 C 19.2206 19.5466 19.488 19.2893 19.6792 18.978 C 19.8703 18.6667 19.9786 18.3117 19.9939 17.9467 C 20.0092 17.5821 19.931 17.2196 19.767 16.8937 L 19.7665 16.8927 L 11.9104 1.18041 L 11.9104 1.18039 L 11.9072 1.17416 C 11.7274 0.823157 11.4542 0.52859 11.1177 0.322896 C 10.7812 0.117202 10.3945 0.00836182 10.0001 0.00836182 Z M 10 6.25139 C 10.4931 6.25139 10.8929 6.65113 10.8929 7.14425 V 11.43 C 10.8929 11.9231 10.4931 12.3228 10 12.3228 C 9.50692 12.3228 9.10718 11.9231 9.10718 11.43 V 7.14425 C 9.10718 6.65113 9.50692 6.25139 10 6.25139 Z M 10 17.1443 C 10.789 17.1443 11.4286 16.5047 11.4286 15.7157 C 11.4286 14.9267 10.789 14.2871 10 14.2871 C 9.21106 14.2871 8.57146 14.9267 8.57146 15.7157 C 8.57146 16.5047 9.21106 17.1443 10 17.1443 Z"
          fill="#F8821A"
        />
      </G>
    </Svg>
  );
};
