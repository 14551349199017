import React, { ReactElement, useContext } from 'react';
import { View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { Scene } from '@bighealth/types/src/scene-component';

import { Text } from 'components/sceneset-components/Text';
import { ProgressBadge } from 'daylight/components/icons/ProgressBadge';
import { useTransformContentStyle } from 'daylight/hooks';

import { ProgressCard } from '../ProgressCard/ProgressCard';

export const GoalView = ({
  upperText,
  lowerText,
  boldText,
}: {
  upperText?: Scene.Components.Text;
  lowerText?: Scene.Components.Text;
  boldText?: Scene.Components.Text;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyle = useTransformContentStyle();

  return (
    <ProgressCard
      upperComponent={
        <View
          style={{
            flexDirection: 'row',
            marginBottom: transformStyle(16),
          }}
        >
          <ProgressBadge
            width={transformStyle(32)}
            height={transformStyle(42)}
            color={theme.color.text.primary}
          />
          <Text
            {...upperText}
            style={{
              ...upperText?.style,
              paddingTop: transformStyle(10),
              marginLeft: transformStyle(21),
              fontSize: transformStyle(18),
              color: theme.color.text.primary,
            }}
          />
        </View>
      }
      lowerText={lowerText}
      boldText={boldText}
    />
  );
};
