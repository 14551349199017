import { useGetContentDimensions } from './useGetContentDimensions';
import { useGetNavDimensions } from './useGetNavDimensions';

export const useGetPageDimensions = (): {
  pageContentWidth: number;
} => {
  const { navWidth, marginRight } = useGetNavDimensions();
  const { contentWidth } = useGetContentDimensions();
  // This is a bit elaborate but we need to match the left and right of the nav bar
  // content to that of the page content
  const pageContentWidth = navWidth + marginRight + contentWidth;
  return {
    pageContentWidth,
  };
};
