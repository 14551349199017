/* eslint-disable camelcase */
import { APIRequestBody, APIResponse } from '@bighealth/api';
import { DeepWritable } from '@bighealth/types';

import { ResponsePipe } from '../../index';

import { transformResponseResult } from './utils/transformResponseResult';

/**
 * Use to transform certain questions in SleepDiary.get_diary_entry_form,
 * so they use ms instead of minutes
 *
 */
const fromMinutesToMilliseconds = (
  readOnlyBody: APIRequestBody
): ResponsePipe => {
  const body: DeepWritable<APIRequestBody> = readOnlyBody;
  switch (body?.service_name) {
    case 'SleepDiary':
      switch (body?.service_method) {
        case 'get_diary_entry_form':
          return transformResponseResult;
      }
      break;
  }
  return (response: APIResponse): APIResponse => response;
};

export { fromMinutesToMilliseconds };
