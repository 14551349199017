import React, { ReactElement } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { roles } from 'cross-platform/utils/roleProps';

import { CloseButton, Container, CrossStyled, Title } from './styled';

export type HeaderProps = {
  text?: string;
  onClose: () => void;
};

export const Header = ({ text, onClose }: HeaderProps): ReactElement => {
  const safeArea = useSafeArea();

  return (
    <Container safeArea={safeArea}>
      {typeof text === 'string' ? (
        <Title {...roles('HeaderTitle')} text={text} />
      ) : null}
      <CloseButton
        {...roles('CloseButton')}
        onPress={onClose}
        safeArea={safeArea}
        accessibilityLabel="Leave page"
      >
        <CrossStyled size={30} />
      </CloseButton>
    </Container>
  );
};
