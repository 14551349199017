import React, { ReactElement } from 'react';
import { LogBox } from 'react-native';

import { AppContainer } from 'components/Containers';
import ProvidersContainer from 'components/ProvidersContainer';
import Routes from 'components/Routes';
import { DeepLinking } from 'cross-platform/react-router/Router/utils/DeepLinking';
import { SetupNativeAVEvents } from 'lib/player/media/nativeAVEvents';

import { useInit } from './utils/useInit/useInit';

// https://github.com/facebook/react-native/issues/12981#issuecomment-652745831
LogBox.ignoreLogs(['Setting a timer']);

export const CoreApp = (): ReactElement => {
  return (
    <AppContainer>
      <DeepLinking />
      <Routes />
      <SetupNativeAVEvents />
    </AppContainer>
  );
};
export const App = (): ReactElement => {
  useInit();
  return (
    <ProvidersContainer>
      <CoreApp />
    </ProvidersContainer>
  );
};
