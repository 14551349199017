import React from 'react';

import * as roleProps from 'cross-platform/utils/roleProps';
import { ErrorBoundary } from 'lib/error/ErrorBoundary/ErrorBoundary';
import { addStory } from 'lib/story-books';

import { ThrowButton } from './components/ThrowButton';
import { useThrowingErroringApi } from './utils/useThrowingErroringApi';
import { withWrapper } from './utils/withWrapper';

// Story
export const FallbackRenderWithWrapperStory = (): JSX.Element => {
  const { throwing, erroring } = useThrowingErroringApi();
  return (
    <ErrorBoundary
      fallbackRenderWithWrapper={
        // Wrapper
        withWrapper
      }
      onError={error => {
        erroring.setErrorDetails({
          error,
        });
      }}
    >
      <ThrowButton
        {...roleProps.roles('ThrowButton')}
        api={throwing}
        text="Recoverable error"
      />
    </ErrorBoundary>
  );
};

addStory(
  'error-handling/fallbackRenderWithWrapper',
  FallbackRenderWithWrapperStory
);
