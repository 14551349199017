import { Location } from 'history';

import {
  get_latest_versionReturnType,
  useQueryProductStreamVersion,
} from 'components/Footer/hooks/useQueryProductStreamVersion';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useHistory } from 'cross-platform/react-router';
import { URLLike, useGetURL } from 'lib/api/analytics/useGetURL';
import { useQueryProduct, useQueryProgram } from 'lib/api/reactQueryHelpers';

export type GetButtonClickedAnalyticsRequestValues = {
  programId: number;
  productId: number;
  getUrl: () => Promise<URLLike>;
  pathname: Location['pathname'];
  productVersionStreamResult: get_latest_versionReturnType;
};

/**
 * Effect that fetches the values used in useQuerySendButtonClickedAnalytics
 * - Exported so these value can be used in other effects
 */
export const useQueryGetButtonClickedAnalyticsRequestValues = (): GetButtonClickedAnalyticsRequestValues => {
  const programId = useQueryProgram().data?.result.id as number;
  const productId = useQueryProduct().data?.result.id as number;
  const getUrl = useGetURL();
  const pathname = useHistory().location.pathname;
  const productVersionStreamResult = useQueryProductStreamVersion.get_latest_version(
    { meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN } }
  );

  return {
    getUrl,
    pathname,
    productId,
    productVersionStreamResult,
    programId,
  };
};
