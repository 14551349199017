import React, { ReactElement } from 'react';
import { useField } from 'formik';

import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { roles } from 'cross-platform/utils/roleProps';

import {
  InputFieldPresentational,
  InputFieldPresentationalProps,
} from '../../InputField/InputFieldPresentational';

export type SimpleInputFieldProps = {
  name: string;
  label: string;
  error?: string;
  value?: any;
} & Omit<
  InputFieldPresentationalProps,
  'onChange' | 'onBlur' | 'touched' | 'fieldTypes'
>;

const SimpleInputField = (props: SimpleInputFieldProps): ReactElement => {
  const [field, meta, helpers] = useField<DropdownItem[]>(props);
  return (
    <InputFieldPresentational
      {...props}
      {...roles.pass(props)}
      label={props.label}
      onChange={(val?: Value): void => {
        field.onChange(props.name)(String(val || ''));
      }}
      onBlur={(): void => {
        field.onBlur(props.name);
        helpers.setTouched(true);
      }}
      value={field.value}
      fieldTypeProps={props.fieldTypeProps}
      touched={meta.touched}
      error={meta.error}
    />
  );
};

export { SimpleInputField };
