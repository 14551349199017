import { useItemListCarousel } from 'domain/carousel/ItemListCarousel/hooks/useItemListCarousel/useItemListCarousel';

import * as React from 'react';
import { ScrollView, View } from 'react-native';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { NavBarStyles } from 'components/Navigation/constants';
import {
  PlayerControlsStyles,
  ScreenResponsiveLayoutContext,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { getPseudoTreeStructureProps } from 'lib/getNthChildProps';
import { noOpFunction } from 'lib/noOpFunction';

import { Nav } from './components/Nav';
import { NavButton } from './components/NavButton';
import { Card, ScrollRow, ScrollRowProps, Wrapper } from './styled';

const STUB_NO_CAROUSEL = {
  getItemListRef: null,
  isNav: false,
  scrollerRef: null,
  getScrollerWrapperRef: null,
  getTargetRefFactory: () => () => null,
  back: { onPress: noOpFunction },
  forward: { onPress: noOpFunction },
  handleLayout: noOpFunction,
};

const ScrollRowForwardedRef = React.forwardRef<ScrollView, ScrollRowProps>(
  (props, ref) => <ScrollRow {...props} ref={ref} />
);
ScrollRowForwardedRef.displayName = 'ScrollRowForwardedRef';

const CardForwardedRef = React.forwardRef(
  (props: React.ComponentProps<typeof Card>, ref) => (
    <Card {...props} ref={ref} />
  )
);
CardForwardedRef.displayName = 'CardForwardedRef';

export type CardListProps = {
  data: get_user_session_screen_data.ResultStandard['session_list'];
  card: typeof CardForwardedRef;
  variant: 'session_list' | 'optional_content_list';
};

/**
 * (Horizontal) scrolling list of Session cards
 *
 * @param {CardListProps} props
 */
export const CardList = (props: CardListProps): React.ReactElement => {
  const scaleFn = useTransformContentStyle();

  const scaleValue = scaleFn(1);
  const { screenWidth } = React.useContext(ScreenResponsiveLayoutContext);

  const useItemListCarouselOptions = {
    items: props.data,
    getInitialPos: () => 0,
  };
  const carousel =
    useItemListCarousel<CardListProps['data'][number]>(
      useItemListCarouselOptions
    ) || STUB_NO_CAROUSEL;
  const isMobile = screenWidth < PlayerControlsStyles.largeScreenBreakpoint;
  const isSmall =
    screenWidth < NavBarStyles.navBarCollapseMobileWebBreakPointPx;
  return (
    <Wrapper>
      <View ref={carousel.getScrollerWrapperRef} style={{ width: '100%' }}>
        {carousel.isNav ? (
          <Nav isSmall={isSmall}>
            <NavButton {...carousel.back} scaleX={1} />
            <NavButton {...carousel.forward} scaleX={-1} />
          </Nav>
        ) : null}
        <ScrollRowForwardedRef
          scaleValue={scaleValue}
          scrollToOverflowEnabled={true}
          scrollEventThrottle={1}
          ref={carousel.scrollerRef}
          horizontal={false}
          onLayout={carousel.handleLayout}
        >
          <View
            ref={carousel.getItemListRef}
            style={{ width: '100%', flexDirection: 'row', paddingBottom: 10 }}
          >
            {props.data.map((item, index, { length }) => (
              <props.card
                {...item}
                {...getPseudoTreeStructureProps({
                  index,
                  length,
                })}
                ref={carousel.getTargetRefFactory(index)}
                scaleValue={scaleValue}
                screenWidth={screenWidth}
                isMobile={isMobile}
                key={`${index}`}
                variant={props.variant}
              />
            ))}
          </View>
        </ScrollRowForwardedRef>
      </View>
    </Wrapper>
  );
};

export type Props = {
  data: get_user_session_screen_data.ResultStandard['session_list'];
};

const SessionList = (props: Props): React.ReactElement => (
  <CardList variant="session_list" {...props} card={CardForwardedRef} />
);

export { SessionList };
