import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_sleep_efficiency_trend_page_data_desc } from '@bighealth/api/SleepEfficiencyTrend/v1';

import { getTimezone } from 'lib/timezone';

import { useQueryProduct } from './useQueryProduct';

export const useQuerySleepEfficiency = ({
  page,
  items_per_page,
}: {
  page: number;
  items_per_page: number;
}): UseQueryResult<
  get_sleep_efficiency_trend_page_data_desc.Response,
  APIErrorResponse
> => {
  const productId = useQueryProduct()?.data?.result.id;

  return useQuery({
    queryKey: [
      get_sleep_efficiency_trend_page_data_desc.queryKey,
      page,
      items_per_page,
    ],
    queryFn: () =>
      get_sleep_efficiency_trend_page_data_desc({
        page,
        items_per_page,
        user_timezone: getTimezone(),
        product_id: productId as number,
      }),
    enabled: typeof productId === 'number',
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
