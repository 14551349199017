import { useMutation, UseMutationResult } from 'react-query';

import { confirm_otp } from '@bighealth/api/UserAccountMFA/v1';

export type UseMutationMFAConfirmResponse = UseMutationResult<
  confirm_otp.Response,
  unknown,
  string,
  unknown
>;

export const useMutationMFAConfirm = (): UseMutationMFAConfirmResponse => {
  const result = useMutation({
    mutationFn: (otp: string) => confirm_otp({ otp: otp }),
  });
  return result;
};
