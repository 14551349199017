export type Status = CardProps['status'];

import {
  CtaCircleLockedPlaySvg,
  CtaCirclePlaySvg,
  CtaCircleReplaySvg,
} from 'components/Screens/ContentScreens';

import { CardProps } from '..';

type IconComponent =
  | typeof CtaCircleReplaySvg
  | typeof CtaCirclePlaySvg
  | typeof CtaCircleLockedPlaySvg;

export const ICONS_BY_STATUS: Record<Status, IconComponent> = {
  COMPLETED: CtaCircleReplaySvg,
  NOT_STARTED: CtaCirclePlaySvg,
  LOCKED: CtaCircleLockedPlaySvg,
  IN_PROGRESS: CtaCirclePlaySvg,
};
