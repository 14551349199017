import styled from 'styled-components/native';

import { Text as TextUnstyled } from 'components/sceneset-components/Text';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';

const DOMAIN = 'Form';

export const Wrapper = styled.View``;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

export const HeadingForm = styled.Text``;
HeadingForm.displayName = `${DOMAIN}.HeadingForm`;

export const ButtonContainer = styled.View`
  width: 50%;
  margin: auto;
  align-items: center;
  justify-content: center;
`;
ButtonContainer.displayName = `${DOMAIN}.ButtonContainer`;

export const WarningContainer = styled.View`
  flex-direction: row;
  align-items: center;
  border: 2px solid ${sleepioColors.warningBorderColor};
  background-color: ${sleepioColors.warningBackgroundColor};
  border-radius: 8px;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 28px;
`;
WarningContainer.displayName = `${DOMAIN}.WarningContainer`;

export const WarningText = styled(TextUnstyled)`
  color: 2px solid ${sleepioColors.warningBorderColor};
  font-weight: 500;
  font-size: 16px;
`;
WarningText.displayName = `${DOMAIN}.WarningText`;

export const WarningTextPadding = styled.View`
  width: 16px;
`;
WarningTextPadding.displayName = `${DOMAIN}.WarningTextPadding`;
