import React, { FunctionComponent, ReactNode } from 'react';
import { Modal, ViewStyle } from 'react-native';

import {
  TooltipContent,
  TooltipInteractiveModalBackground,
  TooltipModalBackground,
} from './styled';

export interface TooltipProps {
  containerStyle?: ViewStyle;
  contentStyle?: ViewStyle;
  visible?: boolean;
  children: ReactNode;
  offset?: string;
  onClose?: () => void;
  setVisible: (state: boolean) => void;
}

const SimpleTooltip: FunctionComponent<TooltipProps> = ({
  containerStyle,
  contentStyle,
  visible,
  onClose,
  setVisible,
  children,
}) => {
  const handleClose = () => {
    onClose?.();
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      animationType="fade"
      transparent={true}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <TooltipModalBackground
        style={{
          ...containerStyle,
        }}
      >
        <TooltipInteractiveModalBackground
          accessibilityLabel="Close tooltip"
          onPress={handleClose}
          activeOpacity={1}
        />
        <TooltipContent style={contentStyle}>{children}</TooltipContent>
      </TooltipModalBackground>
    </Modal>
  );
};

SimpleTooltip.displayName = 'SimpleTooltip';
export { SimpleTooltip };
