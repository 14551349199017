import { useEffect } from 'react';
import { useFormikContext } from 'formik';

/**
 * When data changes, revalidate the form.
 *
 * First needed by SleepDiaryForm to revalidate when showing/hiding a field
 *
 * @param {String} props.data A "hash", whose changes trigger Formik.validateForm()
 */
const ValidateIfDataChanges = (props: { data?: string }): null => {
  const { validateForm } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [props.data, validateForm]);
  return null;
};

export { ValidateIfDataChanges };
