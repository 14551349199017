import { ErrorInfo } from 'react';

import { isDevMode } from 'lib/isDevMode';

import { captureException } from '../utils/captureException';
import { DataDog } from '../utils/DataDog';

// Type when following convention
type Context = {
  version: '1';
  errorInfo?: ErrorInfo;
  error?: Error | unknown;
  payload: Record<string, unknown>;
};

export function error(
  domain: string,
  message: string,
  context?: Context
): Promise<void> {
  if (isDevMode()) {
    console.error(`[${domain}] ${message}`, context);
  }
  captureException(`[${domain}] ${message}: ${context}`);
  return DataDog.error(`[${domain}] ${message}`, context);
}
