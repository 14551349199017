import React from 'react';
import Svg, {
  Defs,
  LinearGradient,
  Polygon,
  Stop,
  Use,
} from 'react-native-svg';

import {
  Star as StarProps,
  ViewStylesObject,
} from '@bighealth/types/src/scene-components/client';

import { useTransformStylesToContext } from 'components/ResponsiveLayout/hooks/useTransformStylesToContext';
import { roles } from 'cross-platform/utils/roleProps';

export default function Star(props: StarProps): React.ReactElement {
  const radius = props.radius;
  const scaleValues = useTransformStylesToContext();
  const { width, height } = scaleValues({
    width: radius,
    height: radius,
  });

  /**
   * We need to scale each point of the star too, so lets do that.
   * Here is the layout of the points.
   *
   *
   *       0
   *       A
   * 8 __9/_\1___ 2
   *    '7. .3'
   *    /.'5'.\
   *   /'     '\
   * 6           4
   *
   */
  const points: number[][] = [
    [8, 0],
    [10, 6],
    [16, 6],
    [11.5, 9],
    [13.5, 15],
    [8, 11.5],
    [2.5, 15],
    [4.5, 9],
    [0, 6],
    [6, 6],
  ];
  const scaledPoints = [];
  let maxX = 0;
  let maxY = 0;

  for (const [x, y] of points) {
    const { width: scaledX, height: scaledY } = scaleValues({
      width: x,
      height: y,
    });
    scaledPoints.push(scaledX, scaledY);
    if (scaledX > maxX) {
      maxX = scaledX;
    }
    if (scaledY > maxY) {
      maxY = scaledY;
    }
  }
  const strokeWidth =
    (typeof props.stroke?.weight === 'undefined' ? 10 : props.stroke.weight) /
    maxX;
  const scale = 1 - (strokeWidth * 2) / maxX;
  const { width: strokeWeight } = scaleValues({
    width: strokeWidth,
  });
  const transformedStyles = scaleValues(props.style as ViewStylesObject);

  if (props.style?.top || props.style?.left) {
    transformedStyles.position = 'absolute';
  }

  return (
    <Svg
      {...roles('Star')}
      width={width}
      height={height}
      viewBox={`0 0 ${maxX} ${maxY}`}
      style={transformedStyles}
      pointerEvents={'none'}
    >
      <Defs>
        <LinearGradient id="value-fill">
          <Stop
            stopColor={props.valueFill || 'white'}
            offset={(typeof props.value === 'number' ? props.value : 100) + '%'}
          />
          <Stop
            stopColor={props.fill || 'white'}
            offset={(typeof props.value === 'number' ? props.value : 100) + '%'}
          />
          <Stop stopColor={props.fill} offset={100} />
        </LinearGradient>
        <Polygon
          id={'star-polygon'}
          fill="url(#value-fill)"
          points={scaledPoints.join(' ')}
        />
      </Defs>

      <Use
        xlinkHref={'#star-polygon'}
        x={strokeWidth}
        y={strokeWidth}
        transform={`scale(${scale} ${scale})`}
      />

      <Use
        xlinkHref={'#star-polygon'}
        fill={props.fill || 'white'}
        stroke={props.stroke?.color || 'red'}
        strokeWidth={strokeWeight}
        strokeOpacity={props.stroke?.opacity}
        strokeLinecap="round"
        x={strokeWidth}
        y={strokeWidth}
        transform={`scale(${scale} ${scale})`}
      />
    </Svg>
  );
}
