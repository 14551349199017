import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const disconnect = async (
  args: disconnect.Args,
  requestConfig?: AxiosRequestConfig
): Promise<disconnect.Response> => {
  const request: disconnect.Request = {
    service_name: 'Fitbit',
    service_version: '1',
    service_method: 'disconnect',
    args,
  };
  return await api(request, requestConfig);
};

disconnect.queryKey = 'Fitbit/disconnect';

export declare namespace disconnect {
  export type Args = {
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'Fitbit';
    service_version: '1';
    service_method: 'disconnect';
    args: Args;
  }

  export type Result = boolean;

  export type Response = APIResponse<Result>;
}
