import { APIResponse } from '@bighealth/api';
import { SceneSetResponse } from '@bighealth/types';

/**
 * Handler to determine which screen to redirect to based on whether or not the Scene Set is resumable.
 * @param {number} sceneSetGraphId Scene Set Graph Id
 * @param {string} productReference Product Reference
 * @param {number | undefined} destinationId Destination Scene Set Id
 * @param {APIResponse<SceneSetResponse | null>} sceneSetResponse Scene Set Response
 * @returns {APIResponse<SceneSetResponse | null>} Scene Set Response
 */
export const jumpToSceneSetResponseHandler = (
  sceneSetGraphId: number,
  productReference: string,
  destinationId: number | undefined,
  sceneSetResponse: APIResponse<SceneSetResponse | null>
): APIResponse<SceneSetResponse | null> => {
  const currentSceneSetId = sceneSetResponse?.result?.id;

  if (typeof sceneSetResponse?.result?.scene_set_json === 'undefined') {
    history.replaceState({}, '', `/${productReference}/home`);
    history.go();
  } else if (
    typeof destinationId !== undefined &&
    typeof currentSceneSetId !== undefined &&
    currentSceneSetId !== destinationId
  ) {
    history.replaceState(
      {},
      '',
      `/${productReference}/${sceneSetGraphId}/${currentSceneSetId}/0/`
    );
    history.go();
  }

  return sceneSetResponse;
};
