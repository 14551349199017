import React, { useContext } from 'react';
import styled from 'styled-components/native';

import * as sceneSetView from 'components/SceneSetView/components/contentErrors';
import { addStory } from 'lib/story-books';

import * as content from './components/content';
import * as global from './components/global';
import { PotentialError } from './components/PotentialError';

const NavContainer = styled.View`
  background-color: darkblue;
  height: 16;
  width: 100%;
`;
const ContentContainer = styled.View`
  background-color: silver;
  width: 100%;
  margin-top: 32;
  margin-left: 32;
`;
const SceneSetContainer = styled.View`
  background-color: white;
  width: 100%;
  margin-top: 32;
  margin-left: 64;
`;

const FauxGlobalView = () => {
  const [error, setError] = React.useState<Error | undefined>(undefined);
  if (error) {
    throw error; // Redirect to error boundary
  }
  return (
    <PotentialError
      domain="GlobalView"
      onError={(error: Error) => {
        if (typeof setError === 'function') {
          setError(error);
        }
      }}
    />
  );
};

const FauxContentView = () => {
  const [error, setError] = React.useState<Error | undefined>(undefined);
  if (error) {
    throw error; // Redirect to error boundary
  }
  return (
    <PotentialError
      domain="ContentView"
      onError={(error: Error) => {
        if (typeof setError === 'function') {
          setError(error);
        }
      }}
    />
  );
};

const FauxSceneSetView = () => {
  const { setErrorDetails } = useContext(sceneSetView.Context);
  return (
    <PotentialError
      domain="SceneSetView"
      onError={(error: Error) => {
        if (typeof setErrorDetails === 'function') {
          setErrorDetails({ error });
        }
      }}
    />
  );
};

// Story
export const AppDemoStory = (): JSX.Element => {
  return (
    <global.ErrorBoundary>
      <FauxGlobalView />
      <NavContainer />
      <ContentContainer>
        <content.ErrorBoundary>
          <FauxContentView />
          <SceneSetContainer>
            <sceneSetView.Provider>
              <sceneSetView.ErrorBoundary>
                <FauxSceneSetView />
              </sceneSetView.ErrorBoundary>
              <sceneSetView.ErrorModal />
            </sceneSetView.Provider>
          </SceneSetContainer>
        </content.ErrorBoundary>
      </ContentContainer>
    </global.ErrorBoundary>
  );
};

addStory('error-handling/app-demo', AppDemoStory);
