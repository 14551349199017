import { ActionHandlerCallback, ActionObject } from '../../useActionHandler';
import { doEffectServiceCalls } from '../doEffectServiceCalls';

//
/**
 * WARNING: Do not design payload shapes here.
 *
 *  The library we use to render Editor UIs in Staff-Web
 *  using JSON Schema:
 *  1. only supports a subset of JSONSchema spec
 *  2. is better at some data types than others
 *
 *  Thus Design the payload in Staff-web e.g.
 *  1. update the JSONSchema/SceneComponentDefinition
 *  2. VERIFY the UI the Editor renders is acceptable
 *
 *  Only after the above is completed, add the shape here
 */
export type EffectServiceCalls = {
  SleepDiary?: {
    v1?: {
      extrapolate_missing_diaries_from_past_seven_days?: boolean;
    };
  };
};

type OptionsType = {
  onCreateReminder: (triggerCreateReminder: string) => void;
  onTriggerServiceCalls: (
    serviceCalls: EffectServiceCalls
  ) => ReturnType<typeof doEffectServiceCalls>;
};

export const withEffectFactory = (
  handler: ActionHandlerCallback,
  action: ActionObject,
  options: OptionsType
): ActionHandlerCallback => {
  const triggerInAppReview = action?.effects?.triggerInAppReview;
  const triggerCreateReminder = action?.effects?.triggerCreateReminder;
  const triggerServiceCalls = action?.effects?.triggerServiceCalls;

  // For performance, only create returned callback if needed
  if (triggerInAppReview || triggerCreateReminder || triggerServiceCalls) {
    return async (...args) => {
      if (typeof triggerInAppReview === 'string') {
        // triggerInAppReview(triggerInAppReview) // Deprecated
      }
      if (typeof triggerCreateReminder === 'string') {
        options.onCreateReminder(triggerCreateReminder);
      }
      if (triggerServiceCalls) {
        await options.onTriggerServiceCalls(triggerServiceCalls);
      }
      if (typeof handler === 'function') {
        return await handler(...args);
      }
    };
  }
  return handler;
};
