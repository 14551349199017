import { useSafeArea } from 'react-native-safe-area-context';

import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import {
  SectionScaleValues,
  useScreenResponsiveLayoutContext,
} from 'components/ResponsiveLayout';
import { isLandscape } from 'lib/isLandscape';

import {
  doubleColumnBase,
  headerStopBreakpoint,
  leftColumnBase,
  smallScreenBreakpoint,
} from '../constants';
import { LayoutType } from '../types';

type ModalLayout = {
  layoutType: LayoutType;
  main: SectionScaleValues;
  header: SectionScaleValues;
};

export const useModalLayout = (): ModalLayout => {
  const safeArea = useSafeArea();
  const screenOrientation = useScreenOrientation();
  const { screenWidth } = useScreenResponsiveLayoutContext();

  const isSmallScreen = screenWidth < smallScreenBreakpoint;

  // width should not be greater than the size of both columns
  const maxWidth =
    screenWidth > doubleColumnBase ? doubleColumnBase : screenWidth;

  // width that can be used to display content
  const safeWidth = maxWidth - safeArea.left - safeArea.right;

  // in landscape the columns should be of equal size
  // to scale down the left column that is larger on large screens, its size is multiple twice
  const landscapeBase = leftColumnBase * 2;

  const main: SectionScaleValues = {
    current: safeWidth,
    base: 1,
  };
  const header: SectionScaleValues = {
    current: safeWidth,
    base: 1,
  };
  let layoutType: LayoutType | null = null;
  if (isLandscape(screenOrientation)) {
    layoutType = LayoutType.Row;

    // the content should scale into two equal columns
    main.base = landscapeBase;
    main.max = doubleColumnBase;

    // landscape is a corner case and we want to the header to scale
    // from its original base to current size
    header.min = headerStopBreakpoint;
    header.max = doubleColumnBase;
    header.base = doubleColumnBase;
  } else if (isSmallScreen) {
    layoutType = LayoutType.Column;

    // the content should scale into a single column that is of left column size
    main.base = leftColumnBase;
    main.max = doubleColumnBase;

    // progressive scaling from small screen breakpoint down to header stop breakpoint
    header.min = headerStopBreakpoint;
    header.max = smallScreenBreakpoint;
    header.base = smallScreenBreakpoint;
  } else {
    layoutType = LayoutType.Row;

    // the content should scale into two columns where left one takes more space;
    main.base = doubleColumnBase;
    main.max = doubleColumnBase;

    // no scaling
    header.min = doubleColumnBase;
    header.max = doubleColumnBase;
    header.base = doubleColumnBase;
  }

  return {
    main,
    header,
    layoutType,
  };
};
