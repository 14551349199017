import { isValidDate } from 'lib/isValidDate';
import { stringify } from 'lib/stringify';

const createValidDate = (...args: ConstructorParameters<typeof Date>): Date => {
  const date = new Date(...args);
  if (isValidDate(date)) {
    return date;
  }
  throw Error(
    `Expected args to create a valid date, instead got ${args
      .map((val: ConstructorParameters<typeof Date>[number]) =>
        typeof val === 'object' || Array.isArray(val) ? stringify(val) : val
      )
      .join(', ')}`
  );
};
export { createValidDate };
