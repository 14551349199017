import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_library_content } from '@bighealth/api/LibraryArticle/v1';

import { useQueryProduct } from './useQueryProduct';

export const useQueryLibraryContent = (
  args: Omit<get_library_content.Args, 'product_id'>
): UseQueryResult<get_library_content.Response, APIErrorResponse> => {
  const productId = useQueryProduct().data?.result?.id;
  const result = useQuery<get_library_content.Response, APIErrorResponse>({
    queryKey: get_library_content.queryKey,
    queryFn: () =>
      get_library_content({
        product_id: productId as number,
        search_words: args.search_words,
      }),
    enabled: typeof productId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
