/* istanbul ignore file */
import React, { ReactElement, useCallback } from 'react';
import { View } from 'react-native';
import { add, sub } from 'date-fns';

import { TimePicker } from 'components/generic-question/DateTimePickers';
import {
  NOTIFICATION_TYPE,
  ScheduleNotificationFunc,
} from 'components/LocalNotifications/NotificationsView';
import { WarningIcon } from 'daylight/components/icons';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { Constant } from '../../Constants';
import { SettingsContentText } from '../../SettingsScreen.styled';
import { VisualState } from '../VisualState';

import * as Styled from './NotificationsSettingsScreen.styled';

type Props = {
  openSettingsScreen: () => void;
  date: Date;
  uiState: VisualState;
  scheduleNotification: ScheduleNotificationFunc;
  setDate: (date: Date) => void;
};

export const NotificationsSettingsScreen = ({
  openSettingsScreen,
  date,
  uiState,
  scheduleNotification,
  setDate,
}: Props): ReactElement => {
  const transformStyle = useTransformContentStyle();
  const formattedDate = sub(date, { hours: date.getTimezoneOffset() / 60 });

  const scheduleDate = useCallback(
    (newDate: Date | null) => {
      if (newDate === null) {
        return;
      }
      scheduleNotification({
        type: NOTIFICATION_TYPE.DAILY_REMINDER,
        data: add(newDate, { hours: newDate.getTimezoneOffset() / 60 }),
      });
    },
    [scheduleNotification]
  );

  const updateDate = useCallback(
    (newDate: Date | null) => {
      if (newDate === null) {
        return;
      }
      setDate(add(newDate, { hours: newDate.getTimezoneOffset() / 60 }));
    },
    [setDate]
  );
  return (
    <Styled.MainView>
      <Styled.TopTextSection>
        {uiState ===
          VisualState.C__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF ||
        uiState ===
          VisualState.D__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON ? (
          <Styled.TextContainer>
            <Styled.MainText>
              {Constant.Notification.FIVE_TO_TEN_MINS}
            </Styled.MainText>
          </Styled.TextContainer>
        ) : null}
        {uiState === VisualState.B__SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT && (
          <View style={{ flexDirection: 'row' }}>
            <WarningIcon size={transformStyle(75)} />
            <View style={{ flexDirection: 'column' }}>
              <SettingsContentText>
                {Constant.Notification.NOTIF_TURNED_OFF}{' '}
              </SettingsContentText>
              <Styled.UnderlinedText
                onPress={openSettingsScreen}
                testID="open-settings-btn"
              >
                {Constant.Notification.GO_TO_SETTINGS}
              </Styled.UnderlinedText>
            </View>
          </View>
        )}
      </Styled.TopTextSection>

      {uiState ===
      VisualState.D__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON ? (
        <Styled.TimePickerContainer>
          <TimePicker
            onDateChange={updateDate}
            onDateDone={scheduleDate}
            date={formattedDate}
          />
          <Styled.MainText
            style={{
              marginTop: transformStyle(12),
              fontSize: transformStyle(14),
            }}
          >
            {Constant.Notification.TAP_TO_EDIT}
          </Styled.MainText>
        </Styled.TimePickerContainer>
      ) : null}
    </Styled.MainView>
  );
};

export default NotificationsSettingsScreen;
