export const SettingsConfigNotificationsKey = 'NOTIFICATIONS';
export const SettingsMfaEnabledKey = 'MFA-ENABLED';
export const SettingsMfaDisabledKey = 'MFA-DISABLED';

export const settingsConfig = {
  items: [
    {
      copy: 'Emergency Support',
      key: 'EMERGENCY_SUPPORT',
      copyColor: '#B92D0F',
      path: '/daylight/settings/emergency-support',
    },
    {
      copy: 'Notifications',
      key: SettingsConfigNotificationsKey,
      path: '/daylight/settings/notifications',
    },
    {
      copy: 'Multi-factor Authentication',
      key: SettingsMfaEnabledKey,
      path: '/daylight/settings/mfa/mfa-is-enabled',
    },
    {
      copy: 'Multi-factor Authentication',
      key: SettingsMfaDisabledKey,
      path: '/daylight/settings/mfa/mfa-is-disabled',
    },
    {
      copy: 'Contact Us',
      key: 'CONTACT_US',
      path: '/daylight/settings/contact-us',
    },
    {
      copy: 'About Daylight',
      key: 'ABOUT_DAYLIGHT',
      path: '/daylight/settings/about-daylight',
    },
  ],
};
