// NOTE: This file is only for 📖 Storybook & ✅ Tests
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { roles } from 'cross-platform/utils/roleProps';
import { FallbackProps } from 'lib/error/ErrorBoundary/types';

const CustomErrorFallback: React.ComponentType<FallbackProps> = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  return (
    <View>
      <Text>{`Custom error fallback: ${error.message}`}</Text>
      <TouchableOpacity
        {...roles('CustomErrorFallback.Button')}
        onPress={resetErrorBoundary}
      >
        Try again
      </TouchableOpacity>
    </View>
  );
};

export { CustomErrorFallback };
