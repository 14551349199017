import React, { ReactElement, ReactNode, useContext } from 'react';
import { View, ViewStyle } from 'react-native';
import { ThemeContext } from 'styled-components';

import { Container } from '@bighealth/types/src/scene-components/client';

import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';

export const GenericContainer = (
  props: Container & { children: ReactNode }
): ReactElement => {
  const theme = useContext(ThemeContext);
  const scaleValues = useTransformStylesToContext(theme);
  const scaledStyles = scaleValues(props.style || {});
  return (
    <View
      {...roles('GenericContainer')}
      pointerEvents={'box-none'}
      style={(scaledStyles as unknown) as ViewStyle}
    >
      {props.children}
    </View>
  );
};
