import React, { ReactElement } from 'react';

import { EmptyScreen } from 'components/Screens';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';

export type AuthenticationGateProps = {
  children: ReactElement | ReactElement[];
};

export const AuthenticationGate = ({
  children,
}: AuthenticationGateProps): ReactElement => {
  const { isFetched } = useQueryProgram();
  // isFetched is false until the request
  // is resolved or rejected
  if (!isFetched) {
    return <EmptyScreen />;
  }

  return <>{children}</>;
};
