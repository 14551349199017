import React, { PropsWithChildren } from 'react';
import { ViewStyle } from 'react-native';

import { ScreenScrollBase } from './ScreenScrollBase';

type ScreenScrollType = React.FC<
  PropsWithChildren<
    Omit<typeof ScreenScrollBase, 'showsVerticalScrollIndicator'> & {
      contentContainerStyle?: ViewStyle;
    }
  >
>;

export const ScreenScroll: ScreenScrollType = props => {
  return <ScreenScrollBase {...props} showsVerticalScrollIndicator={true} />;
};
