import React, { ReactElement } from 'react';
import { Platform } from 'react-native';
import { useIsFetching } from 'react-query';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { ProductReferences } from 'common/constants/enums';
import { ScreenViewedAnalyticsSender } from 'components/analytics';
import { DebugButtonThrow } from 'components/DebugButtonThrow';
import { Footer } from 'components/Footer';
import { NavigationTheme } from 'components/Navigation/types';
import { PRODUCT_STREAM_ID } from 'components/Routes/constants';
import { DebugThrow } from 'components/sceneset-components/DebugThrow';
import SceneSetView from 'components/SceneSetView';
import { AutoRotateOrientationLocker } from 'components/SceneSetView/components/OrientationLocker';
import { DefaultOrientation } from 'components/SceneSetView/types';
import {
  EmptyScreen,
  ForgotPasswordScreen,
  HomeScreen,
  LoginCallbackScreen,
  LoginScreen,
  ResetPasswordScreen,
  SettingsScreen,
} from 'components/Screens';
import { Container } from 'components/Screens/ContentScreens/components/ContentPlaceholder';
import { SleepDiaryScreen } from 'components/Screens/ContentScreens/DiaryScreen';
import { DownloadsScreen } from 'components/Screens/ContentScreens/DownloadsScreen';
import { LibraryScreen } from 'components/Screens/ContentScreens/LibraryScreen';
import { ProgressScreen } from 'components/Screens/ContentScreens/ProgressScreen';
import { ResourcesScreen } from 'components/Screens/ContentScreens/Resources';
import { ScheduleScreen } from 'components/Screens/ContentScreens/ScheduleScreen';
import ForQATeam from 'components/Screens/ForQATeam';
import { ForQATeamAnalytics } from 'components/Screens/ForQATeamAnalytics';
import { ForQATeamDebugMenu } from 'components/Screens/ForQATeamDebugMenu';
import { ForQATeamSSGbySS } from 'components/Screens/ForQATeamSSGbySS';
import { HomeScreenSwitcher } from 'components/Screens/HomeScreenSwitcher';
import { LoginSplashScreen } from 'components/Screens/LoginSplashScreen/LoginSplashScreen';
import {
  MobileWebGate,
  MobileWebGateContent,
} from 'components/Screens/MobileWebGate';
import { SleepNow } from 'components/Screens/SleepNow';
import { SessionGate } from 'components/SessionGate';
import {
  HOME_ROUTE_PARAMETER,
  SLEEP_DIARY_ROUTE_PARAMETER,
} from 'components/SleepDiaryWeek/constants';
import { ButtonColorSchemes } from 'components/UniveralButtons';
import {
  LOCAL_NOTIFICATION_ENABLED,
  PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE,
  PLATGEN_LOGIN_URL,
  PLATGEN_ONBOARDING_URL,
} from 'config/envVars';
import { getAssetsForProduct } from 'config/getAssetsForProduct';
import { getStringsForProduct } from 'config/getStringsForProduct/getStringsForProduct';
import { Redirect } from 'cross-platform/react-router';
import { Redirector } from 'cross-platform/react-router/Router/utils/Redirector';
import { roles } from 'cross-platform/utils/roleProps';
import { DefaultErrorFallback } from 'lib/error/ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import { createErrorBoundaryForDomain } from 'lib/error/utils/createErrorBoundaryForDomain';
import { useInitMessaging } from 'lib/messaging';
import { noOpFunction } from 'lib/noOpFunction';
import { useInitialNotificationEvent } from 'lib/notification-events/useInitialNotificationEvent';
import * as reporter from 'lib/reporter';
import { WithGlobalNavigation } from 'sleepio/components/Navigation/WithGlobalNavigation';
import { FirstSessionRedirect } from 'sleepio/components/screens/FirstSessionRedirect';
import {
  NotificationScreen,
  useSetupLocalNotifications,
} from 'sleepio/components/screens/NotificationsScreen';
import { Throbber } from 'sleepio/components/Throbber';

import { MonitorView } from '../../MonitorView';
import { VerticalScroll } from '../../VerticalScroll/VerticalScroll';
import { StoryBook } from '../StoryBook';
import { useSafeParams } from '../useSafeParams';
import { checkMetaCommon } from '../utils/isFetchingWithThrobberPredicateFactory/checkMetaCommon';
import { checkMetaSleepio } from '../utils/isFetchingWithThrobberPredicateFactory/checkMetaSleepio';
import { checkThrobberBehavior } from '../utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { isFetchingWithThrobberPredicateFactory } from '../utils/isFetchingWithThrobberPredicateFactory/isFetchingWithThrobberPredicateFactory';

import { AuthenticationGate } from './components/AuthenticationGate';
import { renderFallbackReturnsHomeForDomainFactory } from './components/renderFallbackReturnsHomeForDomainFactory';
import { WithNotificationScreenRedirect } from './components/WithNotificationScreenRedirect';

const ERROR_DOMAIN = 'SleepioSwitch';
const ErrorBoundaryForSleepioSwitch = createErrorBoundaryForDomain(
  ERROR_DOMAIN
);
const renderFallbackForSleepioSwitch = renderFallbackReturnsHomeForDomainFactory(
  ERROR_DOMAIN
);

function RedirectWithDebugMessage({
  message,
  to,
}: {
  message: string;
  to: string;
}) {
  reporter.debug(message);
  return <Redirect to={to} />;
}

const strings = getStringsForProduct(ProductReferences.SLEEPIO);
const assets = getAssetsForProduct(ProductReferences.SLEEPIO);

export const SleepioSwitch = (): ReactElement => {
  useSetupLocalNotifications();
  useInitialNotificationEvent();
  useInitMessaging();

  const countIsFetchingWithThrobber = useIsFetching({
    predicate: isFetchingWithThrobberPredicateFactory([
      /**
       * This is an **Ordered-list** of _checker functions_
       * * These _checker function_ are executed in order by the predicate
       * * If a _checker function_ returns `undefined`, the next function called
       * * If a _checker function_ returns `true` or `false`, this is the predicate result
       * * If all _checker functions_ have returned `undefined`, a default is returned
       */
      checkThrobberBehavior,
      checkMetaCommon,
      checkMetaSleepio,
    ]),
  });

  const history = useHistory();
  const isAnyFetching = useIsFetching();
  const { productReference } = useSafeParams();

  const isEmptyScreenFetching =
    isAnyFetching !== 0 && history.location.pathname === `/${productReference}`;

  const isInternalLoginPage =
    Platform.OS !== 'web' || PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE;
  return (
    <ErrorBoundaryForSleepioSwitch
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
      fallbackRender={renderFallbackForSleepioSwitch}
    >
      <Switch>
        <Route path={'/:productReference'} exact>
          {/* TODO Replace EmptyScreen with TitleScreen WHEN spec is defined */}
          {/* <TitleScreen /> */}
          <MonitorView viewName="EmptyScreen">
            <EmptyScreen />
          </MonitorView>
        </Route>
        {isInternalLoginPage ? (
          <Route path={'/:productReference/login'} exact>
            <MonitorView viewName="LoginSplashScreen">
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <LoginSplashScreen
                  website={strings.website}
                  byLine={strings.LoginSplashScreen.byLine}
                  noAccountOptional={
                    strings.LoginSplashScreen.noAccountOptional
                  }
                  noAccount={strings.LoginSplashScreen.noAccount}
                  logoAsset={assets.splashLogoDarkStrokeLightFill}
                  overrides={{
                    SignInButton: {
                      colorScheme: ButtonColorSchemes.AltPrimary,
                    },
                    FillAbsolute: {
                      style: {
                        backgroundColor: '#F7F6F5',
                      },
                    },
                  }}
                  optionalFooter={strings.optionalFooter}
                />
              </AutoRotateOrientationLocker>
            </MonitorView>
          </Route>
        ) : (
          <Route
            path={'/:productReference/login'}
            component={() => {
              window.location.href = `${PLATGEN_LOGIN_URL}?product_stream_id=${PRODUCT_STREAM_ID}`;
              return null;
            }}
          />
        )}
        {isInternalLoginPage ? (
          <Route path={'/:productReference/login-screen'} exact>
            <MonitorView viewName="LoginScreen">
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <LoginScreen />
              </AutoRotateOrientationLocker>
            </MonitorView>
          </Route>
        ) : (
          <Route
            path={'/:productReference/login-screen'}
            component={() => {
              window.location.href = `${PLATGEN_LOGIN_URL}?product_stream_id=${PRODUCT_STREAM_ID}`;
              return null;
            }}
          />
        )}
        <Route path={'/:productReference/login-callback'} exact>
          <MonitorView viewName="LoginCallbackScreen">
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <LoginCallbackScreen />
            </AutoRotateOrientationLocker>
          </MonitorView>
        </Route>
        {/**
         * Looking for <Route path={'/:productReference/login-with-token'} />?
         *
         * login-with-token is one of the FEW (only?) endpoints
         * that are not served by this Single Page Application
         *
         * @see https://github.com/search?q=org%3Asleepio%20login-with-token&type=code
         */}
        <Route path={'/:productReference/home-debug'} exact>
          <AuthenticationGate>
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation enableMobileMode>
                <HomeScreenSwitcher />
                <DebugButtonThrow
                  title="Test error"
                  error={Error('Home test')}
                />
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </AuthenticationGate>
        </Route>

        <Route path={`/:productReference/${HOME_ROUTE_PARAMETER}`} exact>
          <MonitorView viewName="HomeScreenSwitcher">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithNotificationScreenRedirect>
                  <WithGlobalNavigation
                    enableMobileMode={false}
                    navigationTheme={NavigationTheme.Dark}
                  >
                    <HomeScreenSwitcher />
                  </WithGlobalNavigation>
                </WithNotificationScreenRedirect>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'menu', section: 'home' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>
        <Route path={'/:productReference/menu'} exact>
          <MonitorView viewName="HomeScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation enableMobileMode={true}>
                  <MobileWebGate>
                    <HomeScreen />
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/settings'} exact>
          <MonitorView viewName="SettingsScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <MobileWebGate>
                    <SettingsScreen />
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'menu', section: 'settings' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/settings/notifications'} exact>
          {!LOCAL_NOTIFICATION_ENABLED || Platform.OS === 'web' ? (
            <RedirectWithDebugMessage
              message={`Blocked ${!LOCAL_NOTIFICATION_ENABLED} ${Platform.OS}`}
              to="/:productReference/home"
            />
          ) : (
            <MonitorView viewName="Notifications">
              <AuthenticationGate>
                <AutoRotateOrientationLocker
                  allowedOrientation={DefaultOrientation.PORTRAIT}
                >
                  <NotificationScreen />
                </AutoRotateOrientationLocker>
                <ScreenViewedAnalyticsSender
                  field={{ area: 'settings', section: 'notifications' }}
                />
              </AuthenticationGate>
            </MonitorView>
          )}
        </Route>

        <Route path={'/:productReference/qa/go/product'} exact>
          <AuthenticationGate>
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation>
                <ForQATeam />
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </AuthenticationGate>
        </Route>
        <Route path={'/:productReference/qa/go/ssg-ss'} exact>
          <AuthenticationGate>
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation>
                <ForQATeamSSGbySS />
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </AuthenticationGate>
        </Route>
        <Route path={'/:productReference/qa/analytics'} exact>
          <WithGlobalNavigation>
            <ForQATeamAnalytics />
          </WithGlobalNavigation>
        </Route>
        <Route path={'/:productReference/qa/debug-menu'} exact>
          <AuthenticationGate>
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation>
                <ForQATeamDebugMenu />
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </AuthenticationGate>
        </Route>
        <Route path={'/:productReference/qa-throw'} exact>
          <DebugThrow error={Error('DebugThrow.qa-throw')} />
        </Route>

        <Route path={'/:productReference/qa-throw-with-nav'} exact>
          <AuthenticationGate>
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation>
                <DebugThrow error={Error('DebugThrow.qa-throw-with-nav')} />
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </AuthenticationGate>
        </Route>
        <Route path={'/:productReference/schedule'} exact>
          <MonitorView viewName="ScheduleScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <MobileWebGate>
                    <SessionGate
                      minSessionRequired={1}
                      titleScreen={'Schedule'}
                    >
                      <ScheduleScreen />
                    </SessionGate>
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'casefile', section: 'your_schedule' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/schedule/:page'} exact>
          <MonitorView viewName="ScheduleScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <MobileWebGate>
                    <SessionGate
                      minSessionRequired={1}
                      titleScreen={'Schedule'}
                    >
                      <ScheduleScreen />
                    </SessionGate>
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'casefile', section: 'your_schedule' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/casefile/progress'} exact>
          <MonitorView viewName="ProgressScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <MobileWebGate>
                    <SessionGate
                      minSessionRequired={1}
                      titleScreen={'Progress'}
                    >
                      <ProgressScreen />
                    </SessionGate>
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'casefile', section: 'your_progress' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/downloads'} exact>
          <MonitorView viewName="DownloadsScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <DownloadsScreen />
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'downloads', section: 'home' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/downloads/:page'} exact>
          <MonitorView viewName="DownloadsScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <DownloadsScreen />
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'downloads', section: 'download' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/library'} exact>
          <MonitorView viewName="LibraryScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <MobileWebGate>
                    <SessionGate minSessionRequired={1} titleScreen={'Library'}>
                      <LibraryScreen />
                    </SessionGate>
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'library', section: 'home' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/library/:page'} exact>
          <MonitorView viewName="LibraryScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <MobileWebGate>
                    <SessionGate minSessionRequired={1} titleScreen={'Library'}>
                      <LibraryScreen />
                    </SessionGate>
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'library', section: 'topic' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/library/:page/:articleId'} exact>
          <MonitorView viewName="LibraryScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <MobileWebGate>
                    <SessionGate minSessionRequired={1} titleScreen={'Library'}>
                      <LibraryScreen />
                    </SessionGate>
                  </MobileWebGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'library', section: 'article' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/reset-password'} exact>
          <MonitorView viewName="ResetPasswordScreen">
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <ResetPasswordScreen isHardCodeSchema />
            </AutoRotateOrientationLocker>
          </MonitorView>
        </Route>

        {Platform.OS !== 'web' || PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE ? (
          <Route path={'/:productReference/forgot-password'} exact>
            <MonitorView viewName="ForgotPasswordScreen">
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <ForgotPasswordScreen />
              </AutoRotateOrientationLocker>
            </MonitorView>
          </Route>
        ) : (
          <Route
            path={'/:productReference/forgot-password'}
            component={() => {
              window.location.href = `${PLATGEN_ONBOARDING_URL}/login-component/forgot`;
              return null;
            }}
          />
        )}

        <Route path={'/:productReference/resources'} exact>
          <MonitorView viewName="ResourcesScreen">
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation>
                <ResourcesScreen />
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/sleep-now'} exact>
          <MonitorView viewName="SleepNowScreen">
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation>
                <SleepNow />
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </MonitorView>
        </Route>

        <Route path={'/:productReference/redirect-to-session-one'} exact>
          <FirstSessionRedirect />
        </Route>

        <Route
          path={'/:productReference/888888/storybook'}
          component={StoryBook}
          exact
        />

        <Route path={'/:productReference/:sceneSetGraphId/:sceneSetId'} exact>
          <MonitorView viewName="SceneSetView">
            <MobileWebGateContent>
              <SceneSetView defaultOrientation={DefaultOrientation.LANDSCAPE} />
            </MobileWebGateContent>
          </MonitorView>
        </Route>

        <Route
          path={'/:productReference/888888/storybook/:story(.*)'}
          component={StoryBook}
        />

        <Route
          path={
            '/:productReference/:sceneSetGraphId/:sceneSetReference/:sceneId'
          }
          exact
        >
          <MonitorView viewName="SceneSetView">
            <MobileWebGateContent>
              <SceneSetView defaultOrientation={DefaultOrientation.LANDSCAPE} />
            </MobileWebGateContent>
          </MonitorView>
        </Route>

        <Route path={`/:productReference/${SLEEP_DIARY_ROUTE_PARAMETER}`} exact>
          <MonitorView viewName="SleepDiaryScreen">
            <AuthenticationGate>
              <AutoRotateOrientationLocker
                allowedOrientation={DefaultOrientation.PORTRAIT}
              >
                <WithGlobalNavigation>
                  <SessionGate
                    minSessionRequired={1}
                    titleScreen={'Sleep Diary'}
                  >
                    <Formik initialValues={[]} onSubmit={noOpFunction}>
                      <SleepDiaryScreen />
                    </Formik>
                  </SessionGate>
                </WithGlobalNavigation>
              </AutoRotateOrientationLocker>
              <ScreenViewedAnalyticsSender
                field={{ area: 'diary', section: 'view' }}
              />
            </AuthenticationGate>
          </MonitorView>
        </Route>
        <Route>
          {/* Not found / 404 */}
          <MonitorView viewName="DefaultErrorFallback">
            <AutoRotateOrientationLocker
              allowedOrientation={DefaultOrientation.PORTRAIT}
            >
              <WithGlobalNavigation>
                <VerticalScroll>
                  <Container>
                    <DefaultErrorFallback />
                  </Container>
                  <Footer />
                </VerticalScroll>
              </WithGlobalNavigation>
            </AutoRotateOrientationLocker>
          </MonitorView>
        </Route>
      </Switch>
      {countIsFetchingWithThrobber > 0 || isEmptyScreenFetching ? (
        <Throbber {...roles('RootThrobber')} />
      ) : null}
      <Redirector />
    </ErrorBoundaryForSleepioSwitch>
  );
};
