import React, { ReactElement, useEffect, useState } from 'react';
import { ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { MarkdownSceneComponent } from '@bighealth/api/SessionProgress/v1';
import { Scene } from '@bighealth/types/src/scene-component';

import { CrossRounded } from 'components/icons';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryAllForSessionScreen } from 'lib/api/reactQueryHelpers';
import { getProductName } from 'lib/productInfo/getProductName';
import { LayoutComponent } from 'sleepio/components/screens/SessionsScreen/components/Presentation/components/LayoutComponent/LayoutComponent';

const DOMAIN = 'BannerNotificationCenter';

export type NotificationBanner = {
  banner_title: Scene.Components.Text;
  banner_content: MarkdownSceneComponent;
};

function getActiveNotification(data: NotificationBanner[] | undefined) {
  if (Array.isArray(data) && data.length) {
    return data[0];
  }

  return undefined;
}

export function NotificationCenter({
  onCloseNotification,
  style,
}: {
  onCloseNotification?: (notification: NotificationBanner) => void;
  style?: ViewStyle;
}): ReactElement | null {
  const { data, isLoading } = useQueryAllForSessionScreen();

  const [notificationData, setNotificationData] = useState<
    NotificationBanner[]
  >();

  const [activeNotification, setActiveNotification] = useState<
    NotificationBanner | undefined
  >(getActiveNotification(notificationData));

  useEffect(() => {
    if (!isLoading && data?.result?.migration_banner !== undefined) {
      setNotificationData([data.result.migration_banner]);

      setActiveNotification(
        getActiveNotification([data.result.migration_banner])
      );
    } else {
      setNotificationData(undefined);
      setActiveNotification(undefined);
    }
  }, [data, isLoading]);

  if (!notificationData || !activeNotification) {
    return null;
  }

  const cancelNotification = () => {
    const tempData = notificationData.filter(d => d !== activeNotification);
    setNotificationData(tempData);
    setActiveNotification(getActiveNotification(tempData));
    if (onCloseNotification) {
      onCloseNotification(activeNotification);
    }
  };

  const productTheme = getThemeForProduct(getProductName());

  return (
    <Container style={style}>
      <ButtonContainer>
        <CancelButton
          {...roles('notification-center-cancel-button')}
          onPress={cancelNotification}
        >
          <CrossRounded size={productTheme.controls.closeButton.iconSize} />
        </CancelButton>
      </ButtonContainer>
      <Content {...roles('notification-center-content-body')}>
        <LayoutComponent childNodes={[activeNotification.banner_title]} />
        <LayoutComponent childNodes={[activeNotification.banner_content]} />
      </Content>
    </Container>
  );
}

const Container = styled.View`
  position: 'absolute';
  width: 100%;
  background-color: ${props => props.theme.color.primaryLight};
  color: ${props => props.theme.color.background.light};
  padding: 8px;
  padding-bottom: 31px;
`;

Container.displayName = `${DOMAIN}.Container`;

const ButtonContainer = styled.View`
  align-items: flex-end;
  width: 100%;
`;

ButtonContainer.displayName = `${DOMAIN}.ButtonContainer`;

const CancelButton = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

CancelButton.displayName = `${DOMAIN}.CancelButton`;

const Content = styled.View`
  align-items: center;
`;

Content.displayName = `${DOMAIN}.Content`;
