import React, { memo, ReactElement } from 'react';
import styled from 'styled-components/native';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { GrayBox, WhiteBox } from 'daylight/components/ghosts/ghosts';
import { useTransformContentStyle } from 'daylight/hooks';

const Wrapper = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.color.deselectedGrey};
  padding: 16px;
`;
Wrapper.displayName = 'ProgressScreenGhostWrapper';

const Row = styled.View`
  flex-direction: row;
`;
Row.displayName = 'Row';

const Ghost = memo(
  (): ReactElement => {
    const transformV = useTransformContentStyleVertical();
    const transform = useTransformContentStyle();

    const responsiveTextHeight = transformV(24);
    const responsiveGutterSize = transform(16);
    return (
      <Wrapper
        {...roles('progressScreenGhost')}
        style={{
          paddingVertical: responsiveGutterSize,
          paddingHorizontal: responsiveGutterSize,
        }}
      >
        <WhiteBox
          style={{
            paddingHorizontal: transform(20),
            paddingVertical: transformV(35),
            marginBottom: responsiveGutterSize,
          }}
        >
          <GrayBox
            height={responsiveTextHeight}
            width="28%"
            style={{ marginBottom: transformV(13) }}
          />
          <GrayBox height={responsiveTextHeight} width="87%" />
        </WhiteBox>
        <Row style={{ marginBottom: responsiveGutterSize }}>
          <WhiteBox
            style={{
              marginRight: responsiveGutterSize,
              flex: 1,
              paddingTop: transformV(40),
              paddingBottom: transformV(56),
              paddingHorizontal: transform(20),
            }}
          >
            <GrayBox
              height={responsiveTextHeight}
              width="54%"
              style={{ marginBottom: transformV(13) }}
            />
            <GrayBox height={responsiveTextHeight} width="100%" />
          </WhiteBox>
          <WhiteBox
            style={{
              flex: 1,
              paddingTop: transformV(40),
              paddingBottom: transformV(56),
              paddingHorizontal: transform(20),
            }}
          >
            <GrayBox
              height={responsiveTextHeight}
              width="54%"
              style={{ marginBottom: transformV(13) }}
            />
            <GrayBox height={responsiveTextHeight} width="100%" />
          </WhiteBox>
        </Row>
        <WhiteBox
          style={{
            paddingHorizontal: transform(20),
            paddingVertical: transformV(22),
            marginBottom: responsiveGutterSize,
          }}
        >
          <GrayBox height={responsiveTextHeight} width="100%" />
        </WhiteBox>
        <WhiteBox
          style={{
            paddingHorizontal: transform(20),
            paddingTop: transformV(33),
            paddingBottom: transformV(69),
          }}
        >
          <GrayBox
            height={responsiveTextHeight}
            width="28%"
            style={{ marginBottom: transformV(13) }}
          />
          <GrayBox
            height={responsiveTextHeight}
            width="87%"
            style={{ marginBottom: transformV(32) }}
          />
          <GrayBox
            height={responsiveTextHeight}
            width="87%"
            style={{ marginBottom: transformV(32) }}
          />
          <GrayBox height={responsiveTextHeight} width="87%" />
        </WhiteBox>
      </Wrapper>
    );
  }
);

Ghost.displayName = 'ProgressScreenGhost';

export { Ghost };
