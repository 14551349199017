import { equals } from 'ramda';

import { Scene } from '@bighealth/types/src/scene-component';

import { SetTimeout } from 'lib/SetTimeout';

import { TogglableRefNode } from './types';
type ToggleVisibilityProps = Scene.Utils.ToggleVisibility;

export enum State {
  UNINITIALIZED = 'uninitialized',
  PLAYING = 'playing',
  PAUSED = 'paused',
}

export enum TimerId {
  showTimer = 'showTimer',
  hideTimer = 'hideTimer',
}

const TIMERS = [TimerId.showTimer, TimerId.hideTimer];

export class ToggleVisibilityWithTimer {
  private playLock = false;
  private readonly refNode: TogglableRefNode;
  private lastPlayedAtMs?: number = undefined;
  private lastPausedAtMs?: number = undefined;
  private timerTimeElapsedMs = 0;
  private props: ToggleVisibilityProps;
  private timers: {
    [TimerId.showTimer]?: SetTimeout;
    [TimerId.hideTimer]?: SetTimeout;
  } = {
    [TimerId.showTimer]: undefined,
    [TimerId.hideTimer]: undefined,
  };
  private triggeredState = {
    [TimerId.showTimer]: false,
    [TimerId.hideTimer]: false,
  };

  constructor(refNode: TogglableRefNode) {
    this.refNode = refNode;
    this.props = ToggleVisibilityWithTimer.getPropsForNode(
      this.refNode
    ) as ToggleVisibilityProps;
  }

  /**
   * ----------------------------------------
   * External controls
   */

  play = (): void => {
    if (this.playLock) {
      return;
    }

    this.startTimers();
  };

  pause = (): void => {
    if (this.getState() === State.PAUSED) {
      return;
    }
    this.clearTimers();
    const timeElapsedPlaying = Date.now() - this.getLastPlayedAt();
    this.setTimerElapsedTimeMs(this.timerTimeElapsedMs + timeElapsedPlaying);
    this.setLastPausedAtMs(Date.now());
  };

  reset = (): void => {
    this.clearTimers();
  };

  /**
   * ----------------------------------------
   * Component internals
   */

  private show = (): void => {
    this.getNode().current?.show();
  };

  private hide = (): void => {
    this.getNode().current?.hide();
  };

  /**
   * ----------------------------------------
   * Timers
   *
   */

  private startTimers = () => {
    const state = this.getState();
    if (state === State.PLAYING) {
      return; // Don't play again
    }
    this.clearTimers();

    // Re-initiate play/pause times
    this.setLastPlayedAtMs(Date.now());
    this.setLastPausedAtMs(undefined);

    for (const id of TIMERS) {
      this.startTimer(id);
    }
  };

  private startTimer = (id: TimerId): void => {
    this.clearTimer(id);

    if (typeof this.getEndForTimer(id) === 'undefined') {
      // There's no value and so we don't need any times
      return;
    }

    const remainingTime =
      (this.getEndForTimer(id) as number) - this.getTimeElapsed();
    if (remainingTime < 0) {
      return;
    }
    if (remainingTime === 0) {
      // Don't need to bother about the timers; it's right there
      this.triggerTimerAction(id);
      return;
    }
    this.timers[id] = new SetTimeout(() => {
      this.triggerTimerAction(id);
      this.clearTimer(id);
    }, remainingTime);
  };

  private triggerTimerAction = (id: TimerId): void => {
    // Don't trigger a second time. Will cause flashes etc otherwise
    if (!this.triggeredState[id]) {
      if (id === TimerId.showTimer) {
        this.show();
      } else {
        this.hide();
      }
    }
    this.triggeredState[id] = true;
  };

  private clearTimers = (): void => {
    this.clearTimer(TimerId.hideTimer);
    this.clearTimer(TimerId.showTimer);
  };

  private clearTimer = (id: TimerId): void => {
    const timer = this.timers[id];
    if (timer) {
      timer.clearTimeout();
      this.timers[id] = undefined;
    }
  };

  /**
   * ----------------------------------------
   * Public setters
   *
   */

  setProps = (nextProps: ToggleVisibilityProps): void => {
    if (equals(this.props, nextProps)) {
      // Don't do anything
      return;
    }
    this.props = nextProps;
  };

  setPlayLock = (): void => {
    this.playLock = true;
  };

  releasePlayLock = (): void => {
    this.playLock = false;
  };

  /**
   * ----------------------------------------
   * Private setters
   *
   */

  private setLastPlayedAtMs = (time: number | undefined): void => {
    this.lastPlayedAtMs = time;
  };

  private setLastPausedAtMs = (time: number | undefined): void => {
    this.lastPausedAtMs = time;
  };

  private setTimerElapsedTimeMs = (time: number): void => {
    this.timerTimeElapsedMs = time;
  };

  /**
   * ----------------------------------------
   * Public getters
   */

  getProps = (): ToggleVisibilityProps => this.props;

  static getPropsForNode = (node: TogglableRefNode): ToggleVisibilityProps => {
    return {
      hideAtSeconds: node.current?.props.hideAtSeconds as number,
      showAtSeconds: node.current?.props.showAtSeconds as number,
    };
  };

  getNode = (): TogglableRefNode => this.refNode;

  /**
   * ----------------------------------------
   * Private getters
   */

  private getShowAtMs = (): number | undefined =>
    typeof this.props.showAtSeconds === 'number'
      ? this.props.showAtSeconds * 1000
      : undefined;

  private getHideAtMs = (): number | undefined =>
    typeof this.props.hideAtSeconds === 'number'
      ? this.props.hideAtSeconds * 1000
      : undefined;

  private getEndForTimer = (id: TimerId): number | undefined => {
    if (id === TimerId.showTimer) {
      return this.getShowAtMs();
    } else {
      return this.getHideAtMs();
    }
  };

  private getTimeElapsed = (): number => {
    return this.timerTimeElapsedMs;
  };

  private getLastPlayedAt = (): number =>
    this.lastPlayedAtMs || -10000000000000;

  private getState = (): State => {
    // We literally want this.lastPlayedAtMs here and not the getter value
    if (!this.lastPlayedAtMs) {
      return State.UNINITIALIZED;
    }
    if (this.lastPausedAtMs) {
      return State.PAUSED;
    }
    return State.PLAYING;
  };

  /**
   * ----------------------------------------
   * For use in testing only
   */

  __getState = (): State => this.getState();

  __getTimerTimeElapsed = (): number => this.timerTimeElapsedMs;

  __getTimerStartAtMs = (): number | undefined => this.lastPlayedAtMs;

  __getTimerPausedAtTimeMs = (): number | undefined => this.lastPausedAtMs;

  __getCurrentTime = (): number => Date.now();

  __getPlayLock = (): boolean => this.playLock;
}
