import React from 'react';

interface AccessibleWrapperProps {
  roleKind?: string;
  accessibilityLabel?: string;
}

export const AccessibleWrapper: React.FC<React.PropsWithChildren<
  AccessibleWrapperProps
>> = ({ children }) => {
  return <>{children}</>;
};
