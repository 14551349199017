import { useCallback, useState } from 'react';

import { instead } from 'lib/error/utils/instead';
import { throwIf } from 'lib/error/utils/throwIf';

export type UseCursorReturns<T> = {
  value: T | undefined;
  setPageBack: () => void;
  setPageForward: () => void;
  set: (newValue: T) => void;
};

export const useCursor = <T,>({
  all,
  visibleCount,
  initialVisibleValue,
}: {
  all: T[];
  visibleCount: number;
  initialVisibleValue?: T;
}): UseCursorReturns<T> => {
  throwIf(
    visibleCount <= 0,
    `visibleCount to be > 0, ${instead(visibleCount)}`
  );
  const [value, setInternal] = useState<T | undefined>(
    initialVisibleValue || all[0] || undefined
  );
  // TODO: temp fix to resolve TS errors from byStringified
  // which lives in '../../../../lib/fp/predicateFactory/byStringified'
  const byStringified = (needle?: T) => (hay: T) => `${needle}` === `${hay}`;

  const set = useCallback(
    (newValue: T) => {
      if (typeof newValue === 'undefined') {
        return; // Nothing
      }
      const index = all.findIndex(byStringified(newValue));
      throwIf(index === -1, `${newValue} in ${all[0]}-${all.join(', ')}`);
      setInternal(newValue);
    },
    [all]
  );
  const setPageBack = useCallback(() => {
    const index = all.findIndex(byStringified(value));
    const newIndex = Math.max(0, index - visibleCount);
    set(all[newIndex]);
  }, [all, value, visibleCount, set]);

  const setPageForward = useCallback(() => {
    const index = all.findIndex(byStringified(value));
    const newIndex = Math.min(all.length - 1, index + visibleCount);
    set(all[newIndex]);
  }, [all, value, visibleCount, set]);

  return { value, setPageBack, setPageForward, set };
};
