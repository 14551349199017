import React, { ReactElement } from 'react';
import { Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { AccessibilityProps } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Text as TextProps } from '@bighealth/types/src/scene-components/client';

import { TextTypes } from 'common/constants/enums';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { Text as TextComponent } from 'components/Text';
import { accessibility } from 'cross-platform/utils/accessibilityProps';
import * as roleProps from 'cross-platform/utils/roleProps';
import { RoleProps } from 'cross-platform/utils/roleProps';

import { getFontFamilyFromStyles } from './getFontFamilyFromStyles';
import getFontStyle from './getFontStyle';
import { getTextAlignmentConfig } from './utils';

type Props = Omit<TextProps, 'text'>;

const TextPresentation = (
  props: React.PropsWithChildren<Props> & RoleProps & AccessibilityProps
): ReactElement => {
  // IDEA remove Array.isArray
  // WHEN have time to deal with types
  // WHO Ash (or anyone)
  const flatStyles = Array.isArray(props.style)
    ? StyleSheet.flatten(props.style || {})
    : props.style;

  const theme = useTheme();
  const scaleValues = useTransformStylesToContext(theme);
  const scaledStyles = scaleValues(flatStyles);
  const alignmentStyles = getTextAlignmentConfig(props.alignment);

  if (props.style) {
    const { top, left, bottom, right } = props.style;
    if (top || left || bottom || right) {
      scaledStyles.position = 'absolute';
    }
  }

  const defaultStyles = theme.text;

  const contentTextStyles = scaleValues(
    defaultStyles[TextTypes.CONTENT]
  ) as typeof defaultStyles[TextTypes.CONTENT];

  const fontWeight = flatStyles?.fontWeight
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      ((flatStyles?.fontWeight).toString() as TextStyle['fontWeight'])
    : contentTextStyles.fontWeight;

  const textStyles = {
    ...scaledStyles,
    ...alignmentStyles,

    [Platform.OS == 'web'
      ? 'textDecoration'
      : 'textDecorationLine']: props?.underline ? 'underline' : 'none',

    fontStyle: getFontStyle(props?.italic),
    fontSize: scaledStyles?.fontSize || contentTextStyles.fontSize,
    fontWeight: fontWeight,
    fontFamily: getFontFamilyFromStyles(
      { fontWeight: fontWeight },
      props?.italic
    ),
    color: scaledStyles.color || contentTextStyles.color,
  };
  const hasAriaHidden = (props as { 'aria-hidden'?: boolean })['aria-hidden'];

  return (
    <TextComponent
      href={props.href}
      {...roleProps.pass(props, undefined, { default: 'Text' })}
      {...accessibility(props?.accessibility)}
      style={StyleSheet.flatten({
        display: flatStyles?.display || 'flex',
        ...textStyles,
      } as ViewStyle)}
      numberOfLines={props?.numberOfLines}
      aria-hidden={hasAriaHidden ? true : undefined} // to solve windows accessibility issues
      accessible={true}
    >
      {props.children}
    </TextComponent>
  );
};

export { TextPresentation };
