import React, { ReactElement, useContext } from 'react';
import { ThemeContext } from 'styled-components/native';

import { Chevron } from 'components/icons';
import { NavBarStyles } from 'components/Navigation/constants';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import {
  CALENDAR_CHART_MARGIN_LEFT,
  NARROW_CALENDAR_CHART_MARGIN_LEFT,
} from 'components/SleepDiaryWeek/constants';
import { pass, RoleProps } from 'cross-platform/utils/roleProps';

import { CalendarTileContainerPadding } from '../styled';

import { Button, ButtonView } from './styled';

const PagingButton = ({
  disabled,
  onPress,
  icon,
  iconSize,
  ...rest
}: {
  disabled?: boolean;
  onPress: () => void;
  icon: 'right' | 'left';
  iconSize: number;
} & RoleProps): ReactElement => {
  const { screenWidth, screenHeight } = useContext(
    ScreenResponsiveLayoutContext
  );
  const theme = useContext(ThemeContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;
  const isLandscape = screenWidth > screenHeight;

  if (disabled) {
    return (
      <CalendarTileContainerPadding
        style={{
          width:
            isMobileWeb && !isLandscape
              ? NARROW_CALENDAR_CHART_MARGIN_LEFT
              : CALENDAR_CHART_MARGIN_LEFT,
        }}
      />
    );
  }

  return (
    <Button
      {...pass(rest)}
      disabled={disabled}
      onPress={onPress}
      style={{
        width:
          isMobileWeb && !isLandscape
            ? NARROW_CALENDAR_CHART_MARGIN_LEFT
            : CALENDAR_CHART_MARGIN_LEFT,
      }}
      accessibilityRole="button"
      aria-label={icon}
      accessibilityLabel={icon}
    >
      <ButtonView>
        <Chevron
          size={iconSize}
          direction={icon}
          style={{ color: theme.color.overlay.primary }}
        />
      </ButtonView>
    </Button>
  );
};

export { PagingButton };
