import { Value } from 'components/forms/types';
import { isDate } from 'lib/isDate';

const getOutOfBoundsOptionsIfNeeded = (
  options: Date[],
  selected?: Value
): Date[] => {
  if (
    !isDate(selected) &&
    typeof selected !== 'undefined' &&
    selected !== null
  ) {
    throw TypeError(
      `Expected selected to be Date or null or undefined, instead got ${selected} (${typeof selected})`
    );
  } else if (!isDate(selected)) {
    return [];
  } else if (options.find(date => selected.getTime() === date.getTime())) {
    return [];
  }
  return [new Date(selected.getTime())];
};

export { getOutOfBoundsOptionsIfNeeded };
