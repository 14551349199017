import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import CrossPlatformImage from 'cross-platform/Image';
import { optionalCardValues } from 'sleepio/components/screens/SessionsScreen/hooks/constants';
import { ScaleValueProps } from 'sleepio/components/screens/SessionsScreen/utils/scaler';

import { StatusProps } from '../../utils/byStatus';
export { Grid } from 'components/layout/Grid';
import { Text } from 'components/sceneset-components/Text';

const DOMAIN = 'OptionalCard';

export const Touchable = styled.TouchableOpacity``;
Touchable.displayName = `${DOMAIN}.Touchable`;

type WrapperProps = ScaleValueProps & StatusProps & StyleProp<ViewStyle>;

export const Wrapper = styled.View<WrapperProps>`
  background-color: #ffffff;
  width: ${optionalCardValues.card.width}px;
  height: ${optionalCardValues.card.height}px;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  border-radius: ${optionalCardValues.card.large.borderRadius}px;
  overflow: hidden;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

export const OptionalContantWrapper = styled.View`
  postion: relative;
`;

OptionalContantWrapper.displayName = `${DOMAIN}.OptionalContantWrapper`;

export const OptionalContent = styled.View`
  display: flex;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: ${optionalCardValues.card.details.width}px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: ${optionalCardValues.card.image.width + 16}px;
`;

export const Image = styled(CrossPlatformImage)<{
  src: string;
  assetSize: {
    width: number;
    height: number;
  };
}>`
  postion: absolute;
  top: 0px;
  left: 0px;
  width: ${({ assetSize: { width } }) => width}px;
  height: ${({ assetSize: { height } }) => height}px;
`;
Image.displayName = `${DOMAIN}.Image`;

export const LabelText = styled(Text).attrs({
  numberOfLines: 2,
})<ScaleValueProps>`
  font-weight: 500;
`;
LabelText.displayName = `${DOMAIN}.LabelText`;
