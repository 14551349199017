import { isDevMode } from 'lib/isDevMode';

import { DataDog } from '../utils/DataDog';

/**
 * DEPRECATED DEPRECATED DEPRECATED DEPRECATED
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function warn(message: string, context?: any): Promise<void> {
  if (isDevMode()) {
    console.warn(message, context);
  }
  return DataDog.warn(message, context);
}

/**
 * DEPRECATED DEPRECATED DEPRECATED DEPRECATED
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function info(message: string, context?: any): Promise<void> {
  if (isDevMode()) {
    console.info(message, context);
  }
  return DataDog.info(message, context);
}

export const deprecated = { warn, info };
