import styled from 'styled-components/native';

import { TouchableOpacity as TouchableOpacityUnstyled } from 'components/primitives/cross-platform';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { TextPresentation } from 'components/sceneset-components/Text/TextPresentation';

import {
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  ERROR_BOUNDARY_FONT_SIZE,
  LINK_DISPLAY_VALUE,
} from '../constants';

const MarkdownText = styled(TextPresentation)`
  display: ${LINK_DISPLAY_VALUE};
  font-weight: 400;
`;

export const ErrorBoundaryDetails = styled(MarkdownText)`
  color: #435fa9;
  font-size: ${ERROR_BOUNDARY_FONT_SIZE}px;
`;
ErrorBoundaryDetails.displayName = 'Details';

export const ErrorBoundaryParagraph = styled.View`
  margin-top: 0;
  display: ${LINK_DISPLAY_VALUE};
`;
ErrorBoundaryParagraph.displayName = 'Paragraph';

export const DefaultParagraph = styled.Text`
  font-size: ${DEFAULT_FONT_SIZE}px;
  line-height: ${DEFAULT_LINE_HEIGHT}px;
  color: ${props => props.theme.color.overlay.primary};
  font-family: '${FontFamilies.Regular}';
  margin-top: 1em;
`;
DefaultParagraph.displayName = 'Paragraph';

export const ErrorBoundaryLinkText = styled(MarkdownText).attrs({
  underline: true,
})`
  color: #368bae;
  font-size: ${ERROR_BOUNDARY_FONT_SIZE}px;
`;
ErrorBoundaryLinkText.displayName = 'LinkText';

export const TouchableOpacity = styled(TouchableOpacityUnstyled)`
  display: ${LINK_DISPLAY_VALUE};
`;
TouchableOpacity.displayName = 'TouchableOpacity';

export const DefaultLinkText = styled(MarkdownText).attrs({
  underline: true,
})`
  color: #1a80a2;
  font-size: ${DEFAULT_FONT_SIZE}px;
  line-height: ${DEFAULT_LINE_HEIGHT}px;
`;
DefaultLinkText.displayName = 'LinkText';
