import { FunctionComponent } from 'react';
import styled from 'styled-components/native';

import {
  IsSelectedProp,
  TabIconProps,
} from 'components/Navigation/BottomNavigation/types';

export const createTabIcon = ({
  Icon,
  displayName,
}: {
  displayName: string;
  Icon: FunctionComponent<TabIconProps>;
}): FunctionComponent<TabIconProps & IsSelectedProp> => {
  const StyledIcon = styled(Icon)<TabIconProps & IsSelectedProp>`
    color: ${props =>
      props.isSelected
        ? props.theme.bottomNavigation.textHighlight
        : props.theme.bottomNavigation.textColor};
  `;
  StyledIcon.displayName = displayName;

  return StyledIcon;
};
