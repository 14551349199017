import { useCallback } from 'react';

import { DEFAULT_THEME, Theme } from 'config/getThemeForProducts';

import { useGetDynamicModalStyles } from './useGetDynamicModalStyles';
import { UseScaleToContextCallback } from './useScaleToScalingContext';

export const useScaleToModal = (
  theme: Theme = DEFAULT_THEME
): UseScaleToContextCallback => {
  const { width } = useGetDynamicModalStyles(theme);

  return useCallback(
    valueToScale => {
      const scale = theme.modal.maxWidthPx / width;
      return valueToScale / scale;
    },
    [theme.modal.maxWidthPx, width]
  );
};
