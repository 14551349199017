import { clone } from 'ramda';

import { isDevMode } from 'lib/isDevMode';
import { qaConfigFlags } from 'lib/showQAMenu/qaConfigFlags';

import {
  AndroidLocalNotificationConfig,
  BHNotificationToShowConfig,
  IosLocalNotificationConfig,
  LocalNotificationConfig,
  NotificationFrequency,
} from './types';

const channelId = 'sleepio-daily-reminder';
const channelName = 'Daily Reminder';

const sessionsReminderChannelId = 'sleepio-sessions-reminder';
const sessionsReminderChannelName = 'Sessions Reminder';

export const SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID =
  'SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID';

export const SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID =
  'SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID';

export const iosConfig: IosLocalNotificationConfig = {
  SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID: {
    channelConfig: {
      id: channelId,
    },
  },
  SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID: {
    channelConfig: {
      id: sessionsReminderChannelId,
    },
  },
};

/**
 * This is set up as config objects accessed by the notifGroupConfigKey
 *
 * - Intentionally not set up as an array of configs in order to simplify access
 */
export const androidConfig: AndroidLocalNotificationConfig = {
  [SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID]: {
    channelConfig: {
      id: channelId,
      name: channelName,
      vibration: true,
      vibrationPattern: [300, 500],
    },
    notificationConfig: {
      channelId,
      /**
       * KNOWN NOTIFEE ERROR WITH THE `ic_launcher` method
       * @see https://notifee.app/react-native/docs/android/appearance
       * Android resources (checks mipmap first, then drawable -
       *  Note there appears to be an Android limitation where if the mipmap folder contains any xml files,
       *  the icon will not load).
       */
      smallIcon: 'ic_notif_small_icon_sleepio',
      color: '#1F52C8',
      pressAction: {
        id: 'default',
      },
    },
  },
  [SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID]: {
    channelConfig: {
      id: sessionsReminderChannelId,
      name: sessionsReminderChannelName,
      vibration: true,
      vibrationPattern: [300, 500],
    },
    notificationConfig: {
      channelId,
      /**
       * KNOWN NOTIFEE ERROR WITH THE `ic_launcher` method
       * @see https://notifee.app/react-native/docs/android/appearance
       * Android resources (checks mipmap first, then drawable -
       *  Note there appears to be an Android limitation where if the mipmap folder contains any xml files,
       *  the icon will not load).
       */
      smallIcon: 'ic_notif_small_icon_sleepio',
      color: '#1F52C8',
      pressAction: {
        id: 'default',
      },
    },
  },
};

/**
 * This is set up as a collection of configs in order
 *   to facilitate many configs (maybe many for the same notifGroupConfigKey)
 */
export const notificationsToShow: BHNotificationToShowConfig[] = [
  {
    // --- VALUES __NOT__ AVAILBLE IN NOTIFICATION INFORMATION ---
    bhNotificationGroupConfig: {
      id: SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID,
      frequency: NotificationFrequency.DAILY,
      count: 30,
      defaults: {
        timeOfDayHours24: 9,
        timeOfDayMinutes: 0,
      },
      fnForCreatingIndividualIds: (i: number): string =>
        `${SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID}-${i}`,
    },
    // --- VALUES AVAILABLE IN NOTIFICATION INFORMATION ---
    title: 'Fill in your Sleep Diary',
    body: 'Sleepio users find more success when seeing progress!',
    data: {
      notificationGroupConfigFrequency: NotificationFrequency.DAILY,
      notificationGroupConfigId: SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID,
    },
  },
  {
    // --- VALUES __NOT__ AVAILBLE IN NOTIFICATION INFORMATION ---
    bhNotificationGroupConfig: {
      id: SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID,
      frequency: NotificationFrequency.ONE_TIME_REMINDER,
      count: 1,
      defaults: {
        timeOfDayHours24: 0,
        timeOfDayMinutes: 0,
        date: undefined,
      },
      fnForCreatingIndividualIds: (i: number): string =>
        `${SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID}-${i}`,
    },
    // --- VALUES AVAILABLE IN NOTIFICATION INFORMATION ---
    title: 'New Session Available!',
    body: 'Keep up your momentum and work on improving your sleep',
    data: {
      notificationGroupConfigFrequency: NotificationFrequency.ONE_TIME_REMINDER,
      notificationGroupConfigId: SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID,
    },
  },
];

export const getConfig = (): LocalNotificationConfig => {
  const localNotificationsToShow = clone(notificationsToShow);
  localNotificationsToShow.forEach(notification => {
    if (
      notification.bhNotificationGroupConfig.id ===
      SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID
    ) {
      notification.bhNotificationGroupConfig.frequency =
        NotificationFrequency.ONE_TIME_REMINDER;
      notification.data.notificationGroupConfigFrequency =
        NotificationFrequency.ONE_TIME_REMINDER;
    } else {
      notification.bhNotificationGroupConfig.frequency =
        NotificationFrequency.DAILY;
      notification.data.notificationGroupConfigFrequency =
        NotificationFrequency.DAILY;
    }
  });
  if (isDevMode()) {
    const notificationFrequency = qaConfigFlags.getValue(
      'Notifications every 10 secs'
    )
      ? NotificationFrequency.FOR_TESTING_ONLY__EVERY_10_SECONDS
      : NotificationFrequency.DAILY;

    const sessionNotificationFrequency = qaConfigFlags.getValue(
      'Session notification after 10 seconds'
    )
      ? NotificationFrequency.ONE_TIME_REMINDER_AFTER_ONE_MINUTE
      : NotificationFrequency.ONE_TIME_REMINDER;

    localNotificationsToShow.forEach(notification => {
      if (
        notification.bhNotificationGroupConfig.id ===
        SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID
      ) {
        notification.bhNotificationGroupConfig.frequency = sessionNotificationFrequency;
        notification.data.notificationGroupConfigFrequency = sessionNotificationFrequency;
      } else {
        notification.bhNotificationGroupConfig.frequency = notificationFrequency;
        notification.data.notificationGroupConfigFrequency = notificationFrequency;
      }
    });
  }
  return {
    iosConfig,
    androidConfig,
    notificationsToShow: localNotificationsToShow,
  };
};
