import React, { ReactElement } from 'react';

import { ClientFeatureVersions } from '@bighealth/types';
import { FormTypes } from '@bighealth/types/dist/scene-components/sleep-diary/entry-form';

import { SleepDiaryForm as SleepDiaryFormComponent } from 'components/SleepDiaryForm';
import { Payloads } from 'lib/api/SleepDiaryService/payloads';

/**
 * Scene Component
 *
 * @param props.diary_date e.g. '2020-06-30'
 * @param props.form_type e.g. create_from_weekly_average_estimate
 */

type Props = {
  diary_date?: string; // e.g. '2020-06-30'
  form_type: FormTypes;
  sleep_diary_form_version?: ClientFeatureVersions.SleepDiaryFormVersion; // optional only for mocks of old data
};

const SleepDiaryForm = ({
  diary_date,
  form_type,
  sleep_diary_form_version,
}: Props): ReactElement | null => {
  /**
   * WARNING using `Payloads.get_diary_entry_form means` this is hard-coded to FE code
   * This contravenes the principle of not coupling FE code to requests.
   * But its easily changed, while the **Editor** is probably not.
   * Feels a bit in the day/Milestone to fix this now.
   *
   * TODO Move `{service_name, service_version, service_method }` dependency from frontend code, to content
   * WHY So frontend code doesn't need to be changed if payload changes
   * WHEN can build request arg payloads in Editor
   * HOW probably:
   *    1. write new variant of this component (with SCD) which takes renderRequest prop
   *    2. Migrate all data
   *    3. Remove this  variant of the component
   * as component/SleepDiaryForm
   */

  return (
    <SleepDiaryFormComponent
      renderRequest={Payloads.get_diary_entry_form({
        form_type,
        $date: diary_date,
        sleep_diary_form_version,
      })}
    />
  );
};

export { SleepDiaryForm };
