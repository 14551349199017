import React from 'react';
import { StyleProp, ViewProps } from 'react-native';
import styled from 'styled-components/native';
// IDEA prop "noStyle" that disables styling from  any "downstream" usage
// WHY to ensure these are always invisible, and only Box is used for visible things
// HOW
//  - rename style to debugStyle (including styled-components)
//  - some nasty prop-drill/context usage

// References used:
// - https://medium.com/p/a4147802405c/responses/show
// - https://reactnative.dev/docs/layout-props.html#flexgrow
// First attribute is default value
type FlexDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse';
type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';
type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'stretch';
type AlignSelf = 'flex-start' | 'flex-end' | 'center' | 'stretch';
type FlexWrap = 'wrap' | 'nowrap';
type AlignContent =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'stretch'
  | 'space-between'
  | 'space-around';
type Position = 'relative' | 'absolute';

export interface FlexBoxProps {
  readonly style?: StyleProp<any>; // IDEA make FlexBoxProps a generic
  // readonly flex?: number; // No shorthand @see https://github.com/styled-components/styled-components/issues/1158
  readonly flexGrow?: number; // positive
  readonly flexShrink?: number; // positive
  readonly flexBasis?: number | 'auto';
  readonly flexDirection?: FlexDirection;
  readonly justifyContent?: JustifyContent;
  readonly alignItems?: AlignItems;
  readonly alignSelf?: AlignSelf;
  readonly flexWrap?: FlexWrap;
  readonly alignContent?: AlignContent;
  readonly position?: Position;
  // readonly zIndex?: number; // Usage is considered Code smell
  readonly width?: number | string;
  children?: React.ReactNode;
  onLayout?: ViewProps['onLayout'];
}
const cssDefaults: FlexBoxProps = {
  flexGrow: 1,
  flexBasis: 'auto',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
  flexWrap: 'wrap',
  position: 'relative',
  alignContent: 'flex-start',
  width: '100%',
};
const FlexBox = styled.View<FlexBoxProps>`
  flex-grow: ${(p: FlexBoxProps): FlexBoxProps['flexGrow'] =>
    p.flexGrow !== undefined ? p.flexGrow : cssDefaults.flexGrow};
  flex-basis: ${(p: FlexBoxProps): FlexBoxProps['flexBasis'] =>
    p.flexBasis !== undefined ? p.flexBasis : cssDefaults.flexBasis};
  flex-direction: ${(p: FlexBoxProps): FlexBoxProps['flexDirection'] =>
    p.flexDirection !== undefined
      ? p.flexDirection
      : cssDefaults.flexDirection};
  justify-content: ${(p: FlexBoxProps): FlexBoxProps['justifyContent'] =>
    p.justifyContent !== undefined
      ? p.justifyContent
      : cssDefaults.justifyContent};
  align-items: ${(p: FlexBoxProps): FlexBoxProps['alignItems'] =>
    p.alignItems !== undefined ? p.alignItems : cssDefaults.alignItems};
  align-self: ${(p: FlexBoxProps): FlexBoxProps['alignSelf'] =>
    p.alignSelf !== undefined ? p.alignSelf : cssDefaults.alignSelf};
  flex-wrap: ${(p: FlexBoxProps): FlexBoxProps['flexWrap'] =>
    p.flexWrap !== undefined ? p.flexWrap : cssDefaults.flexWrap};
  align-content: ${(p: FlexBoxProps): FlexBoxProps['alignContent'] =>
    p.alignContent !== undefined ? p.alignContent : cssDefaults.alignContent};
  position: ${(p: FlexBoxProps): FlexBoxProps['position'] =>
    p.position !== undefined ? p.position : cssDefaults.position};
  width: ${(p: FlexBoxProps): FlexBoxProps['width'] =>
    p.width !== undefined ? p.width : cssDefaults.width};
`;
FlexBox.displayName = 'FlexBox';

export { FlexBox };
