import { useCallback } from 'react';

import { useSectionResponsiveLayoutContext } from '../providers';

import { UseScaleToContextCallback } from './useScaleToScalingContext';

export const useScaleToSection = (): UseScaleToContextCallback => {
  const { min, max, base, current } = useSectionResponsiveLayoutContext();
  return useCallback(
    valueToScale => {
      if (typeof min !== 'undefined' && current < min) {
        return (min / base) * valueToScale;
      }
      if (typeof max !== 'undefined' && current > max) {
        return (max / base) * valueToScale;
      }

      return (current / base) * valueToScale;
    },
    [base, current, max, min]
  );
};
