import { APIResponse } from '@bighealth/api';
import { DeepWritable } from '@bighealth/types';

import { PredicateFn } from 'lib/deep-map-where';

import { transformQuestionFromMinutesToMilliseconds } from '../transformQuestionFromMinutesToMilliseconds';

type Result = DeepWritable<APIResponse>['result'];
/**
 * Transforms payloads as they are received by the client app
 *
 * Usually client code is expected to treat response payloads as read-only.
 *
 * But this special "middleware" code mimics backend logic
 * @see {@link https://expressjs.com/en/guide/using-middleware.html}
 *
 * It thus has a higher "permission" than normal client-app code,
 * which is why we use DeepWritable to allow changes to the payloads.
 *
 */
const transformResponseResult = (response: APIResponse): APIResponse => {
  const responseWritable = response as DeepWritable<APIResponse>;
  if (responseWritable.result === null) {
    return responseWritable as APIResponse;
  }

  const findQuestionsWithMinutes: PredicateFn = (_key, value): boolean => {
    /**
     * TODO Remove this (and all) terrible response transforms
     * WHEN A.S.A.P
     * WHO Probably Ash
     * https://bighealth.atlassian.net/browse/FEG-232
     * */
    if (typeof value === 'object') {
      if (
        value?.component === 'Dropdown' ||
        value?.component === 'SelectHorizontal'
      ) {
        if (value?.questionProps?.semantic_id?.endsWith('_total_time')) {
          return true;
        }
      }
    }
    return false;
  };

  responseWritable.result = transformQuestionFromMinutesToMilliseconds<Result>(
    responseWritable.result,
    findQuestionsWithMinutes
  );
  return responseWritable as APIResponse;
};

export { transformResponseResult };
