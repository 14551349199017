import { Animated, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'components/Text';

export const ClosedCaptionSelectStyled = styled(Animated.View)`
  position: absolute;
  bottom: 100%;
  right: -24px;
  background-color: ${props =>
    props.theme.content.controls.player.captionSelect.backgroundColor};
  border-radius: 9px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 25px;
`;
ClosedCaptionSelectStyled.displayName = 'ClosedCaptionSelectStyled';

export const CloseCaptionSelectOption = styled(TouchableOpacity)`
  color: ${props => props.theme.content.controls.player.captionSelect.color};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
CloseCaptionSelectOption.displayName = 'CloseCaptionSelectOption';

export const CloseCaptionSelectOptionText = styled(Text)<{ selected: boolean }>`
  font-weight: ${props =>
    props.theme.content.controls.player.smallTitleTextWeight};
  color: ${props =>
    props.selected
      ? props.theme.content.controls.player.captionSelect.color
      : props.theme.content.controls.player.captionSelect.colorDim};
  font-size: 16px;
  line-height: 19.2px;
  margin-left: ${props => (props.selected ? '10px' : '25px')};
`;
CloseCaptionSelectOptionText.displayName = 'CloseCaptionSelectOptionText';
