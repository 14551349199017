import { ButtonResourceEvent } from '@bighealth/analytics';

import {
  StreamTraceActions,
  StreamTraceEventTypes,
  StreamTraceResources,
} from 'components/constants';
import { getAnalyticsDate } from 'lib/api/analytics/getAnalyticsDate';
import { getUserAgent } from 'lib/api/analytics/getUserAgent';
import { ButtonPressEventParams } from 'lib/api/reactQueryHelpers/queryClientAnalogs/queryClientButtonClickEvent';

export const getButtonClickEventPayload = async ({
  fields,
  name,
  productId,
  programId,
  relations,
  url,
}: ButtonPressEventParams): Promise<ButtonResourceEvent> => {
  return {
    type: StreamTraceEventTypes.UI,
    action: StreamTraceActions.CLICKED,
    resource: StreamTraceResources.BUTTON,
    name,
    occurred_at: getAnalyticsDate(),
    product_id: productId,
    program_id: programId,
    body: {
      fields,
      relations,
      meta: {
        http: {
          current_url: url.href,
          referrer: url.href,
          user_agent: await getUserAgent(),
        },
      },
    },
  };
};
