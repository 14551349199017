import {
  DatesAndTimesValueObject,
  DateTimeValueObject,
  DateValueObject,
  TimeValueObject,
} from '@bighealth/types';

import { createValidDate } from 'lib/createValidDate';
import { getDateFromHoursMinutesSeconds } from 'lib/getDateFromHoursMinutesSeconds';
import { stringify } from 'lib/stringify';

const isDateTimeValueObject = (
  value: DatesAndTimesValueObject
): value is DateTimeValueObject =>
  (value as DateTimeValueObject).$datetime !== undefined;
const isDateValueObject = (
  value: DatesAndTimesValueObject
): value is DateValueObject => (value as DateValueObject).$date !== undefined;
const isTimeValueObject = (
  value: DatesAndTimesValueObject
): value is TimeValueObject => (value as TimeValueObject).$time !== undefined;

const toJavasScriptDate = (value: DatesAndTimesValueObject): Date | never => {
  if (isDateTimeValueObject(value)) {
    return createValidDate(value.$datetime);
  } else if (isDateValueObject(value)) {
    return createValidDate(value.$date);
  } else if (isTimeValueObject(value)) {
    return getDateFromHoursMinutesSeconds(value.$time, false);
  }
  throw TypeError(`Unknown dates value object: was expecting either
DateTimeValueObject { $datetime: string}, or
DateValueObject { $date: string }, or
TimeValueObject { $time: string}
Instead got "${stringify(value)}" (${typeof value})
`);
};
export { toJavasScriptDate };
