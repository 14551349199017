import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_entry_graph_and_scene_set_ids } from '@bighealth/api/Program';

export const useQueryGetEntryGraphAndSceneSetIds = (): UseQueryResult<
  get_entry_graph_and_scene_set_ids.Response,
  APIErrorResponse
> => {
  const result = useQuery<
    get_entry_graph_and_scene_set_ids.Response,
    APIErrorResponse
  >({
    queryKey: get_entry_graph_and_scene_set_ids.queryKey,
    queryFn: () => get_entry_graph_and_scene_set_ids(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
