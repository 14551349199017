import { View } from 'react-native';
import styled from 'styled-components/native';

import Image, { ResizeMode } from 'cross-platform/Image';

interface PracticeItemProps {
  sideAndBottomMargin: number;
  useBottomMargin: boolean;
  locked: boolean;
}
export const Wrapper = styled.View<PracticeItemProps>`
  margin-left: ${({ sideAndBottomMargin }) => sideAndBottomMargin}px;
  margin-right: ${({ sideAndBottomMargin }) => sideAndBottomMargin}px;
  margin-bottom: ${({ sideAndBottomMargin, useBottomMargin }) =>
    useBottomMargin ? sideAndBottomMargin : 0}px;
  opacity: ${({ locked }) => (locked ? 0.2 : 1)};
`;

export const HeaderWrapper = styled(View)<{ height: number }>`
  flex-direction: row;
  height: ${({ height }) => height}px;
  background-color: ${({ theme }) => theme.color.background.imageFallback};
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

export const BackgroundImage = styled(Image).attrs({
  fit: ResizeMode.cover,
})`
  width: 100%;
  height: 100%;
  position: absolute;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

export const PillWrapper = styled.View<{
  marginBottom: number;
  marginLeft: number;
}>`
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
`;

interface ContentWrapperProps {
  paddingVertical: number;
  paddingHorizontal: number;
  useTopBorderRadius: boolean;
}
export const ContentWrapper = styled(View)<ContentWrapperProps>`
  background-color: ${({ theme }) => theme.color.background.light};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  ${({ useTopBorderRadius }) =>
    useTopBorderRadius ? 'border-top-left-radius: 9px' : ''};
  ${({ useTopBorderRadius }) =>
    useTopBorderRadius ? 'border-top-right-radius: 9px' : ''};
  flex-direction: row;
  justify-content: center;
`;

interface ContentTextWrapperProps {
  paddingVertical: number;
  marginRight: number;
}
export const ContentTextWrapper = styled.View<ContentTextWrapperProps>`
  flex: 1;
  padding-top: ${({ paddingVertical }) => paddingVertical}px;
  padding-bottom: ${({ paddingVertical }) => paddingVertical}px;
  margin-right: ${({ marginRight }) => marginRight}px;
`;

export const ButtonWrapper = styled.View`
  justify-content: center;
`;
