import { useMemo } from 'react';

import { Question } from '@bighealth/types';

import { getStateKeyFromIdentifiers } from 'components/EphemeralStateProvider';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';

const useQuestionPath = (questionId: number): string => {
  const { sceneSetGraphId, sceneSetId } = useSafeParams<SceneSetParams>();

  return getStateKeyFromIdentifiers({
    sceneSetGraphId,
    sceneSetId,
    questionId,
  });
};

type Store = [number, string][];

const useQuestionPaths = (questions: Question[]): Store => {
  const { sceneSetGraphId, sceneSetId } = useSafeParams<SceneSetParams>();

  return useMemo(() => {
    const store: Store = [];
    for (const question of questions) {
      const key = getStateKeyFromIdentifiers({
        sceneSetGraphId,
        sceneSetId,
        questionId: question.id as number,
      });
      store.push([question.id as number, key]);
    }
    return store;
  }, [questions, sceneSetGraphId, sceneSetId]);
};

export { useQuestionPath, useQuestionPaths };
