// NOTE: This file is only for 📖 Storybook & ✅ Tests
import React from 'react';
import { Button, Text } from 'react-native';

import { FillAbsolute } from 'components/Containers';
import { roles } from 'cross-platform/utils/roleProps';

const FauxErrorModal = (props: {
  heading?: string;
  message?: string;
  error?: Error;
  onClose: () => void;
}): JSX.Element | null =>
  props.error ? (
    <FillAbsolute style={{ backgroundColor: 'salmon' }}>
      <Text>{props.heading}</Text>
      <Text>{props.message}</Text>
      <Text>{`Error: ${props.error}`}</Text>
      <Button
        {...roles('ErrorModal.onClose')}
        onPress={props.onClose}
        title="onClose"
      />
    </FillAbsolute>
  ) : null;

export { FauxErrorModal };
