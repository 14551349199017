import { Response as QuestionResponse } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const create_response_for_question = async (
  args: create_response_for_question.Args,
  requestConfig?: AxiosRequestConfig
): Promise<create_response_for_question.Response> => {
  const request: create_response_for_question.Request = {
    service_name: 'Response',
    service_version: '2',
    service_method: 'create_response_for_question',
    args,
  };

  return await api(request, requestConfig);
};

create_response_for_question.queryKey = 'Response/create_response_for_question';

export declare namespace create_response_for_question {
  export type Args = QuestionResponse;

  export interface Request extends APIRequestBody {
    service_name: 'Response';
    service_version: '2';
    service_method: 'create_response_for_question';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
