import React from 'react';

import { InfoCard } from '@bighealth/types';

import { ProductReferences } from 'common/constants/enums';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { roles } from 'cross-platform/utils/roleProps';
import { CardCurlGraphic } from 'daylight/components/icons';
import { useTransformContentStyle } from 'daylight/hooks';

import {
  CardText,
  CardWrapper,
  ImageView,
  InfoCardWrapper,
  TextView,
} from './styles';

export const PracticeLibraryInfoCard = ({
  info,
}: {
  info: InfoCard;
}): JSX.Element => {
  const theme = getThemeForProduct(ProductReferences.DAYLIGHT);
  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();

  const { header, body } = info;

  return (
    <CardWrapper>
      <InfoCardWrapper
        {...roles('PracticeLibraryInfoCard')}
        style={{ minHeight: transformStyle(230) }}
      >
        <ImageView {...roles('CardCurlGraphic')}>
          <CardCurlGraphic />
        </ImageView>
        <TextView>
          <CardText
            // always defined for Daylight
            fontSize={transformStyle(theme.text.DEFAULT.fontSize ?? 18)}
            bold={true}
            style={{
              marginTop: transformVerticalStyle(4),
              marginBottom: transformVerticalStyle(4),
            }}
          >
            {header?.text ?? ''}
          </CardText>
          <CardText
            fontSize={transformStyle(
              // always defined for Daylight
              theme.controls.textArea.textInputStyles.fontSize ?? 16
            )}
            lineHeight={transformStyle(24)}
            style={{
              marginTop: transformVerticalStyle(4),
              marginBottom: transformVerticalStyle(4),
            }}
          >
            {body?.text ?? ''}
          </CardText>
        </TextView>
      </InfoCardWrapper>
    </CardWrapper>
  );
};
