import { Dispatch, SetStateAction } from 'react';

import { Selected } from '../useItemListCarousel';

const useNavigation = (
  selected: Selected,
  setSelected: Dispatch<SetStateAction<Selected>>,
  scrollBehaviorIndicies: { maxIndex: number; index: number }
): {
  back: typeof back;
  forward: typeof forward;
  selected: typeof selected;
} => {
  const backIndex = Math.max(0, selected.index - 1);
  const back = {
    onPress: () => setSelected({ ...selected, index: backIndex, dir: -1 }),
    disabled: 0 === selected.index,
  };
  const forwardIndex = Math.min(
    scrollBehaviorIndicies.maxIndex,
    selected.index + 1
  );
  const forward = {
    onPress: () => setSelected({ ...selected, index: forwardIndex, dir: 1 }),
    disabled: scrollBehaviorIndicies.maxIndex === selected.index,
  };
  return {
    back,
    forward,
    selected,
  };
};

export { useNavigation };
