import React from 'react';
import { StyleProp, Text } from 'react-native';

import { addStory } from 'lib/story-books';

import { Col } from './Col';
import { Row } from './Row';
import { Grid } from '.';

// IDEA: Make generic for general-use
type DebugStyleType = {
  backgroundColor: string;
};
const bg = ([
  backgroundColor,
]: TemplateStringsArray): StyleProp<DebugStyleType> => ({
  backgroundColor,
});

addStory('grid-row-column', () => (
  <Grid style={bg`red`}>
    <Row flexBasis={'auto'}>
      <Text>Row 1</Text>
    </Row>
    <Row style={bg`#aaa`}>
      <Col style={bg`#99FF99`}>
        <Text>Row 2 Column 1</Text>
      </Col>
      <Col style={bg`#FF9999`} flexGrow={1}>
        <Text>Row 2 Column 2</Text>
      </Col>
    </Row>
  </Grid>
));
