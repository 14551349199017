import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { ThemeContext } from 'styled-components/native';

import { FillAbsolute } from 'components/Containers';
import { cancelNotificationsByConfigId } from 'components/LocalNotifications/localNotificationsState';
import { useBootstrapNotificationConfiguration } from 'components/LocalNotifications/localNotificationsState/hooks';
import { useRefreshNotifications } from 'components/LocalNotifications/NotificationsView/hooks/useRefreshNotifications';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
// TODO: move the Text from the sceneset-components to the components folder as a shared folder
// WHEN: during Daylight Rebuild
import { Text } from 'components/sceneset-components/Text';
import { DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID } from 'config/localNotifications/daylightConfig';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { ActionButton, Pill } from 'daylight/components';
import { GlobalNavigationState } from 'daylight/components/higherOrderComponents';
import { SettingsIconType } from 'daylight/components/higherOrderComponents/WithSettings/WithSettings';
import { PracticeLibraryIcon } from 'daylight/components/icons';
import {
  Ghost,
  SSGPlayButton,
} from 'daylight/components/screens/TodayScreen/components';
import { useTransformContentStyle } from 'daylight/hooks';
import {
  nonSceneSetAssetsStatus,
  useLazyQueryDownloadNonSceneSetAssets,
} from 'lib/api/reactQueryHelpers';
import { useMutationDailyReminderPrompted } from 'lib/api/reactQueryHelpers/useMutationAnalogs/useMutationSettings';
import { useQueryDaylightHomeScreenData } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQueryDaylightHomeScreenData';
import { noOpFunction } from 'lib/noOpFunction';
import { getScreenReference } from 'state/debug/selectors';
import {
  setHasDefaultNotificationModalPopUpBeenPrompted,
  setNotificationFeatureFlag,
} from 'state/notifications/actions';
import {
  getHasDefaultNotificationModalPopUpBeenPrompted,
  getOsNotificationPermissionsRequestCount,
} from 'state/notifications/selectors';

import { ErrorScreen } from '../ErrorScreen/ErrorScreen';

import {
  BackgroundImage,
  ColumnContainer,
  ContainerContent,
  ContainerNextSSGInfo,
  ContainerPlayButton,
  ContainerPracticeLibraryButton,
  ContainerText,
  RawContainer,
} from './TodayScreen.styled';

type TodayScreenProps = GlobalNavigationState & {
  settingsIcon: SettingsIconType | undefined;
  setSettingsIcon: (settingsIcon: SettingsIconType) => void;
};

export const TodayScreen = ({
  settingsIcon,
  setSettingsIcon: setSettingsIconFromHoc,
  setShowLoadingOverlay: setShowLoadingOverlayFromHoc,
}: TodayScreenProps): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [screenRef, setScreenRef] = useState<string | undefined>(undefined);
  const screenReference = useSelector(getScreenReference);

  const homeScreenResponse = useQueryDaylightHomeScreenData(screenReference);
  const dailyReminderPromptMutation = useMutationDailyReminderPrompted();

  const hasDefaultNotificationModalPopUpBeenPrompted = useSelector(
    getHasDefaultNotificationModalPopUpBeenPrompted
  );

  useEffect(() => {
    if (screenRef !== screenReference) {
      homeScreenResponse.refetch();
      setScreenRef(screenReference);
    }
  }, [homeScreenResponse, screenRef, screenReference]);
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);

  useEffect(() => {
    // if error or not defined, set to false
    const ffNotificationsOnFromBe =
      homeScreenResponse.data?.result?.is_daily_reminder_feature_ready ?? false;

    // set in redux for full app to respond to
    dispatch(setNotificationFeatureFlag(ffNotificationsOnFromBe));

    // only clear all notifs if the response has come back successfully
    if (!!homeScreenResponse && ffNotificationsOnFromBe === false) {
      // this method is already wrapped in async try/catch
      cancelNotificationsByConfigId(
        DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID
      );
    }
  }, [dispatch, homeScreenResponse]);

  useEffect(() => {
    if (
      homeScreenResponse.data?.result?.should_prompt_for_daily_reminder &&
      !hasDefaultNotificationModalPopUpBeenPrompted &&
      homeScreenResponse.data?.result?.is_daily_reminder_feature_ready
    ) {
      dailyReminderPromptMutation.mutate(undefined);
      dispatch(setHasDefaultNotificationModalPopUpBeenPrompted());
      history.push('/daylight/settings/notifications');
    }
  }, [
    history,
    dailyReminderPromptMutation,
    homeScreenResponse.data,
    hasDefaultNotificationModalPopUpBeenPrompted,
    dispatch,
  ]);

  // NOTE: we have chosen to not update the state when completed because if the user
  // changes the tab the state will be updated in an unmounted component.
  // IDEA: we can also move this hook in a "higher leverl, more global component",
  // where there is no risk to update the status of an unmounted component.
  const { status, downloadAssets } = useLazyQueryDownloadNonSceneSetAssets(
    false
  );

  // feature flagging happens within the custom effect
  useBootstrapNotificationConfiguration(
    dispatch,
    DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID
  );
  // feature flagging happens within the custom effect
  const osNotificationPermissionRequestCount = useSelector(
    getOsNotificationPermissionsRequestCount
  );
  useRefreshNotifications({
    osNotificationPermissionRequestCount,
    notificationGroupConfigId: DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID,
  });

  useEffect(() => {
    // we download and cache the assets for the coming exercise.
    if (
      status === nonSceneSetAssetsStatus.IDLE &&
      typeof homeScreenResponse.data?.result.downloadable_assets !==
        'undefined' &&
      typeof settingsIcon !== 'undefined'
    ) {
      downloadAssets(homeScreenResponse.data?.result.downloadable_assets);
    }
  }, [
    downloadAssets,
    homeScreenResponse.data,
    homeScreenResponse.status,
    settingsIcon,
    status,
  ]);

  useEffect(() => {
    setShowLoadingOverlayFromHoc(
      homeScreenResponse.status === 'idle' ||
        homeScreenResponse.status === 'loading' ||
        !backgroundImageLoaded
    );
  }, [
    backgroundImageLoaded,
    homeScreenResponse.status,
    setShowLoadingOverlayFromHoc,
  ]);

  useEffect(() => {
    if (homeScreenResponse.data?.result) {
      setSettingsIconFromHoc(homeScreenResponse.data.result.settings_icon);
    }
  }, [homeScreenResponse.data, setSettingsIconFromHoc]);

  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();

  if (homeScreenResponse.status === 'error') {
    return <ErrorScreen message="Could not fetch home screen data" />;
  }

  if (homeScreenResponse.status !== 'success') {
    return <Ghost />;
  }

  const homeScreenData = homeScreenResponse.data.result;

  const srcBackgroundImage =
    homeScreenData.background_image.sourceProps.storage_url;

  // there is always at least one button in the payload
  let buttonPracticeLibraryProps = homeScreenData.buttons[0];
  let buttonPlayProps = undefined;
  let practiceLibraryButtonStyle = undefined;
  let practiceButtonFontSize = undefined;

  if (homeScreenData.buttons.length > 1) {
    buttonPlayProps = homeScreenData.buttons[0];
    buttonPracticeLibraryProps = homeScreenData.buttons[1];
    practiceLibraryButtonStyle = buttonPracticeLibraryProps.style;

    if (
      practiceLibraryButtonStyle?.size &&
      practiceLibraryButtonStyle?.size > 0
    ) {
      practiceButtonFontSize = buttonPracticeLibraryProps.style?.size;
    }
  }

  const isPlayButtonDisplayed = typeof buttonPlayProps !== 'undefined';
  const areLabelsPresent = homeScreenData.labels.length !== 0;

  if (!isPlayButtonDisplayed) {
    homeScreenData.title = {
      ...homeScreenData.title,
      text: homeScreenData.title.text?.replace(/, /g, ',\n'),
    };
  }

  return (
    <FillAbsolute
      {...roles('homeScreen')}
      style={{ backgroundColor: '#FFFFFF' }}
    >
      <ContainerContent {...roles('containerContent')}>
        <BackgroundImage
          {...roles('backgroundImage')}
          src={srcBackgroundImage}
          onLoad={() => setBackgroundImageLoaded(true)}
        />

        <ColumnContainer>
          <ContainerNextSSGInfo
            style={{
              paddingHorizontal: '5%',
              height: '20%',
              justifyContent: 'flex-end',
            }}
          >
            {areLabelsPresent ? (
              <RawContainer
                style={{
                  paddingBottom: transformVerticalStyle(8),
                }}
                {...roles('payloadLabels')}
              >
                {homeScreenData.labels.map((label, index) => (
                  <Pill key={index} textComponent={label} />
                ))}
              </RawContainer>
            ) : null}

            <RawContainer style={{ paddingBottom: 32 }}>
              <RawContainer
                style={{
                  flexDirection: 'row',
                }}
              >
                <ContainerText isNotFullWidth={isPlayButtonDisplayed}>
                  <Text
                    {...homeScreenData.title}
                    style={{
                      fontWeight: 700,
                      fontSize: 28,
                      color: theme.color.primaryGrey,
                    }}
                  />
                  <Text
                    {...homeScreenData.subtitle}
                    style={{
                      fontWeight: 500,
                      fontSize: 18,
                      paddingTop: 8,
                      color: theme.color.primaryGrey,
                    }}
                  />
                </ContainerText>
                {buttonPlayProps ? (
                  <ContainerPlayButton>
                    <SSGPlayButton
                      props={buttonPlayProps}
                      width={transformStyle(buttonPlayProps.style?.width || 56)}
                      height={transformStyle(
                        buttonPlayProps.style?.height || 56
                      )}
                      iconColor={buttonPlayProps.iconColor || '#FFFFFF'}
                    />
                  </ContainerPlayButton>
                ) : null}
              </RawContainer>
            </RawContainer>
          </ContainerNextSSGInfo>
        </ColumnContainer>
      </ContainerContent>
      <ContainerPracticeLibraryButton
        {...roles('containerPracticeLibraryButton')}
      >
        {/* Formik must be present because of the useActionHandler hook in the button */}
        <Formik initialValues={[]} onSubmit={noOpFunction}>
          <ActionButton
            {...buttonPracticeLibraryProps}
            style={{
              ...(Array.isArray(buttonPracticeLibraryProps.style)
                ? StyleSheet.flatten(buttonPracticeLibraryProps.style)
                : buttonPracticeLibraryProps.style),
              paddingVertical: transformVerticalStyle(16),
              marginHorizontal: '5%',
              backgroundColor:
                buttonPracticeLibraryProps.style?.backgroundColor || '#111111',
            }}
            onPress={noOpFunction}
            isDisabled={false}
            color={buttonPracticeLibraryProps.textColor || '#FFFFFF'}
            fontSize={transformStyle(practiceButtonFontSize || 18)}
            fontWeight={500}
            lineHeight={transformStyle(24)}
            icon={
              <View style={{ marginRight: 14 }}>
                <PracticeLibraryIcon
                  size={17}
                  color={buttonPracticeLibraryProps.textColor || '#FFFFFF'}
                />
              </View>
            }
          />
        </Formik>
      </ContainerPracticeLibraryButton>
    </FillAbsolute>
  );
};
