import { DAY, HOUR, MINUTE, SECOND } from 'lib/durations';

type Options = {
  interval: number;
  min?: Date; // in Locale time
  max?: Date; // in Locale time
};

/**
 * WARNING Should not convert min/max `response_option` to UTC,
 * as this is now out of whack with min/max in `validation_schema`
 *
 * TODO Use locale dates for these calculating range
 * WHY transformation is error-prone and cases divergence
 * WHO Ash
 */
const createUTCFromLocale = (date: Date) =>
  new Date(
    date.getHours() * HOUR +
      date.getMinutes() * MINUTE +
      date.getSeconds() * SECOND +
      date.getMilliseconds()
  );

/**
 *
 */
const getMinAndMaxMilliseconds = (
  options: Options
): { minNum: number; maxNum: number } => {
  const min = options?.min
    ? createUTCFromLocale(options.min) // get time
    : new Date(0);
  const maxRange = DAY - options.interval;
  const minNum = min.valueOf();
  const max = options?.max
    ? createUTCFromLocale(options.max) // get time
    : new Date(minNum + maxRange);

  let maxNum = max.valueOf();
  if (maxNum <= minNum) {
    maxNum += DAY;
  }
  if (maxNum - minNum > maxRange) {
    maxNum = minNum + maxRange;
  }
  return { minNum, maxNum };
};

/**
 *
 */
const createDates = ({
  offsetFromUTC,
  interval,
  minNum,
  maxNum,
}: {
  offsetFromUTC: number;
  interval: number;
  minNum: number;
  maxNum: number;
}): Date[] => {
  const entries = Math.ceil(maxNum / interval);
  if (entries < 0) {
    throw Error(`${new Date(maxNum)} ${maxNum} / ${interval}`);
  }
  return [...Array<number>(entries + 1)]
    .map((_, index) => index * interval) // increment by position
    .filter(stepMs => stepMs >= minNum)
    .filter(stepMs => stepMs <= maxNum)
    .map(stepMs => new Date(stepMs + offsetFromUTC));
};

/**
 *
 */
const deriveTimeResponseOptions = (options: Options): Date[] => {
  const offsetFromUTC = new Date(0).getTimezoneOffset() * MINUTE;
  const { minNum, maxNum } = getMinAndMaxMilliseconds(options);
  return createDates({
    offsetFromUTC,
    interval: options.interval,
    minNum,
    maxNum,
  });
};

export const forTestsOnly = { createDates, getMinAndMaxMilliseconds };

export { deriveTimeResponseOptions };
