import * as React from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { ButtonContainerFixedWidthScalable } from 'components/Button/components';
import { useNavigationBarContext } from 'components/Navigation/providers/NavigationBarProvider';
import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import {
  useScreenResponsiveLayoutContext,
  useTransformContentStyle,
  useTransformContentStyleVertical,
} from 'components/ResponsiveLayout';
import { isMobileWeb } from 'config/isMobileWeb';
import { useABTestVariants } from 'lib/ab-testing/useABTestVariants';
import { isLandscape } from 'lib/isLandscape';

import { Button, ButtonRow, Grid, HeadingRow, HeadingText } from './styled';

export type SessionStatusProps = {
  heading: React.ComponentProps<typeof HeadingText>;
  buttons: React.ComponentProps<typeof Button>[];
  isProgressReviewWidgetVisible?: boolean;
};

/**
 * The "hero" panel of the Session Progress screen
 *
 * @param {SessionStatusProps} props
 */
const SessionStatus = ({
  heading,
  buttons,
  isProgressReviewWidgetVisible,
}: SessionStatusProps): React.ReactElement => {
  const edgeInsets = useSafeArea();
  const abTestVariants = useABTestVariants();
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);
  const { isCollapsed } = useNavigationBarContext();
  const { screenWidth } = useScreenResponsiveLayoutContext();
  const screenOrientation = useScreenOrientation();

  return (
    <Grid scaleYValue={scaleYValue}>
      <HeadingRow>
        <HeadingText
          edgeInsets={edgeInsets}
          scaleValue={scaleValue}
          scaleYValue={scaleYValue}
          isMobileWeb={isMobileWeb}
          isCollapsed={isCollapsed(screenWidth)}
          isLandscape={isLandscape(screenOrientation)}
          abTestVariants={abTestVariants}
          isProgressReviewWidgetVisible={isProgressReviewWidgetVisible}
          {...heading}
        />
      </HeadingRow>
      <ButtonRow
        isMobileWeb={isMobileWeb}
        noButtons={buttons.length === 0}
        isLandscape={isLandscape(screenOrientation)}
      >
        {buttons.map((button, i) => (
          <ButtonContainerFixedWidthScalable
            key={`${i}`}
            style={{
              marginLeft: 25 / 2,
              marginRight: 25 / 2,
            }}
            scaleYValue={scaleYValue}
          >
            <Button
              isMobileWeb={isMobileWeb}
              scaleValue={scaleValue}
              scaleYValue={scaleYValue}
              abTestVariants={abTestVariants}
              {...button}
            />
          </ButtonContainerFixedWidthScalable>
        ))}
      </ButtonRow>
    </Grid>
  );
};

export { SessionStatus };
