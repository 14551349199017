import React from 'react';

import { ItemCarouselTools } from '../../useItemCarouselTools/useItemCarouselTools';
import { Selected } from '../useItemListCarousel';

type CreateScrollTo = (
  tools: ItemCarouselTools
) =>
  | {
      x?: undefined;
      animated?: undefined;
    }
  | {
      x: number;
      animated: boolean;
    };

type GetIndexAndMaxIndex = (
  tools: ItemCarouselTools,
  selected: Selected,
  targetCount: number
) => {
  index: number;
  maxIndex: number;
};

export const OFFSET_RHS = 1;

const getWidths = (
  { scrollerWrapperApi, itemListApi, targetItemApi }: ItemCarouselTools,
  selectedIndex: number,
  targetCount: number
) => {
  const itemList = itemListApi?.measurement?.width || 0;
  const scrollerWrapper = scrollerWrapperApi.measurement?.width || 0;
  const target = targetItemApi.measurement?.width || 0;
  const targetsPerScreen = scrollerWrapper / target;
  const offset = scrollerWrapper > itemList ? 0 : OFFSET_RHS;
  const maxIndex = Math.ceil(targetCount / targetsPerScreen) + offset;
  const index = Math.min(selectedIndex, maxIndex);
  return {
    itemList,
    scrollerWrapper,
    target,
    targetsPerScreen,
    maxIndex,
    index,
  };
};
const useScrollByItem = (
  selected: Selected
): {
  createScrollTo: CreateScrollTo;
  getIndexAndMaxIndex: GetIndexAndMaxIndex;
} => {
  /**
   *
   * @param selected
   * @returns
   */
  const createScrollTo = React.useCallback(
    (tools: ItemCarouselTools) => {
      const scrollerWrapperWidth = tools.scrollerWrapperApi?.measurement?.width;
      if (typeof scrollerWrapperWidth === 'undefined') {
        return {};
      }
      const w = getWidths(tools, selected.index, selected.itemsCount);
      const itemW = w.itemList / selected.itemsCount;
      const targetX = selected.index * itemW;
      if (w.index == w.maxIndex) {
        return {
          x: targetX + itemW /* right edge */,
          animated: true,
        };
      }
      return {
        x: targetX,
        animated: true,
      };
    },
    [selected]
  );
  /**
   *
   * @param tools
   * @param selected
   * @param targetCount
   * @returns
   */
  const getIndexAndMaxIndex = (
    tools: ItemCarouselTools,
    selected: Selected,
    targetCount: number
  ) => {
    const w = getWidths(tools, selected.index, targetCount);
    if (!(w.scrollerWrapper > 0 && w.target > 0 && w.itemList > 0)) {
      return { index: 0, maxIndex: 0 };
    }
    return { index: w.index, maxIndex: w.maxIndex };
  };
  /**
   *
   */
  return { createScrollTo, getIndexAndMaxIndex };
};

export { useScrollByItem };
