import { format } from 'date-fns';

import { book_next_session } from '@bighealth/api/SessionProgress/v1';

import { bookNextSession } from 'lib/api';
import * as reporter from 'lib/reporter';

const ERROR_MESSAGE =
  'There was an error in scheduling your session reminder. Please continue without reminder.';

async function submitScheduleNextSession({
  productId,
  nextSessionDate,
  userTimezone,
}: {
  productId: number;
  nextSessionDate: Date;
  userTimezone: string;
}): Promise<[book_next_session.Result | null, Error | null]> {
  try {
    const response = await bookNextSession({
      productId: productId,
      nextSessionDateStr: format(nextSessionDate, 'yyyy-MM-dd HH:mm:SS'),
      userTimezone: userTimezone,
    });
    if (response.status_code !== 200) {
      reporter.log(ERROR_MESSAGE);
      return [null, new Error(ERROR_MESSAGE)];
    }
    return [response.result, null];
  } catch (e) {
    reporter.log(ERROR_MESSAGE, e);
    return [null, e];
  }
}

export { submitScheduleNextSession };
