import { instead } from 'lib/error/utils/instead';
import { throwIf } from 'lib/error/utils/throwIf';

export const getOptions = ({
  all,
  slice,
}: {
  all: Date[];
  slice?: {
    visibleCount: number;
    cursor: number;
  };
}): [Date[], Date[], Date[]] => {
  if (slice) {
    const cursor = Math.max(0, slice.cursor);
    throwIf(
      cursor >= all.length,
      `cursor less than all (${all.length}), ${instead(cursor)}`
    );
    return [
      all.slice(0, cursor),
      all.slice(cursor, cursor + slice.visibleCount),
      all.slice(cursor + slice.visibleCount),
    ];
  }
  return [[], all, []];
};
