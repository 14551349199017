import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { isResultMobileWeb } from 'lib/utils/guards/isResultMobileWeb';

export const getHasSessionAvailable = (
  result?: get_user_session_screen_data.Result
): boolean => {
  if (typeof result === 'undefined' || isResultMobileWeb(result)) {
    return false;
  }

  return (
    result.has_new_session_available || result.has_in_progress_session_available
  );
};
