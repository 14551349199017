import React from 'react';
import { ScrollView } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { isMobileWeb } from 'config/isMobileWeb';

import { Card as CardUnstyled } from '../components/Card';
import { ScrollViewWrapper } from '../components/ScrollViewWrapper';
import { DOMAIN } from '../constants';

import { ScrollRowInternal } from './styled.platform';
import { ScrollRowProps } from './types';

export const Wrapper = styled.ScrollView`
  width: 0px;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

// Apparently styled.ScrollView has props of type `boolean | null | undefined`,
// whilst react-native's ScrollView prosp of type `true | undefined`
export const ScrollRow = React.forwardRef<ScrollView, ScrollRowProps>(
  (props, ref) => {
    const edgeInsets = useSafeArea();
    return (
      <ScrollViewWrapper>
        <ScrollRowInternal
          {...props}
          ref={ref}
          isMobileWeb={isMobileWeb}
          edgeInsets={edgeInsets}
        />
      </ScrollViewWrapper>
    );
  }
);
ScrollRow.displayName = 'ScrollRow';

export const Card = styled(CardUnstyled)``;
Card.displayName = `${DOMAIN}.Card`;
