import { DomainIDs } from 'common/constants/enums';

import { GlobalState } from '../store/initialState';

import { CaptionState, PlaybackState, PlayerStatus } from './reducer';

export const getPlayerStatus = (state: GlobalState): PlayerStatus =>
  state[DomainIDs.PLAYER].status;

export const getControlsAreVisible = (state: GlobalState): boolean =>
  state[DomainIDs.PLAYER].controlsAreVisible;

export const getControlsCloseButtonIsVisible = (state: GlobalState): boolean =>
  state[DomainIDs.PLAYER].controlsCloseButtonIsVisible;

export const getPlayerState = (state: GlobalState): PlaybackState =>
  state[DomainIDs.PLAYER].playerState;

export const getSceneSetRenderKey = (state: GlobalState): number =>
  state[DomainIDs.PLAYER].sceneSetRenderKey;

export const getCaptionState = (state: GlobalState): CaptionState =>
  state[DomainIDs.PLAYER].captionState;

export const getCaptionLanguage = (state: GlobalState): string =>
  state[DomainIDs.PLAYER].captionLanguage;
