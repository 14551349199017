import { Value } from 'components/forms/types';
import { isDate } from 'lib/isDate';
import { isValidDate } from 'lib/isValidDate';

const getValidatedDateValue = (value: Value): Date | null => {
  if (isDate(value) && isValidDate(value)) {
    return value;
  }
  throw Error(`Expected valid date, instead got "${value}" (${typeof value})`);
};

export { getValidatedDateValue };
