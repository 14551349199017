import React, { ReactElement } from 'react';
import Svg, { Rect } from 'react-native-svg';

/**
 * Books that are used to represent the Practice Library
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const PracticeLibraryIcon = ({
  size,
  color,
}: {
  size: number;
  color: string;
  onPress?: () => void;
}): ReactElement => {
  return (
    <Svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 18 19"
      fill="none"
    >
      <Rect
        x="5.79926"
        y="0.864136"
        width="3.86621"
        height="17.2076"
        rx="1"
        fill={color}
      />
      <Rect y="5.16602" width="3.86615" height="12.9057" rx="1" fill={color} />
      <Rect
        width="3.8458"
        height="12.9909"
        rx="1"
        transform="matrix(0.979529 -0.201303 0.251049 0.967974 10.9713 5.6217)"
        fill={color}
      />
    </Svg>
  );
};
