export const NavBarStyles = {
  navBarHeightPx: 58,
  navBarMenuVerticalOffsetPx: 20,
  navBarCollapseTabletWebBreakPointPx: 1240,
  navBarCollapseMobileWebBreakPointPx: 1000,
  navBarHeightTransparentBreakPointPx: 450,
  navBarZIndex: 999,
  menuZIndex: 1000,
  navBarPadding: 32,
};
