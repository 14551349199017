import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ProductReferences } from 'common/constants/enums';
import { Footer } from 'components/Footer';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import {
  MediaContainerScalingBehavior,
  MediaContainerScalingBehaviorProvider,
} from 'components/ResponsiveLayout/providers/MediaContainerScalingBehaviorProvider';
import SceneSetView from 'components/SceneSetView';
import { DefaultOrientation } from 'components/SceneSetView/types';
import { Container } from 'components/Screens/ContentScreens/components/ContentPlaceholder';
import { ForQATeamSSGbySS } from 'components/Screens/ForQATeamSSGbySS';
import { LoginSplashScreen } from 'components/Screens/LoginSplashScreen/LoginSplashScreen';
import { getAssetsForProduct } from 'config/getAssetsForProduct';
import { getStringsForProduct } from 'config/getStringsForProduct/getStringsForProduct';
import { DaylightThrobber } from 'daylight/components';
import { WithGlobalNavigation } from 'daylight/components/higherOrderComponents/WithGlobalNavigation/WithGlobalNavigation';
import {
  ForgotPasswordScreen,
  LoginScreen,
  PracticeLibraryScreen,
  ProgressScreen,
} from 'daylight/components/screens';
import {
  AboutDaylightView,
  ContactUsView,
  EmergencySupportView,
  MFAManagementView,
  NotificationsView,
} from 'daylight/components/screens/SettingsScreen/components';
import { TodayScreenWithSettings } from 'daylight/components/screens/TodayScreen/TodayScreenWithSettings';
import { useRedirectIsAuthenticated } from 'lib/api/hooks/useRedirectIsAuthenticated';
import { DefaultErrorFallback } from 'lib/error/ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import { createErrorBoundaryForDomain } from 'lib/error/utils/createErrorBoundaryForDomain';
import { useInitMessaging } from 'lib/messaging';

import { MonitorView } from '../../MonitorView';
import { useAppOpenedFromNotification } from '../hooks/useAppOpenedFromNotification/useAppOpenedFromNotification';
import { StoryBook } from '../StoryBook';

const ErrorBoundaryForDaylightSwitch = createErrorBoundaryForDomain(
  'DaylightSwitch'
);
const strings = getStringsForProduct(ProductReferences.DAYLIGHT);
const assets = getAssetsForProduct(ProductReferences.DAYLIGHT);

export const DaylightSwitch = (): ReactElement => {
  // Must be used so we redirect from the default landing (/daylight) to home or login
  useRedirectIsAuthenticated(true);
  useAppOpenedFromNotification();
  useInitMessaging();
  return (
    <ErrorBoundaryForDaylightSwitch
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
    >
      <MediaContainerScalingBehaviorProvider
        behavior={MediaContainerScalingBehavior.COVER}
      >
        <Switch>
          <Route path={'/:productReference'} exact>
            <LoadingOverlay />
          </Route>

          <Route path={'/:productReference/login'} exact>
            <MonitorView viewName="LoginSplashScreen">
              <LoginSplashScreen
                website={strings.website}
                byLine={strings.LoginSplashScreen.byLine}
                noAccount={strings.LoginSplashScreen.noAccount}
                logoAsset={assets.splashLogoUrl}
                splashScreenAsset={assets.splashScreenStaticUrl}
              />
            </MonitorView>
          </Route>

          <Route path={'/:productReference/login-screen'} exact>
            <MonitorView viewName="LoginScreen">
              <LoginScreen />
            </MonitorView>
          </Route>
          {/**
           * Looking for <Route path={'/:productReference/login-with-token'} />?
           *
           * login-with-token is one of the FEW (only?) endpoints
           * that are not served by this Single Page Application
           *
           * @see https://github.com/search?q=org%3Asleepio%20login-with-token&type=code
           */}

          <Route path={'/:productReference/forgot-password'} exact>
            <MonitorView viewName="ForgotPasswordScreen">
              <ForgotPasswordScreen />
            </MonitorView>
          </Route>

          <Route path={'/:productReference/home'} exact>
            <MonitorView viewName="TodayScreenWithSettings">
              <TodayScreenWithSettings />
            </MonitorView>
          </Route>

          <Route path={'/:productReference/progress'} exact>
            <WithGlobalNavigation>
              {navState => (
                <MonitorView viewName="ProgressScreen">
                  <ProgressScreen {...navState} />
                </MonitorView>
              )}
            </WithGlobalNavigation>
          </Route>

          <Route path={'/:productReference/practice-library'} exact>
            <MonitorView viewName="PracticeLibraryScreen">
              <PracticeLibraryScreen />
            </MonitorView>
          </Route>

          <Route path={'/:productReference/settings/emergency-support'} exact>
            <MonitorView viewName="EmergencySupportView">
              <EmergencySupportView />
            </MonitorView>
          </Route>

          <Route path={'/:productReference/settings/contact-us'} exact>
            <MonitorView viewName="ContactUsView">
              <ContactUsView />
            </MonitorView>
          </Route>

          {/* Push Notifications have been descoped from the initial release of Daylight Rebuild */}
          <Route path={'/:productReference/settings/notifications'} exact>
            <MonitorView viewName="NotificationsView">
              <NotificationsView />
            </MonitorView>
          </Route>

          <Route path={'/:productReference/settings/about-daylight'} exact>
            <MonitorView viewName="AboutDaylightView">
              <AboutDaylightView />
            </MonitorView>
          </Route>

          <Route path={'/:productReference/settings/mfa/:status'} exact>
            <MonitorView viewName="MFAManagementView">
              <MFAManagementView />
            </MonitorView>
          </Route>

          <Route
            path={'/:productReference/888888/storybook'}
            component={StoryBook}
            exact
          />

          <Route path={'/:productReference/:sceneSetGraphId/:sceneSetId'} exact>
            <MonitorView viewName="SceneSetView">
              <SceneSetView defaultOrientation={DefaultOrientation.PORTRAIT} />
            </MonitorView>
          </Route>

          <Route
            path={'/:productReference/888888/storybook/:story(.*)'}
            component={StoryBook}
          />
          <Route path={'/:productReference/qa/go/ssg-ss'} exact>
            <WithGlobalNavigation>
              <ForQATeamSSGbySS />
            </WithGlobalNavigation>
          </Route>

          <Route
            path={
              '/:productReference/:sceneSetGraphId/:sceneSetReference/:sceneId'
            }
            exact
          >
            <MonitorView viewName="SceneSetViewWithScene">
              <SceneSetView defaultOrientation={DefaultOrientation.PORTRAIT} />
            </MonitorView>
          </Route>
          <Route>
            {/* Not found / 404 */}
            <MonitorView viewName="DefaultErrorFallback">
              <WithGlobalNavigation>
                <Container>
                  <DefaultErrorFallback />
                </Container>
                <Footer />
              </WithGlobalNavigation>
            </MonitorView>
          </Route>
        </Switch>
        <DaylightThrobber />
      </MediaContainerScalingBehaviorProvider>
    </ErrorBoundaryForDaylightSwitch>
  );
};
