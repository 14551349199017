import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { roles } from 'cross-platform/utils/roleProps';

/**
 * The "X" icon indicating that something can be closed
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 * @param onPress: callback for when the button is pressed
 */
export const CloseIcon = ({
  size,
  color,
  onPress,
}: {
  size: number;
  color: string;
  onPress?: () => void;
}): ReactElement => {
  return (
    <TouchableOpacity
      style={{ padding: 15 }}
      {...roles('XIcon')}
      onPress={onPress}
    >
      <Svg width={size} height={size} fill="none" viewBox="0 0 17 16">
        <G>
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.5147 7.83973L1.02769 2.35272C0.489469 1.8145 0.489469 0.941881 1.02769 0.403663C1.5659 -0.134554 2.43853 -0.134554 2.97675 0.403663L8.46375 5.89067L13.9508 0.403663C14.489 -0.134554 15.3616 -0.134554 15.8998 0.403663C16.438 0.941881 16.438 1.8145 15.8998 2.35272L10.4128 7.83973L15.8998 13.3267C16.438 13.865 16.438 14.7376 15.8998 15.2758C15.3616 15.814 14.489 15.814 13.9508 15.2758L8.46375 9.78879L2.97675 15.2758C2.43853 15.814 1.5659 15.814 1.02769 15.2758C0.489469 14.7376 0.489469 13.865 1.02769 13.3267L6.5147 7.83973Z"
            fill={color}
          />
        </G>
      </Svg>
    </TouchableOpacity>
  );
};
