import React, { useState } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { ProductReferences } from 'common/constants/enums';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { getIFUSlugName } from 'components/Screens/ContentScreens/components/helpers';
import { useGetIFUScreenPath } from 'components/Screens/ContentScreens/hooks/useGetIFUScreenPath';
import {
  ButtonColorSchemes,
  UniversalButton,
} from 'components/UniveralButtons';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryAllDownloads } from 'lib/api/reactQueryHelpers';

import MFAModalINeedHelp from '../MFAModalINeedHelp';
import {
  MFAActionTypes,
  MFASceneOptionDisabledType,
  MFASceneOptionType,
  ScreenContext,
  ScreenContextDisabled,
  ScreenContextToggle,
} from '../types';

import MFAModalHeader from './shared/MFAModalHeader';
import {
  ContinueButtonView,
  HeaderLine,
  INeedHelpText,
  MFAContentWrapper,
  SectionView,
} from './styled/styled';
import TitleSubtileMFA from './TitleSubtitleMFA';

export type MFASectionWrapperBase = {
  screenIdentifier?: string;
  onRequestClose: () => void;
  onContinue: (
    str: MFASceneOptionType | MFASceneOptionDisabledType | undefined
  ) => void;
  onSubmit?: () => void;
};

type MFASectionParams = MFASectionWrapperBase & {
  children?: React.ReactNode;
  content: ScreenContext | ScreenContextDisabled | ScreenContextToggle;
  toggleMFAFlag?: () => void;
};

const MFASectionWrapper = (
  props: React.PropsWithChildren<MFASectionParams>
): React.ReactElement | null => {
  const safeArea = useSafeArea();

  const history = useHistory();
  const { productReference } = useSafeParams();

  const { content, toggleMFAFlag, screenIdentifier } = props;
  const getIFUUrl = useGetIFUScreenPath();
  const { data: DownloadsData } = useQueryAllDownloads();
  const ifu_url = getIFUUrl(getIFUSlugName(DownloadsData?.result));
  const { isLastScreen } = content;

  const [isINeedHelp, setINeedHelp] = useState(false);

  const previousExist:
    | MFASceneOptionType
    | MFASceneOptionDisabledType
    | undefined = content.previous;

  const redirectToDownloads = () => {
    // Redirect from a modal
    if (productReference === ProductReferences.DAYLIGHT) {
      return history.push(`/${productReference}/settings/about-daylight`);
    }
    if (ifu_url !== undefined) {
      return history.push(ifu_url);
    }
  };

  const onContinuePress = () => {
    if (content.continue_button?.action.type === MFAActionTypes.NEXT) {
      // If Last Screen of the workflow
      if (isLastScreen === true) {
        if (toggleMFAFlag) {
          // If last screen
          // This will trigger to invalidate the Query Cache for the
          // useQuerySettings API Call that the settings screen uses
          // and close the MFA Modal.
          toggleMFAFlag();
        }
      } else {
        // Majority of the time, this is the function that will be
        // called to go to the next or back screens.
        props.onContinue(content.continue_button?.next);
      }
    } else {
      // Some screens have a request to talk to the BE
      // If this is 1 of them, make the API call.
      // Components like MFA Verify have this functionality.
      if (props.onSubmit) {
        props.onSubmit();
      }
    }
  };

  // Similar to onContinuePress above
  // If it is the last scene in the MFA Workflow
  // AND If user closes modal, then trigger the toggleMFAFlag
  // Else execute normal onRequestClose function.
  const requestClose = () => {
    if (isLastScreen === true && typeof toggleMFAFlag === 'function') {
      toggleMFAFlag();
    } else {
      props.onRequestClose();
    }
  };

  const closeINeedHelp = () => {
    // Close the I need Help modal
    setINeedHelp(false);
    // But if last screen
    // Close all screens
    if (isLastScreen === true) {
      requestClose();
    }
  };

  return (
    <>
      <SectionView
        style={{ paddingTop: safeArea.top }}
        {...roles(screenIdentifier ?? 'MFASectionWrapper')}
      >
        <MFAModalHeader
          navigate={() => {
            content.previous && props.onContinue(content.previous);
          }}
          onRequestClose={requestClose}
          showBackButton={previousExist !== undefined}
        />
        <HeaderLine />
        <MFAContentWrapper safeArea={safeArea}>
          {content.title && (
            <TitleSubtileMFA
              title={content.title.text}
              subtitle={content.subtitle?.text}
            />
          )}

          {props.children}

          {content.continue_button && (
            <ContinueButtonView>
              {content.continue_button.next || isLastScreen ? (
                <UniversalButton
                  accessibility={{
                    role: 'button',
                    accessibilityRole: 'button',
                  }}
                  text={content.continue_button.text}
                  onPress={onContinuePress}
                  colorScheme={ButtonColorSchemes.AltPrimary}
                  {...roles('MFAModalContinueButton')}
                />
              ) : null}
            </ContinueButtonView>
          )}
          {props.content.showINeedHelpLink && (
            <INeedHelpText
              onPress={() => {
                setINeedHelp(true);
              }}
              role="button"
              accessibilityRole="button"
              {...roles('MFAModalINeedHelpLink')}
            >
              {'I need help'}
            </INeedHelpText>
          )}
        </MFAContentWrapper>
      </SectionView>
      {isINeedHelp ? (
        <MFAModalINeedHelp
          redirectToDownloads={redirectToDownloads}
          visible={isINeedHelp}
          onRequestClose={requestClose}
          navigate={closeINeedHelp}
        />
      ) : null}
    </>
  );
};

export default MFASectionWrapper;
