import React, { ReactElement } from 'react';
import { View } from 'react-native';
import Svg, { Circle as SvgCircle } from 'react-native-svg';

import {
  PieChart as PieChartProps,
  PieChartValue,
  ViewStylesObject,
} from '@bighealth/types/src/scene-components/client';

import { useTransformStylesToContext } from 'components/ResponsiveLayout/hooks/useTransformStylesToContext';
import { roles } from 'cross-platform/utils/roleProps';

export const PieChart = (props: PieChartProps): ReactElement => {
  const scaleValues = useTransformStylesToContext();
  // @TODO Make useTransformStylesToContext an optional generic
  // WHEN we find it annoying to destructure and remap values like this.
  const { width, height } = scaleValues({
    width: props.radius,
    height: props.radius,
  });
  const { height: strokeWidth } = scaleValues({
    height:
      typeof props.stroke?.weight === 'undefined' ? 10 : props.stroke.weight,
  });
  const transformedStyles = scaleValues(props.style as ViewStylesObject);

  const sizeHalved = width / 2;
  const circumference = sizeHalved * Math.PI;
  let total = 0;

  // This rotate fixes SVG coordinates so  a positive y-value, is "up" (on screen)
  return (
    <View pointerEvents={'none'}>
      <Svg
        {...roles('PieChart')}
        width={width}
        height={height}
        style={{
          ...transformedStyles,
          transform: [{ rotate: '-90deg' }],
        }}
        strokeWidth={strokeWidth}
      >
        <SvgCircle
          cx={sizeHalved}
          cy={sizeHalved}
          r={(width - strokeWidth) / 2}
          fill={props.fill}
          stroke={props.stroke?.color || 'red'}
          strokeWidth={strokeWidth}
          strokeOpacity={props.stroke?.opacity}
        />
        {props.values.map(
          (value: PieChartValue, index: number): ReactElement => {
            const target = circumference * (value.value / 100);
            const segment = (
              <SvgCircle
                key={index}
                cx={sizeHalved}
                cy={sizeHalved}
                r={(width - strokeWidth) / 2}
                fill={'transparent'}
                stroke={value.fill || 'red'}
                strokeOpacity={value.stroke?.opacity}
                strokeDasharray={`${target} ${circumference}`}
                strokeDashoffset={-total}
              />
            );
            total = total + target;
            return segment;
          }
        )}
      </Svg>
    </View>
  );
};

export default PieChart;
