import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { Scene } from '@bighealth/types/src/scene-component';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import downloadRemoteAssets from 'lib/downloader/downloader';
import * as reporter from 'lib/reporter';
import {
  moveAssetToCache,
  setAssetsToDownload,
} from 'state/downloader/actions';
import { Asset } from 'state/downloader/reducer';
import { getAssetsInCache } from 'state/downloader/selectors';

export type UseQueryDownloadNonSceneSetAssetsReturn = {
  status: nonSceneSetAssetsStatus;
  downloadAssets: (
    sceneComponents:
      | get_user_session_screen_data.SessionScreenComponent[]
      | Scene.Contexts.Client.Components.Video[]
  ) => Promise<string[] | undefined>;
};

export enum nonSceneSetAssetsStatus {
  IDLE = 'IDLE',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  LOADING = 'LOADING',
}

export const useLazyQueryDownloadNonSceneSetAssets = (
  updateStateWhenCompleted = true
): UseQueryDownloadNonSceneSetAssetsReturn => {
  const dispatch = useDispatch();
  const queryClient = getQueryClient();
  const assetsInCache = useSelector(getAssetsInCache);

  const [status, setStatus] = useState(nonSceneSetAssetsStatus.IDLE);

  const downloadAssets: UseQueryDownloadNonSceneSetAssetsReturn['downloadAssets'] = useCallback(
    async sceneComponents => {
      try {
        setStatus(nonSceneSetAssetsStatus.LOADING);

        const uncachedAssets: Asset[] = [];
        const uncachedRemoteURIs: string[] = [];

        for (const sceneComponent of sceneComponents) {
          if (sceneComponent.component === 'Image') {
            // determine if asset is already cached
            const assetInCache = !!assetsInCache.find(asset =>
              asset.remoteURI.includes(sceneComponent.sourceProps.storage_url)
            );

            if (!assetInCache) {
              uncachedAssets.push({
                localURI: undefined,
                remoteURI: sceneComponent.sourceProps.storage_url,
              });
              uncachedRemoteURIs.push(sceneComponent.sourceProps.storage_url);
            }
          }
        }

        if (uncachedAssets.length > 0) {
          dispatch(setAssetsToDownload(uncachedAssets));
          await downloadRemoteAssets(uncachedRemoteURIs, newAsset => {
            dispatch(moveAssetToCache(newAsset));
          });
          queryClient.setQueryData('NonSceneSetAssets', uncachedAssets); // Not required but improved react-query logs now we don't have redux
        }
        if (updateStateWhenCompleted) {
          setStatus(nonSceneSetAssetsStatus.SUCCESS);
        }

        // following the convention set by useLazyQueryDownloadSceneSetAssets
        return uncachedRemoteURIs.length === 0 ? undefined : uncachedRemoteURIs;
      } catch (e) {
        if (updateStateWhenCompleted) {
          setStatus(nonSceneSetAssetsStatus.FAILURE);
        }
        reporter.log(e.message, e);
        // TODO examine error, and throw if required
        // WHEN TP-88 Client app error handling
      }
    },
    [assetsInCache, dispatch, queryClient, updateStateWhenCompleted]
  );

  return {
    status,
    downloadAssets,
  };
};
