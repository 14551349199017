import { ScreenResourceEvent } from '@bighealth/analytics';
import { fire_ui_events } from '@bighealth/api/Event/v1';

import { URLLike } from 'lib/api/analytics/useGetURL';

import { getAnalyticsDate } from './getAnalyticsDate';
import { getUserAgent } from './getUserAgent';

export const sendSceneAnalytics = async ({
  productId,
  programId,
  sceneId,
  sceneSetGraphId,
  sceneSetId,
  url,
  productVersionStream,
}: {
  productId: number;
  programId: number;
  sceneId: number;
  sceneSetGraphId: number;
  sceneSetId: number;
  url: URLLike;
  productVersionStream?: string;
}): Promise<void> => {
  const userAgent = await getUserAgent();
  const clientAppNodePackageVersion = require('../../../../package.json')
    .version;
  const payload: ScreenResourceEvent = {
    occurred_at: getAnalyticsDate(),
    product_id: productId,
    program_id: programId,
    action: 'viewed',
    name: 'screen|viewed|therapy',
    resource: 'screen',
    type: 'ui',
    body: {
      fields: {
        feature: {
          area: 'therapy',
          section: 'therapy',
        },
        route: url.pathname,
      },
      relations: {
        scene_set_graph: {
          id: sceneSetGraphId,
        },
        scene_set: {
          id: sceneSetId,
        },
        scene: {
          id: sceneId || 0,
        },
      },
      meta: {
        http: {
          current_url: url.href,
          referrer: url.href,
          user_agent: userAgent,
          client_app_node_package_version: clientAppNodePackageVersion,
          product_stream_version: productVersionStream,
        },
      },
    },
  };

  try {
    // Do not await this. We do not care if it succeeds or fails and we do not want
    // to block the thread for this API call
    fire_ui_events({
      events: [payload],
    });
  } catch (error) {
    console.error('Attempted to send analytics but failed', payload);
  }
};
