import { DropdownItem } from 'components/generic-question/Dropdown';
import {
  TIME_FINAL_AWAKENING,
  TIME_GET_OUT_OF_BED,
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
} from 'components/SleepDiaryForm/constants';
import { DAY, HOUR } from 'lib/durations';
import { QuestionId } from 'state/question-response/actions';

import { getSelected } from '../../helpers/getSelected';
import { Config } from '..';

import { createQuestionProps } from './helpers/createQuestionProps';
import { getDiaryDate } from './helpers/getDiaryDate';
import { isPreviousDate } from './helpers/isPreviousDate';
/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getTimeOutOfBedConfigFromFinalAwakening = (
  inputs: Record<QuestionId, DropdownItem[]>,
  diaryDate?: Date
): Record<QuestionId, Config> | null => {
  const timeInBed = getSelected(inputs[TIME_INTO_BED])?.valueOf();
  const timeTryToSleep = getSelected(inputs[TIME_TRY_TO_SLEEP])?.valueOf();
  const finalAwakening = getSelected(inputs[TIME_FINAL_AWAKENING])?.valueOf();

  if (typeof finalAwakening !== 'number') {
    return null;
  }
  const min = finalAwakening;
  const max = finalAwakening + 12 * HOUR;

  // if TIME_FINAL_AWAKENING is less than TIME_TRY_TO_SLEEP or TIME_INTO_BED than
  // TIME_TRY_TO_SLEEP or TIME_INTO_BED is after 12AM and TIME_TRY_TO_SLEEP options start from same day
  // instead of next day, for example TIME_INTO_BED=1970-01-02 00:00 and TIME_FINAL_AWAKENING=1970-01-01 00:00
  const minDate = isPreviousDate(finalAwakening, [timeTryToSleep, timeInBed])
    ? finalAwakening + DAY
    : finalAwakening;

  diaryDate = getDiaryDate(minDate, diaryDate);

  return {
    [TIME_GET_OUT_OF_BED]: createQuestionProps(min, max, diaryDate),
  };
};

export { getTimeOutOfBedConfigFromFinalAwakening };
