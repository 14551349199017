import styled from 'styled-components/native';

import { FillAbsolute, ScrollableContainer } from 'components/Containers';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { Text } from 'components/Text';
import TextInput from 'components/TextInput';
import Image from 'cross-platform/Image';

export const LimitWidth = styled.View`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  max-width: 720px;
  width: 100%;
`;

export const ContentContainer = styled.View`
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
`;

export const LogoContainer = styled.View`
  align-self: center;
  margin-bottom: 8px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LogoButton = styled.TouchableOpacity`
  padding: 16px;
`;

export const Logo = styled(Image)``;

export const LogoContainerPortrait = styled(LogoContainer)`
  margin-bottom: 40px;
  width: 100%;
`;

export const Input = styled(TextInput)<{
  hasErrors?: boolean;
  fontSize?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  color: #2d2d2d;
  border: ${({ hasErrors }) =>
    hasErrors ? `solid 2px #d0021b;` : `solid 1px #ffffff;`};
  font-style: normal;
`;

export const FormattedText = styled<typeof Text>(Text)`
  font-size: 20px;
  color: white;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
`;

export const BackLinkContainer = styled.TouchableOpacity`
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BackLinkText = styled(Text)`
  font-size: 16px;
  margin-left: 8px;
  font-family: '${FontFamilies.Regular}';
  color: ${props => props.theme.color.overlay.primary};
  font-weight: 500;
`;

export const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 24px;
`;

export const ActionButtonContainer = styled.View`
  padding-left: 106px;
  padding-right: 106px;
`;

export const HeadingText = styled(Text)`
  font-size: 24px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: ${props => props.theme.color.overlay.primary};
  margin-bottom: 16px;
`;

export const PaddingContainer = styled.View`
  flex-direction: column;
  margin-bottom: 24px;
  display: flex;
  align-items: stretch;
`;

export const Container = styled(FillAbsolute)`
  background-color: ${props => props.theme.color.lightGray};
`;

export const ForgotPasswordScroll = styled(ScrollableContainer).attrs({
  contentContainerStyle: {
    flexGrow: 1,
    alignItems: 'center',
  },
})`
  width: 100%;
`;

export const ErrorContainer = styled.View`
  margin-top: 8px;
`;

export const InstructionText = styled(Text)`
  font-size: 18px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: ${props => props.theme.color.overlay.primary};
  margin-bottom: 16px;
`;

export const LabelContainer = styled.View`
  display: flex;
  padding-bottom: 7px;
`;

export const ValidationText = styled<typeof Text>(Text)`
  font-size: 16px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: #d0021b;
`;

export const InlineLink = styled<typeof InstructionText>(Text)`
  text-decoration-line: underline;
`;

export const LabelText = styled<typeof Text>(Text)`
  font-size: 18px;
  color: ${props => props.theme.color.midBlue};
  margin-bottom: 8px;
  font-weight: 400;
`;
LabelText.displayName = 'LableText';
