import React, { ReactElement } from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { IconStyles } from './types';

export const ExclamationCircle = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      style={style}
    >
      <Circle cx="30" cy="30" r="30" fill={style?.color || 'currentColor'} />
      <Path
        d="M28.7679 34.6V15H32.2479V34.6H28.7679ZM30.4879 43.28C28.8479 43.28 27.6079 41.96 27.6079 40.36C27.6079 38.76 28.8479 37.44 30.4879 37.44C32.1279 37.44 33.4479 38.76 33.4479 40.36C33.4479 41.96 32.1279 43.28 30.4879 43.28Z"
        fill="#ffffff"
      />
    </Svg>
  );
};
