import React, { FunctionComponent } from 'react';
import { GestureResponderEvent } from 'react-native';

import { CalendarProps } from '@bighealth/types/dist/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';
import { ChartProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period/';

import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';
import { roles } from 'cross-platform/utils/roleProps';

import { BarChartColumnContainer } from './BarChartColumnContainer';
import { BarChartColumnFillContainer } from './BarChartColumnFillContainer';
import { BarChartLabelContainer } from './BarChartLabelContainer';
import { ChartContainer } from './ChartContainer';
import {
  BarChartColumn,
  BarChartColumnFill,
  BarChartGuide,
  BarChartGuideContainer,
  BarChartGuideLabel,
  BarChartGuideLine,
  BarChartLabelText,
  BarChartLabelView,
} from './styled';

interface ChartComponentProps extends ChartProps {
  daysData: CalendarProps['days'];
  onColumnPress: (data: { x: number; y: number }, date: string) => void;
}

const Chart: FunctionComponent<ChartComponentProps> = (
  props: ChartComponentProps
) => {
  const { guides, days, upper_y_value, daysData, onColumnPress } = props;

  const styles = useGetDynamicContentStyles();
  const guideLabelSize = styles.sleepDiaryWeekChartGuideLabelSize;

  const handleColumnPress = (
    event: GestureResponderEvent,
    shouldExecute: boolean,
    dayDate: string
  ) => {
    if (shouldExecute) {
      onColumnPress?.(
        {
          x: event.nativeEvent.pageX,
          y: event.nativeEvent.pageY,
        },
        dayDate
      );
    }
  };

  return (
    <ChartContainer>
      {/* The padding at the top must be the size of the guide label + 1px of space and 1 px of guide line */}
      <BarChartColumnContainer paddingTop={guideLabelSize + 2}>
        {days.map((day, index) => (
          <BarChartColumn
            {...roles(`sleepDiaryChartColumn${index}`)}
            key={index}
          >
            <BarChartColumnFillContainer
              {...roles(`sleepDiaryChartColumnFillContainer${index}`)}
              accessibilityLabel={
                'See detail: ' + (daysData[index].date as any).$date
              }
              disabled={day?.label.text.length === 0}
              onPress={event =>
                handleColumnPress(
                  event,
                  !!day && !(day?.label.text.length === 0),
                  daysData[index].date
                )
              }
            >
              {day?.data
                ? day.data.map((dataPoint, innerIndex) => (
                    <BarChartColumnFill
                      {...dataPoint}
                      {...roles(
                        `sleepDiaryChartColumnFill${index}_${innerIndex}`
                      )}
                      key={innerIndex}
                      upperYValue={upper_y_value}
                      style={
                        day.label.text.length === 0 && {
                          borderColor: sleepioColors.altDarkBlue,
                          borderWidth: 2,
                          borderStyle: 'dashed',
                          borderRadius: 4,
                        }
                      }
                    />
                  ))
                : null}
            </BarChartColumnFillContainer>
          </BarChartColumn>
        ))}
      </BarChartColumnContainer>

      <BarChartGuideContainer
        {...roles('sleepDiaryChartGuideContainer')}
        pointerEvents="none"
      >
        {guides.map((guide, index) => (
          <BarChartGuide key={index}>
            <BarChartGuideLabel
              text={guide.label}
              style={{ color: guide.color, fontSize: guideLabelSize }}
            />
            <BarChartGuideLine />
          </BarChartGuide>
        ))}
      </BarChartGuideContainer>

      <BarChartLabelContainer>
        {days.map((day, index) => {
          return day && day.label.text.length ? (
            <BarChartLabelView {...day.label} key={index}>
              <BarChartLabelText
                {...day.label}
                style={{
                  fontSize: styles.sleepDiaryWeekBarChartLabelTextFontSize,
                }}
              />
            </BarChartLabelView>
          ) : (
            <BarChartLabelView
              key={index}
              text={''}
              background_color={'transparent'}
            />
          );
        })}
      </BarChartLabelContainer>
    </ChartContainer>
  );
};

export { Chart };
