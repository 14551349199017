import { AVProps } from '@bighealth/avplayer';
import { SceneActionTypes } from '@bighealth/types/dist/enums';

import { ActionHandlerCallback } from 'lib/player/useActionHandler';
import { qaLogFactory } from 'lib/showQAMenu/qaLogFactory';

import { MediaNodeWithTimer } from './MediaNodeWithTimer';
import {
  AVRefNode,
  OrchestratorEndCallback,
  OrchestratorFailCallback,
  OrchestratorPauseCallback,
  OrchestratorSuccessCallback,
} from './types';

const qaqaMediaPlayerLog = qaLogFactory('MediaPlayer log');

export class MediaPlayerOrchestrator {
  private playLock = false;
  private nodes: MediaNodeWithTimer[] = [];
  private onPause?: OrchestratorPauseCallback = undefined;
  private onPlayFail?: OrchestratorFailCallback = undefined;
  private onPlaySuccess?: OrchestratorSuccessCallback = undefined;
  private onEnd?: OrchestratorEndCallback = undefined;

  registerOrUpdateNode = async (
    node: AVRefNode,
    { onAction }: { onAction: ActionHandlerCallback }
  ): Promise<void> => {
    this.pruneNodes();
    const existingNode = this.nodes.find(
      storedNode => storedNode.getNode() === node
    );

    if (existingNode) {
      await existingNode.setProps(
        MediaNodeWithTimer.getPropsForNode(node) as AVProps
      );
      this.updateHandlersForNode(existingNode);
      existingNode.setOnActionHandler(onAction);
    } else {
      const newTimerNode = new MediaNodeWithTimer(node);
      this.updateHandlersForNode(newTimerNode);
      newTimerNode.setOnActionHandler(onAction);
      this.nodes.push(newTimerNode);

      if (this.playLock) {
        this.handlePause();
      } else {
        await newTimerNode.play();
      }
    }
    return;
  };

  updateHandlersForNode = (node: MediaNodeWithTimer): void => {
    node.setOnPlayFailHandler(this.handlePlayFail);
    node.setOnPlaySuccessHandler(this.handlePlaySuccess);
    node.setOnEndHandler(this.handleEnd);
  };

  getNodes = (): MediaNodeWithTimer[] => {
    this.pruneNodes();
    return this.nodes;
  };

  play = async (): Promise<void> => {
    qaqaMediaPlayerLog(`MPO play was called`);
    if (this.playLock) {
      qaqaMediaPlayerLog(`!MPO ▶️ ❌ (🧍)`);
      return;
    }
    qaqaMediaPlayerLog(`MPO ▶️`);
    this.pruneNodes();
    try {
      const promises = this.getNodes().map(async node => {
        await node?.play();
      });
      await Promise.all(promises);
    } catch (e) {
      this.handlePlayFail(e);
    }
    return;
  };

  pause = (): void => {
    qaqaMediaPlayerLog('MPO ⏸️');
    this.pruneNodes();
    this.nodes.forEach(node => node?.pause());
  };

  setOnPauseHandler = (onPause?: OrchestratorPauseCallback): void => {
    this.onPause = onPause;
  };

  setOnPlayFailHandler = (onPlayFail?: OrchestratorFailCallback): void => {
    this.onPlayFail = onPlayFail;
  };

  setOnPlaySuccessHandler = (
    onPlaySuccess?: OrchestratorSuccessCallback
  ): void => {
    this.onPlaySuccess = onPlaySuccess;
  };

  setOnEndHandler = (onEnd?: OrchestratorEndCallback): void => {
    this.onEnd = onEnd;
  };

  setPlayLock = (): void => {
    qaqaMediaPlayerLog('MPO setPlayLock 🧍');
    this.playLock = true;
    this.nodes.forEach(node => node?.setPlayLock());
  };

  releasePlayLock = (): void => {
    qaqaMediaPlayerLog('MPO releasePlayLock 🏃');
    this.playLock = false;
    this.nodes.forEach(node => node?.releasePlayLock());
  };

  private handlePlayFail = (error: DOMException) => {
    // Workaround for "DOMException: The play() request was interrupted"
    //
    // This is a big of a hack....
    // When SceneSetView re-renders (for instance when Repeat SceneSet is called) browsers seem to interpret this
    // as firstly a play event (which might be us triggering it) and then secondly a pause event (which might be
    // the browser pausing the unmounting video). This pause event is called "immediately" after the play but since
    // play is a promise the attempt to play errors resulting in the message above.
    //
    // A quick solution to this is non-obvious right now. However, it seems to be harmless so we intercept the error,
    // check that it's of type "ABORT_ERR" (i.e. DOMException error code 20) and if so don't call this.onPlayFail
    const isInterruptError =
      typeof error.ABORT_ERR === 'number' && error.ABORT_ERR === error.code;
    if (isInterruptError) {
      return;
    }
    if (typeof this.onPlayFail === 'function') {
      this.onPlayFail(error);
    }
    //  This pausing of everything on a single fail creates a race condition which means some assets aren't
    // seeked to
    // @TODO: Fix it
    // @WHEN: It becomes a problem
    this.pause();
  };

  handlePause = (): void => {
    if (typeof this.onPause === 'function') {
      this.onPause();
    }
  };

  handlePlaySuccess = (): void => {
    if (typeof this.onPlaySuccess === 'function') {
      this.onPlaySuccess();
    }
  };

  handleEnd = (node: MediaNodeWithTimer): void => {
    if (this.getNodeIsLast(node)) {
      if (
        typeof this.onEnd === 'function' &&
        node.getProps().action?.type !== SceneActionTypes.NEXT
      ) {
        this.onEnd(node);
      }
    }
  };

  private pruneNodes = (): void => {
    this.nodes = this.nodes.filter(node => !!node.getNode()?.current);
  };

  getMediaNodeByKey = (key: string): MediaNodeWithTimer | undefined => {
    this.pruneNodes();
    return this.nodes.find(node => node.getProps().src === key);
  };

  getNodeByKey = (key: string): AVRefNode | undefined => {
    this.pruneNodes();
    const mediaNode = this.getMediaNodeByKey(key);
    return mediaNode?.getNode();
  };

  getNodeIsLast = (node: MediaNodeWithTimer): boolean => {
    const { from, to, delay } = node.getProps();
    const lengths = this.getNodes().map(node => {
      const { from, to, delay } = node.getProps();
      const length = delay + to - from;
      return length;
    });
    const max = Math.max(...[0, ...lengths]);
    const nodeLength = delay + to - from;
    return nodeLength >= max;
  };

  /**
   *
   * Always call teardown at the end of a SceneSet
   */
  teardown = (): void => {
    this.playLock = false;
    this.nodes.forEach(node => node.reset());
    this.nodes = [];
    this.onPause = undefined;
    this.onPlayFail = undefined;
    this.onPlaySuccess = undefined;
    this.onEnd = undefined;
  };

  /**
   * ----------------------------------------
   * For use in testing only
   */

  __handleEnd = (node: MediaNodeWithTimer): void => {
    this.handleEnd(node);
  };

  __handlePlaySuccess = (): void => {
    this.handlePlaySuccess();
  };

  getPlayLock = (): boolean => this.playLock;
}

export let mediaPlayerOrchestrator = new MediaPlayerOrchestrator();

export const buildMediaPlayerOrchestrator = (): MediaPlayerOrchestrator => {
  mediaPlayerOrchestrator?.teardown();
  mediaPlayerOrchestrator = new MediaPlayerOrchestrator();
  return mediaPlayerOrchestrator;
};
