import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const connect = async (
  args: connect.Args,
  requestConfig?: AxiosRequestConfig
): Promise<connect.Response> => {
  const request: connect.Request = {
    service_name: 'Fitbit',
    service_version: '1',
    service_method: 'connect',
    args,
  };

  return await api(request, requestConfig);
};

connect.queryKey = 'Fitbit/connect';

export declare namespace connect {
  export type Args = {
    auth_code: string;
    product_id: number;
    redirect_uri: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'Fitbit';
    service_version: '1';
    service_method: 'connect';
    args: Args;
  }

  export type Result = number;

  export type Response = APIResponse<Result>;
}
