import {
  QueryKey,
  useQuery as useQueryLib,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { useHistory } from 'cross-platform/react-router';

/**
 * Data that should be used to
 * decide if Throbber is shown
 */
export type IsFetchingMeta = Record<string, unknown> & {
  pathname?: string;
};

/**
 * ⚠️ WARNING: Do NOT use this function for anything but _is fetching meta_
 *
 * This function simply calls `useQuery` (with config object), but with
 * additional values added to `config.meta` which are SPECIFCALLY
 * used for inputs to the decisions to show throbber or not.
 *
 * NOTE: This file is not placed in `lib/api` as this is
 * conceptually glue between `useQuery` and
 * `isFetchingWithThrobberPredicateFactory`
 * (as opposed to being an actual network request)
 *
 * @see {@link ./README.md} for more info
 *
 * @param options
 */
function useQueryWithIsFetchingMeta<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> {
  const {
    location: { pathname },
  } = useHistory();
  const isFetchingWithThrobber: IsFetchingMeta = { pathname };
  const meta = options?.meta || null;
  return useQueryLib({
    ...options,
    meta: { ...isFetchingWithThrobber, ...meta },
  });
}

export { useQueryWithIsFetchingMeta };
