import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

export const isResultMobileWeb = (
  data: get_user_session_screen_data.Result
): data is get_user_session_screen_data.ResultMobileWeb => {
  if (typeof data === 'object' && 'header' in data && 'main' in data) {
    return true;
  }
  return false;
};
