export const DEVELOPER_COMPONENTS_PRODUCT = 'sleepio';

export const DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID = 888888;

export const EDITOR_SCENESET_GRAPH_ID = 999999;

export const DEVELOPER_COMPONENTS_SCENESET_ID = 1;

export const DEVELOPER_RESPONSIVE_SCENESET_ID = 2;

export const DEVELOPER_AV_SCENESET_ID = 3;

export const DEVELOPER_COMPONENTS_TEST_PATH = `/${DEVELOPER_COMPONENTS_PRODUCT}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_COMPONENTS_SCENESET_ID}`;

export const DEVELOPER_RESPONSIVE_TEST_PATH = `/${DEVELOPER_COMPONENTS_PRODUCT}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_RESPONSIVE_SCENESET_ID}`;

export const DEVELOPER_AV_TEST_PATH = `/${DEVELOPER_COMPONENTS_PRODUCT}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_AV_SCENESET_ID}`;

export const DEVELOPER_STORIES = `/${DEVELOPER_COMPONENTS_PRODUCT}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/storybook`;

// editor_sceneset_preview
export const EDITOR_SCENESET_PREVIEW_PATH = `/${DEVELOPER_COMPONENTS_PRODUCT}/${EDITOR_SCENESET_GRAPH_ID}/:sceneSetId`;
