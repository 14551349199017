import { UseQueryResult } from 'react-query';
import * as History from 'history';

import { HttpStatusCode } from '@bighealth/api';
import { get_assigned_program_with_user_and_product } from '@bighealth/api/UserAccountAuthorization/v1';

import { ThrobberBehaviorMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useQueryWithIsFetchingMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/hooks/useQueryWithIsFetchingMeta';
import { useHistory } from 'cross-platform/react-router';
import { SECOND } from 'lib/durations';

import { useQueryProduct } from './useQueryProduct';

export type useQueryProgramReturnType = UseQueryResult<
  get_assigned_program_with_user_and_product.Response,
  Readonly<{ response: { status: HttpStatusCode } }>
>;

export const isDisallowed = (history: History.History): boolean =>
  // TODO don't call useQueryProgram on /forgot-password
  // WHY Unnecessary; but in rush to unblock regression testing
  history.location.pathname.endsWith('/forgot-password');

export const useQueryProgram = (options?: {
  meta: ThrobberBehaviorMeta | undefined;
}): useQueryProgramReturnType => {
  const history = useHistory();
  const productId = useQueryProduct(options).data?.result?.id;

  const result = useQueryWithIsFetchingMeta<
    get_assigned_program_with_user_and_product.Response,
    Readonly<{ response: { status: HttpStatusCode } }>
  >({
    queryKey: get_assigned_program_with_user_and_product.queryKey,
    queryFn: () => {
      return get_assigned_program_with_user_and_product(
        {
          product_id: productId as number,
        },
        { timeout: 10 * SECOND }
      );
    },
    enabled: typeof productId === 'number' && !isDisallowed(history),
    cacheTime: Infinity,
    meta: options?.meta,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
  return result;
};
