import {
  MFAActionTypes,
  MFADisableComponentOptions,
  MFAEnableComponentOptions,
  MFASceneDisabledTypes,
  MFASceneTypes,
  MockMFAScenePropsType,
} from './types';

//mockFormPresentationProps
export const mockedMFAScenes: MockMFAScenePropsType = {
  enable: {
    toggle_view: {
      component: MFADisableComponentOptions.MFAToggle,
      inputLabel:
        'Add extra security to your account when you log in with multi-factor authentication.',
      continue_button: {
        text: 'Multi-factor authentication',
        component: 'Switch',
        next: MFASceneTypes.AuthenticationStart,
        action: {
          type: MFAActionTypes.SUBMIT,
        },
      },
    },
    authentication_start: {
      title: {
        text: 'Protect your account with multi-factor authentication',
      },
      subtitle: {
        text:
          'You can use an authentication app to quickly generate security codes without a text message. This helps us confirm that it’s you logging in.',
      },
      previous: MFASceneTypes.ToggleView,
      component: MFAEnableComponentOptions.MFASectionWrapper,
      continue_button: {
        text: 'Continue',
        component: 'Button',
        next: MFASceneTypes.AuthenticationDownload,
        action: {
          type: MFAActionTypes.NEXT,
        },
      },
    },
    authentication_download: {
      title: {
        text:
          'Download an authentication app like Authy or Google Authenticator.',
      },
      subtitle: {
        text:
          'Follow the instructions given in your authentication app to add an account. Then come back here to finish setting up.',
      },
      component: MFAEnableComponentOptions.MFASectionWrapper,
      previous: MFASceneTypes.AuthenticationStart,
      showINeedHelpLink: true,
      continue_button: {
        text: 'Continue',
        component: 'Button',
        next: MFASceneTypes.AuthenticationCopyCode,
        action: {
          type: MFAActionTypes.NEXT,
        },
      },
    },
    authentication_copy_code: {
      title: {
        text: 'Copy and paste this key into your authentication app.',
      },
      component: MFAEnableComponentOptions.MFAEnableCopyClipboard,
      previous: MFASceneTypes.AuthenticationDownload,
      showINeedHelpLink: true,
      continue_button: {
        text: 'Continue',
        component: 'Button',
        next: MFASceneTypes.AuthenticationVerifyCode,
        action: {
          type: MFAActionTypes.NEXT,
        },
      },
    },
    authentication_verify_code: {
      title: {
        text:
          'Enter the 6-digit verification code from your authentication app.',
      },
      component: MFAEnableComponentOptions.MFAEnableVerifyCode,
      previous: MFASceneTypes.AuthenticationCopyCode,
      showINeedHelpLink: true,
      inputHint: {
        error:
          'There was an error with your verification code. Check the code again and enter it again or ',
        text_for_continue: 're-verify your account',
      },
      continue_button: {
        text: 'Continue',
        component: 'Button',
        next: MFASceneTypes.AuthenticationAllSetUp,
        action: {
          type: MFAActionTypes.SUBMIT,
        },
      },
    },
    authentication_all_set_up: {
      title: {
        text: 'You’re all set up.',
      },
      subtitle: {
        text:
          'Now when you sign in to your account, you’ll be asked for your password and a verification code from your authentication app.',
      },
      component: MFAEnableComponentOptions.MFASectionWrapper,
      isLastScreen: true,
      continue_button: {
        text: 'Done',
        component: 'Button',
        action: {
          type: MFAActionTypes.NEXT,
        },
      },
    },
  },
  disabled: {
    toggle_view: {
      component: MFADisableComponentOptions.MFAToggle,
      inputLabel:
        'Add extra security to your account when you log in with multi-factor authentication.',
      continue_button: {
        text: 'Multi-factor authentication',
        component: 'Switch',
        next: MFASceneDisabledTypes.AuthenticationDisableEnterCode,
        action: {
          type: MFAActionTypes.SUBMIT,
        },
      },
    },
    authentication_disable_enter_code: {
      title: {
        text: 'Are you sure you want to disable multi-factor authentication?',
      },
      subtitle: {
        text:
          'To enable this again, you will need to re-verify your authentication app.',
      },
      previous: MFASceneDisabledTypes.ToggleView,
      component: MFADisableComponentOptions.MFAEnterDisableCode,
      showINeedHelpLink: true,
      inputLabel:
        'Enter verification code from your authentication app to continue.',
      inputHint: {
        error: 'Invalid verification code. Try again.',
      },
      continue_button: {
        text: 'Continue',
        component: 'Button',
        next: MFASceneDisabledTypes.AuthenticationIsDisabled,
        action: {
          type: MFAActionTypes.SUBMIT,
        },
      },
    },
    authentication_is_disabled: {
      title: {
        text: 'You have disabled multi-factor authentication.',
      },
      subtitle: {
        text: `Now when you sign in to your account, you'll be asked for your password only.`,
      },
      component: MFADisableComponentOptions.MFASectionWrapper,
      showINeedHelpLink: true,
      isLastScreen: true,
      continue_button: {
        text: 'Done',
        component: 'Button',
        action: {
          type: MFAActionTypes.NEXT,
        },
      },
    },
  },
};
