import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { useSafeParams } from 'components/Routes/useSafeParams';
import { NotificationGroupConfigId } from 'config/localNotifications';
import { useHistory } from 'cross-platform/react-router';
import {
  useQueryProgram,
  useQuerySessionCheck,
} from 'lib/api/reactQueryHelpers';
import { useLazyQueryFireUIEvent } from 'lib/api/reactQueryHelpers/useLazyQueries/useLazyQueryFireUIEvent';
import { useSessionsDataQuery } from 'sleepio/components/screens/SessionsScreen/components/WithNetworkAndState/hooks/useQuerySessionsData';
import { setNotificationUserSettingById } from 'state/notifications/actions';
import {
  getNotificationFeatureFlag,
  getOsNotificationPermissionsRequestCount,
} from 'state/notifications/selectors';

import {
  requestOsNotificationPermissions,
  useNotificationPermissionState,
  useNotificationUserSettings,
} from '../localNotificationsState';

import { getDateFromHoursAndMinutes } from './helpers/getDateFromHoursAndMinutes';
import { getVisualStateFromNotifState } from './helpers/getVisualStateFromNotifState';
import { useRefreshNotifications } from './hooks/useRefreshNotifications';
import { eventFactory } from './utils/eventFactory';
import { dailyButtonEventFactory } from './utils/scheduled-to-remove-FEG_289/dailyButtonEventFactory';
import { visualStateScreenEventFactory } from './utils/scheduled-to-remove-FEG_289/visualStateScreenEventFactory';
import {
  NOTIFICATION_TYPE,
  NotificationViewArgs,
  ScheduleNotificationFunc,
  VisualState,
} from './types';

export type NotificationsViewProps = {
  notificationGroupConfigId: NotificationGroupConfigId;
  sessionsNotificationGroupConfigId: NotificationGroupConfigId;
  renderPreviewView: (args: NotificationViewArgs) => ReactElement;
  renderReminderView: (args: NotificationViewArgs) => ReactElement;
};

export const NotificationsView = ({
  renderPreviewView,
  renderReminderView,
  notificationGroupConfigId,
  sessionsNotificationGroupConfigId,
}: NotificationsViewProps): ReactElement | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productReference } = useSafeParams();
  const [enableButtonPressed, setEnableButtonPressed] = useState(false);
  const programId = useQueryProgram()?.data?.result?.id;
  const { data } = useSessionsDataQuery();
  const isTodaysSessionDone = useMemo(() => {
    return (
      (data?.result as get_user_session_screen_data.ResultStandard)
        ?.sleep_diary_data?.[0]?.type === 'efficiency'
    );
  }, [data?.result]);
  const osNotificationPermissionRequestCount = useSelector(
    getOsNotificationPermissionsRequestCount
  );
  const hasCompletedSessionOneResponse = useQuerySessionCheck({
    sessionNumber: 1,
  });

  useRefreshNotifications({
    osNotificationPermissionRequestCount,
    notificationGroupConfigId: notificationGroupConfigId,
    sessionNotificationGroupConfigId: sessionsNotificationGroupConfigId,
    programId,
  });

  const {
    osNotificationsAuthorized,
    canNativeOsPermsRequestBeCalled: canNativeOsPermsRequestBeCalled,
  } = useNotificationPermissionState(osNotificationPermissionRequestCount);

  const notificationUserSettings = useNotificationUserSettings(
    notificationGroupConfigId
  );

  const sessionsNotificationsUserSettings = useNotificationUserSettings(
    sessionsNotificationGroupConfigId
  );

  const hasNotificationUserSettings =
    (notificationUserSettings?.isOn ||
      sessionsNotificationsUserSettings?.isOn) ??
    false;
  const visualState = getVisualStateFromNotifState(
    !canNativeOsPermsRequestBeCalled,
    osNotificationsAuthorized,
    hasNotificationUserSettings,
    productReference
  );

  const fireUIEvent = useLazyQueryFireUIEvent();
  useEffect(() => {
    fireUIEvent(visualStateScreenEventFactory(visualState));
    // WARNING: Do not include fireUIEvent in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualState]);

  const notificationAlertHandler = useCallback(() => {
    requestOsNotificationPermissions(dispatch);
  }, [dispatch]);

  const notificationFeatureFlag = useSelector(getNotificationFeatureFlag);
  const openSettingsScreen = useCallback(
    () => {
      fireUIEvent(
        eventFactory.notification(
          {
            action: 'user-action',
            fields: {
              value: 'os-prompt',
              app_notification_settings: {
                is_on: !!notificationUserSettings?.isOn,
              },
              notif_feature_flag: !!notificationFeatureFlag,
              os_notification_authorization_status: osNotificationsAuthorized
                ? 'AUTHORIZED'
                : 'DENIED',
            },
          },
          'open-os-settings-screen'
        )
      );
      Linking.openSettings();
    },
    // WARNING: Do not include fireUIEvent in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      notificationFeatureFlag,
      notificationUserSettings,
      osNotificationsAuthorized,
    ]
  );

  const scheduleNotification = useCallback<ScheduleNotificationFunc>(
    payload => {
      if (!osNotificationsAuthorized) {
        return;
      }

      const newDate = new Date(payload.data);
      if (isTodaysSessionDone) {
        newDate.setDate(newDate.getDate() + 1);
      }
      if (payload.type === NOTIFICATION_TYPE.DAILY_REMINDER) {
        fireUIEvent(
          dailyButtonEventFactory({
            isOn: true,
            id: notificationGroupConfigId,
            date: newDate,
          })
        );
        fireUIEvent(
          eventFactory.notification({
            action: 'user-action',
            fields: {
              value: 'app-notif-time-is-updated',
              app_notification_settings: {
                is_on: !!notificationUserSettings?.isOn,
                scheduled_time_in_24h: format(newDate, 'HH:mm'),
              },
              notif_feature_flag: !!notificationFeatureFlag,
              os_notification_authorization_status: osNotificationsAuthorized
                ? 'AUTHORIZED'
                : 'DENIED',
            },
          })
        );
        dispatch(
          setNotificationUserSettingById({
            isOn: true,
            id: notificationGroupConfigId,
            timeOfDayHours24: newDate.getHours(),
            timeOfDayMinutes: newDate.getMinutes(),
            date: isTodaysSessionDone ? newDate : undefined,
            programId: programId,
          })
        );
      }
      if (payload.type === NOTIFICATION_TYPE.SESSION_REMINDER) {
        fireUIEvent(
          dailyButtonEventFactory({
            isOn: true,
            id: sessionsNotificationGroupConfigId,
            date: payload.data,
          })
        );
        dispatch(
          setNotificationUserSettingById({
            isOn: true,
            id: sessionsNotificationGroupConfigId,
            timeOfDayHours24: 0,
            timeOfDayMinutes: 0,
            date: payload.data,
            programId: programId,
          })
        );
      }
    },
    // WARNING: Do not include fireUIEvent in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      osNotificationsAuthorized,
      notificationGroupConfigId,
      notificationUserSettings,
      notificationFeatureFlag,
      dispatch,
    ]
  );

  const cancelNotification = useCallback<ScheduleNotificationFunc>(
    payload => {
      if (!osNotificationsAuthorized) {
        return;
      }
      if (payload.type === NOTIFICATION_TYPE.DAILY_REMINDER) {
        fireUIEvent(
          dailyButtonEventFactory({
            isOn: false,
            id: notificationGroupConfigId,
            date: payload.data,
          })
        );
        dispatch(
          setNotificationUserSettingById({
            isOn: false,
            id: notificationGroupConfigId,
            timeOfDayHours24: payload.data.getHours(),
            timeOfDayMinutes: payload.data.getMinutes(),
          })
        );
        fireUIEvent(
          eventFactory.notification({
            action: 'user-action',
            fields: {
              value: 'app-notif-time-is-updated',
              app_notification_settings: {
                is_on: !!notificationUserSettings?.isOn,
              },
              notif_feature_flag: !!notificationFeatureFlag,
              os_notification_authorization_status: osNotificationsAuthorized
                ? 'AUTHORIZED'
                : 'DENIED',
            },
          })
        );
      }
      if (payload.type === NOTIFICATION_TYPE.SESSION_REMINDER) {
        fireUIEvent(
          dailyButtonEventFactory({
            isOn: false,
            id: sessionsNotificationGroupConfigId,
            date: payload.data,
          })
        );
        dispatch(
          setNotificationUserSettingById({
            isOn: false,
            id: sessionsNotificationGroupConfigId,
            timeOfDayHours24: 0,
            timeOfDayMinutes: 0,
            date: payload.data,
          })
        );
      }
    },

    // WARNING: Do not include fireUIEvent in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      osNotificationsAuthorized,
      notificationGroupConfigId,
      sessionsNotificationGroupConfigId,
      dispatch,
      notificationUserSettings,
      sessionsNotificationsUserSettings,
      notificationFeatureFlag,
    ]
  );

  const scheduleNotificationState = {
    [NOTIFICATION_TYPE.DAILY_REMINDER]: {
      date:
        typeof notificationUserSettings?.id !== 'undefined'
          ? getDateFromHoursAndMinutes({
              hours: notificationUserSettings?.timeOfDayHours24,
              minutes: notificationUserSettings?.timeOfDayMinutes,
            })
          : new Date(),
      isReminderDisabled: !osNotificationsAuthorized,
      isReminderOn: notificationUserSettings?.isOn && osNotificationsAuthorized,
    },
    [NOTIFICATION_TYPE.SESSION_REMINDER]: {
      date:
        sessionsNotificationsUserSettings?.id !== undefined &&
        sessionsNotificationsUserSettings?.date
          ? sessionsNotificationsUserSettings.date
          : new Date(),
      isSessionReminderDisabled: !osNotificationsAuthorized,
      isSessionReminderOn:
        sessionsNotificationsUserSettings?.isOn && osNotificationsAuthorized,
    },
  };

  const viewProps = {
    visualState,
    openSettingsScreen,
    cancelNotification,
    scheduleNotification,
    notificationAlertHandler,
    scheduleNotificationState,
    enableButtonPressedHandler: setEnableButtonPressed,
  };

  if (!canNativeOsPermsRequestBeCalled && enableButtonPressed) {
    if (osNotificationsAuthorized) {
      if (
        !notificationUserSettings?.isOn &&
        hasCompletedSessionOneResponse.isSuccess &&
        hasCompletedSessionOneResponse.data?.result
      ) {
        scheduleNotification({
          type: NOTIFICATION_TYPE.DAILY_REMINDER,
          data:
            scheduleNotificationState[NOTIFICATION_TYPE.DAILY_REMINDER].date ??
            new Date(),
        });
      }

      if (!sessionsNotificationsUserSettings?.isOn) {
        scheduleNotification({
          type: NOTIFICATION_TYPE.SESSION_REMINDER,
          data:
            scheduleNotificationState[NOTIFICATION_TYPE.SESSION_REMINDER]
              .date ?? new Date(),
        });
      }
    }

    history.push(`/${productReference}/home`);
    return null;
  }

  if (visualState === VisualState.A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT) {
    return renderPreviewView(viewProps);
  } else {
    return renderReminderView(viewProps);
  }
};
