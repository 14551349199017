import { clone, equals } from 'ramda';

import { AVProps } from '@bighealth/avplayer';
import { PlatformAVPrimitive } from '@bighealth/avplayer/dist/AVPlayer/platform';
import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { ActionHandlerCallback } from 'lib/player/useActionHandler';
import { SetTimeout } from 'lib/SetTimeout';
import { qaLogFactory } from 'lib/showQAMenu/qaLogFactory';

import {
  AVRefNode,
  OrchestratorEndCallback,
  OrchestratorFailCallback,
  OrchestratorSuccessCallback,
  SeekToMsPayload,
} from './types';

const qaMediaPlayerLog = qaLogFactory('MediaPlayer log');
const qaMediaPlayerError = qaLogFactory('MediaPlayer log', console.error);

export enum State {
  UNINITIALIZED = 'uninitialized',
  PLAYING = 'playing',
  PAUSED = 'paused',
}

export enum TimerId {
  mediaStartTimer = 'mediaStartTimer',
  mediaEndTimer = 'mediaEndTimer',
}

export enum TimerStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  IDLE = 'idle',
}

export type TimerState = {
  timeout?: SetTimeout;
  triggered: boolean;
  status: TimerStatus;
};

export type TimersState = {
  [TimerId.mediaStartTimer]: TimerState;
  [TimerId.mediaEndTimer]: TimerState;
};

const TIMERS = [TimerId.mediaStartTimer, TimerId.mediaEndTimer];

type SubsetProps = Pick<
  AVProps,
  'from' | 'to' | 'delay' | 'action' | 'isContinuous' | 'src' | 'captionSrc'
>;

export class MediaNodeWithTimer {
  private playLock = false;
  private readonly refNode: AVRefNode;
  private lastPlayedAtMs?: number = undefined;
  private lastPausedAtMs?: number = undefined;
  private timerTimeElapsedMs = 0;
  private props: AVProps;
  private onAction?: ActionHandlerCallback = undefined;
  private onPlaySuccess?: OrchestratorSuccessCallback = undefined;
  private onPlayFail?: OrchestratorFailCallback = undefined;
  private onEnd?: OrchestratorEndCallback = undefined;
  private timers: TimersState = {
    [TimerId.mediaStartTimer]: {
      timeout: undefined,
      triggered: false,
      status: TimerStatus.IDLE,
    },
    [TimerId.mediaEndTimer]: {
      timeout: undefined,
      triggered: false,
      status: TimerStatus.IDLE,
    },
  };

  constructor(refNode: AVRefNode) {
    this.refNode = refNode;
    this.props = MediaNodeWithTimer.getPropsForNode(this.refNode);
  }

  /**
   * ----------------------------------------
   * External controls
   */

  play = async (): Promise<void> => {
    qaMediaPlayerLog(`MNWT Play was called`);
    if (this.playLock) {
      return;
    }
    const shouldSeekPayload = this.getShouldSeek();
    if (shouldSeekPayload.shouldSeek) {
      qaMediaPlayerLog(`MNWT shouldSeekPayload was found`);
      this.seekToMs(shouldSeekPayload.seekToMs);
    }
    if (this.isPlayable()) {
      qaMediaPlayerLog(`MNWT ${this}▶️ isPlayable()`);
      // Skip all the timer stuff if the assets are just a freeze frame
      await this.startTimers();
    }
    return;
  };

  pause = (): void => {
    qaMediaPlayerLog(`MNWT PAUSE was called`);
    if (this.getState() === State.PAUSED) {
      qaMediaPlayerLog(`MNWT pause() ${this}⏸ ❌ (already paused)`);
      return;
    }
    qaMediaPlayerLog(`MNWT ${this}⏸`);
    this.clearTimers();
    const timeElapsedPlaying = Date.now() - this.getLastPlayedAt();
    this.setTimerElapsedTimeMs(this.timerTimeElapsedMs + timeElapsedPlaying);
    this.setLastPausedAtMs(Date.now());
    this.pauseMedia();
    return;
  };

  reset = (): void => {
    this.clearTimers();
    this.lastPlayedAtMs = undefined;
    this.lastPausedAtMs = undefined;
    this.timerTimeElapsedMs = 0;

    for (const id of TIMERS) {
      this.timers[id].triggered = false;
    }
  };

  /**
   * ----------------------------------------
   * Component internals
   */

  private playMedia = async (): Promise<boolean> => {
    qaMediaPlayerLog(`Trying to play`, this.getNode().current);
    // Attempt to play multiple times
    const MAX_ATTEMPTS = 3;
    let attempt = 0;
    while (attempt < MAX_ATTEMPTS) {
      attempt++;
      try {
        await this.getNode().current?.play();
        this.handlePlaySuccess();
        attempt = MAX_ATTEMPTS;
        qaMediaPlayerLog(`Trying to play...DONE`);
      } catch (error) {
        if (attempt >= MAX_ATTEMPTS) {
          this.handlePlayFail(error);
          return false;
        }
      }
    }

    return true;
  };

  pauseMedia = (): void => {
    qaMediaPlayerLog(`MNWT pauseMedia was called`);
    this.getPlayer()?.pause();
  };

  private seekToMs = (toMs: number): void => {
    qaMediaPlayerError(`MNWT setToMS called with: ${toMs}`);
    this.getPlayer()?.setCurrentTime(toMs / 1000);
  };

  /**
   * ----------------------------------------
   * Event handlers
   */

  private handlePlaySuccess = (): void => {
    if (typeof this.onPlaySuccess === 'function') {
      this.onPlaySuccess();
    }
  };

  private handlePlayFail = (error: DOMException): void => {
    qaMediaPlayerError(`handlePlayFail: ${error}`);
    this.clearTimers();
    // Store this value as we've probably interrupted the timers
    this.lastPlayedAtMs = Date.now();
    this.pause();
    if (typeof this.onPlayFail === 'function') {
      this.onPlayFail(error);
    }
  };

  private handleEnd = (): void => {
    qaMediaPlayerLog(`MNWT handleEnd was called`);
    if (typeof this.onEnd === 'function') {
      this.onEnd(this);
    }
  };

  private handleMediaEndTimerEnd = async (): Promise<void> => {
    qaMediaPlayerLog(`MNWT handleMediaEndTimerEnd was called`);
    this.clearTimers();
    try {
      this.pauseMedia();
      this.handleEnd();
    } catch (e) {
      // @TODO: try to determine why exactly this happens
      // @WHEN: when it becomes important
      qaMediaPlayerError(`MNWT ERROR: ${e}`);
    }
    if (typeof this.onAction === 'function') {
      await this.onAction();
    }
    return;
  };

  /**
   * ----------------------------------------
   * Timers
   *
   */

  private startTimers = async (): Promise<void> => {
    const state = this.getState();
    if (state === State.PLAYING) {
      qaMediaPlayerLog(
        `MNWT ${this}.startTimers() NO (already playing, don't play again)`
      );
      return; // Don't play again
    }
    this.clearTimers();

    // Re-initiate play/pause times
    this.setLastPlayedAtMs(Date.now());
    this.setLastPausedAtMs(undefined);
    qaMediaPlayerLog(`MNWT ${this}.startTimers()`);

    await this.startTimer(TimerId.mediaStartTimer);
    // when start timer has to be executed straight away and it fails
    // the end timer doesn't need to be set
    // the only exception are seamless videos
    if (this.timers[TimerId.mediaStartTimer].status !== TimerStatus.ERROR) {
      await this.startTimer(TimerId.mediaEndTimer);
    } else if (this.props.seamless) {
      await this.startTimer(TimerId.mediaEndTimer);
    }
    return;
  };

  private startTimer = async (id: TimerId): Promise<void> => {
    const remainingTime = this.getEndAtMsForTimer(id) - this.getTimeElapsed();

    if (remainingTime <= 0) {
      qaMediaPlayerLog(
        `MNWT ${this}.startTimer: ${id} (remainingTime:${remainingTime})`
      );
      await this.triggerTimerAction(id);
      return;
    }
    this.clearTimer(id);
    qaMediaPlayerLog(
      `MNWT ${this}.SetTimeout: ${id} (remainingTime:${remainingTime})`
    );
    this.timers[id].timeout = new SetTimeout(async () => {
      qaMediaPlayerLog(
        `MNWT ${this}.triggerTimerAction: ${id} (remainingTime:${remainingTime})`
      );

      await this.triggerTimerAction(id);
      this.clearTimer(id);
    }, remainingTime);
    return;
  };

  private triggerTimerAction = async (id: TimerId): Promise<void> => {
    qaMediaPlayerLog(`MNWT triggerTimerAction called with ID: "${id}"`);
    if (id === TimerId.mediaStartTimer) {
      qaMediaPlayerLog(`MNWT triggerTimerAction setting triggered to FALSE`);
      this.timers[TimerId.mediaEndTimer].triggered = false; // Fixes SLEEPIO-900 (When auto-play on, blocks "trigger check" below )
      // We could get to this when the delay is zero and the user has play/paused/played
      // but we're beyond the end of the video so we don't want to restart
      if (
        this.getEndAtMsForTimer(TimerId.mediaEndTimer) > this.getTimeElapsed()
      ) {
        qaMediaPlayerLog('playMedia...');
        this.timers[id].status = (await this.playMedia())
          ? TimerStatus.SUCCESS
          : TimerStatus.ERROR;
        qaMediaPlayerLog(
          `playMedia...DONE with status of: "${this.timers[id].status}"`
        );
      }
      // eslint-disable-next-line prettier/prettier
    } /*if  (id === TimerId.mediaStartTimer) */ else {
      qaMediaPlayerLog(`MNWT triggerTimerAction in ELSE STATEMENT`);
      if (this.timers[id].triggered !== true /* (@see SLEEPIO-900) */) {
        qaMediaPlayerLog(`MNWT triggerTimerAction setting triggered to TRUE`);
        this.timers[id].triggered = true;
        // We shouldn't need this check but it's safer this way
        // 2023-06-28: After upgrading to React 18, it is possible for the next scene's start timer to trigger before the below await call resolves.
        // Therefore, until we have refactored this logic to avoid race conditions, the await call below must be the last thing executed in this method.
        // Any updates to timers made after awaiting handleMediaEndTimerEnd may affect the timers being used by a subsequent scene.
        await this.handleMediaEndTimerEnd();
        qaMediaPlayerLog(`MNWT ${this}.handleMediaEndTimerEnd: ${id}`);
      } else {
        qaMediaPlayerLog(
          `MNWT ${this}.handleMediaEndTimerEnd: ${id} ❌ (triggeredState:${this.timers[id].triggered})`
        );
      }
    }
    return;
  };

  private clearTimers = (): void => {
    this.clearTimer(TimerId.mediaEndTimer);
    this.clearTimer(TimerId.mediaStartTimer);
  };

  private clearTimer = (id: TimerId): void => {
    const timeout = this.timers[id].timeout;
    if (timeout) {
      timeout.clearTimeout();
      this.timers[id].timeout = undefined;
    }
    this.timers[id].status = TimerStatus.IDLE;
  };

  /**
   * ----------------------------------------
   * Public setters
   *
   */

  setOnPlayFailHandler = (onPlayFail: OrchestratorFailCallback): void => {
    this.onPlayFail = onPlayFail;
  };

  setOnPlaySuccessHandler = (
    onPlaySuccess: OrchestratorSuccessCallback
  ): void => {
    this.onPlaySuccess = onPlaySuccess;
  };

  setOnEndHandler = (onEnd: OrchestratorEndCallback): void => {
    this.onEnd = onEnd;
  };

  setOnActionHandler = (onAction: ActionHandlerCallback): void => {
    this.onAction = onAction;
  };

  setPlayLock = (): void => {
    this.playLock = true;
  };

  releasePlayLock = (): void => {
    this.playLock = false;
  };

  setProps = async (nextProps: AVProps): Promise<void> => {
    const newSubsetProps = MediaNodeWithTimer.getSubsetOfProps(nextProps);
    const oldSubsetProps = MediaNodeWithTimer.getSubsetOfProps(this.props);

    // NOTE(Luca - 2022-06-09): if the next subset of props is identical to the current subset of props,
    // it means that the current node doesn't need to be updated.
    // There is prior art where we are not considering 'src' in the subset of the props, because some tests were failing:
    // https://github.com/sleepio/react-native-app/commit/b07dd2cf82ef0e79ba79967769f7e0cdf278e922
    //
    // However, it causes issues with two consecutive assets that have the same subset of props
    // but different 'src' prop. In that scenario, the props are not updated
    // and the node is not played, causing the app to never consume the asset.
    if (equals(oldSubsetProps, newSubsetProps)) {
      // Don't do anything
      return;
    }

    const nextTo = nextProps.to;
    const nextFrom = nextProps.from;
    const nextDelay = nextProps.delay;
    const isFreezeFrame = nextFrom === nextTo;
    const isContinuous = this.props.isContinuous;
    const doesJump = this.props.to !== nextProps.from;

    this.props = clone(nextProps);
    this.reset();
    if (isContinuous) {
      // Let the media keep playing but reset everything else and start the timers
      // again for the next actions
      qaMediaPlayerLog(
        `MNWT ${this}.setProps() startTimers() isContinuous......`
      );
      await this.startTimers();
      qaMediaPlayerLog(
        `MNWT ${this}.setProps() startTimers() isContinuous...DONE`
      );
      return;
    }
    if (nextDelay > 0) {
      qaMediaPlayerLog(
        `MNWT ${this}.setProps() pauseMedia() nextDelay > 0......`
      );
      // Pause the media and then make the timers restart using the new values
      qaMediaPlayerLog(
        `MNWT ${this}.setProps() pauseMedia() nextDelay > 0...DONE`
      );
      this.pauseMedia();
      await this.play();

      return;
    }
    if (isFreezeFrame) {
      qaMediaPlayerLog(
        `MNWT ${this}.setProps() pauseMedia() freezeFrame......`
      );
      if (doesJump) {
        this.seekToMs(nextFrom * 1000);
        qaMediaPlayerLog(
          `MNWT ${this}.setProps() pauseMedia() freezeFrame......does jump....`
        );
      }
      qaMediaPlayerLog(
        `MNWT ${this}.setProps() pauseMedia() freezeFrame......DONE`
      );
      this.pauseMedia();
      return;
    }
    // Else just play
    await this.play();

    return;
  };

  /**
   * ----------------------------------------
   * Private setters
   *
   */

  private setLastPlayedAtMs = (time: number | undefined): void => {
    this.lastPlayedAtMs = time;
  };

  private setLastPausedAtMs = (time: number | undefined): void => {
    this.lastPausedAtMs = time;
  };

  private setTimerElapsedTimeMs = (time: number): void => {
    this.timerTimeElapsedMs = time;
    qaMediaPlayerLog(`MNWT ${this}.setTimer ${time}`);
  };

  /**
   * ----------------------------------------
   * Public getters
   */

  getProps = (): AVProps => this.props;

  static getPropsForNode = (node: AVRefNode): AVProps =>
    (node.current as PlatformAVPrimitive).props;

  static getSubsetOfProps = (props: AVProps): SubsetProps => {
    return {
      delay: props.delay as number,
      from: props.from as number,
      to: props.to as number,
      action: props.action as SceneAction,
      isContinuous: props.isContinuous,
      src: props.src,
      captionSrc: props.captionSrc,
    };
  };

  getNode = (): AVRefNode => this.refNode;

  getPlayer = (): PlatformAVPrimitive =>
    this.getNode()?.current as PlatformAVPrimitive;

  /**
   * ----------------------------------------
   * Private getters
   */

  private getMediaStartAtMs = (): number => (this.props.delay || 0) * 1000;

  private getMediaEndAtMs = (): number =>
    (this.props.delay + this.props.to - this.props.from) * 1000;

  private getEndAtMsForTimer = (id: TimerId): number => {
    if (id === TimerId.mediaStartTimer) {
      return this.getMediaStartAtMs();
    } else {
      return this.getMediaEndAtMs();
    }
  };

  private isPlayable = (): boolean => this.props.from !== this.props.to;

  private getTimeElapsed = (): number => {
    return this.timerTimeElapsedMs;
  };

  private getLastPlayedAt = (): number =>
    this.lastPlayedAtMs || -10000000000000;

  private getState = (): State => {
    // Important: we literally want `this.lastPlayedAtMs` here and not the getter value
    if (!this.lastPlayedAtMs) {
      qaMediaPlayerLog(`MWWT ${this} getState() UNINITIALIZED`);
      return State.UNINITIALIZED;
    }
    if (this.lastPausedAtMs) {
      qaMediaPlayerLog(`MWWT ${this} getState() PAUSED`);
      return State.PAUSED;
    }
    qaMediaPlayerLog(`MWWT ${this} getState() PLAYING`);
    return State.PLAYING;
  };

  /**
   * Returns whether or not to seek to a specific point in time, or to just allow
   * the native video to play from where it was last paused
   */
  private getShouldSeek = (): SeekToMsPayload => {
    const { delay, from, to } = this.getProps();
    const delayMs = delay * 1000;
    const fromMs = from * 1000;
    const toMs = to * 1000;
    const totalMediaTimeMs = delayMs + toMs - fromMs;
    if (this.timerTimeElapsedMs >= totalMediaTimeMs) {
      // I.e. we're done
      return { shouldSeek: true, seekToMs: toMs };
    }
    if (this.timerTimeElapsedMs >= delayMs) {
      // I.e. we're still playing the video do not seek but let it play
      return { shouldSeek: false };
    }
    // I.e. we've either not started or not got into the playing phase yet
    return { shouldSeek: true, seekToMs: fromMs };
  };

  /**
   * Custom stringify function
   */
  toString(): string {
    const { src, to, from } = this.getProps() || {};
    const t0 = `${from}`.padStart(4, ' ');
    const t1 = `${to}`.padStart(4, ' ');
    const [label] = src?.split('-').reverse() || [];
    return `${label} [${t0} - ${t1}]`;
  }
  /**
   * ----------------------------------------
   * For use in testing only
   */

  __getState = (): State => this.getState();

  __getTimerTimeElapsed = (): number => this.timerTimeElapsedMs;

  __getTimersState = (): TimersState => this.timers;

  __getTimerStartAtMs = (): number | undefined => this.lastPlayedAtMs;

  __getTimerPausedAtTimeMs = (): number | undefined => this.lastPausedAtMs;

  __getCurrentTime = (): number => Date.now();

  __getPlayLock = (): boolean => this.playLock;

  __triggerTimerAction = this.triggerTimerAction;
}
