import styled from 'styled-components/native';

import { Grid as GridRaw } from 'components/layout/Grid';
import { ScaleYValueProps } from 'sleepio/components/screens/SessionsScreen/utils/scaler';

import { DOMAIN } from './constants';

export const Grid = styled(GridRaw)<ScaleYValueProps>`
  width: 100%;
`;
Grid.displayName = `${DOMAIN}.Grid`;

export const HeadingRow = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;
HeadingRow.displayName = `${DOMAIN}.HeadingRow`;
