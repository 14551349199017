import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const enable_otp = async (
  requestConfig?: AxiosRequestConfig
): Promise<enable_otp.Response> => {
  const request: enable_otp.Request = {
    service_name: 'UserAccount',
    service_version: '1',
    service_method: 'enable_otp',
    args: {},
  };

  return await api(request, requestConfig);
};

enable_otp.queryKey = 'UserAccount/enable_otp';

export declare namespace enable_otp {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'UserAccount';
    service_version: '1';
    service_method: 'enable_otp';
    args: Args;
  }

  export type Result = {
    otp_secret: string;
    otp_uri: string;
  };

  export type Response = APIResponse<Result>;
}
