import { DaylightPracticeLibraryPayload } from '@bighealth/types';
import { AxiosRequestConfig } from 'axios';
import { api } from '../../api';
import { APIRequestBody, APIResponse } from '../../types';

export const get_practice_library_data = async (
  args: get_practice_library_data.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_practice_library_data.Response> => {
  const request: get_practice_library_data.Request = {
    service_name: 'DaylightPracticeLibrary',
    service_version: '2',
    service_method: 'get_practice_library_data',
    args,
  };

  return await api(request, requestConfig);
};

get_practice_library_data.queryKey =
  'DaylightPracticeLibrary/get_practice_library_data';

export declare namespace get_practice_library_data {
  export type Args = {
    program_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'DaylightPracticeLibrary';
    service_version: '2';
    service_method: 'get_practice_library_data';
    args: Args;
  }

  export type Result = DaylightPracticeLibraryPayload;

  export type Response = APIResponse<Result>;
}
