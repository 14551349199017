import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { isEmpty, mergeAll } from 'ramda';
import styled from 'styled-components';

import { MarkdownSceneComponent } from '@bighealth/api/SessionProgress/v1';
import { StyleObject } from '@bighealth/types/dist/scene-components/client';

import { CustomLinkProps, CustomLinkRenderer } from './linkRenderer';

export type CustomLinkComponent = FunctionComponent<CustomLinkProps>;

type CustomRenderers = {
  [key: string]: FunctionComponent<CustomComponentProps>;
};

type MarkdownNodePosition = {
  end: { line: number; column: number; offset: number };
  start: { line: number; column: number; offset: number };
};

type MarkdownNode = {
  type: string;
  url?: string;
  value?: string;
  title?: string | null;
  children: MarkdownNode[];
  position: MarkdownNodePosition;
};

type CustomComponentProps = {
  href?: string;
  node: MarkdownNode;
  style?: StyleObject;
  children: ReactNode;
};

const filterStyles = (styles: (StyleObject | undefined)[]): StyleObject => {
  const filteredStyles: StyleObject[] = [];
  for (const style of styles) {
    if (typeof style !== 'undefined' && !isEmpty(style)) {
      filteredStyles.push(style);
    }
  }
  return mergeAll(filteredStyles);
};

const getCustomComponent = (
  Component: FunctionComponent | CustomLinkComponent,
  style?: StyleObject
): FunctionComponent<CustomComponentProps> => {
  const CustomComponent = (props: CustomComponentProps): ReactElement => {
    return <Component {...props} style={filterStyles([props.style, style])} />;
  };

  return CustomComponent;
};

// this will only be used on web, so creating a div component
const BaseComponent = styled.div``;
BaseComponent.displayName = 'BaseComponent';

const ListComponent = styled.ul`
  /* these properties are hard to change and then can be added using regular margin */
  margin-block-end: 0px;
  margin-block-start: 0px;
`;
ListComponent.displayName = 'ListComponent';

const ListItemComponent = styled.li``;
ListItemComponent.displayName = 'ListItemComponent';

export const getCustomRenderers = (
  style?: MarkdownSceneComponent['style']
): CustomRenderers => {
  const customRenderers: CustomRenderers = {};
  for (const key in style) {
    if (key === 'link') {
      customRenderers[key] = getCustomComponent(CustomLinkRenderer, style[key]);
    } else if (key === 'list') {
      customRenderers[key] = getCustomComponent(ListComponent, style[key]);
    } else if (key === 'listItem') {
      customRenderers[key] = getCustomComponent(ListItemComponent, style[key]);
    } else {
      customRenderers[key] = getCustomComponent(BaseComponent, style[key]);
    }
  }

  if (!Object.prototype.hasOwnProperty.call(customRenderers, 'link')) {
    customRenderers['link'] = getCustomComponent(CustomLinkRenderer);
  }

  return customRenderers;
};
