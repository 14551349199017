import styled from 'styled-components/native';

const Nav = styled.View<{ isSmall: boolean }>`
  position: relative;
  width: 100px; /* From figma */
  height: 35px; /* From figma */
  right: 20px; /* From figma */
  flex-direction: row;
  align-self: flex-end;
`;

export { Nav };
