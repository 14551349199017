import { STAGE } from 'config/envVars';

export const getAssetDomain = (stage: typeof STAGE) => {
  switch (stage) {
    case 'local':
    case 'dev':
      return `https://app.sleepiodev.com`;
    case 'ci':
    case 'qa':
    case 'stage':
      return `https://app.sleepio${stage}.com`;
    case 'prod':
      return `https://app.sleepio.com`;
  }
};
