// IDEA create data dropdown for testing in ResponseOption/index.story
import { DEVELOPER_COMPONENTS_TEST_SCENESET } from 'developer/data';

import { ResponseInputProps } from '../ResponseInput';

import { sceneSetWithMaxSelection2 } from './sceneSetWithMaxSelection2';

const set1 =
  DEVELOPER_COMPONENTS_TEST_SCENESET.childNodes[4]?.childNodes?.[0]
    ?.childNodes || [];
const set2 =
  DEVELOPER_COMPONENTS_TEST_SCENESET.childNodes[1]?.childNodes?.[1]
    ?.childNodes || [];

const FIELDS: ResponseInputProps[] = ([
  ...set1,
  ...set2,
] as unknown) as ResponseInputProps[];
export { FIELDS };

// Created for testing selection bug in PG-578 QA failure
const fields =
  sceneSetWithMaxSelection2?.result?.scene_set_json?.childNodes?.[0]
    ?.childNodes?.[0]?.childNodes;

export const fieldsWithMaxSelection2 = (fields as unknown) as ResponseInputProps[];
