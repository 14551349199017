import React, { ReactElement, useContext } from 'react';
import { Formik } from 'formik';

import {
  SceneComponentContext,
  SceneComponentContextType,
} from 'components/Scene/SceneComponentContext';

/**
 * A wrapper that supplies a NOOP formik context to allow components that would ordinarily
 * use Formik to trigger forms-related stuff (for instance Questions with a Modal) to
 * be positioned outside a modal and cause no harm
 * @param props
 * @param Component - the React component you
 * @constructor
 */
export function WithBackupFormikContext<Props>(
  props: Props,
  Component: React.ComponentType<any>
): ReactElement {
  const componentContext = useContext(SceneComponentContext);
  // If we're in a Modal, no need to wrap it in a no-op Formik provider
  if (componentContext === SceneComponentContextType.Modal) {
    return <Component {...props} />;
  }
  // Else wrap in a no op
  return (
    <Formik initialValues={{}} initialErrors={{}} onSubmit={() => undefined}>
      <Component {...props} />
    </Formik>
  );
}
