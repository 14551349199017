import { SceneChild, SceneSet } from '@bighealth/types';

/**
 * Returns whether captions_storage_url is used in a given SceneSet
 * @param sceneSet
 */
export const sceneSetHasCaptionsStorageUrl = (sceneSet?: SceneSet): boolean => {
  if (!sceneSet) {
    return false;
  }
  let hasCaptionsStorageUrl = false;

  // @TODO replace with deepMapWhere
  // WHEN before merge into develop
  // @see https://github.com/sleepio/react-native-app/blob/legacy-daylight/src/common/lib/deepMapWhere/index.test.js
  // @TODO Replace SceneChild with new interfaces.
  // WHEN ASAP
  const traverseTree = ({
    childNodes: rootChildNodes,
    component: rootComponent,
    sourceProps,
  }: SceneChild & {
    sourceProps?: {
      captions_storage_url?: string;
    };
  }): SceneChild => {
    if (
      sourceProps?.captions_storage_url !== undefined &&
      sourceProps?.captions_storage_url !== null &&
      sourceProps?.captions_storage_url?.trim() !== ''
    ) {
      hasCaptionsStorageUrl = true;
    }

    // Pretty much returns the same thing (for convenience)
    return {
      component: rootComponent,
      sourceProps,
      childNodes: rootChildNodes
        ? rootChildNodes.map(
            ({ component, childNodes, sourceProps }): SceneChild => {
              return traverseTree({
                childNodes,
                component,
                sourceProps: sourceProps as {
                  captions_storage_url?: string;
                },
              });
            }
          )
        : undefined,
    };
  };

  // @TODO: Remove this unknown and sort out the definition of SceneSet
  traverseTree((sceneSet as unknown) as SceneChild); // We're treating the SceneSet like a childNode here (for convenience)

  return hasCaptionsStorageUrl;
};

export default sceneSetHasCaptionsStorageUrl;
