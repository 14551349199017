import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { IconStyles } from './types';

export const Warning = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" style={style}>
      <G stroke="none" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.732 2c-.77-1.333-2.694-1.333-3.464 0L.742 18.5c-.77 1.333.192 3 1.732 3h19.052c1.54 0 2.502-1.667 1.732-3L13.732 2zm-.456 5H10.45v8.208h2.826V7zM10.54 19.222c.36.36.798.54 1.314.54.528 0 .972-.18 1.332-.54.36-.372.54-.81.54-1.314s-.18-.936-.54-1.296a1.81 1.81 0 00-1.332-.54c-.516 0-.954.18-1.314.54-.36.36-.54.792-.54 1.296s.18.942.54 1.314z"
          fill={style?.color || 'currentColor'}
        />
      </G>
    </Svg>
  );
};
