import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { ReactElement, ReactNode } from 'react';

import { useLatestFocusedQuestion } from 'lib/question-response/useLatestFocusedQuestion';

type FocusFunctions = {
  onBlur: () => void;
  onFocus: () => void;
  questionInFocus: boolean;
  questionId: number | undefined;
};

const initialValue: FocusFunctions = {
  onBlur: () => null,
  onFocus: () => null,
  questionId: undefined,
  questionInFocus: false,
};

const InputFocusContext = createContext(initialValue);

export const useInputFocusContext = (): FocusFunctions =>
  useContext(InputFocusContext);

export const InputFocusProvider = ({
  children,
  questionId,
}: {
  children: ReactNode;
  questionId: number | undefined;
}): ReactElement => {
  const {
    currentFocusedQuestionId,
    setLatestFocusedQuestion,
  } = useLatestFocusedQuestion();

  const onBlur = useCallback(() => {
    setLatestFocusedQuestion(undefined);
  }, [setLatestFocusedQuestion]);

  const onFocus = useCallback(() => {
    if (currentFocusedQuestionId !== questionId) {
      setLatestFocusedQuestion(questionId);
    }
  }, [currentFocusedQuestionId, questionId, setLatestFocusedQuestion]);

  const questionInFocus = useMemo(() => {
    return questionId === currentFocusedQuestionId;
  }, [questionId, currentFocusedQuestionId]);

  return (
    <InputFocusContext.Provider
      value={{ onBlur, onFocus, questionInFocus, questionId }}
    >
      {children}
    </InputFocusContext.Provider>
  );
};
