import styled from 'styled-components/native';

import { Row as GridRow } from 'components/layout/Grid';
import { Text } from 'components/sceneset-components/Text';
import {
  scaler,
  ScaleValueProps,
  ScaleYValueProps,
  yScalerWithOptions,
} from 'sleepio/components/screens/SessionsScreen/utils/scaler';

const DOMAIN = 'DiaryCard';

export const Wrapper = styled.View`
  align-items: center;
`;

Wrapper.displayName = `${DOMAIN}.Wrapper`;

export const BackgroundContainer = styled.View`
  padding: 0px 16px;
  margin-bottom: 16px;
  align-items: center;
  width: 100%;
  max-width: 720px;
`;
BackgroundContainer.displayName = `${DOMAIN}.BackgroundContainer`;

export const QuestionWrapper = styled.View`
  width: 100%;
  background-color: ${props =>
    props.theme.color.background.sleepEfficiencyCard};
  color: ${props => props.theme.color.white};
  border-radius: 8px;
`;
QuestionWrapper.displayName = `${DOMAIN}.QuestionWrapper`;

export const Row = styled(GridRow)`
  width: 100%;
  align-content: flex-end;
  justify-content: center;
`;
Row.displayName = `${DOMAIN}.Row`;

export const scalerVertical = yScalerWithOptions({ minScale: 0.6 });
export type HeadingTextProps = React.ComponentProps<typeof Text> &
  ScaleValueProps &
  ScaleYValueProps;

export const HeadingText = styled(Text).attrs({
  // See getTextAlignmentConfig
  alignment: { horizontal: 'middle' },
})<HeadingTextProps>`
  margin-top: 16px;
  font-size: 22px;
  max-width: ${scaler(720)}px;

  min-width: ${168}px;
  font-weight: 400;
  color: ${props => props.theme.color.white};
`;
HeadingText.displayName = `${DOMAIN}.HeadingText`;

type TitleTextProps = React.ComponentProps<typeof Text> &
  ScaleValueProps &
  ScaleYValueProps;

// FIXME
// When SLEEPIO-1989 gets merged in, update the color to use SALMON
export const TitleText = styled(Text).attrs({
  // See getTextAlignmentConfig
  alignment: { horizontal: 'middle' },
})<TitleTextProps>`
  margin-top: 16px;
  font-size: 14px;
  max-width: ${scaler(720)}px;
  min-width: ${168}px;
  font-weight: 700;
  color: #ff9a84;
`;
TitleText.displayName = `${DOMAIN}.TitleText`;
