import React, { ReactElement } from 'react';
import { ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';

import { closeButtonMargin } from 'components/PopupModal/constants';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';

import {
  CloseButton as CloseButtonUnstyled,
  Props as CloseButtonProps,
} from '../../CloseButton';

export const CloseButton = (props: CloseButtonProps): ReactElement => {
  const safeArea = useSafeArea();
  const styles = useGetDynamicContentStyles();

  const closeButtonStyle: ViewStyle = {
    position: 'absolute',
    right: closeButtonMargin + safeArea.right,
    top: styles.sleepDiaryFormCloseButtonPaddingPortrait,
  };

  return (
    <CloseButtonUnstyled
      {...roles('CloseButton')}
      {...props}
      style={closeButtonStyle}
    />
  );
};
