import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

/**
 * Progress line
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const ProgressLine = ({
  color,
  height,
}: {
  color: string;
  height?: number | string;
}): ReactElement => (
  <Svg width="3" height={height} fill="none">
    <G fill="none" fillRule="evenodd">
      <Path
        d={`M1.81142 ${height}L1.28204 0.354004`}
        stroke={color}
        strokeWidth="2"
      />
    </G>
  </Svg>
);
