import React, { memo, ReactElement, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import styled from 'styled-components/native';

import * as roleProps from 'cross-platform/utils/roleProps';

import { DEFAULT_DURATION_MS, DOMAIN, USE_NATIVE_DRIVER } from './constants';
import { ThrobberProps } from './types';

const Rotation = styled(Animated.View)``;
Rotation.displayName = `${DOMAIN}.Rotation`;

const Throbber = memo(
  ({
    size,
    fill,
    duration = DEFAULT_DURATION_MS,
  }: ThrobberProps): ReactElement | null => {
    const rotateAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
      const animation = Animated.loop(
        Animated.timing(rotateAnim, {
          toValue: 1,
          duration,
          useNativeDriver: USE_NATIVE_DRIVER,
        })
      );
      animation.start();
      return () => animation.reset();
    }, [duration, rotateAnim]);

    return (
      <Rotation
        {...roleProps.roles('throbber')}
        style={{
          transform: [
            {
              rotate: rotateAnim.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', '360deg'],
              }),
            },
          ],
        }}
      >
        <Svg
          id="throbber"
          width={`${size}px`}
          height={`${size}px`}
          viewBox="0 0 50 50"
        >
          <Path
            fill={fill}
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
          />
        </Svg>
      </Rotation>
    );
  }
);

Throbber.displayName = 'Throbber';

export { Throbber };
