import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { View, ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { subDays } from 'date-fns';
import { useFormikContext } from 'formik';
import { useTheme } from 'styled-components';

import { Question, ResponseOption } from '@bighealth/types/src/models';
import { SleepDiaryFormProps } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import accessibilityElements from 'common/constants/accessibilityElements';
import { Errors, OnValidateCallback } from 'components/forms';
import { ResponseForm } from 'components/forms/ResponseOptions/ResponseForm';
import { castDropdownItemsByResponseType } from 'components/forms/ResponseOptions/ResponseForm/utils/castDropdownItemsByResponseType';
import { castResponseOptionByResponseType } from 'components/forms/ResponseOptions/ResponseForm/utils/castResponseOptionByResponseType';
import {
  ResponseInput,
  ResponseInputProps,
} from 'components/forms/ResponseOptions/ResponseInput';
import { ResponseInputState } from 'components/forms/ResponseOptions/ResponseInput/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { contentPaddingRight } from 'components/PopupModal/constants';
import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { WarningIconSvg } from 'components/Screens/ContentScreens';
import { useSleepDiaryActionHandlerFactory } from 'components/SleepDiaryForm/hooks/useSleepDiaryActionHandlerFactory';
import { WarningText } from 'components/Text/WarningText';
import {
  ButtonColorSchemes,
  ButtonProps,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';
import { isPortrait } from 'lib/isPortrait';
import { TrackableMessagingEventNames } from 'lib/messaging';
import { trackEvent } from 'lib/messaging/messagingUtils';
import { qaConfigFlags } from 'lib/showQAMenu/qaConfigFlags';
import { stringify } from 'lib/stringify';
import { mergeDeepAndByIndex } from 'lib/utils/mergeDeepAndByIndex';
import { QuestionId } from 'state/question-response/actions';

import {
  HeaderView,
  Heading,
  SectionHeading,
  SectionHeadingBottomPadding,
  SectionHeadingPadding,
  SectionHeadingView,
  SectionSubHeading,
  SectionWrapper,
} from '../../styled';
import { getFlowState } from '../FlowingForm/helpers/getFlowState';
import { useSubmitCallbackContext } from '../NetworkedFlowingForm/providers/SubmitCallbackProvider';

import { parseDiaryDateStr } from './helpers/parseDiaryDateStr';
import { transformBySemanticId } from './helpers/transformBySemanticId';
import {
  ButtonContainer,
  WarningContainer,
  WarningTextPadding,
  Wrapper,
} from './styled';

export type SleepDiaryOptionalProps = {
  onSubmit?: (
    values: Record<React.ReactText, ResponseOption[]>,
    onSuccess?: () => void
  ) => void;
  onFormClose?: () => void;
};

type SectionGroupType = PropsWithChildren<{
  data: ResponseInputProps[];
  diaryDateStr?: string;
  fieldProps: ReturnType<typeof getFlowState>;
}>;

const SectionGroup = ({
  data,
  fieldProps,
  diaryDateStr,
}: SectionGroupType): ReactElement => (
  <>
    {data.map((el, i) => {
      if (el?.questionProps && el?.component) {
        let defaultState: ResponseInputState | undefined;
        if (
          ['DropdownTimePicker', 'SelectHorizontalTime'].includes(
            el.component
          ) &&
          typeof diaryDateStr === 'string'
        ) {
          let diaryDate = parseDiaryDateStr(diaryDateStr);
          // the sleep diary starts the night before
          diaryDate = subDays(diaryDate, 1);
          defaultState = {
            date: diaryDate,
          };
        }

        const fieldProp = fieldProps[el.questionProps.semantic_id];
        const questionProps = fieldProp?.questionProps
          ? (mergeDeepAndByIndex(
              el.questionProps,
              fieldProp?.questionProps || {}
            ) as Question)
          : el.questionProps;
        return (
          <ResponseInput
            key={
              // IDEA Remove String() call below, WHEN id is non-optional
              el.questionProps.semantic_id + String(el.questionProps.id) + i
            }
            state={fieldProp?.state || defaultState}
            warning={fieldProp?.warning}
            highlight={fieldProp?.highlight}
            initialValue={fieldProp?.initialValue}
            component={
              !qaConfigFlags.getValue('SD: Force SelectHorizontal')
                ? el.component
                : ['DropdownTimePicker'].includes(el.component)
                ? 'SelectHorizontalTime'
                : ['Dropdown', 'DropdownDurationPicker'].includes(el.component)
                ? 'SelectHorizontal'
                : el.component
            }
            questionProps={transformBySemanticId(questionProps)}
          />
        );
      }
      return null;
    })}
  </>
);

type ButtonFactoryType = {
  buttonProps: ButtonProps;
  onPress: () => void;
  diaryDate?: string | undefined;
};

type FormTypes = SleepDiaryFormProps &
  SleepDiaryOptionalProps &
  RoleProps &
  Partial<{
    onValidate: OnValidateCallback;
    fieldProps: ReturnType<typeof getFlowState>; // IDEA make ResponseInput type?
  }>;

const isSubmitAction = (actionType: string) => {
  return (
    actionType === 'sleep-diary/SUBMIT_AND_CONTINUE' ||
    actionType === 'sleep-diary/SUBMIT_AND_MODAL_CLOSE'
  );
};

/**
 * Creates a button from the button config passed in.
 *
 * The key difference between the buttons is that there are submit buttons and
 * non-submit buttons. Submit buttons have some extra functionality that need
 * to be handled.
 */
const ButtonFactory = ({
  buttonProps,
  onPress,
  diaryDate,
}: ButtonFactoryType): ReactElement => {
  const {
    submitState,
    isSubmitting,
    setSubmitState,
  } = useSubmitCallbackContext();
  const {
    isValid,
    submitForm,
    isSubmitting: isFormikSubmitting,
  } = useFormikContext();
  const programId = useQueryProgram()?.data?.result.id;

  const actionType = buttonProps?.action?.type;
  if (typeof actionType === 'undefined') {
    const buttonPropsStr = stringify(buttonProps);
    throw Error(
      `Expected buttonProps to have action.type, instead got ${buttonPropsStr}`
    );
  }

  const isSubmitButton = isSubmitAction(actionType);
  let submitAction = onPress;

  if (isSubmitButton) {
    submitAction = async () => {
      await submitForm();

      if (
        diaryDate &&
        // @ts-expect-error types need to be updated, payload exists here
        buttonProps.action?.payload?.method_name === 'submit_single_diary_form'
      ) {
        trackEvent(
          TrackableMessagingEventNames.SLEEPIO_SLEEP_DIARY_COMPLETE,
          programId,
          { diary_date: diaryDate }
        );
      }
    };
  }

  // FIXME: remove
  // WHEN: the submission of the form can be controlled
  // because now the form submission cannot be awaited
  // WHY: this code can cause infinite loops if onPress
  // is different on every re-render
  // CURRENT PROBLEM:
  //   * Datadog > https://github.com/DataDog/dd-sdk-reactnative/issues/138
  //   * Workaround > Stringify function to compare.
  //                  This workaround still causes some loops until state
  //                  is fully flushed for it to compare with.
  useEffect(() => {
    if (
      isSubmitButton &&
      submitState.onSubmitDone?.toString() !== onPress.toString()
    ) {
      setSubmitState({ onSubmitDone: onPress });
    }
  }, [
    isSubmitButton,
    onPress,
    setSubmitState,
    submitState,
    submitState.onSubmitDone,
  ]);

  const isDisabled =
    isSubmitButton && (!isValid || isFormikSubmitting || isSubmitting);
  const buttonRole = isDisabled
    ? `${buttonProps.text}-disabled`
    : `${buttonProps.text}`;

  return (
    <UniversalButton
      {...buttonProps}
      {...roles(buttonRole)}
      onPress={submitAction}
      isDisabled={isDisabled}
      text={buttonProps.text}
      size={ButtonSizes.Small}
      colorScheme={ButtonColorSchemes.AltPrimary}
    />
  );
};

const CloseButton = ({
  onClose,
  diaryDate,
  ...buttonProps
}: ButtonFactoryType['buttonProps'] & {
  onClose?: () => void;
  diaryDate?: string | undefined;
}) => {
  const [closeForm, setCloseForm] = useState(false);

  useEffect(() => {
    if (closeForm && typeof onClose === 'function') {
      onClose();
    }
  }, [closeForm, onClose]);

  const closeFormCallback = useCallback(() => {
    setCloseForm(true);
  }, []);

  const sleepDiaryActionHandlerPartial = useSleepDiaryActionHandlerFactory({
    closeForm: closeFormCallback,
  });
  return (
    <ButtonFactory
      buttonProps={buttonProps}
      diaryDate={diaryDate}
      onPress={sleepDiaryActionHandlerPartial(buttonProps.action) as () => void}
    />
  );
};

const Warning = (): ReactElement => {
  return (
    <WarningContainer>
      <WarningIconSvg />
      <WarningTextPadding />
      <WarningText>
        {'Please double check your times under going to bed.'}
      </WarningText>
    </WarningContainer>
  );
};

/**
 * Just the form
 * @param props
 */
export const Form = (
  props: FormTypes & { onClose?: () => void }
): ReactElement => {
  const { onClose } = props;
  const theme = useTheme();
  const safeArea = useSafeArea();

  const initialAccumulator: Question[] = [];
  const questions = props.form.sections.reduce(
    (
      accumulator, // Previous value used for accumulation
      section // Current section
    ) => [
      ...accumulator,
      ...section.group.map(({ questionProps }) => questionProps),
    ],
    initialAccumulator
  );

  const styles = useGetDynamicContentStyles();
  const screenOrientation = useScreenOrientation();

  const contentContainerStyle: ViewStyle = {
    paddingRight: isPortrait(screenOrientation)
      ? styles.sleepDiaryFormHeadingHorizontalPaddingPortrait
      : contentPaddingRight + safeArea.right,
    paddingLeft: isPortrait(screenOrientation)
      ? styles.sleepDiaryFormHeadingHorizontalPaddingPortrait
      : styles.sleepDiaryFormHeadingHorizontalPadding,
    paddingBottom: styles.sleepDiaryFormSaveButtonBottomPadding,
  };

  return (
    <Wrapper>
      <HeaderView>
        <Heading
          {
            ...roles(`SDHeading`) /* for e2e */
          }
          accessibility={accessibilityElements.H1}
          {...(props?.date_label || props.form.heading)}
          style={{
            color: theme.color.overlay.primary,
            fontSize: 18,
            fontWeight: '400',
            alignSelf: 'center',
          }}
        />
      </HeaderView>

      <View style={contentContainerStyle}>
        <ResponseForm
          onSubmit={values => {
            props.onSubmit?.(
              castResponseOptionByResponseType(values, questions)
            );
          }}
          onValidate={(items: Record<QuestionId, DropdownItem[]>): Errors =>
            props?.onValidate?.(
              castDropdownItemsByResponseType(items, questions)
            ) || {}
          }
          triggerValidateHash={Object.entries(props?.fieldProps || {})
            .map(([questionId, val]) => `${questionId}: ${val?.hidden}`)
            .join(', ')}
        >
          {props.form.sections.map(section => (
            <SectionWrapper
              {
                ...roles(`section:${section.heading.text}`) /* for e2e */
              }
              key={section.heading.text}
            >
              <SectionHeading
                {...section.heading}
                style={{ color: theme.color.overlay.primary, fontSize: 18 }}
                accessibility={accessibilityElements.H2}
              />
              {typeof section.sub_heading?.text !== 'string' ? (
                <SectionHeadingBottomPadding />
              ) : (
                <SectionSubHeading
                  {...section.sub_heading}
                  accessibility={accessibilityElements.H3}
                />
              )}
              <SectionHeadingView />
              <SectionHeadingPadding />
              <SectionGroup
                data={section.group.filter(
                  responseInput =>
                    props?.fieldProps?.[responseInput.questionProps.semantic_id]
                      ?.hidden !== true
                )}
                diaryDateStr={props.diaryDateStr}
                fieldProps={props.fieldProps || {}}
              />
            </SectionWrapper>
          ))}
          {props.form.sections
            .reduce<ResponseInputProps[]>(
              (prev, cur) => [...prev, ...cur.group],
              []
            )
            .filter(
              responseInput =>
                props?.fieldProps?.[responseInput.questionProps.semantic_id]
                  ?.highlight
            ).length > 0 ? (
            <Warning />
          ) : null}

          {props.form.buttons.map((button, i) => (
            <ButtonContainer key={i}>
              <CloseButton
                onClose={onClose}
                diaryDate={props.diaryDateStr}
                {
                  // SleepDiaryFormProps['form']['buttons'][number] is subset of ButtonProps
                  ...(button as ButtonProps)
                }
              />
            </ButtonContainer>
          ))}
        </ResponseForm>
      </View>
    </Wrapper>
  );
};
