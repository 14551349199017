import { AnalyticsField } from '@bighealth/analytics';

import { useQuerySendScreenViewedAnalytics } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQuerySendScreenViewedAnalytics';

export const ScreenViewedAnalyticsSender = ({
  field,
}: {
  field: AnalyticsField;
}): null => {
  useQuerySendScreenViewedAnalytics(field);
  return null;
};
