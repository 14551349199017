import { byStringified } from 'lib/fp/predicateFactory/byStringified';

import { getOptions } from './getOptions';
import { getValues } from './getValues';

type Args = {
  selectedValue?: Date;
  visibleCount: number;
  cursorValue?: Date;
  all: Date[];
};

/**
 * Slices array of data into 3 arrays:
 * 1. before visible,
 * 2. visible (a.k.a page), &
 * 3. after visible
 *
 * This is to support a "See previous" and "See more" inputs
 *
 * Example of "See more" (slice at index 2)
 *```tsx
 * {slices.optionSlices[2].length >= 0 ? (
 *   <DropdownWithOptions
 *     value={slices.valueSlices[2]}
 *     onValueChange={onChange}
 *     highlight={highlight}
 *     data={slices.optionSlices[2]}
 *     title="See more"
 *   /> : null}
 *```
 */
export const getDataSlices = ({
  selectedValue,
  visibleCount,
  all,
  cursorValue,
}: Args) => {
  const slice =
    visibleCount < all.length
      ? {
          visibleCount,
          cursor: Math.max(0, all.findIndex(byStringified(cursorValue))),
        }
      : undefined;
  const optionSlices = getOptions({ all, slice });
  const valueSlices = getValues({
    optionSlices,
    selectedValue,
  });
  return { valueSlices, optionSlices };
};
