import styled from 'styled-components/native';

import { DOMAIN } from './constants';

export const Wrapper = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
  showsVerticalScrollIndicator: false,
})`
  background-color: ${props => props.theme.color.white};
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;
