import { useMemo } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import {
  CONTENT_MIN_REFERENCE_DIMENSION,
  contentStyles,
  useGetScreenDimensions,
} from 'components/ResponsiveLayout';

export const useGetNavDimensions = (): {
  navWidth: number;
  marginRight: number;
} => {
  const { screenWidth } = useGetScreenDimensions();
  const insets = useSafeArea();
  const effectiveWidth = screenWidth - insets.left - insets.right;
  const MIN = CONTENT_MIN_REFERENCE_DIMENSION;
  const {
    navMarginRightMin,
    navWidthMax,
    navLowerScaleWidth,
    navMarginRightMaxWidth,
  } = contentStyles;

  let navWidth: number;
  let marginRight: number;
  const scale = effectiveWidth / MIN;
  if (effectiveWidth <= MIN) {
    navWidth = navLowerScaleWidth * scale;
    marginRight = navMarginRightMin * scale;
    // Then just calculate everything as a linear scale
  } else {
    // If it's above then we need to one of two things; either expand the content and lock the margin
    // or expand both content and margin. The signal is the scaled nav reaching the threshold of 400px
    navWidth = navLowerScaleWidth * scale;
    if (navWidth > navWidthMax) {
      const screenWidthAtThreshold = 1240;
      marginRight = Math.min(
        navMarginRightMin + effectiveWidth - screenWidthAtThreshold,
        navMarginRightMaxWidth
      );
      navWidth = navWidthMax;
    } else {
      marginRight = navMarginRightMin;
    }
  }
  return useMemo(() => ({ marginRight, navWidth }), [marginRight, navWidth]);
};
