import React, { ReactElement } from 'react';
import { View } from 'react-native';

import { Text } from 'components/Text';
import { ProgressDot } from 'daylight/components/icons/ProgressDot';

export const ProgressItem = ({
  exercise,
  colorDot,
  sizeDot,
  colorText,
  sizeText,
}: {
  exercise: {
    name_ssg: string;
    times_ssg_completion: number;
  };
  colorDot: string;
  sizeDot: number;
  colorText: string;
  sizeText: number;
}): ReactElement => {
  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: 'white',
      }}
    >
      <ProgressDot size={sizeDot} color={colorDot} />
      <Text
        style={{
          fontSize: sizeText,
          color: colorText,
          opacity: exercise.times_ssg_completion > 0 ? 1 : 0.4,
        }}
      >
        {exercise.name_ssg}
      </Text>
    </View>
  );
};
