import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_diary_daily_detailed_info } from '@bighealth/api/SleepDiary/v1';

export const useQuerySleepDiaryDaysDetail = (
  args: get_diary_daily_detailed_info.Args,
  queryHash: number | undefined
): UseQueryResult<get_diary_daily_detailed_info.Response, APIErrorResponse> => {
  const result = useQuery<
    get_diary_daily_detailed_info.Response,
    APIErrorResponse
  >({
    queryKey: [
      get_diary_daily_detailed_info.queryKey,
      queryHash,
      args.start_date,
    ],
    queryFn: () =>
      get_diary_daily_detailed_info({
        ...args,
      }),
    enabled: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    staleTime: 0,
    cacheTime: 0,
  });

  return result;
};
