/* eslint-disable @typescript-eslint/no-explicit-any */
import { JSONValue } from '@bighealth/types';

export const responseTypeDict = {
  TEXT: 'text',
  NUMBER: 'number',
  DATETIME: 'datetime',
  TIME: 'time',
  DATE: 'date',
  PHONENUMBER: 'phonenumber',
};

type ResponseTypeValue = {
  $ResponseType: keyof typeof responseTypeDict;
};

function valueHasResponseType(value: any): value is ResponseTypeValue {
  return !!value?.$ResponseType;
}

const reviver = (
  key: string,
  value: ResponseTypeValue | JSONValue
): JSONValue | typeof responseTypeDict => {
  if (key === 'response_type') {
    if (valueHasResponseType(value)) {
      const apiType = value.$ResponseType;
      const frontendType = responseTypeDict[apiType];
      if (!frontendType) {
        throw Error(
          `$ResponseType \`${
            value.$ResponseType
          }\` does not correspond to frontend response types dictionary: \`${JSON.stringify(
            responseTypeDict
          )}\``
        );
      }
      return frontendType;
    }
  }
  return value;
};

export function decodeAPITypes<T = any>(json: T): any {
  return JSON.parse(JSON.stringify(json), reviver);
}
