import { useCallback } from 'react';

import { useHistory } from 'cross-platform/react-router';

export type URLLike = {
  href: string;
  pathname: string;
  origin: string;
  hostname: string;
};

/**
 * Returns an object like web's `const url = new URL("https://.. etc"(
 */
export const useGetURL = (): (() => Promise<URLLike>) => {
  const history = useHistory();
  const pathname = history.location.pathname;
  return useCallback(async () => {
    return {
      href: window.location.href, // e.g. "https://app.sleepiolocal.com/sleepio/home"
      pathname, // e.g "/sleepio/home"
      origin: window.location.origin, // e.g. "https://app.sleepiolocal.com"
      hostname: window.location.hostname, // e.g.  "app.sleepiolocal.com"
    };
  }, [pathname]);
};
