import { throwIfMissingEnvVar } from 'config/envVars/helpers';

const ENV_GOOGLE_CLIENT_ID = throwIfMissingEnvVar(
  'PLATGEN_GOOGLE_CLIENT_ID_WEB',
  process.env.PLATGEN_GOOGLE_CLIENT_ID_WEB
);
const ENV_FACEBOOK_APP_ID = throwIfMissingEnvVar(
  'PLATGEN_FACEBOOK_APP_ID_WEB',
  process.env.PLATGEN_FACEBOOK_APP_ID_WEB
);

export const GOOGLE_CLIENT_ID = ENV_GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_ID_LONG_FORM = `${ENV_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`;
export const GOOGLE_REDIRECT_URL = 'not_required';
export const FACEBOOK_APP_ID = ENV_FACEBOOK_APP_ID;
