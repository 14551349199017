import { Query } from 'react-query';

import { find_with_reference } from '@bighealth/api/Product/v2';
import { get_assigned_program_with_user_and_product } from '@bighealth/api/UserAccountAuthorization/v1';

const ignoredQueries = new Set([
  get_assigned_program_with_user_and_product.queryKey,
  find_with_reference.queryKey,
]);

/**
 * NOTE: Only day this should be injected into app from Daylight package
 *
 * @see {@link ./README.md} for more info
 */
const checkMetaDaylight = (query: Query): boolean | undefined => {
  const queryKey = query.options?.queryKey;
  // EXAMPLE: const meta: IsFetchingMeta | undefined = query?.meta;
  const [firstQueryKeySegment] = Array.isArray(queryKey)
    ? queryKey
    : [queryKey];

  if (ignoredQueries.has(firstQueryKeySegment)) {
    return false;
  }

  return undefined;
};

export { checkMetaDaylight };
