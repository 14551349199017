import React, { ReactElement } from 'react';

import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

import { FormPresentation } from '../components/FormPresentation';

import { mockFormPresentationProps } from './helpers';

const FormStateStory = (): ReactElement | null => {
  const sceneSet = useFakeStorybookSceneSet();
  if (!sceneSet) {
    return null;
  }
  return <FormPresentation {...mockFormPresentationProps} />;
};

addStory('SetSleepWindow/FormState', () => <FormStateStory />);
