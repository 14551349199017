import React, { ReactNode } from 'react';

import { StyleObject } from '@bighealth/types/src/scene-components/client';

import * as roleProps from 'cross-platform/utils/roleProps';
import { RoleProps } from 'cross-platform/utils/roleProps';
import { openURL } from 'lib/navigation/openURL';

import {
  DefaultLinkText,
  ErrorBoundaryLinkText,
  TouchableOpacity,
} from '../styled';

type LinkProps = {
  href: string;
  children: React.ReactNode;
} & RoleProps;

/**
 * Used by Markdown component
 *
 * IDEA use generic util (see other linkRenderers)
 * @param {LinkProps} props
 */
export const ErrorBoundaryLinkRenderer: React.FC<LinkProps> = ({
  children,
  href,
  ...restProps
}: LinkProps) => {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      /**
       * IDEA use <a href=""/>
       * WHEN react-router-dom supports mailto
       * @see {@link https://stackoverflow.com/a/63819003}
       */
      return <ErrorBoundaryLinkText {...child.props} />;
    }
    return child;
  });

  return (
    <TouchableOpacity
      {...roleProps.pass(restProps)}
      onPress={() => openURL(href)}
    >
      {childrenWithProps}
    </TouchableOpacity>
  );
};

export const DefaultLinkRenderer: React.FC<LinkProps> = ({
  href,
  ...restProps
}: LinkProps) => {
  return (
    <TouchableOpacity
      {...roleProps.pass(restProps, 'touchable')}
      onPress={() => openURL(href)}
    >
      <DefaultLinkText
        href={href}
        accessibility={{ role: 'link', accessibilityRole: 'link' }}
        {...restProps}
      />
    </TouchableOpacity>
  );
};

export type CustomLinkProps = {
  href?: string;
  children: ReactNode;
  style?: StyleObject;
};

export const CustomLinkRenderer: React.FC<CustomLinkProps & RoleProps> = ({
  href,
  style,
  ...restProps
}: CustomLinkProps & RoleProps) => {
  return (
    <TouchableOpacity
      {...roleProps.pass(restProps, 'touchable')}
      onPress={() => (typeof href === 'string' ? openURL(href) : undefined)}
    >
      <DefaultLinkText {...restProps} style={style as StyleObject} />
    </TouchableOpacity>
  );
};
