import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components/native';

import { SleepioLogoWhite } from './SleepioLogoWhite';
import { LogoProps } from './types';

export const SleepioLogoOutline = (props: LogoProps): ReactElement => {
  const theme = useTheme();
  return <SleepioLogoWhite {...props} color={theme.color.overlay.primary} />;
};
