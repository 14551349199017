import React, { CSSProperties, ReactElement } from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { useHistory } from 'react-router-dom';

import { Link as LinkProps } from '@bighealth/types/src/scene-components/client';

import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { openURL } from 'lib/navigation/openURL';

import { getFontFamilyFromStyles } from './getFontFamilyFromStyles';
import getFontStyle from './getFontStyle';
import { getTextAlignmentConfig } from './utils';

export const Link = (
  props: React.PropsWithChildren<LinkProps>
): ReactElement => {
  const isWeb = Platform.OS === 'web'; // TODO remove platform expressions
  const external = props.to.startsWith('http');
  const scaleValues = useTransformStylesToContext();
  const scaledStyles = scaleValues(StyleSheet.flatten(props.style || {}));
  const alignmentStyles = getTextAlignmentConfig(props.alignment);

  if (props.style) {
    const { top, left, bottom, right } = props.style;

    if (top || left || bottom || right) {
      props.style.position = 'absolute';
    }
  }

  const style = StyleSheet.flatten({
    display: 'flex',
    [isWeb ? 'textDecoration' : 'textDecorationLine']: props.underline
      ? 'underline'
      : 'none',
    color: props.style?.color || '#005270',
    fontStyle: getFontStyle(props.italic),
    ...((scaledStyles as unknown) as ViewStyle),
    ...alignmentStyles,
    fontWeight: (props.style?.fontWeight || 200).toString(),
    fontFamily: getFontFamilyFromStyles(props.style, props.italic),
  } as CSSProperties);

  const roleProps = roles('Link', { role: 'link' });
  const linkStyleProps = {
    style,
    to: props.to,
  };
  const linkProps = {
    ...roleProps,
    ...linkStyleProps,
  };
  const history = useHistory();

  if (isWeb) {
    return (
      <a
        {...(linkProps as LinkProps)}
        href={props.to.trim()}
        rel="external noopener noreferrer nofollow"
        target={'_blank'}
        style={style as CSSProperties}
      >
        {props.text}
      </a>
    );
  }
  return (
    <Pressable
      {...roleProps}
      style={{ display: 'flex' }}
      onPress={() => {
        if (external) {
          openURL(props.to);
        } else {
          history.push(props.to);
        }
      }}
    >
      <Text {...linkStyleProps} accessible={true} style={style as TextStyle}>
        {props.text}
      </Text>
    </Pressable>
  );
};
