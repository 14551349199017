import { FontFamilies } from 'components/sceneset-components/Text/fonts';

export * from './ErrorText';
export * from './HelperText';
export * from './NullText';
export * from './Text';
import styled from 'styled-components/native';

/* this href property is used for accesibility, this is necessary because styled-components does not recognize react-native-web types  */
const Text = styled.Text<{ href?: string; 'aria-hidden'?: boolean }>`
  font-family: '${FontFamilies.Regular}';
`;

export { Text };
