import { ProductReferences } from 'common/constants/enums';

export type StringsForProduct = {
  website: string;
  LoginSplashScreen: {
    byLine: string;
    noAccountOptional?: string;
    noAccount: string;
  };
  optionalFooter?: boolean;
};

const getStringsForProduct = (
  productReference: ProductReferences
): StringsForProduct => {
  switch (productReference) {
    case ProductReferences.DAYLIGHT:
      return {
        website: 'www.trydaylight.com',
        LoginSplashScreen: {
          byLine: 'Your expert guide to address worry and anxiety.',
          noAccount:
            'If you don’t have an account, please speak to your benefits provider or visit',
        },
      };
    case ProductReferences.SLEEPIO:
      return {
        website: 'Sleepio.com',
        LoginSplashScreen: {
          byLine: 'A six-week course to address insomnia and poor sleep',
          noAccount: `Sleepio is available on the NHS or through benefits plans. For more info, visit Sleepio.com`,
        },
        optionalFooter: true,
      };
    default:
      throw new Error(`Unsupported strings for product ${productReference}`);
  }
};

export { getStringsForProduct };
