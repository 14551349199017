import { throwIfMissingEnvVar } from 'config/envVars/helpers';

export const PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER = throwIfMissingEnvVar(
  'PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER',
  process.env.PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER
);

export const DEBUG_SHOW_DEV_CONTENT_SELECTOR =
  throwIfMissingEnvVar(
    'DEBUG_SHOW_DEV_CONTENT_SELECTOR',
    process.env.DEBUG_SHOW_DEV_CONTENT_SELECTOR
  ) === '1';
