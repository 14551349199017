import { SleepDairyModalActionTypes, SleepDiaryModalAction } from './actions';

export interface SleepDiaryModalState {
  isVisible: boolean;
}

export const initialSleepDiaryModalState: SleepDiaryModalState = {
  isVisible: false,
};

export const sleepDiaryModalReducer = (
  state = initialSleepDiaryModalState,
  action: SleepDiaryModalAction
): SleepDiaryModalState => {
  const { type } = action;
  switch (type) {
    case SleepDairyModalActionTypes.SET_VISIBLE:
      return {
        ...state,
        isVisible: action.payload,
      };
    default:
      return state;
  }
};
