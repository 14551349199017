import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const confirm_otp = async (
  args: confirm_otp.Args,
  requestConfig?: AxiosRequestConfig
): Promise<confirm_otp.Response> => {
  const request: confirm_otp.Request = {
    service_name: 'UserAccount',
    service_version: '1',
    service_method: 'confirm_otp',
    args,
  };

  return await api(request, requestConfig);
};

confirm_otp.queryKey = 'UserAccount/confirm_otp';

export declare namespace confirm_otp {
  export type Args = {
    otp: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserAccount';
    service_version: '1';
    service_method: 'confirm_otp';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
