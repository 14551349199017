import { useEffect } from 'react';
import notifee, { EventType } from '@notifee/react-native';

import { sendButtonClickedAnalytics } from 'lib/api/analytics/sendButtonClickedAnalytics';
import { useQueryGetButtonClickedAnalyticsRequestValues } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQuerySendButtonClickedAnalytics';

export const useAppOpenedFromNotification = (): void => {
  const {
    getUrl,
    productId,
    productVersionStreamResult,
    programId,
  } = useQueryGetButtonClickedAnalyticsRequestValues();
  useEffect(() => {
    return notifee.onForegroundEvent(({ type }) => {
      if (type == EventType.PRESS) {
        sendButtonClickedAnalytics({
          field: {
            area: 'notification',
            section: 'notification',
          },
          productId,
          programId,
          getUrl,
          productVersionStream: productVersionStreamResult?.data?.result,
        });
      }
    });
  }, [getUrl, productId, productVersionStreamResult, programId]);
};
