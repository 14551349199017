const getTileBackgroundColor = (diaryStatus: string): string => {
  switch (diaryStatus) {
    case 'COMPLETE':
      return '#ffffff';
    case 'MISSING':
      return '#ffffff';
    default:
    case 'LOCKED':
      return '#ffffff';
  }
};

export { getTileBackgroundColor };
