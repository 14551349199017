import React, { ReactElement, ReactNode } from 'react';
import { Animated, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { NavBarStyles } from 'components/Navigation/constants';
import { useGetScreenDimensions } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { getTheme } from 'lib/styles/getTheme';
import { getControlsAreVisible } from 'state/player/selectors';

const MediaBarContainer = styled.View`
  margin-left: 24px;
  margin-right: 24px;
`;
MediaBarContainer.displayName = 'MediaBarContainer';

const MediaBarStyled = styled(Animated.View)`
  position: absolute;
  background-color: ${getTheme('content.controls.player.backgroundColor')};
  width: 100%;
  border-radius: 9px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
`;
MediaBarStyled.displayName = 'MediaBarStyled';

export const MediaBar = ({
  children,
  style,
  hidden,
  fadeAnim,
}: {
  children: ReactNode;
  style?: ViewStyle;
  hidden?: boolean;
  fadeAnim?: Animated.Value;
}): ReactElement | null => {
  const { screenWidth, screenHeight } = useGetScreenDimensions();
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;
  const isLandscape = screenWidth > screenHeight;
  const controlsAreVisible = useSelector(getControlsAreVisible);

  if (!controlsAreVisible && hidden) {
    return null;
  }

  return (
    <MediaBarContainer>
      <MediaBarStyled
        {...roles('MediaBar')}
        style={{
          bottom: isMobileWeb ? 27 : 70,
          maxWidth: isMobileWeb && isLandscape ? 310 : 440,
          opacity: fadeAnim ?? 1,
          ...style,
        }}
      >
        {children}
      </MediaBarStyled>
    </MediaBarContainer>
  );
};
