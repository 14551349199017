export const CONTENT_MIN_REFERENCE_DIMENSION = 768;
// 667:308
// 768:810
export const CONTENT_MIN_HEIGHT_REFERENCE_DIMENSION = 810;

// NOTE: deliberately not using px suffix here so we can get some free type safety
export const contentStyles = {
  // NOTE: All these values are relative to the "768px" value above
  pagePaddingLeft: 32,
  pagePaddingRight: 32,
  pagePaddingBottom: 60,

  pageTitleFontSize: 40,
  pageTitleFontHeight: 46,
  pageTitlePaddingTop: 50,
  pageTitlePaddingBottom: 32,

  pageSubTitleFontSize: 30,
  pageSubTitlePaddingBottom: 40,

  navWidthMax: 400,
  navLowerScaleWidth: 248,
  navMarginRightMin: 16,
  navMarginRightMaxWidth: 70,

  navItemVerticalPadding: 16,
  navItemHorizontalPadding: 20,
  navItemTitleFontSize: 24,
  navItemTitleMarginBottom: 10,
  navItemSubtitleFontSize: 18,
  navItemMarginBottom: 16,
  navItemBorderRadius: 8,
  navItemMinHeight: 100,

  navItemIconMarginRight: 25,
  navItemIconMarginLeft: 20,
  navItemIconWidth: 35,
  navItemIconHeight: 20,

  contentWidthMax: 700,
  contentLowerScaleWidth: 440,

  contentHorizontalPadding: 20,
  contentVerticalPadding: 20,
  contentBorderRadius: 8,
  contentMarginBottom: 40,
  contentMarginTop: 24,

  contentTitleFontSize: 24,
  contentSubtitleFontSize: 18,
  contentTitleBlockMarginTop: 20,
  contentTitleBlockMarginBottom: 20,

  contentPlaceholderFontSize: 24,
  contentPlaceholderMaxWidth: 452,

  entryMarginBottom: 20,
  entryVerticalPadding: 32,
  entryHorizontalPadding: 42,
  entryBorderRadius: 8,

  entryTagVerticalPadding: 8,
  entryTagHorizontalPadding: 18,
  entryTagMarginRight: 8,
  entryTagMarginBottom: 8,
  entryTagFontSize: 14,

  entryDownloadExtensionFontSize: 18,
  entryDownloadNameMarginLeft: 32,
  entryDownloadNameFontSize: 24,

  libraryCategoryHeadingFontSize: 20,
  libraryArticleTitleMarginTop: 24,
  articleFontSize: 18,
  articleLineHeight: 24,

  articleAuthorNamePaddingLeft: 31,
  articleAuthorPhotoWidth: 96,
  articleTitleFontSize: 30,
  articleAuthorNameFontSize: 16,
  articleAuthorNamePaddingTop: 8,

  articleFiledUnderTextFontSize: 18,
  articleFiledUnderTextPaddingBottom: 16,

  entryPointFontSize: 20,
  entryPointContainerMarginBottom: 16,

  titleSetSleepWindow: 24,
  labelSetSleepWindow: 18,
  labelMarginBottom: 4,
  inputSetSleepWindow: 22,
  inputWidth: 140,
  circleButtonSetSleepWindowDiameter: 32,
  circleButtonSetSleepWindowMarginLeft: 8,

  triangleSetSleepWindowWidth: 9,
  triangleSetSleepWindowHeight: 6,

  submitButtonSetSleepWindowWidth: 220,
  submitButtonSetSleepWindowHeight: 60,
  submitButtonSetSleepWindowBorderRadius: 32,
  submitButtonSetSleepWindowFontSize: 20,
  submitButtonSetSleepWindowPadding: 15,

  goalsHeadingFontSize: 41,
  goalsHeadingFontSizeNative: 30,
  goalsHeadingPaddingBottom: 24,
  goalsHeadingPaddingBottomNative: 16,
  goalEntryWidth: 300,
  goalEntryHeight: 400,
  goalEntryBorderRadius: 8,
  goalIconSize: 60,

  settingsMaxWidth: 700,
  settingsPaddingLeft: 135,
  settingsPaddingLeftNarrow: 32,
  settingsEntryHeadingFontSize: 20,
  settingsEntryTextFontSize: 16,
  settingsEntrySubHeadingTopPadding: 3,
  settingsEntrySubHeadingBottomPadding: 7,
  settingsEntryTextPadding: 16,
  settingsSwitchWidth: 34,
  settingsSwitchHeight: 20,
  settingsSwitchText: 16,
  settingsContentMarginBottom: 130,

  settingsEntryMarginBottom: 20,
  settingsEntryTopPadding: 16,
  settingsEntryBottomPadding: 16,
  settingsEntryLeftPadding: 16,
  settingsEntryRightPadding: 40,
  settingsEntryBorderRadius: 4,
  settingsEntryIconSize: 20,
  settingsEntryMessagePadding: 13,

  sleepDiaryFormHeadingFontSize: 20,
  sleepDiaryFormErrorFontSize: 16,
  sleepDiaryFormCloseButtonPaddingPortrait: 18,
  sleepDiaryFormHeadingVerticalPadding: 22,
  sleepDiaryFormHeadingHorizontalPadding: 45,
  sleepDiaryFormHeadingHorizontalPaddingPortrait: 24,
  sleepDiaryFormHeadingHorizontalPaddingWeb: 52,
  sleepDiaryFormSaveButtonBottomPadding: 70,

  sleepDiaryWeekWidth: 1100,
  sleepDiaryWeekWidthMobileWeb: 1400,
  sleepDiaryWeekWidthMobileWebLandscape: 700,
  sleepDiaryWeekWidthMobileWebContentPortrait: 700,
  sleepDiaryWeekHeight: 600,
  sleepDiaryWeekPaddingBottom: 40,
  sleepDiaryWeekWidthNarrow: 704,
  sleepDiaryWeekCalendarTitleFontSizeNarrow: 40,
  sleepDiaryWeekCalendarSubTitleMarginTopNarrow: 23,
  sleepDiaryWeekCalendarSubTitleFontSizeNarrow: 18,
  sleepDiaryWeekCalendarSubTitleFontSizeMobileWeb: 24,
  sleepDiaryWeekCalendarIconSize: 100,
  sleepDiaryWeekCalendarContainerPaddingTop: 36,
  sleepDiaryWeekCalendarContainerPaddingBottom: 10,
  sleepDiaryWeekCalendarViewContainerMarginTop: 40,
  sleepDiaryWeekCalendarTileDayOfWeekMarginTop: 20,
  sleepDiaryWeekCalendarTileDayOfMonthFontSize: 60,
  sleepDiaryWeekCalendarTileButtonLabelDiaryMissing: 22,
  sleepDiaryWeekCalendarExtrapolateButtonFontSize: 20,
  sleepDiaryWeekCalendarExtrapolateButtonMarginTop: 43,
  sleepDiaryWeekChartHeight: 400,
  sleepDiaryWeekChartHeightMobileWeb: 500,
  sleepDiaryWeekChartHeightNativePortrait: 650,
  sleepDiaryWeekChartGuideLabelSize: 18,
  sleepDiaryWeekBarChartLabelTextFontSize: 28,

  sleepDiaryWeekMetricMinWidthMobileWeb: 600,
  sleepDiaryWeekMetricMaxHeightMobileWeb: 140,

  resourcesHorizontalPadding: 135,
  resourcesHorizontalPaddingNarrow: 32,
  resourcesVerticalPadding: 51,
  resourcesVerticalPaddingNarrow: 26,
};

export type ContentStylesType = typeof contentStyles;
