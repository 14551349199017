import React, { ReactElement, ReactNode, useContext } from 'react';

import { Footer } from 'components/Footer';
import {
  CONTENT_MIN_REFERENCE_DIMENSION,
  ScalingContext,
  ScalingContextProvider,
  ScreenResponsiveLayoutContext,
} from 'components/ResponsiveLayout';

import { ScreenScroll } from './components/ScreenScroll';
import {
  ContentColumn,
  FullPage,
  FullWidthTitleContainer,
  HeaderedContent,
  HeaderedPage,
  NavColumn,
  PageTitle,
  ScrollColumn,
} from './components';

type Props = {
  navTitle: ReactNode;
  nav: ReactNode;
  content: ReactNode;
};

const ResponsiveContentScreen = ({
  navTitle,
  nav,
  content,
}: Props): ReactElement => {
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isNarrow = screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION;
  if (isNarrow) {
    return (
      <ScreenScroll>
        <HeaderedPage>
          <FullWidthTitleContainer>
            <PageTitle>{navTitle}</PageTitle>
          </FullWidthTitleContainer>
          <HeaderedContent>
            <NavColumn>
              <ScrollColumn>{nav}</ScrollColumn>
            </NavColumn>
            <ContentColumn>
              <ScrollColumn>{content}</ScrollColumn>
            </ContentColumn>
          </HeaderedContent>
        </HeaderedPage>
        <Footer />
      </ScreenScroll>
    );
  }
  // TODO: flip this layout on mobile so title appears at top
  return (
    <ScreenScroll>
      <FullPage>
        <NavColumn>
          <PageTitle>{navTitle}</PageTitle>
          <ScrollColumn>{nav}</ScrollColumn>
        </NavColumn>
        <ContentColumn>
          <ScrollColumn>{content}</ScrollColumn>
        </ContentColumn>
      </FullPage>
      <Footer />
    </ScreenScroll>
  );
};

export const ContentScreen = (props: Props): ReactElement => (
  <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
    <ResponsiveContentScreen {...props} />
  </ScalingContextProvider>
);
