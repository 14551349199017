import React, { ReactElement } from 'react';
import { View } from 'react-native';

import { ProgressEntry } from '@bighealth/types';

import { useGoalsScalingStyles } from 'components/Goals/useGoalsScalingStyles';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { Text } from 'components/Text';

import { GoalContainer } from './GoalContainer';
import { GoalIcon } from './GoalIcon';

export type GoalProps = ProgressEntry & {
  isInSceneSet?: boolean;
  isLast?: boolean;
};

export const Goal = (props: GoalProps): ReactElement => {
  const styles = useGoalsScalingStyles();

  return (
    <GoalContainer showMarginRight={!props.isLast}>
      <View style={{ marginBottom: styles.iconMarginBottom }}>
        <GoalIcon icon={props.icon} size={styles.iconSize} />
      </View>
      <Text
        style={{
          width: '100%',
          fontSize: styles.titleFontSize,
          marginBottom: styles.titleMarginBottom,
          fontFamily: FontFamilies.Bold,
          color: '#282828',
        }}
      >
        {props.label.text}
      </Text>
      <Text
        style={{
          fontSize: styles.contentFontSize,
          color: '#282828',
        }}
      >
        {props.text.text}
      </Text>
    </GoalContainer>
  );
};
