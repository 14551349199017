import tryGetCleanedRequestObjFromString from './tryGetCleanedRequestObjFromString';

const updateNetworkBreadcrumb = (
  data: Record<string, unknown>,
  input?: string | Array<unknown>
): Record<string, unknown> => {
  const maybeRequestObj = tryGetCleanedRequestObjFromString(input);
  if (typeof maybeRequestObj === 'undefined') {
    return data; // no update
  }
  return { ...data, ...maybeRequestObj };
};

export default updateNetworkBreadcrumb;
