import { UsePostponeNotificationsFunc } from '../types';

export const usePostponeSleepDiaryNotifications = () => {
  return usePostponeNotifications({
    osNotificationPermissionRequestCount: 0,
    sessionNotificationGroupConfigId: undefined,
  });
};

/**
 * Use Refresh Notifications
 *
 * Custom hook for logic to postpone daily notifications start date by one day
 *   this with the aim of avoiding notifications of the current day
 */
export const usePostponeNotifications: UsePostponeNotificationsFunc = () => {
  return { postponeDailyNotifications: () => null };
};
