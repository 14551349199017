import { ComponentThemeCollection } from 'config/getThemeForProducts/utils/createTheme/ComponentTheme';

import { CardProps } from '../components/Presentation/components/SessionList/components/Card';

// These are only used in cards...I think..
// TODO delete sessionValue
export const sessionsValues = {
  card: {
    width: 343,
    large: { borderRadius: 8 },
    small: { borderRadius: 4 },
    image: {
      height: 170,
    },
    details: {
      height: 105,
    },
  },
};

export const optionalCardValues = {
  card: {
    width: 291,
    large: { borderRadius: 9 },
    small: { borderRadius: 9 },
    height: 158,
    image: {
      width: 118,
    },
    details: {
      width: 173,
    },
  },
};

// Replace sessionValues with cardTheme, accessed by createThemeByAttr()
export type CardTheme = {
  width: number;
  marginLeft: number;
  marginRight: number;
  borderRadius: number;
  image: {
    height: number;
  };
  details: {
    height: number;
  };
};

export type CardAttrs = Pick<CardProps, 'isLarge'>;

export type CardThemeCollection = ComponentThemeCollection<
  CardAttrs,
  CardTheme
>;

// Need to reconstruct from CardPresentation -> getCardStyles -> sessionValues etc
export const cardThemeCollection: CardThemeCollection = {
  default: {
    width: 100,
    marginLeft: 4,
    marginRight: 4,
    borderRadius: 4,
    image: {
      height: 100,
    },
    details: {
      height: 100,
    },
  },
  variants: [
    // [
    //   'isLarge',
    //   [
    //     true,
    //     {
    //       width: 100,
    //     },
    //   ],
    // ],
    // [
    //   'type', // TODO make typescript error
    //   [
    //     'primary',
    //     {
    //       //
    //     },
    //   ],
    //   [
    //     'secondary',
    //     {
    //       color: 'white',
    //       background: 'blue',
    //     },
    //   ],
    // ],
  ],
};
