import { ComponentProps } from 'react';

import { SleepDiaryForm } from '..';

type Props = ComponentProps<typeof SleepDiaryForm>;

const SleepDiaryFormMocks: {
  v1: Record<string, Props>;
  v2: Record<string, Props>;
} = {
  v1: {
    create: {
      diary_date: '2020-06-03',
      form_type: 'create_single_day_entry',
    },

    update: {
      diary_date: '2020-06-03',
      form_type: 'update_single_day_entry_with_date',
    },
    'weekly average': {
      form_type: 'create_from_weekly_average_estimate',
    },
    'monthly average': {
      form_type: 'create_baseline_entities',
    },
  },
  v2: {
    create: {
      diary_date: '2020-06-03',
      form_type: 'create_single_day_entry',
      sleep_diary_form_version: 3,
    },

    update: {
      diary_date: '2020-06-03',
      form_type: 'update_single_day_entry_with_date',
      sleep_diary_form_version: 3,
    },
    'weekly average': {
      form_type: 'create_from_weekly_average_estimate',
      sleep_diary_form_version: 3,
    },
    'monthly average': {
      form_type: 'create_baseline_entities',
      sleep_diary_form_version: 3,
    },
  },
};

export { SleepDiaryFormMocks };
