import { View } from 'react-native';
import styled from 'styled-components/native';

import { FooterTheme } from 'config/getThemeForProducts/utils/createTheme/footerSchemesTypes';

export const EmptyBackground = styled(View)`
  flex: 1;
  justify-content: flex-end;
  background-color: ${props =>
    props.theme.footer[FooterTheme.Dark].backgroundColor};
`;
