import { View } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'components/Text';
import { FooterTheme } from 'config/getThemeForProducts/utils/createTheme/footerSchemesTypes';

export const EmptyBackground = styled(View)`
  flex: 1;
  justify-content: flex-end;
  background-color: ${props =>
    props.theme.footer[FooterTheme.Dark].backgroundColor};
`;

export const ErrorIconContainer = styled.View`
  margin: 72px 0 24px;
`;

export const TextContainer = styled.View`
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 18px;
`;

export const FormattedText = styled(Text)`
  color: ${props => props.theme.color.midBlue};
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
`;
