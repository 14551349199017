import { useMatchedPath } from 'cross-platform/react-router';

const PATH = '/:productReference/:screen';

type ScreenParams = {
  screen: string;
  productReference: string;
};

export const useShowLogo = (screens: string[]): boolean => {
  const matchedPath = useMatchedPath<ScreenParams>(PATH);
  return matchedPath !== null && screens.includes(matchedPath.params.screen);
};
