import React, { ReactElement, useContext } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';
import { ThemeContext } from 'styled-components/native';

import { get_resources_screen_data } from '@bighealth/api/SleepioResources/v1';

import { Markdown } from 'components/Markdown/Markdown';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import {
  getIsMediumOrNative,
  getIsSmall,
} from 'components/Screens/ContentScreens/Resources/util';
import { VerticalScroll } from 'components/VerticalScroll/VerticalScroll';
import { roles } from 'cross-platform/utils/roleProps';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { openURL } from 'lib/navigation/openURL';

import { CEMark, UKCAMark } from '../../../components/iconsDictionary';
import { ProductMark } from '../../types';

import {
  AboutUsSubtitle,
  AboutUsTitle,
  AddressContainer,
  ColumnContainer,
  LeftColumn,
  LeftColumnTextLink,
  LeftColumnTitle,
  RightColumn,
  RightColumnBodyContainer,
  RightColumnTitleContainer,
  View,
} from './styled';

export type ResourcesProps = get_resources_screen_data.Result & {
  product_mark?: ProductMark;
};

export const Resources = (props: ResourcesProps): ReactElement | null => {
  useUpdateTitle('Resources');
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const safeArea = useSafeArea();
  const isSmall = getIsSmall(screenWidth);
  const isMedium = getIsMediumOrNative(screenWidth, isSmall);
  const theme = useContext(ThemeContext);

  return (
    <VerticalScroll
      style={{ backgroundColor: 'white', marginTop: safeArea.top }}
    >
      <ColumnContainer>
        <LeftColumn style={{ paddingBottom: 25 }}>
          <LeftColumnTitle
            role="heading"
            accessibilityRole="header"
            {...roles('ResourcesTitle')}
          >
            Resources
          </LeftColumnTitle>
          <View role="menu" accessibilityRole="menu">
            {props.left_column_items.map((columnItem, index) => (
              <View role="menuitem" key={index}>
                <LeftColumnTextLink
                  {...roles(`resourcesLink${index}`)}
                  role="link"
                  accessibilityRole="link"
                  onPress={() => openURL(columnItem.destination)}
                >
                  {columnItem.text}
                </LeftColumnTextLink>
              </View>
            ))}
          </View>
        </LeftColumn>
        <RightColumn
          style={{
            paddingTop: isMedium ? 90 : 0,
            paddingLeft: isSmall ? 0 : 25,
            width: isSmall ? '100%' : '70%',
          }}
        >
          <RightColumnTitleContainer>
            <AboutUsTitle
              {...roles('About Us')}
              role="heading"
              accessibilityRole="header"
              style={{
                marginBottom: 10,
                fontSize: isSmall ? 18 : 30,
              }}
            >
              {isMedium ? props.about_us_narrow.title : props.about_us.title}
            </AboutUsTitle>
            <AboutUsSubtitle
              role="heading"
              accessibilityRole="header"
              aria-level={2}
              style={{ fontSize: isSmall ? 18 : 30 }}
            >
              {isMedium
                ? props.about_us_narrow.subtitle
                : props.about_us.subtitle}
            </AboutUsSubtitle>
          </RightColumnTitleContainer>
          <RightColumnBodyContainer>
            <AddressContainer
              style={{
                flexDirection: isMedium || isSmall ? 'column' : 'row',
                justifyContent: 'space-between',
              }}
            >
              {typeof props.uk_address_data !== 'undefined' ? (
                <Markdown>{props.uk_address_data}</Markdown>
              ) : null}
              <Markdown>{props.us_address_data}</Markdown>
            </AddressContainer>
            <View
              {...roles('Contact Us')}
              style={{ paddingRight: isMedium ? 0 : 18, marginTop: 25 }}
            >
              <Markdown>{props.contact_us_data}</Markdown>
            </View>
            {typeof props.disclaimer_data !== 'undefined' ? (
              <View
                style={{
                  paddingRight: isMedium ? 0 : 18,
                  marginTop: 25,
                }}
                {...roles('Disclaimer')}
              >
                {typeof props.disclaimer_data !== 'undefined' ? (
                  <Markdown>{props.disclaimer_data}</Markdown>
                ) : null}
              </View>
            ) : null}
            {typeof props.product_mark !== 'undefined' ? (
              <View
                {...roles(
                  props.product_mark === ProductMark.UKCAMark
                    ? 'UKCAMark'
                    : 'CEMark'
                )}
                role="img"
                accessibilityRole="image"
                aria-label={ProductMark.UKCAMark ? 'UKCA mark' : 'CE mark'}
                style={{ marginTop: 40 }}
              >
                {props.product_mark === ProductMark.UKCAMark ? (
                  <UKCAMark color={theme.color.primaryDark} size={50} />
                ) : (
                  <CEMark />
                )}
              </View>
            ) : null}
          </RightColumnBodyContainer>
        </RightColumn>
      </ColumnContainer>
    </VerticalScroll>
  );
};
