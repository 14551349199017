import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { Label } from 'components/forms/Label';
import { Col, Grid } from 'components/layout/Grid';
import { QuoteRight } from 'components/sceneset-components/Quote';

const SceneSet = styled.View``;
const Video = styled.View``;
const Scene = Grid;
const ContentQuestion = Col;
const ContentInfo = Col;

// Presentational
const SingleSelectQuestion = (): ReactElement => {
  return (
    <SceneSet>
      <Scene>
        <Video />
        <ContentInfo>
          <QuoteRight>
            I wish I could sleep right through the night without waking up. No
            matter what I do I can’t get myself to sleep. It makes me feel so
            helpless and alone.
          </QuoteRight>
        </ContentInfo>
        <ContentQuestion>
          <Label>Select what advice you’d give below</Label>
          <TouchableOpacity onPress={console.log}>
            Perhaps you shouldn’t try so hard to ‘solve’ your sleep problem -
            it’s just winding you up, making you even less likely to sleep!
          </TouchableOpacity>
          <TouchableOpacity onPress={console.log}>
            Yes, let’s really focus on how terrible you are at sleeping! Try not
            to take it so seriously, it’s not the end of the world.
          </TouchableOpacity>
          <TouchableOpacity onPress={console.log}>
            On most nights you get at least some sleep. Now you just need to
            figure out how to improve.
          </TouchableOpacity>
          <TouchableOpacity onPress={console.log}>Continue</TouchableOpacity>
        </ContentQuestion>
      </Scene>
    </SceneSet>
  );
};

export { SingleSelectQuestion };
