import styled from 'styled-components/native';

import { Theme } from 'config/getThemeForProducts';

import { DayOfWeekThemeProps } from './types';
import { status } from './WeekTrackerConstants';

export const dayOfWeekColors = {
  lightGreen: '#80C0BA',
  successGreen: '#008275',
};

export const fontWeights = {
  normalFontWeight: '400',
  boldFontWeight: '700',
} as const;

export const DayOfWeekContainer = styled.View`
  height: 70px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const DayOfWeekText = styled.Text`
  padding-top: 4px;
`;

export const getDayOfWeekTheme = ({
  dayOfWeekStatus,
  theme,
}: {
  dayOfWeekStatus: string;
  theme: Theme;
}): DayOfWeekThemeProps => {
  switch (dayOfWeekStatus) {
    case status.COMPLETED:
      return {
        backgroundColor: theme.color.background.medium,
        circleFillColor: dayOfWeekColors.successGreen,
        circleStrokeColor: dayOfWeekColors.successGreen,
      };

    case status.NOT_COMPLETED:
      return {
        backgroundColor: theme.color.background.medium,
        circleFillColor: theme.color.inputOutlineGrey,
        circleStrokeColor: theme.color.inputOutlineGrey,
      };

    case status.TODAY_NOT_COMPLETED:
      return {
        backgroundColor: theme.color.white,
        circleFillColor: theme.color.white,
        circleStrokeColor: dayOfWeekColors.lightGreen,
        circleStrokeWidth: 3,
        fontWeight: fontWeights.boldFontWeight,
      };

    case status.TODAY_COMPLETED:
      return {
        backgroundColor: theme.color.white,
        circleFillColor: dayOfWeekColors.successGreen,
        circleStrokeColor: dayOfWeekColors.successGreen,
        fontWeight: fontWeights.boldFontWeight,
      };

    // return "default" which is "status.FUTURE_DAY"
    default:
      return {
        backgroundColor: theme.color.background.medium,
        circleFillColor: theme.color.white,
        circleStrokeColor: theme.color.white,
        fontWeight: fontWeights.normalFontWeight,
        circleStrokeWidth: 0,
      };
  }
};
