import { RequestConfig } from './types';

export const getServiceDetails = (requestConfig: RequestConfig): string => {
  return `${requestConfig.service_name}_${requestConfig.service_version}.${requestConfig.service_method}`;
};

export const hasServiceDetails = (requestConfig: RequestConfig): boolean => {
  return (
    typeof requestConfig.service_name !== 'undefined' &&
    typeof requestConfig.service_method !== 'undefined' &&
    typeof requestConfig.service_version !== 'undefined'
  );
};
