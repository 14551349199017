import styled from 'styled-components/native';

import { ThemeProp } from 'config/getThemeForProducts';
import { SpeechLocation } from 'config/getThemeForProducts/utils/createTheme/SpeechLocationSchemesTypes';
import { getTheme } from 'lib/styles/getTheme';

import { FlexBox, FlexBoxProps } from '../FlexBox';

import { ContentProps } from '.';

// https://github.com/Jpoliachik/react-native-triangle
interface TriangleProps {
  readonly direction: SpeechLocation;
}

export const Triangle = styled.View<TriangleProps>``;
Triangle.displayName = 'Storybook.Triangle';

export type InvisibleProps = Partial<FlexBoxProps>;
export const Invisible = styled(FlexBox).attrs({ flexGrow: 1 })<
  InvisibleProps
>``;
Invisible.displayName = 'Storybook.Invisible';

type Props = ContentProps & ThemeProp;
export const Solid = styled(FlexBox)`
  padding-horizontal: ${getTheme('layout.paddingHorizontal')}px;
  padding-vertical: ${getTheme('layout.paddingVertical')}px;
  background-color: ${getTheme('content.backgroundColor')};
  ${(props: Props): string =>
    props.mono
      ? `
      font-family: ${props.theme.text.mono.fontFamily};
      `
      : `
      font-family: ${props.theme.text.fontFamily};
      `}
`;
Solid.displayName = 'Storybook.Solid';
