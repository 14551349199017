import React, { useEffect, useState } from 'react';

import {
  ButtonColorSchemes,
  UniversalButton,
} from 'components/UniveralButtons';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { roles } from 'cross-platform/utils/roleProps';
import { useMutationMFADisable } from 'lib/api/reactQueryHelpers/useMutationAnalogs/useMutationMFADisable';
import { getProductName } from 'lib/productInfo/getProductName';

import { MFASceneDisabledTypes, ScreenContextDisabled } from '../../types';
import { MFASectionWrapperBase } from '../MFASectionWrapper';
import MFASectionWrapper from '../MFASectionWrapper';
import MFAErrorMessage from '../shared/MFAErrorMessage';
import {
  CodeTextInput,
  CodeTextInputWrapper,
  CodeTextLabel,
  ContinueButtonView,
} from '../styled/styled';

export type MFADisableEnterCodeParams = ScreenContextDisabled &
  MFASectionWrapperBase;

const MFAEnterDisableCode = (
  props: MFADisableEnterCodeParams
): React.ReactElement => {
  const { onContinue, onRequestClose, continue_button, ...rest } = props;
  const [code, setCode] = useState('');

  const { mutate, isLoading, isError, isSuccess } = useMutationMFADisable();

  const productTheme = getThemeForProduct(getProductName());

  // Fallback as text.placeholder is optional for themes.
  const placeHolderTextColor = productTheme.color.text.placeholder
    ? productTheme.color.text.placeholder
    : productTheme.color.primaryGrey;

  useEffect(() => {
    // If just submitted
    if (isLoading === false) {
      // If there is an error, then it should stop here
      if (isError === false && isSuccess) {
        // If Success Stop here & go to next screen
        onContinue(continue_button?.next);
      }
    }
  }, [continue_button, isError, isLoading, isSuccess, onContinue]);

  const changeCode = (value: string) => {
    // Allow only numeric characters
    setCode(value.replace(/\D/g, ''));
  };

  const localSubmit = async () => {
    mutate(code);
  };

  return (
    <MFASectionWrapper
      content={rest}
      onRequestClose={onRequestClose}
      onContinue={onContinue}
      screenIdentifier={`MFA_DisableFlow_${MFASceneDisabledTypes.AuthenticationDisableEnterCode}`}
    >
      <CodeTextInputWrapper>
        {rest.inputLabel ? (
          <CodeTextLabel>{rest.inputLabel}</CodeTextLabel>
        ) : null}
        <CodeTextInput
          keyboardType="numeric"
          placeholder={'6-digit code'}
          value={code}
          onChangeText={changeCode}
          hasErrors={isError}
          maxLength={6}
          isPlaceholder={code.length <= 0}
          autoFocus={true}
          placeholderTextColor={placeHolderTextColor}
          {...roles('MFAModalDisableInput')}
        />
        {isError && <MFAErrorMessage onContinue={onContinue} content={rest} />}
      </CodeTextInputWrapper>
      {continue_button?.next ? (
        <ContinueButtonView>
          <UniversalButton
            text={continue_button.text}
            onPress={localSubmit}
            colorScheme={ButtonColorSchemes.AltPrimary}
            isDisabled={isLoading || code.length !== 6}
            {...roles('MFAModalDisableSubmitButton')}
          />
        </ContinueButtonView>
      ) : null}
    </MFASectionWrapper>
  );
};

export default MFAEnterDisableCode;
