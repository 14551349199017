import React from 'react';
import ReactMarkdown from 'react-markdown';

import { MarkdownType } from 'components/Markdown/constants';
import { MarkdownProps } from 'components/Markdown/types';
import { renderers } from 'components/Markdown/utils/renderers';
import { accessibility } from 'cross-platform/utils/accessibilityProps';
import { roles } from 'cross-platform/utils/roleProps';

import { getCustomRenderers } from './utils/getCustomRenderers';

export const Markdown = ({
  style,
  children,
  type = MarkdownType.DEFAULT,
  ...restProps
}: MarkdownProps): React.ReactElement => {
  const finalRenderers =
    type === MarkdownType.CUSTOM ? getCustomRenderers(style) : renderers[type];

  return (
    <ReactMarkdown
      {...roles.pass(restProps)}
      {...accessibility(restProps?.accessibility)}
      renderers={finalRenderers}
    >
      {children}
    </ReactMarkdown>
  );
};
