import React from 'react';
import Clipboard from '@react-native-clipboard/clipboard';

import { CopyIcon } from 'components/Screens/ContentScreens/components/iconsDictionary';
import { Text } from 'components/Text/Text';
import {
  ButtonColorSchemes,
  UniversalButton,
} from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryMFAEnable } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQueryMFAEnable';

import { MFASceneTypes, ScreenContext } from '../../types';
import MFAQRCode from '../MFAQRCode';
import MFASectionWrapper, { MFASectionWrapperBase } from '../MFASectionWrapper';
import { ContinueButtonView, CopyTextBlock, OtpValue } from '../styled/styled';

export type MFACopyClipboardPrams = ScreenContext & MFASectionWrapperBase;

const MFAEnableCopyClipboard = (
  props: MFACopyClipboardPrams
): React.ReactElement | null => {
  const { data, isFetching } = useQueryMFAEnable();

  const { onContinue, onRequestClose, continue_button, ...rest } = props;

  const onCopyToKeyboardPress = () => {
    if (data?.result.otp_secret) {
      Clipboard.setString(data?.result.otp_secret);
    }
  };

  return (
    <MFASectionWrapper
      content={rest}
      onRequestClose={onRequestClose}
      onContinue={onContinue}
      screenIdentifier={`MFA_EnableFlow_${MFASceneTypes.AuthenticationCopyCode}`}
    >
      <OtpValue {...roles('MFAModalOtpSecret')}>
        <Text
          aria-label={`Authentication key value: ${data?.result.otp_secret}`}
          style={{ fontSize: 16 }}
          selectable={true}
        >
          {data?.result.otp_secret}
        </Text>
      </OtpValue>
      <CopyTextBlock
        accessibilityRole="button"
        role="button"
        aria-label="Copy authentication key"
        onPress={onCopyToKeyboardPress}
        {...roles('MFAModalOtpCopyButton')}
      >
        <CopyIcon />
        <Text style={{ fontSize: 18, marginLeft: 8 }}>Copy</Text>
      </CopyTextBlock>
      {data?.result.otp_uri ? <MFAQRCode value={data.result.otp_uri} /> : null}
      {continue_button?.next ? (
        <ContinueButtonView>
          <UniversalButton
            text={continue_button.text}
            onPress={() => props.onContinue(continue_button?.next)}
            colorScheme={ButtonColorSchemes.AltPrimary}
            isDisabled={isFetching}
            {...roles('MFAModalCopyContinueButton')}
          />
        </ContinueButtonView>
      ) : null}
    </MFASectionWrapper>
  );
};

export default MFAEnableCopyClipboard;
