/* istanbul ignore file */ /* test logic */

import React from 'react';
import { Button, Text, View } from 'react-native';

const ThrowInRenderButton = (): JSX.Element => {
  const [renderError, setRenderError] = React.useState(false);
  if (renderError) {
    throw Error('Render error');
  }
  return (
    <Button
      title="Trigger error in error"
      onPress={() => setRenderError(true)}
    />
  );
};

const ThrowInHandlerButton = (props: {
  onError: (error: Error) => void;
}): JSX.Element | null => {
  if (typeof props.onError === 'undefined') {
    return null;
  }
  return (
    <Button
      title="Trigger error in handler"
      onPress={() => {
        try {
          throw Error('Handler error');
        } catch (error) {
          props.onError(error); // must catch
        }
      }}
    />
  );
};

const PotentialError = (props: {
  domain: string;
  onError: (error: Error) => void;
}): JSX.Element => {
  return (
    <View style={{ margin: 10 }}>
      <Text>{props.domain}</Text>
      <ThrowInHandlerButton onError={props.onError} />
      <ThrowInRenderButton />
    </View>
  );
};

export { PotentialError };
