export const getUserAgent = async (): Promise<string> => {
  return window.navigator.userAgent;
};

export const init = async (): Promise<string> => {
  return window.navigator.userAgent;
};

export const getUserAgentSync = (): string => {
  return window.navigator.userAgent;
};
