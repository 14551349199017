import { Question } from '@bighealth/types/src';

export const injectHideEmptyOption = (questionProps: Question): Question => {
  if (
    questionProps &&
    questionProps.response_config &&
    typeof questionProps.response_config?.hide_empty_option === 'undefined'
  ) {
    return {
      ...questionProps,
      response_config: {
        hide_empty_option: true,
        ...questionProps.response_config,
      },
    };
  }

  return questionProps;
};
