import { clone } from 'ramda';

import { Scene } from '@bighealth/types/src/scene-component';
import {
  DiarySourceEnum,
  DiaryTypeEnum,
  SleepDiaryFormProps,
} from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import { ButtonTypes } from 'common/constants/enums';

import { mockQuestionList } from '../../../__mocks__/questions';

export const payload: SleepDiaryFormProps = {
  date_label: { text: 'Wednesday, April 13' },
  form: {
    heading: { text: 'On Tuesday night...' },
    sections: [
      {
        heading: { text: 'Going to bed' },
        group: mockQuestionList.slice(0, 3),
      },
      {
        heading: { text: 'During the night' },
        group: mockQuestionList.slice(3, 4),
      },
      {
        heading: { text: 'Getting up' },
        group: mockQuestionList.slice(4),
      },
    ],
    buttons: [
      {
        component: 'Button',
        text: 'Save entry',
        type: ButtonTypes.SECONDARY_BUTTON as Scene.Components.Button['type'],
        action: {
          type: 'sleep-diary/SUBMIT_AND_MODAL_CLOSE',
          payload: {
            service_name: 'SleepDiary',
            service_version: '1',
            service_method: 'create_single_day_entry',
            args: {
              diary_date: '28 Aug 2020',
              diary_type: 'NORMAL' as DiaryTypeEnum,
              diary_source: 'USER' as DiarySourceEnum,
            },
          },
        },
      },
      {
        component: 'Button',
        text: 'Cancel',
        action: {
          type: 'sleep-diary/CANCEL',
        },
      },
    ],
  },
};

const getPayloadV2 = (t0: SleepDiaryFormProps): SleepDiaryFormProps => {
  const t1 = clone(t0);
  [
    ...t1.form.sections[0].group,
    ...t1.form.sections[1].group,
    ...t1.form.sections[2].group,
  ].forEach(responseInput => {
    switch (responseInput.component) {
      case 'Dropdown':
        responseInput.component = 'SelectHorizontal';
        break;
      case 'DropdownTimePicker':
        responseInput.component = 'SelectHorizontalTime';
        break;
    }
  });
  return t1;
};

export const payloadV2 = getPayloadV2(payload);
