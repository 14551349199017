import { filter, flatten, reject, uniq } from 'ramda';

import { ResponseOption } from '@bighealth/types';

import { QuizInfo } from 'components/forms/InputField';
import { checkResponseOption } from 'lib/question-response/useQuizForCurrentScene/utils/checkResponseOption';

export type QuizAssessment = {
  correctSelection: ResponseOption['id'][];
  incorrectSelection: ResponseOption['id'][];
  attemptsLeft: number;
  isAllCorrect: boolean;
};

const getId = (item: ResponseOption): number | never => {
  if (typeof item.id === 'number') {
    return item.id;
  }
  throw TypeError(`Expected ${JSON.stringify(item)} to have id of type number`);
};

const getAssessment = (
  attempts: ResponseOption[][] = [],
  quizInfo: QuizInfo
): QuizAssessment | undefined => {
  if (attempts.length && quizInfo?.correct_response_ids?.length) {
    if (quizInfo) {
      const correct = quizInfo.correct_response_ids;
      const attemptsFlat = uniq(flatten(attempts));
      const checkAttempt = (a: ResponseOption): boolean =>
        !!correct.find(cid => cid === a.id);
      return {
        correctSelection: filter(checkAttempt, attemptsFlat).map(getId),
        incorrectSelection: reject(checkAttempt, attemptsFlat).map(getId),
        attemptsLeft: Math.max(
          quizInfo.max_number_attempts - attempts.length,
          0
        ),
        isAllCorrect: checkResponseOption(
          attempts[attempts.length - 1],
          correct
        ),
      };
    }
  }
  return undefined;
};
export { getAssessment };
