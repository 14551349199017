import { throwIfMissingEnvVar } from 'config/envVars/helpers';

// Don't run the env var checker on this: it's not important
export const PLATGEN_LOCAL_ONLY_ASSET_OVERWRITE =
  process.env.PLATGEN_LOCAL_ONLY_ASSET_OVERWRITE;

export const PLATFORM_ASSETS_URL = throwIfMissingEnvVar(
  'PLATFORM_ASSETS_URL',
  process.env.PLATFORM_ASSETS_URL
);
