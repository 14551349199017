import { IsMobileWebProps } from './types';

/**
 * Higher order fn to match API of native counterpart
 *
 * @param {string} _edge
 *      WARNING no param needed in web.
 *      Types account for that.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getContentWhiteSpace = <T,>(_edge: 'left' | 'right' | T) =>
  /**
   *
   */
  (props: IsMobileWebProps): number => (props.isMobileWeb ? 16 : 60);
