import { getFitbitRedirectUrl } from '../getFitbitRedirectUrl';

const openAuthorizationPage = async (
  redirectPath: string,
  redirectScreen: string,
  clientId: string
): Promise<void> => {
  window.location.href = getFitbitRedirectUrl(
    redirectPath,
    redirectScreen,
    clientId
  );
};

export { openAuthorizationPage };
