import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { roles } from 'cross-platform/utils/roleProps';

/**
 * Today's icon is a sun
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const SettingsIcon = ({
  size,
  color,
  onPress,
}: {
  size: number;
  color: string;
  onPress?: () => void;
}): ReactElement => {
  return (
    <TouchableOpacity {...roles('SettingsIcon')} onPress={onPress}>
      <Svg
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 30 20"
        fill="none"
      >
        <G>
          <Path
            d="M27.8317 2L1.83173 2"
            stroke={color}
            strokeWidth={2.5}
            strokeLinecap="round"
          />
          <Path
            d="M27.8317 10L1.83173 10"
            stroke={color}
            strokeWidth={2.5}
            strokeLinecap="round"
          />
          <Path
            d="M27.8317 18L1.83173 18"
            stroke={color}
            strokeWidth={2.5}
            strokeLinecap="round"
          />
        </G>
      </Svg>
    </TouchableOpacity>
  );
};
