type DaylightLastPracticeTimeUtc = {
  daylight_last_practice_time_utc: string;
};

type IterableTimezoneSync = { user_timezone: string };

export type MessagingProfileDataFields =
  | DaylightLastPracticeTimeUtc
  | IterableTimezoneSync;

export enum TrackableMessagingEventNames {
  SLEEPIO_SLEEP_DIARY_COMPLETE = 'sleepio_sleep_diary_complete',
}

export type AllTrackableMessagingEventData = {
  [TrackableMessagingEventNames.SLEEPIO_SLEEP_DIARY_COMPLETE]: {
    diary_date: string;
  };
};

export type OverloadedTrackEventFcn = OverloadTrackEvent<
  AllTrackableMessagingEventData,
  TrackableMessagingEventNames
>;

// credits goes to https://stackoverflow.com/a/50375286
type UnionToIntersection<U> = (U extends any
? (k: U) => void
: never) extends (k: infer I) => void
  ? I
  : never;

type Values<T> = T[keyof T];

type TrackEventOverloads<EventDataMappings, Events extends string> = {
  [Prop in Events]: Prop extends keyof EventDataMappings
    ? (
        eventName: Prop,
        programId: number | undefined,
        dataFields: EventDataMappings[Prop]
      ) => any
    : (eventName: Prop, programId: number | undefined) => any;
};

type OverloadTrackEvent<
  EventDataMappings,
  Events extends string
> = keyof EventDataMappings extends Events
  ? UnionToIntersection<Values<TrackEventOverloads<EventDataMappings, Events>>>
  : never;
