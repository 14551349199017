/* eslint-disable camelcase */
import React, { ReactElement, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useFormikContext } from 'formik';

import {
  DatesAndTimesValueObject,
  Question,
  ResponseOption,
} from '@bighealth/types';

import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { DatePickerDisplayFormat } from 'components/generic-question/DateTimePickers';
import { getDateShown } from 'components/generic-question/DateTimePickers/utils/getDateShown';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { toValueObject } from 'lib/api/middleware/response/toJavaScriptDateWithRequest/utils/toValueObject';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';
import { QuestionId } from 'state/question-response/actions';

import { Value } from '../types';
import { Errors } from '..';

import { FIELDS } from './utils/data';
import { ResponseForm } from './ResponseForm';
import { ResponseInput } from './ResponseInput';

// IDEA: fix this
// WHY: This is "first-idea" due to M5 crunch
const getQuestionProps = (id: ResponseOption['semantic_id']): Question => {
  const field = FIELDS.filter(field => field?.questionProps).find(
    field => field?.questionProps?.semantic_id === id
  );
  if (typeof field === 'undefined') {
    throw Error(
      `Expected to find field with id ${id} in ${FIELDS.map(
        field => field?.questionProps?.semantic_id
      ).join(',')} `
    );
  }

  return field?.questionProps;
};

const SubmitButton = (): ReactElement => {
  const { isValid, isSubmitting, handleSubmit } = useFormikContext();
  const isDisabled = !isValid && isSubmitting;
  const pressHandler = (handleSubmit as unknown) as React.ComponentProps<
    typeof TouchableOpacity
  >['onPress'];
  return (
    <TouchableOpacity
      testID="submit"
      onPress={pressHandler}
      disabled={isDisabled}
    >
      Submit
    </TouchableOpacity>
  );
};

type StoryProps = {
  defaultFilter?: string[];
  fieldProps?: Record<QuestionId, { initialValue?: Value }>;
};
export const Story = (props: StoryProps = {}): ReactElement | null => {
  const fieldprops = props.fieldProps || {};
  const filter = props.defaultFilter || [
    'TextInput',
    'DateTimePicker',
    'SelectHorizontalTime',
    'DatePicker',
    'TimePicker',
    'NumberInput',
    'HorizontalScale',
    'SelectHorizontal',
    'Dropdown',
    'VerticalSelect',
    'Button',
  ];
  const [submitted, setSubmitted] = useState<Record<string, ResponseOption[]>>(
    {}
  );
  const sceneSet = useFakeStorybookSceneSet();
  if (!sceneSet) {
    return null;
  }
  const newSubmitted: Record<
    string,
    {
      value: Date;
      valueUI: Value | undefined;
      valueSubmitted: DatesAndTimesValueObject;
    }
  > = {};

  for (const [key, obj] of Object.entries(submitted)) {
    const option = obj[0];
    if (option.value instanceof Date) {
      const questionProps = getQuestionProps(key);
      const responseType = questionProps?.response_type;
      if (typeof responseType === 'undefined') {
        throw Error('Expected to find field response_type in ');
      }
      let valueUI: Value | undefined = option.value;

      if (
        responseType === 'date' ||
        responseType === 'datetime' ||
        responseType === 'time'
      ) {
        valueUI = getDateShown({
          date: option.value,
          mode: responseType,
          displayFormat:
            responseType === 'datetime'
              ? ('MM/dd/yyyy h:mm aa' as DatePickerDisplayFormat)
              : responseType === 'date'
              ? ('MM/dd/yyyy' as DatePickerDisplayFormat)
              : ('h:mm aa' as DatePickerDisplayFormat),
        });
      }
      newSubmitted[key] = {
        value: option.value,
        valueUI,
        valueSubmitted: toValueObject(responseType, option.value),
      };
    }
  }
  return (
    <>
      <ResponseForm
        onSubmit={setSubmitted}
        onValidate={(): Errors => {
          // IDEA replace with json-schema based validator
          // WHEN is convenient
          const errors: Errors = {};
          return errors;
        }}
      >
        {FIELDS.filter(e => filter.includes(e.component)).map(el =>
          el?.questionProps && el?.component ? (
            <ResponseInput
              key={
                // IDEA Remove String() call below, WHEN id is non-optional
                el.questionProps.semantic_id + String(el.questionProps.id)
              }
              component={el.component}
              questionProps={el.questionProps}
              initialValue={
                fieldprops?.[el.questionProps.semantic_id]?.initialValue
              }
            />
          ) : null
        )}
        {<SubmitButton />}
      </ResponseForm>
      {
        <View>
          <Text testID="submitted">
            {JSON.stringify(newSubmitted, null, 2)}
          </Text>
        </View>
      }
    </>
  );
};

addStory('form ResponseOption', () => {
  return (
    <EphemeralStateProvider>
      <CrossPlatformThemeProvider
        theme={getThemeForProduct(ProductReferences.SLEEPIO)}
      >
        <Story />
      </CrossPlatformThemeProvider>
    </EphemeralStateProvider>
  );
});
