import format from 'date-fns/format';

export const formatAnalyticsDate = (date: Date): string => {
  const utcDateStr = date.toISOString();
  const dateWithoutZulu = utcDateStr.substr(0, utcDateStr.length - 1);
  // Could also just set the "T" to a blank space
  return format(new Date(dateWithoutZulu), 'yyyy-MM-dd HH:mm:ss.SSS');
};

/**
 * Gets the current time relative to UTC but without the timezone
 */
export const getAnalyticsDate = (): string => {
  return formatAnalyticsDate(new Date());
};
