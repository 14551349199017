import { Query } from 'react-query';

type CheckerFn = (query: Query) => boolean | undefined;

/**
 * Creates a predicate to determine if Throbber should be visible
 *
 * @see {@link ./README.md} for more info
 *
 * @param {CheckerFn[]} predicates
 *    This is an **Ordered-list** of _checker functions_
 *    * These _checker function_ are executed in order by the predicate
 *    * If a _checker function_ returns `undefined`, the next function called
 *    * If a _checker function_ returns `true` or `false`, this is the predicate result
 *    * If all _checker functions_ have returned `undefined`, a default is returned
 */
const isFetchingWithThrobberPredicateFactory = (predicates: CheckerFn[]) => (
  query: Query
): boolean => {
  for (let i = 0; i < predicates.length; i++) {
    const showThrobber = predicates[i](query);
    if (typeof showThrobber !== 'undefined') {
      // if predicate has opinion on throbber, return
      return showThrobber;
    }
    // ... else keep checking
  }
  // ...else default to showThrobber
  return true;
};

export { CheckerFn, isFetchingWithThrobberPredicateFactory };
