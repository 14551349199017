/* istanbul ignore file */
import React, { ReactElement, useContext } from 'react';
import { ViewProps } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { FillAbsolute } from 'components/Containers';
import { LoginFooter } from 'components/Footer/LoginFooter';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout/hooks/useGetDynamicContentStyles';
import {
  ButtonColorSchemes,
  UniversalButton,
} from 'components/UniveralButtons';
import { ResizeMode } from 'cross-platform/Image';
import { useHistory, useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { useTransformContentStyle } from 'daylight/hooks';
import { LoginPhases } from 'lib/api/hooks/useLogin';
import { useBigHealthLogin } from 'lib/api/hooks/useLogin/useBigHealthLogin';

import {
  BackgroundImage,
  ButtonContainer,
  FormattedText,
  LimitWidth,
  LoginSplashScreenScroll,
  Logo,
  LogoContainer,
  TextContainer,
} from './LoginSplashScreen.styled';

type ProductParams = {
  productReference: string;
};

export const LoginSplashScreen = ({
  byLine,
  noAccountOptional,
  noAccount,
  website,
  logoAsset,
  splashScreenAsset,
  overrides,
  optionalFooter,
}: {
  byLine: string;
  noAccountOptional?: string;
  noAccount: string;
  website: string;
  // TODO remove optional modifier
  // HOW create platform-specific types for getAssetsForProduct
  // WHEN have plenty fo time and care about types
  logoAsset?: string;
  splashScreenAsset?: string;
  /* "overrides" are short fixes, which will be removed once theming is fixed */
  overrides?: {
    SignInButton?: {
      colorScheme?: ButtonColorSchemes;
    };
    FillAbsolute?: ViewProps;
  };
  optionalFooter?: boolean;
}): ReactElement => {
  const { status } = useBigHealthLogin();

  const transformStyle = useTransformContentStyle();
  const transformStyleVertical = useTransformContentStyleVertical();
  const { productReference } = useParams<ProductParams>();
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const screenOrientation = useScreenOrientation();
  const isPortrait =
    screenOrientation === 'PORTRAIT' ||
    screenOrientation === 'PORTRAIT-UPSIDEDOWN';

  const handleSubmit = async (): Promise<void> => {
    history.push(`/${productReference}/login-screen`);
  };

  const transformWidth = website.includes('Sleepio') ? 180 : 120;

  return (
    <FillAbsolute {...(overrides?.FillAbsolute || null)}>
      <LoginSplashScreenScroll>
        {splashScreenAsset !== undefined && (
          <BackgroundImage
            {...roles('BackgroundImageSplashScreen')}
            src={splashScreenAsset}
            alt={`${productReference} Splash Logo`}
          />
        )}
        <LimitWidth>
          <LogoContainer>
            <Logo
              src={logoAsset}
              fit={ResizeMode.contain}
              style={{
                width: transformStyle(transformWidth),
                height: 120,
                alignSelf: 'flex-end',
              }}
              alt={`${productReference} Logo`}
            />
          </LogoContainer>
          <TextContainer
            style={{
              paddingTop: transformStyleVertical(32),
              height: isPortrait ? '45%' : 'auto',
            }}
          >
            <FormattedText
              // TODO: use the theme
              // WHEN alignment with designers is found
              style={{
                fontSize: transformStyle(24),
                color: theme.color.text.splashPage,
              }}
            >
              {byLine}
            </FormattedText>
          </TextContainer>
          <ButtonContainer>
            <TextContainer
              style={{
                paddingTop: transformStyleVertical(0),
                height: 'auto',
              }}
            >
              {noAccountOptional ? (
                <FormattedText
                  style={{
                    fontSize: transformStyle(18),
                    color: theme.color.text.splashPage,
                    width: '100%',
                    paddingBottom: 16,
                  }}
                >
                  {noAccountOptional}
                </FormattedText>
              ) : null}
              <FormattedText
                style={{
                  fontSize: 20,
                  fontWeight: '500',
                  color: theme.color.text.splashPage,
                  width: '100%',
                  paddingBottom: 16,
                }}
              >
                Don&apos;t have an account?
              </FormattedText>
              <FormattedText
                style={{
                  fontSize: transformStyle(16),
                  color: theme.color.text.splashPage,
                  fontWeight: '500',
                  paddingBottom: 32,
                }}
              >
                {noAccount}
              </FormattedText>
              <UniversalButton
                {...roles('SignIn-button')}
                onPress={handleSubmit}
                isDisabled={false}
                text={'Sign in'}
                colorScheme={
                  overrides?.SignInButton?.colorScheme ||
                  ButtonColorSchemes.Secondary
                }
              />
              {optionalFooter ? <LoginFooter /> : null}
            </TextContainer>
          </ButtonContainer>
          {status === LoginPhases.FETCHING ? <LoadingOverlay /> : null}
        </LimitWidth>
      </LoginSplashScreenScroll>
    </FillAbsolute>
  );
};

export default LoginSplashScreen;
