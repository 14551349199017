import React, { ReactElement } from 'react';
import { View } from 'react-native';

import { StatusIndicator } from 'components/icons';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useQueryAllForSessionScreen } from 'lib/api/reactQueryHelpers';

import { getHasSessionAvailable } from './helpers/getHasSessionAvailable';

const SessionAvailabilityIndicator = ({
  iconSize,
  testID,
  ...rest
}: {
  iconSize: number;
  testID?: string;
}): ReactElement => {
  const { data } = useQueryAllForSessionScreen({
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });
  const color = getHasSessionAvailable(data?.result)
    ? '#57D6A5'
    : 'transparent';

  return (
    <View testID={testID} {...rest}>
      <StatusIndicator size={iconSize} style={{ color }} />
    </View>
  );
};

export default SessionAvailabilityIndicator;
