import React from 'react';
import ReactMarkdown from 'react-markdown';
import { View } from 'react-native';

import * as roleProps from 'cross-platform/utils/roleProps';
import { RoleProps } from 'cross-platform/utils/roleProps';

import { Details, Paragraph } from '../styled.web';
import { linkRenderer } from '../utils/linkRenderer.web';

type Props = { children: string } & RoleProps;
const Markdown = ({ children, ...restProps }: Props): JSX.Element => {
  return (
    <View {...roleProps.pass(restProps, undefined, { default: 'Markdown' })}>
      <ReactMarkdown
        renderers={{
          paragraph: Paragraph,
          text: Details,
          link: linkRenderer,
        }}
      >
        {children}
      </ReactMarkdown>
    </View>
  );
};

export { Markdown };
