import React, { ReactElement, ReactNode } from 'react';

export enum ScalingContext {
  Screen = 'SCREEN',
  Section = 'SECTION',
  MediaContainer = 'MEDIA_CONTAINER',
  Modal = 'MODAL',
  ContentScreen = 'CONTENT_SCREEN',
  Player = 'PLAYER',
}

const defaultScalingContext = ScalingContext.Screen;

export const ScalingContextContext = React.createContext<ScalingContext>(
  defaultScalingContext
);

export const ScalingContextProvider = ({
  scalingContext: drivenScalingContext,
  children,
}: {
  scalingContext?: ScalingContext;
  children: ReactNode;
}): ReactElement => {
  const scalingContext = drivenScalingContext || defaultScalingContext;
  return (
    <ScalingContextContext.Provider value={scalingContext}>
      {children}
    </ScalingContextContext.Provider>
  );
};
