/**
 * IDEA: pull these values from the content
 * WHEN: when these props can be added to the content.continue_button
 *  */
export const mfaToggleView = {
  MFA_IS_ENABLED_DISABLED: (isMFAEnabled: boolean): string =>
    `Multi-factor Authentication is ${isMFAEnabled ? 'enabled' : 'disabled'}`,
  TOGGLE_MFA: 'Toggle MFA',
  PRESS_TO_ENABLE_DISABLE_MFA: (isMFAEnabled: boolean): string =>
    `Press to ${isMFAEnabled ? 'disable' : 'enable'} MFA`,
};

export default {
  mfaToggleView,
};
