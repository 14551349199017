import { Config } from '../../..';
// FIXME rename createQuestionPropsDelta
/**
 * Generate questionProp modification (for merge) approximating what backend does
 *
 * This allows programmatic changing of max/min at runtime
 *
 * @param min
 * @param max
 */
const createQuestionProps = (
  min: number,
  max: number,
  diaryDate?: Date
): Config => ({
  ...(typeof diaryDate !== 'undefined'
    ? {
        state: {
          /**
           * TODO
           * - Perhaps make "state" agnostic to the response type
           * - else need to parametise everything with "DropdownTimePicker", "SelectHorizontalTime", etc
           * WHEN SLEEPIO-2277
           */
          date: diaryDate,
        },
      }
    : {}),
  questionProps: {
    response_config: {
      response_options: [
        {
          min_response: new Date(min),
          max_response: new Date(max),
        },
      ],
      validation_schema: {
        properties: {
          '1': {
            type: 'number',
            minimum: min,
            exclusiveMaximum: max,
          },
        },
      },
    },
  },
});

export { createQuestionProps };
