export { HorizontalWrapper } from './HorizontalWrapper/HorizontalWrapper';
import React, { ReactElement, useContext } from 'react';
import {
  AccessibilityProps,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

import { StyleObject } from '@bighealth/types/src/scene-components/client';

import {
  useGetDynamicInputStyles,
  useTransformStylesToContext,
} from 'components/ResponsiveLayout';
import { Label } from 'components/Semantics/Label';
import { Text } from 'components/Text';

import { AccessibleWrapper } from '../../../AccessibleWrapper';
const flatten = StyleSheet.flatten;

export const QuestionTitleContainer: React.FC<ViewProps> = ({
  style,
  ...rest
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyles = useTransformStylesToContext(theme);
  const styles = {
    ...flatten(style),
    paddingBottom: theme.modal.question.modalQuestionTitleMarginBottomPx,
  };
  return (
    <AccessibleWrapper>
      <View
        {...rest}
        // Needs coercing due to slight (and not meaningful) difference between types
        style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
      />
    </AccessibleWrapper>
  );
};

export const QuestionTitle: React.FC<React.PropsWithChildren<{
  style?: TextStyle;
  htmlFor?: string;
  id?: string;
}>> = ({
  style,
  ...rest
}: {
  style?: TextStyle;
  htmlFor?: string;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyles = useTransformStylesToContext(theme);

  const styles = {
    ...flatten(style),
    fontSize: theme.modal.question.modalQuestionTitleFontSizePx,
    color: theme.controls.color,
    fontWeight: theme.modal.question.modalQuestionTitleFontWeight,
  };
  return (
    <Label
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

export const PreTextContainer: React.FC<ViewProps> = ({
  style,
  ...rest
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyles = useTransformStylesToContext(theme);
  const styles = {
    ...flatten(style),
    marginBottom: theme.modal.question.modalQuestionPretextMarginBottomPx,
  };
  return (
    <AccessibleWrapper>
      <View
        {...rest}
        style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
      />
    </AccessibleWrapper>
  );
};

const QuestionPreTextStatic = styled(Text)`
  color: black;
`;

export const QuestionPreText: React.FC<React.PropsWithChildren<
  AccessibilityProps & {
    style?: TextStyle;
  }
>> = ({ style, ...rest }: { style?: TextStyle }): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyles = useTransformStylesToContext(theme);
  const styles = {
    ...flatten(style),
    fontSize: theme.modal.question.modalQuestionPretextFontSizePx,
  };
  return (
    <QuestionPreTextStatic
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

const ResponsePreTextStatic = styled(Text)`
  color: #666666;
`;

export const ResponsePreText: React.FC<React.PropsWithChildren<
  AccessibilityProps & {
    style?: TextStyle;
  }
>> = ({ style, ...rest }: { style?: TextStyle }): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyles = useTransformStylesToContext(theme);
  const styles = {
    ...flatten(style),
    fontSize: theme.modal.question.modalQuestionPretextFontSizePx,
  };
  return (
    <ResponsePreTextStatic
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

export const InputPadding: React.FC<ViewProps> = ({
  style,
  ...rest
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyles = useTransformStylesToContext(theme);
  const { inputMarginBottom } = useGetDynamicInputStyles(theme);

  const styles = {
    ...flatten(style),
    paddingBottom: inputMarginBottom,
  };
  return (
    <View
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};
