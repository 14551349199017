import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { SceneSet, SceneSetGraph } from '../../../types/src';
import { AssetEntity } from '../../../types/src/services/Asset';
import { Scene } from '@bighealth/types/src/scene-component';
import { AxiosRequestConfig } from 'axios';
import {
  Accessibility,
  DateValueObject,
  ResponseOption,
} from '@bighealth/types';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';
import { ClientFeatureVersions } from '@bighealth/types/src/ClientFeatureVersions';

export const get_user_session_screen_data = async (
  args: get_user_session_screen_data.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_user_session_screen_data.Response> => {
  const request: get_user_session_screen_data.Request = {
    service_name: 'SessionProgress',
    service_version: '1',
    service_method: 'get_user_session_screen_data',
    args,
  };

  return await api(request, requestConfig);
};

get_user_session_screen_data.queryKey =
  'SessionProgress/get_user_session_screen_data';

// FIXME needs to be created and added to sceneSetComponentDict
// Does NOT need to be added to @bighealth/types yet
export type MarkdownSceneComponent = {
  component: 'Markdown';
  text: string;
  type?: string;
  style?: {
    [key: string]: Scene.Presentational.StyleObject;
  };
  accessibility?: Accessibility;
};

//
/**
 * TODO Resolve this type not being in packages-shared/types
 * WHY Its inconsistent
 * HOW
 * - work out if we should force all types in packages-shared/types.
 * - Or if packages-shared/types is pointless
 * WHEN FEG
 */
export declare namespace get_user_session_screen_data {
  export type Args = {
    program_id: number;
    // If session_screen_version is not present (in prev versions of code),
    // backend will respond with old-style payload response (SLEEPIO-2138)
    session_screen_version: ClientFeatureVersions.SessionScreenVersion;
    //
    session_1_mobile_web?: ClientFeatureVersions.Session1MobileWeb;
  };
  type ABTestVariants = { sleepio_mobileweb_s1?: boolean };

  export interface Request extends APIRequestBody {
    service_name: 'SessionProgress';
    service_version: '1';
    service_method: 'get_user_session_screen_data';
    args: Args;
  }

  type SessionScreenComponent =
    | Scene.Components.Text
    | Scene.Components.Button
    | MarkdownSceneComponent
    | GroupComponent
    | Scene.Contexts.Client.Components.Image;

  type ResultComponentData = {
    style: Scene.Presentational.ViewStylesObject;
    childNodes: SessionScreenComponent[];
  };

  type GroupComponent = {
    component: 'Group';
    style: Scene.Presentational.ViewStylesObject;
    childNodes: SessionScreenComponent[];
    accessibility?: Accessibility;
  };

  type MigrationBanner = {
    banner_title: Scene.Components.Text;
    banner_content: MarkdownSceneComponent;
  };

  type ResponseOptionWithImage = Readonly<
    ResponseOption & {
      background_color?: string;
      image?: AssetEntity;
    }
  >;

  type JumpToSceneSetAction = {
    type: 'jump_to_sceneset';
    payload: {
      sceneSetGraphId: SceneSetGraph['id'];
      destinationSceneSetId: SceneSet['id'];
    };
  };

  type NavAction = {
    type: 'NAV_TO';
    to: string;
  };

  type SleepDiaryQuestion = {
    type: 'question';
    heading: {
      text: string;
    };
    title: {
      text: string;
    };
    diary_request: SleepDiaryPayloads['get_diary_entry_form']['request'];
    diary_date: DateValueObject;
    response_options: Array<ResponseOptionWithImage>;
  };

  type SleepEfficiencyButton = {
    action: Scene.Utils.SceneAction;
    text: string;
  };

  type SleepEfficiency = {
    type: 'efficiency';
    heading: {
      text: string;
    };
    title: {
      text: string;
    };
    sleep_efficiency: {
      text: string;
    };
    buttons: Array<SleepEfficiencyButton>;
  };

  type SessionCard = {
    status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'LOCKED';
    heading: {
      text: string;
    };
    status_text: {
      text: string;
    };
    play_action: {
      type: 'jump_to_sceneset';
      payload: {
        sceneSetGraphId: SceneSetGraph['id'];
        destinationSceneSetId: SceneSet['id'];
      };
    } | null;
    title?: { text: string };
    description?: { text: string };
    duration?: { text: string };
    lock_text?: { text: string };
    session_thumb_image_asset: AssetEntity;
  };

  // For the future
  // Could be more than 1 item returned
  type SleepDiaryData = Array<SleepDiaryQuestion | SleepEfficiency>;

  type ResultMobileWeb = {
    header: {
      title: ResultComponentData;
      content: ResultComponentData;
      buttons: ResultComponentData;
    };
    main: ResultComponentData;
    migration_banner?: MigrationBanner;
    background_image_asset: AssetEntity | null;
    ab_test_variants?: ABTestVariants;
  };

  type PreviewModal = {
    header: ResultComponentData;
    main: {
      column_1: ResultComponentData;
      column_2: ResultComponentData;
    };
  };

  type ResultStandard = {
    session_status: {
      heading: {
        text: string;
      };
      buttons: Array<{
        text: string;
        colorScheme: 'primary_on_dark' | 'standard' | 'alt-primary'; // ButtonColorSchemes
        action: JumpToSceneSetAction | NavAction;
      }>;
    };
    session_list: Array<SessionCard>;
    optional_content_list: Array<SessionCard>;
    background_image_asset: AssetEntity | null;
    has_new_session_available: boolean;
    has_in_progress_session_available: boolean;
    modal?: PreviewModal;
    distribution_buttons?: ResultComponentData;
    // TODO: this should never exist on mobile
    migration_banner?: MigrationBanner;
    sleep_diary_data?: SleepDiaryData;
    ab_test_variants?: ABTestVariants;
    progress_review_data?: {
      heading: {
        text: string;
      };
      buttons: Array<{
        text: string;
        colorScheme: 'primary_on_dark' | 'standard' | 'alt-primary'; // ButtonColorSchemes
      }>;
      action: JumpToSceneSetAction;
    } | null;
  };

  export type Result = ResultMobileWeb | ResultStandard;
  export type Response = APIResponse<Result>;
}
