import React, { ReactElement } from 'react';
import { Linking } from 'react-native';
import { View } from 'react-native';

import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { SettingsScreen } from '../SettingsScreen';
import {
  SettingsContentLinkText,
  SettingsContentText,
} from '../SettingsScreen.styled';

import { ContentLinkArray } from './types';

const emergencySupportContent: ContentLinkArray = [
  {
    testID: 'Lifeline-link',
    link: 'http://suicidepreventionlifeline.org',
    text: 'U.S. Suicide Prevention Lifeline',
  },
  {
    testID: 'Samaritans-link',
    link:
      'https://www.samaritans.org/?inf_contact_key=910ad657a8cb8adbdf1a87834d33ec4e4dfbc39d7283b2cb89d5189540b69330',
    text: 'U.K. or Ireland Samaritans',
  },
];

export const EmergencySupportView = (): ReactElement => {
  const transformStyle = useTransformContentStyle();
  return (
    <SettingsScreen title={'Emergency Support'}>
      <View style={{ padding: transformStyle(24) }}>
        <SettingsContentText
          bold
          fontSize={transformStyle(18)}
          marginBottom={transformStyle(10)}
          lineHeight={transformStyle(24.3)}
        >
          Need additional support?
        </SettingsContentText>
        <SettingsContentText
          fontSize={transformStyle(18)}
          marginBottom={transformStyle(10)}
          lineHeight={transformStyle(24.3)}
        >
          Daylight is a self-help program. For additional help, please contact
          your company’s EAP, your doctor, or someone you trust.
        </SettingsContentText>
        <SettingsContentText
          fontSize={transformStyle(18)}
          marginBottom={transformStyle(10)}
          lineHeight={transformStyle(24.3)}
        >
          If you’re concerned for your safety, contact your local emergency
          services, or go to the nearest emergency room.
        </SettingsContentText>
        <SettingsContentText bold>
          For suicide prevention resources, visit:
        </SettingsContentText>
        {emergencySupportContent.map((content, index) => {
          return (
            <SettingsContentLinkText
              testID={content.testID}
              key={index}
              onPress={() => Linking.openURL(content.link)}
              fontSize={transformStyle(18)}
              lineHeight={transformStyle(24.3)}
              marginTop={transformStyle(24)}
            >
              {content.text}
            </SettingsContentLinkText>
          );
        })}
      </View>
    </SettingsScreen>
  );
};
