import React, { useEffect, useRef, useState } from 'react';
import { Animated, Platform } from 'react-native';

import { ButtonContainerWithMinMax } from 'components/Button/components';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { ButtonTypes } from 'config/index';
import { Redirect } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { useLoginWithOtp } from 'lib/api/hooks/useLogin/useLoginWithOtp';

import {
  Input,
  MFAVerificationText,
  NeedHelpText,
  PaddingContainer,
} from '../../styled';
import NeedHelpModal from '../NeedHelpModal/NeedHelpModal';

type MFAVerificationProps = {
  productReference: string;
  email: string;
  onError: (message?: string) => void;
};

const MFAVerification = ({
  productReference,
  email,
  onError,
}: MFAVerificationProps) => {
  const animated = useRef(new Animated.Value(0)).current;
  const [otp, setOtp] = useState<string>('');
  const { loading, error, redirect, login } = useLoginWithOtp();
  const [showNeedHelpModal, setShowNeedHelpModal] = useState(false);
  const maxInputLength = 6;

  const handleChange = (input: string) => {
    if (input.length > maxInputLength) {
      return;
    }
    setOtp(input.replace(/[^0-9]/g, ''));
  };

  const handleSubmit = async (): Promise<void> => {
    if (otp?.length !== maxInputLength) {
      return;
    }
    login(otp, email);
  };

  const handleError = () => {
    if (onError instanceof Function) {
      onError(error);
    }
  };

  useEffect(() => {
    Animated.timing(animated, {
      toValue: 1,
      duration: 500,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [animated]);

  if (error) {
    handleError();
  }

  if (redirect) {
    return <Redirect to={`/${productReference}${'/home'}`} />;
  }

  return (
    <Animated.View style={[{ opacity: animated }]}>
      <PaddingContainer>
        <MFAVerificationText
          aria-live="polite"
          accessibilityRole="header"
          role="heading"
          aria-level="2"
        >
          Enter the verification code from your authentication app to complete
          log in
        </MFAVerificationText>
        <Input
          {...roles('mfa-verification-input')}
          placeholder={'6-digit code'}
          placeholderTextColor={'#757575'}
          keyboardType={'number-pad'}
          type={'number'}
          required
          autoCapitalize={'none'}
          returnKeyType={'next'}
          autoCorrect={false}
          onChangeText={handleChange}
          value={otp}
          autoFocus
        />
      </PaddingContainer>
      <ButtonContainerWithMinMax type={ButtonTypes.PRIMARY_BUTTON}>
        <UniversalButton
          {...roles('mfa-verification-button')}
          onPress={handleSubmit}
          text="Continue"
          colorScheme={ButtonColorSchemes.MultiFactorAuthentication}
          size={ButtonSizes.Small}
          isDisabled={otp.length !== maxInputLength || loading}
        />
      </ButtonContainerWithMinMax>
      <NeedHelpText
        {...roles('mfa-verification-need-help-link')}
        onPress={() => {
          setShowNeedHelpModal(true);
        }}
      >
        Need Help?
      </NeedHelpText>
      <NeedHelpModal
        show={showNeedHelpModal}
        onClose={() => setShowNeedHelpModal(false)}
      />
    </Animated.View>
  );
};

export default MFAVerification;
