import { View } from 'react-native';
import styled from 'styled-components/native';

import Link from 'components/Link';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { Text } from 'components/Text';
import TextInput from 'components/TextInput';
import Image from 'cross-platform/Image';

export const LimitWidth = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  max-width: 380px;
  width: 100%;
  padding-top: 100px;
`;

export const FormattedText = styled(Text)`
  font-size: 19px;
  color: white;
  text-align: center;
`;

export const Logo = styled(Image)`
  width: 170px;
  height: 100px;
`;

export const LogoLink = styled(Link)`
  align-self: center;
`;

export const ButtonContainer = styled.View`
  display: flex;
  align-self: center;
  flex-direction: column;
  padding-horizontal: 60px;
  align-items: center;
`;

export const PaddingContainer = styled.View`
  flex-direction: column;
  margin-bottom: 20px;
  padding-horizontal: 30px;
  display: flex;
  align-items: stretch;
`;

export const ErrorContainer = styled.View`
  margin-top: 5px;
`;

export const AttemptErrorContainer = styled(View)`
  border-color: ${props => props.theme.color.error};
  background-color: ${props => props.theme.color.selectedPink};
  margin-top: 16px;
  padding: 10px;
  border: solid 1.5px #d0021b;
  border-radius: 4px;
`;

export const ValidationText = styled<typeof Text>(Text)`
  font-size: 16px;
  text-align: left;
  font-family: '${FontFamilies.Medium}';
  color: #D72020;
`;

export const AttemptValidationText = styled<typeof Text>(Text)`
  font-size: 16px;
  text-align: center;
  font-family: '${FontFamilies.Medium}';
  color: ${props => props.theme.color.black};
`;

export const MultiLineInput = styled(TextInput)<{
  hasErrors?: boolean;
  isFocused?: boolean;
}>`
  font-size: 16px;
  font-style: normal;
  border-radius: 8px;
  text-decoration-line: none;
  box-shadow: ${({ isFocused, ...props }) =>
    isFocused
      ? `0px 0px 4px ${props.theme.color.selectedPink};`
      : `0px 0px 0px;`};
  color: ${props => props.theme.color.text.primary};
  background-color: ${props =>
    props.value
      ? props.theme.color.white
      : props.theme.color.disabledInputGrey};
  border: ${({ hasErrors, isFocused, ...props }) =>
    hasErrors
      ? `solid 1.5px ${props.theme.color.error};`
      : isFocused
      ? `solid 1px ${props.theme.color.primary};`
      : `1px solid ${props.theme.color.inputOutlineGrey}`};
`;
