export const leftColumnBase = 650;
export const rightColumnBase = 405;
export const doubleColumnBase = leftColumnBase + rightColumnBase;
export const smallScreenBreakpoint = 767;
export const headerStopBreakpoint = 450;
// 132 is 100 + 32 on each side where
// 100 is the maximum space on the right side between the content and the close button
// 32 is an additional room for the built-in right margin of the close button that is 32
export const navigationMaxWidth = doubleColumnBase + 132 * 2;

export const DOMAIN = 'PreviewModal';
