import * as React from 'react';

import { ProgressScreen } from 'components/Screens/ContentScreens/ProgressScreen';
import { addStory } from 'lib/story-books';

import { renderData } from '../WithNetworkAndState/__mocks__/screen_data';
import { Providers } from '../WithNetworkAndStateAndWrappers/components/Providers';

import { Presentation } from '.';

addStory('components/Screens/SessionsScreen/components/ProgressScreen;', () => (
  <ProgressScreen />
));

addStory('components/Screens/SessionsScreen/components/Presentation', () => (
  <Providers>
    <Presentation data={renderData.result} />
  </Providers>
));
