import { PLATGEN_FF_USE_SNAPENGAGE, SNAPENGAGE_ID } from 'config/envVars';

let done = false;

export const handleStateChange = function(this: { readyState?: string }) {
  if (
    !done &&
    (!this.readyState ||
      this.readyState === 'loaded' ||
      this.readyState === 'complete')
  ) {
    done = true;
    /* Place your SnapEngage JS API code below */
    /* SnapEngage.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
    window.SnapEngage.setButtonEffect(0);
  }
};

export const initializeSnapEngage = (): void => {
  if (!PLATGEN_FF_USE_SNAPENGAGE) {
    return;
  }
  (function() {
    const anyScript = document.querySelector('script');
    const snapEngageScript = document.createElement('script');
    snapEngageScript.addEventListener('onload', handleStateChange);
    snapEngageScript.addEventListener('onreadystatechange', handleStateChange);
    snapEngageScript.type = 'text/javascript';
    snapEngageScript.async = true;
    snapEngageScript.src = `//storage.googleapis.com/code.snapengage.com/js/${SNAPENGAGE_ID}.js`;
    snapEngageScript.id = 'snapengage';

    anyScript?.parentNode?.insertBefore(snapEngageScript, anyScript);
  })();
};
