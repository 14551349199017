import { Question } from '@bighealth/types';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { mergeDeepAndByIndex } from 'lib/utils/mergeDeepAndByIndex';
import { QuestionId } from 'state/question-response/actions';

import { checkInputValues } from './helpers/checkInputValues';
import { getHiddenFields } from './getHiddenFields';
import { getInitialValuesAndOptions } from './getInitialValuesAndOptions';
import { getWarningsAndHighlights } from './getWarningsAndHighlights';

/**
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 * @param {Record<QuestionId, Question>} initialQuestionProps question props to generate initial values and props from
 * @return State to represent inter-field flow
 */
const getFlowState = (
  inputs: Record<QuestionId, DropdownItem[]>,
  initialQuestionProps?: Record<QuestionId, Question | undefined>,
  diaryDate?: Date
): Partial<typeof hiddenFields &
  typeof warningsAndHighlights &
  typeof initialValuesAndOptions> => {
  checkInputValues(inputs);
  const hiddenFields = getHiddenFields(inputs, initialQuestionProps);
  const warningsAndHighlights = getWarningsAndHighlights(inputs, diaryDate);
  const initialValuesAndOptions = getInitialValuesAndOptions(
    inputs,
    initialQuestionProps,
    diaryDate
  );
  return mergeDeepAndByIndex(
    mergeDeepAndByIndex(warningsAndHighlights, hiddenFields),
    initialValuesAndOptions // has deep structure, thus needs mergeDeepAndByIndex()
  );
};

export { getFlowState };
