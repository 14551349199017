import { ProductReferences } from 'common/constants/enums';

import { daylightTheme } from './themes/daylight';
import { sleepioTheme } from './themes/sleepio';
import { sleepioV2Theme } from './themes/sleepioV2';
import { Theme as InternalTheme } from './utils/createTheme';

export type Theme = InternalTheme;

export type ThemeProp = {
  readonly theme: Theme;
};

const getThemeForProduct = (
  productReference: ProductReferences,
  version?: string
): Theme => {
  switch (productReference) {
    case ProductReferences.DAYLIGHT:
      return daylightTheme;
    case ProductReferences.SLEEPIO:
      if (version === 'v2') {
        return sleepioV2Theme;
      }
      return sleepioTheme;
    default:
      return sleepioTheme;
  }
};

export const DEFAULT_THEME = getThemeForProduct(ProductReferences.SLEEPIO);

export { getThemeForProduct };
