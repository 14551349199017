import React, { ReactElement, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import styled from 'styled-components/native';

import { FillAbsoluteCenter } from 'components/Containers';
import * as roleProps from 'cross-platform/utils/roleProps';

import { DOMAIN, USE_NATIVE_DRIVER } from './constants';

const Rotation = styled(Animated.View)``;
Rotation.displayName = `${DOMAIN}.Rotation`;

export const Throbber = (props: roleProps.RoleProps): ReactElement | null => {
  const rotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.timing(rotateAnim, {
        toValue: 1,
        duration: 600,
        useNativeDriver: USE_NATIVE_DRIVER,
      })
    ).start();
  }, [rotateAnim]);

  return (
    <FillAbsoluteCenter {...roleProps.pass(props)}>
      <Rotation
        style={{
          transform: [
            {
              rotate: rotateAnim.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', '360deg'],
              }),
            },
          ],
        }}
      >
        <Svg id="throbber" width="70px" height="70px" viewBox="0 0 50 50">
          <Path
            fill="#B2B2B2"
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
          />
        </Svg>
      </Rotation>
    </FillAbsoluteCenter>
  );
};
