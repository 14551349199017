import React, { ReactElement } from 'react';
import Svg, { Circle, G } from 'react-native-svg';

/**
 * Progress dot icon
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const ProgressDot = ({
  size,
  color,
}: {
  size: number;
  color: string;
}): ReactElement => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 40 40">
    <G fill="none" fillRule="evenodd">
      <Circle
        cx="9.76636"
        cy="22.1128"
        r="7.5"
        fill="#888888"
        stroke={color}
        strokeWidth={3}
      />
    </G>
  </Svg>
);
