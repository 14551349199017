import React, { ReactElement, ReactNode } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import {
  useGetScreenDimensions,
  useScaleToScalingContext,
} from 'components/ResponsiveLayout';
import { getIsNarrowMobileWeb } from 'components/ResponsiveLayout/helpers/getIsNarrowMobileWeb';
import { Text as SceneComponentText } from 'components/sceneset-components/Text';
import { ButtonColorSchemes } from 'components/UniveralButtons/buttonColorSchemes';
import { UniversalButton } from 'components/UniveralButtons/UniversalButton';
import { pass } from 'cross-platform/utils/roleProps';

export const WrapperForm = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const scaleToContext = useScaleToScalingContext();

  const { screenWidth } = useGetScreenDimensions();
  return (
    <View
      style={{
        paddingTop: getIsNarrowMobileWeb(screenWidth)
          ? 200 /* Approx height react-datepicker box */
          : scaleToContext(64),
        paddingBottom: scaleToContext(64),
      }}
    >
      {children}
    </View>
  );
};

export const ContinueButton = ({
  text,
  onPress,
  ...rest
}: {
  text: string;
  onPress: () => void;
}): ReactElement => {
  return (
    <UniversalButton
      text={text}
      colorScheme={ButtonColorSchemes.Link}
      style={{ width: 310 }}
      onPress={onPress}
      {...pass(rest)}
    ></UniversalButton>
  );
};

export const ControlWrapper = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const scaleToContext = useScaleToScalingContext();
  return (
    <View
      style={{
        paddingTop: scaleToContext(24),
      }}
    >
      {children}
    </View>
  );
};

export const SubmitButton = ({
  title,
  onPress,
  ...rest
}: {
  title: string;
  onPress: () => void;
}): ReactElement => {
  return (
    // TODO: set the right width of the button
    <UniversalButton
      text={title}
      colorScheme={ButtonColorSchemes.Standard}
      onPress={onPress}
      {...pass(rest)}
      style={
        {
          // width: scaleToContext(286),
          // height: scaleToContext(55),
        }
      }
    />
  );
};

export const SubmitButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled(SceneComponentText)`
  align-items: center;
  justify-content: center;
  width: 100%;
`;
