import React, { ReactElement } from 'react';

import { FillAbsolute } from 'components/Containers';
import { Footer } from 'components/Footer';
import { roles } from 'cross-platform/utils/roleProps';

export const LoadingBlankScreen = (): ReactElement => (
  <FillAbsolute
    {...roles('LoadingBlankScreen')}
    style={{ backgroundColor: 'white', justifyContent: 'flex-end' }}
  >
    <Footer />
  </FillAbsolute>
);
