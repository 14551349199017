import styled from 'styled-components/native';

import { DOMAIN } from './constants';

export const EntryTextRowContainer = styled.View`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;
EntryTextRowContainer.displayName = `${DOMAIN}.EntryTextRowContainer`;

export const Padding = styled.View`
  width: 1%;
`;
Padding.displayName = `${DOMAIN}.Padding`;

export const TextContainer = styled.View`
  display: flex;
  width: 75%;
`;
TextContainer.displayName = `${DOMAIN}.TextContainer`;

export const UserMessageText = styled.View`
  flex-direction: row;
`;
UserMessageText.displayName = `${DOMAIN}.UserMessageText`;
