import { Scene } from '@bighealth/types/dist/scene-component';

export const isOpenLinkAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: Record<string, any>
): action is Scene.Utils.OpenLinkAction => {
  if (action?.type === 'open_link' && typeof action?.payload === 'string') {
    return true;
  }
  return false;
};
