import { ResponseOption } from '@bighealth/types';
import { FormTypes } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { DiaryEntry } from '@bighealth/types/src/services/SleepDiaryPayloads/types';

import {
  AWAKENINGS_IN_NIGHT,
  AWAKENINGS_TOTAL_TIME,
  SLEEP_QUALITY,
  TIME_FINAL_AWAKENING,
  TIME_GET_OUT_OF_BED,
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
  TO_FALL_ASLEEP_TOTAL_TIME,
} from 'components/SleepDiaryForm/constants';
import { getTimezone } from 'lib/timezone';

import {
  tryFromMillisecondsToMinutes,
  tryGetTime,
  tryParseInt,
} from './helpers';

/**
 * Convert form values to DiaryEntry
 *
 * TODO Check if convert to outgoing middleware interceptor
 * WHY There is an an ingoing interceptor
 * WHY NOT If there is other- app logic that requires the outout of this
 *
 * WARNING: The Option values of some questions are transformed as the request is received
 * @see {@link ./src/lib/api/middleware/response/fromMinutesToMilliseconds} - IF case 'get_diary_entry_form': AND endsWith('_total_time') AND
 *
 *
 * @param values from form
 * @param diaryDate date
 * @return {DiaryEntry} diaryDate Payload required
 */
const asDiaryEntry = ({
  values,
  diaryDate,
  formType,
  productId,
  programId,
  sceneSetId,
  sceneId,
}: {
  values: Record<string, ResponseOption[]>;
  diaryDate?: string;
  formType: FormTypes;
  productId?: number;
  programId?: number;
  sceneSetId?: number;
  sceneId?: number;
}): DiaryEntry => ({
  diary_date:
    formType !== 'create_from_weekly_average_estimate' &&
    formType !== 'create_baseline_entities' &&
    typeof diaryDate !== 'undefined' &&
    diaryDate !== null
      ? {
          $date: diaryDate,
        }
      : undefined,
  get_into_bed_time: tryGetTime(values, TIME_INTO_BED),
  try_to_sleep_time: tryGetTime(values, TIME_TRY_TO_SLEEP),
  time_to_fall_asleep_minutes: tryFromMillisecondsToMinutes(
    values,
    TO_FALL_ASLEEP_TOTAL_TIME
  ),
  number_of_awakenings: tryParseInt(values, AWAKENINGS_IN_NIGHT, '0'),
  total_awakenings_duration_minutes:
    // if there are no awakenings force time to be 0
    tryParseInt(values, AWAKENINGS_IN_NIGHT, '0') === 0
      ? 0
      : tryFromMillisecondsToMinutes(values, AWAKENINGS_TOTAL_TIME, 0),
  final_awakening_time: tryGetTime(values, TIME_FINAL_AWAKENING),
  get_out_of_bed_time: tryGetTime(values, TIME_GET_OUT_OF_BED),
  sleep_quality: tryParseInt(values, SLEEP_QUALITY),
  timezone: getTimezone(),
  product_id: productId,
  program_id: programId,
  scene_set_graph_id: sceneSetId,
  scene_set_id: sceneId,
});

export { asDiaryEntry };
