import React from 'react';

import { ProductReferences } from 'common/constants/enums';
import { MarkdownType } from 'components/Markdown/constants';
import { Markdown } from 'components/Markdown/Markdown';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { roles } from 'cross-platform/utils/roleProps';
import { ErrorDescription, ErrorDetails } from 'lib/error';
import { FallbackProps } from 'lib/error/ErrorBoundary/types';
import { isDevMode } from 'lib/isDevMode';

import { DaylightErrorFallback } from '../DaylightErrorFallback/DaylightErrorFallback';

import {
  Button,
  DevModeErrorText,
  MarkdownWrapper,
  PageTitle,
  Wrapper,
} from './styled';

const RECOVERY_TEXT = 'Try again';

const DEFAULTS: ErrorDescription = {
  heading: 'Whoops',
  message: `It seems something has gone wrong.
Need help? Please reach out to us at [hello@sleepio.com](mailto:hello@sleepio.com)
  `,
};

type Props = Partial<ErrorDetails & ErrorDescription> &
  Partial<FallbackProps> & { debugMessage?: string };

/**
 *
 * @param {Error | undefined} props.error Caught error
 * @param {{text: string, action: Function}[]} props.actions list of buttons
 * @param {string} heading
 * @returns
 */
const DefaultErrorFallback: React.ComponentType<Props> = (props: Props) => {
  const {
    heading,
    message,
    error = undefined,
    actions = [],
    onRecover,
    resetErrorBoundary,
    debugMessage = '',
  } = {
    ...DEFAULTS,
    ...props,
  };
  const debugMessageFormatted = debugMessage.length
    ? `${debugMessage}: `
    : debugMessage;
  const errorMessage =
    typeof error !== 'undefined'
      ? `${error}`
      : 'DefaultErrorFallback: No error';
  const devModeErrorText = `${debugMessageFormatted}${errorMessage}`;
  const { productReference } = useSafeParams<SceneSetParams>();

  switch (productReference) {
    case ProductReferences.DAYLIGHT:
      return <DaylightErrorFallback />;
    case ProductReferences.SLEEPIO:
    default:
      return (
        <Wrapper {...roles('DefaultErrorFallback')}>
          <PageTitle {...roles('heading')}>{heading}</PageTitle>
          <MarkdownWrapper>
            <Markdown type={MarkdownType.ERROR_BOUNDARY} {...roles('message')}>
              {message}
            </Markdown>
          </MarkdownWrapper>
          {isDevMode() ? (
            <DevModeErrorText {...roles('error')}>
              {devModeErrorText}
            </DevModeErrorText>
          ) : null}
          {actions?.map((action, index) => (
            <Button
              key={action.text + index}
              {...roles(`action--index:${index}`)}
              {...action}
            />
          ))}
          {onRecover ? (
            <Button
              {...roles('onRecoverButton')}
              text={RECOVERY_TEXT}
              onPress={() => {
                onRecover?.(error);
                resetErrorBoundary?.();
              }}
            />
          ) : null}
        </Wrapper>
      );
  }
};

export { DefaultErrorFallback };
