import { clone } from 'ramda';

import { DeepWritable, Question, ResponseOption } from '@bighealth/types/src';

type Options = {
  coerceValueToString?: boolean;
  clearValidationSchema?: boolean;
};

/**
 * Modify question props (in one configurable function)
 *
 * @param questionProps original
 * @param options type of transformations to make
 */
const cloneAndTransformQuestionProps = (
  questionProps: Question,
  options?: Options
): Question => {
  const clonedQuestionProps = clone(questionProps) as DeepWritable<Question>;
  if (options?.clearValidationSchema) {
    // Min max is bugged, but whole thing s not useful in Sleep Diary anyway
    clonedQuestionProps.response_config.validation_schema = {
      type: 'object',
      title: 'answers',
      $schema: 'http://json-schema.org/draft-07/schema#',
      properties: {
        selections: {
          type: 'array',
          maxItems: 1,
          minItems: 1,
        },
      },
      description: 'answer option values',
    };
  }
  if (options?.coerceValueToString) {
    // NOTE converting response_options[number].value to string for number Dropdown's
    // WHY currently ResponseInput dropdown only accept strings
    // WHY HERE based on principle of transform early or transform late (as in this case)
    clonedQuestionProps.response_config.response_options = clonedQuestionProps.response_config.response_options.map(
      (option: ResponseOption) => ({
        ...option,
        value: `${option.value}`,
      })
    );
  }
  return clonedQuestionProps as Question;
};

export { cloneAndTransformQuestionProps };
