// TODO create generic that takes "scaleYValue"
export interface ScaleValueProps {
  scaleValue: number;
}
export interface ScaleYValueProps {
  scaleYValue: number;
}

type ScaleOptions = {
  minScale?: number;
};

/**
 * Used to scale numbers for responsive styling
 *
 * Configure callback used to scale values in styled-components
 *
 * @param {number} value
 * @example
 * ```tsx
 * const Thing = styled.View`
 *  width: ${scaler(100)}px;
 *`;
 * const ScaledThing = () =>
 *   <Thing scaleValue={0.5} />;
 * ```
 */
export const scalerWithOptions = ({ minScale }: ScaleOptions) =>
  /**
   *
   */
  (value: number) =>
    /**
     * Used to scale numbers for responsive styling
     *
     * Callback used for styled-components
     *
     * @param {ScaleValueProps} props
     */
    (props: ScaleValueProps): number => {
      if (typeof props.scaleValue !== 'number' || isNaN(props.scaleValue)) {
        throw TypeError(`Expected scaleValue on props`);
      }
      const scale =
        typeof minScale === 'number'
          ? Math.max(props.scaleValue, minScale)
          : props.scaleValue;

      return value * scale;
    };

export const scaler = scalerWithOptions({});

/**
 *
 * @param {ScaleOptions} scaleOptions
 */
export const yScalerWithOptions = ({ minScale }: ScaleOptions) =>
  /**
   *
   */
  (value: number) =>
    /**
     * Used to scale vertical numbers for responsive styling
     *
     * Callback used for styled-components
     *
     * @param {ScaleYValueProps} props
     */
    (props: ScaleYValueProps): number => {
      if (typeof props.scaleYValue !== 'number' || isNaN(props.scaleYValue)) {
        throw TypeError(`Expected scaleYValue on props`);
      }
      const scale =
        typeof minScale === 'number'
          ? Math.max(props.scaleYValue, minScale)
          : props.scaleYValue;

      return value * scale;
    };

export const yScaler = yScalerWithOptions({});
