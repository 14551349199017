import {
  HOME_ROUTE_PARAMETER,
  SLEEP_DIARY_ROUTE_PARAMETER,
} from 'components/SleepDiaryWeek/constants';
import { DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID } from 'developer/constants';

import { STUB_ID } from './constants';
import { PathParams, useSafeParams } from './useSafeParams';

type TryReturnHardCodedParamsType = ReturnType<typeof useSafeParams>;

const visitsCache: string[] = [];
const isTestingEnvironment = process.env.NODE_ENV === 'test';

/**
 * This function checks if the route we are currently on requires some hard
 * coded params to be returned, rather than the dynamic one in the URL.
 *
 * It is not especially easy to see the broader context from just here, but the
 * use cases for this include:
 *  - When we present a question outside of a player context. For example in a
 *    storybook, or in the Sleep Diary out of player exeprience.
 *    In these cases, (Correct as of 2020-12-17), our internal state keys for
 *    these questions are built using params that are not always present in the
 *    route. (For example SceneSetId). We return hard-coded values to facilitate
 *    the creation of those keys.
 *
 * @param params the params pulled out by useSafeParams. Used to check against
 *  a small set of routes that require hard-coded params.
 */
const tryReturnHardCodedParams = (
  params?: PathParams
): TryReturnHardCodedParamsType | undefined => {
  if (!params) {
    // If params are null, return early.
    return;
  }
  const isStorybookSceneSetGraph =
    params.sceneSetGraphId === String(DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID);
  const isStorybookSceneSet = params.sceneSetId === 'storybook';
  const key = JSON.stringify(params);
  if (isStorybookSceneSetGraph && isStorybookSceneSet) {
    if (!visitsCache.includes(key) && !isTestingEnvironment) {
      visitsCache.push(key);
    }
    // cc:FEG_210_stub#1;set
    return {
      productReference: params.productReference,
      sceneSetGraphId: STUB_ID,
      sceneSetId: STUB_ID,
      sceneId: STUB_ID,
    };
  }

  const isNonPlayerSleepDiaryRoute =
    params?.sceneSetGraphId === SLEEP_DIARY_ROUTE_PARAMETER;

  const isNonPlayerHomeRoute = params?.sceneSetGraphId === HOME_ROUTE_PARAMETER;

  if (isNonPlayerSleepDiaryRoute || isNonPlayerHomeRoute) {
    if (!visitsCache.includes(key) && !isTestingEnvironment) {
      visitsCache.push(key);
    }
    // INFO: Formik caches using these values - so cache is shared
    // between /home and /sleep-diary.
    return {
      productReference: params.productReference,
      sceneSetGraphId: STUB_ID,
      sceneSetId: STUB_ID,
      sceneId: STUB_ID,
    };
  }
  return;
};

export { tryReturnHardCodedParams };
