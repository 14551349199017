import { Platform } from 'react-native';

import { CONTENT_MIN_REFERENCE_DIMENSION } from 'components/ResponsiveLayout';

import { ProductMark } from './types';

// NOTE: At the moment the resource page does not look good under this point in which I make it look like the native app portrait mocks
// If we decide to have a global mobile portrait content break point we can swap, this value later
export const SMALL_BREAK_POINT = 550;

export const getIsSmall = (screenWidth: number): boolean => {
  return screenWidth <= SMALL_BREAK_POINT;
};

// NOTE: This is tablet width or native landscape view.
// Uses platform to ensure that a native device doesn't get desktop styling
export const getIsMediumOrNative = (
  screenWidth: number,
  isSmall = false
): boolean => {
  return (
    (screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION || Platform.OS !== 'web') &&
    !isSmall
  );
};

export const parseProductMark = (
  productMark?: string
): ProductMark | undefined => {
  if (productMark === ProductMark.UKCAMark) {
    return ProductMark.UKCAMark;
  } else if (productMark === ProductMark.CEMark) {
    return ProductMark.CEMark;
  }

  return undefined;
};
