import { KeyboardTypeOptions } from 'react-native';

import { ResponseType, ResponseTypeType } from '@bighealth/types';

// IDEA throw instead of allowing number to return NaN?
export const coerceValueByInputType = (
  value: string,
  inputType: ResponseTypeType
): number | string => {
  switch (inputType) {
    case ResponseType.NUMBER:
      return Number(value.substr(0, 8));
    case ResponseType.TEXT:
    default:
      return String(value);
  }
};

type HTMLTextInputProps =
  | {
      type: 'text';
    }
  | {
      type: 'text';
      inputmode: 'numeric';
      pattern: '[0-9]*';
    };

export const getReactNativeKeyboardTypeFromInputType = (
  inputType: ResponseTypeType
): KeyboardTypeOptions => {
  switch (inputType) {
    case ResponseType.NUMBER:
      return 'number-pad';
    case ResponseType.TEXT:
    default:
      return 'default';
  }
};

export const getHTMLTextInputPropsFromInputType = (
  type: ResponseTypeType
): HTMLTextInputProps => {
  switch (type) {
    case ResponseType.NUMBER:
      return {
        type: 'text',
        inputmode: 'numeric',
        pattern: '[0-9]*',
      };
    case ResponseType.TEXT:
    default:
      return {
        type: 'text',
      };
  }
};
