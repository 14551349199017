import { View } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'components/Text';

export const HeaderWrapper = styled(View)<{ paddingTop: number }>`
  background-color: ${({ theme }) => theme.color.background.light};
  padding-top: ${({ paddingTop }) => paddingTop}px;
`;

export const HeaderContentWrapper = styled.View`
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const HeaderTitle = styled(Text)<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize ?? 24}px;
  color: ${props => props.theme.text.DEFAULT.color ?? 24};
`;

export const IconWrapper = styled.View<{ side: 'right' | 'left' }>`
  position: absolute;
  ${({ side }) => (side === 'right' ? 'right' : 'left')}: 28px;
`;
