import { getAsYouType, parsePhoneNumber } from 'awesome-phonenumber';

type PhoneTypes = {
  phoneVal: string;
  e164Val: string;
  regionCode: string;
  isValid: boolean;
};

/**
 * Parses a phone number to get proper formats and check validity. Will also
 * detect the region code if none is given.
 * @param {string} phoneInput - The raw phone number input string.
 * @param {string} [regionInput=''] - The region code. E.g. 'us' or 'gb'
 * @returns {object}
 */
export const parsePhone = (
  phoneInput: string,
  regionInput = ''
): PhoneTypes => {
  const phoneDigits = phoneInput.replace(/\D/g, '');

  if (phoneDigits.length === 0) {
    return {
      phoneVal: '',
      e164Val: '',
      regionCode: regionInput,
      isValid: false,
    };
  }

  const phoneE164 = '+' + phoneDigits;

  // Without regionInput we need to use international (e164) format so the region can be detected
  const phoneNumber = regionInput ? phoneDigits : phoneE164;
  const parsedNumber = parsePhoneNumber(phoneNumber, regionInput);

  // Region code will either be what was passed in or auto detected
  const regionCode = parsedNumber.getRegionCode()?.toUpperCase();
  const isValid = parsedNumber.isValid();

  let displayVal = phoneNumber;

  // We can only format the number if we know the region
  if (regionCode) {
    // Initialize the formatter
    const ayt = getAsYouType(regionCode);
    // Re-run the formatter with the new number
    ayt.reset(phoneNumber);

    // Get the formatted number and trim any extra spaces.
    // NOTE: The trim() is imporant! Otherwise a deleted trailing space will
    // keep getting re-added.
    displayVal = ayt.number().trim();
  }

  return {
    phoneVal: displayVal,
    e164Val: phoneE164,
    regionCode: regionCode,
    isValid: isValid,
  };
};

/**
 * A quick helper function to get a phone mask/placeholder for a given region.
 * @todo Refactor this to dynamically create the masks using the library to
 * lookup example number formats. This can be done if/when we use a country
 * selector. The phone number question type currently only has 'us' and 'gb' as
 * the default country values.
 * @param {string} [regionCode] - E.g. 'us' or 'gb'
 * @returns {string} - The phone mask or placeholder text
 */
export const phoneMask = (regionCode = ''): string => {
  switch (regionCode.toLowerCase()) {
    case 'gb':
      return '+44 xxx xxx xxxx';
    case 'us':
      return '+1 xxx-xxx-xxxx';
    default:
      return 'Phone number';
  }
};
