import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_settings } from '@bighealth/api/UserSleepioMetadata/v1';

import {
  matchesLogin,
  matchesLoginScreen,
  matchesTitleScreen,
} from 'components/Routes/matchesRoutes';
import { useHistory } from 'cross-platform/react-router';

import { useQueryProduct } from './useQueryProduct';

export type UseQuerySettingsReturnType = UseQueryResult<
  get_settings.Response,
  APIErrorResponse
>;

export const useQuerySettings = (): UseQuerySettingsReturnType => {
  const productId = useQueryProduct().data?.result?.id;
  const history = useHistory();

  const enabled =
    typeof productId === 'number' &&
    !matchesLogin(history) &&
    !matchesLoginScreen(history) &&
    !matchesTitleScreen(history);

  const result = useQuery<get_settings.Response, APIErrorResponse>({
    queryKey: get_settings.queryKey,
    queryFn: () =>
      get_settings({
        product_id: productId as number,
      }),
    enabled,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
    retry: false,
  });
  return result;
};
