import React, { useState } from 'react';

import { mockedMFAScenes } from '../../MFAMockedData';
import {
  MFADisableComponentOptions,
  MFASceneDisabledTypes,
  MFASceneOptionDisabledType,
  MFASceneOptionType,
} from '../../types';
import MFASectionWrapper, { MFASectionWrapperBase } from '../MFASectionWrapper';
import { isDaylight } from '../shared/crossPlatformGetProductTmp';
import MFAToggleView from '../shared/MFAToggleView';
import { isDisableFlow } from '../utils';

import MFAEnterDisableCode from './MFAEnterDisableCode';

type Props = Omit<MFASectionWrapperBase, 'onContinue'> & {
  toggleMFAFlag: () => void;
};

const initialMfaSceneKeyName: MFASceneOptionDisabledType = isDaylight()
  ? MFASceneDisabledTypes.ToggleView
  : MFASceneDisabledTypes.AuthenticationDisableEnterCode;

const MFADisabledFlow = (props: Props): React.ReactElement => {
  const { onRequestClose, toggleMFAFlag } = props;
  const DisabledFlow = mockedMFAScenes['disabled'];
  const [MFASceneKeyName, setMFASceneKeyName] = useState<
    MFASceneOptionDisabledType
  >(initialMfaSceneKeyName);

  const navigate = (
    navigateSection: MFASceneOptionDisabledType | MFASceneOptionType | undefined
  ) => {
    // Got tired of fighting with Typescript
    // Will fix before merge.
    if (navigateSection === undefined) {
      return;
    }
    if (!isDisableFlow(navigateSection)) {
      return;
    }

    setMFASceneKeyName(navigateSection);
  };

  // Get the content of the Screen for the Workflow
  const sceneContent = DisabledFlow[MFASceneKeyName];

  // Default is to render the MFASectionWrapper
  let component: React.ReactElement;

  /**
   * Conditionally update the "previous" node if not Daylight
   * IDEA: Remove this conditional
   * WHEN: Content will evetntually come from service
   */
  if (
    !isDaylight() &&
    MFASceneKeyName === MFASceneDisabledTypes.AuthenticationDisableEnterCode
  ) {
    sceneContent.previous = undefined;
  }

  // See if component is for Showing the MFA Code or Verify MFA
  // Logic for these are local to those components.
  switch (sceneContent.component) {
    case MFADisableComponentOptions.MFAToggle:
      component = (
        <MFAToggleView
          onRequestClose={onRequestClose}
          onContinue={navigate}
          isMFAEnabled={true}
          {...sceneContent}
        />
      );
      break;
    case MFADisableComponentOptions.MFAEnterDisableCode:
      component = (
        <MFAEnterDisableCode
          onRequestClose={onRequestClose}
          onContinue={navigate}
          {...sceneContent}
        />
      );
      break;
    default:
      component = (
        <MFASectionWrapper
          onRequestClose={onRequestClose}
          onContinue={navigate}
          content={sceneContent}
          toggleMFAFlag={toggleMFAFlag}
          screenIdentifier={`MFA_DisableFlow_${MFASceneKeyName}`}
        />
      );
      break;
  }
  return component;
};

export default MFADisabledFlow;
