import { AnalyticsField, ButtonResourceEvent } from '@bighealth/analytics';
import { fire_ui_events } from '@bighealth/api/Event/v1';

import { getAnalyticsDate } from './getAnalyticsDate';
import { getUserAgent } from './getUserAgent';
import { URLLike } from './useGetURL';

const EVENT_RESOURCE = 'button';
const EVENT_ACTION = 'clicked';

enum eventComponents {
  tab = 'tab',
  notification = 'notification',
}

export const sendButtonClickedAnalytics = async ({
  field,
  productId,
  programId,
  getUrl,
  productVersionStream,
}: {
  field: AnalyticsField;
  productId: number;
  programId: number;
  getUrl: () => Promise<URLLike>;
  productVersionStream?: string;
}): Promise<void> => {
  const [url, userAgent] = await Promise.all([getUrl(), getUserAgent()]).catch(
    error => {
      console.error('Attempted to send analytics but failed', {
        error,
        payload,
      });
      return [null, null];
    }
  );
  if (url === null || userAgent === null) {
    // error state, no need to proceed
    return;
  }
  const clientAppNodePackageVersion = require('../../../../package.json')
    .version;

  let eventComponent;
  switch (field?.area) {
    case 'notification':
      eventComponent = eventComponents.notification;
      break;
    default:
      eventComponent = eventComponents.tab;
      break;
  }
  const payload: ButtonResourceEvent = {
    occurred_at: getAnalyticsDate(),
    product_id: productId,
    program_id: programId,
    action: EVENT_ACTION, // BE validation that EVENT_ACTION is in name
    name: `${EVENT_RESOURCE}|${EVENT_ACTION}|${field?.area}`,
    resource: EVENT_RESOURCE,
    type: 'ui',
    body: {
      fields: {
        feature: field,
        route: url.pathname,
        component: eventComponent,
        component_state: 'active',
      },
      meta: {
        http: {
          current_url: url.href,
          referrer: url.href,
          user_agent: userAgent,
          client_app_node_package_version: clientAppNodePackageVersion,
          product_stream_version: productVersionStream,
        },
      },
      relations: {},
    },
  };

  try {
    // Awaiting, but not returning
    // this won't block the thread, but will enable us to do proper error containment
    await fire_ui_events({
      events: [payload],
    }).catch(error =>
      console.error('Attempted to send analytics but failed', {
        error,
        payload,
      })
    );
  } catch (error) {
    console.error('Attempted to send analytics but failed', { error, payload });
  }
};
