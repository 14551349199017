import { FieldHelperProps, FieldInputProps } from 'formik';
import { reject } from 'ramda';

import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';

import { InputFieldProps } from '../..';

type OnChangeCallback = (val?: Value) => void;

const createOnChange = (
  props: InputFieldProps,
  field: FieldInputProps<DropdownItem[]>,
  helpers: FieldHelperProps<DropdownItem[]>
): OnChangeCallback => {
  const items = props.items || [];
  const onChange = field.onChange(props.name);
  return (changeValue?: Value): void => {
    if (typeof onChange === 'function') {
      onChange(String(changeValue || ''));
    }
    if (props.fieldTypeProps.fieldType === 'TEXT') {
      // WARNING Field of type "TEXT" state is array-of-one: [{ value: string}]
      // WHY Backend API provides `{value: string}` per-option within a field
      // NEXT This text field is NOT mapped over. Jof is creating an input hat can be mapped over
      if (props?.items?.length !== 1) {
        // But only requires one value, so it is a list of one
        // where text value === field.value[0].value
        throw Error(
          `Text requires item of length 1, instead got ${props?.items}`
        );
      } else if (typeof props.value !== 'undefined') {
        if (props.value instanceof Array && props?.value?.length > 1) {
          throw Error(
            `Expected Text field props.value.length to be less than 1, instead got ${JSON.stringify(
              props.value
            )}`
          );
        }
      }
      const theItem = props?.value?.length ? props.value[0] : props.items[0];
      helpers.setValue([{ ...theItem, value: changeValue }]); // single value in array
    } else if (props.fieldTypeProps.fieldType === 'SELECTION') {
      const currentItems: DropdownItem[] = field.value || [];
      const isItemOfChangeValue = (el: DropdownItem): boolean =>
        el.value === changeValue;
      const changeItem = items.find(isItemOfChangeValue);
      if (typeof changeItem === 'undefined') {
        throw Error('Does not handle selecting non-items'); // IDEA make undefined "clear"
      } else if (currentItems.find(isItemOfChangeValue)) {
        helpers.setValue(reject(isItemOfChangeValue, currentItems));
      } else {
        helpers.setValue([...currentItems, changeItem]);
      }
    }
  };
};

export { createOnChange };
