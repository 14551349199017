import * as React from 'react';
import { Formik } from 'formik';

import {
  ScalingContext,
  ScalingContextProvider,
} from 'components/ResponsiveLayout';
import { noOpFunction } from 'lib/noOpFunction';

/**
 * Providers used by SessionsScreen components
 */
export const Providers = (props: {
  children: React.ReactElement;
}): React.ReactElement => {
  return (
    <Formik initialValues={{}} onSubmit={noOpFunction}>
      <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
        {props.children}
      </ScalingContextProvider>
    </Formik>
  );
};
