/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';

import { HttpStatusCode } from '@bighealth/api';

import {
  UseServiceMethodCall,
  useServiceMethodCall,
} from 'lib/api/hooks/useServiceMethodCall';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import {
  GENERAL_OTP_ERROR_MESSAGE,
  INVALID_OTP_ERROR_MESSAGE,
} from 'lib/strings';

import { ActionTypes } from '../useServiceMethodCall/ActionTypes';

export const useLoginWithOtp = () => {
  const productId = useQueryProduct()?.data?.result.id;
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loginWithOtpState, requestLoginWithOtp] = useServiceMethodCall({
    serviceName: 'GeneralLogin',
    serviceVersion: '1',
    serviceMethod: 'login_with_otp',
    args: {},
  }) as ReturnType<UseServiceMethodCall>;

  const handleError = (
    response: Readonly<{
      status_code: HttpStatusCode;
      result: any;
    }>
  ) => {
    setLoading(false);
    setError(
      response?.status_code === 401
        ? GENERAL_OTP_ERROR_MESSAGE
        : INVALID_OTP_ERROR_MESSAGE
    );
    setRedirect(false);
  };

  const login = useCallback(
    async (otp: string, email: string) => {
      return requestLoginWithOtp({
        email,
        otp,
        product_id: productId,
      });
    },
    [productId, requestLoginWithOtp]
  );

  useEffect(() => {
    switch (loginWithOtpState?.status) {
      case ActionTypes.FETCHING:
        setLoading(true);
        setRedirect(false);
        setError(undefined);
        break;
      case ActionTypes.SUCCESS:
        if (loginWithOtpState.response?.result?.status === 'error') {
          handleError(loginWithOtpState.response);
        } else {
          setLoading(false);
          setRedirect(true);
          setError(undefined);
        }

        break;
      case ActionTypes.ERROR: {
        const response = loginWithOtpState.response as Readonly<{
          status_code: HttpStatusCode;
          result: any;
        }>;
        handleError(response);
        break;
      }
      default:
        setLoading(false);
        setRedirect(false);
        setError(undefined);
        break;
    }
  }, [loginWithOtpState]);

  return {
    loading: loading,
    error: error,
    redirect: redirect,
    login,
  };
};
