export const MISSING_EMAIL = 'Please enter your email';
export const INVALID_EMAIL = 'Please enter a valid email';
export const MISSING_PASSWORD = 'Please enter a valid password';
export const LOGIN_ERROR = 'Incorrect email or password. Please try again.';

export const copy = {
  missingEmail: 'Please enter your email',
  invalidEmail: 'Please enter a valid email',
  updateTitle: 'Forgot Password',
  forgotPasswordBackLink: '< Back to Forgot Password',
  loginLinkTitle: 'Back to Login',
  loginBackLink: '< Back to Login',
  successHeader: 'Check your email',
  pageHeader: 'Forgot your password?',
  instructionsBegin: 'Please check your email for further details. Contact ',
  emailTo: 'mailto:hello@sleepio.com',
  emailAddress: 'hello@sleepio.com',
  instructionsEnd: ' with any questions.',
  instructions:
    "Forgotten your password? No problem. Just enter the email you signed up with below and we'll send you a link to reset your password.",
  emailPlaceholder: 'Type your email here',
  emailLabel: 'Email',
  signUpTitle: 'Sign up',
  actionButtonText: 'Submit',
};
