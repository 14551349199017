import styled from 'styled-components/native';

import { Text } from 'components/Text';

export const CardWrapper = styled.View`
  width: 100%;
  padding: 16px;
`;

export const InfoCardWrapper = styled.View`
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.primaryLight};
  align-self: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
`;

export const ImageView = styled.View`
  padding-top: 10px;
`;

export const TextView = styled.View`
  padding-top: 10px;
  padding-right: 24px;
  padding-left: 24px;
  width: 100%;
`;

export const CardText = styled(Text)<{
  lineHeight?: number;
  fontSize?: number;
  bold?: boolean;
}>`
  color: ${({ theme }) => theme.color.text.primary};
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px` : props.theme.text.DEFAULT.fontSize};
  font-weight: ${props =>
    props.bold ? '700' : props.theme.text.DEFAULT.fontWeight};

  line-height: ${props =>
    props.lineHeight
      ? `${props.lineHeight}px`
      : `${(props.theme.text.DEFAULT.fontSize ?? 0) + 5}px`};
`;
