import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { read } from '@bighealth/api/QuestionSet/v2/read';

export const useQueryQuestionSet = (
  entityId: number
): UseQueryResult<read.Response, APIErrorResponse> => {
  return useQuery({
    queryKey: read.queryKey,
    queryFn: () =>
      read({
        entity_id: entityId,
      }),
    enabled: typeof entityId === 'number' && entityId > 0,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });
};
