import styled from 'styled-components/native';

import { ScrollableContainer } from 'components/Containers';
import { Text } from 'components/Text';
import Image, { ResizeMode } from 'cross-platform/Image';

export const LimitWidth = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 60px 24px;
`;

export const LogoContainer = styled.View`
  flex-direction: column-reverse;
  height: 25%;
`;
export const Logo = styled(Image)``;

export const BackgroundImage = styled(Image).attrs({
  fit: ResizeMode.cover,
})`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const TextContainer = styled.View`
  flex-direction: column;
  height: 45%;
  max-width: 500px;
`;

// TODO: define the colors of the texts in the theme - find alignment with the Design team
export const FormattedText = styled(Text)`
  color: black;
  text-align: center;
  align-self: flex-start;
`;

export const ButtonContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding-horizontal: 30px;
  height: 25%;
`;

export const LoginSplashScreenScroll = styled(ScrollableContainer).attrs({
  contentContainerStyle: {
    flexGrow: 1,
    alignItems: 'center',
  },
})`
  width: 100%;
`;
