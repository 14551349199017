import React, { ReactElement, ReactNode, useContext } from 'react';
import { Animated, Linking, Role, View } from 'react-native';
import { AccessibilityRole } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import styled, { ThemeContext } from 'styled-components/native';

import { Footer } from 'components/Footer';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { usePulsingOpacityAnimation } from 'lib/animations';
import { openInAppBrowser } from 'lib/navigation/openInAppBrowser';
import { openURL } from 'lib/navigation/openURL';
import { getPlatform } from 'lib/platform';

import { ContentContainer, ScreenScroll } from '../ContentScreens';

import { useIsSettingsNarrow } from './hooks/useIsSettingsNarrow';

// Opens a URL with the InAppBrowser on native, otherwise opens in a new browser tab
const openBrowserLink = (url: string) => {
  const platform = getPlatform();
  if (platform === 'web' || platform === 'mobile-web') {
    Linking.openURL(url);
  } else {
    openInAppBrowser(url);
  }
};

// IDEA: create a screen-componenents.tsx file that is in common to all the Screens
// WHY: ContentScreens and SettingsScreen share some of the components.
// Now we are duplicating them (in this file).

// --- section with Components that are in common with other Screens
const ContentStatic = styled.View`
  background-color: #ddefff;
`;
ContentStatic.displayName = 'ContentStatic';

const ContainerView = styled.ScrollView`
  flex: 1;
`;
ContainerView.displayName = 'ContainerView';

export const Content = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <ContentStatic
      style={{
        paddingLeft: styles.contentHorizontalPadding,
        paddingRight: styles.contentHorizontalPadding,
        paddingTop: styles.contentVerticalPadding,
        paddingBottom: 0,
        borderRadius: styles.contentBorderRadius,
      }}
    >
      {children}
    </ContentStatic>
  );
};

export const ScrollColumn = styled.ScrollView``;
ScrollColumn.displayName = 'ScrollColumn';

export const ContentColumnStatic = styled.View``;
ContentColumnStatic.displayName = 'ContentColumnStatic';

export const PageTitle = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const theme = useContext(ThemeContext);
  return (
    <View
      style={{
        marginBottom: styles.pageTitlePaddingBottom,
        marginTop: styles.pageTitlePaddingTop,
      }}
    >
      <Text
        style={{
          fontSize: styles.pageTitleFontSize,
          color: theme.color.overlay.primary,
        }}
        accessibilityRole="header"
        role="heading"
      >
        {children}
      </Text>
    </View>
  );
};

const SkeletonOuterContainerStatic = styled(Animated.View)`
  background-color: #ddefff;
  min-height: 200px;
`;

export const SkeletonOuterContainerAnimated = (): ReactElement => {
  const fadeAnimation = usePulsingOpacityAnimation();
  const styles = useGetDynamicContentStyles();
  return (
    <SkeletonOuterContainerStatic
      {...roles('SkeletonOuterContainerAnimated')}
      style={{
        opacity: fadeAnimation,
        paddingLeft: styles.contentHorizontalPadding,
        paddingRight: styles.contentHorizontalPadding,
        paddingTop: styles.contentVerticalPadding,
        bottom: styles.contentVerticalPadding,
        borderRadius: styles.contentBorderRadius,
        marginBottom: styles.contentMarginBottom,
      }}
    />
  );
};

const EntryStatic = styled.View`
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
`;

export const Entry = ({ children }: { children: ReactNode }): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <EntryStatic
      style={{
        paddingLeft: styles.settingsEntryLeftPadding,
        paddingRight: styles.settingsEntryRightPadding,
        paddingTop: styles.settingsEntryTopPadding,
        paddingBottom: styles.settingsEntryBottomPadding,
        borderRadius: styles.settingsEntryBorderRadius,
        marginBottom: styles.settingsEntryMarginBottom,
      }}
    >
      {children}
    </EntryStatic>
  );
};

// --- section with ad-hoc SettingsScreen components

export const Container = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const safeArea = useSafeArea();
  const styles = useGetDynamicContentStyles();
  const isSettingsNarrow = useIsSettingsNarrow();

  let paddingLeft = styles.settingsPaddingLeftNarrow;
  if (!isSettingsNarrow) {
    paddingLeft = styles.settingsPaddingLeft;
  }
  return (
    <ScreenScroll>
      <ContentContainer
        style={{
          flex: 1,
          width: '100%',
          backgroundColor: 'white',
          paddingBottom: styles.pagePaddingBottom,
          paddingLeft: paddingLeft + safeArea.left,
          paddingRight: styles.settingsPaddingLeftNarrow,
        }}
      >
        {children}
      </ContentContainer>
      <Footer />
    </ScreenScroll>
  );
};

export const ContentColumn = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <ContentColumnStatic
      style={{
        maxWidth: styles.settingsMaxWidth,
      }}
    >
      {children}
    </ContentColumnStatic>
  );
};

export const SettingsEntry = ({
  heading,
  subheading,
  children,
}: {
  heading: string;
  subheading?: string;
  children?: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const theme = useContext(ThemeContext);
  return (
    <Entry>
      <Text
        role="heading"
        aria-level={2}
        style={{
          fontSize: styles.settingsEntryHeadingFontSize,
          paddingBottom: styles.settingsEntrySubHeadingBottomPadding,
          color: theme.color.overlay.primary,
        }}
      >
        {heading}
      </Text>
      {typeof subheading !== 'undefined' ? (
        <Text
          style={{
            fontSize: styles.settingsEntryTextFontSize,
            paddingBottom: styles.settingsEntryTextPadding,
            paddingTop: styles.settingsEntrySubHeadingTopPadding,
          }}
        >
          {subheading}
        </Text>
      ) : null}
      {children}
    </Entry>
  );
};

export const UserMessageText = styled.View`
  flex-direction: row;
`;
UserMessageText.displayName = 'UserMessageText';

export const EntryText = ({
  text,
  titleEmailAnchor,
  titleLinkAnchor,
  id,
  role,
  accessibilityRole,
}: {
  text: string;
  titleEmailAnchor?: string;
  titleLinkAnchor?: string;
  id?: string;
  role?: Role;
  accessibilityRole?: AccessibilityRole;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  if (typeof titleLinkAnchor !== 'undefined') {
    return (
      <Text
        style={{
          fontSize: styles.settingsEntryTextFontSize,
          color: '#1a80a2',
        }}
        onPress={() => openBrowserLink(titleLinkAnchor)}
        role={role || 'link'}
        accessibilityRole={accessibilityRole || 'link'}
        id={id}
      >
        {text}
      </Text>
    );
  }

  return typeof titleEmailAnchor === 'undefined' ? (
    <Text
      style={{
        fontSize: styles.settingsEntryTextFontSize,
        color: '#282828',
      }}
      role={role}
      accessibilityRole={accessibilityRole}
    >
      {text}
    </Text>
  ) : (
    <Text
      style={{
        fontSize: styles.settingsEntryTextFontSize,
        color: '#1a80a2',
      }}
      role={role || 'link'}
      accessibilityRole={accessibilityRole || 'link'}
      href="mailto:hello@sleepio.com"
      onPress={() => openURL('mailto:hello@sleepio.com')}
    >
      {text}
    </Text>
  );
};
