import { useCallback, useEffect, useMemo, useState } from 'react';

import { get_settings } from '@bighealth/api/UserSleepioMetadata/v1';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';
import {
  queryClientFitBitConnect,
  queryClientFitBitDisconnect,
  useQueryFitBitClientId,
  useQueryProduct,
  useQuerySettings,
} from 'lib/api/reactQueryHelpers';
import { isDevMode } from 'lib/isDevMode';
import * as reporter from 'lib/reporter';
import { useQuery } from 'lib/router/useQuery';

import {
  closeAuthorization,
  getFitbitCallbackUri,
  openAuthorizationPage,
  withConfirm,
} from '../../helpers';
import { FitbitUserMessageInfo } from '../../types';

import { FitbitApi } from './types';

export const useFitbitApi = (redirectScreen: string): FitbitApi => {
  const query = useQuery();
  const history = useHistory();
  const queryClient = getQueryClient();
  const { productReference } = useSafeParams();
  const settingsResponse = useQuerySettings();
  const productResponse = useQueryProduct();
  const { data: clientIdData, error: clientIdError } = useQueryFitBitClientId();

  const [handlingRedirect, setHandlingRedirect] = useState(false);
  const [isFitbitConnected, setIsFitbitConnected] = useState(false);
  const [messageInfo, setMessageInfo] = useState<null | FitbitUserMessageInfo>(
    null
  );

  // updates the local state using react-query state
  useEffect(() => {
    const newFitbitStatus =
      settingsResponse.data?.result?.is_fitbit_connected || false;
    if (newFitbitStatus !== isFitbitConnected) {
      setIsFitbitConnected(newFitbitStatus);
    }
  }, [isFitbitConnected, settingsResponse.data]);

  const reportError = useCallback((reason?: string) => {
    setMessageInfo({
      type: 'error',
      message:
        isDevMode() && typeof reason === 'string'
          ? `Something went wrong (debug: ${reason})`
          : 'Something went wrong',
    });
  }, []);

  // updates local react-query state
  const updateFitbitSetting = useCallback(
    (isConnected: boolean) => {
      queryClient.setQueryData(get_settings.queryKey, {
        ...settingsResponse.data,
        result: {
          ...settingsResponse.data?.result,
          is_fitbit_connected: isConnected,
        },
      });
    },
    [queryClient, settingsResponse.data]
  );

  const submitFitbitCode = useCallback(
    async (code: string, productId: number) => {
      try {
        await queryClientFitBitConnect({
          auth_code: code,
          product_id: productId,
          redirect_uri: getFitbitCallbackUri(productReference, redirectScreen),
        });
        updateFitbitSetting(true);
        setMessageInfo({
          type: 'success',
          message: 'Your Fitbit has been connected',
        });
        setHandlingRedirect(false);
      } catch (e) {
        reportError(e.message);
        reporter.log('Unable to connect Fitbit', e);
      }
    },
    [productReference, redirectScreen, reportError, updateFitbitSetting]
  );

  // handles Fitbit redirect
  useEffect(() => {
    const code = query.get('code');
    const error = query.get('error');
    const device = query.get('device');
    const errorDescription = query.get('error_description');

    // Fitbit auth failure
    if (device === 'fitbit' && typeof error === 'string') {
      setHandlingRedirect(true);
      history.replace({ pathname: `/${productReference}/${redirectScreen}` });
      reportError(`${error}: ${errorDescription}`);
      reporter.log(
        `Unable to connect Fitbit`,
        Error(`${error}: ${errorDescription}`),
        { silent: true }
      );
      setHandlingRedirect(false);
      return;
    }

    // Fitbit auth success
    if (device === 'fitbit' && typeof code === 'string') {
      setHandlingRedirect(true);
      // if the application failed to fetch the product cleanup url
      if (productResponse.status === 'error') {
        history.replace({ pathname: `/${productReference}/${redirectScreen}` });
        reportError(productResponse.error.result.user_message);
        setHandlingRedirect(false);
      } else if (settingsResponse.status === 'error') {
        history.replace({ pathname: `/${productReference}/${redirectScreen}` });
        reportError('Failed to fetch user settings');
        setHandlingRedirect(false);
      } else if (
        productResponse.status === 'success' &&
        settingsResponse.status === 'success'
      ) {
        history.replace({ pathname: `/${productReference}/${redirectScreen}` });
        submitFitbitCode(code, productResponse.data.result.id);
      }
    }
  }, [
    query,
    history,
    reportError,
    redirectScreen,
    productResponse,
    productReference,
    submitFitbitCode,
    settingsResponse.status,
    history.location.pathname,
  ]);

  const connect = useCallback(async () => {
    setMessageInfo(null);
    if (typeof clientIdData?.result === 'undefined') {
      reportError(clientIdError?.result.user_message);
      return;
    }
    try {
      await openAuthorizationPage(
        productReference,
        redirectScreen,
        clientIdData.result
      );
    } catch (err) {
      reporter.log(`${err}`, err, { silent: true });
      reportError(`${err}`);
    }
  }, [
    clientIdData,
    clientIdError,
    productReference,
    redirectScreen,
    reportError,
  ]);

  const disconnect = useCallback(async () => {
    setMessageInfo(null);
    const confirmedDisconnect = async () => {
      await closeAuthorization();
      if (productResponse.status !== 'success') {
        reportError('Product request error');
        return;
      }
      try {
        const response = await queryClientFitBitDisconnect({
          product_id: productResponse.data.result.id,
        });
        // result is a boolean
        if (response.result) {
          updateFitbitSetting(false);
          setMessageInfo({
            type: 'success',
            message: 'Your Fitbit has been disconnected',
          });
        } else {
          // this should never happen because backend never returns false
          const error = new Error('Fitbit disconnect endpoint returned false');
          reporter.log('Fitbit disconnect error', error, { silent: true });
          reportError(error.message);
        }
      } catch (e) {
        reporter.log('Fitbit disconnect error', e, { silent: true });
        reportError(e.message);
      }
    };
    withConfirm(
      'Fitbit Sync',
      'Are you sure you want to disconnect your Fitbit?',
      confirmedDisconnect
    );
  }, [productResponse, reportError, updateFitbitSetting]);

  const reset = useCallback(() => {
    setMessageInfo(null);
  }, []);

  return useMemo(
    () => ({
      reset,
      connect,
      disconnect,
      messageInfo,
      isFitbitConnected,
      settingsFetched: !handlingRedirect && settingsResponse.isSuccess,
    }),
    [
      reset,
      connect,
      disconnect,
      messageInfo,
      handlingRedirect,
      isFitbitConnected,
      settingsResponse.isSuccess,
    ]
  );
};
