import React, { ReactElement, ReactNode } from 'react';
import { StyleSheet, ViewProps as ViewStyleRN, ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { RoleProps } from 'cross-platform/utils/roleProps';

import { dismissBoundaryOffset } from './dismissBoundaryOffset';
import { MenuDismisser } from './MenuDismisser';
import { MenuPortal } from './MenuPortal';
import {
  ClickPosition,
  useWindowAwarePosition,
} from './useWindowAwarePosition';

const MenuContainerInner = styled.View`
  background-color: #002141;
  border-radius: 8px;
  padding: 20px;
  min-width: 200px;
`;
MenuContainerInner.displayName = `WindowAwareMenu.MenuContainerInner`;

type ViewProps = {
  style?: ViewStyle;
  children?: ReactNode;
  onLayout: ViewStyleRN['onLayout'];
} & RoleProps;

export const MenuContainer = (props: ViewProps): ReactElement => {
  const safeArea = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = {
    marginTop: dismissBoundaryOffset + safeArea.top,
    ...StyleSheet.flatten(style),
  };
  return <MenuContainerInner style={newStyle} {...restProps} />;
};

const DismissBoundary = styled.View``;

export const WindowAwareMenu = ({
  children,
  targetPosition,
  onDismiss,
}: {
  onDismiss: () => void;
  children: ReactNode;
  targetPosition: ClickPosition;
}): ReactElement => {
  const { handleLayout, top, left, isReady } = useWindowAwarePosition(
    targetPosition
  );
  return (
    <MenuPortal>
      <MenuDismisser onDismiss={onDismiss} />
      <DismissBoundary
        testID={'WindowAwareMenuBoundary'}
        style={{
          left,
          top: top - dismissBoundaryOffset,
          opacity: isReady ? 1 : 0,
        }}
      >
        <MenuContainer testID={'WindowAwareMenu'} onLayout={handleLayout}>
          {children}
        </MenuContainer>
      </DismissBoundary>
    </MenuPortal>
  );
};
