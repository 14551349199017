import React from 'react';

import { isMobileWeb } from 'config/isMobileWeb';
import { useQueryIsSessionAvailableOnMobileWeb } from 'lib/api/reactQueryHelpers';

import { MobileWebExperience } from './components/MobileWebExperience';
import { useCheckParamRedirectHomeIfMobileWeb } from './hooks/useCheckParamRedirectHomeIfMobileWeb';
import { MobileWebGateComponent } from './types';

export const MobileWebGateContent: MobileWebGateComponent = props => {
  useCheckParamRedirectHomeIfMobileWeb();
  const response = useQueryIsSessionAvailableOnMobileWeb();

  if (!isMobileWeb) {
    return props?.children;
  } else if (response.status === 'success') {
    return response.data.result ? props?.children : <MobileWebExperience />;
  }

  return null;
};
