import React, { memo, ReactElement } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { DarkGrayBox } from 'daylight/components/ghosts/ghosts';

import { CardGhost } from './CardGhost';

const Wrapper = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.color.deselectedGrey};
  padding: 0px 16px;
`;
Wrapper.displayName = 'PracticeLibraryScreenGhostWrapper';

const Ghost = memo(
  (): ReactElement => {
    const transformV = useTransformContentStyleVertical();
    return (
      <Wrapper {...roles('practiceLibraryGhost')}>
        <View style={{ width: '100%', alignItems: 'center' }}>
          <DarkGrayBox
            height={transformV(24)}
            width="60%"
            style={{ marginTop: transformV(23), marginBottom: transformV(23) }}
          />
        </View>
        <CardGhost />
        <CardGhost />
        <CardGhost />
      </Wrapper>
    );
  }
);

Ghost.displayName = 'PracticeLibraryGhost';

export { Ghost };
