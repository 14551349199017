import React from 'react';
import { ReactElement, ReactNode } from 'react';
import { View } from 'react-native';

export const ExternalLinkContainer = ({
  children,
  link,
  download,
}: {
  children: ReactNode;
  link: string;
  download: string;
}): ReactElement => {
  return (
    <View>
      <a href={link} download={download}>
        {children}
      </a>
    </View>
  );
};
