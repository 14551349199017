import React, { ReactElement } from 'react';
import styled from 'styled-components/native';

import { useScaleToScalingContext } from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { getTheme } from 'lib/styles/getTheme';

const Paragraph = styled.View``;

const ParagraphText = styled(Text)`
  color: ${getTheme('overlay.color')};
`;

const OverlayParagraph = ({ text }: { text: string }): ReactElement => {
  // @TODO: `useScaleToScalingContext` is deprecated. When you next edit this component, switch to `useTransformStylesToContext` instead
  const scaleToContext = useScaleToScalingContext();
  return (
    <Paragraph
      style={{
        marginBottom: scaleToContext(35), // This component won't exist in future so no point defining this value as a constant to import
        width: scaleToContext(275),
        backgroundColor: 'lightgray',
      }}
    >
      <ParagraphText
        testID={`OverlayParagraphText-${text}`}
        style={{ fontSize: scaleToContext(18) }}
      >
        {text}
      </ParagraphText>
    </Paragraph>
  );
};

export default OverlayParagraph;
