import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SceneActionTypes } from '@bighealth/types/dist/enums';
import { SceneSet } from '@bighealth/types/src';
import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';
import getPathForParams from 'lib/player/getPathForParams';
import { incrementSceneSetRenderKey } from 'state/player/actions';

export type UseNavigateToJumpedSceneSetCallback = (props: {
  targetSceneSetId?: number;
  currentSceneSet: SceneSet;
}) => void;

const JumpAction = SceneActionTypes.JUMP_TO_SCENESET_BY_ID;
const RepeatAction = SceneActionTypes.REPEAT_CURRENT_SCENESET;

/**
 * A hook that roles up some of the calculations necessary to determine which
 * Scene (potentially within the current or a new SceneSet) the browser
 * should navigate to.
 *
 * Note: most of the tests for this are in useActionHandler.test.tsx
 */
export const useNavigateToJumpedSceneSet = (
  action: SceneAction | undefined
): UseNavigateToJumpedSceneSetCallback => {
  const history = useHistory();
  const { productReference, sceneSetGraphId, sceneSetId } = useSafeParams<
    SceneSetParams
  >();
  const dispatch = useDispatch();
  return useCallback(
    ({ targetSceneSetId, currentSceneSet }) => {
      if (!(action?.type === JumpAction || action?.type === RepeatAction)) {
        console.warn(
          `useNavigateToJumpedSceneSet may only be called within the '${JumpAction}' or '${RepeatAction}' actions.
  Instead it was called within '${action?.type}' which is a no op.`
        );
        return;
      }
      const currentSceneSetHasMultipleScenes =
        currentSceneSet.childNodes.length > 1;
      const isSameSceneSet = currentSceneSet.id === targetSceneSetId;
      if (isSameSceneSet) {
        if (currentSceneSetHasMultipleScenes) {
          const path = getPathForParams({
            productReference,
            sceneSetGraphId,
            sceneSetId,
            sceneId: 0,
          });
          history.push(path);
        } else {
          // NOOP - we stay where we are
        }
        dispatch(incrementSceneSetRenderKey());
      } else {
        // The user is navigating to a new SceneSet
        const path = getPathForParams({
          productReference,
          sceneSetGraphId,
          sceneSetId: targetSceneSetId,
          sceneId: 0,
        });
        history.push(path);
      }
    },
    [action, dispatch, history, productReference, sceneSetGraphId, sceneSetId]
  );
};
