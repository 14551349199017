export const getRedirectQuery = (
  pathname: string,
  productReference: string
): string => {
  let path = '';
  for (const param of pathname.split('/')) {
    if (param !== productReference && param.length > 0) {
      path += `/${param}`;
    }
  }
  return path.length > 0 ? `?redirect=${path}` : '';
};
