import React, { ReactElement } from 'react';

import { Footer } from 'components/Footer';
import { Container } from 'components/Screens/ContentScreens/components/ContentPlaceholder';
import { VerticalScroll } from 'components/VerticalScroll/VerticalScroll';
import { DefaultErrorFallback } from 'lib/error/ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';

export const SleepNow = (): ReactElement => {
  // SleepNow feature doesn't exist on web
  return (
    <VerticalScroll>
      <Container>
        <DefaultErrorFallback error={Error('404 - Path no found')} />
      </Container>
      <Footer />
    </VerticalScroll>
  );
};
