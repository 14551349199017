/* istanbul ignore file */ /* No point testing a env-var based config accessor */

import { PLATGEN_FF_USE_DEBUG_MODE } from 'config/envVars/debug';

const isDevMode = (): boolean => {
  return (
    window.location?.host?.startsWith('localhost') || PLATGEN_FF_USE_DEBUG_MODE
  );
};

export { isDevMode };
