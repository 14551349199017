import React from 'react';
import { Switch, TouchableOpacity } from 'react-native';

import { getThemeForProduct } from 'config/getThemeForProducts';
import { noOpFunction } from 'lib/noOpFunction';
import { getProductName } from 'lib/productInfo/getProductName';

import { MFAEnableComponentOptions, ScreenContextToggle } from '../../types';
import { MFASectionWrapperBase } from '../MFASectionWrapper';
import MFASectionWrapper from '../MFASectionWrapper';

import Styled from './MFAToggleView.styled';
import { mfaToggleView } from './textContants';

export type MFAToggleViewProps = ScreenContextToggle &
  MFASectionWrapperBase & {
    isMFAEnabled: boolean;
  };

export const MFAToggleView = (
  props: MFAToggleViewProps
): React.ReactElement => {
  const {
    onContinue,
    onRequestClose,
    continue_button,
    inputLabel,
    isMFAEnabled,
    ...rest
  } = props;

  const productTheme = getThemeForProduct(getProductName());

  // Fallback as text.placeholder is optional for themes.
  const placeHolderTextColor = productTheme.color.text.placeholder
    ? productTheme.color.text.placeholder
    : productTheme.color.primaryGrey;

  return (
    <MFASectionWrapper
      content={rest as ScreenContextToggle}
      onRequestClose={onRequestClose}
      onContinue={onContinue}
      screenIdentifier={`MFA_${isMFAEnabled ? 'Disable' : 'Enable'}Flow_${
        MFAEnableComponentOptions.MFAToggle
      }`}
    >
      {continue_button?.next ? (
        <TouchableOpacity
          testID={'MFA_Toggle_TouchableTitleAndSwitch'}
          onPress={() => onContinue(continue_button?.next)}
          activeOpacity={1}
          accessible
          accessibilityRole="button"
          accessibilityValue={{
            text: mfaToggleView.MFA_IS_ENABLED_DISABLED(isMFAEnabled),
          }}
          accessibilityLabel={mfaToggleView.TOGGLE_MFA}
          accessibilityHint={mfaToggleView.PRESS_TO_ENABLE_DISABLE_MFA(
            isMFAEnabled
          )}
        >
          <Styled.SwitchToggleView>
            <Styled.SettingsContentText>
              {continue_button.text}
            </Styled.SettingsContentText>
            <Switch
              accessible={false}
              testID="MFA_Toggle_NonInteractiveSwitch"
              onValueChange={noOpFunction}
              value={isMFAEnabled}
              disabled={false}
            />
            <Styled.CoverSwitchToggleInteractiveArea />
          </Styled.SwitchToggleView>
        </TouchableOpacity>
      ) : null}
      <Styled.SwitchToggleHelperText
        placeHolderTextColor={placeHolderTextColor}
      >
        {inputLabel}
      </Styled.SwitchToggleHelperText>
    </MFASectionWrapper>
  );
};

export default MFAToggleView;
