import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { Program } from '@bighealth/types';
import { AxiosRequestConfig } from 'axios';

export const get_assigned_program_with_user_and_product = async (
  args: get_assigned_program_with_user_and_product.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_assigned_program_with_user_and_product.Response> => {
  const request: get_assigned_program_with_user_and_product.Request = {
    service_name: 'UserAccountAuthorization',
    service_version: '1',
    service_method: 'get_assigned_program_with_user_and_product',
    args,
  };

  return await api(request, requestConfig);
};

get_assigned_program_with_user_and_product.queryKey =
  'UserAccountAuthorization/get_assigned_program_with_user_and_product';

export declare namespace get_assigned_program_with_user_and_product {
  export type Args = {
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserAccountAuthorization';
    service_version: '1';
    service_method: 'get_assigned_program_with_user_and_product';
    args: Args;
  }

  export type Result = Program;

  export type Response = APIResponse<Result>;
}
