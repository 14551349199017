const createDateAtDayEnd = (date: Date): Date => {
  const newDate = new Date(date.getTime());
  newDate.setUTCHours(23);
  newDate.setUTCMinutes(59);
  newDate.setUTCSeconds(59);
  newDate.setUTCMilliseconds(999);
  return newDate;
};

export { createDateAtDayEnd };
