import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { IconStyles } from 'components/icons/types';

/**
 * Today's icon is a sun
 * @param size: the size of the square where the icon lives
 * @param style: the IconStyle of the icon
 */
export const ProgressIcon = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => (
  <Svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 40 40"
    style={style}
  >
    <G fill="none" fillRule="evenodd">
      <G>
        <Path d="M0 0H40V40H0z" />
        <G
          stroke={style?.color || 'currentColor'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        >
          <G>
            <Path
              d="M.5 21L.5 11M7.5 21L7.5 7M14.5 21L14.5 3M21.5 21L21.5 0"
              transform="translate(9 9)"
            />
          </G>
        </G>
      </G>
    </G>
  </Svg>
);
