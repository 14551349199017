import React from 'react';

export const useStateApi = <T,>(
  initialState: T | (() => T)
): {
  value: T;
  set: React.Dispatch<React.SetStateAction<T>>;
} => {
  const [value, set] = React.useState<T>(initialState);
  return { value, set };
};
