import React, { FC, ReactElement } from 'react';

import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import {
  matchesSceneSet,
  matchesSceneSetByPathname,
} from 'components/Routes/matchesRoutes';
import { PreviousHistoryState } from 'components/Routes/types';
import { ThemeMode } from 'config/getThemeForProducts/utils/createTheme/themeMode';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';

import { useIsFetchingCounter } from './useIsFetchingCounter';

const DaylightThrobber: FC = (): ReactElement | null => {
  const history = useHistory<PreviousHistoryState>();
  const previousHistoryState: PreviousHistoryState | undefined =
    history.location.state;
  const {
    countIsFetchingWithThrobber,
    countIsFetchingWithoutSpinningThrobber,
  } = useIsFetchingCounter();

  // If no queries are pending, then do not show Daylight throbber
  if (countIsFetchingWithThrobber <= 0) {
    return null;
  }

  const thisIsAContentScreen = matchesSceneSet(history);
  const previousWasAContentScreen =
    typeof previousHistoryState?.pathname === 'string' &&
    matchesSceneSetByPathname(previousHistoryState?.pathname);

  // Should only be light if we are looking at content and are coming from content
  // If any non-content screens are involved, keep it dark
  const overlayMode = thisIsAContentScreen
    ? previousWasAContentScreen
      ? ThemeMode.Light
      : ThemeMode.Dark
    : ThemeMode.Dark;

  // To show if it is not one of the query keys that does not want a spinning throbber
  const showSpinningThrobber = countIsFetchingWithoutSpinningThrobber <= 0;

  const showBackground = (): boolean => {
    // Do not show background when there is no spinning throbber
    if (!showSpinningThrobber) {
      return false;
    }

    // When we are going from a daylight screen into content, we do not want to see the
    // semi-transparent black background
    return previousWasAContentScreen || !thisIsAContentScreen;
  };

  return (
    <LoadingOverlay
      {...roles('daylightRootThrobber')}
      showSpinningThrobber={showSpinningThrobber}
      mode={overlayMode}
      showBackground={showBackground()}
    />
  );
};

export { DaylightThrobber };
