import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const is_session_available_on_mobile_web = async (
  args: is_session_available_on_mobile_web.Args,
  requestConfig?: AxiosRequestConfig
): Promise<is_session_available_on_mobile_web.Response> => {
  const request: is_session_available_on_mobile_web.Request = {
    service_name: 'SessionProgress',
    service_version: '1',
    service_method: 'is_session_available_on_mobile_web',
    args,
  };

  return await api(request, requestConfig);
};

is_session_available_on_mobile_web.queryKey =
  'SessionProgress/is_session_available_on_mobile_web';

export declare namespace is_session_available_on_mobile_web {
  export type Args = {
    graph_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SessionProgress';
    service_version: '1';
    service_method: 'is_session_available_on_mobile_web';
    args: Args;
  }

  export type Result = boolean;

  export type Response = APIResponse<Result>;
}
