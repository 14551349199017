import { subDays } from 'date-fns';

import { HOUR, MINUTE } from 'lib/durations';

export const getDiaryDate = (
  minDate: number,
  diaryDate?: Date
): undefined | Date => {
  if (typeof diaryDate === 'undefined') {
    return undefined;
  }

  const timezone = new Date(0).getTimezoneOffset();
  const hoursInUTC = Math.floor((minDate - timezone * MINUTE) / HOUR);

  // the sleep diary starts the day before
  // if the minimum value is more or equal 24 hours from 1970 1st January
  // then we don't need to shift the day
  if (hoursInUTC < 24 && typeof diaryDate !== 'undefined') {
    diaryDate = subDays(diaryDate, 1);
  }

  return diaryDate;
};
