import React, { ReactElement, useState } from 'react';

import { useHistory } from 'cross-platform/react-router';
import {
  SettingsIconType,
  WithGlobalNavigation,
  WithSettings,
} from 'daylight/components/higherOrderComponents';

import { TodayScreen } from './TodayScreen';

export const TodayScreenWithSettings = (): ReactElement => {
  const history = useHistory<{
    openSettingsNav: boolean;
  }>();

  const [settingsIcon, setSettingsIcon] = useState<
    SettingsIconType | undefined
  >(undefined);
  return (
    <WithSettings
      settingsIcon={settingsIcon}
      openSettingsNav={history.location.state?.openSettingsNav}
    >
      <WithGlobalNavigation>
        {navState => (
          <TodayScreen
            settingsIcon={settingsIcon}
            setSettingsIcon={setSettingsIcon}
            {...navState}
          />
        )}
      </WithGlobalNavigation>
    </WithSettings>
  );
};
