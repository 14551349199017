import React, { ReactElement } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

const StyledScreenScroll = styled.ScrollView.attrs(props => ({
  contentContainerStyle: StyleSheet.flatten([
    {
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    props.contentContainerStyle,
  ]),
}))`
  width: 100%;
  background-color: white;
`;
StyledScreenScroll.displayName = 'StyledScreenScroll';

type ScrollViewPropsType = React.ComponentProps<typeof StyledScreenScroll> & {
  contentContainerStyle?: ViewStyle;
};

export const ScreenScrollBase: React.FC<ScrollViewPropsType> = (
  props: ScrollViewPropsType
): ReactElement => {
  const safeArea = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = {
    paddingTop: safeArea.top,
    ...StyleSheet.flatten(style),
  };
  return (
    <StyledScreenScroll
      style={newStyle}
      {...restProps}
      showsVerticalScrollIndicator={props.showsVerticalScrollIndicator}
    />
  );
};
