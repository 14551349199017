import { TextStyle } from 'react-native';

export type TextAreaStyles = {
  outlineColor?: string;
  outlineWidth?: number;
  outlineBorderRadius?: number;
  placeholderColor?: string;
  placeholderFontStyle?: 'italic' | 'normal';
  textInputStyles: TextStyle;
};

export type PartialTextAreaStyles = Partial<TextAreaStyles>;

export enum TextAreaStates {
  // Indicates that the text area is not in focus
  INACTIVE = 'inactive',

  // Indicates that the text area is in focus and being edited
  ACTIVE = 'active',
}
