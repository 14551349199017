export type RawDatePickersProps = {
  onDateChange: (value: Date | null) => void;
  date: Date | null;
  minDate?: Date;
  maxDate?: Date;
  highlight?: boolean;
  schedule_delay_in_minutes?: number;
  onDateDone?: (value: Date | null) => void;
};

/**
 * Notes about formatting
 * - Midnight will be 24:00 instead of 0:00
 *   To change this, substitute `k` instead of `h` for time formats
 * - Sleepio and Daylight use different DateTime formats
 */
export enum DatePickerDisplayFormat {
  TIME_FORMAT_12H = 'h:mm aa',
  TIME_FORMAT_24H = 'HH:mm',
  DATE_FORMAT = 'MM/dd/yyyy',
  DATE_TIME_FORMAT_12H = 'MM/dd/yyyy h:mm aa',
  DATE_TIME_FORMAT_24H = 'MM/dd/yyyy HH:mm',
}
export enum DaylightDatePickerDisplayFormat {
  TIME_FORMAT_12H = 'h:mm aa',
  TIME_FORMAT_24H = 'HH:mm',
  DATE_FORMAT = 'MM/dd/yyyy',
  DATE_TIME_FORMAT_12H = 'EEE MMM dd h:mm aa',
  DATE_TIME_FORMAT_24H = 'EEE MMM dd HH:mm',
}

export enum DatePickerPlaceholder {
  TIME_PLACEHOLDER_12H = '-- : -- --',
  TIME_PLACEHOLDER_24H = '-- : --',
  DATE_PLACEHOLDER = 'mm-dd-yyyy',
  DATE_TIME_PLACEHOLDER_12H = 'mm-dd-yyyy  -- : -- --',
  DATE_TIME_PLACEHOLDER_24H = 'mm-dd-yyyy  -- : --',
}
