import { DropdownItem } from 'components/generic-question/Dropdown';

type Errors = Record<string, string>;

export const handleValidate = (
  inputs: Record<string, DropdownItem[]>
): Errors => {
  // throw Error('handle validate');
  const errors: Errors = {};
  Object.entries(inputs).forEach(([name, optionsAnswer]) => {
    if (!optionsAnswer[0].value) {
      errors[name] =
        'Please set date and time of the reminder or continue without reminder';
    }
  });
  return errors;
};
