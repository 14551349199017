import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { DownloadsScreen } from '@bighealth/types';
import { AxiosRequestConfig } from 'axios';

export const get_all_downloads = async (
  args: get_all_downloads.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_all_downloads.Response> => {
  const request: get_all_downloads.Request = {
    service_name: 'SleepioDownload',
    service_version: '1',
    service_method: 'get_all_downloads',
    args,
  };

  return await api(request, requestConfig);
};

get_all_downloads.queryKey = 'SleepioDownload/get_all_downloads';

export declare namespace get_all_downloads {
  export type Args = {
    program_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepioDownload';
    service_version: '1';
    service_method: 'get_all_downloads';
    args: {};
  }

  export type Result = DownloadsScreen;

  export type Response = APIResponse<Result>;
}
