import { ControlAttrs } from 'config/getThemeForProducts/utils/createTheme';

export const newTheme = {
  controls: {
    selection: {
      color: '#163258',
      labelColor: '#163258',
      borderColor: '#DFE1E6',
      backgroundColor: '#FBFCFD',
      border: '1px solid #DFE1E6',
      default: {
        [ControlAttrs.isPressing]: {
          color: 'rgba(22, 50, 88, 0.3)', // '#163258'
          labelColor: 'rgba(22, 50, 88, 0.3)', // '#163258'
          borderColor: 'rgba(223, 225, 230, 0.3)', // #DFE1E6
          backgroundColor: 'rgba(251, 252, 253, 0.3)', // #FBFCFD
          border: '1px solid rgba(223, 225,230, 0.3)', // #DFE1E6
        },
        [ControlAttrs.isActive]: {
          color: '#163258',
          labelColor: '#163258',
          borderColor: '#DFE1E6',
          backgroundColor: '#FBFCFD',
          border: '1px solid #DFE1E6',
        },
        [ControlAttrs.isSelected]: {
          color: '#163258',
          labelColor: '#163258',
          borderColor: '#DFE1E6',
          backgroundColor: '#FBFCFD',
          border: '1px solid #DFE1E6',
        },
        [ControlAttrs.highlight]: {
          color: '#163258',
          labelColor: '#163258',
          borderColor: '#DFE1E6',
          backgroundColor: '#FBFCFD',
          border: '1px solid #DFE1E6',
        },
        [ControlAttrs.isHovering]: {
          color: '#163258',
          labelColor: '#163258',
          borderColor: '#DFE1E6',
          backgroundColor: '#FBFCFD',
          border: '1px solid #DFE1E6',
        },
      },
    },
  },
  modal: {
    input: {
      height: 70,
      paddingTop: 26,
      dateTimePicker: {
        editLabel: 'Edit',
        showCaret: false,
        showBorder: true,
      },
    },
  },
};
