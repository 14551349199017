import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

/**
 * Progress dot icon
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const ProgressBadge = ({
  width,
  height,
  color,
}: {
  width: number;
  height: number;
  color: string;
}): ReactElement => (
  <Svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
  >
    <G fill="none" fillRule="evenodd">
      <Path
        d="M18.3076 33.9312L13.7857 30.9228L9.26379 33.9312V20.0757H18.3076V33.9312Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M26.4554 10.6353C26.882 11.9065 25.3036 13.4742 25.3036 14.703C25.3036 16.0165 26.8394 17.5843 26.4128 18.813C25.9862 20.0418 23.8106 20.3808 23.0427 21.4401C22.2748 22.457 22.6161 24.6603 21.5496 25.423C20.5258 26.1433 18.5635 25.1688 17.2837 25.5925C16.0039 26.0162 14.9801 27.9229 13.7003 27.9229C12.3779 27.9229 11.3967 25.9738 10.1596 25.5501C8.92246 25.1264 6.96014 26.1009 5.89365 25.3382C4.86983 24.5756 5.2111 22.4146 4.44324 21.3553C3.67537 20.296 1.49975 19.9147 1.07315 18.6435C0.646562 17.3724 2.22495 15.8046 2.22495 14.5759C2.22495 13.2624 0.689221 11.6946 1.11581 10.4658C1.54241 9.23706 3.71803 8.89809 4.4859 7.8388C5.25376 6.82188 4.91249 4.61856 5.97897 3.85588C7.00279 3.13556 8.96512 4.11011 10.2449 3.68639C11.5247 3.26268 12.5485 1.35596 13.8283 1.35596C15.1507 1.35596 16.1319 3.30505 17.369 3.72876C18.6061 4.15248 20.5684 3.17793 21.6349 3.94062C22.6588 4.70331 22.3175 6.86426 23.0853 7.92354C23.8532 9.0252 26.0288 9.36418 26.4554 10.6353Z"
        fill="white"
      />
      <Path
        d="M26.4554 10.6353C26.882 11.9065 25.3036 13.4742 25.3036 14.703C25.3036 16.0165 26.8394 17.5843 26.4128 18.813C25.9862 20.0418 23.8106 20.3808 23.0427 21.4401C22.2748 22.457 22.6161 24.6603 21.5496 25.423C20.5258 26.1433 18.5635 25.1688 17.2837 25.5925C16.0039 26.0162 14.9801 27.9229 13.7003 27.9229C12.3779 27.9229 11.3967 25.9738 10.1596 25.5501C8.92246 25.1264 6.96014 26.1009 5.89365 25.3382C4.86983 24.5756 5.2111 22.4146 4.44324 21.3553C3.67537 20.296 1.49975 19.9147 1.07315 18.6435C0.646562 17.3724 2.22496 15.8046 2.22496 14.5759C2.22496 13.2624 0.689221 11.6946 1.11581 10.4658C1.54241 9.23706 3.71803 8.89809 4.4859 7.8388C5.25376 6.82188 4.91249 4.61856 5.97897 3.85588C7.00279 3.13556 8.96512 4.11011 10.2449 3.68639C11.5247 3.26268 12.5485 1.35596 13.8283 1.35596C15.1507 1.35596 16.1319 3.30505 17.369 3.72876C18.6061 4.15248 20.5684 3.17793 21.6349 3.94062C22.6588 4.70331 22.3175 6.86426 23.0853 7.92354C23.8532 9.0252 26.0288 9.36418 26.4554 10.6353Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.7725 22.7587C18.1575 22.7587 21.7122 19.204 21.7122 14.8191C21.7122 10.4341 18.1575 6.87939 13.7725 6.87939C9.38754 6.87939 5.83282 10.4341 5.83282 14.8191C5.83282 19.204 9.38754 22.7587 13.7725 22.7587Z"
        fill="white"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);
