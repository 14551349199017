const isValidDate = <T,>(date?: T): boolean => {
  if (date instanceof Date) {
    if (Object.prototype.toString.call(date) === '[object Date]') {
      return isNaN(date.getTime()) === false;
    }
  }
  return false;
};

export { isValidDate };
