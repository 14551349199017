/**
 * This method calculates if a video is seamless.
 * `seamless` property will be used by MediaNodeWithTimer to determine if
 * the application needs to pause the video during failure or continue.
 *
 * @param {number} nextTo video's end timestamp
 * @param {string} currentFrom video's start timestamp
 * @param {object} options existing state of the video component
 *
 * @returns {boolean} is video seamless
 */
export const getIsSeamless = (
  nextTo: number,
  currentFrom: number,
  options?: {
    seamless?: boolean;
  }
): boolean => {
  if (typeof options?.seamless === 'boolean') {
    return options.seamless;
  } else {
    return nextTo - currentFrom < 0.5;
  }
};
