import React, { ReactElement } from 'react';
import { View } from 'react-native';

import accessibilityElements from 'common/constants/accessibilityElements';
import {
  useGetDynamicContentStyles,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { isMobileWeb } from 'config/isMobileWeb';

import { SubTitle, Title } from './styled';
import { PresentationProps } from '.';

export const Header = (props: PresentationProps): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const scaleFn = useTransformContentStyle();

  const titleProps = {
    text: props.title.text,
    alignment: props.titleAlignment,
  };

  const subtitleProps = {
    text: props.diaries.date_range_label.text,
    alignment: props.subtitleAlignment,
  };

  let subTitleStyle = {
    fontSize: scaleFn(props.subtitle.fontSize),
    color: props.subtitle.color,
    marginTop: styles.sleepDiaryWeekCalendarSubTitleMarginTopNarrow,
  };
  let titleStyle = {
    fontSize: scaleFn(props.title.fontSize),
    color: props.title.color,
  };

  if (isMobileWeb) {
    titleStyle = {
      fontSize: styles.sleepDiaryWeekCalendarTitleFontSizeNarrow,
      color: props.title.color,
    };

    subTitleStyle = {
      fontSize: styles.sleepDiaryWeekCalendarSubTitleFontSizeMobileWeb,
      color: props.subtitle.color,
      marginTop: styles.sleepDiaryWeekCalendarSubTitleMarginTopNarrow,
    };
  }

  return (
    <View
      style={{
        paddingTop: styles.sleepDiaryWeekCalendarContainerPaddingTop,
      }}
    >
      <Title
        accessibility={accessibilityElements.H1}
        {...titleProps}
        style={titleStyle}
      />
      <SubTitle
        accessibility={accessibilityElements.H1}
        {...subtitleProps}
        style={subTitleStyle}
      />
    </View>
  );
};
