import { formatDuration, intervalToDuration } from 'date-fns';

const formatDurationDisplayText = (end: number): string =>
  end === 0
    ? '0 mins'
    : formatDuration(intervalToDuration({ start: 0, end }))
        .split('minute')
        .join('min')
        .split('hour')
        .join('hr');

export { formatDurationDisplayText };
