const code = `
self.addEventListener('message', ({ data }) => {
  const { timeout, command } = data;
  let timer = undefined;
  switch (command) {
    case 'setTimeout':
      timer = setTimeout(() => {
        clearTimeout(timer);
        self.postMessage({status: "complete"});
      }, parseInt(timeout, 10));
      break;
    case 'clearTimeout':
      if (timer) {
        clearTimeout(timer);
      }
      break;
    default:
    // NOOP
  }
});
`;

export const generateSetTimeoutWorkerCode = (): string => {
  const blob = new Blob([code], { type: 'application/javascript' });
  return URL.createObjectURL(blob);
};
