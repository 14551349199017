import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { VisibilityOverride } from 'common/constants/enums';
import useScreenReaderStatus from 'common/hooks/useScreenReaderStatus/useScreenReaderStatus';
import { SessionProgressBar } from 'components/Media/PlayerControls/SessionProgressBar/SessionProgressBar';
import { useContentRedirectContext } from 'components/ProvidersContainer/ContentRedirectProvider';
import { MediaResponsiveLayoutProvider } from 'components/ResponsiveLayout';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryJumpToSceneSet } from 'lib/api/reactQueryHelpers';
import { getCaptionLanguageOptionsFromSceneSet } from 'lib/player/getCaptionLanguageOptionsFromSceneSet';
import { getMediaPropsFromSceneSetForScene } from 'lib/player/getMediaPropsFromSceneSetForScene';
import { getSessionProgressPercent } from 'lib/player/getSessionProgressPercent';
import sceneSetHasCaptionsStorageUrl from 'lib/player/sceneSetHasCaptionsStorageUrl';
import * as contentModalSelectors from 'state/content-modal/selectors';
import {
  getControlsAreVisible,
  getControlsCloseButtonIsVisible,
  getPlayerState,
} from 'state/player/selectors';

import { CloseButtonContainer } from './CloseButton/CloseButtonContainer';
import { MediaFadeInOut } from './MediaFadeInOut/MediaFadeInOut';
import { CloseButton } from './CloseButton';
import { ClosedCaption } from './ClosedCaption';
import { MediaBar } from './MediaBar';
import { MediaPlayerControls } from './MediaPlayerControls';
import { SessionDetails } from './SessionDetails';
import { useFadingAnimation } from './useFadingAnimation';

const Row = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
Row.displayName = 'Row';

type PlayerControlsProps = {
  hideMediaBar?: boolean;
};

export const PlayerControls = ({
  hideMediaBar,
}: PlayerControlsProps): ReactElement | null => {
  const totalContentModalsMounted = useSelector(
    contentModalSelectors.getTotalMounted
  );
  const { exitContent } = useContentRedirectContext();
  const { productReference, sceneSetId, sceneId } = useSafeParams<
    SceneSetParams
  >();
  const { errorDetails } = contentErrors.useContext();
  const controlsCloseButtonIsVisible = useSelector(
    getControlsCloseButtonIsVisible
  );
  const { screenReaderEnabled } = useScreenReaderStatus();
  const playerState = useSelector(getPlayerState);
  const controlsAreVisible = useSelector(getControlsAreVisible);
  const { fadeAnim, hidden } = useFadingAnimation({
    playerState,
    controlsAreVisible,
    visibilityOverride: screenReaderEnabled
      ? VisibilityOverride.SHOW
      : undefined,
    modal: totalContentModalsMounted !== 0,
  });
  const sceneSet = useQueryJumpToSceneSet(sceneSetId)?.data?.result
    ?.scene_set_json;
  const captionLanguageOptions = useMemo(
    () => getCaptionLanguageOptionsFromSceneSet(sceneSet),
    [sceneSet]
  );
  const hasCaptionsStorageUrl = useMemo(
    () => sceneSetHasCaptionsStorageUrl(sceneSet),
    [sceneSet]
  );

  if (!sceneSet || typeof errorDetails?.error !== 'undefined') {
    return null;
  }

  const { height, width } = getMediaPropsFromSceneSetForScene(
    sceneSet,
    sceneId || 0
  );

  const sessionProgressPercent = getSessionProgressPercent(sceneSet);

  const handlePress = () => {
    exitContent({ fallback: 'home', productReference });
  };

  return (
    <MediaResponsiveLayoutProvider height={height} width={width}>
      {hideMediaBar ? null : (
        <MediaBar hidden={hidden} fadeAnim={fadeAnim}>
          <Row>
            <SessionDetails />
            {(captionLanguageOptions?.length > 0 || hasCaptionsStorageUrl) && (
              <ClosedCaption languageOptions={captionLanguageOptions} />
            )}
            <MediaPlayerControls
              visibilityOverride={
                screenReaderEnabled ? VisibilityOverride.SHOW : undefined
              }
            />
          </Row>
          <SessionProgressBar sessionProgressPercent={sessionProgressPercent} />
        </MediaBar>
      )}
      {controlsCloseButtonIsVisible ? (
        <CloseButtonContainer {...roles('CloseButtonContainer')}>
          <MediaFadeInOut
            hidden={hidden}
            fadeAnim={fadeAnim}
            visibilityOverride={
              totalContentModalsMounted !== 0
                ? VisibilityOverride.SHOW
                : undefined
            }
          >
            <CloseButton
              {...roles('CloseButtonInModal')}
              showText
              onPress={handlePress}
            />
          </MediaFadeInOut>
        </CloseButtonContainer>
      ) : null}
    </MediaResponsiveLayoutProvider>
  );
};
