import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';

import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { mockRes17Sep2020 } from 'components/SleepDiaryForm/__mocks__/17Sep2020';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { HOUR, SECOND } from 'lib/durations';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

import { Form } from '..';

const payload = mockRes17Sep2020.result;

const Story = () => {
  const sceneSets = useFakeStorybookSceneSet();
  const [time, setTime] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setTime((time + 3) % 24); // Rotate around clock
    }, 1 * SECOND);
  }, [time]);

  if (!sceneSets) {
    return null;
  }
  const hide = { hidden: true };
  const minTime = time;
  const maxTime = time + 12; // Open up valid window
  const FIELD_PROPS = {
    sleep_efficiency_time_into_bed: {
      initialValue: new Date('1970-01-01T06:00:00.000Z'),
    },
    sleep_efficiency_time_try_to_sleep: {
      questionProps: {
        response_config: {
          response_options: [
            {
              min_response: new Date(minTime * HOUR),
              max_response: new Date(maxTime * HOUR),
            },
          ],
          validation_schema: {
            properties: {
              '1': {
                type: 'number',
                minimum: minTime * HOUR,
                exclusiveMaximum: maxTime * HOUR,
              },
            },
          },
        },
      },
      initialValue: new Date('1970-01-01T06:00:00.000Z'),
    },
    sleep_efficiency_to_fall_asleep_total_time: hide,
    sleep_efficiency_awakenings_in_night: hide,
    sleep_efficiency_awakenings_total_time: hide,
    sleep_efficiency_time_final_awakening: {
      questionProps: {
        response_config: {
          response_options: [
            {
              min_response: new Date(minTime * HOUR),
              max_response: new Date(maxTime * HOUR),
            },
          ],
          validation_schema: {
            properties: {
              '1': {
                type: 'number',
                minimum: minTime * HOUR,
                exclusiveMaximum: maxTime * HOUR,
              },
            },
          },
        },
      },
    },
    sleep_efficiency_time_get_out_of_bed: hide,
    sleep_efficiency_sleep_quality: { initialValue: 'fair' },
  };
  return (
    <View>
      <Text>{`sleep_efficiency_time_into_bed has initial value`}</Text>
      <Text>{`sleep_efficiency_time_try_to_sleep has rotates (1s) max/min window and deselects initial value`}</Text>
      <Text>{`sleep_efficiency_time_final_awakening rotates max/min window (once selected will show validation errors)`}</Text>
      <Text>{`sleep_efficiency_sleep_quality has initial value`}</Text>
      <Form fieldProps={FIELD_PROPS} {...payload} />
      <Text>{JSON.stringify(FIELD_PROPS, null, 2)}</Text>
    </View>
  );
};

addStory('sleep-diary/Form initialValueAndOptions', () => (
  <EphemeralStateProvider>
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <Story />
    </CrossPlatformThemeProvider>
  </EphemeralStateProvider>
));
