import React, { ReactElement } from 'react';
import { View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { CrossRounded } from 'components/icons';
import { TextPresentation } from 'components/sceneset-components/Text/TextPresentation';
import { RoleProps } from 'cross-platform/utils/roleProps';
import * as roleProps from 'cross-platform/utils/roleProps';

export const Container = styled.View``;
Container.displayName = 'Container';

export const ButtonWrapper = styled.TouchableOpacity<{
  backgroundColor?: string;
}>`
  background-color: ${props =>
    props.backgroundColor ||
    props.theme.content.controls.closeButton.backgroundColor};
  padding: 12px;
  border-radius: 50px;
  flex-direction: row;
  align-items: center;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const CloseButtonText = styled(TextPresentation)<{ showText?: boolean }>`
  font-weight: 700;
  font-size: 18px;
  margin-right: 8px;
  color: ${props => props.theme.content.controls.closeButton.textColor};
  display: ${props =>
    props.showText && props.theme.content.controls.closeButton.showText
      ? 'flex'
      : 'none'};
`;
CloseButtonText.displayName = 'CloseButtonText';

const CrossRoundedStyled = styled(CrossRounded)`
  color: ${props => props.theme.content.controls.closeButton.iconColor};
`;
CrossRoundedStyled.displayName = 'CrossRoundedStyled';

export type CloseButtonProps = {
  style?: ViewStyle;
  showText?: boolean;
  onPress: () => void;
} & RoleProps;

export const CloseButton = ({
  style,
  onPress,
  showText,
  ...rest
}: CloseButtonProps): ReactElement => {
  return (
    <Container style={style}>
      {/*
        TODO: Render text only when props.showText and content.controls.closeButton.showText
        WHEN: styled-components theme will be extended
      */}
      <ButtonWrapper
        {...roleProps.pass(rest)}
        backgroundColor={style?.backgroundColor as string}
        onPress={onPress}
        accessibilityRole="button"
        aria-label="Close modal"
        accessibilityLabel="Close modal"
        accessibilityHint="Closes the navigation menu"
      >
        <CloseButtonText showText={showText} aria-hidden={true}>
          Close
        </CloseButtonText>
        <View aria-hidden={true}>
          <CrossRoundedStyled size={16} />
        </View>
      </ButtonWrapper>
    </Container>
  );
};
