import { clone } from 'ramda';

import { ProgressEntry } from '@bighealth/types/dist';

import { deepMerge } from 'lib/deepMerge';

import { ImageProps, Props, TextProps } from '../..';
import { defaultProps } from '../../constants';
import { getReference } from '../getReference';
import { guardAndCastProgressEntry } from '../guardAndCastProgressEntry';

/**
 * A Factory function, that creates props.
 *
 * The design biases for:
 * - named, typed functions for debuggin
 * - separating messy merging from rendering
 * - changes in data sources
 * - minimal boilerplate i.e. shared params
 *
 * @param progressEntry from `useQueryGoals` result
 * @param rootProps from scene_set_json
 * @returns set of functions for accessing a created prop
 */
export const propGetterFactory = (
  progressEntryUncast: ProgressEntry,
  rootProps: Props
) => {
  const progressEntryCast = guardAndCastProgressEntry(progressEntryUncast);
  const reference = getReference(progressEntryCast);

  return {
    /**
     * Not rendered, only used as mapping identifier
     */
    label: (): TextProps => progressEntryCast.label,
    /**
     *
     */
    goal: (): TextProps =>
      deepMerge(
        clone(defaultProps.goal),
        progressEntryCast.text || {}, // "text" is unfortunate network response field name
        rootProps.goal || {}
      ),
    /**
     *
     */
    image: () => {
      const imageMap = deepMerge(
        clone(defaultProps.imageMap),
        rootProps.imageMap
      );
      // RE: "as ImageProps" on deepMerge result
      // "sourceProps" omitted from target, but in source, thus result
      const image = imageMap[reference] as ImageProps;
      if (image.sourceProps === undefined) {
        throw TypeError(
          `Expected ProgressGoalsV2 ${reference} image to have sourceProps, instead got ${JSON.stringify(
            image,
            null,
            2
          )} from merge,

          defaults[reference]:
          ${JSON.stringify(defaultProps.imageMap[reference], null, 2)}
          rootProps[reference]:
          ${JSON.stringify(rootProps.imageMap[reference], null, 2)}
          
          defaults:
          ${JSON.stringify(defaultProps.imageMap, null, 2)} 
          rootProps:
          ${JSON.stringify(rootProps.imageMap, null, 2)}
          
          `
        );
      }
      return image;
    },
    /**
     * Note: `progressEntryCast.comment` (from network request) is currently undefined
     * @see SLEEPIO-4269 "Defensive code: comment"
     * @returns
     */
    comment: (): TextProps =>
      deepMerge(
        clone(defaultProps.comment),
        progressEntryCast.comment || {},
        rootProps.comment || {},
        {
          text:
            rootProps?.commentTextMap?.[progressEntryCast.icon]?.[reference] ||
            defaultProps.commentTextMap[progressEntryCast.icon][reference],
        }
      ),
  };
};

export const getGoalDetailsProps = (
  rootProps: Props, // From content
  progressEntry: ProgressEntry // From network request
) => {
  const propGetter = propGetterFactory(progressEntry, rootProps);
  return {
    label: propGetter.label(),
    goal: propGetter.goal(),
    comment: propGetter.comment(),
    image: propGetter.image(),
  };
};
