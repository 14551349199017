import styled from 'styled-components/native';

import { DOMAIN } from 'components/generic-question/SelectHorizontal/constants';

import {
  EdgeInsetsProps,
  IsMobileWebProps,
} from './getContentWhiteSpace/types';
import { getContentWhiteSpace } from './getContentWhiteSpace';

const Content = styled.View<IsMobileWebProps & EdgeInsetsProps>`
  margin-left: ${getContentWhiteSpace('left')}px;
  margin-right: ${getContentWhiteSpace('right')}px;
`;
Content.displayName = `${DOMAIN}.Content`;

export { Content };
