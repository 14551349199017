import { useCallback } from 'react';

// eslint-disable-next-line prettier/prettier
import { fire_ui_events_types } from '@bighealth/api/Event/v1';

import { ProductReferences } from 'common/constants/enums';
import { useQueryProductStreamVersion } from 'components/Footer/hooks/useQueryProductStreamVersion';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { getAnalyticsDate } from 'lib/api/analytics/getAnalyticsDate';
import * as getUserAgentModule from 'lib/api/analytics/getUserAgent/getUserAgent'; // for mocking
import { URLLike, useGetURL } from 'lib/api/analytics/useGetURL';

import { queryFireUIEvent } from '../queryClientAnalogs/queryFireUIEvent';
import { useQueryProduct, useQueryProgram } from '..';

// "client environment" variables can be accessed in module
const packageJSON = require('../../../../../package.json');
const clientAppNodePackageVersion = packageJSON.version;

let userAgentPromise: Promise<string> | undefined;

export type UIEventCreatorContextArg = {
  productId: number;
  programId: number;
  sceneSetGraphId: number;
  productReference: string;
  url: URLLike;
  productVersionStream?: string;
  clientAppNodePackageVersion: string;
  userAgent: string;
  occurredAt: string;
};
export type UIEventCreator = (
  context: UIEventCreatorContextArg
) => Promise<fire_ui_events_types.Args['events']>;

export type TrackEvent = (uiEventCreator: UIEventCreator) => Promise<void>;
const SLEEPIO_3133_TEMP_VALUE = -1;
export const useLazyQueryFireUIEvent = (): TrackEvent => {
  const {
    productReference,
    sceneSetGraphId = SLEEPIO_3133_TEMP_VALUE,
  } = useSafeParams();
  const programId = useQueryProgram().data?.result.id ?? 0;
  const productId = useQueryProduct().data?.result.id ?? 0;
  const getUrl = useGetURL();
  /**
   * TODO Check if getUrl needs to return a promise
   * HOW FEG-288 Seems like unnecessary promise
   * WHEN Tempted by the prospect of avoiding a whirl on the event loop
   */
  const urlPromise = getUrl();
  const productVersionStreamResult = useQueryProductStreamVersion.get_latest_version(
    { meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN } }
  );

  return useCallback<TrackEvent>(
    productReference !== ProductReferences.SLEEPIO
      ? async () => undefined // Stub for everything but Sleepio
      : async function trackEvent(uiEventCreator: UIEventCreator) {
          if (typeof userAgentPromise === 'undefined') {
            userAgentPromise = getUserAgentModule.getUserAgent(); // Start promise.
          }
          await queryFireUIEvent(
            await uiEventCreator({
              productId,
              programId,
              sceneSetGraphId,
              productReference,
              url: await urlPromise,
              productVersionStream: productVersionStreamResult?.data?.result,
              clientAppNodePackageVersion,
              userAgent: await userAgentPromise,
              occurredAt: getAnalyticsDate(),
            })
          );
        },
    [
      productId,
      productReference,
      productVersionStreamResult,
      programId,
      urlPromise,
    ]
  );
};
