export const isPreviousDate = (
  minDate: number,
  compareTo: (number | string | undefined)[]
): boolean => {
  for (const date of compareTo) {
    if (typeof date === 'number' && date > minDate) {
      return true;
    }
  }
  return false;
};
