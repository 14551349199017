import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

export const CardCurlGraphic = (): ReactElement => {
  return (
    <Svg width={193} height={27} viewBox="0 0 193 27" fill="none">
      <G>
        <Path
          d="M 2.02783 12.1081 C 15.9102 20.5286 34.3007 23.4982 50.3655 22.5187 C 55.7914 22.1878 60.4766 20.144 62.3311 14.7864 C 63.6054 11.1052 65.0525 2.86394 58.7458 2.86394 C 52.9079 2.86394 45.5141 6.61159 47.4713 13.4041 C 48.7949 17.9978 52.765 19.873 57.1043 21.3955 C 70.4365 26.0735 86.2261 24.8217 99.9559 23.2962 C 110.247 22.1527 126.436 19.2513 126.436 6.6653 C 126.436 2.41223 121.275 0.627462 118.056 3.72789 C 116.4 5.3223 114.775 7.58386 114.427 9.90509 C 113.148 18.4282 126.031 20.1532 131.447 20.9204 C 145.874 22.9642 158.484 19.9027 171.879 14.7 C 179.732 11.6499 184.597 6.78181 190.972 2"
          stroke="#FFAA92"
          strokeWidth={3}
          strokeLinecap="round"
        />
      </G>
    </Svg>
  );
};
