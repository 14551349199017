import { useCallback } from 'react';

import { useGetURL } from 'lib/api/analytics/useGetURL';

import {
  ButtonPressEventParams,
  queryClientButtonClickEvent,
} from '../queryClientAnalogs/queryClientButtonClickEvent';
import { useQueryProduct, useQueryProgram } from '..';

export type CallbackParams = Pick<
  ButtonPressEventParams,
  'name' | 'fields' | 'relations'
>;
export type Callback = (params: CallbackParams) => Promise<void>;

/**
 * Used to asynchronously send a button click/press event to StreamTrace. You must pass
 * the event specifics to the callback when you use it.
 */
export const useLazyQueryButtonClickEvent = (): Callback => {
  const programId = useQueryProgram().data?.result.id ?? 0;
  const productId = useQueryProduct().data?.result.id ?? 0;
  const getUrl = useGetURL();
  return useCallback(
    async ({ name, fields, relations }) => {
      await queryClientButtonClickEvent({
        fields,
        name,
        productId,
        programId,
        relations,
        url: await getUrl(),
      });
    },
    [getUrl, productId, programId]
  );
};
