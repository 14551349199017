import React, { ReactElement } from 'react';
import Svg, { Path } from 'react-native-svg';

import { roles } from 'cross-platform/utils/roleProps';

import { IconStyles } from './types';

export const CompactCheck = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  const width = size;
  const height = size * 0.8;

  return (
    <Svg
      {...roles('CompactCheck')}
      accessibilityLabel="Captions check"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <Path
        d="M2.6665 5.94556L6.15755 10.0808L12.7675 2"
        stroke={style?.color || 'currentColor'}
        strokeWidth="3.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
