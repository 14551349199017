import React, { ReactElement, useEffect } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { SleepioLogoWhite } from 'components/icons';
import { CloseButton } from 'components/Media/PlayerControls/CloseButton';
import { Modal } from 'components/PopupModal/components/Modal';
import {
  ScalingContext,
  ScalingContextProvider,
  SectionResponsiveLayoutProvider,
} from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { setIsFirstLogin } from 'state/user/actions';
import { getIsFirstLogin } from 'state/user/selectors';

import {
  LayoutComponent,
  renderChildNodes,
} from '../LayoutComponent/LayoutComponent';

import { useModalLayout } from './hooks/useModalLayout';
import { DOMAIN, doubleColumnBase, navigationMaxWidth } from './constants';
import { LayoutType } from './types';

const ModalContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  flex-direction: column;
  background-color: #081353;
`;
ModalContainer.displayName = `${DOMAIN}.ModalContainer`;

const ContentContainer = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
    alignItems: 'center',
  },
})`
  flex: 1;
  width: 100%;
  background-color: #081353;
`;
ContentContainer.displayName = `${DOMAIN}.ContentContainer`;

const Nav = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  max-width: ${navigationMaxWidth}px;
`;
Nav.displayName = `${DOMAIN}.Nav`;

const Header = styled.View`
  flex-direction: row;
`;
Header.displayName = `${DOMAIN}.Header`;

export const Main = styled.View<{ layoutType: LayoutType }>`
  width: 100%;
  margin-bottom: 70px;
  max-width: ${doubleColumnBase}px;
  flex-direction: ${props =>
    props.layoutType === LayoutType.Row ? 'row' : 'column'};
`;
Main.displayName = `${DOMAIN}.Main`;

type PreviewModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  modal: get_user_session_screen_data.PreviewModal;
};

export const PreviewModal = ({
  modal,
  visible,
  setVisible,
}: PreviewModalProps): ReactElement | null => {
  const safeArea = useSafeArea();
  const dispatch = useDispatch();
  const modalLayout = useModalLayout();
  const isFirstLogin = useSelector(getIsFirstLogin);

  useEffect(() => {
    if (visible && isFirstLogin) {
      dispatch(setIsFirstLogin(false));
    }
  }, [dispatch, isFirstLogin, visible]);

  return (
    <ModalContainer
      {...roles('PreviewModalContainer')}
      style={{ display: visible ? 'flex' : 'none' }}
    >
      <Modal
        visible={visible}
        transparent={true}
        supportedOrientations={['portrait', 'landscape']}
      >
        <ContentContainer
          style={{ paddingLeft: safeArea.left, paddingRight: safeArea.right }}
        >
          <Nav style={{ marginTop: safeArea.top + 32 }}>
            <SleepioLogoWhite height={39} />
            <CloseButton
              {...roles('PreviewModalCloseButton')}
              onPress={() => {
                setVisible(false);
              }}
              style={{
                position: 'absolute',
                // when there is safeArea requirement on the right side,
                // the container will provide necessary padding
                right: safeArea.right > 0 ? 0 : 32,
              }}
            />
          </Nav>
          <Header>
            <SectionResponsiveLayoutProvider {...modalLayout.header}>
              <LayoutComponent {...modal.header} />
            </SectionResponsiveLayoutProvider>
          </Header>
          <Main layoutType={modalLayout.layoutType}>
            <SectionResponsiveLayoutProvider {...modalLayout.main}>
              <LayoutComponent {...modal.main.column_1} />
            </SectionResponsiveLayoutProvider>
            {/* we want layout component to scale but not its content */}
            <SectionResponsiveLayoutProvider {...modalLayout.main}>
              <LayoutComponent
                childNodes={[]}
                style={modal.main.column_2.style}
              >
                {/* ScalingContext.Screen has no scaling */}
                <ScalingContextProvider scalingContext={ScalingContext.Screen}>
                  {renderChildNodes(modal.main.column_2.childNodes)}
                </ScalingContextProvider>
              </LayoutComponent>
            </SectionResponsiveLayoutProvider>
          </Main>
        </ContentContainer>
      </Modal>
    </ModalContainer>
  );
};
