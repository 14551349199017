import { disconnect } from '@bighealth/api/Fitbit/v1';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';

export const queryClientFitBitDisconnect = async (args: {
  product_id: number;
}): Promise<disconnect.Response> => {
  return await getQueryClient().fetchQuery(
    disconnect.queryKey,
    () => disconnect(args),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
};
