import { throwIfMissingEnvVar } from 'config/envVars/helpers';

export const PLATGEN_ONBOARDING_URL = throwIfMissingEnvVar(
  'PLATGEN_ONBOARDING_URL',
  process.env.PLATGEN_ONBOARDING_URL
);

export const PLATGEN_LOGIN_URL = throwIfMissingEnvVar(
  'PLATGEN_LOGIN_URL',
  process.env.PLATGEN_LOGIN_URL
);

export const PLATGEN_COMMUNITY_URL = throwIfMissingEnvVar(
  'PLATGEN_COMMUNITY_URL',
  process.env.PLATGEN_COMMUNITY_URL
);
