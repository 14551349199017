import { SceneChild, SceneSet } from '@bighealth/types';

type MediaPropsStyle = {
  height: number;
  width: number;
};

const fallbackDimensions: MediaPropsStyle = {
  width: 1024,
  height: 576,
};

export const getMediaPropsFromSceneSetForScene = (
  sceneSet: SceneSet,
  sceneId: number
): MediaPropsStyle => {
  const scene = sceneSet.childNodes[sceneId];
  if (!scene) {
    return fallbackDimensions;
  }
  let foundMediaPropsStyle: MediaPropsStyle | undefined = undefined;
  const traverseTree = ({
    childNodes: rootChildNodes,
    component: rootComponent,
    mediaProps,
  }: SceneChild & { mediaProps?: MediaPropsStyle }): SceneChild => {
    if (
      typeof mediaProps?.width !== 'undefined' &&
      typeof mediaProps?.height !== 'undefined'
    ) {
      foundMediaPropsStyle = mediaProps;
    }
    return {
      component: rootComponent,
      mediaProps,
      childNodes: rootChildNodes
        ? rootChildNodes.map(
            ({ component, childNodes, mediaProps }): SceneChild => {
              return traverseTree({
                childNodes,
                component,
                mediaProps: mediaProps as MediaPropsStyle,
              });
            }
          )
        : undefined,
    };
  };
  traverseTree((scene as unknown) as SceneChild);
  if (typeof foundMediaPropsStyle === 'undefined') {
    // Use default value
    return fallbackDimensions;
  }
  return foundMediaPropsStyle;
};
