import { useCallback } from 'react';

import { ButtonFields } from '@bighealth/analytics/dist/types/eventBase';

import {
  StreamTraceAreas,
  StreamTraceEventNames,
  StreamTraceSections,
} from 'components/constants';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useLazyQueryButtonClickEvent } from 'lib/api/reactQueryHelpers/useLazyQueries/useLazyQueryButtonClickEvent';

type ReturnType = (fields: ButtonFields) => Promise<void>;

/**
 * Returns a function that takes in the fields portion of the ButtonResourceEvent.body payload.
 * Currently, it is meant to track clicks on the play/pause button as well as the closed captions button.
 */
export const useMediaPlayerButtonEvent = (): ReturnType => {
  const sendClickCallback = useLazyQueryButtonClickEvent();
  const { sceneSetGraphId, sceneSetId, sceneId } = useSafeParams();

  return useCallback(
    async fields => {
      sendClickCallback({
        name: StreamTraceEventNames.MEDIA_PLAYER_BUTTON_PRESS,
        fields: {
          ...fields,
          feature: {
            area: StreamTraceAreas.THERAPY,
            section: StreamTraceSections.THERAPY,
          },
        },
        relations: {
          scene: sceneId !== undefined ? { id: sceneId } : undefined,
          scene_set: sceneSetId !== undefined ? { id: sceneSetId } : undefined,
          scene_set_graph: { id: sceneSetGraphId },
        },
      });
    },
    [sceneId, sceneSetGraphId, sceneSetId, sendClickCallback]
  );
};
