import { get_diary_entry_form } from '@bighealth/api/SleepDiary/v1';

import { ActionHandlerCallback } from 'lib/player/useActionHandler';

export type SleepDiaryAction = get_diary_entry_form.Result['form']['buttons'][number]['action'];

type Options = {
  closeInputFormHandler?: ActionHandlerCallback;
  submitSleepDiaryFormAndCloseInputFormHandler?: ActionHandlerCallback;
  submitSleepDiaryFormAndGoToNextSceneSet?: ActionHandlerCallback;
};

export type SleepDiaryToSceneHandler = (
  action: SleepDiaryAction
) => ActionHandlerCallback;

type Factory = (options: Options) => SleepDiaryToSceneHandler;

/**
 * Higher-order function
 *
 * @param {ActionHandlerCallback} options.nextHandler
 */
const createHandler: Factory = ({
  closeInputFormHandler,
  submitSleepDiaryFormAndCloseInputFormHandler,
  submitSleepDiaryFormAndGoToNextSceneSet,
}) => {
  return action => {
    if (typeof action === 'undefined') {
      return;
    }
    switch (action.type) {
      case 'sleep-diary/CANCEL':
        return closeInputFormHandler;
      case 'sleep-diary/SUBMIT_AND_MODAL_CLOSE':
        return submitSleepDiaryFormAndCloseInputFormHandler;
      case 'sleep-diary/SUBMIT_AND_CONTINUE':
        return submitSleepDiaryFormAndGoToNextSceneSet;
    }
  };
};

export { createHandler };
