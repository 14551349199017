import { SceneSetResponse } from '@bighealth/types';
import { JumpToSceneSetSource } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const jump_to_specific_scene_set = async (
  args: jump_to_specific_scene_set.Args,
  requestConfig?: AxiosRequestConfig
): Promise<jump_to_specific_scene_set.Response> => {
  // Note: user_id and user_timezone are in the header
  const request: jump_to_specific_scene_set.Request = {
    service_name: 'SceneSetGraph',
    service_version: '1',
    service_method: 'jump_to_specific_scene_set',
    args,
  };

  return await api(request, requestConfig);
};

jump_to_specific_scene_set.queryKey =
  'SceneSetGraph/jump_to_specific_scene_set';

export declare namespace jump_to_specific_scene_set {
  export type Args = {
    current_graph_id: number;
    current_program_id: number;
    current_product_id: number;
    current_scene_set_id: number | undefined;
    destination_scene_set_id: number | undefined;
    source?: JumpToSceneSetSource;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SceneSetGraph';
    service_version: '1';
    service_method: 'jump_to_specific_scene_set';
    args: Args;
  }

  export type Result = SceneSetResponse;

  export type Response = APIResponse<Result | null>;
}
