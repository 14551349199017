import { MarkdownType } from 'components/Markdown/constants';

export const convertMarkdownType = (type?: string): MarkdownType => {
  switch (type) {
    case MarkdownType.CUSTOM:
      return MarkdownType.CUSTOM;
    case MarkdownType.DEFAULT:
      return MarkdownType.DEFAULT;
    case MarkdownType.ERROR_BOUNDARY:
      return MarkdownType.ERROR_BOUNDARY;
    default:
      return MarkdownType.DEFAULT;
  }
};
