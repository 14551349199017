import { useMemo } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { CONTENT_MIN_REFERENCE_DIMENSION } from 'components/ResponsiveLayout/constants/contentStyles';
import { contentStyles } from 'components/ResponsiveLayout/constants/contentStyles'; // IMPORTANT. This is a _different_ `contentStyles` to above ^
import { useGetScreenDimensions } from 'components/ResponsiveLayout/hooks/useGetScreenDimensions';

export const useGetContentDimensions = (): {
  contentWidth: number;
  contentMarginTop: number;
} => {
  const { screenWidth } = useGetScreenDimensions();
  const insets = useSafeArea();
  const effectiveWidth = screenWidth - insets.left - insets.right;
  const MIN = CONTENT_MIN_REFERENCE_DIMENSION;
  const scale = screenWidth / MIN;
  const {
    navWidthMax,
    navLowerScaleWidth,
    contentLowerScaleWidth,
    contentWidthMax,
    contentMarginTop,
  } = contentStyles;
  let contentWidth: number;
  let contentMarginTopAdjusted: number;
  if (effectiveWidth <= MIN) {
    contentWidth = contentLowerScaleWidth * scale;
    contentMarginTopAdjusted = 0;
  } else {
    const navWidth = navLowerScaleWidth * scale;
    if (navWidth > navWidthMax) {
      contentWidth = contentWidthMax;
    } else {
      contentWidth = contentLowerScaleWidth * scale;
    }
    contentMarginTopAdjusted = contentMarginTop;
  }
  return useMemo(
    () => ({ contentWidth, contentMarginTop: contentMarginTopAdjusted }),
    [contentMarginTopAdjusted, contentWidth]
  );
};
