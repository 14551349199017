import { DaylightProgressScreenPayload } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_progress_data = async (
  args: get_progress_data.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_progress_data.Response> => {
  const request: get_progress_data.Request = {
    service_name: 'DaylightSessionProgress',
    service_version: '1',
    service_method: 'get_progress_data',
    args,
  };

  return await api(request, requestConfig);
};

get_progress_data.queryKey = 'DaylightSessionProgress/get_progress_data';

export declare namespace get_progress_data {
  export type Args = {
    program_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'DaylightSessionProgress';
    service_version: '1';
    service_method: 'get_progress_data';
    args: Args;
  }

  export type Result = DaylightProgressScreenPayload;

  export type Response = APIResponse<Result>;
}
