export enum MFAEnableComponentOptions {
  MFAToggle = 'MFAToggle',
  MFASectionWrapper = 'MFASectionWrapper',
  MFAEnableCopyClipboard = 'MFAEnableCopyClipboard',
  MFAEnableVerifyCode = 'MFAEnableVerifyCode',
  MFAINeedHelp = 'MFAEnableINeedHelp',
}

export enum MFADisableComponentOptions {
  MFAToggle = 'MFAToggle',
  MFASectionWrapper = 'MFASectionWrapper',
  MFAEnterDisableCode = 'MFAEnterDisableCode',
  MFADisableINeedHelp = 'MFADisableINeedHelp',
}

export enum MFAActionTypes {
  'SUBMIT' = 'submit',
  'NEXT' = 'next',
}

export enum MFASceneTypes {
  ToggleView = 'toggle_view',
  AuthenticationStart = 'authentication_start',
  AuthenticationDownload = 'authentication_download',
  AuthenticationCopyCode = 'authentication_copy_code',
  AuthenticationVerifyCode = 'authentication_verify_code',
  AuthenticationAllSetUp = 'authentication_all_set_up',
}
export enum MFASceneDisabledTypes {
  ToggleView = 'toggle_view',
  AuthenticationDisableEnterCode = 'authentication_disable_enter_code',
  AuthenticationIsDisabled = 'authentication_is_disabled',
}

export type MFASceneOptionType =
  | MFASceneTypes.ToggleView
  | MFASceneTypes.AuthenticationStart
  | MFASceneTypes.AuthenticationDownload
  | MFASceneTypes.AuthenticationCopyCode
  | MFASceneTypes.AuthenticationVerifyCode
  | MFASceneTypes.AuthenticationAllSetUp;

export type MFASceneOptionDisabledType =
  | MFASceneDisabledTypes.ToggleView
  | MFASceneDisabledTypes.AuthenticationDisableEnterCode
  | MFASceneDisabledTypes.AuthenticationIsDisabled;

type TitleSection = {
  text: string;
};

type SubTitleSection = {
  text: string;
};

type InputHintSection = {
  error: string;
  text_for_continue?: string;
};

export type MFAEnableComponent =
  | MFAEnableComponentOptions.MFASectionWrapper
  | MFAEnableComponentOptions.MFAEnableCopyClipboard
  | MFAEnableComponentOptions.MFAEnableVerifyCode
  | MFAEnableComponentOptions.MFAINeedHelp;

export type MFADisableComponent =
  | MFADisableComponentOptions.MFASectionWrapper
  | MFADisableComponentOptions.MFAEnterDisableCode
  | MFADisableComponentOptions.MFADisableINeedHelp;

export type MFAToggleComponent =
  | MFAEnableComponentOptions.MFAToggle
  | MFADisableComponentOptions.MFAToggle;

export type CommonScreenContext = {
  title: TitleSection;
  subtitle?: SubTitleSection;
  inputLabel?: string;
  inputHint?: InputHintSection;
  showINeedHelpLink?: boolean;
  isLastScreen?: boolean;
  continue_button?: {
    text: string;
    component: string;
    action: {
      type: MFAActionTypes;
    };
  };
};

export type ScreenContext = CommonScreenContext & {
  title: TitleSection;
  subtitle?: SubTitleSection;
  previous?: MFASceneOptionType;
  inputLabel?: string;
  inputHint?: InputHintSection;
  component: MFAEnableComponent;
  showINeedHelpLink?: boolean;
  isLastScreen?: boolean;
  continue_button?: {
    text: string;
    component: string;
    next?: MFASceneOptionType;
    action: {
      type: MFAActionTypes;
    };
  };
};

export type ScreenContextDisabled = CommonScreenContext & {
  title: TitleSection;
  subtitle?: SubTitleSection;
  previous?: MFASceneOptionDisabledType;
  inputLabel?: string;
  inputHint?: InputHintSection;
  component: MFADisableComponent;
  showINeedHelpLink?: boolean;
  isLastScreen?: boolean;
  continue_button?: {
    text: string;
    component: string;
    next?: MFASceneOptionDisabledType;
    action: {
      type: MFAActionTypes;
    };
  };
};

export type ScreenContextToggle = {
  inputLabel: string;
  component: MFAToggleComponent;
  continue_button: {
    text: string;
    component: string;
    next: MFASceneOptionDisabledType | MFASceneOptionType;
    action: {
      type: MFAActionTypes;
    };
  };
  // props expected to be undefined
  title?: undefined;
  subtitle?: undefined;
  previous?: undefined;
  inputHint?: undefined;
  showINeedHelpLink?: undefined;
  isLastScreen?: undefined;
};

export type MockMFAScenePropsType = {
  enable: {
    toggle_view: ScreenContextToggle;
    authentication_start: ScreenContext;
    authentication_download: ScreenContext;
    authentication_copy_code: ScreenContext;
    authentication_verify_code: ScreenContext;
    authentication_all_set_up: ScreenContext;
  };
  disabled: {
    toggle_view: ScreenContextToggle;
    authentication_disable_enter_code: ScreenContextDisabled;
    authentication_is_disabled: ScreenContextDisabled;
  };
};
