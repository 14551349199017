import storage from '@react-native-community/async-storage';
import { PersistConfig } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { PERSIST_BAN_LIST } from 'state/store/persist/banlist';

import { PlayerAction, PlayerActions } from './actions';

export enum PlayerStatus {
  PREPARING = 'PREPARING',
  READY = 'READY',
}

export enum PlaybackState {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZED = 'INITIALIZED',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export enum CaptionState {
  ON = 'ON',
  OFF = 'OFF',
  HIDDEN = 'HIDDEN',
}

export const Bar = '123';

export const authPersistConfig: PersistConfig<PlayerState> = {
  key: 'auth',
  storage,
  blacklist: PERSIST_BAN_LIST,
};

export interface PlayerState extends PersistPartial {
  status: PlayerStatus;
  controlsAreVisible: boolean;
  _persist: Readonly<{
    version: number;
    rehydrated: boolean;
  }>;
  playerState: PlaybackState;
  sceneSetRenderKey: number;
  controlsCloseButtonIsVisible: boolean;
  captionState: CaptionState;
  captionLanguage: string;
}

export const initialPlayerState: PlayerState = {
  status: PlayerStatus.PREPARING,
  _persist: {
    version: 0,
    rehydrated: false,
  },
  controlsAreVisible: true,
  playerState: PlaybackState.UNINITIALIZED,
  sceneSetRenderKey: 0,
  controlsCloseButtonIsVisible: true,
  captionState: CaptionState.ON,
  captionLanguage: 'en',
};

export const playerReducer = (
  state = initialPlayerState,
  action: PlayerAction
): PlayerState => {
  switch (action.type) {
    case PlayerActions.RESET: {
      return initialPlayerState;
    }
    case PlayerActions.SET_PLAYER_STATUS_READY: {
      return {
        ...state,
        status: PlayerStatus.READY,
      };
    }
    case PlayerActions.SHOW_CONTROLS: {
      return {
        ...state,
        controlsAreVisible: action.payload,
      };
    }
    case PlayerActions.SHOW_CONTROLS_CLOSE_BUTTON: {
      return {
        ...state,
        controlsCloseButtonIsVisible: action.payload,
      };
    }
    case PlayerActions.SET_PLAYER_STATE: {
      return {
        ...state,
        playerState: action.payload,
      };
    }
    case PlayerActions.INCREMENT_SCENESET_RENDER_KEY: {
      return {
        ...state,
        sceneSetRenderKey: state.sceneSetRenderKey + 1,
      };
    }
    case PlayerActions.TOGGLE_CAPTIONS: {
      if (
        state.captionState !== CaptionState.ON &&
        state.captionState !== CaptionState.OFF
      ) {
        return { ...state };
      }
      return {
        ...state,
        captionState:
          state.captionState === CaptionState.ON
            ? CaptionState.OFF
            : CaptionState.ON,
      };
    }
    case PlayerActions.SET_CAPTION_STATE: {
      return {
        ...state,
        captionState: action.payload,
      };
    }
    case PlayerActions.SET_CAPTION_LANGUAGE: {
      return {
        ...state,
        captionLanguage: action.payload,
      };
    }
    default:
      return state;
  }
};
