import React, { ReactElement, ReactNode, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Text as TextRaw } from 'react-native';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

import { Article, Category, LibraryScreen } from '@bighealth/types';

import Link from 'components/Link';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';

import { Entry } from '../components';
import { SkeletonOuterContainerAnimated } from '../components';

const HeadingContainer = styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TitleAndAuthorNameContainer = ({
  articleTitle,
  authorName,
}: {
  articleTitle: string;
  authorName: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const theme = useContext(ThemeContext);
  return (
    <View
      style={{
        width: '75%',
      }}
    >
      <Text
        style={{
          fontSize: styles.articleTitleFontSize,
          color: theme.color.overlay.primary,
        }}
      >
        {articleTitle}
      </Text>
      <Text
        style={{
          fontSize: styles.articleAuthorNameFontSize,
          paddingTop: styles.articleAuthorNamePaddingTop,
          color: theme.color.overlay.primary,
        }}
      >
        Written by: {authorName}
      </Text>
    </View>
  );
};

const AuthorPhotoContainer = ({
  photoUrl,
}: {
  photoUrl: string | undefined;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <View
      {...roles('ArticleAuthorPhotoImage')}
      style={{
        height: styles.articleAuthorPhotoWidth,
        marginRight: '5%',
      }}
    >
      <img
        title="Article author photo"
        src={photoUrl}
        height={styles.articleAuthorPhotoWidth}
        width={styles.articleAuthorPhotoWidth}
        style={{
          borderRadius: '50%',
        }}
      />
    </View>
  );
};

const MarkdownTextContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const theme = useContext(ThemeContext);
  return (
    <Text
      style={{
        fontSize: styles.articleFontSize,
        lineHeight: styles.articleLineHeight,
        color: theme.color.overlay.primary,
        width: '100%',
      }}
    >
      {children}
    </Text>
  );
};

export const TagsContainer = styled.View`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

const getLinkTag = ({
  tagType,
  tagText,
  content,
  productReference,
}: {
  tagType: 'section' | 'category' | 'tag';
  tagText: string;
  content?: LibraryScreen;
  productReference: string;
}): string | undefined => {
  let linkTo = undefined;

  if (content) {
    content.forEach(section => {
      if (tagType === 'section' && section.title === tagText) {
        linkTo = `/${productReference}/library/${section.slug}`;
      }
      if (tagType === 'category') {
        (section.content as Category[]).forEach(category => {
          if (category.heading === tagText) {
            // Navigate to the section and scroll to the category using the web anchor
            linkTo = `/${productReference}/library/${
              section.slug
            }#${category.heading.replace(/ /g, '_')}`;
          }
        });
      }
    });
  }
  return linkTo;
};

export const ArticleTag = ({
  text,
  tagType,
  color = '#1a80a2',
  backgroundColor = '#d8f6ff',
  content,
}: {
  text: string;
  tagType: 'section' | 'category' | 'tag';
  color?: string;
  backgroundColor?: string;
  content?: LibraryScreen;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { productReference } = useParams<{ productReference: string }>();

  const linkTo = getLinkTag({
    tagType: tagType,
    tagText: text,
    content: content,
    productReference: productReference,
  });

  return (
    <View
      style={{
        backgroundColor,
        marginRight: styles.entryTagMarginRight,
        marginBottom: styles.entryTagMarginBottom,
        paddingTop: styles.entryTagVerticalPadding,
        paddingBottom: styles.entryTagVerticalPadding,
        paddingLeft: styles.entryTagHorizontalPadding,
        paddingRight: styles.entryTagHorizontalPadding,
        overflow: 'hidden',
        borderRadius: 20,
      }}
    >
      {typeof linkTo === 'undefined' ? (
        <Text style={{ color, fontSize: styles.entryTagFontSize }}>{text}</Text>
      ) : (
        <Link to={linkTo} style={{ color, fontSize: styles.entryTagFontSize }}>
          {text}
        </Link>
      )}
    </View>
  );
};

export const ArticleContent = ({
  isLoading,
  article,
  content,
}: {
  isLoading?: boolean;
  article?: Article;
  content?: LibraryScreen;
}): ReactElement | null => {
  const styles = useGetDynamicContentStyles();
  const theme = useContext(ThemeContext);

  if (isLoading) {
    return <SkeletonOuterContainerAnimated />;
  }
  if (typeof article === 'undefined') {
    return null;
  }

  return (
    <Entry>
      <HeadingContainer>
        {typeof article.author_photo_asset_url !== 'undefined' &&
        article.author_photo_asset_url !== null &&
        article.author_photo_asset_url !== '' ? (
          <AuthorPhotoContainer photoUrl={article.author_photo_asset_url} />
        ) : null}
        <TitleAndAuthorNameContainer
          articleTitle={article.title}
          authorName={article.author_name}
        />
      </HeadingContainer>

      <MarkdownTextContainer>
        <ReactMarkdown>{article.markup_body_text}</ReactMarkdown>
      </MarkdownTextContainer>

      <TextRaw
        style={{
          color: theme.color.overlay.primary,
          fontSize: styles.articleFiledUnderTextFontSize,
          fontFamily: 'GT-Walsheim-Bold',
          fontWeight: 'bold',
          paddingBottom: styles.articleFiledUnderTextPaddingBottom,
        }}
      >
        Filed Under:
      </TextRaw>
      <TagsContainer>
        {article.sections.map(section => (
          <ArticleTag
            key={section}
            text={section}
            tagType={'section'}
            content={content}
          />
        ))}
        {article.categories.map(category => (
          <ArticleTag
            key={category}
            text={category}
            tagType={'category'}
            content={content}
          />
        ))}
        {article.tags.map(tag => (
          <ArticleTag key={tag} text={tag} tagType={'tag'} />
        ))}
      </TagsContainer>
    </Entry>
  );
  // );
};
