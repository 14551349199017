// See Zeplin for these values: https://app.zeplin.io/project/5ea89f6fa9c2e9255ee771d0

export const ModalStyles = {
  modalQuestionMarginBottom: 30, // Design is 40 but we need to accommodate margin bottom from inputs,

  skeletonLoaderCellHeightPx: 69,
  skeletonLoaderCellBorderRadiusPx: 4,
  skeletonLoaderCellMarginBottomPx: 10,
  skeletonLoaderTitleHeightPx: 19,
  skeletonLoaderMarginBottomPx: 21,
} as const;
