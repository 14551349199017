import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type OnCloseFunc = () => void;

export type ModalControlsState = {
  onClose?: OnCloseFunc;
  setCustomOnClose?: Dispatch<SetStateAction<OnCloseFunc | undefined>>;
};

type ModalControlsStateProviderProps = PropsWithChildren<{
  onClose: () => void;
}>;

const initialState: ModalControlsState = {};

export const ModalControlsStateContext = createContext(initialState);

export const useModalControlsStateContext = (): ModalControlsState =>
  useContext(ModalControlsStateContext);

export const ModalControlsStateProvider: FunctionComponent<ModalControlsStateProviderProps> = ({
  children,
  onClose,
}: ModalControlsStateProviderProps) => {
  const [customOnClose, setCustomOnClose] = useState<OnCloseFunc | undefined>(
    undefined
  );

  const onCloseHandler = useCallback(() => {
    if (typeof customOnClose === 'function') {
      customOnClose();
    } else {
      onClose();
    }
  }, [customOnClose, onClose]);

  const state = useMemo(
    () => ({
      onClose: onCloseHandler,
      setCustomOnClose,
    }),
    [onCloseHandler]
  );

  return (
    <ModalControlsStateContext.Provider value={state}>
      {children}
    </ModalControlsStateContext.Provider>
  );
};
