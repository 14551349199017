import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  LayoutRectangle,
  ScrollView as ScrollViewRN,
  ScrollViewProps,
} from 'react-native';
import _ from 'lodash';

import { FixedView, ScrollView } from './styled';

// For non-scrolling...
type ChildAsFunction<T> = {
  children(args: T): ReactNode;
};
type FixedChildArgs = Omit<ScrollerChildArgs, 'scrollToTarget'>; // i.e. always {}
type FixedProps = ChildAsFunction<FixedChildArgs>;
const Fixed = (props: FixedProps) => (
  <FixedView {...props}>{props.children({})}</FixedView>
);

// For scrolling...
/**
 * @see Scroller.md for usage instructions
 */
type ScrollerChildArgs = {
  scrollToTarget: (rect: LayoutRectangle) => void;
};
type ScrollerProps = ScrollViewProps & ChildAsFunction<ScrollerChildArgs>;

const Scroller = (props: ScrollerProps) => {
  const scrollRef = useRef<ScrollViewRN>(null);
  const [target, setTarget] = useState<LayoutRectangle | null>(null);
  useEffect(() => {
    if (!target) {
      return;
    }
    if (scrollRef.current?.scrollTo === undefined) {
      throw ReferenceError(`Expected scrollTo, instead got undefined`);
    }
    scrollRef.current.scrollTo({ x: target.x });
  }, [target]);
  const { children, ...restProps } = props;
  return (
    <ScrollView ref={scrollRef} {...restProps}>
      {children({
        scrollToTarget: setTarget,
      })}
    </ScrollView>
  );
};

// Chooses between non-scrolling and scrolling...
type HorizontalWrapperProps = (
  | ({ isScroll: false } & FixedProps)
  | ({ isScroll: true } & ScrollerProps)
) &
  ChildAsFunction<ScrollerChildArgs>;
export const HorizontalWrapper = (props: HorizontalWrapperProps) =>
  props.isScroll === true ? <Scroller {...props} /> : <Fixed {...props} />;
