import { Options } from './types';

export const getInteractionProps = <T,>({
  value,
  onSelect,
  setIsPressing,
  setIsHovering,
}: Options<T>) => ({
  onMouseEnter: () => {
    setIsHovering(true);
  },
  onMouseLeave: () => {
    setIsHovering(false);
    setIsPressing(false);
  },
  onMouseDown: () => {
    setIsPressing(true);
  },
  onMouseUp: () => {
    onSelect(value);
    setIsPressing(false);
  },
});
