export const getFitbitCallbackUri = (
  productReference: string,
  redirectScreen: string
): string => {
  // managing build hash
  const pathParts = window.location.pathname
    .split('/')
    .filter(part => part.length > 0);

  if (
    pathParts.length > 2 &&
    pathParts[0] === 'builds' &&
    pathParts[2] === productReference
  ) {
    // pathParts[1] is a build hash
    return `${window.location.origin}/${pathParts[0]}/${pathParts[1]}/${productReference}/${redirectScreen}?device=fitbit`;
  }

  return `${window.location.origin}/${productReference}/${redirectScreen}?device=fitbit`;
};
