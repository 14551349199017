import { DropdownItem } from 'components/generic-question/Dropdown';
import {
  AWAKENINGS_TOTAL_TIME,
  TIME_FINAL_AWAKENING,
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
  TO_FALL_ASLEEP_TOTAL_TIME,
} from 'components/SleepDiaryForm/constants';
import { DAY, HOUR } from 'lib/durations';
import { QuestionId } from 'state/question-response/actions';

import { getSelected } from '../../helpers/getSelected';
import { getSelectedAsNumber } from '../../helpers/getSelectedAsNumber';
import { Config } from '..';

import { createQuestionProps } from './helpers/createQuestionProps';
import { getDiaryDate } from './helpers/getDiaryDate';
import { isPreviousDate } from './helpers/isPreviousDate';

/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getFinalAwakeningFromTryToSleep = (
  inputs: Record<QuestionId, DropdownItem[]>,
  diaryDate?: Date
): Record<QuestionId, Config> | null => {
  const timeInBed = getSelected(inputs[TIME_INTO_BED])?.valueOf();
  const timeTryToSleep = getSelected(inputs[TIME_TRY_TO_SLEEP])?.valueOf();

  if (typeof timeTryToSleep !== 'number') {
    return null;
  }

  const selected: Record<QuestionId, number> = {
    [TIME_TRY_TO_SLEEP]: timeTryToSleep,
    [TO_FALL_ASLEEP_TOTAL_TIME]: getSelectedAsNumber(
      inputs,
      TO_FALL_ASLEEP_TOTAL_TIME
    ),
    [AWAKENINGS_TOTAL_TIME]: getSelectedAsNumber(inputs, AWAKENINGS_TOTAL_TIME),
  };

  const min = selected[TIME_TRY_TO_SLEEP];
  const max = min + 24 * HOUR;

  // if TIME_INTO_BED is less than TIME_TRY_TO_SLEEP than
  // TIME_INTO_BED is after 12AM and TIME_TRY_TO_SLEEP options start from same day as TIME_INTO_BED
  // instead of next day, for example TIME_INTO_BED=1970-01-02 00:00 and TIME_TRY_TO_SLEEP=1970-01-01 00:00
  const minDate = isPreviousDate(selected[TIME_TRY_TO_SLEEP], [timeInBed])
    ? selected[TIME_TRY_TO_SLEEP] + DAY
    : selected[TIME_TRY_TO_SLEEP];

  diaryDate = getDiaryDate(minDate, diaryDate);

  return {
    [TIME_FINAL_AWAKENING]: createQuestionProps(min, max, diaryDate),
  };
};

export { getFinalAwakeningFromTryToSleep };
