import React, { ReactElement, useState } from 'react';
import { ScrollView } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';

import { FillAbsolute } from 'components/Containers';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
  UniversalLinkButton,
} from 'components/UniveralButtons';
import { DEBUG_SHOW_DEV_CONTENT_SELECTOR } from 'config/envVars';
import { useHistory, useParams } from 'cross-platform/react-router';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { QA_MENU_STRINGS } from 'lib/showQAMenu/constants';
import { getQAOptions } from 'lib/showQAMenu/getQAOptions';

import { UserInfo } from './components/UserInfo';

const DebugInstructions = styled.Text`
  color: white;
  margin-bottom: 12px;
`;

const DebugTitle = styled(DebugInstructions)`
  font-size: 24px;
`;

const Container = styled(FillAbsolute)`
  align-items: center;
`;

const LimitWidth = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  max-width: 880px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
`;

const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  align-items: stretch;
  margin-bottom: 30px;
`;

const ForQATeamDebugMenu = (): ReactElement => {
  useUpdateTitle('Debug Menu');
  const { productReference } = useParams<{ productReference: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const getOptions = () => getQAOptions(productReference, history, dispatch);
  const [options, setOptions] = useState(getOptions());
  return (
    <Container>
      <LimitWidth>
        <ScrollView>
          <ButtonContainer>
            <DebugTitle>{QA_MENU_STRINGS['Menu name']}</DebugTitle>
            <UserInfo />
            <DebugInstructions>
              {QA_MENU_STRINGS['Instructions']}
            </DebugInstructions>
            {/** TODO: uncomment when Markdown component will be fixed */}
            {/* <DebugInstructions>
              <Markdown>
                {`
### Toggle options

**Problem**: ▣ will reset to ◻ if you use browser controls to navigate

e.g. If the url _manually_ changed, or back button clicked 

**Solution**: nav to sceneset or scene using "\`Goto /ssg/ss\`" option below`}
              </Markdown>
            </DebugInstructions> */}
          </ButtonContainer>
          {DEBUG_SHOW_DEV_CONTENT_SELECTOR
            ? options.map(option => (
                <ButtonContainer key={option.text}>
                  <UniversalButton
                    onPress={() => {
                      if (typeof option?.onPress !== 'undefined') {
                        option.onPress();
                      }
                      // Hacky re-rerender as QAConfigFlag's toggle via mutation
                      setOptions([...getOptions()]);
                    }}
                    text={option.text}
                  />
                </ButtonContainer>
              ))
            : null}
        </ScrollView>
        <ButtonContainer>
          <UniversalLinkButton
            text={'Back home'}
            to={`/${productReference}`}
            title={'Back Home'}
            colorScheme={ButtonColorSchemes.Login}
            size={ButtonSizes.Small}
          />
        </ButtonContainer>
      </LimitWidth>
    </Container>
  );
};

export { ForQATeamDebugMenu };
