import { Clock, Home, Moon } from 'components/icons';
import { createTabIcon } from 'components/Navigation/BottomNavigation/helpers/createTabIcon';
import { TabItemData } from 'components/Navigation/BottomNavigation/types';

export const sleepNowTabItem = {
  text: 'Sleep Now',
  screenRoute: 'sleep-now',
  Icon: createTabIcon({ Icon: Moon, displayName: 'MoonIcon' }),
  iconSize: 24,
};

export const sleepioTabItems: TabItemData[] = [
  {
    text: 'Home',
    screenRoute: 'home',
    Icon: createTabIcon({ Icon: Home, displayName: 'HomeIcon' }),
    iconSize: 22,
  },
  {
    text: 'Sleep Diary',
    screenRoute: 'sleep-diary',
    Icon: createTabIcon({ Icon: Clock, displayName: 'ClockIcon' }),
    iconSize: 22,
  },
  sleepNowTabItem,
];
