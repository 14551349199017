/* eslint-disable @typescript-eslint/no-explicit-any */
// "any" represents a React component prop
/**
 * Takes in a set of possible values  e.g.
 * [
 *  ["dog", "cat"],
 *  ["black", "white"],
 * ]
 *
 * will return:
 *
 * [
 *  ['dog', 'black'],
 *  ['dog', 'white'],
 *  ['cat', 'black'],
 *  ['cat', 'white'],
 * ]
 * @param sets of potential values
 */
const getSetPermutations = (sets: any[][]): any[] =>
  sets.reduce((prev1: any[], curr1: any[]) =>
    prev1.reduce(
      (prev2, curr2) => prev2.concat(curr1.map(el => [].concat(curr2, el))),
      []
    )
  );

export { getSetPermutations };
