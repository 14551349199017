import { find_with_reference } from '@bighealth/api/Product/v2';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { SECOND } from 'lib/durations';

export const queryClientProduct = async ({
  product_reference,
}: {
  product_reference: string;
}): Promise<find_with_reference.Response> => {
  const result = await getQueryClient().fetchQuery(
    find_with_reference.queryKey,
    () =>
      find_with_reference(
        {
          reference: product_reference,
        },
        { timeout: 10 * SECOND }
      ),
    {
      cacheTime: Infinity,
    }
  );
  return result;
};
