import React, { ReactElement } from 'react';
import { View } from 'react-native';
import styled, { useTheme } from 'styled-components/native';

import { DistributionPlatformList } from 'components/DistributionPlatformList';
import { useTransformContentStyle } from 'components/ResponsiveLayout';
import { Text } from 'components/Text';

const DOMAIN = 'MobileWebExperience';

const VerticalScroll = styled.ScrollView.attrs({
  vertical: true,
  persistentScrollbar: true,
  contentContainerStyle: { flexGrow: 1 },
})`
  width: 100%;
  flex: 1;
`;
VerticalScroll.displayName = `${DOMAIN}.VerticalScroll`;

export const MobileWebExperience = (): ReactElement => {
  const theme = useTheme();
  const scaleFn = useTransformContentStyle();

  return (
    <VerticalScroll>
      <View
        style={{
          flexBasis: '100%',
          flex: 1,
          backgroundColor: 'white',
          paddingTop: scaleFn(120),
          paddingLeft: scaleFn(72),
          paddingRight: scaleFn(120),
        }}
      >
        <Text
          style={{
            fontSize: scaleFn(48),
            color: theme.color.overlay.primary,
            fontWeight: '400',
          }}
        >
          Uh Oh!
        </Text>
        <Text
          style={{
            fontSize: scaleFn(32),
            color: theme.color.overlay.primary,
            fontWeight: '400',
            marginTop: scaleFn(36),
          }}
        >
          Looks like this page is unavailable on mobile web.
        </Text>
        <Text
          style={{
            fontSize: scaleFn(32),
            color: theme.color.overlay.primary,
            fontWeight: '400',
            marginTop: scaleFn(36),
          }}
        >
          Switch to a computer or the Sleepio App to get the full Sleepio
          Experience.
        </Text>
        <View
          style={{
            marginTop: scaleFn(60),
          }}
        >
          <DistributionPlatformList
            productName={'Sleepio'}
            showCaption={false}
          />
        </View>
      </View>
    </VerticalScroll>
  );
};
