/* eslint-disable @typescript-eslint/no-explicit-any */
export function throwIfMissingEnvVar(
  envVarName: string,
  envVarValue?: string
): never | string {
  if (typeof envVarValue === 'string') {
    return envVarValue;
  }
  throw Error(`
❌ Missing environment variable \`${envVarName}\`.
If you're running locally, add it to your .env file in this package's root and run \`yarn update-env\` to clear caches.
If you're running in CI, add this to CI's environment variables.\n`);
}
