import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { RoleProps } from 'cross-platform/utils/roleProps';

import { IconStyles } from './types';

export const ControlsPlay = ({
  size,
  style,
  ...roleProps
}: {
  size: number;
  style?: IconStyles;
} & RoleProps): ReactElement => {
  return (
    <Svg
      {...roleProps}
      width={size}
      height={size}
      viewBox="0 0 56 56"
      style={style}
    >
      <G stroke="none" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0ZM21.3332 38L35.4999 28L21.3332 18V38Z"
          fill={style?.color || 'currentColor'}
        />
      </G>
    </Svg>
  );
};
