import React, { ReactElement } from 'react';
import { Defs, LinearGradient, Rect, Stop, Svg } from 'react-native-svg';
import styled from 'styled-components/native';

import { RoleProps } from 'cross-platform/utils/roleProps';

import { BackgroundColors, BackgroundProps } from './types';

const GradientSvg = styled(Svg)`
  width: 100%;
  height: 100%;
`;
GradientSvg.displayName = 'Sessions.GradientSvg';

const GradientContainer = styled.View`
  width: 100%;
  align-items: center;
`;
GradientContainer.displayName = 'Sessions.GradientContainer';

export const getLinearGradient = (
  backgroundColors: BackgroundColors
): ReactElement => {
  const sectionSize = 100 / (backgroundColors.length - 1);
  return (
    <LinearGradient id="value-fill" gradientTransform="rotate(90)">
      {backgroundColors.map((color, i) => (
        <Stop
          key={i}
          stopColor={color}
          offset={`${
            i + 1 === backgroundColors.length ? 100 : i * sectionSize
          }%`}
        />
      ))}
    </LinearGradient>
  );
};

export const GradientBackground = ({
  children,
  style = {},
  backgroundColors,
  ...rest
}: BackgroundProps & RoleProps): ReactElement | null => {
  return (
    <GradientContainer {...rest} style={style}>
      <GradientSvg
        height="100%"
        width="100%"
        viewBox={`0 0 100 100`}
        preserveAspectRatio="none"
      >
        <Defs>{getLinearGradient(backgroundColors)}</Defs>
        <Rect x={0} y={0} width="100%" height="100%" fill="url(#value-fill)" />
      </GradientSvg>
      {children}
    </GradientContainer>
  );
};
