import { Caption } from '@bighealth/types';

import { DomainIDs } from 'common/constants/enums';

import { GlobalState } from '../store/initialState';

import { Asset } from './reducer';

export const getAssetsLeftToDownload = (state: GlobalState): Asset[] =>
  state[DomainIDs.DOWNLOADER].remoteAssetsToDownload;

export const getLocalURIForRemoteURL = (
  remoteURIToTest: string | undefined
) => (state: GlobalState): string | undefined => {
  const cachedAssets = state[DomainIDs.DOWNLOADER].locallyCachedAssets;
  const asset = cachedAssets.find(
    ({ remoteURI }) => remoteURI === remoteURIToTest
  );
  if (asset) {
    return asset.localURI;
  }
  return undefined;
};

export const getIsAssetInCache = (remoteURIToTest: string) => (
  state: GlobalState
): boolean => {
  const cachedAssets = state[DomainIDs.DOWNLOADER].locallyCachedAssets;
  return !!cachedAssets.find(({ remoteURI }) => remoteURI === remoteURIToTest);
};

export const getAssetsInCache = (state: GlobalState): Asset[] => {
  return state[DomainIDs.DOWNLOADER].locallyCachedAssets;
};

export const getLocalCaptions = (captions?: Caption[]) => (
  state: GlobalState
): Caption[] | undefined => {
  const localCaptions = [];
  const cachedAssets = state[DomainIDs.DOWNLOADER].locallyCachedAssets;

  if (!Array.isArray(captions) || captions?.length <= 0) {
    return [];
  }

  for (const caption of captions) {
    const asset = cachedAssets.find(
      ({ remoteURI }) => remoteURI === caption.storage_url
    );

    if (asset) {
      localCaptions.push({
        ...caption,
        storage_url: asset.localURI as string,
      });
    }
  }

  return localCaptions;
};
