import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { DiaryEntry } from '@bighealth/types/src/services/SleepDiaryPayloads/types';
import { AxiosRequestConfig } from 'axios';

export const create_baseline_entities = async (
  args: create_baseline_entities.Args,
  requestConfig?: AxiosRequestConfig
): Promise<create_baseline_entities.Response> => {
  const request: create_baseline_entities.Request = {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'create_baseline_entities',
    args,
  };

  return await api(request, requestConfig);
};

create_baseline_entities.queryKey = 'SleepDiary/create_baseline_entities';

export declare namespace create_baseline_entities {
  export type Args = Omit<DiaryEntry, 'diary_date'>;

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'create_baseline_entities';
    args: Args;
  }

  export type Result = Omit<DiaryEntry, 'diary_date'>;

  export type Response = APIResponse<Result>;
}
