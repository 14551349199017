import React, { ReactElement } from 'react';

import { ContentScreen } from '@bighealth/types';

import { FillAbsoluteCenter } from 'components/Containers';

import { NavContainer } from './components';
import { isNonEmptyPageData } from './helpers';
import { NavItem } from './NavItem';

export const ContentNav = ({
  navigation,
  isLoading,
}: {
  isLoading?: boolean;
  navigation?: ContentScreen;
}): ReactElement | null => {
  if (isLoading) {
    // the global throbber will be rendered
    return <FillAbsoluteCenter style={{ backgroundColor: 'white' }} />;
  }
  if (!navigation) {
    return null;
  }
  if (!isNonEmptyPageData(navigation)) {
    // This should never happen as user shouldn't be able to get to this part of the content but
    // we'll make it type safe anyway.
    return null;
  }
  return (
    <NavContainer>
      {navigation.map(({ slug, title, ...rest }) => (
        <NavItem {...rest} key={slug} slug={slug} title={title} />
      ))}
    </NavContainer>
  );
};
