import React, { ReactElement, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components/native';

import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { BackIcon } from 'daylight/components/icons/BackIcon';
import { useTransformContentStyle } from 'daylight/hooks';
import { sendRequestForForgotPassword } from 'lib/api';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import * as reporter from 'lib/reporter';

import { EmailSentConfirmation } from './components/EmailSentConfirmation';
import { PasswordResetDirections } from './components/PasswordResetDirections';
import {
  BackButtonContainer,
  PaddedContainer,
} from './ForgotPasswordScreen.styled';

export const ForgotPasswordScreen = (): ReactElement => {
  const [isEmailSent, setIsEmaiSent] = useState<boolean>(false);
  const transformStyle = useTransformContentStyle();
  const history = useHistory();
  const productId = useQueryProduct()?.data?.result.id;
  const theme = useContext(ThemeContext);

  const handleSubmit = async (
    email: string,
    isSent: boolean
  ): Promise<void> => {
    setIsEmaiSent(isSent);
    try {
      // TODO in https://bighealth.atlassian.net/browse/DP-1175
      await sendRequestForForgotPassword(email, productId as number);
    } catch (e) {
      reporter.log(e.message, e, { silent: true });
    }
  };
  return (
    <PaddedContainer>
      <BackButtonContainer testID="back-button-container">
        <BackIcon
          size={transformStyle(24.5)}
          color={theme.color.text.placeholder || theme.color.primaryGrey}
          onPress={() => history.goBack()}
        />
      </BackButtonContainer>
      {!isEmailSent ? (
        <PasswordResetDirections onSubmit={handleSubmit} />
      ) : (
        <EmailSentConfirmation {...roles('email-sent-confirmation')} />
      )}
    </PaddedContainer>
  );
};
