import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { IconStyles } from './types';

export const Clock = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 23 22" style={style}>
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          fill={style?.color || 'currentColor'}
          d="M11.5 0C5.42487 0 0.5 4.92487 0.5 11C0.5 17.0751 5.42487 22 11.5 22C17.5751 22 22.5 17.0751 22.5 11C22.5 4.92487 17.5751 0 11.5 0ZM12.4167 4.58333C12.4167 4.07707 12.0063 3.66667 11.5 3.66667C10.9937 3.66667 10.5833 4.07707 10.5833 4.58333V11.9167C10.5833 12.3913 10.944 12.7817 11.4063 12.8286C11.4371 12.8317 11.4684 12.8333 11.5 12.8333H17C17.5063 12.8333 17.9167 12.4229 17.9167 11.9167C17.9167 11.4104 17.5063 11 17 11H12.4167V4.58333Z"
        />
      </G>
    </Svg>
  );
};
