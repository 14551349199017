import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryQuestionSet } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQueryQuestionSet';

export const ProgressViewRole = 'ProgressView';
export const ProgressTextRole = 'ProgressText';
export const ProgressIndicator = ({
  questionToQuestionSet,
  latestQuestionId,
  backgroundColor,
  isHidden = false,
}: {
  questionToQuestionSet: Record<number, number[]>;
  latestQuestionId: number;
  backgroundColor?: string;
  isHidden?: boolean;
}): ReactElement | null => {
  const theme = useContext(ThemeContext);

  const [position, setPosition] = useState<number>();

  const questionSetId =
    (latestQuestionId &&
      questionToQuestionSet[latestQuestionId]?.find(Boolean)) ??
    0;

  const questionSet = useQueryQuestionSet(questionSetId).data?.result
    ?.question_ids;

  useEffect(() => {
    if (questionSet && latestQuestionId) {
      const currentPositionIndex = questionSet
        ? questionSet.indexOf(latestQuestionId)
        : -1;

      if (currentPositionIndex >= 0) {
        setPosition(currentPositionIndex + 1);
      }
    }
  }, [latestQuestionId, questionSet]);

  if (isHidden || !questionSet || !questionSet.length) {
    return null;
  }

  if (latestQuestionId && questionSet?.length > 1) {
    return (
      <View
        {...roles(ProgressViewRole)}
        style={{
          alignSelf: 'center',
          alignItems: 'center',
          paddingTop: 15,
          backgroundColor: backgroundColor || theme.color.white,
          width: '100%',
        }}
      >
        <Text
          {...roles(ProgressTextRole)}
          style={{
            fontSize: 16,
            color: theme.modal.input.progressIndicator.textColor,
          }}
        >
          {position} of {questionSet?.length}
        </Text>
      </View>
    );
  } else {
    return null;
  }
};
