import {
  MFASceneDisabledTypes,
  MFASceneOptionDisabledType,
  MFASceneOptionType,
  MFASceneTypes,
} from '../types';

// Check if Next / Previous passed in was 1 of the options for
//  the Enable Workflow.
export function isEnableFlow(
  navigateSection: MFASceneOptionType | MFASceneOptionDisabledType
): navigateSection is MFASceneOptionType {
  return [...Object.values(MFASceneTypes)].includes(
    (navigateSection as unknown) as MFASceneTypes
  );
}

export function isDisableFlow(
  navigateSection: MFASceneOptionType | MFASceneOptionDisabledType
): navigateSection is MFASceneDisabledTypes {
  return [...Object.values(MFASceneDisabledTypes)].includes(
    (navigateSection as unknown) as MFASceneDisabledTypes
  );
}
