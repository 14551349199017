import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { useTransformContentStyle } from 'components/ResponsiveLayout';

import { AppStoreButton } from './AppStoreButton';
// import { GooglePlayButton } from './GooglePlayButton';

/**
 * With "Distribution Platform" we define the platforms where the
 * mobile software applications are released and distributed to the wild.
 * Examples of distribution platforms are "App Store" and "Google Play"
 */
export const DistributionPlatformList = ({
  productName,
  showCaption,
}: {
  productName?: string;
  showCaption?: boolean;
}): React.ReactElement => {
  const product = productName || 'Sleepio';
  const scaleFn = useTransformContentStyle();
  const theme = useContext(ThemeContext);

  // TODO: read this URL from config based on the product
  const urlAppStore =
    'https://apps.apple.com/us/app/sleepio-platgen-prod/id1538191784';

  // TODO: read this URL from config based on the product
  // SLEEPIO-1458 temporary removal of Google Play button
  // const urlGooglePlay =
  //   'http://play.google.com/store/apps/details?id=com.bighealth.sleepio';

  return (
    <>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          marginBottom: scaleFn(34),
        }}
      >
        {/*
        SLEEPIO-1458 temporary removal of Google Play button

        <GooglePlayButton
          height={scaleFn(94)}
          width={scaleFn(320)}
          url={urlGooglePlay}
        /> */}
        <AppStoreButton
          height={scaleFn(94)}
          width={scaleFn(294)}
          url={urlAppStore}
        />
      </View>

      {showCaption ? (
        <View style={{ width: '73%' }}>
          <Text
            style={{
              fontSize: 16,
              color: theme.color.overlay.primary,
              textAlign: 'center',
            }}
          >
            {`Switch to a computer or the ${product} App to get the full ${product} Experience.`}
          </Text>
        </View>
      ) : null}
    </>
  );
};
