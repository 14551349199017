import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { useSafeParams } from 'components/Routes/useSafeParams';
import useHistory from 'cross-platform/react-router/useHistory';
import { isResultMobileWeb } from 'lib/utils/guards/isResultMobileWeb';
import * as sleepDiaryModalSelectors from 'state/sleep-diary-modal/selectors';
import { setIsFirstInit } from 'state/user/actions';
import { getIsFirstInit } from 'state/user/selectors';

export const useProgressReviewRedirect = (
  data?: get_user_session_screen_data.Result
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { productReference } = useSafeParams();
  const getIsRenderedUseProgressReviewRedirect = useSelector(getIsFirstInit);
  const sleepDiaryModalIsVisible = useSelector(
    sleepDiaryModalSelectors.getIsVisisble
  );

  useEffect(() => {
    if (
      typeof data !== 'undefined' &&
      !isResultMobileWeb(data) &&
      !sleepDiaryModalIsVisible &&
      getIsRenderedUseProgressReviewRedirect
    ) {
      if (data.progress_review_data) {
        dispatch(setIsFirstInit(false));
        history.push(
          `/${productReference}/${data.progress_review_data.action.payload.sceneSetGraphId}/${data.progress_review_data.action.payload.destinationSceneSetId}?bypass_ss_validation`
        );
      }
    }
  }, [
    data,
    dispatch,
    history,
    getIsRenderedUseProgressReviewRedirect,
    productReference,
    sleepDiaryModalIsVisible,
  ]);
};
