import { useEffect, useRef } from 'react';

// In react we cannot simple use setInterval in useEffect because it will create
// a new interval every time the component re-renders, which is why we need to
// create a custom hook to handle this. See Dan Abramov's blog post for more info:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
