import { format } from 'date-fns';

import { ButtonResourceEvent } from '@bighealth/analytics';

import { UIEventCreator } from 'lib/api/reactQueryHelpers/useLazyQueries/useLazyQueryFireUIEvent';

import { AREA } from './constants';

export const dailyButtonEventFactory = (eventArgs: {
  date: Date;
  isOn: boolean;
  id: string;
}): UIEventCreator =>
  async function uiEventCreator(context): Promise<[ButtonResourceEvent]> {
    const time = eventArgs?.date
      ? format(eventArgs.date, 'HH:mm')
          .split(':')
          .join('_')
      : 'none';
    const nameEnd = eventArgs.isOn ? `set-${time}` : 'cancel';
    return [
      {
        occurred_at: context.occurredAt,
        product_id: context.productId,
        program_id: context.programId,
        action: 'clicked',
        name: `button|clicked|${AREA}|${nameEnd}`,
        resource: 'button',
        version: '1.0.0',
        type: 'ui',
        body: {
          fields: {
            feature: {
              area: AREA,
              section: 'daily', // Bad place, but meh
            },
            component: 'tab', // Meaningless; Type requirement
            component_state: 'active', // Meaningless; Type requirement
          },
          relations: {
            scene_set_graph: {
              id: context.sceneSetGraphId,
            },
          },
          meta: {
            http: {
              current_url: context.url.href,
              referrer: context.url.href,
              user_agent: context.userAgent,
              client_app_node_package_version:
                context.clientAppNodePackageVersion,
              product_stream_version: context.productVersionStream,
            },
          },
        },
      },
    ];
  };
