import { useMutation, UseMutationResult } from 'react-query';

import { disable_otp } from '@bighealth/api/UserAccountMFA/v1';

export type UseMutationMFADisableResponse = UseMutationResult<
  disable_otp.Response,
  unknown,
  string,
  unknown
>;

export const useMutationMFADisable = (): UseMutationMFADisableResponse => {
  const result = useMutation({
    mutationFn: (otp: string) => disable_otp({ otp: otp }),
  });
  return result;
};
