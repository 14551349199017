import React, { useContext } from 'react';
import { ReactElement, ReactNode, useState } from 'react';
import { View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

import { Footer } from 'components/Footer';
import { Goals } from 'components/Goals';
import { Chevron } from 'components/icons';
import {
  ScalingContext,
  ScalingContextProvider,
  useGetDynamicContentStyles,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import {
  convertXToUnits,
  convertYToUnits,
  getPaddedChartDataFromAPIData,
} from 'components/Screens/ContentScreens/ProgressScreen/utils/dataUtils';
import { roles } from 'cross-platform/utils/roleProps';
import { useQuerySleepEfficiency } from 'lib/api/reactQueryHelpers';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

import { ContentContainer, PageSubTitle, PageTitle } from '../components';
import { ScreenScroll } from '../components/ScreenScroll';
import { useGetPageDimensions } from '../hooks';

import { LineChart } from './LineChart';
import { SleepEfficiencyScreenStyles } from './styles';

const ChartContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const transformStyle = useTransformContentStyle();

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'stretch',
        marginTop: transformStyle(40),
        marginBottom: transformStyle(90),
      }}
    >
      {children}
    </View>
  );
};

const Button = styled.TouchableOpacity`
  background-color: #1a80a222;
`;

const ButtonView = styled.View`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const REFERENCE_WIDTH = SleepEfficiencyScreenStyles.referenceWidth;

const DynamicButton = ({
  testID,
  marginDirection,
  disabled,
  onPress,
  icon,
}: {
  disabled?: boolean;
  testID: string;
  onPress: () => void;
  marginDirection: 'right' | 'left';
  icon: 'right' | 'left';
}) => {
  const { pageContentWidth } = useGetPageDimensions();

  const theme = useContext(ThemeContext);
  const buttonWidth =
    (SleepEfficiencyScreenStyles.buttonWidth * pageContentWidth) /
    REFERENCE_WIDTH;

  const buttonMargin =
    (SleepEfficiencyScreenStyles.buttonOffset * pageContentWidth) /
    REFERENCE_WIDTH;

  const iconSize =
    (SleepEfficiencyScreenStyles.buttonIconSize * pageContentWidth) /
    REFERENCE_WIDTH;

  const style = {
    width: buttonWidth,
    opacity: disabled ? 0 : 1,
    marginRight: marginDirection === 'right' ? buttonMargin : 0,
    marginLeft: marginDirection === 'left' ? buttonMargin : 0,
  };

  return (
    <Button
      {...roles(testID)}
      style={style}
      disabled={disabled}
      onPress={onPress}
    >
      <ButtonView>
        <Chevron
          size={iconSize}
          direction={icon}
          style={{ color: theme.color.overlay.primary }}
        />
      </ButtonView>
    </Button>
  );
};

const LineContainer = styled.View`
  flex: 1;
`;

export const ProgressScreen = (): ReactElement => {
  useUpdateTitle('Progress');
  const contentStyles = useGetDynamicContentStyles();
  const { pageContentWidth } = useGetPageDimensions();
  const [page, setPage] = useState(0);

  const { data } = useQuerySleepEfficiency({
    items_per_page: 6,
    page,
  });

  const canGoForwardInTime = data?.result.has_next;
  const canGoBackInTime = data?.result.has_prev;
  const itemsPerPage = data?.result.items_per_page;

  const chartData = getPaddedChartDataFromAPIData(data?.result);

  return (
    <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
      <ScreenScroll
        contentContainerStyle={{
          alignItems: 'center',
        }}
      >
        <ContentContainer
          style={{
            flex: 1,
            width: pageContentWidth,
            paddingBottom: contentStyles.pagePaddingBottom,
          }}
        >
          <PageTitle>Progress</PageTitle>
          <PageSubTitle>Your Average Sleep Efficiency</PageSubTitle>
          <ChartContainer>
            <DynamicButton
              testID="PrevButton"
              onPress={() => setPage(page + 1)}
              disabled={!data?.result || !canGoBackInTime}
              marginDirection="right"
              icon="left"
            />
            <LineContainer>
              <LineChart
                maxY={100}
                data={chartData}
                convertXToUnits={convertXToUnits}
                convertYToUnits={convertYToUnits}
                lineColor="#ff6c00"
                lineStrokeWidth={2}
                ruleColor="#1a80a2"
                ruleStrokeWidth={1}
                xTicksCount={itemsPerPage || 6}
                yTicksCount={5}
                markerRadius={6}
                horizontalPadding={100}
                baseWidth={REFERENCE_WIDTH}
                baseHeight={300}
              />
            </LineContainer>
            <DynamicButton
              testID="NextButton"
              onPress={() => setPage(page - 1)}
              disabled={page === 0 || !canGoForwardInTime}
              marginDirection="left"
              icon="right"
            />
          </ChartContainer>
          <PageSubTitle>Your Goal Progress Since Starting</PageSubTitle>
          <Goals />
        </ContentContainer>
        <Footer />
      </ScreenScroll>
    </ScalingContextProvider>
  );
};
