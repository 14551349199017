import { openURLFunc } from './types';

const openNewTab = (url: string, target: string): void => {
  const newWindow = window.open(url, target);

  if (newWindow !== null) {
    newWindow.focus();
  }
};

const openNewURL = (url: string): void => {
  window.location.href = url;
};

export const openURL: openURLFunc = (url, options) => {
  const { target = '_blank', newTab = false } = options?.webOptions || {};

  try {
    if (newTab) {
      openNewTab(url, target);
    } else {
      openNewURL(url);
    }
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
