import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';

import { roles } from 'cross-platform/utils/roleProps';
const flatten = StyleSheet.flatten;

/**
  @TODO(JA): give this the same preload/caching ability of FastImage
  WHY: we will want preloading images for the web to avoid flashing there too
  WHEN: when the roadmap requires it
*/

const Image = ({
  style,
  fit,
  alt,
  src,
  ...rest
}: React.ImgHTMLAttributes<HTMLImageElement>): ReactElement => {
  const flattenedStyles = flatten(style);
  const moddedStyles: React.CSSProperties = {
    ...flattenedStyles,
    objectFit: fit,
  };
  return (
    <img
      {...roles.filterNative(roles.pass(rest))}
      src={src}
      style={moddedStyles}
      alt={alt}
      alt-text={alt}
    />
  );
};

export default Image;
