import React, { ReactElement } from 'react';
import { ScrollView } from 'react-native';
import { AuthorizationStatus } from '@notifee/react-native';
import { format } from 'date-fns';

import { VisualState } from 'components/LocalNotifications/NotificationsView';
import { eventFactory } from 'components/LocalNotifications/NotificationsView/utils/eventFactory';
import { dailyButtonEventFactory } from 'components/LocalNotifications/NotificationsView/utils/scheduled-to-remove-FEG_289/dailyButtonEventFactory';
import { permissionScreenEventFactory } from 'components/LocalNotifications/NotificationsView/utils/scheduled-to-remove-FEG_289/permissionScreenEventFactory';
import { visualStateScreenEventFactory } from 'components/LocalNotifications/NotificationsView/utils/scheduled-to-remove-FEG_289/visualStateScreenEventFactory';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
  UniversalLinkButton,
} from 'components/UniveralButtons';
import { useParams } from 'cross-platform/react-router';
import { useLazyQueryFireUIEvent } from 'lib/api/reactQueryHelpers/useLazyQueries/useLazyQueryFireUIEvent';
import { useStateApi } from 'lib/useStateApi/useStateApi';

import {
  ButtonContainer,
  Container,
  DebugInstructions,
  LimitWidth,
} from '../ForQA/styled';

const AUTH_STATUS_LIST = [
  AuthorizationStatus.NOT_DETERMINED,
  AuthorizationStatus.DENIED,
  AuthorizationStatus.AUTHORIZED,
  AuthorizationStatus.PROVISIONAL,
  'unknown',
];
const ForQATeamAnalytics = (): ReactElement => {
  const { productReference } = useParams<{ productReference: string }>();
  const fireUIEvent = useLazyQueryFireUIEvent();
  const viewStateLabel = VisualState.A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT;
  const nextAuthStatus = useStateApi(0);
  const isOn = useStateApi(false);
  return (
    <Container>
      <LimitWidth>
        <ScrollView>
          <ButtonContainer>
            <UniversalButton
              text={`Toggle boolean to ${!isOn.value}`}
              colorScheme={ButtonColorSchemes.LinkInverse}
              size={ButtonSizes.Small}
              onPress={() => {
                isOn.set(!isOn.value);
              }}
            />
          </ButtonContainer>
          <LimitWidth>
            <ButtonContainer>
              <UniversalButton
                colorScheme={ButtonColorSchemes.Login}
                onPress={() => {
                  fireUIEvent(
                    dailyButtonEventFactory({
                      isOn: isOn.value,
                      id: 'notificationGroupConfigId',
                      date: new Date(),
                    })
                  );
                }}
                text={`dailyButtonEventFactory: ${isOn.value}`}
              />
            </ButtonContainer>
            <ButtonContainer>
              <DebugInstructions>{`permission state ${1 +
                nextAuthStatus.value} of ${
                AUTH_STATUS_LIST.length
              } (PRESS TO TRY NEXT)`}</DebugInstructions>
              <UniversalButton
                colorScheme={ButtonColorSchemes.Login}
                onPress={() => {
                  fireUIEvent(
                    permissionScreenEventFactory(nextAuthStatus.value)
                  );
                  nextAuthStatus.set(
                    nextAuthStatus.value === AUTH_STATUS_LIST.length - 1
                      ? 0
                      : nextAuthStatus.value + 1
                  );
                }}
                text={`permission ${nextAuthStatus.value + 1}`}
              />
            </ButtonContainer>
            <ButtonContainer>
              <UniversalButton
                colorScheme={ButtonColorSchemes.Login}
                onPress={() => {
                  fireUIEvent(visualStateScreenEventFactory(viewStateLabel));
                }}
                text={'visualState'}
              />
            </ButtonContainer>
            <ButtonContainer>
              <UniversalButton
                colorScheme={ButtonColorSchemes.Login}
                onPress={() => {
                  fireUIEvent(
                    eventFactory.notification(
                      {
                        action: 'user-action',
                        fields: {
                          value: 'os-prompt',
                          app_notification_settings: {
                            is_on: isOn.value,
                          },
                          notif_feature_flag: isOn.value,
                          os_notification_authorization_status: 'AUTHORIZED',
                        },
                      },
                      'open-os-settings-screen'
                    )
                  );
                }}
                text={`CUSTOM-open-os-settings-screen: ${!!isOn.value}`}
              />
            </ButtonContainer>
            <ButtonContainer>
              <UniversalButton
                colorScheme={ButtonColorSchemes.Login}
                onPress={() => {
                  fireUIEvent(
                    eventFactory.notification({
                      action: 'user-action',
                      fields: {
                        value: 'os-prompt',
                        app_notification_settings: {
                          is_on: isOn.value,
                        },
                        notif_feature_flag: isOn.value,
                        os_notification_authorization_status: 'AUTHORIZED',
                      },
                    })
                  );
                }}
                text={`user-action|os-prompt: ${!!isOn.value}`}
              />
            </ButtonContainer>
            <ButtonContainer>
              <UniversalButton
                colorScheme={ButtonColorSchemes.Login}
                onPress={() => {
                  fireUIEvent(
                    eventFactory.notification({
                      action: 'user-action',
                      fields: {
                        value: 'app-notif-time-is-updated',
                        app_notification_settings: {
                          is_on: isOn.value,
                          scheduled_time_in_24h: format(new Date(), 'HH:mm'),
                        },
                        notif_feature_flag: isOn.value,
                        os_notification_authorization_status: 'AUTHORIZED',
                      },
                    })
                  );
                }}
                text={`app-notif-time-is-updated (+TIME):${!!isOn.value}`}
              />
            </ButtonContainer>
            <ButtonContainer>
              <UniversalButton
                colorScheme={ButtonColorSchemes.Login}
                onPress={() => {
                  fireUIEvent(
                    eventFactory.notification({
                      action: 'user-action',
                      fields: {
                        value: 'app-notif-time-is-updated',
                        app_notification_settings: {
                          is_on: isOn.value,
                        },
                        notif_feature_flag: isOn.value,
                        os_notification_authorization_status: 'AUTHORIZED',
                      },
                    })
                  );
                }}
                text={`app-notif-time-is-updated (CANCEL):${!!isOn.value}`}
              />
            </ButtonContainer>
          </LimitWidth>
          <ButtonContainer>
            <UniversalLinkButton
              text={'Back home'}
              to={`/${productReference}`}
              title={'Back Home'}
              colorScheme={ButtonColorSchemes.LinkInverse}
              size={ButtonSizes.Small}
            />
          </ButtonContainer>
        </ScrollView>
      </LimitWidth>
    </Container>
  );
};

export { ForQATeamAnalytics };
