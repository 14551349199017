import { useMutation, UseMutationResult } from 'react-query';

import { auth_poll } from '@bighealth/api/ClientGateway/v1';

export type UseMutationAuthPollResponse = UseMutationResult<
  auth_poll.Response,
  unknown,
  void,
  unknown
>;

export const useMutationAuthPoll = (): UseMutationAuthPollResponse => {
  const result = useMutation({
    mutationFn: () => auth_poll(),
  });
  return result;
};
