import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { setPlayerState } from 'state/player/actions';
import { PlaybackState } from 'state/player/reducer';

import { mediaPlayerOrchestrator } from './MediaPlayerOrchestrator';
import { toggleVisibilityOrchestrator } from './ToggleVisibilityOrchestrator';

type Callbacks = {
  play: () => Promise<void>;
  pause: () => void;
};

export const usePlayPauseMedia = (): Callbacks => {
  const dispatch = useDispatch();
  const play = useCallback(async () => {
    mediaPlayerOrchestrator.releasePlayLock();
    toggleVisibilityOrchestrator.releasePlayLock();
    await mediaPlayerOrchestrator.play();
    await toggleVisibilityOrchestrator.play();
    dispatch(setPlayerState(PlaybackState.PLAYING));
  }, [dispatch]);

  const pause = useCallback(() => {
    mediaPlayerOrchestrator.pause();
    toggleVisibilityOrchestrator.pause();
    mediaPlayerOrchestrator.setPlayLock();
    toggleVisibilityOrchestrator.setPlayLock();
    dispatch(setPlayerState(PlaybackState.PAUSED));
  }, [dispatch]);
  return useMemo(
    () => ({
      play,
      pause,
    }),
    [pause, play]
  );
};
