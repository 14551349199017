import { useCallback } from 'react';

import { useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';

type Callback = (args: { destination: string }) => Promise<void>;

export const useGoTo = (): Callback => {
  const history = useHistory();
  const { productReference } = useSafeParams();
  return useCallback(
    async ({ destination }) => {
      if (destination.startsWith('/')) {
        const errorStr = `"go_to" payload.destination cannot start with "/", got: "${destination}"`;
        throw Error(errorStr);
      }
      history.push(`/${productReference}/${destination}`);
    },
    [history, productReference]
  );
};
