import { uniq } from 'ramda';

import { GoalReferences, SceneChild, SceneSet } from '@bighealth/types';
import { Scene } from '@bighealth/types/dist/scene-component';

import { ProgressGoalsV2Props } from 'components/sceneset-components/ProgressGoalsV2';
import * as reporter from 'lib/reporter';

const KNOWN_GOALS: GoalReferences[] = [
  'daytime_goal',
  'emotional_goal',
  'overall_goal',
];

/**
 * Returns if the component of a given type contains a downloadable asset
 * @param componentName
 * @param src
 */
export const canComponentRenderAsset = (
  componentName: string,
  src: string
): boolean =>
  Boolean(['Video', 'Audio', 'Image'].includes(componentName) && src);

type SceneChildWithSourceProps = SceneChild & {
  sourceProps: Scene.Utils.ClientMediaSourceProps;
};

type SceneChildProgressGoalsV2Props = SceneChild & ProgressGoalsV2Props;

type Leaf =
  | SceneChild
  | SceneChildWithSourceProps
  | SceneChildProgressGoalsV2Props;

const guard = {
  isSceneChildWithSourceProps: (
    leaf: Leaf
  ): leaf is SceneChildWithSourceProps =>
    'sourceProps' in leaf &&
    typeof leaf.sourceProps === 'object' &&
    leaf.sourceProps !== null,
  isSceneChildProgressGoalsV2Props: (
    leaf: Leaf
  ): leaf is SceneChildProgressGoalsV2Props =>
    'imageMap' in leaf &&
    typeof leaf.imageMap === 'object' &&
    leaf.imageMap !== null,
};
/**
 * Returns all the assets used in a given SceneSet
 * @param sceneSet
 */
const getAssetsFromSceneSet = (sceneSet?: SceneSet): string[] | [] => {
  if (!sceneSet) {
    return [];
  }

  const assets: string[] = [];

  const traverseTree = (leaf: Leaf): SceneChild => {
    if (guard.isSceneChildWithSourceProps(leaf)) {
      if (
        canComponentRenderAsset(
          leaf.component,
          leaf.sourceProps.storage_url as string
        )
      ) {
        assets.push(leaf.sourceProps.storage_url);
      }

      if (typeof leaf.sourceProps?.captions_storage_url === 'string') {
        assets.push(leaf.sourceProps?.captions_storage_url);
      }

      for (const caption of leaf.sourceProps?.captions_data ?? []) {
        if (typeof caption?.storage_url === 'string') {
          assets.push(caption?.storage_url);
        }
      }
    }
    if (guard.isSceneChildProgressGoalsV2Props(leaf)) {
      Object.entries(leaf.imageMap).map(([name]) => {
        if (name in KNOWN_GOALS) {
          reporter.info('getAssetsFromSceneSet', `Unknown goal "${name}"`);
        }
        assets.push(
          leaf.imageMap[
            // WHY "as"?
            // Code uses compile-time type `GoalReferences`.
            // But data comes from api response and we want this code
            // to work even if name is not a known e.g. `GoalReferences`
            name as GoalReferences
          ].sourceProps.storage_url
        );
      });
    }

    // Pretty much returns the same thing (for convenience)
    return {
      component: leaf.component,
      sourceProps: leaf.sourceProps,
      childNodes: Array.isArray(leaf.childNodes)
        ? (leaf.childNodes as SceneChild[]).map(
            (leftEl): SceneChild => {
              return traverseTree(leftEl);
            }
          )
        : undefined,
    };
  };

  // @TODO: Remove this unknown and sort out the definition of SceneSet
  traverseTree((sceneSet as unknown) as SceneChild); // We're treating the SceneSet like a childNode here (for convenience)

  return uniq(assets).filter(Boolean);
};

export default getAssetsFromSceneSet;
