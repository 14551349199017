import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { ScheduleScreen } from '@bighealth/types';
import { AxiosRequestConfig } from 'axios';

export const get_schedule = async (
  args: get_schedule.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_schedule.Response> => {
  const request: get_schedule.Request = {
    service_name: 'SleepioSchedule',
    service_version: '1',
    service_method: 'get_schedule',
    args,
  };

  return await api(request, requestConfig);
};

get_schedule.queryKey = 'SleepioSchedule/get_schedule';

export declare namespace get_schedule {
  export type Args = {
    program_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepioSchedule';
    service_version: '1';
    service_method: 'get_schedule';
    args: Args;
  }

  export type Result = ScheduleScreen;

  export type Response = APIResponse<Result>;
}
