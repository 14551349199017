import { throwIfMissingEnvVar } from '../helpers';

export const DEBUG_SHOW_NETWORK_INFO = false;
export const DEV_DEBUG = process.env.NODE_ENV !== 'production';

type RemoteEnvironments = 'ci' | 'qa' | 'dev' | 'stage' | 'prod';

// DANGER: Using "STAGE" will couple your code, to the environment. Which is likely to cause problems.
// This is an short-term escape hatch. Better to use a proper env-var of Staff-Web config key/value.
export const STAGE: RemoteEnvironments | 'local' =
  ((process.env.STAGE as unknown) as RemoteEnvironments) || 'local';

export const PLATGEN_FF_USE_DEBUG_MODE =
  throwIfMissingEnvVar(
    'PLATGEN_FF_USE_DEBUG_MODE',
    process.env.PLATGEN_FF_USE_DEBUG_MODE
  ) === '1';

if (PLATGEN_FF_USE_DEBUG_MODE) {
  // NOTE: don't use `reporter.log` here due to circular dependency
  console.info(`USE_DEBUG_MODE: true`);
}
