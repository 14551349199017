import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ButtonContainerWithMinMax } from 'components/Button/components';
import { LoginFooter } from 'components/Footer';
import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import { LeftArrow } from 'components/Screens/ContentScreens/components';
import {
  BackLinkContainer,
  BackLinkText,
  Container,
  ContentContainer,
  ErrorContainer,
  ForgotPasswordScroll,
  HeadingText,
  InlineLink,
  Input,
  InstructionText,
  LabelText,
  LimitWidth,
  PaddingContainer,
  ValidationText,
} from 'components/Screens/ForgotPasswordScreen/styled';
import { Logo } from 'components/Screens/LoginScreen/components/HeaderLogo';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { ButtonTypes } from 'config/index';
import { useHistory, useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { sendRequestForForgotPassword } from 'lib/api';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { isDevMode } from 'lib/isDevMode';
import { openURL } from 'lib/navigation/openURL';
import * as reporter from 'lib/reporter';
import { showQAMenu } from 'lib/showQAMenu';

type Params = {
  productReference: string;
};

export const copy = {
  missingEmail: 'Please enter your email',
  invalidEmail: 'Please enter a valid email',
  updateTitle: 'Forgot Password',
  forgotPasswordBackLink: '< Back to Reset Password',
  loginLinkTitle: 'Log in',
  loginBackLink: 'Log in',
  successHeader: 'Check your inbox',
  pageHeader: 'Forgot password?',
  instructionsBegin:
    'We’ve just sent you email with a link to reset your password. Contact ',
  emailTo: 'mailto:hello@sleepio.com',
  emailAddress: 'hello@sleepio.com',
  instructionsEnd: ' with any questions.',
  instructions:
    'Enter the email associated with your account and we’ll send you a link to reset your password.',
  emailPlaceholder: 'Email',
  emailLabel: 'Email',
  actionButtonText: 'Reset password',
};

const emailInitialState = {
  isValid: false,
  value: '',
  isPresent: false,
  isSubmitted: false,
};

const ForgotPasswordScreen = (): ReactElement => {
  useUpdateTitle(copy.updateTitle);
  const dispatch = useDispatch();
  const screenOrientation = useScreenOrientation();
  const [email, setEmail] = useState(emailInitialState);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const productId = useQueryProduct()?.data?.result.id;
  const { productReference } = useParams<Params>();
  const history = useHistory();
  const hasEmailPresentError = email.isSubmitted && !email.isPresent;

  const hasEmailValidError =
    email.isSubmitted && !email.isValid && email.isPresent;

  const showErrorMessage = hasEmailValidError || hasEmailPresentError;

  const isPortrait =
    screenOrientation === 'PORTRAIT' ||
    screenOrientation === 'PORTRAIT-UPSIDEDOWN';

  const onLogoPress = (): void => {
    if (isDevMode()) {
      showQAMenu(productReference as string, history, dispatch);
    }
  };

  const onChangeEmail = (value: string): void => {
    setEmail(prevState => ({
      ...prevState,
      value: value,
      isValid: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value),
      isPresent: value.length > 0,
      isSubmitted: false,
    }));
  };

  const handleSubmit = async (): Promise<void> => {
    setEmail(prevState => ({ ...prevState, isSubmitted: true }));
    if (email.isPresent && email.isValid) {
      try {
        await sendRequestForForgotPassword(email.value, productId as number);
        setSubmittedSuccessfully(true);
      } catch (e) {
        reporter.log(e.message, e, { silent: true });
        setSubmittedSuccessfully(false);
      }
    }
  };

  const goToLogin = (): void => {
    history.push(`/${productReference}/login-screen`);
  };

  return (
    <Container>
      <ForgotPasswordScroll>
        <Logo
          isPortrait={isPortrait}
          onPress={onLogoPress}
          variant={'back_to_login'}
        />
        <LimitWidth>
          <ContentContainer>
            <BackLinkContainer onPress={goToLogin}>
              <LeftArrow />
              <BackLinkText>Back</BackLinkText>
            </BackLinkContainer>
            <HeadingText>
              {submittedSuccessfully ? copy.successHeader : copy.pageHeader}
            </HeadingText>
            <PaddingContainer>
              {submittedSuccessfully ? (
                <InstructionText>
                  {copy.instructionsBegin}
                  <InlineLink
                    onPress={() => {
                      openURL(copy.emailTo);
                    }}
                  >
                    {copy.emailAddress}
                  </InlineLink>
                  {copy.instructionsEnd}
                </InstructionText>
              ) : (
                <InstructionText>{copy.instructions}</InstructionText>
              )}
            </PaddingContainer>
            {!submittedSuccessfully && (
              <>
                <PaddingContainer>
                  <LabelText id="email-label">Email</LabelText>
                  <Input
                    {...roles('forgot-password-email-input')}
                    onChangeText={onChangeEmail}
                    placeholder={copy.emailPlaceholder}
                    placeholderTextColor={'#757575'}
                    keyboardType={'email-address'}
                    type={'email'}
                    hasErrors={showErrorMessage}
                    required
                    autoCapitalize={'none'}
                    returnKeyType={'next'}
                    autoCorrect={false}
                    value={email.value}
                    aria-labelledby="email-label"
                    fontSize="16px"
                  />
                  {hasEmailPresentError ? (
                    <ErrorContainer>
                      <ValidationText>{copy.missingEmail}</ValidationText>
                    </ErrorContainer>
                  ) : null}
                  {hasEmailValidError ? (
                    <ErrorContainer>
                      <ValidationText>{copy.invalidEmail}</ValidationText>
                    </ErrorContainer>
                  ) : null}
                </PaddingContainer>
                <ButtonContainerWithMinMax type={ButtonTypes.PRIMARY_BUTTON}>
                  <UniversalButton
                    {...roles('action-button')}
                    onPress={handleSubmit}
                    text={copy.actionButtonText}
                    colorScheme={ButtonColorSchemes.AltPrimary}
                    size={ButtonSizes.Small}
                  />
                </ButtonContainerWithMinMax>
              </>
            )}
          </ContentContainer>
        </LimitWidth>
        <LoginFooter />
      </ForgotPasswordScroll>
    </Container>
  );
};

export default ForgotPasswordScreen;
