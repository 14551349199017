import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { RoleProps } from 'cross-platform/utils/roleProps';

import { IconStyles } from './types';

export const Pause = ({
  size,
  style,
  ...roleProps
}: {
  size: number;
  style?: IconStyles;
} & RoleProps): ReactElement => {
  return (
    <Svg
      {...roleProps}
      width={size}
      height={size}
      viewBox="0 0 56 56"
      style={style}
    >
      <G stroke="none" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0ZM19.2499 19.6257C19.2499 18.7279 19.9777 18 20.8756 18H21.9991C22.897 18 23.6249 18.7279 23.6249 19.6257V36.3743C23.6249 37.2721 22.897 38 21.9991 38H20.8756C19.9777 38 19.2499 37.2721 19.2499 36.3743V19.6257ZM32.3749 19.6257C32.3749 18.7279 33.1027 18 34.0006 18H35.1241C36.022 18 36.7499 18.7279 36.7499 19.6257V36.3743C36.7499 37.2721 36.022 38 35.1241 38H34.0006C33.1027 38 32.3749 37.2721 32.3749 36.3743V19.6257Z"
          fill={style?.color || 'currentColor'}
        />
      </G>
    </Svg>
  );
};
