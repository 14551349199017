import React, { ReactElement, useState } from 'react';
import { View } from 'react-native';

import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';
import {
  EmailInput,
  isEmailPresent,
  isEmailValid,
} from 'daylight/components/inputs';
import { useTransformContentStyle } from 'daylight/hooks';

import {
  ButtonContainer,
  ForgotPasswordText,
  ResetDirectionContainer,
} from '../ForgotPasswordScreen.styled';

export const PasswordResetDirections = ({
  onSubmit,
}: {
  onSubmit: (value: string, isSubmitted: boolean) => void;
}): ReactElement => {
  const [email, setEmail] = useState({
    isValid: false,
    value: '',
    isPresent: false,
    isSubmitted: false,
  });
  const transformStyle = useTransformContentStyle();
  const onChangeEmail = (value: string): void => {
    setEmail(prevState => ({
      ...prevState,
      value: value,
      isValid: isEmailValid(value),
      isPresent: isEmailPresent(value),
      isSubmitted: false,
    }));
  };

  const submitForgetPassword = async (): Promise<void> => {
    setEmail(prevState => ({ ...prevState, isSubmitted: true }));
    if (email.isValid) {
      onSubmit(email.value, true);
    }
  };
  return (
    <View {...roles('password-reset-directions')}>
      <ResetDirectionContainer
        {...roles('reset-direction-contaner')}
        style={{ padding: transformStyle(24) }}
      >
        <ForgotPasswordText
          bold
          fontSize={transformStyle(21)}
          {...roles('reset-password-text')}
        >
          Reset your password
        </ForgotPasswordText>
        <ForgotPasswordText
          {...roles('enter-email-text')}
          style={{
            paddingTop: transformStyle(20),
            paddingBottom: transformStyle(20),
          }}
        >
          Enter your email and we&#39;ll send you a link to reset your password.
        </ForgotPasswordText>
        <EmailInput
          {...roles('EmailInput-forgot-password')}
          label="Email address"
          placeholder="Enter your email"
          onChange={onChangeEmail}
          emailProps={email}
        />
        <ButtonContainer style={{ paddingTop: transformStyle(50) }}>
          <UniversalButton
            {...roles('Send-link-button')}
            isDisabled={!email.value}
            onPress={submitForgetPassword}
            text={'Send link to email'}
            colorScheme={ButtonColorSchemes.Primary}
            size={ButtonSizes.Small}
          />
        </ButtonContainer>
      </ResetDirectionContainer>
    </View>
  );
};
