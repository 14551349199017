import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const consolidate_password = async (
  args: consolidate_password.Args
): Promise<consolidate_password.Response> => {
  return await api({
    service_name: 'UserAccount',
    service_version: '1',
    service_method: 'consolidate_password',
    args: args,
  } as consolidate_password.Request);
};

consolidate_password.queryKey = 'UserAccount/consolidate_password';

export declare namespace consolidate_password {
  export type Args = {
    password: string;
    device_platform: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserAccount';
    service_version: '1';
    service_method: 'consolidate_password';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
