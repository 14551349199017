import {
  addDays,
  differenceInDays,
  differenceInMilliseconds,
  subDays,
} from 'date-fns';

import { MINUTE } from 'lib/durations';

export const getDifferenceWithDiaryDate = (
  timeNum1: number,
  timeNum2: number,
  diaryDate?: Date
): number => {
  if (typeof diaryDate === 'undefined') {
    return timeNum1 - timeNum2;
  }

  const timezone = new Date(0).getTimezoneOffset();
  const baseDate = new Date(0 + timezone * MINUTE);

  // the sleep diary starts the night before
  diaryDate = subDays(diaryDate, 1);

  // the time starts from 1st of January 1970
  const time1 = new Date(timeNum1);
  const time2 = new Date(timeNum2);

  // cloning date
  let date1 = new Date(diaryDate.getTime());
  let date2 = new Date(diaryDate.getTime());

  // shifting sleep diary by the amount of days from 1st of January 1970
  date1 = addDays(date1, differenceInDays(time1, baseDate));
  date2 = addDays(date2, differenceInDays(time2, baseDate));

  date1.setHours(time1.getHours());
  date1.setMinutes(time1.getMinutes());

  date2.setHours(time2.getHours());
  date2.setMinutes(time2.getMinutes());

  return differenceInMilliseconds(date1, date2);
};
