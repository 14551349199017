import React, { ReactElement } from 'react';

import { LoadingBlankScreen } from 'components/LoadingBlankScreen';
import { Redirect, useParams } from 'cross-platform/react-router';
import { useQuerySchedule } from 'lib/api/reactQueryHelpers';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

import { ContentNav } from '../components';
import {
  getFirstSlugForScreen,
  isNonEmptyPageData,
} from '../components/helpers';
import { ContentScreen } from '../ContentScreen';
import { useGetFullContentScreensPath } from '../hooks/useGetFullContentScreensPath';

import { ScheduleContent } from './ScheduleContent';
import { ScheduleScreenParams } from './types';

export const ScheduleScreen = (): ReactElement => {
  useUpdateTitle('Schedule');
  const { page } = useParams<ScheduleScreenParams>();
  const getScheduleScreensPath = useGetFullContentScreensPath();
  const { isLoading, data } = useQuerySchedule();

  if (isLoading) {
    // the global throbber will be rendered
    return <LoadingBlankScreen />;
  }

  const isCompleteButIsOnRoot =
    !page && !isLoading && isNonEmptyPageData(data?.result);

  if (isCompleteButIsOnRoot) {
    // Then redirect it to the first page in the list
    return (
      <Redirect
        exact
        to={getScheduleScreensPath(getFirstSlugForScreen(data?.result))}
      />
    );
  }

  return (
    <ContentScreen
      navTitle={'Schedule'}
      nav={<ContentNav isLoading={isLoading} navigation={data?.result} />}
      content={<ScheduleContent isLoading={isLoading} content={data?.result} />}
    />
  );
};
