import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const refresh_access_token = async (
  requestConfig?: AxiosRequestConfig
): Promise<refresh_access_token.Response> => {
  const request: refresh_access_token.Request = {
    service_name: 'UserAccountAuthentication',
    service_version: '1',
    service_method: 'refresh_access_token',
    args: {},
  };

  return await api(request, requestConfig);
};

refresh_access_token.queryKey =
  'UserAccountAuthentication/refresh_access_token';

export declare namespace refresh_access_token {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'UserAccountAuthentication';
    service_version: '1';
    service_method: 'refresh_access_token';
    args: Args;
  }

  export type Result = { user_uuid: string };

  export type Response = APIResponse<Result>;
}
