import React from 'react';

import { QuizAttribute } from 'components/constants';

import { ContentProps } from '..';

import { Content } from './styled';

export type ControlProps = {
  isDisabled?: boolean;
  isPressing?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  quiz?: QuizAttribute;
  isInput?: boolean;
  highlight?: boolean;
} & ContentProps;

const Control = (props: ControlProps): React.ReactElement => (
  <Content {...props} />
);

export { Control };
