import { useCallback } from 'react';

import { transition_to_next_scene_set } from '@bighealth/api/SceneSetGraph/v1';

import { queryClientTransitionSceneSet } from '../queryClientAnalogs/queryClientTransitionSceneSet';

import { useLazyQueryDownloadSceneSetAssets } from './useLazyQueryDownloadSceneSetAssets';

export type TransitionCallback = (
  args: transition_to_next_scene_set.Args
) => Promise<undefined | transition_to_next_scene_set.Response>;
/**
 * A hook for fetching SceneSet data
 * @WARNING!!! Only to be used in useActionHandler
 */
export const useLazyQueryTransitionSceneSetWithAssets = (): TransitionCallback => {
  const downloadAssets = useLazyQueryDownloadSceneSetAssets();

  return useCallback(
    async args => {
      try {
        const response = await queryClientTransitionSceneSet(args);
        const sceneSetJSON = response?.result?.scene_set_json;
        await downloadAssets(sceneSetJSON);
        return response;
      } catch (e) {
        // IMPORTANT!!! This QueryError is handled in useActionHandler
        // do not attempt to use it elsewhere
        return e;
      }
    },
    [downloadAssets]
  );
};
