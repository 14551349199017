/* istanbul ignore file */
import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { VisualState } from 'components/LocalNotifications/NotificationsView';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';
import { useTransformContentStyle } from 'daylight/hooks';

import { Constant } from '../../Constants';
import { SettingsContentText } from '../../SettingsScreen.styled';
import { NotificationsSettingsScreenWrapper } from '../NotificationsSettingsScreenWrapper';
import { UIScreenView } from '../NotificationsView.styled';

import * as Styled from './ContextScreenBeforeOsNotifPermsRequest.styled';
type Props = PropsWithChildren<{
  notificationAlertHandler: () => void;
  visualState: VisualState;
}>;

export const ContextScreenBeforeOsNotifPermsRequest = ({
  notificationAlertHandler,
  visualState,
}: Props): ReactElement => {
  const theme = useContext(ThemeContext);

  const transformStyle = useTransformContentStyle();

  return (
    <NotificationsSettingsScreenWrapper visualState={visualState}>
      <UIScreenView>
        <Styled.UIScreenView>
          <View>
            <SettingsContentText
              fontSize={transformStyle(18)}
              marginBottom={transformStyle(10)}
              lineHeight={transformStyle(24.3)}
            >
              {Constant.Notification.DAILY_REMINDER_MAIN_TEXT}
            </SettingsContentText>
            <SettingsContentText
              fontSize={transformStyle(16)}
              marginBottom={transformStyle(0)}
              lineHeight={transformStyle(24.3)}
              style={{ color: theme.color.text.placeholder }}
            >
              {Constant.Notification.DAILY_REMINDER_SUBTEXT}
            </SettingsContentText>
          </View>
          <Styled.FooterView>
            <Styled.ButtonContainer style={{ paddingTop: transformStyle(50) }}>
              <UniversalButton
                {...roles('Turn-on-reminder-button')}
                onPress={notificationAlertHandler}
                text={Constant.Notification.TURN_ON_REMINDER_BTN}
                colorScheme={ButtonColorSchemes.Primary}
                size={ButtonSizes.Small}
                testID={'Turn-on-reminder-button'}
              />
            </Styled.ButtonContainer>
            <Styled.ReminderSubText
              fontSize={transformStyle(14)}
              marginBottom={transformStyle(10)}
              lineHeight={transformStyle(32)}
            >
              {Constant.Notification.ASKED_TO_TURN_ON}
            </Styled.ReminderSubText>
          </Styled.FooterView>
        </Styled.UIScreenView>
      </UIScreenView>
    </NotificationsSettingsScreenWrapper>
  );
};

export default ContextScreenBeforeOsNotifPermsRequest;
