import { sessionsValues } from '../constants';
import { CardStyles } from '../types';

const cardWrapperStylesLarge = {
  width: sessionsValues.card.width,
  height: sessionsValues.card.image.height + sessionsValues.card.details.height,
  marginLeft: 12,
  marginRight: 12,
  borderRadius: sessionsValues.card.large.borderRadius,
};

const contentPadding = {
  paddingLeft: 24,
  paddingRight: 24,
};

const cardContentStylesLarge = {
  iconSize: 80,
  headerStyles: {
    fontSize: 40,
  },
  statusStyles: {
    fontSize: 20,
  },
  descriptionStyles: {
    fontSize: 16,
  },
  titleStyles: {
    fontSize: 21,
  },
  newIconSize: 42,
  labelTextStyles: {
    fontSize: 14,
  },
  newStatusStyles: {
    fontSize: 18,
  },
};

// Card Wrapper style transform #4
const cardWrapperStylesSmall = {
  width: 295,
  height: sessionsValues.card.image.height + sessionsValues.card.details.height,
  marginLeft: 12,
  marginRight: 12,
  borderRadius: sessionsValues.card.small.borderRadius,
};

const cardContentStylesSmall = {
  iconSize: 45,
  headerStyles: {
    fontSize: 24,
  },
  statusStyles: {
    fontSize: 12,
  },
  descriptionStyles: {
    fontSize: 16,
  },
  titleStyles: {
    fontSize: 20,
  },
  newIconSize: 42,
  labelTextStyles: {
    fontSize: 14,
  },
  newStatusStyles: {
    fontSize: 16,
  },
};

const getImageWrapper = (isLarge: boolean) => {
  const size = isLarge ? 'large' : 'small';
  return {
    borderTopLeftRadius: sessionsValues.card[size].borderRadius,
    borderTopRightRadius: sessionsValues.card[size].borderRadius,
  };
};

export const getCardStyles = (isLarge: boolean): CardStyles => {
  const imageWrapper = getImageWrapper(isLarge);
  // Card Wrapper style transform #3
  if (isLarge) {
    return {
      wrapperStyles: cardWrapperStylesLarge,
      contentPadding,
      contentStyles: cardContentStylesLarge,
      imageWrapper,
    };
  }

  return {
    wrapperStyles: cardWrapperStylesSmall,
    contentPadding,
    contentStyles: cardContentStylesSmall,
    imageWrapper,
  };
};
