/* istanbul ignore file */

/**
 * onStartShouldSetResponder and onResponderGrant aren't testable currently
 * so whilst we do have tests for this file, it is ignored for coverage for
 * that reason.
 * @TODO: add tests for these events
 * @WHEN: when the platform supports it
 */
import React, { ReactElement } from 'react';
import { ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { FillAbsolute } from 'components/Containers';
import { showControls } from 'state/player/actions';
import { getControlsAreVisible } from 'state/player/selectors';

const ToggleControlsNode = styled(FillAbsolute)``;

export const ToggleControlsHandler = ({
  style,
}: {
  style?: ViewStyle;
}): ReactElement => {
  const controlsAreVisible = useSelector(getControlsAreVisible);
  const dispatch = useDispatch();

  return (
    <ToggleControlsNode
      testID={'ToggleControlsNode'}
      onStartShouldSetResponder={() => true}
      onResponderGrant={() => {
        dispatch(showControls(!controlsAreVisible));
      }}
      style={style}
    />
  );
};
