import React, { ReactElement } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { NotificationViewArgs } from 'components/LocalNotifications/NotificationsView';
import { getAssetsForProduct } from 'config/getAssetsForProduct';
import { roles } from 'cross-platform/utils/roleProps';

import { Wrapper } from '../../styled';
import { NotificationPreviewContent } from '../../types';
import { Header } from '../Header';

import {
  Description,
  Footer,
  Main,
  PreviewImage,
  PrimaryButton,
  SecondaryButton,
  Title,
} from './styled';

export type PreviewProps = NotificationViewArgs & {
  onClose: () => void;
  productReference: string;
  content: NotificationPreviewContent;
};

export const Preview = ({
  onClose,
  productReference,
  notificationAlertHandler,
  content,
  enableButtonPressedHandler,
}: PreviewProps): ReactElement => {
  const safeArea = useSafeArea();
  const { notificationsPreview } = getAssetsForProduct(productReference);
  const enableNotificationsHandler = () => {
    notificationAlertHandler();
    enableButtonPressedHandler(true);
  };

  return (
    <Wrapper>
      <Header onClose={onClose} />
      <Main>
        <PreviewImage
          alt="Notification: Hand pressing on a button picture"
          src={notificationsPreview}
        />
        <Title {...roles('PreviewTitle')} text={content.title} />
        <Description
          {...roles('PreviewDescripion')}
          text={content.description}
        />
      </Main>
      <Footer safeArea={safeArea}>
        <PrimaryButton
          text={'Enable notifications'}
          onPress={enableNotificationsHandler}
        />
        <SecondaryButton text={'Maybe later'} onPress={onClose} />
      </Footer>
    </Wrapper>
  );
};
