import { View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

type BaseBoxProps = {
  height?: ViewStyle['height'];
  width?: ViewStyle['width'];
};
const BaseBox = styled(View).attrs<BaseBoxProps>(({ height, width }) => ({
  style: {
    height,
    width,
  },
}))<BaseBoxProps>`
  border-radius: 8px;
`;

export const WhiteBox = styled(BaseBox)`
  background-color: ${({ theme }) => theme.color.white};
`;
WhiteBox.displayName = 'WhiteBox';

export const GrayBox = styled(BaseBox)`
  background-color: #ddd;
`;
GrayBox.displayName = 'GrayBox';

export const DarkGrayBox = styled(BaseBox)`
  background-color: #bdbdbd;
`;
DarkGrayBox.displayName = 'DarkGrayBox';
