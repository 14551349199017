import styled from 'styled-components/native';

import { getFontFamilyFromStyles } from 'components/sceneset-components/Text/getFontFamilyFromStyles';
import { Text } from 'components/Text';

export const SettingsContentText = styled(Text)<{
  bold?: boolean;
  fontSize?: number;
  marginBottom?: number;
  lineHeight?: number;
}>`
  margin-bottom: ${props =>
    props.marginBottom ? `${props.marginBottom}px` : '0px'};
  color: ${({ theme }) => theme.color.text.primary};
  font-weight: ${props => (props.bold ? '700' : '500')};
  font-family: '${props =>
    getFontFamilyFromStyles({
      fontWeight: props.bold ? 700 : 500,
    })}';
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '18px')};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px` : '24.3px'};
`;
SettingsContentText.displayName = 'SettingsContentText';

export const SettingsContentLinkText = styled(Text)<{
  fontSize?: number;
  marginTop?: number;
  lineHeight?: number;
}>`

  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '0px')};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '18px')};
  color: ${({ theme }) => theme.color.text.link};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px` : '24.3px'};
  font-weight: 700;
  font-family: '${getFontFamilyFromStyles({ fontWeight: 700 })}';
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.color.text.link};
`;
SettingsContentLinkText.displayName = 'SettingsContentLinkText';
