import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_entry_graph_and_scene_set_ids = async (
  requestConfig?: AxiosRequestConfig
): Promise<get_entry_graph_and_scene_set_ids.Response> => {
  const request: get_entry_graph_and_scene_set_ids.Request = {
    service_name: 'Program',
    service_version: '1',
    service_method: 'get_entry_graph_and_scene_set_ids',
    args: {},
  };

  return await api(request, requestConfig);
};

get_entry_graph_and_scene_set_ids.queryKey =
  'Program/get_entry_graph_and_scene_set_ids';

export declare namespace get_entry_graph_and_scene_set_ids {
  export interface Request extends APIRequestBody {
    service_name: 'Program';
    service_version: '1';
    service_method: 'get_entry_graph_and_scene_set_ids';
    args: {};
  }

  export type Result = {
    scene_set_id: number;
    scene_set_graph_id: number;
  };

  export type Response = APIResponse<Result>;
}
