import React from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { roles } from 'cross-platform/utils/roleProps';
import { openURL } from 'lib/navigation/openURL';

import {
  HeaderLine,
  INeedHelpComponentWrapper,
  MFAContentWrapper,
  SectionView,
  SubtitleINeedHelp,
  SubtitleLink,
  Title,
} from '../styled/styled';

import MFAModalHeader from './MFAModalHeader';

type INeedHelpComponentProps = {
  onRequestClose: () => void;
  redirectToDownloads: () => void;
  navigate: () => void;
};

const INeedHelpComponent = (
  props: INeedHelpComponentProps
): React.ReactElement => {
  const safeArea = useSafeArea();
  return (
    <SectionView
      style={{ paddingTop: safeArea.top }}
      {...roles('MFAModalINeedHelpSectionView')}
    >
      <MFAModalHeader
        navigate={() => props.navigate()}
        onRequestClose={() => props.onRequestClose()}
        showBackButton={true}
      />
      <HeaderLine />
      <MFAContentWrapper aria-live="polite" safeArea={safeArea}>
        <Title role="heading" accessibilityRole="header" aria-level="1">
          {'I need help'}
        </Title>
        <INeedHelpComponentWrapper role="summary" disabled={true}>
          <SubtitleINeedHelp>
            {'Read through our help documentation '}
            <SubtitleLink
              role="link"
              accessibilityRole="link"
              onPress={props.redirectToDownloads}
            >
              {'here'}
            </SubtitleLink>
            <SubtitleINeedHelp>
              {' or you can email us for technical support at '}
            </SubtitleINeedHelp>
            <SubtitleLink
              role="link"
              accessibilityRole="link"
              onPress={() => openURL('mailto:hello@bighealth.com')}
            >
              {'hello@bighealth.com'}
            </SubtitleLink>
            <SubtitleINeedHelp>
              {' and we’ll respond as soon as possible.'}
            </SubtitleINeedHelp>
          </SubtitleINeedHelp>
        </INeedHelpComponentWrapper>
      </MFAContentWrapper>
    </SectionView>
  );
};

export default INeedHelpComponent;
