import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SceneSet } from '@bighealth/types';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import downloadRemoteAssets from 'lib/downloader/downloader';
import getAssetsFromSceneSet from 'lib/player/getAssetsFromSceneSet';
import {
  moveAssetToCache,
  setAssetsToDownload,
} from 'state/downloader/actions';
import { Asset } from 'state/downloader/reducer';
import { getAssetsInCache } from 'state/downloader/selectors';

export type UseQueryDownloadSceneSetAssetsCallback = (
  sceneSetJSON: SceneSet | null | undefined
) => Promise<string[] | undefined>;

export const useLazyQueryDownloadSceneSetAssets = (): UseQueryDownloadSceneSetAssetsCallback => {
  const queryClient = getQueryClient();
  const dispatch = useDispatch();
  const assetsInCache = useSelector(getAssetsInCache);
  return useCallback(
    async sceneSetJSON => {
      if (!sceneSetJSON) {
        return undefined;
      }

      const remoteURIsInSceneSet: string[] = getAssetsFromSceneSet(
        sceneSetJSON as SceneSet
      );
      const uncachedRemoteURIs = remoteURIsInSceneSet
        .map(remoteURI =>
          assetsInCache.map(asset => asset.remoteURI).includes(remoteURI)
            ? undefined
            : remoteURI
        )
        .filter(Boolean) as string[];

      if (!uncachedRemoteURIs?.length) {
        // There's nothing to download
        return undefined;
      }
      const assets = uncachedRemoteURIs.map(
        (path: string): Asset => ({
          remoteURI: path,
          localURI: undefined,
        })
      );
      dispatch(setAssetsToDownload(assets));
      await downloadRemoteAssets(uncachedRemoteURIs, newAsset => {
        dispatch(moveAssetToCache(newAsset));
      });
      queryClient.setQueryData('Assets', assets); // Not required but improved react-query logs now we don't have redux
      return uncachedRemoteURIs;
    },
    [assetsInCache, dispatch, queryClient]
  );
};
