import { instead } from 'lib/error/utils/instead';

/**
 * "Pipe" value, with integrated type-guard to ensure Array.map() have no undefined values
 * This keeps code neater.
 * @param el
 */
export const throwIfUndefined = <T,>(
  el: T | undefined,
  label: unknown
): T | never => {
  if (el === undefined) {
    throw TypeError(`Unexpected ${label} is undefined`);
  }
  return el;
};

export const throwIfStringWithLabel = (label: unknown) => <T,>(
  el: T | string
): T | never => {
  if (typeof el === 'string') {
    throw TypeError(`Unexpected ${label} is as string ${el}`);
  }
  return el;
};

export const throwIfNotDateOrUndefined = (val: unknown, label: string) => {
  if (!isUndefined(val) && !isDate(val)) {
    throw TypeError(`Expected ${label} to be Date, ${instead(val)}`);
  }
  return val;
};

export const throwIfIsNumber = <T,>(value: T | number, label: string): T => {
  if (isNumber(value)) {
    throw TypeError(`Expected ${label} to be not number, ${instead(value)}`);
  }
  return value;
};

export function assertUnreachable(_: never): never {
  throw Error("Didn't expect to get here");
}

export const isStringArray = (
  array: number[] | string[] | Record<string, unknown>[] | boolean[]
): array is string[] => {
  return typeof array[0] === 'string';
};

export const isNumber = (val: unknown): val is number =>
  typeof val === 'number' && !isNaN(val);

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const isDate = (val: any): val is Date => val instanceof Date;

// eslint-disable-next-line @typescript-eslint/ban-types
export const isObject = (val: any): val is object => typeof val === 'object';

export const isString = (val: any): val is string => typeof val === 'string';

export const isUndefined = (val: any): val is undefined => val === undefined;

export const isDefined = <T,>(val: T | undefined): val is T =>
  val !== undefined;
