import { View } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'components/Text';

export const SwitchToggleView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 0;
`;
export const SettingsContentText = styled(Text)`
  margin-bottom: 0px;
  color: ${({ theme }) => theme.color.text.primary};
  font-weight: 500;
  font-size: ${({ theme }) => `${theme.modal.input.fontSize}px}`};
  line-height: ${({ theme }) => `${theme.modal.input.fontSize * 1.3}px}`};
`;

SettingsContentText.displayName = 'SettingsContentText';

export const SwitchToggleHelperText = styled(Text)<{
  placeHolderTextColor: string;
}>`
  font-size: ${({ theme }) => `${theme.modal.input.labelFontSize}px`};
  line-height: ${({ theme }) => `${theme.modal.input.labelFontSize * 1.35}px`};
  color: ${({ placeHolderTextColor }) => placeHolderTextColor}
  padding-bottom: 24px;
  font-weight: 500;
`;

export const CoverSwitchToggleInteractiveArea = styled(View)`
  width: 60px;
  height: 40px;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  margin: -5px;
`;

const Styled = {
  CoverSwitchToggleInteractiveArea,
  SettingsContentText,
  SwitchToggleHelperText,
  SwitchToggleView,
};

export default Styled;
