import { DomainIDs } from 'common/constants/enums';
import { NotificationFeatureFlag } from 'config/localNotifications';

import { GlobalState } from '../store/initialState';

import { NotificationUserSetting } from './reducer';

export const getOsNotificationPermissionsRequestCount = (
  state: GlobalState
): number =>
  state[DomainIDs.NOTIFICATIONS].osNotificationPermissionsRequestCount;

export const getNotificationConfigurations = (
  state: GlobalState
): NotificationUserSetting[] => state[DomainIDs.NOTIFICATIONS].configurations;

export const getNotificationFeatureFlag = (
  state: GlobalState
): NotificationFeatureFlag =>
  state[DomainIDs.NOTIFICATIONS].FF_NOTIFICATIONS_ON;

export const getHasDefaultNotificationModalPopUpBeenPrompted = (
  state: GlobalState
): NotificationFeatureFlag =>
  state[DomainIDs.NOTIFICATIONS].hasDefaultNotificationModalPopUpBeenPrompted;

export const getHasPreSleepDiaryNotificationModalPopUpBeenPrompted = (
  state: GlobalState
): NotificationFeatureFlag =>
  state[DomainIDs.NOTIFICATIONS]
    .hasPreSleepDiaryNotificationModalPopUpBeenPrompted;

export const getHasPostSleepDiaryNotificationModalPopUpBeenPrompted = (
  state: GlobalState
): NotificationFeatureFlag =>
  state[DomainIDs.NOTIFICATIONS]
    .hasPostSleepDiaryNotificationModalPopUpBeenPrompted;
