import { Question, ResponseType, ResponseTypeType } from '@bighealth/types';

import { Value } from 'components/forms/types';
import * as reporter from 'lib/reporter';

import { coerceValueByInputType } from '../GenericInput';

export const getIsToggleable = (questionProps: Question): boolean => {
  const { response_config: responseConfig } = questionProps;
  const {
    min_selections_required: minSelections,
    max_selections_required: maxSelections,
    response_options: responseOptions,
  } = responseConfig;
  if (
    responseOptions.length === 1 &&
    minSelections !== 1 &&
    maxSelections !== 1
  ) {
    reporter.log(
      `Content creator error in "response_options" for question id "${questionProps.id}"; when there is just one response option, both "max_selections_required" and "min_selections_required" must be set to 1.`
    );
  }
  return responseOptions.length !== 1;
};

export const onInputValueChange = (
  newValue: string,
  type: ResponseTypeType,
  onSetValue?: (value: Value) => void
): void => {
  if (newValue === '') {
    // We're always cool with empty string
    onSetValue?.(newValue);
    return;
  }

  const isNumberField = type === ResponseType.NUMBER;
  if (!isNumberField) {
    // For text fields/whatever we can blindly set as there's no coercion
    onSetValue?.(newValue);
    return;
  }

  const coercedValue = coerceValueByInputType(newValue, type);
  const isNumber =
    typeof coercedValue === 'number' && !Number.isNaN(coercedValue);
  if (isNumber) {
    onSetValue?.(coercedValue);
    return;
  }
  // NOOP: one we've fallen through to here, there's nothing valid to set
};

export const getIsMultiSelect = (questionProps: Question): boolean => {
  const { response_config: responseConfig } = questionProps;
  const {
    max_selections_required: maxSelections,
    response_options: responseOptions,
  } = responseConfig;
  return responseOptions.length > 1 && maxSelections > 1;
};
