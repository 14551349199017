import {
  ItemCarouselTools,
  useItemCarouselTools,
} from 'domain/carousel/ItemListCarousel/hooks/useItemCarouselTools/useItemCarouselTools';

import React from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import debounce from 'lodash.debounce';

import { isMobileWeb } from 'config/isMobileWeb';

import { useGetNodeFromApi } from './hooks/useGetNodeFromApi';
import { useNavigation } from './hooks/useNavigation';
import { useScrollByItem } from './hooks/useScrollByItem';

export const OVER_SCROLL_TO_VIEW = 1;

export type Selected = { index: number; itemsCount: number; dir: number };

type LayoutHandler = (e: LayoutChangeEvent) => void;

export type ItemListCarousel =
  | (ItemCarouselTools & {
      back: { disabled: boolean; onPress: () => void };
      forward: { disabled: boolean; onPress: () => void };
      selected: Selected;
      setSelected: React.Dispatch<React.SetStateAction<Selected>>;
      getTargetRefFactory: (
        index: number
      ) => ((node: View) => void) | undefined;
      getItemListRef: (node: View) => void;
      getScrollerWrapperRef: (node: View) => void;
      handleLayout: LayoutHandler;
      isNav: boolean;
    })
  | undefined;

/**
 * @param props
 * @returns
 */
const useItemListCarousel = <Item,>(options: {
  items: Item[];
  getInitialPos: (items: Item[]) => number;
}): ItemListCarousel | undefined => {
  const [remeasure, setRemeasure] = React.useState(0);

  const [selected, setSelected] = React.useState<Selected>({
    index: options.getInitialPos(options.items),
    itemsCount: options.items.length,
    dir: 0,
  });
  const scrollBehavior = useScrollByItem(selected);
  // Carousel
  const tools = useItemCarouselTools({
    // <--- Shared
    createScrollTo: scrollBehavior.createScrollTo,
    remeasure,
  });
  // Refs
  const scrollBehaviorIndicies = scrollBehavior.getIndexAndMaxIndex(
    tools,
    selected,
    options.items.length
  );
  // ref "getters"
  const getTargetRef = useGetNodeFromApi(tools.targetItemApi);
  const getTargetRefFactory = (index: number) =>
    index === scrollBehaviorIndicies.index ? getTargetRef : undefined;
  const getScrollerWrapperRef = useGetNodeFromApi(tools.scrollerWrapperApi);
  const getItemListRef = useGetNodeFromApi(tools.itemListApi);

  // Nav
  const { back, forward } = useNavigation(
    // <--- Shared
    selected,
    setSelected,
    scrollBehaviorIndicies
  );

  const handleLayout: LayoutHandler = debounce(() => {
    setRemeasure(remeasure + 1);
  }, 333 /*ms*/);

  if (isMobileWeb) {
    return undefined;
  }

  return {
    ...tools,
    isNav: true,
    back,
    forward,
    selected,
    setSelected,
    getTargetRefFactory,
    getItemListRef,
    getScrollerWrapperRef,
    handleLayout,
  };
};

export { useItemListCarousel };
