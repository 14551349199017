import { Value } from 'components/forms/types';
import { valueToDropdownItem } from 'components/generic-question/DropdownDurationPicker/helpers/valueToDropdownItem';

export const getValues = ({
  optionSlices,
  selectedValue,
}: {
  optionSlices: [Date[], Date[], Date[]];
  selectedValue?: Value;
}) =>
  optionSlices.map(options =>
    options.find(option => `${selectedValue}` === `${option}`)
      ? valueToDropdownItem(selectedValue)
      : undefined
  );
