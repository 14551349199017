import React, { ReactElement, ReactNode, useState } from 'react';
import { AccessibilityProps, View } from 'react-native';
import styled from 'styled-components';

import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { pass } from 'cross-platform/utils/roleProps';

const ButtonContainer = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
ButtonContainer.displayName = 'ButtonContainer';

const CircleButton = ({
  children,
  onPress,
  backgroundColor,
  ...rest
}: {
  children: ReactNode;
  onPress?: () => void;
  backgroundColor: string;
  rest?: string;
} & AccessibilityProps): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const [dynamicBackgroundColor, setDynamicBackgroundColor] = useState<string>(
    backgroundColor
  );

  const handleMouseEnter = () => {
    setDynamicBackgroundColor('#00212c');
  };

  const handleMouseLeave = () => {
    setDynamicBackgroundColor(backgroundColor);
  };

  const handleClick = () => {
    onPress?.();
  };

  return (
    <View>
      <ButtonContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        style={{
          opacity: 1,
          backgroundColor: dynamicBackgroundColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: styles.circleButtonSetSleepWindowDiameter / 2,
          width: styles.circleButtonSetSleepWindowDiameter,
          height: styles.circleButtonSetSleepWindowDiameter,
          marginLeft: styles.circleButtonSetSleepWindowMarginLeft,
        }}
        {...pass(rest)}
        aria-label={rest.accessibilityLabel}
      >
        {children}
      </ButtonContainer>
    </View>
  );
};

export { CircleButton };
