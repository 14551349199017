import React, { FunctionComponent } from 'react';

import { useQueryGetResourcesScreenData } from 'lib/api/reactQueryHelpers';

import { parseProductMark } from '../../util';
import { Resources } from '../Resources';

export const NetworkedResources: FunctionComponent = () => {
  const response = useQueryGetResourcesScreenData();

  if (response.status === 'success') {
    return (
      <Resources
        {...response.data.result}
        product_mark={parseProductMark(response.data.result.product_mark)}
      />
    );
  }

  return null;
};
