import React, { memo, ReactElement } from 'react';
import { ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';

import { FillAbsoluteCenter } from 'components/Containers';
import { ThemeMode } from 'config/getThemeForProducts/utils/createTheme/themeMode';
import * as roleProps from 'cross-platform/utils/roleProps';

import { Throbber } from './Throbber/Throbber';
import { LoadingOverlayProps } from './types';

export const backgroundColorOverride = 'transparent';

const LoadingOverlay = memo(
  ({
    showBackground = true,
    mode = ThemeMode.Dark,
    showSpinningThrobber = true,
    ...rest
  }: LoadingOverlayProps): ReactElement => {
    const {
      overlay: overlayStyles,
      throbber: throbberStyles,
    } = useTheme().loadingOverlay[mode];
    const styleOverrides: ViewStyle = showBackground
      ? {}
      : { backgroundColor: backgroundColorOverride };

    return (
      <FillAbsoluteCenter
        style={[overlayStyles, styleOverrides]}
        {...roleProps.pass(rest)}
      >
        {showSpinningThrobber && (
          <Throbber
            size={throbberStyles.size}
            fill={throbberStyles.fill}
            duration={throbberStyles.duration}
          />
        )}
      </FillAbsoluteCenter>
    );
  }
);

LoadingOverlay.displayName = 'LoadingOverlay';

export { LoadingOverlay };
