import asyncStorage from '@react-native-community/async-storage';
import { createMigrate, PersistConfig } from 'redux-persist';
import autoMerge from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { DEV_DEBUG } from 'config/envVars';

import rootReducer from '../rootReducer';

import { PERSIST_BAN_LIST } from './banlist';

export const persistConfig: PersistConfig<typeof rootReducer> = {
  key: 'root',
  storage: asyncStorage,
  stateReconciler: autoMerge,
  // "blacklist" and "whitelist" at BigHealth are words we choose not to use we feel they can be considered offensive.
  // Unfortunately thi, is a third party library and hence we have no control over it here.
  blacklist: PERSIST_BAN_LIST,
  debug: DEV_DEBUG,
  version: 0,
  migrate: createMigrate({}),
};
