import React, { ReactElement, useContext } from 'react';
import { ThemeContext } from 'styled-components/native';

import { Footer } from 'components/Footer';
import {
  ContentContainer,
  ScreenScroll,
  useGetPageDimensions,
} from 'components/Screens/ContentScreens';
import { SleepDiaryWeek } from 'components/SleepDiaryWeek';
import { useParams } from 'cross-platform/react-router';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

import { SleepDiaryScreenParams } from './types';

export const SleepDiaryScreen = (): ReactElement => {
  useUpdateTitle('Sleep Diary');
  const { calendarWeekOffset } = useParams<SleepDiaryScreenParams>();
  const { pageContentWidth } = useGetPageDimensions();
  const theme = useContext(ThemeContext);

  return (
    <ScreenScroll>
      <ContentContainer
        style={{
          flex: 1,
          width: pageContentWidth,
        }}
      >
        <SleepDiaryWeek
          includeMetadata={true}
          isReadOnly={false}
          isOutsideContent={true}
          showFitbitSetup={true}
          shouldShowContinueButton={false}
          period={'calendar_week'}
          calendarWeekOffset={parseInt(calendarWeekOffset || '0')}
          titleAlignment={{ horizontal: 'left' }}
          title={{
            text: 'Sleep Diary',
            color: theme.color.overlay.primary,
            fontSize: 40,
          }}
          subtitleAlignment={{ horizontal: 'left' }}
          subtitle={{
            color: theme.color.overlay.primary,
            fontSize: 30,
          }}
        />
      </ContentContainer>
      <Footer />
    </ScreenScroll>
  );
};
