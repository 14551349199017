import { Scene } from '@bighealth/types';

import { STAGE } from 'config/envVars';
import { getAssetDomain } from 'lib/test-utils/getAssetDomain';

/**
 * Debug data
 */
const getSceneForSelectHorizontal = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      childNodes: [
        {
          questionProps: {
            created_utc: { $datetime: '2020-10-21T10:57:59.958780' },
            updated_utc: { $datetime: '2022-05-20T19:54:52.019666' },
            deleted_utc: null,
            id: 129,
            semantic_id: 'sleep_efficiency_sleep_quality',
            uuid: '410835db-fbd1-41ee-88fd-9d99ea518758',
            language: 'en',
            version: 1,
            question_pre_text: null,
            question_title: 'How would you rate the quality of your sleep?',
            response_pre_text: null,
            visual_representation_json: '{}',
            visual_representation_markup: '<markup/>',
            optional: true,
            response_config: {
              response_options: [
                {
                  id: 0,
                  score: 0,
                  value: 1,
                  semantic_id: '0',
                  display_text: 'Very poor',
                  image: {
                    // AssetEntity
                    reference: 'sleep_quality_very_poor_icon',
                    file_name: 'Very poor.png',
                    storage_url: `${getAssetDomain(
                      STAGE
                    )}/content/sleep_quality_very_poor_icon__2022.09.08.15.22.48.481231.png`,
                    description:
                      '2ndSep2022: Used in "How did you Sleep last night" option',
                    height: 66,
                    width: 55,
                  },
                  max_response: 99999,
                  min_response: 0,
                },
                {
                  id: 1,
                  score: 1,
                  value: 3,
                  semantic_id: '1',
                  display_text: 'Poor',
                  max_response: 99999,
                  min_response: 0,
                },
                {
                  id: 2,
                  score: 2,
                  value: 5,
                  semantic_id: '2',
                  display_text: 'Average',
                  max_response: 99999,
                  min_response: 0,
                },
                {
                  id: 3,
                  score: 3,
                  value: 7,
                  semantic_id: '3',
                  display_text: 'Good',
                  max_response: 99999,
                  min_response: 0,
                },
                {
                  id: 4,
                  score: 4,
                  value: 9,
                  semantic_id: '4',
                  display_text: 'Very good',
                  max_response: 99999,
                  min_response: 0,
                },
              ],
              validation_schema: {
                type: 'object',
                title: 'answers',
                $schema: 'http://json-schema.org/draft-07/schema#',
                properties: {
                  '0': { type: 'number', maximum: 1, minimum: 1 },
                  '1': { type: 'number', maximum: 3, minimum: 3 },
                  '2': { type: 'number', maximum: 5, minimum: 5 },
                  '3': { type: 'number', maximum: 7, minimum: 7 },
                  '4': { type: 'number', maximum: 9, minimum: 9 },
                  selections: { type: 'array', maxItems: 1, minItems: 0 },
                },
                description: 'answer option values',
                errorMessage: { _: 'Enter a number' },
              },
              correct_response_ids: [],
              default_response_ids: [],
              max_selections_required: 1,
              min_selections_required: 0,
            },
            response_type: { $ResponseType: 'NUMBER' },
            display_name: 'sleep_efficiency_sleep_quality',
            primary_key: 129,
            previous_responses: [],
          },
          component: 'SelectHorizontal',
        },
      ],
    },
  ],
});

export { getSceneForSelectHorizontal };
