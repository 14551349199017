import isFetchError from 'lib/statusCode/isFetchError';
import { Asset } from 'state/downloader/reducer';

import network from '../api/fetch';

const downloadAsset = async (remoteUri: string): Promise<string> => {
  const res = await network(remoteUri, {
    mode: 'cors',
  });

  if (isFetchError(res.status)) {
    // @todo - add detail to this error message such as res.status value
    // @when - when we have a proper error logging (e.g. Sentry) and user facing messages
    throw Error('Could not download file');
  }

  const assetBlob = await res.blob();
  const { size, type } = assetBlob;
  if (!(size > 0 && type !== '')) {
    // E.g. blob size is zero because of a CORS issue
    // {@link https://developer.mozilla.org/en-US/docs/Web/API/Body/blob}
    // @todo - add detail to this error message such "Invalid blob size or type"
    // @when - when we have a proper error logging (e.g. Sentry) and user facing messages
    throw Error('Could not download file');
  }

  return URL.createObjectURL(assetBlob);
};

const downloadRemoteAssets = async (
  assets: string[],
  onComplete: (asset: Asset) => void
): Promise<void> => {
  const promises = assets.map(async remoteURI => {
    const localUri = await downloadAsset(remoteURI);

    const newAsset = {
      localURI: localUri,
      remoteURI: remoteURI,
    };
    onComplete(newAsset);
  });
  await Promise.all(promises);
};

export default downloadRemoteAssets;
