import { Value } from 'components/forms/types';

type ValueChangeHandler = (val: Value) => void;
type OnValueChange = (value: number | null) => void;

/**
 * Handler Factory function
 * @param {OnValueChange | undefined} onValueChange
 */
const createValueChangeHandler = (
  onValueChange?: OnValueChange
): ValueChangeHandler =>
  /**
   * Actual handler
   * @param {number | string |null} val
   */
  (val): void => {
    if (val === '' || typeof onValueChange === 'undefined') {
      return;
    }
    if (typeof val === 'string') {
      const valAsNumber = +val;
      if (isNaN(valAsNumber)) {
        throw TypeError(
          `Expected "${val}" to be a string to be cast to a valid number`
        );
      }
      onValueChange(valAsNumber);
    } else {
      throw TypeError(
        `Expected "${val}" (${typeof val}) to be string, which will be cast to a number`
      );
    }
  };

export { createValueChangeHandler };
