import { ReactElement } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Theme } from 'config/getThemeForProducts';

import { PillComponent } from '../Pill/Pill';

interface SelectablePillListWrapperProps {
  children: ReactElement[];
}

export const SelectablePillListWrapper = styled(View)<
  SelectablePillListWrapperProps
>`
  padding-top: 24px;
  flex-direction: row;
  justify-content: center;
`;

const commonPillStyles: PillComponent['style'] = {
  fontSize: 16,
  borderWidth: 1,
  borderRadius: 4,
  minWidth: 140,
  alignItems: 'center',
};

export const getActiveStatePillStyles = (
  theme: Theme
): PillComponent['style'] => ({
  ...commonPillStyles,
  borderColor: theme.color.primaryGrey,
});

export const getInactiveStatePillStyles = (
  theme: Theme
): PillComponent['style'] => ({
  ...commonPillStyles,
  color: theme.color.text.placeholder,
  borderColor: theme.color.inputOutlineGrey,
});
