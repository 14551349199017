import { Question, ResponseOption } from '@bighealth/types';

import { FormProps } from 'components/forms';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { stringify } from 'lib/stringify';
import { QuestionId } from 'state/question-response/actions';

import { OnSubmitCallback } from '../..';

type CreateOnSubmitProps = {
  questions: Question[];
  onSubmit: OnSubmitCallback;
};

export const getSelectedResponseOptions = (
  questions: Question[],
  dropdownItemsByQuestion: Record<QuestionId, DropdownItem[]>
): Record<QuestionId, ResponseOption[]> => {
  const responseOptions = Object.entries(dropdownItemsByQuestion).reduce<
    Record<QuestionId, ResponseOption[]>
  >((previous, [questionIdKey, dropdownItems]) => {
    // TS infers string: https://github.com/microsoft/TypeScript/issues/32811#issuecomment-520448992
    const questionId = (questionIdKey as unknown) as keyof typeof dropdownItemsByQuestion;
    return {
      ...previous,
      [questionId]: dropdownItems
        // Only selected
        .filter(item => item.isSelected)
        // Map dropdown item
        .map((item: DropdownItem): ResponseOption | never => {
          const question: Question | undefined = questions.find(
            e => e.semantic_id === questionId
          );
          if (!question) {
            throw ReferenceError(
              `No field found for "${questionId}" in "${stringify(questions)}"`
            );
          }
          const found = question.response_config.response_options.find(
            option => option.id === item.id
          );
          if (!found) {
            throw ReferenceError(
              `Could not find ${item.id} (${stringify(item)}) in:
              "${stringify(question)}"`
            );
          }
          if (item.value === null) {
            throw TypeError(
              `Input.value cannot be null ${JSON.stringify(
                item
              )} in question ${questionId}`
            );
          }
          return {
            ...found,
            value: item.value,
          };
        }),
    };
  }, {});
  return responseOptions;
};

/**
 * Factory function
 */
const onSubmitWithResponseOptionFactory = ({
  questions,
  onSubmit,
}: CreateOnSubmitProps): FormProps['onSubmit'] => {
  /**
   * Call onSubmit with response Options (from  dropdown items)
   * @param dropdownItemsByQuestion
   * @param options
   */
  const onSubmitCallback: FormProps['onSubmit'] = (
    dropdownItemsByQuestion,
    options
  ): void => {
    const responseOptions = getSelectedResponseOptions(
      questions,
      dropdownItemsByQuestion
    );
    onSubmit(responseOptions, dropdownItemsByQuestion, options);
    options.setSubmitting(false);
  };
  return onSubmitCallback;
};

export { onSubmitWithResponseOptionFactory };
