import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';

import { getSelected } from './getSelected';

const getSelectedAsNumber = (
  items: Record<QuestionId, DropdownItem[]>,
  key: QuestionId
): number => {
  const value = getSelected(items[key]) || 0;
  if (value instanceof Date) {
    throw TypeError(`Expected number, instead got date for ${key}`);
  }
  const valueAsNumber = Number(value);
  if (isNaN(valueAsNumber)) {
    throw TypeError(
      `Expected value that could be coerced to number, instead got ${value}`
    );
  }
  return valueAsNumber;
};

export { getSelectedAsNumber };
