import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { DiaryEntry } from '@bighealth/types/src/services/SleepDiaryPayloads/types';
import { AxiosRequestConfig } from 'axios';

export const create_from_weekly_average_estimate = async (
  args: create_from_weekly_average_estimate.Args,
  requestConfig?: AxiosRequestConfig
): Promise<create_from_weekly_average_estimate.Response> => {
  const request: create_from_weekly_average_estimate.Request = {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'create_from_weekly_average_estimate',
    args,
  };

  return await api(request, requestConfig);
};

create_from_weekly_average_estimate.queryKey =
  'SleepDiary/create_from_weekly_average_estimate';

export declare namespace create_from_weekly_average_estimate {
  export type Args = Omit<DiaryEntry, 'diary_date'>;

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'create_from_weekly_average_estimate';
    args: Args;
  }

  export type Result = Omit<DiaryEntry, 'diary_date'>;

  export type Response = APIResponse<Result>;
}
