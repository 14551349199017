import { MINUTE } from 'lib/durations';

import { checkIsTimezoneOffsetNeeded } from './checkIsTimezoneOffsetNeeded';
import { moveToEpochZero } from './moveToEpochZero';

export type transformDateOnChangeArgs = {
  newDate: Date;
  oldDate?: Date;
  mode: string;
};

const transformDateOnChange = (config: transformDateOnChangeArgs): Date => {
  let date = config.newDate;
  if (config.newDate instanceof Date && config.oldDate instanceof Date) {
    const offset =
      config.newDate.getTimezoneOffset() - config.oldDate.getTimezoneOffset();
    if (offset !== 0) {
      if (config.mode === 'time' && config.newDate?.getUTCFullYear() !== 1970) {
        date = moveToEpochZero(config.newDate);
      }

      if (
        // Currently native = true, web = false
        checkIsTimezoneOffsetNeeded()
      ) {
        // ... "wind the clock" to match new timezone
        date = new Date(date.getTime() + offset * MINUTE);
      }
    }
  }
  return date;
};

export { transformDateOnChange };
