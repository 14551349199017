import { useEffect } from 'react';

import { isDefined } from 'lib/type-guarded';

import { useCursor, UseCursorReturns } from './useCursor';
import { useMeasure, UseMeasureReturns } from './useMeasure';

export type UsePaginationReturns<T> = {
  measure: UseMeasureReturns;
  cursor: UseCursorReturns<T>;
  visibleCount: number;
};
/**
 * Glues cursor together with measurements of dom
 */
export const usePagination = ({
  isPaginated,
  rows,
  all,
  initialVisibleValue,
  triggerCursorUpdateHash,
}: {
  isPaginated: boolean;
  rows: number;
  all: Date[];
  initialVisibleValue?: Date;
  triggerCursorUpdateHash: string;
}): UsePaginationReturns<Date> => {
  const measure = useMeasure({ isPaginated });
  const visibleCount = isDefined(measure.rowSize)
    ? measure.rowSize * rows
    : rows;
  const cursor = useCursor<Date>({
    all,
    visibleCount,
    initialVisibleValue,
  });

  useEffect(() => {
    if (initialVisibleValue !== undefined) {
      cursor.set(initialVisibleValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCursorUpdateHash]);
  return { cursor, visibleCount, measure };
};
