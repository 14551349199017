import React, { ComponentProps, ReactElement, useState } from 'react';

import * as roleProps from 'cross-platform/utils/roleProps';

import { Div } from './styled.web';
/**
 * IMPORTANT native has no concept of hover, so this does nothing
 */
export type HoverableProps = {
  style?: ComponentProps<typeof Div>['style']; // SLEEPIO-2657 Fix hoverable types
  disabled?: boolean;
  children(options: { isHovering: boolean }): ReactElement;
};

const Hoverable = <P extends HoverableProps>(props: P): ReactElement => {
  const { children, disabled, ...rest } = props;
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Div
      {...roleProps.filterNative(roleProps.pass(rest, 'Hoverable'))}
      onMouseEnter={() => {
        setIsHovering(disabled ? false : true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={props.style}
    >
      {children({ isHovering, ...rest })}
    </Div>
  );
};

export { Hoverable };
