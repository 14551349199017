import deepmerge from 'deepmerge'; // Deprecated; Use R.deepMergeLeft

import { TextTypes } from 'common/constants/enums';

import { ControlAttrs, createTheme } from '../utils/createTheme';

import { sleepioColors, sleepioValues } from './sleepio';

const defaultControlsV2 = {
  [ControlAttrs.highlightAndIsSelected]: {
    color: sleepioColors.progressColorDim,
    backgroundColor: sleepioColors.warningBackgroundColor,
    borderColor: sleepioColors.warningBorderColor,
    labelColor: sleepioColors.warningBorderColor,
    editColor: '#ffba44',
    border: `2px solid ${sleepioColors.warningBorderColor}`,
  },
  [ControlAttrs.isPressing]: {
    color: 'white',
    backgroundColor: sleepioColors.blackBlue,
    borderColor: sleepioColors.blackBlue,
    labelColor: '#555555',
    editColor: '#ffba44',
    border: `1px solid ${sleepioColors.borderColorGray}`,
  },
  [ControlAttrs.isActive]: {
    color: 'white',
    backgroundColor: sleepioColors.progressColorDim,
    borderColor: sleepioColors.progressColorDim,
    labelColor: 'white',
    editColor: 'white',
    border: `1px solid ${sleepioColors.progressColorDim}`,
  },
  [ControlAttrs.isSelected]: {
    color: 'white',
    backgroundColor: sleepioColors.progressColorDim,
    borderColor: sleepioColors.progressColorDim,
    labelColor: 'white',
    editColor: 'white',
    border: `1px solid ${sleepioColors.progressColorDim}`,
  },
  [ControlAttrs.highlight]: {
    color: sleepioColors.warningBorderColor,
    backgroundColor: sleepioColors.warningBackgroundColor,
    borderColor: sleepioColors.warningBorderColor,
    labelColor: sleepioColors.warningBorderColor,
    editColor: '#ffba44',
    border: `2px solid ${sleepioColors.warningBorderColor}`,
  },
  [ControlAttrs.isHovering]: {
    backgroundColor: 'transparent',
    color: sleepioColors.progressColorDim,
    borderColor: sleepioColors.progressColorDim,
    labelColor: 'white',
    editColor: 'white',
    border: `1px solid ${sleepioColors.borderColorGray}`,
  },
};

const sleepioValuesV2 = deepmerge(sleepioValues, {
  controls: {
    selection: {
      color: sleepioColors.black,
      backgroundColor: sleepioColors.white,
      borderColor: sleepioColors.borderColorGray,
      default: defaultControlsV2,
    },
    color: sleepioColors.progressColorDim,
    borderRadius: 8,
    borderWidth: '1px',
  },
  modal: {
    question: { modalQuestionTitleFontSizePx: 24 },
  },
  text: {
    [TextTypes.WARNING]: {
      color: sleepioColors.warningBorderColor,
      fontWeight: '500',
    },
  },
});

const sleepioV2Theme = createTheme(sleepioValuesV2);
export { sleepioV2Theme };
