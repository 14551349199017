import { api } from '@bighealth/api';
import { APIRequestBody, APIResponse, HttpStatusCode } from '@bighealth/api';

import isFetchError from 'lib/statusCode/isFetchError';

//
// WHY: written because  didn't see a throw in

/**
 * FIXME: Check if needed
 * WHY: written because  didn't see a throw in `api()`, but Jof pointed out
 *  there is some error handling in `useWithResponseHooks()`
 * HOW: Carefully check control flow is the same
 * WHEN: Soon
 * @see {@link src/lib/api/index.tsx@api} Didn't see throw here
 * @see {@link src/lib/api/hooks/useWithResponseHooks.tsx@useWithResponseHooks} didn't
 */
const apiAndThrow = async (body: APIRequestBody): Promise<APIResponse> => {
  const response = await api(body);
  if (typeof response.status_code === 'number') {
    if (isFetchError(response.status_code)) {
      if (response.status_code !== HttpStatusCode.UNAUTHORIZED) {
        throw Error(
          `Network error status_code: ${response.status_code}: ${body.service_name}.v${body.service_version}.${body.service_method}(...)`
        );
      } else {
        // Handled elsewhere
        // IDEA token handling within the middleware, without coupling to the component
      }
    }
  }
  return response;
};

export { apiAndThrow };
