import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components/native';

import accessibilityElements from 'common/constants/accessibilityElements';
import { isWeb } from 'common/utils/utils';
import { Text as TextUnstyled } from 'components/sceneset-components/Text';
import { SLEEP_DIARY_ROUTE_PARAMETER } from 'components/SleepDiaryWeek/constants';
import { ButtonSizes, UniversalButton } from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';
import { SECOND } from 'lib/durations';
import { FitbitUserMessage, useFitbitApi } from 'sleepio/components/Fitbit';

import { DOMAIN, SETUP_TEXT } from './constants';

const Wrapper = styled.View`
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-top: 4px;
  flex-direction: row;
  border-radius: 3.5px;
  background-color: #e6f0ff;
  justify-content: space-between;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

const Text = styled(TextUnstyled)`
  font-size: 18px;
`;
Text.displayName = `${DOMAIN}.Text`;

const TextWrapper = styled.View`
  flex-grow: 1;
  margin-top: 16px;
  justify-content: center;
`;
TextWrapper.displayName = `${DOMAIN}.TextWrapper`;

const SyncButton = styled(UniversalButton).attrs({ size: ButtonSizes.Mini })`
  width: 165px;
  min-height: 0px;
  font-size: 18px;
  margin-top: 16px;
`;
SyncButton.displayName = `${DOMAIN}.SyncButton`;

const FitBitUserMessageWrapper = styled.View`
  width: 100%;
`;
FitBitUserMessageWrapper.displayName = `${DOMAIN}.FitBitUserMessageWrapper`;

export const FitbitSetup: FunctionComponent = () => {
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const {
    isFitbitConnected,
    connect,
    reset,
    messageInfo,
    settingsFetched,
  } = useFitbitApi(SLEEP_DIARY_ROUTE_PARAMETER);

  useEffect(() => {
    if (
      isFitbitConnected &&
      messageInfo !== null &&
      typeof timer !== 'undefined'
    ) {
      timer.current = setTimeout(() => {
        reset();
      }, SECOND * 4);
    }

    return () => {
      if (typeof timer.current !== 'undefined') {
        clearTimeout(timer.current);
      }
    };
  }, [isFitbitConnected, messageInfo, reset]);

  if (!settingsFetched) {
    return null;
  }

  if (isFitbitConnected) {
    return messageInfo !== null ? (
      <Wrapper>
        <FitBitUserMessageWrapper>
          <FitbitUserMessage withScaling={false} messageInfo={messageInfo} />
        </FitBitUserMessageWrapper>
      </Wrapper>
    ) : null;
  }

  return (
    <Wrapper {...roles('FitbitSetup')}>
      <TextWrapper>
        <Text
          text={SETUP_TEXT}
          {...(isWeb() && { accessibility: accessibilityElements.P })}
        />
      </TextWrapper>
      <SyncButton
        {...roles('SyncFitbit')}
        onPress={connect}
        text={'Sync my Fitbit'}
        aria-label={
          'Sync my Fitbit. This button will take you to another webpage.'
        }
      />
      <FitBitUserMessageWrapper>
        <FitbitUserMessage withScaling={false} messageInfo={messageInfo} />
      </FitBitUserMessageWrapper>
    </Wrapper>
  );
};
