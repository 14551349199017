/* eslint-disable react/prop-types */ // TS can't handle FunctionComponent
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AccessibilityInfo, View } from 'react-native';

import { SleepWindowTypes } from '@bighealth/types/src/scene-components/SleepWindowTypes';

import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

import {
  CircleButtonSleepWindow,
  Input,
  InputContainer,
  Label,
  SetSleepWindowButton,
  SetSleepWindowTriangle,
  SubmitButtonContainer,
  TriangleContainer,
  wrappers,
} from './styled';

export type SleepWindowFormProps = {
  onSubmit?: () => void;
  onPressedSubtract?: () => void;
  onPressedAdd?: () => void;
};

export type FormPresentationProps = SleepWindowTypes['Props'] &
  SleepWindowFormProps;

/**
 * Just renders. Handlers are configured elsewhere
 * @param props
 */
const FormPresentation: FunctionComponent<FormPresentationProps> = props => {
  const {
    fields: { from_time, to_time },
    submit_button,
  } = props;
  const styles = useGetDynamicContentStyles();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const accessibilityLabelText = useMemo(
    () =>
      from_time.label.text +
      from_time.input.initialValue +
      ' and ' +
      to_time.label.text +
      to_time.input.initialValue,
    [
      from_time.label.text,
      from_time.input.initialValue,
      to_time.label.text,
      to_time.input.initialValue,
    ]
  );

  const LeftCircleButton = useCallback(
    (roleProps: RoleProps, label: string) => (
      <CircleButtonSleepWindow
        {...roleProps}
        onPress={props.onPressedSubtract}
        accessibilityLabel={label + ', Subtract time'}
      >
        <TriangleContainer>
          <SetSleepWindowTriangle
            width={styles.triangleSetSleepWindowWidth}
            height={styles.triangleSetSleepWindowHeight}
            color={'#ffffff'}
            direction={'left'}
          />
        </TriangleContainer>
      </CircleButtonSleepWindow>
    ),
    [
      props.onPressedSubtract,
      styles.triangleSetSleepWindowWidth,
      styles.triangleSetSleepWindowHeight,
    ]
  );
  const RightCircleButton = useCallback(
    (roleProps: RoleProps, label: string) => (
      <CircleButtonSleepWindow
        {...roleProps}
        onPress={props.onPressedAdd}
        accessibilityLabel={label + ', Add time'}
      >
        <TriangleContainer>
          <SetSleepWindowTriangle
            width={styles.triangleSetSleepWindowWidth}
            height={styles.triangleSetSleepWindowHeight}
            color={'#ffffff'}
            direction={'right'}
          />
        </TriangleContainer>
      </CircleButtonSleepWindow>
    ),
    [
      props.onPressedAdd,
      styles.triangleSetSleepWindowWidth,
      styles.triangleSetSleepWindowHeight,
    ]
  );

  useEffect(() => {
    if (!isFirstRender) {
      AccessibilityInfo.announceForAccessibility(accessibilityLabelText);
    } else {
      setIsFirstRender(false);
    }
  }, [from_time.input.initialValue, to_time.input.initialValue]);

  // TODO: implement the hover of the circle button for web
  return (
    <View>
      <Label
        aria-live="assertive"
        aria-label={accessibilityLabelText}
        text=""
      />
      <wrappers.Field>
        <InputContainer>
          <Label {...from_time.label} />
          <wrappers.Controls>
            {useMemo(
              () =>
                LeftCircleButton(
                  roles('SetSleepWindow.LeftCircleButton'),
                  from_time.label.text + from_time.input.initialValue
                ),
              [
                LeftCircleButton,
                from_time.input.initialValue,
                from_time.label.text,
              ]
            )}
            <Input {...from_time.input} />
            {useMemo(
              () =>
                RightCircleButton(
                  roles('SetSleepWindow.RightCircleButton'),
                  from_time.label.text + from_time.input.initialValue
                ),
              [
                RightCircleButton,
                from_time.input.initialValue,
                from_time.label.text,
              ]
            )}
          </wrappers.Controls>
        </InputContainer>
      </wrappers.Field>
      <wrappers.Field>
        <InputContainer>
          <Label {...to_time.label} />
          <wrappers.Controls>
            {useMemo(
              () =>
                LeftCircleButton(
                  roles(''),
                  to_time.label.text + to_time.input.initialValue
                ),
              [LeftCircleButton, to_time.input.initialValue, to_time.label.text]
            )}
            <Input {...to_time.input} />
            {useMemo(
              () =>
                RightCircleButton(
                  roles(''),
                  to_time.label.text + to_time.input.initialValue
                ),
              [
                RightCircleButton,
                to_time.input.initialValue,
                to_time.label.text,
              ]
            )}
          </wrappers.Controls>
        </InputContainer>
      </wrappers.Field>
      <SubmitButtonContainer>
        <SetSleepWindowButton
          title={submit_button.text}
          onPress={props.onSubmit}
          {...roles('SetSleepWindow.SubmitButton')}
        ></SetSleepWindowButton>
      </SubmitButtonContainer>
    </View>
  );
};

export { FormPresentation };
