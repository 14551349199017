import { SceneSetFormStateIdentifiers } from './EphemeralStateProvider';

const DELIMITER = '/';

export const getStateKeyFromIdentifiers = ({
  sceneSetGraphId,
  sceneSetId,
  questionId,
}: SceneSetFormStateIdentifiers): string => {
  if (!sceneSetGraphId || !sceneSetId) {
    throw new TypeError(
      `Expected sceneSetGraphId ("${sceneSetGraphId}") and sceneSetId ("${sceneSetId}")  to be defined.
      \`getStateKeyFromIdentifiers\` is probably being used outside of a SceneSet`
    );
  }
  if (typeof questionId !== 'number') {
    throw TypeError(
      `question_id of type \`${typeof questionId}\` is not allowed. Only \`number\` is permitted, instead got "${JSON.stringify(
        questionId
      )}`
    );
  }
  return `${sceneSetGraphId}${DELIMITER}${sceneSetId}${DELIMITER}${questionId}`;
};

export const getIdentifiersFromStateKey = (
  path: string
): SceneSetFormStateIdentifiers => {
  const [sceneSetGraphId, sceneSetId, questionId] = path.split(DELIMITER);
  return {
    sceneSetGraphId: parseInt(sceneSetGraphId, 10),
    sceneSetId: parseInt(sceneSetId, 10),
    questionId: parseInt(questionId, 10),
  } as SceneSetFormStateIdentifiers;
};
