import styled from 'styled-components/native';

import { TextTypes } from 'common/constants/enums';
import { getTheme } from 'lib/styles/getTheme';

import { textInputStyle } from './styles';
import PlatformInput from './TextInput';

export type TextInputProps = {
  presentationType: TextTypes;
  secureTextEntry?: boolean;
  placeholder?: string;
  placeholderTextColor?: string;
  autoCorrect?: boolean;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  maxLength?: number;
};

const TextInput = styled(PlatformInput).attrs<TextInputProps>({
  presentationType: TextTypes.DEFAULT,
})`
  ${textInputStyle};
  border: 1px solid black;
  padding-left: ${getTheme('text[presentationType].paddingHorizontal')}px;
  padding-right: ${getTheme('text[presentationType].paddingHorizontal')}px;
  padding-bottom: ${getTheme('text[presentationType].paddingVertical')}px;
  padding-top: ${getTheme('text[presentationType].paddingVertical')}px;
  background-color: white;
  font-family: '${props => props.theme.text.nativeFontFamily}';
  color: black;
  border-radius: 6px;
  font-size: ${getTheme('text[presentationType].fontSize')}px;
`;

export default TextInput;
