import { ButtonResourceEvent } from '@bighealth/analytics';
import { fire_ui_events } from '@bighealth/api/Event/v1';

import { StreamTraceEventNames } from 'components/constants';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { URLLike } from 'lib/api/analytics/useGetURL';
import { getButtonClickEventPayload } from 'lib/api/helpers/getButtonClickEventPayload/getButtonClickEventPayload';

export type ButtonPressEventParams = {
  fields: ButtonResourceEvent['body']['fields'];
  relations: ButtonResourceEvent['body']['relations'];
  name: StreamTraceEventNames;
  productId: number;
  programId: number;
  url: URLLike;
};

export const queryClientButtonClickEvent = async (
  eventParams: ButtonPressEventParams
): Promise<void> => {
  const eventPayload = await getButtonClickEventPayload(eventParams);
  try {
    await getQueryClient().fetchQuery(
      [fire_ui_events.queryKey, eventPayload.occurred_at],
      () =>
        fire_ui_events({
          events: [eventPayload],
        }),
      {
        cacheTime: 0,
      }
    );
  } catch {
    console.error('Attempted to send analytics but failed', eventPayload);
  }
};
