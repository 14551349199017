import { useCallback } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import {
  CONTENT_MIN_HEIGHT_REFERENCE_DIMENSION,
  CONTENT_MIN_REFERENCE_DIMENSION as MIN,
  contentStyles,
  ContentStylesType,
} from '../constants';
import { ScalingContext } from '../providers';

import { useGetScalingContext } from './useGetScalingContext';
import { useGetScreenDimensions } from './useGetScreenDimensions';
import { useScaleToModal } from './useScaleToModal';

/**
 * Scale a number by a key in the props (type safe) of a styled object.
 *
 * @example
 * ```js
 * const scaleBy = (sizeDecimal: number) => scaleNByPropKey<ViewProps>(sizeDecimal, 'width');
 *
 * const MyScalingImg = styled.img`
 *   width: ${scaleBy(100)}px;
 *   height: ${scaleBy(100)}px;
 * `
 * ```
 *
 * @NOTE: Specifically NOT a hook so we can use it in Styled Component
 *
 * @generic {T} the shape of the <T>props object to read.
 * @param {number} value to multiply by a value in <T>props.
 * @param {string} key in the <T>props to use to multiple with.
 * @returns {(props: T) => number | NaN} Function to be consumed by styled components to output a value.
 */
export function scaleNByPropKey<T>(value: number, key: keyof T) {
  return (props: T): number => {
    // IDEA check if can remove unknown
    //  WHEN `export interface DefaultTheme extends Theme {}` is enabled
    // @see src/config/getThemeForProducts/utils/createTheme/index.tsx
    const targetValue: number = parseInt((props[key] as unknown) as string, 10);
    return value * targetValue;
  };
}

export const useTransformContentStyle = (): ((dimension: number) => number) => {
  const insets = useSafeArea();
  const scalingContext = useGetScalingContext();
  const scaleToModal = useScaleToModal();
  const { screenWidth } = useGetScreenDimensions();
  const insetLeft = insets.left;
  const insetRight = insets.right;
  return useCallback(
    (dimension: number) => {
      // some values that go here can actually be undefined
      if (typeof dimension === 'undefined' || dimension === null) {
        return dimension;
      }
      if (scalingContext === ScalingContext.Modal) {
        return scaleToModal(dimension);
      }
      const effectiveWidth = screenWidth - insetLeft - insetRight;
      if (effectiveWidth < MIN) {
        return (dimension * effectiveWidth) / MIN;
      }
      return dimension;
    },
    [insetLeft, insetRight, scaleToModal, scalingContext, screenWidth]
  );
};

export const useTransformContentStyleVertical = (): ((
  dimension: number
) => number) => {
  const insets = useSafeArea();
  const scalingContext = useGetScalingContext();
  const scaleToModal = useScaleToModal();
  const { screenHeight } = useGetScreenDimensions();
  const insetBottom = insets.bottom;
  const insetTop = insets.top;
  return useCallback(
    (dimension: number) => {
      if (scalingContext === ScalingContext.Modal) {
        return scaleToModal(dimension);
      }
      const effectiveHeight = screenHeight - insetBottom - insetTop;
      if (effectiveHeight < MIN) {
        return (
          (dimension * effectiveHeight) / CONTENT_MIN_HEIGHT_REFERENCE_DIMENSION
        );
      }
      return dimension;
    },
    [insetBottom, insetTop, scaleToModal, scalingContext, screenHeight]
  );
};

export const useGetDynamicContentStyles = (): ContentStylesType => {
  const insets = useSafeArea();
  const transformStyle = useTransformContentStyle();
  const styles = {} as ContentStylesType;
  for (const [uncastProperty, uncastValue] of Object.entries(contentStyles)) {
    const property = uncastProperty as keyof ContentStylesType;
    const value = uncastValue as ContentStylesType[keyof ContentStylesType];
    styles[property] = transformStyle(
      value
    ) as ContentStylesType[keyof ContentStylesType];
  }
  // Add insets for notches
  styles.pagePaddingLeft = styles.pagePaddingLeft + insets.left;
  styles.pagePaddingRight = styles.pagePaddingRight + insets.right;

  return styles;
};
