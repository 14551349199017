import { getHostName } from './utils/getHostName';
import { getSource } from './utils/getSource';

/**
* PG-1904:
* In order to properly respond to users with cookies for the correct domain,

* the backend needs to know which domain the user is on/using. Client gateway,
* when responding to the user with cookies, will use information in this domain
* (one dot plus the last two words in the domain; e.g. `.sleepioqa.com` for
* anything below the sleepioqa.com domain).

* As a result of proxying traffic through CloudFront, our backend no longer can
* identify the domain the user originally made the request to (the Host header
* must be rewritten to the API Gateway domain, else API Gateway cannot route our
*  requests properly).

* X-Forwarded-Host was decided upon because:
* 1. It's the defacto standard to achieve what we're effectively trying to solve for
* 2. We can later move implementation of this to a Backend/Infrastructure layer.
* If CloudFront implements this as an option, we add a cloudfront lambda to intercept
*  requests before routing takes place (which we want to avoid for performance reasons),
* or we move to some other technology, we can implement matching logic at that level and
* remove this code from FE
*/
const getHeaderForwardedHost = (): string => getHostName(getSource());

export { getHeaderForwardedHost };
