export type PathParams = {
  productReference: string;
  sceneSetGraphId?: number;
  sceneSetId?: number;
  sceneId?: number;
};

const getParamsFromPath = (path: string): PathParams => {
  const trimmed = path.replace(/^\/|\/$/g, '');
  const [
    productReference,
    sceneSetGraphId,
    sceneSetId,
    sceneId,
  ] = trimmed.split('/');
  return {
    productReference,
    sceneSetGraphId: parseInt(sceneSetGraphId, 10),
    sceneSetId: sceneSetId ? parseInt(sceneSetId, 10) : undefined,
    sceneId: sceneId ? parseInt(sceneId, 10) : undefined,
  };
};

export default getParamsFromPath;
