import { ComponentProps, ReactElement, useContext, useEffect } from 'react';
import React from 'react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'ramda';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { ContentStateContext } from 'components/SceneSetView/providers/ContentContextProvider';
import { useDirectSubmit } from 'lib/player/useActionHandler';
import { useNavigateToNextContent } from 'lib/player/useNavigateToNextContent';

import { ResponseInput } from './ResponseInput';

type ControlProps = Omit<ComponentProps<typeof ResponseInput>, 'component'>;
export type ResponseTextInputProps = ControlProps & {
  isSingleClickEnabled: boolean;
};

export const ResponseTextInput = ({
  isSingleClickEnabled,
  ...rest
}: ResponseTextInputProps): ReactElement => {
  const submitHandler = useDirectSubmit();
  const navigateToNextContent = useNavigateToNextContent();
  const { isSubmitting, touched } = useFormikContext();

  const submitOnSelect = async (
    value: Record<string, DropdownItem[]>
  ): Promise<void> => {
    if (typeof submitHandler === 'function') {
      await submitHandler(value);
      await navigateToNextContent();
    }
  };

  const [{ isSingleClickEnabledQuestion }, updateContentState] = useContext(
    ContentStateContext
  );

  useEffect(() => {
    if (isSingleClickEnabled && !isSingleClickEnabledQuestion) {
      updateContentState({ isSingleClickEnabledQuestion: true });
    }

    return () => {
      if (isSingleClickEnabled && isSingleClickEnabledQuestion) {
        updateContentState({ isSingleClickEnabledQuestion: false });
      }
    };
  }, [isSingleClickEnabled, isSingleClickEnabledQuestion, updateContentState]);

  const disabled = isSingleClickEnabled
    ? isSubmitting || !isEmpty(touched)
    : undefined;

  return (
    <ResponseInput
      {...rest}
      disabled={disabled}
      component={'TextInput'}
      submitOnSelect={isSingleClickEnabled ? submitOnSelect : undefined}
    />
  );
};
