// NOTE snake_case used to avoid aliasing a method named in backend code
import { ClientFeatureVersions } from '@bighealth/types';
import { FormTypes } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';

type Args = {
  form_type: FormTypes;
  $date?: string;
  sleep_diary_form_version?: ClientFeatureVersions.SleepDiaryFormVersion;
};

/**
 *
 * @param $date e.g. "2020-06-30"
 * @param form_type
 * @param sleep_diary_form_version
 */
const get_diary_entry_form = ({
  form_type,
  $date,
}: Args): SleepDiaryPayloads['get_diary_entry_form']['request'] => {
  return {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'get_diary_entry_form',
    args: {
      diary_date: $date
        ? {
            $date,
          }
        : undefined,
      form_type,
      sleep_diary_form_version: 3 as ClientFeatureVersions.SleepDiaryFormVersion,
    },
  };
};

export { get_diary_entry_form };
