import React, { ReactElement, useContext } from 'react';
import { ThemeContext } from 'styled-components/native';

import { useProductStreamVersion } from 'components/Footer/hooks/useProductStreamVersion';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { Text } from 'components/sceneset-components/Text';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { FooterNav, MenuItem } from './CollapsedNav.styled';

export const FooterCollapsedNav = ({
  onLogoutPress,
}: {
  onLogoutPress: () => void;
}): ReactElement => {
  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();
  const theme = useContext(ThemeContext);
  const productStreamVersion = useProductStreamVersion({
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });

  return (
    <FooterNav>
      <MenuItem onPress={onLogoutPress}>
        <Text
          style={{
            color: 'black',
            fontWeight: 700,
            fontSize: transformStyle(18),
          }}
          text={'Log out'}
        />
      </MenuItem>
      <Text
        style={{
          paddingTop: transformVerticalStyle(32),
          color: theme.color.text.primary,
          fontSize: transformStyle(14),
        }}
        text={`© ${new Date().getFullYear()} Big Health`}
      />
      <Text
        style={{
          paddingTop: transformVerticalStyle(4),
          color: theme.color.text.primary,
          fontSize: transformStyle(14),
        }}
        text={productStreamVersion}
      />
    </FooterNav>
  );
};
