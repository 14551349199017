import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_latest_version = async (
  args: get_latest_version.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_latest_version.Response> => {
  const request: get_latest_version.Request = {
    service_name: 'ProductStreamVersion',
    service_version: '1',
    service_method: 'get_latest_version',
    args,
  };

  return await api(request, requestConfig);
};

get_latest_version.queryKey = 'ProductStreamVersion/get_latest_version';

export declare namespace get_latest_version {
  export type Args = {
    product_stream_key: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'ProductStreamVersion';
    service_version: '1';
    service_method: 'get_latest_version';
    args: Args;
  }

  export type Result = string;

  export type Response = APIResponse<Result>;
}
