import React from 'react';

import { useSafeParams } from 'components/Routes/useSafeParams';
import { ThrobberBehaviorMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useQueryProgram } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQueryProgram';

import { useQueryProductStreamVersion } from '../useQueryProductStreamVersion/useQueryProductStreamVersion';

/**
 * TODO write real isLoggedIn that inspects token state
 * WHY Rushed for release-108 hotfix
 * @param queryStatus
 * @param responseStatus
 */
const useIsLoggedIn = (
  queryStatus: string,
  responseStatus?: number
): boolean => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(responseStatus !== 401);
  if (isLoggedIn && queryStatus === 'error') {
    setIsLoggedIn(responseStatus !== 401);
  }
  return isLoggedIn;
};

const useProductStreamVersion = (options?: {
  meta: ThrobberBehaviorMeta | undefined;
}): string => {
  const getLatestVersionQuery = useQueryProductStreamVersion.get_latest_version(
    options
  );
  const programIdRes = useQueryProgram(options);
  const isLoggedIn = useIsLoggedIn(
    programIdRes.status,
    programIdRes?.error?.response?.status
  );
  const { productReference } = useSafeParams();
  const programId = programIdRes?.data?.result.id || '';
  if (typeof programId !== 'number' && isLoggedIn) {
    return ``;
  }
  const latestVersion = getLatestVersionQuery?.data?.result || '';
  if (latestVersion === '') {
    return '';
  }
  if (typeof productReference !== 'string') {
    throw TypeError(
      `Product reference must be string, instead got "${productReference}"" (${typeof productReference})`
    );
  }
  const productName =
    productReference.charAt(0).toUpperCase() + productReference.slice(1);

  return isLoggedIn
    ? `${productName}™ v${latestVersion}-${programId}`
    : `${productName}™ v${latestVersion}`;
};

export { useProductStreamVersion };
