import styled from 'styled-components/native';

import { CloseButton as CloseButtonRaw } from 'components/PopupModal/components/CloseButton';
import { TouchableOpacity as TouchableOpacityUnstyled } from 'components/primitives/cross-platform';
import { TextPresentation } from 'components/sceneset-components/Text/TextPresentation';
import { ErrorText as ErrorTextUnstyled } from 'components/Text';

const FONT_SIZE = '29.2px';
export const CloseButton = styled(CloseButtonRaw)`
  color: white;
  position: absolute;
  top: 53px;
  left: 43px;
  right: 10px;
`;
CloseButton.displayName = 'CloseButton';

export const Wrapper = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #00506b;
  opacity: 1;
  justify-content: center;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Panel = styled.View`
  flex-basis: 0;
  justify-content: center;
`;
Panel.displayName = 'Panel';

export const Title = styled(TextPresentation).attrs({
  alignment: { horizontal: 'middle' },
})`
  color: white;
  font-size: ${FONT_SIZE};
  font-weight: 700;
  margin-bottom: 8px;
  display: inline;
  width: 100%;
  text-align: center;
`;
Title.displayName = 'Title';

export const Details = styled(TextPresentation)`
  color: white;
  text-align: center;
  display: inline;
  font-size: ${FONT_SIZE};
  font-weight: 400;
`;
Details.displayName = 'Details';

export const LinkText = styled(TextPresentation).attrs({ underline: true })`
  color: white;
  font-size: ${FONT_SIZE};
  display: inline;
  font-weight: 400;
`;
LinkText.displayName = 'LinkText';

export const TouchableOpacity = styled(TouchableOpacityUnstyled)`
  display: inline;
`;
TouchableOpacity.displayName = 'TouchableOpacity';

export const Paragraph = styled.View`
  margin-top: 0;
  display: inline;
  margin-bottom: ${FONT_SIZE};
  text-align: center;
`;
Paragraph.displayName = 'Paragraph';

export const ErrorText = styled(ErrorTextUnstyled)`
  display: inline;
  text-align: center;
  font-size: ${FONT_SIZE};
`;
ErrorText.displayName = 'ErrorText';
