import { Question } from '@bighealth/types';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { parseInitialValue } from 'components/SleepDiaryForm/components/Form/helpers/parseInitialValues';
import { AWAKENINGS_IN_NIGHT } from 'components/SleepDiaryForm/constants';
import { QuestionId } from 'state/question-response/actions';

import { getSelected } from '../helpers/getSelected';
/**
 * Custom rules to hide fields based on values
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 * @param {Record<QuestionId, Question>} initialQuestionProps question props to generate initial values and props from
 */
const getHiddenFields = (
  inputs: Record<QuestionId, DropdownItem[]>,
  initialQuestionProps?: Record<QuestionId, Question | undefined>
): Record<QuestionId, Partial<{ hidden: boolean }>> => {
  const awakeningsInNight =
    getSelected(inputs['sleep_efficiency_awakenings_in_night']) ||
    (initialQuestionProps &&
      parseInitialValue(initialQuestionProps[AWAKENINGS_IN_NIGHT])) ||
    '0';

  return {
    sleep_efficiency_awakenings_total_time: {
      hidden: awakeningsInNight === '0',
    },
  };
};

export { getHiddenFields };
