import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';

import { STUB_ID } from 'components/Routes/constants';

// Closely related to Epic FEG-173 "URL"
export const FEG_210_stub_check = (
  args: jump_to_specific_scene_set.Args
): jump_to_specific_scene_set.Args => {
  return Object.entries(args).reduce(
    (prev, [key, val]) => ({
      ...prev,
      // replace any values of STUB_ID with undefined
      [key]: key.endsWith('_id') && val === STUB_ID ? undefined : val,
    }),
    args
  );
};
