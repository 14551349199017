export const testIds = {
  DayTracker: 'DayTracker',
  CompletedCircle: 'CompletedCircle',
  Circle: 'Circle',
  DayOfWeekText: 'DayOfWeekText',
};

export const status = {
  COMPLETED: 'completed',
  NOT_COMPLETED: 'not_completed',
  TODAY_COMPLETED: 'today_completed',
  TODAY_NOT_COMPLETED: 'today_not_completed',
  FUTURE_DAY: 'future_day',
};
