// localNotificationsState
/**
 * No-op on web; Only relevant in native apps.
 *
 * @see localNotificationsState.native
 */

import { NO_OP_MESSAGE__NATIVE } from '../utils/constants';

export async function _getAllScheduledNotificationsForConfigId() {
  throw Error(NO_OP_MESSAGE__NATIVE); // optional
}
export async function cancelNotificationsByConfigId() {
  throw Error(NO_OP_MESSAGE__NATIVE); // optional
}
export async function getMatchingNotificationConfigById() {
  throw Error(NO_OP_MESSAGE__NATIVE); // optional
}
export async function refreshNotifications() {
  throw Error(NO_OP_MESSAGE__NATIVE); // optional
}
export async function setAllConfiguredNotificationsByConfigId() {
  throw Error(NO_OP_MESSAGE__NATIVE); // optional
}
