import React, { ReactElement } from 'react';
import { ThemeProvider as WebThemeProvider } from 'styled-components';
import { ThemeProvider as NativeThemeProvider } from 'styled-components/native';

import { ProductReferences } from 'common/constants/enums';
import { getThemeForProduct, Theme } from 'config/getThemeForProducts';
import { useHistory } from 'cross-platform/react-router';
import getParamsFromPath from 'lib/player/getParamsFromPath';

export const CrossPlatformThemeProvider = ({
  children,
  theme,
}: {
  children: ReactElement;
  theme: Theme;
}): ReactElement => (
  <WebThemeProvider theme={theme}>
    <NativeThemeProvider theme={theme}>{children}</NativeThemeProvider>
  </WebThemeProvider>
);

const ProductThemeProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const history = useHistory();
  // Note: we generally get the productReference from useParams but not here due to how the apps are initialised
  const { productReference } = getParamsFromPath(history.location.pathname);

  const theme: Theme = getThemeForProduct(
    productReference as ProductReferences
  );

  return (
    <CrossPlatformThemeProvider theme={theme}>
      {children}
    </CrossPlatformThemeProvider>
  );
};

export default ProductThemeProvider;
