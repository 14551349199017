/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { APIClient } from '@bighealth/api';
import {
  authenticate_with_email,
  authenticate_with_facebook,
  authenticate_with_google,
} from '@bighealth/api/UserAccountAuthorization/v1';

import {
  loginWithEmailAndPassword,
  loginWithFacebookToken,
  loginWithGoogleToken,
} from 'lib/api';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import { SECOND } from 'lib/durations';
import * as reporter from 'lib/reporter';

import {
  APICallFactory,
  ErrorState,
  getLoginMethodForConfig,
  isErrorState,
  LoginCallback,
  LoginMethods,
  LoginPhases,
  LoginWithEmailPasswordConfig,
  LoginWithFacebookConfig,
  LoginWithGoogleConfig,
  OkState,
} from './types';

const requestConfig: AxiosRequestConfig = {
  timeout: 10 * SECOND,
};

export function useBigHealthLogin(): (ErrorState | OkState) & {
  login: LoginCallback;
} {
  const [loginState, setLoginState] = useState<OkState | ErrorState>({
    status: LoginPhases.IDLE,
    error: null,
  });
  const productId = useQueryProduct()?.data?.result.id;

  const login: LoginCallback = useCallback(
    async config => {
      const loginMethod = getLoginMethodForConfig(config);
      let apiCallFactory: APICallFactory | null = null;
      switch (loginMethod) {
        case LoginMethods.EMAIL_PASSWORD: {
          const { email, password } = config as LoginWithEmailPasswordConfig;
          apiCallFactory = (): Promise<authenticate_with_email.Response> =>
            loginWithEmailAndPassword(
              email,
              password,
              {
                productId: productId as number,
              },
              requestConfig
            );
          break;
        }
        case LoginMethods.FACEBOOK: {
          const { facebookToken } = config as LoginWithFacebookConfig;
          apiCallFactory = (): Promise<authenticate_with_facebook.Response> =>
            loginWithFacebookToken(
              facebookToken,
              {
                productId: productId as number,
              },
              requestConfig
            );
          break;
        }
        case LoginMethods.GOOGLE: {
          const { googleToken } = config as LoginWithGoogleConfig;
          apiCallFactory = (): Promise<authenticate_with_google.Response> =>
            loginWithGoogleToken(
              googleToken,
              {
                productId: productId as number,
              },
              requestConfig
            );
          break;
        }
        default: {
          // NO OP
        }
      }
      if (typeof apiCallFactory === 'function') {
        try {
          setLoginState({
            status: LoginPhases.FETCHING,
            error: null,
          });
          APIClient.reset();
          await apiCallFactory();
          setLoginState({
            status: LoginPhases.SUCCESS,
            error: null,
          });
        } catch (error) {
          reporter.log(error.message, error, { silent: true });
          setLoginState({
            status: LoginPhases.ERROR,
            error,
          });
        }
      }
    },
    [productId]
  );

  if (isErrorState(loginState)) {
    return {
      error: loginState.error,
      status: LoginPhases.ERROR,
      login,
    };
  }
  return {
    status: loginState.status,
    error: null,
    login,
  };
}
