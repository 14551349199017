import React from 'react';

import { ResponseOption } from '@bighealth/types';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';

import { useWithResponseHooks } from 'lib/api/hooks';
// TODO: move the hook outside of the SessionScreen to
// make it available in the whole codebase
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import { SleepDiaryService } from 'lib/api/SleepDiaryService';

import { asDiaryEntry } from '../../helpers/asDiaryEntry';
import {
  fetchSubmitDataAsync,
  OnSuccessCallback,
} from '../../helpers/fetchSubmitDataAsync';

export type Args = {
  onSuccess: OnSuccessCallback;
  renderRequest: SleepDiaryPayloads['get_diary_entry_form']['request'];
  programId: number | undefined;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
  sceneSetId?: number;
  sceneId?: number;
};

type SubmitHandler = (
  values: Record<React.ReactText, ResponseOption[]>
) => Promise<void>;

const useSubmitHandler = ({
  renderRequest,
  programId,
  setIsSubmitting,
  setError,
  onSuccess,
  sceneSetId,
  sceneId,
}: Args): SubmitHandler => {
  const productId = useQueryProduct()?.data?.result.id;
  const fetchSubmit = useWithResponseHooks(
    SleepDiaryService[renderRequest.args.form_type]
  );
  const diaryDate = renderRequest.args?.diary_date?.$date; // variable created to allow static analysis in hook dep
  const formType = renderRequest.args.form_type;

  const submitHandler = React.useCallback(
    (values: Record<React.ReactText, ResponseOption[]>): Promise<void> => {
      // IDEA fix formik wiring so submitHandler is awaited when submitForm() is called
      return fetchSubmitDataAsync({
        fetchData: fetchSubmit,
        fetchDataArgs: [
          asDiaryEntry({
            values: values,
            diaryDate,
            formType: renderRequest.args.form_type,
            productId: productId,
            programId: programId,
            ...(formType === 'create_baseline_entities' && {
              sceneSetId: sceneSetId,
              sceneId: sceneId,
            }),
          }),
        ],
        onLoadingChange: setIsSubmitting,
        // cast handler "as" param fn, as handler doesn't use param args
        onSuccess: onSuccess,
        onError: error => setError(error),
      });
    },
    [
      fetchSubmit,
      diaryDate,
      renderRequest.args.form_type,
      productId,
      programId,
      formType,
      sceneSetId,
      sceneId,
      setIsSubmitting,
      onSuccess,
      setError,
    ]
  );
  return submitHandler;
};

export { useSubmitHandler };
