import React, { ReactElement, useContext } from 'react';
import { ReactNode } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';

import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

export const MenuPortal = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  align-items: flex-start;
`;

export const Stretcher = styled.View``;
Stretcher.displayName = 'PortalStretcher';

export const ScollableContainer = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})``;

export const MenuContainer = styled.View`
  background-color: white;
  flex: 1;
  flex-basis: auto;
  min-height: auto;
  top: 0;
  right: 0;
  position: absolute;
  min-width: 70%;
`;
MenuContainer.displayName = 'MenuContainer';

export const ShadowedMenuContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { screenHeight, screenWidth } = useContext(
    ScreenResponsiveLayoutContext
  );
  const transformStyle = useTransformContentStyle();
  const widthCollapsedNav = transformStyle(280);
  return (
    <MenuContainer
      style={{
        height: screenHeight,
        width: widthCollapsedNav,
        shadowColor: 'black',
        shadowRadius: 0,
        shadowOffset: {
          width: -(screenWidth - widthCollapsedNav),
          height: 0,
        },
        shadowOpacity: 0.3,
        backgroundColor: 'white',
        elevation: 30,
      }}
    >
      {children}
    </MenuContainer>
  );
};

export const NameContainer = styled(View)`
  height: 21%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.color.deselectedGrey};
`;
NameContainer.displayName = 'NameContainer';

export const ItemsContainer = styled(View)`
  height: 58%;
  width: 100%;
  background-color: ${props => props.theme.color.white};
`;
ItemsContainer.displayName = 'ItemsContainer';

export const MenuItem = styled(TouchableOpacity)``;

export const FooterNav = styled.View`
  height: 21%;
  align-items: center;
`;
