import React, { ReactElement } from 'react';
import { View } from 'react-native';

import { Circle } from 'components/sceneset-components/Shape';
import { roles } from 'cross-platform/utils/roleProps';

import { SmallCircleCheckIcon } from '../icons/SmallCircleCheckIcon';

import { DayOfWeekContainer, DayOfWeekText } from './DayTracker.styled';
import { DayOfWeekThemeProps } from './types';
import { status, testIds } from './WeekTrackerConstants';

export const DayTracker = ({
  dayOfWeek,
  dayOfWeekStatus,
  dayOfWeekTheme,
}: {
  dayOfWeek: string;
  dayOfWeekStatus: string;
  dayOfWeekTheme: DayOfWeekThemeProps;
}): ReactElement => {
  const {
    backgroundColor,
    circleFillColor,
    circleStrokeColor,
    fontWeight,
    circleStrokeWidth,
  } = dayOfWeekTheme;

  const isCompleted =
    dayOfWeekStatus !== status.FUTURE_DAY &&
    !dayOfWeekStatus.includes(status.NOT_COMPLETED);

  return (
    <View {...roles(testIds.DayTracker)}>
      <DayOfWeekContainer style={{ backgroundColor }}>
        {isCompleted ? (
          <SmallCircleCheckIcon {...roles(testIds.CompletedCircle)} />
        ) : (
          <Circle
            fill={circleFillColor}
            radius={28}
            stroke={{
              color: circleStrokeColor,
              weight: circleStrokeWidth,
            }}
          />
        )}

        <DayOfWeekText
          {...roles(testIds.DayOfWeekText)}
          style={{ fontWeight: fontWeight }}
        >
          {dayOfWeek}
        </DayOfWeekText>
      </DayOfWeekContainer>
    </View>
  );
};
