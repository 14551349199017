import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components/native';

import { FillAbsolute } from 'components/Containers';
import { BottomNavigation } from 'components/Navigation/BottomNavigation';
import { NavBarStyles } from 'components/Navigation/constants';
import { GlobalNavigationBar } from 'components/Navigation/GlobalNavigationBar';
import { NavigationBarProvider } from 'components/Navigation/providers/NavigationBarProvider';
import { MobileOptions } from 'components/Navigation/types';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { renderFallbackReturnsHomeForDomainFactory } from 'components/Routes/SleepioSwitch/components/renderFallbackReturnsHomeForDomainFactory';
import { roles } from 'cross-platform/utils/roleProps';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import { createErrorBoundaryForDomain } from 'lib/error/utils/createErrorBoundaryForDomain';

import { sleepioTabItems } from './data/sleepioTabItems';

const ERROR_DOMAIN = 'GlobalNavigation';
const ErrorBoundaryForGlobalNavigation = createErrorBoundaryForDomain(
  ERROR_DOMAIN
);
const renderFallbackForGlobalNavigation = renderFallbackReturnsHomeForDomainFactory(
  ERROR_DOMAIN
);

const HeightWithNav = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const WithGlobalNavigation = ({
  children,
  enableMobileMode,
  navigationTheme,
}: {
  children: ReactNode;
} & MobileOptions): ReactElement => {
  const [bottomNavigationHeight, setBottomNavigationHeight] = useState(0);
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  // NOTE: we are moving to a responsive layout not dependant device detection for navigation
  // Transparency is only for the collapsed nav so space is only needed when width and height are appropriate
  const allowSpaceForNav = !(
    enableMobileMode &&
    screenWidth <= NavBarStyles.navBarCollapseTabletWebBreakPointPx
  );
  return (
    <ErrorBoundaryForGlobalNavigation
      fallbackRender={renderFallbackForGlobalNavigation}
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
    >
      <FillAbsolute>
        <NavigationBarProvider
          mobileOptions={{ enableMobileMode, navigationTheme }}
        >
          <>
            <HeightWithNav
              {...roles('WithGlobalNavigation-Content')}
              style={{
                bottom: bottomNavigationHeight,
                top: allowSpaceForNav ? NavBarStyles.navBarHeightPx : 0,
              }}
            >
              {children}
            </HeightWithNav>
            <BottomNavigation
              tabItemsData={sleepioTabItems}
              setBottomNavigationHeight={setBottomNavigationHeight}
            />
            <GlobalNavigationBar />
          </>
        </NavigationBarProvider>
      </FillAbsolute>
    </ErrorBoundaryForGlobalNavigation>
  );
};
