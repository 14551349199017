import React, { ReactElement, useCallback } from 'react';

import { get_settings } from '@bighealth/api/UserDaylightMetadata/v1';

import { useBackHandler } from 'components/backHandler/useBackHandler';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { useSafeParams } from 'components/Routes/useSafeParams';
import MFAModal from 'components/Screens/SettingsScreen/MFA/MFAModal';
import { useHistory } from 'cross-platform/react-router';
import { useQueryDaylightSettings } from 'lib/api/reactQueryHelpers';

export const MFAManagementView = (): ReactElement => {
  const { data } = useQueryDaylightSettings();
  const { mfa_enabled } = data?.result || {};
  const history = useHistory();
  const queryClient = getQueryClient();
  const { productReference } = useSafeParams();
  useBackHandler(productReference);

  const closeMFAModal = useCallback(() => {
    history.push(`/${productReference}/home`, { openSettingsNav: true });
  }, [history, productReference]);

  const updateMFASettings = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [get_settings.queryKey],
      exact: true,
    });
  }, [queryClient]);

  return (
    <MFAModal
      isMFAEnabled={mfa_enabled}
      onRequestClose={closeMFAModal}
      updateMFASettings={updateMFASettings}
      testID={'MFAModal'}
    />
  );
};
