export enum VisualState {
  // Case A - Show reminder prompt
  A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT = 'SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT',
  // Case B - Show go to settings
  B__SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT = 'SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT',
  // Case C - Show turn on app notification
  C__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF = 'SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF',
  // Case D - Show date picker
  D__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON = 'SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON',
  // bad case
  E__EXCEPTION = 'EXCEPTION',
}

export const getVisualStateFromNotifState = (
  canNativeOsPermsRequestBeCalled: boolean,
  isOSNotifTurnedOn: boolean, // permission enabled notifee
  isAppNotifTurnedOn: boolean
): VisualState => {
  switch (
    `${canNativeOsPermsRequestBeCalled}-${isOSNotifTurnedOn}-${isAppNotifTurnedOn}`
  ) {
    case 'true-false-false':
      return VisualState.A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT;
    case 'true-false-true':
    case 'false-false-true':
    case 'false-false-false':
      return VisualState.B__SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT;
    case 'true-true-false':
    case 'false-true-false':
      return VisualState.C__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF;
    case 'true-true-true':
    case 'false-true-true':
      return VisualState.D__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON;
    default:
      return VisualState.E__EXCEPTION;
  }
};
