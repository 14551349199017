import React, { ReactElement, ReactNode, useContext } from 'react';

import { NavBarStyles } from 'components/Navigation/constants';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import {
  CALENDAR_CHART_MARGIN_LEFT,
  NARROW_CALENDAR_CHART_MARGIN_LEFT,
} from 'components/SleepDiaryWeek/constants';
import { roles } from 'cross-platform/utils/roleProps';

import { BarChartColumnCont } from './styled';

export const BarChartColumnContainer = ({
  paddingTop,
  children,
}: {
  paddingTop: number;
  children: ReactNode;
}): ReactElement => {
  const { screenWidth, screenHeight } = useContext(
    ScreenResponsiveLayoutContext
  );
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;
  const isLandscape = screenWidth > screenHeight;

  return (
    <BarChartColumnCont
      {...roles('sleepDiaryChartColumnContainer')}
      style={{
        paddingTop: paddingTop,
        marginLeft:
          isMobileWeb && !isLandscape
            ? NARROW_CALENDAR_CHART_MARGIN_LEFT
            : CALENDAR_CHART_MARGIN_LEFT,
      }}
    >
      {children}
    </BarChartColumnCont>
  );
};
