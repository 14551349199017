import {
  Diary,
  DiaryStatus,
} from '@bighealth/types/dist/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

type ShouldContinueButtonExtrapolateProps = {
  diaryDays: Diary[];
  minCompleteDiaryCount: number | undefined;
  maxCompleteDiaryCount: number | undefined;
};

const getNumberOfCompleteDiaries = (diaries: Diary[]) => {
  return diaries.filter(diary => diary.status === DiaryStatus.Complete).length;
};

const shouldContinueButtonExtrapolate = ({
  diaryDays,
  minCompleteDiaryCount,
  maxCompleteDiaryCount,
}: ShouldContinueButtonExtrapolateProps) => {
  const numberOfCompleteDiaries = getNumberOfCompleteDiaries(diaryDays);

  const numberOfDiariesSatisfiesMinimumConstraint =
    typeof minCompleteDiaryCount === 'number' &&
    numberOfCompleteDiaries >= minCompleteDiaryCount;

  const numberOfDiariesSatisfiesMaximumConstraint =
    typeof maxCompleteDiaryCount === 'number' &&
    numberOfCompleteDiaries <= maxCompleteDiaryCount;

  return (
    numberOfDiariesSatisfiesMinimumConstraint &&
    numberOfDiariesSatisfiesMaximumConstraint
  );
};

export { shouldContinueButtonExtrapolate };
