import * as React from 'react';
import { ReactElement } from 'react';
import { G, Path, Rect, Svg } from 'react-native-svg';

export const CircleLockIcon = ({
  width,
  height,
  viewBox,
  circleColor,
  lockColor,
  opacity,
}: {
  width: number;
  height: number;
  viewBox: string;
  circleColor?: string;
  lockColor?: string;
  opacity?: number;
}): ReactElement => {
  return (
    <Svg
      width={width}
      height={height}
      opacity={opacity}
      viewBox={viewBox}
      fill={'none'}
    >
      <G>
        <Rect
          width={width}
          height={height}
          fill={circleColor}
          rx={`${width / 2}`}
        />

        <G translateX={width / 3} translateY={height / 4}>
          <Rect
            x="0"
            y={`${width / 4.5}`}
            width={`${width / 3}`}
            height={`${width / 4}`}
            rx="2"
            fill="white"
          />
          <Path
            scale={width / 55}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 0C5.51735 0 2.6941 2.82325 2.6941 6.30591V15.1079H15.3059V6.3059C15.3059 2.82325 12.4827 0 9 0ZM9.00001 2.62797C7.25868 2.62797 5.84706 4.0396 5.84706 5.78092V11.1672H12.153V5.78092C12.153 4.0396 10.7413 2.62797 9.00001 2.62797Z"
            fill={lockColor}
          />
        </G>
      </G>
    </Svg>
  );
};
