import React, {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface MessageContextType {
  isSomeActionInProgress: boolean;
  setIsSomeActionInProgress: Dispatch<SetStateAction<boolean>>;
}

const PracticeLibraryScreenContext = createContext<
  MessageContextType | undefined
>(undefined);

const usePracticeLibraryScreenContext = () =>
  useContext(PracticeLibraryScreenContext);

/*
 This context is mainly made to share the current state of the execution of activities 
  triggered by the PlayButton component. To achieve this without passing down props 
  through all the nested components from PracticeLibraryScreen to PlayButton and without 
  modifying the functionality of the PlayButton component.

  The purpose of the state shared by the context is to prevent the user from triggering 
  PlayButton actions until the current action is completed.
*/
const PracticeLibraryScreenProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [isSomeActionInProgress, setIsSomeActionInProgress] = useState(false);

  return (
    <PracticeLibraryScreenContext.Provider
      value={{ isSomeActionInProgress, setIsSomeActionInProgress }}
    >
      {children}
    </PracticeLibraryScreenContext.Provider>
  );
};

export { PracticeLibraryScreenProvider, usePracticeLibraryScreenContext };
