import React, { ComponentProps, PropsWithChildren, useState } from 'react';

import { Question } from '@bighealth/types';

import { OnValidateCallback } from 'components/forms';
import { QuestionId } from 'state/question-response/actions';

import { Form } from '../Form';
import { parseDiaryDateStr } from '../Form/helpers/parseDiaryDateStr';

import { getFlowState } from './helpers/getFlowState';

type FlowState = ReturnType<typeof getFlowState>;

type Props = ComponentProps<typeof Form>;

/**
 * Provides inter-field flow logic to the form
 * - visibility
 * - warnings
 * - initial values
 *
 * @param props {ComponentProps<typeof Form>}
 */
const FlowingForm = (
  props: PropsWithChildren<Props> & { onClose?: () => void }
): React.ReactElement => {
  const { fieldProps, form } = props;
  const initialQuestionProps: Record<QuestionId, Question> = {};
  for (const section of form.sections) {
    for (const group of section.group) {
      initialQuestionProps[group.questionProps.semantic_id] =
        group.questionProps;
    }
  }

  const diaryDate: Date | undefined =
    typeof props.diaryDateStr !== 'undefined'
      ? parseDiaryDateStr(props.diaryDateStr)
      : undefined;

  const [fieldState, setFieldState] = useState<FlowState>(
    // FlowingFormProps.fieldProps is used for debugging/testing
    fieldProps || getFlowState({}, initialQuestionProps, diaryDate)
  );

  const validateHandler: OnValidateCallback = items => {
    setFieldState(getFlowState(items, undefined, diaryDate));
    return {};
  };

  return (
    <Form {...props} fieldProps={fieldState} onValidate={validateHandler} />
  );
};

export { FlowingForm };
