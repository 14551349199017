import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';

import { ButtonContainerWithMinMax } from 'components/Button/components';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import {
  ErrorContainer,
  ValidationText,
} from 'components/Screens/LoginScreen/styled';
import { ButtonTypes } from 'config/index';
import { roles } from 'cross-platform/utils/roleProps';
import { useLoginWithOIDC } from 'lib/api/hooks/useLogin/useLoginWithOIDC';

import { SvgNHSButton } from './SvgNHSButton';

export const NHSPaddingContainer = styled.View`
  flex-direction: column;
  margin-bottom: 32px;
  display: flex;
  align-items: stretch;
  padding: 0 8px 0;
`;
export const ErrorText = styled(ValidationText)`
  text-align: center;
`;

const errorMessage =
  'Unable to log in. Please try again or contact us for support.';

export const NHSLoginButton = ({ flagHide = false }) => {
  const { login, loading, error } = useLoginWithOIDC();

  // The aspect ration for the NHS svg button. Used to preserve correct sizing.
  // The numbers used taken from the viewbox of the svg.
  const svgAspectRatio = 320.22 / 59;

  const handleSubmit = async (): Promise<void> => {
    const loginType = 'nhs';
    login(loginType);
  };

  // TODO: Hook up a runtime feature flag
  if (flagHide) {
    return null;
  }

  return (
    <>
      <NHSPaddingContainer>
        <ButtonContainerWithMinMax type={ButtonTypes.PRIMARY_BUTTON}>
          <TouchableOpacity
            {...roles('nhs-login-button')}
            onPress={handleSubmit}
            disabled={loading}
            accessible={true}
            accessibilityLabel="Continue to NHS login"
            accessibilityRole="button"
          >
            <View
              style={{
                aspectRatio: svgAspectRatio,
              }}
            >
              <SvgNHSButton colorTheme="normal" />
            </View>
          </TouchableOpacity>
        </ButtonContainerWithMinMax>
        {error ? (
          <ErrorContainer {...roles('nhs-login-error')}>
            <ErrorText>{errorMessage}</ErrorText>
          </ErrorContainer>
        ) : null}
      </NHSPaddingContainer>
      {loading ? <LoadingOverlay /> : null}
    </>
  );
};

export default NHSLoginButton;
