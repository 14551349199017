import { isMobileWeb } from 'config/isMobileWeb';

import { getCardStyles } from './helpers/getCardStyles';
import { SessionsStyles } from './types';

export const useSessionsStyles = (): SessionsStyles => {
  return {
    cardStyles: getCardStyles(!isMobileWeb),
  };
};
