import React from 'react';

import { Text } from 'components/primitives/cross-platform';
import { SpeechLocation } from 'config/getThemeForProducts/utils/createTheme/SpeechLocationSchemesTypes';

import { BaseContainerProps } from '..';

import { Invisible, InvisibleProps, Solid, Triangle } from './styled';

// Speech attribute (more meta expected, so object)
export type Speech = {
  location: SpeechLocation;
};

// Props
export type ContentProps = {
  speech?: Speech;
  mono?: boolean;
} & InvisibleProps &
  BaseContainerProps;
// IDEA Enable in a way that doesn't need theme props in stories, or do. I don't know.
// & ThemeProp;

const Content = (props: ContentProps): React.ReactElement => (
  <Invisible>
    <Solid {...props}>
      {!props.children ? null : typeof props.children === 'string' ? (
        <Text>{props.children}</Text>
      ) : (
        props.children
      )}
    </Solid>
    {props.speech ? <Triangle direction={props.speech.location} /> : null}
  </Invisible>
);
export { Content };
