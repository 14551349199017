/* eslint-disable @typescript-eslint/no-unused-vars */
const openInAppBrowser = async (
  url: string,
  _openAuth?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _optionOverrides?: Record<string, any>
): Promise<void> => {
  window.location.href = url;
};

export { openInAppBrowser };
