// NOTE: This file is only for 📖 Storybook & ✅ Tests
import React from 'react';

import { Container } from 'components/Screens/ContentScreens/components/ContentPlaceholder';
import {
  FallbackProps,
  FallbackRenderWithWrapper,
} from 'lib/error/ErrorBoundary/types';

const withWrapper: FallbackRenderWithWrapper = fn => (args: FallbackProps) => (
  <Container>{fn?.(args)}</Container>
);

export { withWrapper };
