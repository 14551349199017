import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { ProgressEntries } from '@bighealth/types';
import { AxiosRequestConfig } from 'axios';

export const get_user_goal_progress = async (
  args: get_user_goal_progress.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_user_goal_progress.Response> => {
  const request: get_user_goal_progress.Request = {
    service_name: 'SleepioUserGoal',
    service_version: '1',
    service_method: 'get_user_goal_progress',
    args,
  };

  return await api(request, requestConfig);
};

get_user_goal_progress.queryKey = 'SleepioUserGoal/get_user_goal_progress';

export declare namespace get_user_goal_progress {
  export type Args = {
    product_id: number;
    program_id: number;
    request_source: 'session' | 'casefile';
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepioUserGoal';
    service_version: '1';
    service_method: 'get_user_goal_progress';
    args: Args;
  }

  export type Result = ProgressEntries;

  export type Response = APIResponse<Result>;
}
