import React, { ReactElement } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { GrayBox, WhiteBox } from 'daylight/components/ghosts/ghosts';
import { CtaCirclePlayIcon } from 'daylight/components/icons';
import { useTransformContentStyle } from 'daylight/hooks';

const Wrapper = styled.View`
  background-color: #ddd;
  border-radius: 8px;
  margin-bottom: 16px;
`;
Wrapper.displayName = 'PracticeLibraryCardGhostWrapper';

const Row = styled.View`
  flex-direction: row;
`;
Row.displayName = 'Row';

const CardTop = styled(GrayBox)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  justify-content: flex-end;
  padding-left: 23px;
`;
CardTop.displayName = 'CardTop';

const CardBottom = styled.View`
  background-color: ${({ theme }) => theme.color.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
`;
CardBottom.displayName = 'CardBottom';

const ButtonWrapper = styled.View`
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

export const CardGhost = (): ReactElement => {
  const transformV = useTransformContentStyleVertical();
  const transform = useTransformContentStyle();
  return (
    <Wrapper>
      <CardTop height={transformV(170)} width="100%">
        <WhiteBox
          height={transformV(29)}
          width="20%"
          style={{ marginRight: transform(7), marginBottom: transformV(13) }}
        />
      </CardTop>
      <CardBottom>
        <Row>
          <View style={{ width: '75%', paddingTop: transformV(16) }}>
            <GrayBox
              height={transformV(24)}
              width="30%"
              style={{ marginBottom: transformV(13) }}
            />
            <GrayBox
              height={transformV(24)}
              width="100%"
              style={{
                marginTop: -transformV(5),
                marginBottom: transformV(33),
              }}
            />
          </View>

          <ButtonWrapper>
            <CtaCirclePlayIcon
              height={transform(56)}
              width={transform(56)}
              viewBox="0 0 56 56"
              circleColor="black"
              triangleColor="white"
            />
          </ButtonWrapper>
        </Row>
      </CardBottom>
    </Wrapper>
  );
};
