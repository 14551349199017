import React, {
  AriaRole,
  HTMLProps,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import styled, { CSSProperties } from 'styled-components';

import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { Value } from 'components/forms/types';
import { DropdownCaret } from 'components/icons';
import { Select } from 'components/primitives/web-only';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { filterNative, roles } from 'cross-platform/utils/roleProps';
import {
  ButtonStates,
  getStylesForButtonStates,
} from 'lib/styles/inputStateStyles';

import { fromValueToSelectHTMLAttributesValue } from '../helpers/fromValueToSelectHTMLAttributesValue';

import { NO_OPTION_SELECTION_TEXT } from './constants';
import { DropdownItem, RawDropdownProps } from './types';

interface SelectHandlers {
  onClick?: () => void;
  onBlur?: (e: unknown) => void;
  onFocus?: () => void;
  selected?: boolean;
}

const StyledPickerStatic = styled(Select)<ButtonStates & SelectHandlers>`
  appearance: none;
  background-color: ${props =>
    getStylesForButtonStates('backgroundColor', props.theme)(props)};
  color: ${props => getStylesForButtonStates('color', props.theme)(props)};
  border: ${props => getStylesForButtonStates('border', props.theme)(props)};
  cursor: pointer;
  flex-grow: 1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

type StyledPickerType = ButtonStates &
  SelectHandlers & {
    id?: string;
    style?: CSSProperties;
    children?: ReactNode;
    onChange?: HTMLProps<HTMLSelectElement>['onChange'];
    value?: Value;
    role?: AriaRole;
  };
const StyledPicker = ({
  style,
  children,
  highlight,
  isSelected,
  value,
  isActive,
  ...rest
}: StyledPickerType): ReactElement => {
  const {
    height,
    borderRadius,
    paddingLeft,
    paddingTop,
    fontSize,
    caretSize,
    inputIconMarginRight,
    inputPaddingRightExtra,
  } = useGetDynamicInputStyles();
  const [hover, setHover] = useState(false);
  return (
    <Container>
      <StyledPickerStatic
        {...rest}
        highlight={highlight}
        style={{
          ...(style || {}),
          height,
          borderRadius,
          paddingLeft,
          paddingRight: inputPaddingRightExtra,
          paddingTop,
          paddingBottom: 15,
          fontSize,
        }}
        value={fromValueToSelectHTMLAttributesValue(value)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        isActive={isActive}
        isSelected={isSelected}
        isHovering={hover}
        role="listbox"
        aria-expanded={isActive}
      >
        {children}
      </StyledPickerStatic>
      <DropdownCaret
        accessible={false}
        pointerEvents="none"
        style={{
          position: 'absolute',
          top: 0.5 * (height - caretSize),
          right: inputIconMarginRight,
          color: getStylesForButtonStates('color')({
            isActive: isActive,
            highlight,
            isHovering: hover,
            isSelected: isSelected,
          }),
          pointerEvents: 'none',
        }}
        size={caretSize}
      />
    </Container>
  );
};

export const DropdownOption = ({
  displayText,
  value,
}: DropdownItem): ReactElement => {
  return (
    <option
      id={`${value}`}
      role="option"
      {...filterNative(roles('DropdownOption'))}
      value={`${value}`}
    >
      {displayText}
    </option>
  );
};

export const Dropdown = ({
  id,
  value,
  onValueChange,
  children,
  highlight,
  hideEmptyOption,
}: RawDropdownProps): ReactElement => {
  const {
    onFocus,
    onBlur: onContextBlur,
    questionInFocus,
  } = useInputFocusContext();
  // true for SleepDiaryForm else false
  const showPleaseSelect = !hideEmptyOption || !value?.[0]?.value;

  const handleValueChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    onValueChange(event.currentTarget.value);
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = () => {
    onContextBlur();
  };
  return (
    <StyledPicker
      id={id}
      {...roles('DropdownPicker')}
      value={value?.[0]?.value}
      onChange={handleValueChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      isSelected={!!value?.length}
      highlight={highlight}
      isActive={questionInFocus}
    >
      {showPleaseSelect ? (
        <option {...filterNative(roles('DropdownOption'))} value={''}>
          {NO_OPTION_SELECTION_TEXT}
        </option>
      ) : null}
      {children}
    </StyledPicker>
  );
};
