import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { Scene } from '@bighealth/types/dist/scene-component';
import { CalendarProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period/';

import accessibilityElements from 'common/constants/accessibilityElements';
import { isWeb } from 'common/utils/utils';
import {
  useGetDynamicContentStyles,
  useGetScreenDimensions,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { matchesSceneSet } from 'components/Routes/matchesRoutes';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';
import { isMobileWeb } from 'config/isMobileWeb';
import { roles } from 'cross-platform/utils/roleProps';

import { getTileBackgroundColor } from './utils/getTileBackgroundColor';
import { ExtrapolateButton } from './ContinueButton';
import { PagingButton } from './PagingButton';
import {
  CalendarContainer,
  CalendarExtrapolateButtonContainer,
  CalendarTile,
  CalendarTileButtonLabel,
  CalendarTileContainer,
  CalendarTileDayOfMonth,
  CalendarTileDayOfWeek,
  CalendarTileInner,
  CalendarViewContainer,
} from './styled';

type CalendarPropsWithActions = CalendarProps & {
  title: {
    text: string;
    fontSize: number;
    color: string;
  };
  titleAlignment: Scene.Presentational.Alignment;
  subtitle: {
    fontSize: number;
    color: string;
  };
  subtitleAlignment: Scene.Presentational.Alignment;
  pageForwardPress: () => void;
  pageBackwardPress: () => void;
  onPressCalendarTile: (diary_date: string | undefined) => void;
  shouldShowContinueButton: boolean;
  isContinueButtonEnabled?: boolean;
  continueButtonText?: string;
  shouldContinueButtonExtrapolate: boolean;
  setIsLoading: (loading: boolean) => void;
};

/**
 * Component for Web.
 * Based on the screen width we differentiate between Mobile and Desktop.
 */
const Calendar: FunctionComponent<CalendarPropsWithActions> = (
  props: CalendarPropsWithActions
) => {
  const { screenWidth, screenHeight } = useGetScreenDimensions();
  const history = useHistory();
  const isContent = matchesSceneSet(history);
  const isLandscape = screenWidth > screenHeight;
  const pageBackButtonEnabled = props.has_prev;
  const pageBackwardPress = props.pageBackwardPress;
  const pageForwardButtonEnabled = props.has_next;
  const pageForwardPress = props.pageForwardPress;

  const scaleFn = useTransformContentStyle();
  const styles = useGetDynamicContentStyles();
  const calendarTileButtonLabelCompleted =
    isMobileWeb && isLandscape ? scaleFn(19) : scaleFn(30);
  const dayOfMonthFontSize =
    isMobileWeb && isLandscape ? scaleFn(36) : scaleFn(60);
  const isSleepDiaryWeekContentMobileWeb =
    isMobileWeb && !isLandscape && isContent;

  return (
    <CalendarContainer
      {...roles(`sleepDiaryCalendarContainer`)}
      style={{
        paddingBottom: styles.sleepDiaryWeekCalendarContainerPaddingBottom,
      }}
    >
      <CalendarViewContainer
        style={{
          marginTop: styles.sleepDiaryWeekCalendarViewContainerMarginTop,
        }}
      >
        <PagingButton
          {...roles('sleepDiaryCalendarPagingButtonLeft')}
          disabled={!pageBackButtonEnabled}
          onPress={pageBackwardPress}
          icon={'left'}
          iconSize={styles.sleepDiaryWeekCalendarIconSize}
        />
        <CalendarTileContainer
          style={
            isSleepDiaryWeekContentMobileWeb && {
              flex: 1,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }
          }
        >
          {props.days.map((day, index) => (
            <CalendarTile
              {...roles(`sleepDiaryCalendarTile${index}`)}
              disabled={day.status === 'LOCKED' || !day.click_action}
              key={index}
              style={{
                backgroundColor: getTileBackgroundColor(day.status),
                paddingBottom: 9,
                width: isSleepDiaryWeekContentMobileWeb ? '20.5%' : '12.5%',
                marginTop: isSleepDiaryWeekContentMobileWeb ? 10 : 0,
                marginLeft: isSleepDiaryWeekContentMobileWeb ? 10 : 0,
              }}
              onPress={() => props.onPressCalendarTile(day.date)}
              accessibilityRole="button"
              // this ariaLabel is needed it, after add ariaHidden to solve windows accessibility issues
              aria-label={`${day?.day_name?.text} ${day?.day_of_month?.text} ${
                day.status == 'MISSING' ? '+Add' : ''
              }`}
            >
              <CalendarTileInner>
                <CalendarTileDayOfWeek
                  {...day.day_name}
                  style={{
                    fontSize:
                      isMobileWeb && !isLandscape ? scaleFn(24) : scaleFn(18),
                    marginTop:
                      styles.sleepDiaryWeekCalendarTileDayOfWeekMarginTop,
                  }}
                  color={sleepioColors.altDarkBlue}
                  {...(isWeb() && { accessibility: accessibilityElements.P })}
                  aria-hidden={true} // to solve windows accessibility issues
                />
                <CalendarTileDayOfMonth
                  {...day.day_of_month}
                  style={{
                    fontSize: dayOfMonthFontSize,
                  }}
                  color={sleepioColors.altDarkBlue}
                  {...(isWeb() && { accessibility: accessibilityElements.P })}
                  aria-hidden={true} // to solve windows accessibility issues
                />
                {day.metrics && day.status == 'COMPLETE' && isContent ? (
                  <CalendarTileButtonLabel
                    {...day.metrics[0].value}
                    {...roles(`sleepDiaryCalendarTileEditButton${index}`)}
                    fontSize={calendarTileButtonLabelCompleted}
                    color={sleepioColors.darkOrange}
                    style={{ fontWeight: 700 }}
                    {...(isWeb() && { accessibility: accessibilityElements.P })}
                  />
                ) : null}
                {day.status == 'MISSING' ? (
                  <CalendarTileButtonLabel
                    {...roles(`sleepDiaryCalendarTileCreateButton${index}`)}
                    text={'+Add'}
                    fontSize={
                      isMobileWeb && !isLandscape ? scaleFn(26) : scaleFn(19)
                    }
                    color={sleepioColors.altDarkBlue}
                    style={{ fontWeight: 700 }}
                    {...(isWeb() && { accessibility: accessibilityElements.P })}
                    aria-hidden={true} // to solve windows accessibility issues
                  />
                ) : null}
              </CalendarTileInner>
            </CalendarTile>
          ))}
        </CalendarTileContainer>
        <PagingButton
          {...roles('sleepDiaryCalendarPagingButtonRight')}
          disabled={!pageForwardButtonEnabled}
          onPress={pageForwardPress}
          icon={'right'}
          iconSize={styles.sleepDiaryWeekCalendarIconSize}
        />
      </CalendarViewContainer>
      {props.shouldShowContinueButton ? (
        <CalendarExtrapolateButtonContainer
          style={{
            marginTop: styles.sleepDiaryWeekCalendarExtrapolateButtonMarginTop,
          }}
        >
          <ExtrapolateButton
            disabled={!props.isContinueButtonEnabled}
            setIsLoading={props.setIsLoading}
            text={props.continueButtonText || 'Continue'}
            shouldExtrapolate={props.shouldContinueButtonExtrapolate}
          />
        </CalendarExtrapolateButtonContainer>
      ) : null}
    </CalendarContainer>
  );
};

export { Calendar };
