import * as React from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { SceneSet, SceneSetGraph } from '@bighealth/types';

import { useGetScreenDimensions } from 'components/ResponsiveLayout';
import { ButtonColorSchemes } from 'components/UniveralButtons';

import {
  Button,
  ButtonWrapper,
  HeadingText,
  HeadingWrapper,
  WidgetWrapper,
} from './styled/';

type JumpToSceneSetAction = {
  type: 'jump_to_sceneset';
  payload: {
    sceneSetGraphId: SceneSetGraph['id'];
    destinationSceneSetId: SceneSet['id'];
  };
};

export type ProgressReviewWidgetProps = {
  heading: React.ComponentProps<typeof HeadingText>;
  button: React.ComponentProps<typeof Button>[];
  action: JumpToSceneSetAction;
};

/**
 * The "hero" panel of the Session Progress screen
 *
 * @param {ProgressReviewWidgetProps} props
 */
const ProgressReviewWidget = ({
  heading,
  button,
  action,
}: ProgressReviewWidgetProps): React.ReactElement => {
  const safeArea = useSafeArea();
  const { screenWidth, screenHeight } = useGetScreenDimensions();
  const isLandscape = screenWidth > screenHeight;

  return (
    <WidgetWrapper safeArea={safeArea} isLandscape={isLandscape}>
      <HeadingWrapper>
        <HeadingText>{heading.text}</HeadingText>
      </HeadingWrapper>
      <ButtonWrapper>
        <Button
          text={button[0].text}
          colorScheme={ButtonColorSchemes.ProgressReview}
          action={action}
        />
      </ButtonWrapper>
    </WidgetWrapper>
  );
};

export { ProgressReviewWidget };
