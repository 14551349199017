// IDEA rethrow/display instead of console.warn via Formik
// WHEN Dev only toast notifications added
// INFO https://github.com/formium/formik/issues/1329
import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { isValidDate } from 'lib/isValidDate';
import { QuestionId } from 'state/question-response/actions';

export const checkIfNumberOrUndefined = (
  value: Value,
  id: QuestionId
): void | never => {
  if (
    typeof value !== 'undefined' &&
    typeof value !== 'number' &&
    value !== ''
  ) {
    throw Error(
      `Expected Question entity with semantic_id ${id} to be undefined or number, instead got "${value}" (${typeof value})`
    );
  }
};

const checkIfDateOrNullOrUndefined = (
  value: Value,
  id: QuestionId
): void | never => {
  if (typeof value !== 'undefined' && !isValidDate(value) && value !== null) {
    throw Error(
      `Expected Question entity with semantic_id ${id} to be undefined or Valid date object, instead got "${value}" (${typeof value})`
    );
  }
};

/**
 * (Defensive coding) Check Sleep Diary Questions entities have correct value types
 *
 * Without this check, the getWarningsAndHighlights() rules may not work
 *
 * @see getWarningsAndHighlights() rules
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 *
 * FIXME if an error fires here, he presents as a warning
 *  "Warning: An unhandled error was caught during low priority validation in <Formik validate />"
 */
const checkInputValues = (
  inputs: Record<QuestionId, DropdownItem[]>
): void | never => {
  Object.entries({
    sleep_efficiency_time_into_bed: checkIfDateOrNullOrUndefined,
    sleep_efficiency_time_try_to_sleep: checkIfDateOrNullOrUndefined,
    sleep_efficiency_to_fall_asleep_total_time: checkIfNumberOrUndefined,
    // sleep_efficiency_awakenings_in_night: not used in rule arithmetic so no check required
    sleep_efficiency_awakenings_total_time: checkIfNumberOrUndefined,
    sleep_efficiency_time_final_awakening: checkIfDateOrNullOrUndefined,
    sleep_efficiency_time_get_out_of_bed: checkIfDateOrNullOrUndefined,
    // sleep_efficiency_sleep_quality not used in rule arithmetic so no check required
  }).forEach(([id, checkFn]) => {
    checkFn(inputs[id]?.[0]?.value, id);
  });
};

export { checkInputValues };
