import { FunctionComponent } from 'react';

import { IconStyles } from 'components/icons/types';

export type TabIconProps = { size: number; style?: IconStyles };

export type TabItemData = {
  text: string;
  iconSize: number;
  screenRoute: string;
  Icon: FunctionComponent<
    { size: number; style?: IconStyles } & IsSelectedProp
  >;
};

export type BottomNavigationProps = {
  scaleYValue?: number;
  minScaleYValue?: number;
  tabItemsData: TabItemData[];
  setBottomNavigationHeight: (height: number) => void;
};

export type IsSelectedProp = {
  isSelected: boolean;
};

export enum TabItemFlexType {
  FLEX = 'flex',
  NO_FLEX = 'noFlex',
}

export enum TabItemTextSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}
