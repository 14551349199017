import styled from 'styled-components/native';

export const UIScreenView = styled.View`
  flex-direction: column;
  flex: 1;
  padding: 24px;
`;

export const SwitchToggleView = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
