import { DropdownItem } from 'components/generic-question/Dropdown/types';

import {
  QuestionPathAction,
  QuestionPathActions,
  QuestionPathProperties,
} from './actions';

export type QuestionPathStateValue = {
  [QuestionPathProperties.selectionQueue]: DropdownItem[];
};
export type QuestionPathState = Record<string, QuestionPathStateValue>;

export const initialQuestionPathState: QuestionPathState = {};

export const questionPathReducer = (
  state = initialQuestionPathState,
  action: QuestionPathAction
): QuestionPathState => {
  switch (action.type) {
    case QuestionPathActions.UPDATE_PROPERTY_VALUE: {
      return {
        ...state,
        [action.payload.questionPath]: {
          ...state[action.payload.questionPath],
          // NOTE: can update with `undefined`
          [action.payload.update.property]: action.payload.update.value,
        },
      };
    }
    default:
      return state;
  }
};
