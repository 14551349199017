// common to all other themes

export const googleColors = {
  white: 'white',
  red: '#EA4336',
};
export const facebookColors = {
  white: 'white',
  blue: '#4C69BA',
};
