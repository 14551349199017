import {
  Redirection,
  RouteChangedAction,
} from 'cross-platform/react-router/Router/utils/Redirector/state/actions';

export const hasMetConditions =
  /**
   *
   * @param param0
   * @returns
   */
  (routeChanged: RouteChangedAction['payload']) =>
    /**
     *
     */
    (redirection: Redirection): boolean => {
      if (redirection.conditions.length === 0) {
        return true;
      }
      return redirection.conditions.reduce((prev, currCond) => {
        switch (currCond.type) {
          case 'LOCATION_MATCH':
            return (
              prev &&
              currCond.payload.pathname == routeChanged.location.pathname
            );
          default:
            throw Error('Unhandled Condition type');
        }
      }, true);
    };
