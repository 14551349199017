/**
 * Response sourced directly from:
 *    WHAT:     SleepDiary.get_diary_entry_form()
 *    WHERE:    Stage
 *    WHEN:     17th Sep 2020
 */
import { APIRequestBody } from '@bighealth/api';
import { get_diary_entry_form as get_entry_form } from '@bighealth/api/SleepDiary/v1';

import { middleware } from 'lib/api/middleware';
import { HOUR } from 'lib/durations';

import { getFallAsleepTotalTimeAsDropdownQuestion } from './17Sep2020/getFallAsleepTotalTimeAsDropdownQuestion';

export const get_diary_entry_form = {
  result: {
    date_label: { text: 'Tuesday, June 30' },
    form: {
      heading: { text: 'Last week...' },
      sections: [
        {
          heading: { text: 'Going to bed' },
          group: [
            {
              questionProps: {
                created_utc: { $datetime: '2020-09-10T18:52:03.445344' },
                updated_utc: { $datetime: '2020-09-10T18:52:03.445352' },
                deleted_utc: null,
                id: 110,
                semantic_id: 'sleep_efficiency_time_into_bed',
                uuid: 'e1678773-e3bd-427b-88eb-00e683844e8c',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'What time did you get into bed?',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      value: '$input',
                      semantic_id: 'ro_se_time_into_bed',
                      min_response: { $time: '06:00:00' },
                      interval_time_minutes: 5,
                      // max_response: { $time: '17:45:00' },
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': {
                        type: 'number',
                        minimum: 6 * HOUR, // 6:00PM
                        // exclusiveMaximum: 24 + 17 * HOUR + 45 * MINUTE, // 5:45PM
                      },
                      selections: { type: 'array', maxItems: 1, minItems: 0 },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 0,
                },
                response_type: { $ResponseType: 'TIME' },
                primary_key: 110,
                display_name: '110',
                previous_responses: [],
              },
              component: 'DropdownTimePicker',
            },
            {
              questionProps: {
                created_utc: { $datetime: '2020-09-10T20:44:06.963974' },
                updated_utc: { $datetime: '2020-09-17T14:05:24.339953' },
                deleted_utc: null,
                id: 122,
                semantic_id: 'sleep_efficiency_time_try_to_sleep',
                uuid: '598e014c-46d2-4c53-9c90-063a35425c4e',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'What time did you try to fall asleep?',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      value: '$input',
                      semantic_id: 'ro_se_time_try_to_sleep',
                      min_response: { $time: '02:01:23' },
                      max_response: { $time: '05:09:23' },
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': { type: 'number' },
                      selections: { type: 'array', maxItems: 2, minItems: 0 },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 1,
                },
                response_type: { $ResponseType: 'TIME' },
                primary_key: 122,
                display_name: '122',
                previous_responses: [],
              },
              component: 'DropdownTimePicker',
            },
            getFallAsleepTotalTimeAsDropdownQuestion(),
          ],
        },
        {
          heading: { text: 'During the night' },
          group: [
            {
              questionProps: {
                created_utc: { $datetime: '2020-09-11T04:28:08.092539' },
                updated_utc: { $datetime: '2020-09-11T15:46:29.163594' },
                deleted_utc: null,
                id: 125,
                semantic_id: 'sleep_efficiency_awakenings_in_night',
                uuid: '02ff06e3-9dcc-48bc-981c-680dd034a13e',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title:
                  'How many times did you wake up during the night?',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      value: 0,
                      semantic_id: 'ro_se_awakenings_in_night_1',
                      display_text: '0 times',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 2,
                      value: 1,
                      semantic_id: 'ro_se_awakenings_in_night_2',
                      display_text: '1 time',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 3,
                      value: 2,
                      semantic_id: 'ro_se_awakenings_in_night_3',
                      display_text: '2 times',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 4,
                      value: 3,
                      semantic_id: 'ro_se_awakenings_in_night_4',
                      display_text: '3 times',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 5,
                      value: 4,
                      semantic_id: 'ro_se_awakenings_in_night_5',
                      display_text: '4 times',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 6,
                      value: 5,
                      semantic_id: 'ro_se_awakenings_in_night_6',
                      display_text: '5 times',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 7,
                      value: 6,
                      semantic_id: 'ro_se_awakenings_in_night_7',
                      display_text: '6 times',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 8,
                      value: 7,
                      semantic_id: 'ro_se_awakenings_in_night_8',
                      display_text: '7 times',
                      max_response: 100,
                      min_response: 0,
                    },
                    {
                      id: 9,
                      value: 8,
                      semantic_id: 'ro_se_awakenings_in_night_9',
                      display_text: '8+ times',
                      max_response: 100,
                      min_response: 0,
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': { type: 'number', maximum: 0, minimum: 0 },
                      '2': { type: 'number', maximum: 1, minimum: 1 },
                      '3': { type: 'number', maximum: 2, minimum: 2 },
                      '4': { type: 'number', maximum: 3, minimum: 3 },
                      '5': { type: 'number', maximum: 4, minimum: 4 },
                      '6': { type: 'number', maximum: 5, minimum: 5 },
                      '7': { type: 'number', maximum: 6, minimum: 6 },
                      '8': { type: 'number', maximum: 7, minimum: 7 },
                      '9': { type: 'number', maximum: 8, minimum: 8 },
                      selections: { type: 'array', maxItems: 1, minItems: 0 },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 0,
                },
                response_type: { $ResponseType: 'NUMBER' },
                primary_key: 125,
                display_name: '125',
                previous_responses: [],
              },
              component: 'Dropdown',
            },
            {
              questionProps: {
                created_utc: { $datetime: '2020-09-11T04:32:15.818507' },
                updated_utc: { $datetime: '2020-09-16T13:45:24.311637' },
                deleted_utc: null,
                id: 126,
                semantic_id: 'sleep_efficiency_awakenings_total_time',
                uuid: 'c70e2fa6-61e1-49f7-aa95-290ce8301ed9',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'In total, how long did these awakenings last?',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      value: '$input',
                      semantic_id: 'ro_se_awakenings_total_time',
                      max_response: 28800000,
                      min_response: 60000,
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': {
                        type: 'number',
                        minimum: 60000,
                        exclusiveMaximum: 28800000,
                      },
                      selections: { type: 'array', maxItems: 1, minItems: 0 },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 0,
                },
                response_type: { $ResponseType: 'NUMBER' },
                primary_key: 126,
                display_name: '126',
                previous_responses: [],
              },
              component: 'DropdownDurationPicker',
            },
          ],
        },
        {
          heading: { text: 'Getting up' },
          group: [
            {
              questionProps: {
                created_utc: { $datetime: '2020-09-11T04:36:23.901484' },
                updated_utc: { $datetime: '2020-09-11T04:36:23.901493' },
                deleted_utc: null,
                id: 127,
                semantic_id: 'sleep_efficiency_time_final_awakening',
                uuid: 'dbc3cb8b-019c-4e9a-b5c1-6aabe4e6d265',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'What time was your final awakening?',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      value: '$input',
                      semantic_id: 'ro_se_time_final_awakening',
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': { type: 'number' },
                      selections: { type: 'array', maxItems: 1, minItems: 0 },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 0,
                },
                response_type: { $ResponseType: 'TIME' },
                primary_key: 127,
                display_name: '127',
                previous_responses: [],
              },
              component: 'DropdownTimePicker',
            },
            {
              questionProps: {
                created_utc: { $datetime: '2020-09-11T04:37:57.874508' },
                updated_utc: { $datetime: '2020-09-11T04:37:57.874516' },
                deleted_utc: null,
                id: 128,
                semantic_id: 'sleep_efficiency_time_get_out_of_bed',
                uuid: 'cd82f378-edbd-40a5-b5f4-27e464cc473c',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'What time did you get out of bed for the day?',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      value: '$input',
                      semantic_id: 'ro_se_time_get_out_of_bed',
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': { type: 'number' },
                      selections: { type: 'array', maxItems: 1, minItems: 0 },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 0,
                },
                response_type: { $ResponseType: 'TIME' },
                primary_key: 128,
                display_name: '128',
                previous_responses: [],
              },
              component: 'DropdownTimePicker',
            },
            {
              questionProps: {
                created_utc: { $datetime: '2020-09-11T04:41:28.245318' },
                updated_utc: { $datetime: '2020-09-11T19:18:27.172373' },
                deleted_utc: null,
                id: 129,
                semantic_id: 'sleep_efficiency_sleep_quality',
                uuid: '9d1054ce-13fc-437d-ab7a-3fa174f14ce4',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'How would you rate the quality of your sleep?',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 21,
                      value: 1,
                      semantic_id: 'ro_se_sleep_quality_very_poor',
                      display_text: 'Very Poor',
                      max_number_lines: 255,
                      min_characters_limit: 1,
                    },
                    {
                      id: 2,
                      value: 3,
                      semantic_id: 'ro_se_sleep_quality_poor',
                      display_text: 'Poor',
                      max_number_lines: 255,
                      min_characters_limit: 1,
                    },
                    {
                      id: 3,
                      value: 5,
                      semantic_id: 'ro_se_sleep_quality_fair',
                      display_text: 'Fair',
                      max_number_lines: 255,
                      min_characters_limit: 1,
                    },
                    {
                      id: 4,
                      value: 7,
                      semantic_id: 'ro_se_sleep_quality_good',
                      display_text: 'Good',
                      max_number_lines: 255,
                      min_characters_limit: 1,
                    },
                    {
                      id: 5,
                      value: 9,
                      semantic_id: 'ro_se_sleep_quality_very_good',
                      display_text: 'Very Good',
                      max_number_lines: 255,
                      min_characters_limit: 1,
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': { type: 'string', pattern: 'very poor' },
                      '2': { type: 'string', pattern: 'poor' },
                      '3': { type: 'string', pattern: 'fair' },
                      '4': { type: 'string', pattern: 'good' },
                      '5': { type: 'string', pattern: 'very good' },
                      selections: { type: 'array', maxItems: 1, minItems: 0 },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 0,
                },
                response_type: { $ResponseType: 'NUMBER' },
                primary_key: 129,
                display_name: '129',
                previous_responses: [],
              },
              component: 'Dropdown',
            },
          ],
        },
      ],
      buttons: [
        {
          component: 'Button',
          text: 'Save estimate',
          action: {
            type: 'sleep-diary/SUBMIT_AND_MODAL_CLOSE',
            payload: {
              service_name: 'SleepDiary',
              service_version: 1,
              method_name: 'create_from_weekly_average_estimate',
              args: {
                diary_date: { $date: '2020-06-30' },
                diary_type: 'NORMAL',
                diary_source: 'USER',
              },
            },
          },
        },
        {
          component: 'Button',
          text: 'Cancel',
          action: { type: 'sleep-diary/CANCEL' },
        },
      ],
    },
  },
  status_code: 200,
  call_id: 'cca707e9-7f2f-4800-a2c1-7522c2ffd7a8',
  aws_request_id: '462bed8d-5169-4bdb-b641-a334b0605b20',
};

const mockReq = ({
  service_name: 'SleepDiary',
  service_method: 'get_diary_entry_form',
} as unknown) as APIRequestBody;
const transformed = middleware.responses(mockReq, true)(get_diary_entry_form);
const mockRes17Sep2020 = transformed as get_entry_form.Response;

export { mockRes17Sep2020 };
