import { DaylightHomeScreenPayload } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_home_screen_data = async (
  args: get_home_screen_data.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_home_screen_data.Response> => {
  const request: get_home_screen_data.Request = {
    service_name: 'DaylightHomeScreen',
    service_version: '1',
    service_method: 'get_home_screen_data',
    args,
  };

  return await api(request, requestConfig);
};

get_home_screen_data.queryKey = 'DaylightHomeScreen/get_home_screen_data';

export declare namespace get_home_screen_data {
  export type Args = {
    product_id: number;
    program_id: number;
    screen_reference?: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'DaylightHomeScreen';
    service_version: '1';
    service_method: 'get_home_screen_data';
    args: Args;
  }

  export type Result = DaylightHomeScreenPayload;

  export type Response = APIResponse<Result>;
}
