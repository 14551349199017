/* eslint-disable react/prop-types */ // AC: For onClose type; I don't think its needed
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
} from 'react';
import { useSafeArea } from 'react-native-safe-area-context';
import { useDispatch } from 'react-redux';

import {
  ScalingContext,
  ScalingContextProvider,
} from 'components/ResponsiveLayout';
import { isMobileWeb } from 'config/isMobileWeb';
import * as roleProps from 'cross-platform/utils/roleProps';
import * as sleepDiaryModalActions from 'state/sleep-diary-modal/actions';

import { Modal } from '../Modal';

import { CloseButton } from './components/CloseButton';
import {
  ModalControlsStateContext,
  ModalControlsStateProvider,
} from './components/ModalControlsContext';
import { VerticalScroll } from './components/VerticalScroll';
import { OverlayTint, Panel, Wrapper } from './styled';

export type PresentationalProps = {
  visible?: boolean;
  onClose: () => void;
  overlayTintColor?: string;
  name?: string;
};

const Presentational: FunctionComponent<PropsWithChildren<
  PresentationalProps
>> = (props): ReactElement => {
  const safeArea = useSafeArea();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.name === 'SleepDiaryModal') {
      if (props.visible) {
        dispatch(sleepDiaryModalActions.setVisible(true));
      }
    }
    return () => {
      if (props.name === 'SleepDiaryModal') {
        dispatch(sleepDiaryModalActions.setVisible(false));
      }
    };
  }, [dispatch, props.name, props.visible]);
  return (
    <ModalControlsStateProvider onClose={props.onClose}>
      <ScalingContextProvider scalingContext={ScalingContext.Modal}>
        <Wrapper
          visible={!!props.visible}
          {...roleProps.pass(props, undefined, { default: 'Wrapper' })}
        >
          <Modal
            visible={!!props.visible}
            transparent={true}
            supportedOrientations={['portrait', 'landscape']}
          >
            <OverlayTint
              {...roleProps.roles('OverlayTint')}
              overlayTintColor={props.overlayTintColor}
            >
              <Panel
                {...roleProps.roles('Panel')}
                isMobileWeb={isMobileWeb}
                style={{ paddingTop: safeArea.top }}
              >
                <VerticalScroll>{props.children || null}</VerticalScroll>
                <ModalControlsStateContext.Consumer>
                  {state => <CloseButton onPress={state.onClose} />}
                </ModalControlsStateContext.Consumer>
              </Panel>
            </OverlayTint>
          </Modal>
        </Wrapper>
      </ScalingContextProvider>
    </ModalControlsStateProvider>
  );
};
export { Presentational };
