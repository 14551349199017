import React from 'react';

import { UniversalButton } from 'components/UniveralButtons';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { ErrorDescription } from 'lib/error';
import { isDevMode } from 'lib/isDevMode';

import { ErrorModalProps } from '../types';

import { Markdown } from './components/Markdown.web';
import { CloseButton, ErrorText, Panel, Title, Wrapper } from './styled.web';

export const DEFAULTS: ErrorDescription = {
  heading: 'Something went wrong',
  message: `It seems something has gone wrong.
  Try refreshing the page or exiting the session.

  If this problem persists please reach out to [hello@sleepio.com](mailto:hello@sleepio.com)
  `,
};

const ErrorModal = (props: ErrorModalProps & RoleProps): JSX.Element | null => {
  const { heading, message, onClose, error = undefined, actions = [] } = {
    ...DEFAULTS,
    ...props,
  };
  return typeof error === 'undefined' ? null : (
    <Wrapper {...roles('ErrorModal')}>
      <Panel>
        <Title {...roles('ErrorModal.Title')}>{heading}</Title>
        <Markdown {...roles('ErrorModal.Message')}>{message}</Markdown>
        {isDevMode() ? (
          <ErrorText {...roles('ErrorModal.ErrorText')}>{`${error}`}</ErrorText>
        ) : null}
        {actions?.map((action, index) => (
          <UniversalButton
            key={`index:${index}`}
            {...roles(`ErrorModal.Button.${index}`)}
            {...action}
          />
        ))}
      </Panel>
      <CloseButton
        {...roles('ErrorModal.CloseButton')}
        onPress={() => onClose()}
      />
    </Wrapper>
  );
};

export { ErrorModal };
