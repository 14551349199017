import { APIRequestBody, APIResponse } from '@bighealth/api';
import { SceneSetResponse } from '@bighealth/types';
import { SceneActionTypes } from '@bighealth/types/dist/enums';

import { middleware } from 'lib/api/middleware';

// Created for testing selection bug in PG-578 QA failure
const sceneSetWithMaxSelection2Response = {
  result: {
    created_utc: { $datetime: '2020-05-01T08:34:16.251000' },
    updated_utc: { $datetime: '2020-07-09T11:44:35.589830' },
    deleted_utc: null,
    id: 137,
    reference: 'first_question_ss',
    description: 'First question ss',
    scene_set_markup: '<NA />',
    scene_set_json: {
      component: 'SceneSet',
      reference: 'first_question_ss',
      childNodes: [
        {
          component: 'Scene',
          childNodes: [
            {
              title: 'First Question',
              component: 'Modal',
              childNodes: [
                {
                  component: 'TextInput',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T13:17:14.108000' },
                    updated_utc: { $datetime: '2020-05-12T13:17:14.108000' },
                    deleted_utc: null,
                    id: 1941,
                    semantic_id: 'both_text_and_button',
                    uuid: 'f7442825-1449-4088-a7f4-c3dd06fb3929',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Name',
                    question_title: 'What should we call you?',
                    response_pre_text: 'This is a response pre-text.',
                    visual_representation_json: { test: 'test' },
                    visual_representation_markup: 'This is some markup.',
                    optional: true,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          score: null,
                          value: '$input',
                          max_number_lines: 10,
                          min_characters_limit: 10,
                        },
                        {
                          id: 2,
                          value: 'sometext12',
                          display_text: 'Anonymous',
                          max_number_lines: 10,
                          min_characters_limit: 10,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': {
                            type: 'string',
                            pattern: '^(?:[^\r\n]*(?:\r\n?|\n)){0,9}[^\r\n]*$',
                            minLength: 10,
                          },
                          '2': { type: 'string', pattern: 'sometext12' },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 0,
                          },
                        },
                        description: 'answer option values',
                      },
                      correct_response_ids: [],
                      default_response_ids: [],
                      max_selections_required: 1,
                      min_selections_required: 0,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '1941',
                    primary_key: 1941,
                  },
                },
                {
                  component: 'TextInput',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-01T08:32:35.951764' },
                    updated_utc: { $datetime: '2020-05-01T08:32:35.951780' },
                    deleted_utc: null,
                    id: 1928,
                    semantic_id: 'first_text_input',
                    uuid: '719be80b-4ead-44e0-9618-b1e947e60924',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Pre text',
                    question_title:
                      'How many hours have you missed from work due to poor sleep?',
                    response_pre_text: null,
                    visual_representation_json: {},
                    visual_representation_markup: '',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'Sup',
                          value: '$input',
                          semantic_id: 'foo',
                          display_text: 'Hello',
                          max_number_lines: 3,
                          min_characters_limit: 2,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': {
                            type: 'string',
                            pattern: '^(?:[^\r\n]*(?:\r\n?|\n)){0,2}[^\r\n]*$',
                            minLength: 2,
                          },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 1,
                      correct_response_ids: [1],
                      default_response_ids: [1],
                      max_selections_required: 1,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '1928',
                    primary_key: 1928,
                  },
                },
                {
                  component: 'VerticalSelect',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T13:28:37.399257' },
                    updated_utc: { $datetime: '2020-07-06T15:53:21.110173' },
                    deleted_utc: null,
                    id: 1945,
                    semantic_id: 'first_single_select_input',
                    uuid: '9b79cc2d-5138-411e-a040-3fe878771a4a',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Some pretext',
                    question_title: 'I am first_single_select_input title',
                    response_pre_text: null,
                    visual_representation_json: {},
                    visual_representation_markup: '',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'Some label',
                          value: 'one',
                          semantic_id: 'first_single_select_input_1',
                          display_text: 'Entry one - incorrect',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 2,
                          label: 'Some label',
                          value: 'two',
                          semantic_id: 'first_single_select_input_2',
                          display_text: 'Entry two - correct',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'string', pattern: 'one' },
                          '2': { type: 'string', pattern: 'two' },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 3,
                      correct_response_ids: [2],
                      default_response_ids: [1],
                      max_selections_required: 1,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '1945',
                    primary_key: 1945,
                  },
                },
                {
                  component: 'NumberInput',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T13:28:31.255325' },
                    updated_utc: { $datetime: '2020-05-12T13:28:31.255331' },
                    deleted_utc: null,
                    id: 1944,
                    semantic_id: 'first_number_input',
                    uuid: '0b4ebf74-aa3b-41b4-8db8-31ff2a33c618',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'I am first_number_input pre_text',
                    question_title: 'I am first_number_input title',
                    response_pre_text: null,
                    visual_representation_json: {},
                    visual_representation_markup: '',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'Sup',
                          value: '$input',
                          semantic_id: 'first_number_input_1',
                          display_text: 'Hello',
                          max_response: 10,
                          min_response: 0,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': {
                            type: 'number',
                            minimum: 0,
                            exclusiveMaximum: 10,
                          },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 1,
                      correct_response_ids: [1],
                      default_response_ids: [1],
                      max_selections_required: 1,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'NUMBER' },
                    display_name: '1944',
                    primary_key: 1944,
                  },
                },
                {
                  component: 'VerticalSelect',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T13:28:21.518563' },
                    updated_utc: { $datetime: '2020-07-06T12:26:53.641670' },
                    deleted_utc: null,
                    id: 1943,
                    semantic_id: 'first_multiselect_input',
                    uuid: 'ad59c951-f2ce-49ae-b244-204660938353',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Some pretext',
                    question_title: 'I am first_multiselect_input title',
                    response_pre_text: null,
                    visual_representation_json: {},
                    visual_representation_markup: '',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'One',
                          value: 'one',
                          semantic_id: 'first_multiselect_input_1',
                          display_text: 'Entry one - correct',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 2,
                          label: 'Two',
                          value: 'two',
                          semantic_id: 'first_multiselect_input_2',
                          display_text: 'Entry two - correct',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 3,
                          label: 'Three',
                          value: 'three',
                          semantic_id: 'first_multiselect_input_3',
                          display_text: 'Entry three - incorrect',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'string', pattern: 'one' },
                          '2': { type: 'string', pattern: 'two' },
                          '3': { type: 'string', pattern: 'three' },
                          selections: {
                            type: 'array',
                            maxItems: 2,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 4,
                      correct_response_ids: [1, 2],
                      default_response_ids: [],
                      max_selections_required: 2,
                      min_selections_required: 2,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '1943',
                    primary_key: 1943,
                  },
                },
                {
                  component: 'HorizontalScale',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T13:28:11.131676' },
                    updated_utc: { $datetime: '2020-07-06T15:26:20.178911' },
                    deleted_utc: null,
                    id: 1942,
                    semantic_id: 'first_horizontal_select_input',
                    uuid: 'b75bb02c-7fd9-4472-a614-5f55cdc9a553',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Some pretext',
                    question_title: 'I am first_horizontal_select_input title',
                    response_pre_text: null,
                    visual_representation_json: {},
                    visual_representation_markup: '',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'Some label',
                          value: 'one',
                          semantic_id: 'first_horizontal_select_input_1',
                          display_text: 'Entry one - incorrect',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 2,
                          label: 'Some label',
                          value: 'two',
                          semantic_id: 'first_horizontal_select_input_2',
                          display_text: 'Entry two - correct',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'string', pattern: 'one' },
                          '2': { type: 'string', pattern: 'two' },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 3,
                      correct_response_ids: [2],
                      default_response_ids: [1],
                      max_selections_required: 1,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '1942',
                    primary_key: 1942,
                  },
                },
                {
                  component: 'DateTimePicker',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T14:04:34.525159' },
                    updated_utc: { $datetime: '2020-05-12T14:04:34.525167' },
                    deleted_utc: null,
                    id: 1947,
                    semantic_id: 'datetime',
                    uuid: '49ca3ef7-6282-41c3-801c-c1befb9b5b7c',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Enter a date and time',
                    question_title: 'When would you like to be reminded?',
                    response_pre_text: '',
                    visual_representation_json: { test: 'test' },
                    visual_representation_markup: 'This is some markup.',
                    optional: true,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'Sup',
                          value: '$input',
                          semantic_id: 'date-time',
                          display_text: 'Hello',
                          max_number_lines: 3,
                          min_characters_limit: 120,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'number' },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 0,
                          },
                        },
                        description: 'answer option values',
                      },
                      correct_response_ids: [],
                      default_response_ids: [],
                      max_selections_required: 1,
                      min_selections_required: 0,
                    },
                    response_type: { $ResponseType: 'DATETIME' },
                    display_name: '1947',
                    primary_key: 1947,
                  },
                },
                {
                  component: 'DatePicker',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T14:23:07.164216' },
                    updated_utc: { $datetime: '2020-06-23T15:02:54.553130' },
                    deleted_utc: null,
                    id: 1948,
                    semantic_id: 'date',
                    uuid: '7d7b804c-8949-404a-b62c-32991a053a0c',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Enter a day next century',
                    question_title: 'When will we have jetpacks?',
                    response_pre_text: '',
                    visual_representation_json: { test: 'test' },
                    visual_representation_markup: 'This is some markup.',
                    optional: true,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'Sup',
                          value: '$input',
                          semantic_id: 'date',
                          display_text: 'Hello',
                          max_response: { $date: '2200-01-01' },
                          min_response: { $date: '2100-01-01' },
                          max_number_lines: 3,
                          min_characters_limit: 120,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': {
                            type: 'number',
                            minimum: 4102444800000.0,
                            exclusiveMaximum: 7258118400000.0,
                          },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 0,
                          },
                        },
                        description: 'answer option values',
                      },
                      correct_response_ids: [],
                      default_response_ids: [],
                      max_selections_required: 1,
                      min_selections_required: 0,
                    },
                    response_type: { $ResponseType: 'DATE' },
                    display_name: '1948',
                    primary_key: 1948,
                  },
                },
                {
                  component: 'TimePicker',
                  questionProps: {
                    created_utc: { $datetime: '2020-05-12T14:31:40.623000' },
                    updated_utc: { $datetime: '2020-05-12T14:31:40.623000' },
                    deleted_utc: null,
                    id: 1949,
                    semantic_id: 'time',
                    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'Birth date',
                    question_title: 'When were you born?',
                    response_pre_text: '',
                    visual_representation_json: { test: 'test' },
                    visual_representation_markup: 'This is some markup.',
                    optional: true,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'Sup',
                          value: '$input',
                          semantic_id: 'time',
                          display_text: 'Hello',
                          max_number_lines: 3,
                          min_characters_limit: 120,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'number' },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 0,
                          },
                        },
                        description: 'answer option values',
                      },
                      correct_response_ids: [],
                      default_response_ids: [],
                      max_selections_required: 1,
                      min_selections_required: 0,
                    },
                    response_type: { $ResponseType: 'TIME' },
                    display_name: '1949',
                    primary_key: 1949,
                  },
                },
                {
                  component: 'VerticalSelect',
                  questionProps: {
                    created_utc: { $datetime: '2020-07-06T16:05:53.391214' },
                    updated_utc: { $datetime: '2020-07-09T08:58:43.175863' },
                    deleted_utc: null,
                    id: 4210,
                    semantic_id: 'multi_select0607',
                    uuid: '1a4e4cfe-cf4c-43cf-b510-eb8655322051',
                    language: 'en',
                    version: 1,
                    question_pre_text: '~@ Pretext Choose correct answer(s) @~',
                    question_title: 'Choose correct answer(s)',
                    response_pre_text: 'Response Pretext',
                    visual_representation_json: '{}',
                    visual_representation_markup: '<markup/>',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'one',
                          value: 'default',
                          semantic_id: 'multi_select0607_1',
                          display_text:
                            'Questions open a space in your mind that allow better answers to breathe.',
                          max_number_lines: 1,
                          min_characters_limit: 2,
                        },
                        {
                          id: 2,
                          label: 'two',
                          value: 'Two',
                          semantic_id: 'multi_select0607_2',
                          display_text:
                            'Part of being successful is about asking questions and listening to the answers.',
                          max_number_lines: 1,
                          min_characters_limit: 2,
                        },
                        {
                          id: 3,
                          label: 'three',
                          value: 'Three',
                          semantic_id: 'multi_select0607_3',
                          display_text:
                            'To seek truth requires one to ask the right questions',
                          max_number_lines: 1,
                          min_characters_limit: 2,
                        },
                        {
                          id: 4,
                          label: 'four',
                          value: '$input',
                          semantic_id: 'multi_select0607_4',
                          max_number_lines: 1,
                          min_characters_limit: 2,
                        },
                        {
                          id: 5,
                          label: 'five',
                          value: '$input',
                          semantic_id: 'multi_select0607_5',
                          max_number_lines: 1,
                          min_characters_limit: 1,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'string', pattern: 'default' },
                          '2': { type: 'string', pattern: 'Two' },
                          '3': { type: 'string', pattern: 'Three' },
                          '4': {
                            type: 'string',
                            pattern: '^(?:[^\r\n]*(?:\r\n?|\n)){0,0}[^\r\n]*$',
                            minLength: 2,
                          },
                          '5': {
                            type: 'string',
                            pattern: '^(?:[^\r\n]*(?:\r\n?|\n)){0,0}[^\r\n]*$',
                            minLength: 1,
                          },
                          selections: {
                            type: 'array',
                            maxItems: 2,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 4,
                      correct_response_ids: [1, 3],
                      default_response_ids: [1],
                      max_selections_required: 2,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '4210',
                    primary_key: 4210,
                  },
                },
                {
                  component: 'VerticalSelect',
                  questionProps: {
                    created_utc: { $datetime: '2020-07-09T11:43:51.359606' },
                    updated_utc: { $datetime: '2020-07-09T12:08:07.876682' },
                    deleted_utc: null,
                    id: 5481,
                    semantic_id: 'multi_0907',
                    uuid: '256494fc-91c9-484c-a962-93d48b5fe641',
                    language: 'en',
                    version: 1,
                    question_pre_text: 'pretext',
                    question_title: 'I am test multi select question',
                    response_pre_text: null,
                    visual_representation_json: '{}',
                    visual_representation_markup: '<markup/>',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'One',
                          value: 'one',
                          semantic_id: 'multi_0907_1',
                          display_text: 'Entry one - correct',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 2,
                          label: 'Two',
                          value: 'two',
                          semantic_id: 'multi_0907_2',
                          display_text: 'Entry two - incorrect',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 3,
                          label: 'Three',
                          value: 'three',
                          semantic_id: 'multi_0907_3',
                          display_text: 'Entry three - incorrect',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 4,
                          label: 'Four',
                          value: 'four',
                          semantic_id: 'multi_0907_4',
                          display_text: 'Entry four - incorrect',
                          max_number_lines: 1,
                          min_characters_limit: 3,
                        },
                        {
                          id: 5,
                          label: 'Five',
                          value: 'five',
                          semantic_id: 'multi_0907_5',
                          display_text: 'Entry five - incorrect',
                          max_number_lines: 1,
                          min_characters_limit: 1,
                        },
                        {
                          id: 6,
                          label: 'Six',
                          value: 'six',
                          semantic_id: 'multi_0907_6',
                          display_text: 'Entry six- correct',
                          max_number_lines: 1,
                          min_characters_limit: 1,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'string', pattern: 'one' },
                          '2': { type: 'string', pattern: 'two' },
                          '3': { type: 'string', pattern: 'three' },
                          '4': { type: 'string', pattern: 'four' },
                          '5': { type: 'string', pattern: 'five' },
                          '6': { type: 'string', pattern: 'six' },
                          selections: {
                            type: 'array',
                            maxItems: 3,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 3,
                      correct_response_ids: [1, 6],
                      default_response_ids: [],
                      max_selections_required: 3,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '5481',
                    primary_key: 5481,
                  },
                },
                {
                  component: 'VerticalSelect',
                  questionProps: {
                    created_utc: { $datetime: '2020-07-09T14:04:07.271075' },
                    updated_utc: { $datetime: '2020-07-09T14:22:48.466236' },
                    deleted_utc: null,
                    id: 5482,
                    semantic_id: 'multi_aditional_text1',
                    uuid: 'eaeaa69e-d7a3-4a21-bf08-c88cd866f15d',
                    language: 'en',
                    version: 1,
                    question_pre_text:
                      'or, where relevant, specify your answer',
                    question_title: 'Please select the appropriate answer ',
                    response_pre_text: null,
                    visual_representation_json: '{}',
                    visual_representation_markup: '<markup/>',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          label: 'one',
                          value: 'one',
                          semantic_id: 'multi_aditional_text1_1',
                          display_text:
                            ' \u201cBeing different is a revolving door in your life where secure people enter  and insecure exit.\u201d',
                          max_number_lines: 1,
                          min_characters_limit: 1,
                        },
                        {
                          id: 2,
                          label: 'two',
                          value: 'two',
                          semantic_id: 'multi_aditional_text1_2',
                          display_text:
                            " \u201cThe most intriguing people you will encounter in this life  are the people who had insights about you, that you didn't know about yourself.\u201d",
                          max_number_lines: 1,
                          min_characters_limit: 1,
                        },
                        {
                          id: 3,
                          label: 'three',
                          value: 'three',
                          semantic_id: 'multi_aditional_text1_3',
                          display_text:
                            'Flying is learning how to hit the ground and miss',
                          max_number_lines: 1,
                          min_characters_limit: 1,
                        },
                        {
                          id: 4,
                          label: 'four',
                          value: '$input',
                          semantic_id: 'multi_aditional_text1_4',
                          max_number_lines: 1,
                          min_characters_limit: 1,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': { type: 'string', pattern: 'one' },
                          '2': { type: 'string', pattern: 'two' },
                          '3': { type: 'string', pattern: 'three' },
                          '4': {
                            type: 'string',
                            pattern: '^(?:[^\r\n]*(?:\r\n?|\n)){0,0}[^\r\n]*$',
                            minLength: 1,
                          },
                          selections: {
                            type: 'array',
                            maxItems: 2,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                      },
                      max_number_attempts: 3,
                      correct_response_ids: [1, 2],
                      default_response_ids: [],
                      max_selections_required: 2,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'TEXT' },
                    display_name: '5482',
                    primary_key: 5482,
                  },
                },
                {
                  component: 'DropdownTimePicker',
                  questionProps: {
                    created_utc: { $datetime: '2020-09-11T04:37:57.874508' },
                    updated_utc: { $datetime: '2022-05-20T19:54:51.633956' },
                    deleted_utc: null,
                    id: 10,
                    semantic_id: 'sleep_efficiency_time_get_out_of_bed',
                    uuid: 'cd82f378-edbd-40a5-b5f4-27e464cc473c',
                    language: 'en',
                    version: 1,
                    question_pre_text: null,
                    question_title:
                      'What time did you get out of bed for the day?',
                    response_pre_text: null,
                    visual_representation_json: '{}',
                    visual_representation_markup: '<markup/>',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 0,
                          value: '$input',
                          semantic_id: '0',
                          max_response: { $time: '05:55:00' },
                          min_response: { $time: '06:00:00' },
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '0': {
                            not: {
                              maximum: 21600000,
                              exclusiveMinimum: 21300000,
                            },
                            type: 'number',
                          },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 1,
                          },
                        },
                        description: 'answer option values',
                        errorMessage: {
                          _: 'Enter a time',
                          properties: {
                            '0': 'Enter a time between 06:00:00 and 05:55:00',
                          },
                        },
                      },
                      correct_response_ids: [],
                      default_response_ids: [],
                      max_selections_required: 1,
                      min_selections_required: 1,
                    },
                    response_type: { $ResponseType: 'TIME' },
                    display_name: 'sleep_efficiency_time_get_out_of_bed',
                    primary_key: 10,
                    previous_responses: [],
                  },
                },
                {
                  component: 'DropdownDurationPicker',
                  questionProps: {
                    created_utc: { $datetime: '2020-09-11T04:32:15.818507' },
                    updated_utc: { $datetime: '2020-09-16T13:45:24.311637' },
                    deleted_utc: null,
                    id: 11,
                    semantic_id: 'sleep_efficiency_awakenings_total_time',
                    uuid: 'c70e2fa6-61e1-49f7-aa95-290ce8301ed9',
                    language: 'en',
                    version: 1,
                    question_pre_text: null,
                    question_title:
                      'In total, how long did these awakenings last?',
                    response_pre_text: null,
                    visual_representation_json: '{}',
                    visual_representation_markup: '<markup/>',
                    optional: false,
                    response_config: {
                      response_options: [
                        {
                          id: 1,
                          value: '$input',
                          semantic_id: 'ro_se_awakenings_total_time',
                          max_response: 28800000,
                          min_response: 60000,
                        },
                      ],
                      validation_schema: {
                        type: 'object',
                        title: 'answers',
                        $schema: 'http://json-schema.org/draft-07/schema#',
                        properties: {
                          '1': {
                            type: 'number',
                            minimum: 60000,
                            exclusiveMaximum: 28800000,
                          },
                          selections: {
                            type: 'array',
                            maxItems: 1,
                            minItems: 0,
                          },
                        },
                        description: 'answer option values',
                      },
                      correct_response_ids: [],
                      default_response_ids: [],
                      max_selections_required: 1,
                      min_selections_required: 0,
                    },
                    response_type: { $ResponseType: 'NUMBER' },
                    primary_key: 11,
                    display_name: '126',
                    previous_responses: [],
                  },
                },
                {
                  text: 'Go to third screen',
                  type: 'SECONDARY_BUTTON',
                  align: 'bottom',
                  action: { type: SceneActionTypes.SUBMIT },
                  component: 'Button',
                },
                {
                  text: 'Go to fourth screen',
                  type: 'SECONDARY_BUTTON',
                  align: 'bottom',
                  action: { type: SceneActionTypes.SUBMIT },
                  component: 'Button',
                },
              ],
            },
          ],
        },
      ],
    },
    scene_set_metadata: {},
    question_ids: [
      1941,
      1928,
      1945,
      1944,
      1943,
      1942,
      1947,
      1948,
      1949,
      4210,
      5481,
      5482,
      10,
      11,
    ],
    question_semantic_ids: [
      'both_text_and_button',
      'first_text_input',
      'first_single_select_input',
      'first_number_input',
      'first_multiselect_input',
      'first_horizontal_select_input',
      'datetime',
      'date',
      'time',
      'multi_select0607',
      'multi_0907',
      'multi_aditional_text1',
      'sleep_efficiency_time_get_out_of_bed',
      'sleep_efficiency_awakenings_total_time',
    ],
    display_name: 'first_question_ss',
    primary_key: 137,
  },
  status_code: 200,
  call_id: 'e3220637-338d-4cc6-98af-2d702077b962',
};

const sceneSetWithMaxSelection2: APIResponse<SceneSetResponse> = middleware.responses(
  (null as unknown) as APIRequestBody,
  true
)(sceneSetWithMaxSelection2Response) as APIResponse<SceneSetResponse>;
export { sceneSetWithMaxSelection2 };
