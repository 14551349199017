import React from 'react';

import { useTransformContentStyle } from 'components/ResponsiveLayout';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

import { LoadingWrapper } from './styled';

type Props = RoleProps;

const Loading = (props: Props): React.ReactElement => {
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  return <LoadingWrapper {...roles.pass(props)} scaleValue={scaleValue} />;
};
export { Loading };
