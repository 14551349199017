import { isSameDay } from 'date-fns';

import { setTime } from './setTime';

export const getMinTime = (
  minDate: Date | undefined,
  intervalMinutes?: number | undefined
): Date => {
  const now = new Date();
  const checkIntervalMinutes =
    typeof intervalMinutes === 'number' ? true : false;

  const minDateMinutes =
    minDate && intervalMinutes && minDate.getMinutes() + intervalMinutes;

  const allowedNowTime = setTime(now, {
    minutes: checkIntervalMinutes ? minDateMinutes : 0,
    hours: checkIntervalMinutes ? now.getHours() : now.getHours() + 1,
  });
  if (typeof minDate === 'undefined') {
    // if no minimum date, setup minimum time to one hour from now
    return allowedNowTime;
  } else if (isSameDay(minDate, now)) {
    // if minimum date same as current date choose the highest
    return minDate > allowedNowTime
      ? setTime(minDate, {
          minutes: 0,
        })
      : allowedNowTime;
  } else {
    // else return minimum date
    return setTime(minDate, { minutes: 0 });
  }
};

export const getCurrentMinTime = (date: Date | null, minTime: Date): Date => {
  // if selected date is same as minimum date then return minimum date
  if (date === null || isSameDay(date, minTime)) {
    return minTime;
  } else {
    // else return date with all time slots available
    return setTime(date, { hours: 0, minutes: 0 });
  }
};
