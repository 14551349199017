import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { add, sub } from 'date-fns';
import deepmerge from 'deepmerge'; // Deprecated; Use R.deepMergeLeft
import { useTheme } from 'styled-components';

import { get_next_session_available_datetime } from '@bighealth/api/SessionProgress/v1/get_next_session_available_datetime';

import { TimePicker } from 'components/generic-question/DateTimePickers';
import { useNotificationUserSettings } from 'components/LocalNotifications/localNotificationsState';
import {
  NOTIFICATION_TYPE,
  NotificationViewArgs,
} from 'components/LocalNotifications/NotificationsView';
import { VisualState } from 'components/LocalNotifications/NotificationsView';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID } from 'config/localNotifications/sleepioConfig';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryNextSessionAvailableDatetime } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQueryNextSessionAvailableDatetime';
import { setNotificationUserSettingById } from 'state/notifications/actions';

import { Wrapper } from '../../styled';
import { Header } from '../Header';

import {
  SESSION_REMINDER_TEXT,
  SESSIONS_REMINDER_DESCRIPTION,
  SLEEP_DIARY_REMINDER_ACTION,
  SLEEP_DIARY_REMINDER_DESCRIPTION,
  SLEEP_DIARY_REMINDER_TEXT,
  SLEEP_DIARY_REMINDER_WARNING,
} from './constants';
import {
  Description,
  GoToSettingsWrapper,
  Line,
  Main,
  Switch,
  TimePickerWrapper,
  ToggleText,
  ToggleWrapper,
  WarningIcon,
  WarningText,
  WarningTextWrapper,
  WarningWithUnderline,
  WarningWrapper,
} from './styled';
import { newTheme } from './theme';

export type SleepDiaryReminderProps = NotificationViewArgs & {
  onClose: () => void;
  productReference: string;
};

export const SleepDiaryReminder = (
  props: SleepDiaryReminderProps
): ReactElement | null => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sessionNotificationsSettings = useNotificationUserSettings(
    SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID
  );
  const timePickerTheme = useMemo(() => deepmerge(theme, newTheme), [theme]);
  const notificationState =
    props.scheduleNotificationState[NOTIFICATION_TYPE.DAILY_REMINDER];
  const sessionNotificationState =
    props.scheduleNotificationState[NOTIFICATION_TYPE.SESSION_REMINDER];
  const [date, setDate] = useState(notificationState.date);
  const { data, isSuccess } = useQueryNextSessionAvailableDatetime();
  const formattedDate = sub(date, {
    hours: date.getTimezoneOffset() / 60,
  });
  const queryClient = getQueryClient();
  useEffect(() => {
    return () => {
      // WHY: Invalidating the key here because on session finish
      //      we want to create a reminder of when the next session
      //      will be available for the user which comes from the BE.
      queryClient.invalidateQueries({
        queryKey: [get_next_session_available_datetime.queryKey],
        exact: true,
      });
    };
  }, [queryClient, sessionNotificationState.isSessionReminderOn]);

  useEffect(() => {
    setDate(notificationState.date);
  }, [notificationState.date, props.scheduleNotificationState]);

  const switchHandler = useCallback(() => {
    if (notificationState.isReminderOn) {
      props.cancelNotification({
        type: NOTIFICATION_TYPE.DAILY_REMINDER,
        data: date,
      });
    } else {
      props.scheduleNotification({
        type: NOTIFICATION_TYPE.DAILY_REMINDER,
        data: date,
      });
    }
  }, [date, notificationState.isReminderOn, props]);

  const sessionReminderSwitchHandler = useCallback(() => {
    if (sessionNotificationState.isSessionReminderOn) {
      props.cancelNotification({
        type: NOTIFICATION_TYPE.SESSION_REMINDER,
        data: sessionNotificationState.date,
      });
    } else {
      /* update state on switch button state change 
        because the user could finish session and then
        navigate to settings screen to turn session notification on
      */
      if (sessionNotificationsSettings) {
        dispatch(
          setNotificationUserSettingById({
            isOn: sessionNotificationsSettings?.isOn,
            id: SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID,
            timeOfDayHours24: 0,
            timeOfDayMinutes: 0,
            date:
              isSuccess && data.result !== null
                ? new Date(data.result.$datetime)
                : new Date(),
          })
        );
        props.scheduleNotification({
          type: NOTIFICATION_TYPE.SESSION_REMINDER,
          data:
            isSuccess && data.result !== null
              ? new Date(data.result.$datetime)
              : new Date(),
        });
      }
    }
  }, [
    sessionNotificationState.isSessionReminderOn,
    sessionNotificationState.date,
    props,
    sessionNotificationsSettings,
    dispatch,
    isSuccess,
    data,
  ]);

  const updateDate = useCallback(
    (newDate: Date | null) => {
      if (newDate === null) {
        return;
      }
      props.scheduleNotification({
        type: NOTIFICATION_TYPE.DAILY_REMINDER,
        data: add(newDate, { hours: newDate.getTimezoneOffset() / 60 }),
      });
    },
    [props]
  );

  return (
    <Wrapper>
      <Header text="Notifications" onClose={props.onClose} />
      <Main>
        <ToggleWrapper>
          <ToggleText
            {...roles('SessionReminderText')}
            text={SESSION_REMINDER_TEXT}
            isReminderDisabled={
              sessionNotificationState.isSessionReminderDisabled
            }
          />
          <Switch
            {...roles('SessionReminderNotificationToggle')}
            aria-label={
              sessionNotificationState.isSessionReminderOn
                ? 'Turn sessions notification off'
                : 'Turn sessions notification on'
            }
            accessibilityLabel={
              sessionNotificationState.isSessionReminderOn
                ? 'Turn sessions notification off'
                : 'Turn sessions notification on'
            }
            onValueChange={sessionReminderSwitchHandler}
            value={sessionNotificationState.isSessionReminderOn}
            disabled={sessionNotificationState.isSessionReminderDisabled}
          />
        </ToggleWrapper>
        <Description
          {...roles('ReminderDescription')}
          text={SESSIONS_REMINDER_DESCRIPTION}
        />
        <Line />
        <ToggleWrapper>
          <ToggleText
            {...roles('ReminderText')}
            text={SLEEP_DIARY_REMINDER_TEXT}
            isReminderDisabled={notificationState.isReminderDisabled}
          />
          <Switch
            {...roles('NotificationToggle')}
            accessibilityLabel={
              notificationState.isReminderOn
                ? 'Turn notification off'
                : 'Turn notification on'
            }
            aria-label={
              notificationState.isReminderOn
                ? 'Turn notification off'
                : 'Turn notification on'
            }
            onValueChange={switchHandler}
            value={notificationState.isReminderOn}
            disabled={notificationState.isReminderDisabled}
          />
        </ToggleWrapper>
        <Description
          {...roles('ReminderDescription')}
          text={SLEEP_DIARY_REMINDER_DESCRIPTION}
        />
        {props.visualState ===
        VisualState.B__SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT ? (
          <WarningWrapper>
            <WarningIcon />
            <WarningTextWrapper>
              <WarningText
                {...roles('ReminderWarning')}
                text={SLEEP_DIARY_REMINDER_WARNING}
              />
              <GoToSettingsWrapper
                {...roles('GoToSettings')}
                onPress={props.openSettingsScreen}
              >
                <WarningWithUnderline text={SLEEP_DIARY_REMINDER_ACTION} />
              </GoToSettingsWrapper>
            </WarningTextWrapper>
          </WarningWrapper>
        ) : null}
        {notificationState.isReminderOn ? (
          <CrossPlatformThemeProvider theme={timePickerTheme}>
            <TimePickerWrapper>
              <TimePicker date={formattedDate} onDateChange={updateDate} />
            </TimePickerWrapper>
          </CrossPlatformThemeProvider>
        ) : null}
      </Main>
    </Wrapper>
  );
};
