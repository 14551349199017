import { ApplicationNames, ProductReferences } from 'common/constants/enums';

export type GetAppName = (productReference: string) => ApplicationNames;

export const getAppName: GetAppName = productReference => {
  switch (productReference) {
    case ProductReferences.DAYLIGHT:
      return ApplicationNames.DAYLIGHT;
    case ProductReferences.SLEEPIO:
      return ApplicationNames.SLEEPIO;
    case ProductReferences.HELLO_WORLD:
    default:
      return ApplicationNames.HELLO_WORLD;
  }
};
