import React, { ReactElement } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { ProductReferences } from 'common/constants/enums';
import {
  useTransformContentStyle,
  useTransformContentStyleVertical,
} from 'components/ResponsiveLayout';
import {
  Logo as LogoImage,
  LogoButton,
  LogoContainer,
  LogoContainerPortrait,
} from 'components/Screens/ForgotPasswordScreen/styled';
import { copy } from 'components/Screens/LoginScreen/constants';
import {
  ButtonColorSchemes,
  UniversalLinkButton,
} from 'components/UniveralButtons';
import { getAssetsForProduct } from 'config/getAssetsForProduct';
import { ResizeMode } from 'cross-platform/Image';
import { useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';

const Logo = ({
  onPress,
  isPortrait,
  variant,
}: {
  onPress: () => void;
  isPortrait: boolean;
  variant: 'sign_up' | 'back_to_login';
}): ReactElement => {
  const safeArea = useSafeArea();
  const { productReference } = useParams<{ productReference: string }>();
  const transformStyle = useTransformContentStyle();
  const transformStyleVertical = useTransformContentStyleVertical();
  const assets = getAssetsForProduct(ProductReferences.SLEEPIO);

  const LinkButton =
    variant === 'back_to_login' ? (
      <UniversalLinkButton
        {...roles('forgot-password-log-in-link')}
        text={copy.loginLinkTitle}
        colorScheme={ButtonColorSchemes.Link}
        to={`/${productReference}/login`}
        title={copy.loginLinkTitle}
        style={{
          minWidth: 0,
          borderRadius: 0,
          minHeight: 0,
        }}
      />
    ) : (
      <UniversalLinkButton
        {...roles('forgot-password-sign-up-link')}
        text={copy.signUpTitle}
        colorScheme={ButtonColorSchemes.Link}
        to={`/${productReference}/login`}
        title={copy.signUpTitle}
        style={{
          minWidth: 0,
          borderRadius: 0,
          minHeight: 0,
        }}
      />
    );

  if (isPortrait) {
    return (
      <LogoContainerPortrait
        testID={'developer-logo-button'}
        style={{
          marginTop: safeArea.top + 8,
        }}
      >
        <LogoButton accessibilityRole="none" role="none" onPress={onPress}>
          <LogoImage
            src={assets.splashLogoDarkStrokeLightFill}
            fit={ResizeMode.contain}
            style={{
              width: transformStyle(180),
              height: transformStyleVertical(60),
              alignSelf: 'flex-end',
            }}
            alt={`${productReference} Logo`}
          />
        </LogoButton>
        {LinkButton}
      </LogoContainerPortrait>
    );
  }

  return (
    <LogoContainer testID={'developer-logo-button'}>
      <LogoButton accessibilityRole="none" role="none" onPress={onPress}>
        <LogoImage
          src={assets.splashLogoDarkStrokeLightFill}
          fit={ResizeMode.contain}
          style={{
            width: transformStyle(180),
            height: transformStyleVertical(60),
            alignSelf: 'flex-end',
          }}
          alt={`${productReference} Logo`}
        />
      </LogoButton>
      {LinkButton}
    </LogoContainer>
  );
};

export { Logo };
