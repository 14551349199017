import { History, LocationState } from 'history';
import { Dispatch } from 'redux';

import { DEBUG_SHOW_DEV_CONTENT_SELECTOR } from 'config/envVars';

const showQAMenu = (
  productReference: string,
  history: History<LocationState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _dispatch: Dispatch
): void => {
  if (DEBUG_SHOW_DEV_CONTENT_SELECTOR) {
    history.push(`/${productReference}/qa/debug-menu`);
  }
};

export { showQAMenu };
