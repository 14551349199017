import React from 'react';

import { ErrorDetailsApi } from 'lib/error/hooks/useErrorDetailsState';

import { ErrorModalContext } from '../components/ErrorModalContext';

type Returns = Partial<ErrorDetailsApi>;

// IDEA Name independent of implementation e.g. useErrorModalApi
export const useErrorModalContext = (): Returns => {
  const { errorDetails, setErrorDetails } = React.useContext(ErrorModalContext);
  return { errorDetails, setErrorDetails };
};

const module = { useErrorModalContext };

export default module;
