/* eslint-disable react/prop-types */ // https://github.com/yannickcr/eslint-plugin-react/issues/2353
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Text, View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { addStory } from 'lib/story-books';

import { FPSStats } from './FPSStats';

const CENTER_STYLE_OBJ: ViewStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const CENTER_STYLE_STR = `
  display: flex;
  align-items: center;
`;

type MutatingStylesProps = {
  dynamic: ViewStyle[];
};
const wave = new Array(360 * 100).fill(null);
const BASE = 20;
const MOD = 20;
const col = (m: number): number => 100 + m * 155; //255 total
const WAVE = {
  val: wave.map((_e, i) => BASE + Math.sin(i / 100) * MOD),
  color: wave.map(
    (_e, i) =>
      `rgb(${col(Math.sin(i / 100))}, ${col(Math.cos(i / 100))}, ${col(
        Math.tan(i / 100)
      )})`
  ),
};

const ARRAY_LENGTH = 24;
const Stepper: FunctionComponent<{
  Component: FunctionComponent<MutatingStylesProps>;
}> = ({ Component }): ReactElement => {
  const [count, setCount] = useState(1);
  useEffect(() => {
    setTimeout(() => setCount(count + 1));
  }, [count]);
  const periodFrac = 45;
  const dynamic = new Array(360 / 45).fill(null).map((_e, i) => {
    const period = i * periodFrac;
    const index = (count + period) % 360;
    return {
      width: WAVE.val[index] + Math.random(), // dirty cache
      height: WAVE.val[index],
      backgroundColor: WAVE.color[index],
    };
  });
  return (
    <View style={{ width: '100%' }}>
      <Text>{`${count % 360} (${count})`}</Text>
      <Text>{`${
        // comp
        ARRAY_LENGTH
      } components x ${
        // children
        360 / periodFrac
      } children  = ${
        // total mutations
        (ARRAY_LENGTH * 360) / periodFrac
      } mutations`}</Text>
      <FPSStats />
      <View
        style={{
          ...CENTER_STYLE_OBJ,
          height: 300,
          width: '100%',
          flexGrow: 1,
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        {new Array(ARRAY_LENGTH).fill(<Component dynamic={dynamic} />)}
      </View>
    </View>
  );
};

function ViaStyleObj(props: MutatingStylesProps): ReactElement | null {
  if (!props.dynamic.length) {
    return <></>;
  }

  const [firstDynamic, ...restDynamic] = props.dynamic;
  return (
    <View style={{ ...CENTER_STYLE_OBJ, ...firstDynamic }}>
      <ViaStyleObj dynamic={restDynamic} />
    </View>
  );
}

const Styled = styled.View`
  ${CENTER_STYLE_STR}
`;
function ViaStyled(props: MutatingStylesProps): ReactElement | null {
  if (!props.dynamic.length) {
    return null;
  }

  const [firstDynamic, ...restDynamic] = props.dynamic;
  return (
    <Styled style={{ ...firstDynamic }}>
      <ViaStyleObj dynamic={restDynamic} />
    </Styled>
  );
}

addStory('perf/style/ViaStyleObj', () => <Stepper Component={ViaStyleObj} />);
addStory('perf/style/ViaStyled', () => <Stepper Component={ViaStyled} />);
