import React, { ReactElement } from 'react';

import { roles } from 'cross-platform/utils/roleProps';
import { SettingsIcon } from 'daylight/components/icons';
import { SettingsContainer } from 'daylight/components/screens/TodayScreen/TodayScreen.styled';

export const Settings = ({
  size,
  color,
  action,
}: {
  size: number;
  color: string;
  action: () => void;
}): ReactElement => {
  return (
    <SettingsContainer {...roles('SettingsMenu')}>
      <SettingsIcon size={size} color={color} onPress={action} />
    </SettingsContainer>
  );
};
