import React, { ReactElement } from 'react';
import { StyleProp, StyleSheet, TextInputProps, ViewStyle } from 'react-native';

import { TextInput as TextInputBase } from 'components/primitives/cross-platform';
import { pass } from 'cross-platform/utils/roleProps';
const flatten = StyleSheet.flatten;

export type PlatformInputProps = {
  value: string;
  onChangeText?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  type?: string;
  style?: StyleProp<ViewStyle>;
  placeholder?: string;
  placeholderTextColor?: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  secureTextEntry?: boolean;
  error?: boolean;
  'data-testid'?: string;
  autoCorrect?: boolean;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  keyboardType?: TextInputProps['keyboardType'];
  returnKeyType?: TextInputProps['returnKeyType'];
  maxLength?: number;
  ref?: (e: any) => void;
};

const TextInput = ({
  disabled,
  onChangeText,
  onBlur,
  onFocus,
  placeholder,
  style,
  value,
  secureTextEntry = false,
  autoFocus,
  placeholderTextColor,
  autoCorrect,
  autoCapitalize,
  keyboardType,
  returnKeyType,
  maxLength,
  ...rest
}: PlatformInputProps): ReactElement => {
  return (
    <TextInputBase
      editable={disabled}
      onChangeText={onChangeText}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor}
      value={value}
      autoFocus={Boolean(autoFocus)}
      style={flatten(style)}
      secureTextEntry={secureTextEntry}
      {...pass(rest)}
      accessibilityLabel={placeholder}
      autoCorrect={autoCorrect}
      autoCapitalize={autoCapitalize}
      keyboardType={keyboardType}
      disableFullscreenUI={true}
      returnKeyType={returnKeyType}
      maxLength={maxLength}
    />
  );
};

export default TextInput;
