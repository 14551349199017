import { Scene } from '@bighealth/types/dist';

const getSceneForProgressGoalsV2 = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      childNodes: [
        {
          alignment: {},
          childNodes: [],
          component: 'Text',
          scene_component_definition_id: '8',
          style: {
            fontSize: 19,
            fontWeight: '700',
            marginBottom: 40,
          },
          text:
            'Now, let\u2019s take a look at how you\u2019re doing with your goals.',
        },
        {
          component: 'ProgressGoalsV2',
          comment: {
            style: {
              color: 'red',
            },
          },
          commentMap: {
            up: {
              daytime_goal: 'yay',
              emotional_goal: 'yay',
              // overall_goal: 'yay',
            },
          },
          imageMap: {
            daytime_goal: {
              sourceProps: {
                storage_url:
                  '//app.sleepioqa.com/content/progress_review_daytimeGoalWithText_PNG__2023.09.19.11.21.39.615755.png',
                height: 86,
                width: 380,
              },
            },
            emotional_goal: {
              sourceProps: {
                storage_url:
                  '//app.sleepioqa.com/content/progress_review_emotionalGoalWithText_PNG__2023.09.19.11.22.32.918740.png',
                height: 62,
                width: 376,
              },
            },
            overall_goal: {
              sourceProps: {
                storage_url:
                  '//app.sleepioqa.com/content/progress_review_overallGoalWIthText_PNG__2023.09.19.11.23.20.140200.png',
                height: 112,
                width: 364,
              },
            },
          },
        },
      ],
    },
  ],
});

export { getSceneForProgressGoalsV2 };
