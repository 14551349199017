import { ProgramIds } from 'common/constants/enums';

import { ContentLinkArray, ContentLinkItem } from './types';

const aboutDaylightContent: ContentLinkArray = [
  {
    testID: 'Daylight-Suitable-link',
    link: 'https://www.trydaylight.com/suitable',
    text: 'Is Daylight right for you?',
  } as ContentLinkItem,
  {
    testID: 'UK-Instructions-link',
    link: 'http://go.bighealth.com/daylight-rebuild-eu-instructions-for-use',
    text: 'UK - Instructions for use',
  } as ContentLinkItem,
  {
    testID: 'US-Instructions-link',
    link: 'http://go.bighealth.com/daylight-rebuild-us-instructions-for-use',
    text: 'US - Instructions for use',
  } as ContentLinkItem,
  {
    testID: 'Regions-Instructions-link',
    link:
      'http://go.bighealth.com/daylight-rebuild-wellness-instructions-for-use',
    text: 'Other regions - Instructions for use',
  } as ContentLinkItem,
];

const aboutBlossomContent: ContentLinkArray = [
  {
    testID: 'Daylight-Suitable-link',
    link: 'https://www.trydaylight.com/suitable',
    text: 'Is Daylight right for you?',
  } as ContentLinkItem,
  {
    testID: 'US-Instructions-link',
    link: 'http://go.bighealth.com/blossom-us-instructions-for-use',
    text: 'US - Instructions for use',
  } as ContentLinkItem,
];

export const getIFUContent = (
  programId: number | undefined
): ContentLinkArray => {
  switch (programId) {
    case ProgramIds.BLOSSOM:
      return aboutBlossomContent;
    case ProgramIds.DAYLIGHT:
      return aboutDaylightContent;
    default:
      return aboutDaylightContent;
  }
};
