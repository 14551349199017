import {
  NotificationResourceEvent,
  NotificationResourceEventDiscriminated,
} from '@bighealth/analytics';

import {
  UIEventCreator,
  UIEventCreatorContextArg,
} from 'lib/api/reactQueryHelpers/useLazyQueries/useLazyQueryFireUIEvent';

export type EventArgs = {
  action: NotificationResourceEvent['action'];
  fields: NotificationResourceEvent['body']['fields'];
};

const createDiscriminated = (
  eventArgs: {
    action: NotificationResourceEventDiscriminated['action'];
    fields: NotificationResourceEventDiscriminated['body']['fields'];
  },
  context: UIEventCreatorContextArg
): NotificationResourceEventDiscriminated | never => {
  const meta = {
    http: {
      current_url: context.url.href,
      referrer: context.url.href,
      user_agent: context.userAgent,
      client_app_node_package_version: context.clientAppNodePackageVersion,
      product_stream_version: context.productVersionStream,
    },
  };
  switch (eventArgs.action) {
    case 'user-action':
      switch (eventArgs.fields.value) {
        case 'app-notif-time-is-updated':
          // As this has time, TypeScript needs a separate
          // "discriminating" code path to confirm the type
          return {
            action: eventArgs.action,
            body: {
              meta,
              fields: eventArgs.fields,
              relations: {
                scene_set_graph: {
                  id: context.sceneSetGraphId,
                },
              },
            },
          };
        case 'os-prompt':
        case 'app-opened-from-notif':
        case 'app-is-enabled':
        case 'app-is-disabled':
          return {
            action: eventArgs.action,
            body: {
              meta,
              fields: eventArgs.fields,
              relations: {
                scene_set_graph: {
                  id: context.sceneSetGraphId,
                },
              },
            },
          };
        default:
          throw TypeError(
            `Unknown action/value ${eventArgs.action}/${eventArgs.fields.value}`
          );
      }
    case 'context-modal-shown':
      switch (eventArgs.fields.value) {
        case 'source-auto-prompt':
        case 'source-settings':
          return {
            action: eventArgs.action,
            body: {
              meta,
              fields: eventArgs.fields,
              relations: {
                scene_set_graph: {
                  id: context.sceneSetGraphId,
                },
              },
            },
          };
        default:
          throw TypeError(
            `Unknown action/value ${eventArgs.action}/${eventArgs.fields.value}`
          );
      }
    case 'os-settings-updated':
      switch (eventArgs.fields.value) {
        case 'os-is-enabled':
        case 'os-is-disabled':
          return {
            action: eventArgs.action,
            body: {
              meta,
              fields: eventArgs.fields,
              relations: {
                scene_set_graph: {
                  id: context.sceneSetGraphId,
                },
              },
            },
          };
        default:
          throw TypeError(
            `Unknown action/value ${eventArgs.action}/${eventArgs.fields.value}`
          );
      }
  }
  // Not dead code
  throw TypeError(
    `Unknown action/value ${eventArgs.action}/${eventArgs.fields.value}`
  );
};

const createEvent = (
  eventArgs: EventArgs,
  context: UIEventCreatorContextArg,
  customName?: string
): NotificationResourceEvent => {
  return {
    occurred_at: context.occurredAt,
    product_id: context.productId,
    program_id: context.programId,
    resource: 'notification',
    version: '1.0.0',
    type: 'ui',
    name: customName
      ? `notification|user-action|CUSTOM-${customName}`
      : `notification|${eventArgs.action}|${eventArgs.fields.value}`,
    ...createDiscriminated(eventArgs, context),
  };
};

export const notification = (
  eventArgs: EventArgs,
  customName?: string
): UIEventCreator =>
  async function uiEventCreator(context): Promise<[NotificationResourceEvent]> {
    return [createEvent(eventArgs, context, customName)];
  };
