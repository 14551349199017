/* eslint-disable camelcase */
import {
  DateResponseOption,
  DeepWritable,
  Question,
  ResponseOption,
} from '@bighealth/types';

import { moveToEpochZero } from 'components/generic-question/DateTimePickers/utils/moveToEpochZero';
import deepMapWhere, { Source } from 'lib/deep-map-where';
import { isDate, isObject, isString } from 'lib/type-guarded';

import { toJavasScriptDate } from '../toJavaScriptDate';

/**
 * Transforms payloads as they are received by the client app
 *
 * Usually client code is expected to treat response payloads as read-only.
 *
 * But this special "middleware" code mimics backend logic
 * @see {@link https://expressjs.com/en/guide/using-middleware.html}
 *
 * It thus has a higher "permission" than normal client-app code,
 * which is why we use DeepWritable to allow changes to the payloads.
 */
const transformToJavaScriptDate = <I extends Source>(apiPayload: I): I =>
  // IDEA Use reviver in JSON.parse(str, reviver) or JSON tree walker package
  // LIMITATIONS
  // - chaining requires constant serialization
  // WARNING can't use runtime objects
  deepMapWhere(
    apiPayload,
    (key): boolean => key === 'questionProps',
    (v): Question => {
      const q: DeepWritable<Question> = v as Question;
      switch (q.response_type) {
        case 'time':
        case 'date':
        case 'datetime': {
          const initial_visible_response_value =
            q.response_config?.initial_visible_response_value;
          if (
            isObject(initial_visible_response_value) &&
            !isString(initial_visible_response_value) &&
            !isDate(initial_visible_response_value)
          ) {
            q.response_config.initial_visible_response_value = moveToEpochZero(
              toJavasScriptDate(initial_visible_response_value)
            );
          }
          q.response_config.response_options = q.response_config.response_options.map(
            (option: ResponseOption): ResponseOption => {
              const dateOption: DateResponseOption = option as DateResponseOption;
              const { max_response, min_response } = dateOption;
              if (
                typeof max_response === 'undefined' &&
                typeof min_response === 'undefined'
              ) {
                return option;
              } else if (
                max_response instanceof Date ||
                min_response instanceof Date
              ) {
                return option;
              }
              return {
                ...dateOption,
                max_response: max_response
                  ? toJavasScriptDate(max_response)
                  : max_response,
                min_response: min_response
                  ? toJavasScriptDate(min_response)
                  : min_response,
              };
            }
          );
          break;
        }
      }
      return q;
    }
  ) as I;

export { transformToJavaScriptDate };
