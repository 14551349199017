import React, { useMemo } from 'react';

import { DateValueObject } from 'components/forms/ResponseOptions/ResponseInput/types';

import { Dropdown, DropdownItem, DropdownOption } from '../Dropdown';

import { createValueChangeHandler } from './helpers/createValueChangeHandler';
import { filterInvalidOptions } from './helpers/filterInvalidOptions';
import { getOutOfBoundsOptionsIfNeeded } from './helpers/getOutOfBoundsOptionsIfNeeded';
import { deriveTimeResponseOptions } from './helpers/getResponseOptions';
import { valueToDropdownItem } from './helpers/valueToDropdownItem';
import { getDisplayText } from './helpers/valueToDropdownItem/getDisplayText';
import { DEFAULT_TIME_INTERVAL } from './constants';

export type DropdownTimePickerProps = {
  min?: Date;
  max?: Date;
  selectedValue?: Date;
  onValueChange?: (value: Date | null) => void;
  highlight?: boolean;
  value?: DropdownItem[];
  hideEmptyOption?: boolean;
  state?: DateValueObject;
  interval?: number;
};

const DropdownTimePicker = ({
  min,
  max,
  selectedValue,
  onValueChange,
  highlight,
  hideEmptyOption,
  state,
  interval = DEFAULT_TIME_INTERVAL,
}: DropdownTimePickerProps): React.ReactElement => {
  const { date } = state || {};
  const derivedOptions = useMemo(() => {
    return filterInvalidOptions(
      deriveTimeResponseOptions({
        min,
        max,
        interval,
      }),
      date
    );
  }, [date, interval, max, min]);

  const outOfBoundsOptions = getOutOfBoundsOptionsIfNeeded(
    derivedOptions,
    selectedValue
  );
  const options = [...derivedOptions, ...outOfBoundsOptions];
  const selectedOption = options.find(
    option => `${selectedValue}` === `${option}`
  );
  const selectedOptionAsDropdownItem = valueToDropdownItem(selectedOption);
  return (
    <Dropdown
      hideEmptyOption={hideEmptyOption}
      value={selectedOptionAsDropdownItem}
      onValueChange={createValueChangeHandler(onValueChange)}
      highlight={highlight}
    >
      {options.map(date => {
        const displayText = getDisplayText(date);
        return (
          <DropdownOption
            key={`${date}`}
            value={`${date}`}
            displayText={displayText}
            label={displayText}
          />
        );
      })}
    </Dropdown>
  );
};
export { DropdownTimePicker };
