import { SceneComponentWithParent } from './addParentNodes';

type Predicate = (node: SceneComponentWithParent) => boolean;

enum NodeTypes {
  SCENE_SET = 'SceneSet',
  SCENE = 'Scene',
}

export function getParent(
  node: SceneComponentWithParent
): SceneComponentWithParent | undefined {
  return node?.__parent__;
}

export function getSceneSet(
  node: SceneComponentWithParent
): SceneComponentWithParent | undefined {
  let currentNode: SceneComponentWithParent | undefined = node;
  while (currentNode.__parent__) {
    currentNode = currentNode.__parent__;
  }
  return currentNode;
}

export function getAncestorByPredicate(
  node: SceneComponentWithParent,
  predicate: Predicate
): SceneComponentWithParent | undefined {
  let currentNode: SceneComponentWithParent | undefined = node;
  while (!predicate(currentNode)) {
    if (!currentNode.__parent__) {
      currentNode = undefined;
      break;
    }
    currentNode = currentNode.__parent__;
  }
  return currentNode;
}

export function getParentScene(
  node: SceneComponentWithParent
): SceneComponentWithParent {
  if (node.component === NodeTypes.SCENE_SET) {
    throw new Error(
      `Node of type \`${node.component}\` is not permitted as an argument to getParentSceneSet`
    );
  }
  return getAncestorByPredicate(
    node,
    node => node && node.component === 'Scene'
  ) as SceneComponentWithParent;
}

export function getParentSceneSet(
  node: SceneComponentWithParent
): SceneComponentWithParent {
  if (node.component === NodeTypes.SCENE_SET) {
    throw new Error(
      `Node of type \`${node.component}\` is not permitted as an argument to getParentSceneSet`
    );
  }
  return getAncestorByPredicate(
    node,
    node => node && node.component === 'SceneSet'
  ) as SceneComponentWithParent; // These always exist
}

function childNodeIterator(
  currentNode: SceneComponentWithParent,
  predicate: Predicate
): void {
  if (predicate(currentNode)) {
    throw currentNode;
  }
  if (currentNode.childNodes) {
    for (const child of currentNode.childNodes) {
      childNodeIterator(child, predicate);
    }
  }
}

export function getFirstMatchingChildByPredicate(
  node: SceneComponentWithParent,
  predicate: Predicate
): SceneComponentWithParent | undefined {
  try {
    childNodeIterator(node, predicate);
  } catch (result) {
    return result;
  }
  return undefined;
}

export function getNextScene(
  node: SceneComponentWithParent
): SceneComponentWithParent {
  if (node.component === NodeTypes.SCENE_SET) {
    throw new Error(
      `Node of type \`${node.component}\` is not permitted as an argument to getNextScene`
    );
  }
  let scene;
  if (node.component === NodeTypes.SCENE) {
    scene = node as SceneComponentWithParent;
  } else {
    scene = getParentScene(node);
  }
  const sceneIndex = scene.__index__ as number;
  const sceneSet = getParentSceneSet(node);
  const nextScene = sceneSet?.childNodes?.[sceneIndex + 1];
  return nextScene as SceneComponentWithParent;
}

export function getNextSceneSiblingByPredicate(
  node: SceneComponentWithParent,
  predicate: Predicate
): SceneComponentWithParent | undefined {
  if (typeof node.__parent__?.__scenePath__ === 'undefined') {
    return undefined;
  }

  // Get the path of the parent of the node
  const pathAsArray = node.__parent__.__scenePath__
    .split('.')
    .map(str => parseInt(str, 10));

  // Set the path to the next Scene over. Use mutating operations
  // for speed. Alternative would be `const [, sceneId, ...rest] = pathOfParentAsArray` etc
  pathAsArray.shift();
  pathAsArray[0] = pathAsArray[0] + 1;

  const sceneSet = getSceneSet(node);
  let nextNodeDown = sceneSet;

  // Keep working down until we find a parent at the same depth as node's parent
  for (const depth in pathAsArray) {
    // ^--- definitely `in` as we want the index here
    nextNodeDown = nextNodeDown?.childNodes?.[pathAsArray[depth]];
  }

  if (typeof nextNodeDown?.childNodes === 'undefined') {
    return undefined;
  }

  // Iterate through all siblings of node in the next Scene and see if any
  // match the predicate
  for (const childNode of nextNodeDown.childNodes) {
    if (predicate(childNode)) {
      return childNode as SceneComponentWithParent;
    }
  }
  return undefined;
}
