import { useEffect } from 'react';
import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField,
  useFormikContext,
} from 'formik';

import { Question } from '@bighealth/types';
import { ResponseInputComponentType } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import { QuizInfo } from 'components/forms/InputField';
import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { useQuestion } from 'lib/question-response/useQuestionForCurrentScene';
import {
  useQuizForCurrentQuestion,
  UseQuizForCurrentQuestionReturn,
} from 'lib/question-response/useQuizForCurrentQuestion';
import {
  useQuizForCurrentScene,
  UseQuizForCurrentSceneReturn,
} from 'lib/question-response/useQuizForCurrentScene';

import { toDropdownItemFactory } from './utils/toDropdownItemFactory';
import { tryCreateValidate } from './utils/tryCreateValidate';

type useResponseOptionFieldReturn = {
  quizForScene: UseQuizForCurrentSceneReturn;
  quizForQuestion: UseQuizForCurrentQuestionReturn;
  field: FieldInputProps<DropdownItem[]>;
  meta: FieldMetaProps<DropdownItem[]>;
  helpers: FieldHelperProps<DropdownItem[]>;
  initialValue: DropdownItem[];
};

type Values = {
  values: Record<string, DropdownItem[]>;
};

const useResponseOptionField = (
  question: Question,
  component: ResponseInputComponentType,
  initialValueOverride?: Value
): useResponseOptionFieldReturn => {
  const defaultIds = question.response_config.default_response_ids;
  const responseOptions = question.response_config.response_options;
  const initialValue = responseOptions.map(
    toDropdownItemFactory(component, initialValueOverride)
  );
  const [field, meta, helpers] = useField<DropdownItem[]>({
    name: question.semantic_id,
    multiple: true,
    validate: tryCreateValidate(question),
  });

  const quizForScene = useQuizForCurrentScene();
  const quizForQuestion = useQuizForCurrentQuestion(question.semantic_id);
  const questionHook = useQuestion();
  const { values: existingValues }: Values = useFormikContext();

  useEffect(() => {
    const existingValuesForQuestion = existingValues?.[question.semantic_id];
    if (typeof existingValuesForQuestion !== 'undefined') {
      // There's values in Formik already so use those
      const rehydratedInitialValue = initialValue.map(dropdownItem => {
        return (
          existingValuesForQuestion.find(item => item.id === dropdownItem.id) ||
          dropdownItem
        );
      });
      helpers.setValue(rehydratedInitialValue);
    } else if (typeof initialValueOverride !== 'undefined') {
      // Override were set so use the values derived them them
      helpers.setValue(initialValue);
    } else {
      // There's no values in Formik or in overrides so set them, setting isSelected
      // to true if the item is part of the default_response_ids list
      const initialWithDefaults = initialValue.map(option => {
        const isInDefaultIds = defaultIds.includes(option.id as number);
        const isInputField =
          responseOptions.find(o => o.id === option.id)?.value === '$input'; // ensures inputs are "selected"
        return {
          ...option,
          isSelected: isInDefaultIds || isInputField,
        };
      });
      helpers.setValue(initialWithDefaults);
    }

    const quizInfo: QuizInfo = {
      correct_response_ids: question.response_config.correct_response_ids,
      max_number_attempts: question.response_config.max_number_attempts,
    };
    questionHook.register(question);
    quizForScene.registerQuizInfoForQuestion(question.semantic_id, quizInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    quizForScene,
    quizForQuestion,
    field,
    meta,
    helpers,
    initialValue,
  };
};

export { useResponseOptionField };
