import * as React from 'react';
import { ReactElement, useContext } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import { printableProps } from 'components/layout/lib/printableProps';
import Link from 'components/Link';
import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';

import { ButtonProps } from './types';
import { useButtonInteractionStates } from './useButtonInteractionStates';
import { useDynamicButtonDimensions } from './useDynamicButtonDimensions';

const LinkStatic = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  box-sizing: border-box;
`;

// At first glance this file looks a lot like the web version and so you might expect
// a bit more DRY. However, there are some important difference. E.g. what element
// the styles are applied to, the event handlers, which styles can be used etc
export const UniversalLinkButton = (
  props: Omit<ButtonProps, 'onPress' | 'action'> & LinkProps
): ReactElement => {
  const {
    isDisabled = false,
    text,
    testID,
    colorScheme,
    style = {},
    textStyleProps = {},
    ...rest
  } = props;
  const theme = useContext(ThemeContext);
  const interactionStates = useButtonInteractionStates({
    ...props,
    colorScheme,
  });
  const dimensions = useDynamicButtonDimensions({ ...props, theme });

  const {
    style: { backgroundColor, color, borderColor },
    webEvents: { onFocus, onBlur, onMouseEnter, onMouseLeave },
  } = interactionStates;

  const { fontSize, lineHeight, ...viewDimensions } = dimensions;

  // Moved ...textStyleProps under color and fontSize to allow
  //  more adjustments for the new designs which differs from previous patterns
  return (
    <LinkStatic
      {...rest}
      {...roles(testID || `Button_${printableProps({ text, isDisabled })}`)}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        ...viewDimensions,
        backgroundColor,
        borderColor,
        borderStyle: 'solid',
        ...(style as Record<string, unknown>), // Work around TypeScript bug https://github.com/Microsoft/TypeScript/issues/10727
      }}
    >
      <Text
        style={{
          color,
          fontSize,
          ...(textStyleProps as Record<string, unknown>),
          lineHeight,
          textAlign: 'center',
        }}
      >
        {text}
      </Text>
    </LinkStatic>
  );
};
