import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';
import { LoginResultDomain } from '@bighealth/types';

export const login_user = async (
  args: login_user.Args,
  requestConfig?: AxiosRequestConfig
): Promise<login_user.Response> => {
  const request: login_user.Request = {
    service_name: 'GeneralLogin',
    service_version: '1',
    service_method: 'login_user',
    args,
  };

  return await api(request, requestConfig);
};

login_user.queryKey = 'GeneralLogin/login_user';

export declare namespace login_user {
  export type Args = {
    email: string;
    password: string;
    product_id: number;
    device_platform: string;
    next_url?: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'GeneralLogin';
    service_version: '1';
    service_method: 'login_user';
    args: Args;
  }

  export type Result = {
    status: string;
    message: string;
    redirect: string;
    domain: LoginResultDomain;
    refresh_token?: string;
  };

  export type Response = APIResponse<Result>;
}
