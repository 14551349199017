import * as React from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';
import styled from 'styled-components/native';

import { Hoverable, HoverableProps } from 'components/Hoverable/Hoverable';
import { Chevron } from 'components/icons';
import { roles } from 'cross-platform/utils/roleProps';
import { flattenStyles } from 'lib/styles/flattenStyles';

type WrapperProps = {
  scaleX: number;
  scale: number;
  disabled?: boolean;
  isHovering?: boolean;
  style?: HoverableProps['style'];
  width: number;
  backgroundColor?: string;
};

const Wrapper = styled.View<WrapperProps>`
  background-color: ${props =>
    props.isHovering ? '#1A80A2' : props.backgroundColor || 'white'};
  border-radius: ${p => p.width / 2}px;
  width: ${p => p.width}px;
  height: ${p => p.width}px;
  transform: scale(${(p: WrapperProps) => `${p.scaleX * p.scale}, ${p.scale}`});
  opacity: ${(p: WrapperProps) => (p.disabled ? 0.5 : 1)};
`;

export type NavButtonProps = Omit<WrapperProps, 'width' | 'scale'> & {
  onPress: TouchableOpacityProps['onPress'];
  foregroundColor?: string;
  backgroundColor?: string;
  scale?: number;
  width?: number;
};

const NavButton = (props: NavButtonProps): JSX.Element => (
  <TouchableOpacity
    disabled={props.disabled}
    onPress={props.disabled ? undefined : props.onPress}
    {...roles.pass(
      props,
      `NavButton:${props.disabled ? 'disabled' : 'enabled'}`
    )}
    accessibilityRole="button"
    aria-label={
      props.scaleX === 1
        ? 'Left to see other options'
        : 'Right to see other options'
    }
  >
    <Hoverable disabled={props.disabled} style={flattenStyles(props.style)}>
      {({ isHovering = false }) => {
        return (
          <Wrapper
            scaleX={props.scaleX}
            scale={props?.scale || 1}
            disabled={props.disabled}
            isHovering={isHovering}
            width={props.width || 35}
            backgroundColor={props.backgroundColor}
          >
            <Chevron
              directionOption={props.scaleX === 1 ? 'left' : 'right'}
              direction={'left'}
              size={props.width}
              isHovering={isHovering}
              style={{ color: props.foregroundColor }}
            />
          </Wrapper>
        );
      }}
    </Hoverable>
  </TouchableOpacity>
);

export { NavButton };
