import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { View } from 'react-native';

import { roles } from 'cross-platform/utils/roleProps';
import { ErrorBoundary } from 'lib/error/ErrorBoundary/ErrorBoundary';
import { addStory } from 'lib/story-books';

import { ErrorDetailsApi } from '../hooks/useErrorDetailsState';
import { ErrorDetails } from '..';

import { CustomErrorFallback } from './components/CustomErrorFallback';
import { FauxErrorModal } from './components/FauxErrorModal';
import { IsThrowApi, ThrowButton } from './components/ThrowButton';

// Story
export const ErrorHandlingStory = (): JSX.Element => {
  const throwing: {
    global?: IsThrowApi;
    player?: IsThrowApi;
  } = {};
  {
    const [isThrow, setIsThrow] = React.useState(false);
    throwing.global = { isThrow, setIsThrow };
  }
  {
    const [isThrow, setIsThrow] = React.useState(false);
    throwing.player = { isThrow, setIsThrow };
  }

  const erroring: {
    global?: ErrorDetailsApi;
    player?: ErrorDetailsApi;
  } = {};
  {
    const [errorDetails, setErrorDetails] = React.useState<
      ErrorDetails | undefined
    >();
    erroring.global = { errorDetails, setErrorDetails };
  }
  {
    const [errorDetails, setErrorDetails] = React.useState<
      ErrorDetails | undefined
    >();
    erroring.player = { errorDetails, setErrorDetails };
  }
  return (
    <View
      style={{
        width: '100%',
      }}
    >
      {/* ---------  */}
      {/* -- <HOME>  */}
      <ErrorBoundary
        onError={error => {
          erroring.global?.setErrorDetails({
            error,
            actions: [
              {
                text: 'cancel',
                onPress: () => {
                  erroring.global?.setErrorDetails(undefined);
                  throwing.global?.setIsThrow(false);
                },
              },
            ],
          });
          erroring.player?.setErrorDetails(undefined);
        }}
      >
        <ThrowButton
          {...roles('home-throw')}
          api={throwing.global}
          text="Home error"
        />
        {/* -------------  */}
        {/* ---- <PLAYER>  */}
        <ErrorBoundary
          fallbackRender={(props: FallbackProps) => (
            <CustomErrorFallback
              error={props.error}
              resetErrorBoundary={() => {
                // For demo purposes, FauxErrorModal takes care of state
                props.resetErrorBoundary();
              }}
            />
          )}
          onError={error => {
            const errorDetails = { error };
            erroring.player?.setErrorDetails(errorDetails);
            erroring.global?.setErrorDetails(undefined);
          }}
        >
          <ThrowButton
            {...roles('player-throw')}
            api={throwing.player}
            text="Player error"
          />
        </ErrorBoundary>
        <FauxErrorModal
          {...erroring.player.errorDetails}
          heading="Player heading override"
          onClose={() => {
            erroring.player?.setErrorDetails(undefined);
            throwing.player?.setIsThrow(false);
          }}
        />
        {/* ---- </PLAYER>  */}
        {/* --------------  */}
      </ErrorBoundary>
      {/* -- </HOME>  */}
      {/* ----------  */}
    </View>
  );
};

addStory('error-handling', ErrorHandlingStory);
