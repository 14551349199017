import React, { ReactElement } from 'react';

import { GradientBackground } from './GradientBackground';
import { SolidBackground } from './SolidBackground';
import { BackgroundProps } from './types';

export const BackgroundSection = (
  props: BackgroundProps
): ReactElement | null => {
  const { backgroundColors } = props;
  if (
    typeof backgroundColors === 'undefined' ||
    backgroundColors.length === 0
  ) {
    return null;
  } else if (backgroundColors.length > 1) {
    return <GradientBackground {...props} />;
  } else {
    return <SolidBackground {...props} />;
  }
};
