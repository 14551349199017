import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';

import { Question } from '@bighealth/types/src';

import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { MINUTE } from 'lib/durations';
import * as Reporter from 'lib/reporter';

import { transformDateMaxToUpperBound } from './transformDateMaxToUpperBound';

type ValidateFunction = (values: DropdownItem[]) => string | undefined;

const DOMAIN = 'QuestionPropsValidation';

const ajv = new Ajv({
  schemaId: 'auto',
  allErrors: true,
  jsonPointers: true,
});
AjvErrors(ajv);

export const createInputFromValues = (
  values: DropdownItem[] = [],
  type: Question['response_type']
): Record<string | number, Value | (string | number)[]> & {
  /**
   * INFO "selections" created for validation
   * @see PR {@link https://github.com/sleepio/platform-service-cluster/pull/79/files#diff-fbcc0daf3d44eccf71a668a233002df2R13}
   * @see File {@link https://github.com/sleepio/platform-service-cluster/src/big_health/services/question/utils.py}
   */
  selections: (string | number)[];
} => {
  const selected = values?.filter(
    e =>
      e.isSelected &&
      // IDEA: Investigate: Sometimes initial date values seem to be null
      e.value !== null
  );
  if (!selected) {
    return {
      selections: [],
    };
  }

  const formatted = selected.reduce((prev, option) => {
    if (typeof option.id === 'undefined') {
      throw ReferenceError(
        `id must be set on option: ${JSON.stringify(option)}`
      );
    }
    let newOptionValue;
    if (option.value instanceof Date) {
      const tzOffset =
        new Date(option.value.valueOf()).getTimezoneOffset() * MINUTE;
      newOptionValue = option.value.valueOf() - tzOffset;
    } else if (type.toLowerCase() === 'number' && option.value !== '') {
      // We specifically need to check that option.value is not an empty string because Number('') === 0
      newOptionValue = Number(option.value);
    } else {
      newOptionValue = option.value;
    }

    if (typeof newOptionValue === 'number' && isNaN(newOptionValue)) {
      throw TypeError(
        `Expected option.value for option.id ${String(option.id)} (${
          option.value
        }) to cast to number, instead got NaN`
      );
    }
    return {
      ...prev,
      [String(option.id)]: newOptionValue,
    };
  }, {});
  return {
    ...formatted,
    selections: selected.map(e => {
      if (typeof e.id === 'undefined') {
        throw ReferenceError(`${JSON.stringify(e)} has no id`);
      }
      return e.id;
    }),
  };
};

const tryCreateValidate = (
  question: Question
): ValidateFunction | undefined => {
  let schema = question.response_config.validation_schema;
  const type = question.response_type;
  if (Object.keys(schema).length === 0) {
    return undefined;
  }
  if (type === 'date') {
    schema = transformDateMaxToUpperBound(schema);
  }
  let validateUsingSchema: Ajv.ValidateFunction;
  try {
    validateUsingSchema = ajv.compile(schema);
  } catch (e) {
    const errMsg = `Question ID "${
      question.id
    }" has invalid JSONSchema ${JSON.stringify(schema)}`;
    Reporter.error(DOMAIN, errMsg);
    return undefined;
  }
  const validate = (values: DropdownItem[]): string | undefined => {
    const input = createInputFromValues(values, type);
    validateUsingSchema(input);

    if (validateUsingSchema.errors) {
      const ajvError = validateUsingSchema.errors[0];
      const id = parseInt(ajvError.dataPath.replace(/^./, ''), 10);
      if (typeof id !== 'number') {
        throw ReferenceError(`id (${id}) cannot be parsed to Int`);
      } else if (typeof ajvError.message !== 'string') {
        throw ReferenceError(`ajvError has no message property`);
      }
      return ajvError.message;
    }
    return undefined;
  };
  return validate;
};

export { tryCreateValidate };
