import React, { ReactElement } from 'react';

import { ProgressEntry } from '@bighealth/types';

import { Green } from './icons/Green';
import { Grey } from './icons/Grey';
import { Red } from './icons/Red';

type Props = {
  icon?: ProgressEntry['icon'];
  size: number;
};

export const GoalIcon = (props: Props): ReactElement | null => {
  if (!props.icon) {
    return null;
  }
  switch (props.icon) {
    case 'up':
      return <Green size={props.size} />;
    case 'down':
      return <Red size={props.size} />;
    case 'neutral':
      return <Grey size={props.size} />;
    default:
      return null;
  }
};
