import React, { PropsWithChildren, ReactElement, useContext } from 'react';

export enum MediaContainerScalingBehavior {
  CONTAIN = 'CONTAIN',
  COVER = 'COVER',
}

type Props = PropsWithChildren<{
  behavior: MediaContainerScalingBehavior;
}>;

const Context = React.createContext(MediaContainerScalingBehavior.CONTAIN);

export const useMediaContainerScalingBehaviorContext = (): MediaContainerScalingBehavior =>
  useContext(Context);

/**
 * Provider for media container scaling behavior.
 * [Read this for more information](https://bighealth.atlassian.net/wiki/spaces/PE/pages/469795443).
 */
export const MediaContainerScalingBehaviorProvider = ({
  behavior,
  children,
}: Props): ReactElement => {
  return <Context.Provider value={behavior}>{children}</Context.Provider>;
};
