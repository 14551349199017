import { MINUTE } from 'lib/durations';

/**
 * Take date, and offset it by the timezone
 *
 * @param date
 * @returns
 */
const createTimezoneOffsetDate = (date: Date): Date => {
  const tzOffset = date.getTimezoneOffset() * MINUTE;
  return new Date(date.getTime() + tzOffset);
};

export { createTimezoneOffsetDate };
