import { DateResponseOption, Question } from '@bighealth/types';

import { DEFAULT_TIME_INTERVAL } from 'components/generic-question/DropdownTimePicker/constants';
import { deriveTimeResponseOptions } from 'components/generic-question/DropdownTimePicker/helpers/getResponseOptions';
import { getDisplayText } from 'components/generic-question/DropdownTimePicker/helpers/valueToDropdownItem/getDisplayText';
import { MINUTE } from 'lib/durations';
import { getDateFromHoursMinutesSeconds } from 'lib/getDateFromHoursMinutesSeconds';

export const parseInitialValue = (
  questionProps?: Question
): string | number | Date | undefined => {
  // use null coalescing to ensure values like 0 and '' are valid
  const value = questionProps?.previous_responses?.[0]?.value ?? null;
  if (value === null) {
    return undefined;
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  }
  const { min_response, max_response, interval_time_minutes } =
    (questionProps?.response_config
      ?.response_options[0] as DateResponseOption) || {};
  const interval = interval_time_minutes
    ? interval_time_minutes * MINUTE
    : DEFAULT_TIME_INTERVAL;
  const derivedOptions = deriveTimeResponseOptions({
    min: min_response,
    max: max_response,
    interval,
  });
  const selectedValue = getDateFromHoursMinutesSeconds(value.$time, false);
  return (
    derivedOptions.find(option => {
      return getDisplayText(selectedValue) === getDisplayText(option);
    }) || selectedValue
  );
};
