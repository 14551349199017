import React, { ReactElement, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { Footer } from 'components/Footer';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { VerticalScroll } from 'components/VerticalScroll/VerticalScroll';
import { FooterTheme } from 'config/getThemeForProducts/utils/createTheme/footerSchemesTypes';
import { isMobileWeb } from 'config/isMobileWeb';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { isResultMobileWeb } from 'lib/utils/guards/isResultMobileWeb';
import { getIsFirstLogin } from 'state/user/selectors';

import { Background } from './components/Background';
import { DiaryCards } from './components/DiaryCards';
import { LayoutComponent } from './components/LayoutComponent/LayoutComponent';
import { OptionalList } from './components/OptionalList';
import { PreviewModal } from './components/PreviewModal';
import { ProgressReviewWidget } from './components/ProgressReviewWidget';
import { SessionList } from './components/SessionList';
import { SessionStatus, SessionStatusProps } from './components/SessionStatus';
import {
  BottomRow,
  Content,
  ContentWrapper,
  Grid,
  SectionHeadingText,
  TopRow,
} from './styled';

type Props = RoleProps & {
  data: get_user_session_screen_data.Result;
};
/**
 * SessionScreen: Simple render of payload of User session screen
 *
 * @param {Props} props
 */
const Presentation = (props: Props): ReactElement => {
  const isFirstLogin = useSelector(getIsFirstLogin);
  const [visible, setVisible] = useState(isFirstLogin);

  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);

  if (isResultMobileWeb(props.data)) {
    return (
      <>
        <VerticalScroll {...roles('SessionScreenVerticalScroll')}>
          <Background data={props.data} />
          <ContentWrapper>
            <Grid {...roles.pass(props)}>
              <TopRow
                style={{
                  marginTop: 30,
                  marginBottom: 45,
                  flexDirection: 'column',
                }}
              >
                <LayoutComponent {...props.data.header.title} />
                <LayoutComponent {...props.data.header.content} />
                <LayoutComponent {...props.data.header.buttons} />
              </TopRow>
              <BottomRow scaleYValue={scaleYValue}>
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LayoutComponent {...props.data.main} />
                </View>
              </BottomRow>
            </Grid>
          </ContentWrapper>
          <Footer footerTheme={FooterTheme.Dark} />
        </VerticalScroll>
      </>
    );
  }

  if (typeof props.data.modal !== 'undefined' && visible) {
    return (
      <PreviewModal
        visible={visible}
        setVisible={setVisible}
        modal={props.data.modal}
      />
    );
  }

  const show_optional_list =
    props.data.optional_content_list &&
    props.data.optional_content_list.length > 0;

  return (
    <>
      <VerticalScroll {...roles('SessionScreenVerticalScroll')}>
        <Background data={props.data} />
        <ContentWrapper>
          <Grid {...roles.pass(props)}>
            {props.data.progress_review_data && (
              <ProgressReviewWidget
                button={props.data.progress_review_data?.buttons}
                action={props.data.progress_review_data?.action}
                heading={props.data.progress_review_data?.heading}
              />
            )}
            <TopRow>
              <SessionStatus
                {...(props.data.session_status as SessionStatusProps)}
              />
            </TopRow>
            {props.data.sleep_diary_data ? (
              <DiaryCards data={props.data.sleep_diary_data} />
            ) : null}
            {/*props.data.session_heading &&*/
            show_optional_list ? (
              <BottomRow style={{ flex: 1 }} scaleYValue={scaleYValue}>
                <Content>
                  <SectionHeadingText
                    // {...props.data.session_heading}
                    text={'Your core sessions'}
                  />
                </Content>
              </BottomRow>
            ) : null}
            <BottomRow
              scaleYValue={scaleYValue}
              style={{
                flexGrow:
                  props.data.distribution_buttons !== undefined && isMobileWeb
                    ? 0
                    : 1,
              }}
            >
              <SessionList data={props.data.session_list} />
            </BottomRow>
            {show_optional_list ? (
              <>
                <BottomRow style={{ flex: 1 }} scaleYValue={scaleYValue}>
                  <Content>
                    <SectionHeadingText
                      // {...props.data.optional_sub_heading}
                      text="More for you"
                    />
                  </Content>
                </BottomRow>
                <BottomRow
                  scaleYValue={scaleYValue}
                  style={{
                    flexGrow:
                      props.data.distribution_buttons !== undefined &&
                      isMobileWeb
                        ? 0
                        : 1,
                  }}
                >
                  <OptionalList data={props.data.optional_content_list} />
                </BottomRow>
              </>
            ) : null}

            {props.data.distribution_buttons !== undefined ? (
              <BottomRow scaleYValue={scaleYValue}>
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 23,
                    marginBottom: 50,
                  }}
                >
                  <LayoutComponent {...props.data.distribution_buttons} />
                </View>
              </BottomRow>
            ) : null}
          </Grid>
        </ContentWrapper>
        <Footer footerTheme={FooterTheme.Dark} />
      </VerticalScroll>
    </>
  );
};

export { Presentation };
