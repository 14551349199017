import { useCallback, useEffect, useState } from 'react';

import {
  UseServiceMethodCall,
  useServiceMethodCall,
} from 'lib/api/hooks/useServiceMethodCall';
import * as reporter from 'lib/reporter';

import { ActionTypes } from '../useServiceMethodCall/ActionTypes';

export const errorMessages = {
  generalError: 'There was an error retrieving feature flags.',
  noFlagsError: 'No flags were received.',
};

export const useFeatureFlags = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [flagsRequested, setFlagsRequested] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<{
    [key: string]: boolean | string;
  }>({});
  const [featureFlagsState, requestFeatureFlags] = useServiceMethodCall({
    serviceName: 'ClientGateway',
    serviceVersion: '1',
    serviceMethod: 'get_feature_flags',
    args: {},
  }) as ReturnType<UseServiceMethodCall>;

  const handleError = (errorMsg: string, error?: Error) => {
    setLoading(false);
    setError(errorMsg);
    reporter.log(errorMsg, error, { silent: true });
  };

  // -----------------------------------------------------------------
  // Get Feature Flags from Platgen
  // -----------------------------------------------------------------
  const getFeatureFlags = useCallback(async () => {
    try {
      setLoading(true);
      setFlagsRequested(true);
      await requestFeatureFlags({});
    } catch (err) {
      handleError(errorMessages.generalError, err);
    }
  }, [requestFeatureFlags]);

  // Handle receiving the feature flags.
  useEffect(() => {
    switch (featureFlagsState?.status) {
      case ActionTypes.FETCHING:
        setLoading(true);
        setError(undefined);
        break;
      case ActionTypes.SUCCESS:
        if (featureFlagsState.response?.result) {
          setLoading(false);
          setError(undefined);

          setFeatureFlags(featureFlagsState.response?.result);
        } else {
          handleError(errorMessages.noFlagsError);
        }
        break;
      case ActionTypes.ERROR: {
        handleError(errorMessages.generalError);
        break;
      }
      default:
        setLoading(false);
        setError(undefined);
        break;
    }
  }, [featureFlagsState]);

  return {
    loading: loading,
    error: error,
    flagsRequested: flagsRequested,
    featureFlags: featureFlags,
    getFeatureFlags,
  };
};
