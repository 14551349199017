import { start_checkin } from '@bighealth/api/DaylightCheckin/v1/start_checkin';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';

export const queryClientDaylightCheckin = async ({
  product_id,
  program_id,
  scene_set_graph_id,
  scene_set_id,
}: {
  product_id: number;
  program_id: number;
  scene_set_graph_id: number;
  scene_set_id: number;
}): Promise<start_checkin.Response> => {
  const result = await getQueryClient().fetchQuery(
    start_checkin.queryKey,
    () =>
      start_checkin({
        product_id: product_id,
        program_id: program_id,
        scene_set_graph_id: scene_set_graph_id,
        scene_set_id: scene_set_id,
      }),
    {
      cacheTime: 0,
    }
  );
  // TP-580: Regression fix, we need to add the scene set result into the react-query cache
  // so that subsequent calls do not hit the backend for this scene set again. This bug was happening
  // because we were not caching here and when we called the backend, we lost the `source` property
  // between this call and the follow-up call in useQueryAllDataForSceneSet
  await getQueryClient().setQueryData(['SceneSet', result.result?.id], result);
  return result;
};
