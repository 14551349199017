import React from 'react';

import { Modal } from 'components/PopupModal/components/Modal';
import {
  ScalingContext,
  ScalingContextProvider,
} from 'components/ResponsiveLayout';
import * as roleProps from 'cross-platform/utils/roleProps';

import MFADisableFlow from './components/disabled/MFADisableFlow';
import MFAEnableFlow from './components/enabled/MFAEnableFlow';
import { OverlayTint, Panel, Wrapper } from './components/styled/styled';

export type MFAModalProps = {
  onRequestClose: () => void;
  visible?: boolean;
  isMFAEnabled: boolean | undefined;
  updateMFASettings: () => void;
  testID?: string;
};

const MFAModal = (props: MFAModalProps): React.ReactElement => {
  const { updateMFASettings, onRequestClose, visible = true } = props;
  // Pass the SLUG name for the IFU section of Downloads
  // and get the URL to redirect to.

  // For Enable / Disable workflows
  // Force update the local mfa_enabled flag
  const toggleMFAFlag = () => {
    updateMFASettings();
    onRequestClose();
  };

  return (
    <ScalingContextProvider scalingContext={ScalingContext.Modal}>
      <Wrapper
        visible={visible}
        {...roleProps.pass(props, undefined, { default: 'MFAModalWrapper' })}
      >
        <Modal
          visible={visible}
          transparent
          supportedOrientations={['portrait', 'landscape']}
        >
          <OverlayTint {...roleProps.roles('MFAModalOverlayTint')}>
            <Panel {...roleProps.roles('MFAModalPanel')}>
              {props.isMFAEnabled === true ? (
                <MFADisableFlow
                  onRequestClose={onRequestClose}
                  toggleMFAFlag={toggleMFAFlag}
                />
              ) : (
                <MFAEnableFlow
                  onRequestClose={onRequestClose}
                  toggleMFAFlag={toggleMFAFlag}
                />
              )}
            </Panel>
          </OverlayTint>
        </Modal>
      </Wrapper>
    </ScalingContextProvider>
  );
};
export default MFAModal;
