import React, { ReactElement } from 'react';
import styled from 'styled-components/native';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import Image, { ResizeMode } from 'cross-platform/Image';
import { roles } from 'cross-platform/utils/roleProps';

import { BackgroundSection } from './components/BackgroundSection';

const BackgroundImage = styled(Image)`
  bottom: 0;
  width: 100%;
  max-width: 930px;
  position: absolute;
`;
BackgroundImage.displayName = 'Sessions.BackgroundImage';

const BackgroundContainer = styled.View`
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
`;
BackgroundContainer.displayName = 'Sessions.BackgroundContainer';

type BackgroundProps = {
  data: get_user_session_screen_data.Result;
};

export const Background = (props: BackgroundProps): ReactElement => {
  const storageUrl = props.data.background_image_asset?.storage_url;

  return (
    <BackgroundContainer>
      <BackgroundSection
        {...roles('Background')}
        style={{ flexGrow: 1 }}
        backgroundColors={['#163258']}
      />
      {storageUrl ? (
        <BackgroundImage
          {...roles('BackgroundImage')}
          src={storageUrl}
          fit={ResizeMode.contain}
          style={{
            width: '100%',
            height: '100%',
            maxWidth: 'auto',
          }}
        />
      ) : null}
    </BackgroundContainer>
  );
};
