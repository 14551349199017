import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { isDate } from 'lib/isDate';

import { formatDurationDisplayText } from '../formatDurationDisplayText';

const valueToDropdownItem = (
  selectedValue?: Value
): DropdownItem[] | undefined => {
  if (typeof selectedValue === 'undefined') {
    return undefined;
  }
  const displayText = isDate(selectedValue)
    ? formatDurationDisplayText(selectedValue.getTime())
    : `${selectedValue}`;

  return [
    {
      value: selectedValue,
      displayText,
      isSelected: true,
    },
  ];
};
export { valueToDropdownItem };
