import React, { ReactElement } from 'react';
import styled from 'styled-components/native';

import { RoleProps } from 'cross-platform/utils/roleProps';

import { BackgroundProps } from './types';

export const SolidContainer = styled.View`
  width: 100%;
  align-items: center;
`;
SolidContainer.displayName = 'Sessions.SolidContainer';

export const SolidBackground = ({
  children,
  style = {},
  backgroundColors,
  ...rest
}: BackgroundProps & RoleProps): ReactElement => {
  return (
    <SolidContainer
      {...rest}
      style={{ ...style, backgroundColor: backgroundColors[0] }}
    >
      {children}
    </SolidContainer>
  );
};
