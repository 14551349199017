import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { ProgramIds } from 'common/constants/enums';
import { CaptionsOff, CaptionsOn } from 'components/icons';
import { CC_ENABLED } from 'config/envVars/feature-flags';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';
import { CaptionState } from 'state/player/reducer';
import { getCaptionState } from 'state/player/selectors';

type Props = {
  onPress: () => void;
};

export const ClosedCaptionButton = ({
  onPress,
}: Props): ReactElement | null => {
  const theme = useTheme();
  const captionState = useSelector(getCaptionState);
  const programId = useQueryProgram()?.data?.result?.id;

  // We do not want the closed caption button to show on clinical research programs or pivotal clinical research programs.
  // TODO: Add a VWO feature flag
  if (
    !CC_ENABLED ||
    captionState === CaptionState.HIDDEN ||
    programId === ProgramIds.DAYLIGHT_CLINICAL_RESEARCH_PROGRAM ||
    programId === ProgramIds.DAYLIGHT_PIVOTAL_CLINICAL_RESEARCH_PROGRAM
  ) {
    return null;
  }

  const captionsIconSize = 28;
  let captionIcon = null;
  switch (captionState) {
    case CaptionState.ON:
      captionIcon = (
        <CaptionsOn
          style={theme.controls.player.captionIconButton.ON}
          size={captionsIconSize}
        />
      );
      break;
    case CaptionState.OFF:
      captionIcon = (
        <CaptionsOff
          style={theme.controls.player.captionIconButton.OFF}
          size={captionsIconSize}
        />
      );
      break;
  }

  return (
    <TouchableOpacity
      {...roles('ClosedCaptionButton')}
      accessibilityState={{ checked: captionState === CaptionState.ON }}
      accessibilityRole="togglebutton"
      style={{
        alignSelf: 'stretch',
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={async () => {
        onPress();
      }}
    >
      {captionIcon}
    </TouchableOpacity>
  );
};
