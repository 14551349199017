import { Scene } from '@bighealth/types';

const getSleepDiaryWeekScene = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      calendarWeekOffset: 1,
      childNodes: [],
      component: 'SleepDiaryWeek',
      includeMetadata: false,
      isExtrapolateButtonEnabled: true,
      period: 'last_seven_days',
      shouldShowExtrapolateButton: true,
      shouldShowExtrapolateButtonMaxCompleteDiaryCount: 7,
      shouldShowExtrapolateButtonMinCompleteDiaryCount: 1,
      subtitle: {
        color: '#003366',
        fontSize: 24,
      },
      subtitleAlignment: {
        horizontal: 'middle',
      },
      title: {
        color: '#000000',
        fontSize: 36,
        text: 'Do you have any missing diary days written down?',
      },
      titleAlignment: {
        horizontal: 'middle',
      },
    },
  ],
});

export { getSleepDiaryWeekScene };
