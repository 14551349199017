import React, { createContext, useContext } from 'react';

export type SubmitState = {
  onSubmitDone?: () => void;
};

type SubmissionCallbackState = {
  isSubmitting: boolean;
  submitState: SubmitState;
  setSubmitState: React.Dispatch<React.SetStateAction<SubmitState>>;
};

const initialState: SubmissionCallbackState = {
  submitState: {},
  isSubmitting: false,
  setSubmitState: () => undefined,
};

export const SubmitCallbackContext = createContext(initialState);
export const useSubmitCallbackContext = (): SubmissionCallbackState =>
  useContext(SubmitCallbackContext);

export const SubmitCallbackProvider = ({
  value,
  children,
}: {
  value: SubmissionCallbackState;
  children: React.ReactElement;
}): React.ReactElement => {
  return (
    <SubmitCallbackContext.Provider value={value}>
      {children}
    </SubmitCallbackContext.Provider>
  );
};
