import React, { ReactElement } from 'react';
import Svg, { Circle, G } from 'react-native-svg';

import { IconStyles } from './types';

export const StatusIndicator = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 11 11" style={style}>
      <G
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G
          id="Oval"
          transform="translate(-130.000000, -47.000000)"
          fill={style?.color || 'currentColor'}
        >
          <Circle cx="135.5" cy="52.5" r="5.5"></Circle>
        </G>
      </G>
    </Svg>
  );
};
