import React, { ReactElement, useState } from 'react';
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { CSSProperties } from 'styled-components';

import { PressHandler } from 'components/Button/types';
import { Control, ControlProps } from 'components/layout/Content/Control';
import { TouchableOpacity } from 'components/primitives/cross-platform';
import { pass } from 'cross-platform/utils/roleProps';

const flatten = StyleSheet.flatten;

export type ControlButtonProps = {
  onPress: PressHandler;
  onPressIn?: (e: GestureResponderEvent) => void;
  onPressOut?: (e: GestureResponderEvent) => void;
  style?: CSSProperties;
} & ControlProps;

const ControlButton = (props: ControlButtonProps): ReactElement => {
  const { onPress, isDisabled, style, testID, ...rest } = props;
  const [isPressing, setIsPressing] = useState(false);
  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress as PressHandler}
      onPressIn={(e: GestureResponderEvent): void => {
        setIsPressing(true);
        props?.onPressIn?.(e);
      }}
      onPressOut={(e: GestureResponderEvent): void => {
        setIsPressing(false);
        props?.onPressOut?.(e);
      }}
      disabled={isDisabled}
      activeOpacity={1}
    >
      <Control
        isPressing={isPressing}
        isDisabled={isDisabled}
        style={flatten(style) as StyleProp<ViewStyle>}
        {...rest}
        {...pass(rest, 'Control')}
      />
    </TouchableOpacity>
  );
};

export { ControlButton };
