import { connect } from '@bighealth/api/Fitbit/v1';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';

export const queryClientFitBitConnect = async (args: {
  product_id: number;
  auth_code: string;
  redirect_uri: string;
}): Promise<connect.Response> => {
  return await getQueryClient().fetchQuery(
    connect.queryKey,
    () => connect(args),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
};
