import React from 'react';

import { openURL } from 'lib/navigation/openURL';

import { LinkText, TouchableOpacity } from '../styled.web';

type LinkProps = {
  href: string;
  children: React.ReactNode;
};

/**
 * Used by Markdown component
 *
 * IDEA use generic util (see other linkRenderers)
 *
 * @param {LinkProps} props
 */
const linkRenderer: React.FC<LinkProps> = (props: LinkProps) => {
  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return <LinkText {...child.props} />;
    }
    return child;
  });
  return (
    <TouchableOpacity onPress={() => openURL(props.href)}>
      {childrenWithProps}
    </TouchableOpacity>
  );
};

export { linkRenderer };
