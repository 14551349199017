import React, { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { DEVELOPER_COMPONENTS_TEST_SCENESET_FROM_API } from 'developer/data';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

import { SleepDiaryFormMocks } from '../__mocks__/sleepDiaryFormMocks';
import { SleepDiaryForm } from '../index';

type SleepDiaryProps = ComponentProps<typeof SleepDiaryForm>;
const getUrl = (props: SleepDiaryProps): number | undefined =>
  DEVELOPER_COMPONENTS_TEST_SCENESET_FROM_API.childNodes.findIndex(scene => {
    const devDataProps = scene?.childNodes?.[0]?.childNodes?.[0];
    return (
      devDataProps?.component === 'SleepDiaryForm' &&
      devDataProps?.diary_date === props.diary_date &&
      devDataProps?.form_type === props.form_type
    );
  });

type Props = { name: string; mockProps: SleepDiaryProps };
const Story = ({ name, mockProps }: Props) => {
  const { productReference } = useSafeParams();
  const sceneSet = useFakeStorybookSceneSet();
  if (!sceneSet) {
    return null;
  }
  return (
    <>
      <Link to={`/${productReference}/888888/1/${getUrl(mockProps)}/`}>
        {`See in test data for ${name} (must be signed in)`}
      </Link>
      <pre>
        {JSON.stringify(
          {
            component: 'SleepDiaryForm',
            ...mockProps,
          },
          null,
          4
        )}
      </pre>
      <SleepDiaryForm {...mockProps} />
    </>
  );
};

Object.entries(SleepDiaryFormMocks.v1).forEach(([name, mockProps]) => {
  addStory(`_SC/SleepDiaryForm/v1/${name}`, () => {
    return (
      <EphemeralStateProvider>
        <CrossPlatformThemeProvider
          theme={getThemeForProduct(ProductReferences.SLEEPIO)}
        >
          <Story name={name} mockProps={mockProps} />
        </CrossPlatformThemeProvider>
      </EphemeralStateProvider>
    );
  });
});

Object.entries(SleepDiaryFormMocks.v2).forEach(([name, mockProps]) => {
  addStory(`_SC/SleepDiaryForm/v2/${name}`, () => {
    return (
      <EphemeralStateProvider>
        <CrossPlatformThemeProvider
          theme={getThemeForProduct(ProductReferences.SLEEPIO)}
        >
          <Story name={name} mockProps={mockProps} />
        </CrossPlatformThemeProvider>
      </EphemeralStateProvider>
    );
  });
});
