import React, { ReactElement, ReactNode, useContext } from 'react';
import { Dimensions } from 'react-native';

import { ProductReferences } from 'common/constants/enums';
import { useSafeParams } from 'components/Routes/useSafeParams';

const initialWindowSize = Dimensions.get('window');

interface MediaContextType {
  mediaWidth: number;
  mediaHeight: number;
}

const defaultContext: MediaContextType = {
  mediaWidth: initialWindowSize.width,
  mediaHeight: initialWindowSize.height,
};

export const MediaResponsiveLayoutContext = React.createContext(defaultContext);

export const useMediaResponsiveLayoutContext = (): MediaContextType =>
  useContext(MediaResponsiveLayoutContext);

export const MediaResponsiveLayoutProvider = ({
  children,
  height,
  width,
}: {
  children?: ReactNode;
  height: number;
  width: number;
}): ReactElement => {
  // TODO Remove all code below as part of DP-2325
  const { productReference } = useSafeParams();

  const deviceWindowSize = Dimensions.get('window');

  const usedHeight =
    productReference === ProductReferences.DAYLIGHT &&
    deviceWindowSize.height <= height
      ? deviceWindowSize.height
      : height;
  // End of remove code block

  return (
    <MediaResponsiveLayoutContext.Provider
      value={{
        mediaHeight: usedHeight,
        mediaWidth: width,
      }}
    >
      {children}
    </MediaResponsiveLayoutContext.Provider>
  );
};
