import { EdgeInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { DOMAIN } from './constants';
import { HeadingTextCommon, WidgetWrapperCommon } from './styled';

export const WidgetWrapper = styled(WidgetWrapperCommon)<{
  safeArea: EdgeInsets;
  isLandscape: boolean;
}>`
  max-width: 726px;
  padding: 16px 24px 16px 24px;
  margin-top: ${props => props.safeArea.top + 46}px;
`;
WidgetWrapper.displayName = `${DOMAIN}.WidgetWrapper`;

export const HeadingText = styled(HeadingTextCommon)`
  font-size: 20px;
`;
HeadingText.displayName = `${DOMAIN}.HeadingText`;
