import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const logout = async (
  requestConfig?: AxiosRequestConfig
): Promise<logout.Response> => {
  const request: logout.Request = {
    service_name: 'UserAccountAuthentication',
    service_version: '1',
    service_method: 'logout',
    args: {},
  };

  return await api(request, requestConfig);
};

logout.queryKey = 'UserAccountAuthentication/logout';

export declare namespace logout {
  export interface Request extends APIRequestBody {
    service_name: 'UserAccountAuthentication';
    service_version: '1';
    service_method: 'logout';
    args: {};
  }

  export type Result = {
    idp_logout_uri?: string;
  };

  export type Response = APIResponse<Result>;
}
