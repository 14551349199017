import { DaylightProgressScreenPayload } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

/**
 *
 * @example
 * User turns reminders off:
 * ```
 * {
 *    "settings": {"daily_reminder_time": null}
 * }
 * ```
 *
 * @example
 * User turns reminders on or changes reminder time:
 * ```
 * {
 *     "settings": {"daily_reminder_time": {"$time": "17:00"}},
 *     "activated_utc": {"$datetime": "2022-11-30 16:19:10.517977"}
 * }
 * ```
 *
 * @example
 * User is prompted to activate reminders:
 * ```
 * {
 *     "settings": {"daily_reminder_prompted": true}
 * }
 * ```
 */
export const update_settings = async (
  args: update_settings.Args,
  requestConfig?: AxiosRequestConfig
): Promise<update_settings.Response> => {
  const request: update_settings.Request = {
    service_name: 'DaylightUserDailyReminder',
    service_version: '1',
    service_method: 'update_settings',
    args,
  };

  return await api(request, requestConfig);
};

update_settings.queryKey = 'DaylightUserDailyReminder/update_settings';

type UserTurnedRemindersOffRequestPayload = {
  settings: {
    daily_reminder_time: null;
  };
};

type UserTurnedRemindersOnOrChangesReminderTimeRequestPayload = {
  settings: {
    daily_reminder_time: { $time: string };
  };
  activated_utc: {
    $datetime: string; // datestring '2022-11-30 16:19:10.517977';
  };
};

type UserWasOsPromptedToActivateRemindersRequestPayload = {
  settings: {
    daily_reminder_prompted: true;
  };
};

export declare namespace update_settings {
  export type Args =
    | UserTurnedRemindersOffRequestPayload
    | UserTurnedRemindersOnOrChangesReminderTimeRequestPayload
    | UserWasOsPromptedToActivateRemindersRequestPayload;

  export interface Request extends APIRequestBody {
    service_name: 'DaylightUserDailyReminder';
    service_version: '1';
    service_method: 'update_settings';
    args: Args;
  }

  export type Result = DaylightProgressScreenPayload;

  export type Response = APIResponse<Result>;
}
