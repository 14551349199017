import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLatestQuestionId as setQuestionId } from 'state/question-response/actions';
import { getLatestQuestionId } from 'state/question-response/selectors';

export type UseLatestQuestion = {
  latestQuestionId: number | undefined;
  setLatestQuestionId: (id: number | undefined) => void;
};

export const useLatestQuestion = (): UseLatestQuestion => {
  const dispatch = useDispatch();
  const latestQuestionId = useSelector(getLatestQuestionId);

  const setLatestQuestionId = useCallback(
    (id: number | undefined): void => {
      dispatch(setQuestionId(id));
    },
    [dispatch]
  );
  return { setLatestQuestionId, latestQuestionId };
};
