import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { IconStyles } from './types';

export const CrossRounded = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" style={style}>
      <G stroke="none" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.89067 7.83973L0.403663 2.35272C-0.134554 1.8145 -0.134554 0.941881 0.403663 0.403663C0.941881 -0.134554 1.8145 -0.134554 2.35272 0.403663L7.83973 5.89067L13.3267 0.403663C13.865 -0.134554 14.7376 -0.134554 15.2758 0.403663C15.814 0.941881 15.814 1.8145 15.2758 2.35272L9.78879 7.83973L15.2758 13.3267C15.814 13.865 15.814 14.7376 15.2758 15.2758C14.7376 15.814 13.865 15.814 13.3267 15.2758L7.83973 9.78879L2.35272 15.2758C1.8145 15.814 0.941881 15.814 0.403663 15.2758C-0.134554 14.7376 -0.134554 13.865 0.403663 13.3267L5.89067 7.83973Z"
          fill={style?.color || 'currentColor'}
        />
      </G>
    </Svg>
  );
};
