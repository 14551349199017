import { DropdownItem } from 'components/generic-question/Dropdown/types';

export enum QuestionPathActions {
  UPDATE_PROPERTY_VALUE = 'question-path/UPDATE_PROPERTY_VALUE',
}

export enum QuestionPathProperties {
  selectionQueue = 'selectionQueue',
}

export type UpdateSelectedQueueProperty = {
  property: QuestionPathProperties.selectionQueue;
  value: DropdownItem[];
};

export type UpdateProperty = UpdateSelectedQueueProperty;

export type QuestionPathAction = {
  type: QuestionPathActions.UPDATE_PROPERTY_VALUE;
  payload: {
    questionPath: string;
    update: UpdateProperty;
  };
};

export const updatePropertyInQuestionPath = (
  questionPath: string,
  update: UpdateProperty
): QuestionPathAction => ({
  type: QuestionPathActions.UPDATE_PROPERTY_VALUE,
  payload: { questionPath, update },
});
