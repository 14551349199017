import { ViewNodeApi } from 'domain/carousel/utils/hooks/useViewApi';

import * as React from 'react';
import { View } from 'react-native';

type Callback = (node: View) => void;

const useGetNodeFromApi = (api: ViewNodeApi): Callback => {
  return React.useCallback(
    (node: View) => {
      if (node === null || node === api.node) {
        return;
      }
      api.setNode(node);
    },
    [api]
  );
};

export { useGetNodeFromApi };
