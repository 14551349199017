import { useIsFetching } from 'react-query';

import { create_responses_for_questions } from '@bighealth/api/Response/v2';

import { checkMetaCommon } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkMetaCommon';
import { checkThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { isFetchingWithThrobberPredicateFactory } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/isFetchingWithThrobberPredicateFactory';

import { checkMetaDaylight } from '../routes/utils/checkMetaDaylight';

const useIsFetchingCounter = (): {
  countIsFetchingWithThrobber: number;
  countIsFetchingWithoutSpinningThrobber: number;
} => {
  return {
    // Determines whether or not to actually show Daylight throbber
    countIsFetchingWithThrobber: useIsFetching({
      predicate: isFetchingWithThrobberPredicateFactory([
        /**
         * This is an **Ordered-list** of _checker functions_
         * * These _checker function_ are executed in order by the predicate
         * * If a _checker function_ returns `undefined`, the next function called
         * * If a _checker function_ returns `true` or `false`, this is the predicate result
         * * If all _checker functions_ have returned `undefined`, a default is returned
         */
        checkThrobberBehavior,
        checkMetaCommon,
        checkMetaDaylight,
      ]),
    }),
    // Fetch queries that do not want spinning throbber but still want an overlay
    countIsFetchingWithoutSpinningThrobber: useIsFetching({
      queryKey: create_responses_for_questions.queryKey,
    }),
  };
};

export { useIsFetchingCounter };
