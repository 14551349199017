import { setHours, setMinutes } from 'date-fns';

export type DateOptions = {
  hours?: number;
  minutes?: number;
};

export const setTime = (date: Date, options: DateOptions): Date => {
  let newDate = new Date(date.getTime());
  const { hours, minutes } = options;
  if (typeof hours === 'number') {
    newDate = setHours(newDate, hours);
  }
  if (typeof minutes === 'number') {
    newDate = setMinutes(newDate, minutes);
  }
  return newDate;
};
