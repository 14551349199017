import { Scene } from '@bighealth/types';

const setSleepWindowScene = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      alignment: {
        horizontal: 'middle',
      },
      childNodes: [
        {
          component: 'SetSleepWindowForm',
          title: {
            text: 'Adjust your 6hr 15 min sleep window until you are happy.',
            color: '#282828',
          },
          fields: {
            from_time: {
              label: {
                text: 'Go to bed after:',
                color: '#666666',
              },
              input: {
                initialValue: '23:00:00',
                color: '#1a80a2',
              },
            },
            to_time: {
              label: {
                text: 'Get out of bed before:',
                color: '#666666',
              },
              input: {
                initialValue: '5:15:00',
                color: '#1a80a2',
              },
            },
          },
          submit_button: {
            text: 'Save and Continue',
            action: {
              type: 'sleep-window/SUBMIT',
              payload: {
                service_name: 'SleepWindow',
                service_version: '1',
                service_method: 'create_with_times',
                args: {
                  // fe to populate
                },
              },
            },
          },
          on_submit_success: {
            type: 'submit',
          },
        },
      ],
    },
  ],
});

export { setSleepWindowScene };
