import { LineChartData, LineChartProps } from './types';

const getMax = (array: LineChartData | undefined, index: 0 | 1) => {
  if (Array.isArray(array)) {
    return Math.max(...array.map(entry => entry[index] || 0));
  }
  return 0;
};
const getMin = (array: LineChartData | undefined, index: 0 | 1) => {
  if (Array.isArray(array)) {
    return Math.min(...array.map(entry => entry[index] || 0));
  }
  return 0;
};

/**
 * Generates the various values needed to render the line chart
 */

export const chartUtils = ({
  // minY:, @TODO Add this when we need it
  baseHeight,
  baseWidth,
  data,
  horizontalPadding,
  maxX: maxSetX,
  maxY: maxSetY,
  minX: minSetX,
}: LineChartProps): {
  flipAndScaleY: (y: number) => number;
  linePoints: string;
  maxX: number;
  maxY: number;
  minX: number;
  scaleX: (x: number) => number;
} => {
  const minX = minSetX || getMin(data, 0);
  const maxX = maxSetX || getMax(data, 0);
  const maxY = maxSetY || getMax(data, 1);

  const scaleX = (x: number) =>
    (x * (baseWidth - 2 * horizontalPadding)) / (data?.length || 5) +
    horizontalPadding;

  const flipAndScaleY = (y: number) => ((maxY - y) / maxY) * baseHeight;

  const linePoints =
    data
      ?.map(([x, y], index) => {
        if (
          typeof x === 'undefined' ||
          typeof y === 'undefined' ||
          y === null
        ) {
          return '';
        }
        return `${scaleX(index)}, ${flipAndScaleY(y)}`;
      })
      .join(' ')
      .trim() || '';
  return {
    minX,
    maxX,
    maxY,
    scaleX,
    flipAndScaleY,
    linePoints,
  };
};
