import React from 'react';

import { TextTypes } from 'common/constants/enums';
import { addStory } from 'lib/story-books';

import { HelperText } from '.';

addStory('text-error', () => (
  <>
    <HelperText presentationType={TextTypes.HINT}>HINT</HelperText>
    <HelperText presentationType={TextTypes.WARNING}>WARNING</HelperText>
    <HelperText presentationType={TextTypes.ERROR}>ERROR</HelperText>
  </>
));
