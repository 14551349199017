import React, { ReactElement, useContext } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { roles } from 'cross-platform/utils/roleProps';

import { DayTracker } from './DayTracker';
import { getDayOfWeekTheme } from './DayTracker.styled';
import {
  DayTrackerWrapper,
  WeekTrackerTitleText,
  WeekTrackerWrapper,
} from './WeekTracker.styled';

const flatten = StyleSheet.flatten;

export const testIds = {
  WeekTracker: 'WeekTracker',
  DayTrackerWrapper: 'DayTrackerWrapper',
};

// Default values are set for testing purposes and something to fall back on
export const WeekTracker = ({
  weekNumber = 1,
  weekStatus = [
    'not_completed',
    'not_completed',
    'completed',
    'today_not_completed',
    'today_completed',
    'future_day',
    'future_day',
  ],
  style,
}: {
  weekNumber: number;
  weekStatus: string[];
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const weekDays = ['Su', 'M', 'T', 'W', 'Th', 'F', 'Sa'];
  const title = `Week ${weekNumber} • Daily Practice`;
  const theme = useContext(ThemeContext);

  return (
    <WeekTrackerWrapper {...roles(testIds.WeekTracker)} style={flatten(style)}>
      <WeekTrackerTitleText>{title}</WeekTrackerTitleText>

      <DayTrackerWrapper {...roles(testIds.DayTrackerWrapper)}>
        {weekStatus.map((dayOfWeekStatus, index) => (
          <DayTracker
            key={index}
            dayOfWeekStatus={dayOfWeekStatus}
            dayOfWeekTheme={getDayOfWeekTheme({ dayOfWeekStatus, theme })}
            dayOfWeek={weekDays[index]}
          />
        ))}
      </DayTrackerWrapper>
    </WeekTrackerWrapper>
  );
};
