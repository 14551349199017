import React, { ReactElement } from 'react';

import { ScheduleContentListProps } from '@bighealth/types';
import { Schedule as ScheduleProps } from '@bighealth/types/src/scene-components/client';

import {
  Content,
  ContentSubtitle,
  ContentTitle,
  ContentTitleBlock,
  SkeletonOuterContainerAnimated,
} from 'components/Screens/ContentScreens';
import { isNonEmptyPageData } from 'components/Screens/ContentScreens/components/helpers';
import { ScheduleContentEntry } from 'components/Screens/ContentScreens/ScheduleScreen';
import { useQuerySchedule } from 'lib/api/reactQueryHelpers';

export const ScheduleComponent = ({
  show_only_section: showOnlySection,
}: ScheduleProps): ReactElement | null => {
  const { isLoading, data } = useQuerySchedule();
  if (isLoading) {
    return <SkeletonOuterContainerAnimated />;
  }
  if (typeof data === 'undefined') {
    return null;
  }
  if (!isNonEmptyPageData(data?.result)) {
    // This should never happen as user shouldn't be able to get to this part of the content but
    // we'll make it type safe anyway.
    return null;
  }
  if (showOnlySection && showOnlySection !== 'all') {
    const entry = data?.result.find(({ slug }) => slug === showOnlySection);
    if (entry) {
      return (
        <Content>
          <ContentTitleBlock>
            <ContentTitle>{entry?.title}</ContentTitle>
            <ContentSubtitle>{entry?.subtitle}</ContentSubtitle>
          </ContentTitleBlock>
          {(entry?.content as ScheduleContentListProps[]).map(
            (props, index) => (
              <ScheduleContentEntry {...props} key={index} />
            )
          )}
        </Content>
      );
    }
  }
  return (
    <>
      {data?.result.map((entry, index) => (
        <Content key={index}>
          <ContentTitleBlock>
            <ContentTitle>{entry?.title}</ContentTitle>
            <ContentSubtitle>{entry?.subtitle}</ContentSubtitle>
          </ContentTitleBlock>
          {(entry?.content as ScheduleContentListProps[]).map(
            (props, index) => (
              <ScheduleContentEntry {...props} key={index} />
            )
          )}
        </Content>
      ))}
    </>
  );
};
