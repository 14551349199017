export type PseudoTreeStructureProps = {
  isFirstChild: boolean;
  isLastChild: boolean;
};

/**
 * Mimic css "Tree structure" pseudo-classes
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-classes#tree-structural_pseudo-classes}
 *
 * IDEA more support: e.g. isEven isOdd
 *
 */
const getPseudoTreeStructureProps = (options: {
  index: number;
  length: number;
}): PseudoTreeStructureProps => ({
  isFirstChild: options.index === 0,
  isLastChild: options.index === options.length - 1,
});

export { getPseudoTreeStructureProps };
