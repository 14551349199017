/**
 * This should contain all the BigHealth and product-specific types
 *
 * If there are stable / lower-level types (that don't rely on native packages),
 *  they should live in packages-shared/types/src/localNotifications
 */

import {
  AndroidChannel,
  IOSNotificationCategory,
  Notification,
  NotificationAndroid,
} from '@notifee/react-native';

export type NotificationGroupConfigId = string;

export type NotificationContent = Omit<Notification, 'data'> & {
  data: NotificationDataObj;
};

export type NotificationCreationPayload = {
  notificationDateTime: Date;
  notificationContent: NotificationContent;
  notificationId?: string;
};

export type IosLocalNotificationConfig = {
  [NotificationGroupConfigId: string]: {
    channelConfig: IOSNotificationCategory;
  };
};

/**
 * Product specific configuration for ANDROID platform
 *   for channels and notifications
 *
 * @param channelConfig {NotificationAndroid}
 *    - Used in calls to notifee.createChannel
 * @param notificationConfig {NotificationAndroid}
 *    - Used in calls to notifee.displayNotification under `android` parameter
 */
export type AndroidLocalNotificationConfig = {
  [NotificationGroupConfigId: string]: {
    channelConfig: AndroidChannel;
    notificationConfig: NotificationAndroid;
  };
};

export enum NotificationFrequency {
  DAILY = 'DAILY',
  FOR_TESTING_ONLY__EVERY_10_SECONDS = 'FOR_TESTING_ONLY__EVERY_10_SECONDS',
  ONE_TIME_REMINDER = 'ONE_TIME_REMINDER',
  ONE_TIME_REMINDER_AFTER_ONE_MINUTE = 'ONE_TIME_REMINDER_AFTER_ONE_MINUTE',
}

/**
 * index will be provided based on the index of an item in an array (0-based)
 */
export type FnForCreatingIndividualIds = (index: number) => string;

export type BhNotificationGroupMetaConfig = {
  count: number; // max is 57 per app, enforced by OS
  fnForCreatingIndividualIds: FnForCreatingIndividualIds;
  frequency: NotificationFrequency;
  id: NotificationGroupConfigId;
  programId?: number;
  defaults: {
    timeOfDayHours24: number;
    timeOfDayMinutes: number;
    date?: Date;
  };
};

/**
 * Ideally, we would update the notifee typing of TriggerNotification to include these type requirements
 *   - that would ensure that our typing works for data returned from notifee
 */
export type NotificationDataObj = {
  notificationGroupConfigFrequency: NotificationFrequency;
  notificationGroupConfigId: NotificationGroupConfigId;
};

export type BHNotificationToShowConfig = Omit<Notification, 'data'> & {
  data: NotificationDataObj;
  bhNotificationGroupConfig: BhNotificationGroupMetaConfig;
};

/**
 * - This is set dynamically VIA endpoint call
 *   - A null will then represent "unknown" (default) and
 *     boolean will represent a known on/off state
 */
export type NotificationFeatureFlag = boolean | null;

export type LocalNotificationConfig = {
  iosConfig: IosLocalNotificationConfig;
  androidConfig: AndroidLocalNotificationConfig;
  notificationsToShow: BHNotificationToShowConfig[];
};
