import React from 'react';

import { roles } from 'cross-platform/utils/roleProps';

import { Title } from './styled/styled';
import { Subtitle, TitleSubtitleView } from './styled/styled';

type Props = { title: string; subtitle?: string };
const TitleSubtileMFA = ({ title, subtitle }: Props): React.ReactElement => {
  return (
    <TitleSubtitleView role="region" aria-live="polite">
      <Title role="heading" {...roles('MFAModalTitle')}>
        {title}
      </Title>
      <Subtitle role="heading" aria-level="2" {...roles('MFAModalSubTitle')}>
        {subtitle}
      </Subtitle>
    </TitleSubtitleView>
  );
};

export default TitleSubtileMFA;
