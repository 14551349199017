import { createTimeDate } from 'lib/createTimeDate';
import { MINUTE } from 'lib/durations';

const moveToEpochZero = (date: Date): Date => {
  // First-ever date is generated from library
  // and so has not gone through InputAsArray.
  // TODO Do not rely on 1970
  // WHY We have to cast to 1970, then recalculated for any time difference caused
  //    (e.g. Extra hour between times in 1970 and now)
  //    We apply createTimeDate transformation, which will be inevitably
  //    applied (and assumed) in InputAsArray at within onDateChange
  //    before being fed back-in via props (uni-directional data flow)
  // HOW Unsure exactly, but something
  // AC: Suffice to say, the need to do this is very bad.
  const newDate = createTimeDate(date);
  const offset = newDate.getTimezoneOffset() - date.getTimezoneOffset();
  return new Date(newDate.getTime() - offset * MINUTE);
};

export { moveToEpochZero };
