import React, { ComponentProps, ReactElement } from 'react';

import { Markdown } from 'components/Markdown/Markdown';
import { Text } from 'components/sceneset-components/Text';

export type TextOrMarkdownProps = {
  text: string;
  component?: string;
} & ComponentProps<typeof Text> &
  Omit<ComponentProps<typeof Markdown>, 'children'>;

export const TextOrMarkdown = (props: TextOrMarkdownProps): ReactElement => {
  if (props.component === 'Markdown') {
    return <Markdown {...props}>{props.text}</Markdown>;
  } else {
    return <Text {...props} />;
  }
};
