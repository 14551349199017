import { transition_to_next_scene_set } from '@bighealth/api/SceneSetGraph/v1';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { getWhichDeveloperSceneSet } from 'developer/helpers';

export const queryClientTransitionSceneSet = async (
  args: transition_to_next_scene_set.Args
): Promise<transition_to_next_scene_set.Response> => {
  const developerSceneSet = getWhichDeveloperSceneSet(
    args.current_graph_id,
    args.current_scene_set_id
  );
  let result;
  if (developerSceneSet) {
    result = {
      status_code: 200,
      result: {
        id: args.current_scene_set_id as number,
        reference: `${args.current_scene_set_id}`,
        scene_set_json: developerSceneSet,
      },
    };
  } else {
    result = await getQueryClient().fetchQuery(
      transition_to_next_scene_set.queryKey,
      () => transition_to_next_scene_set(args),
      {
        cacheTime: 0, // we never want to cache this query so make it expire immediately
        // (default) staleTime: 0
      }
    );
  }
  await getQueryClient().setQueryData(['SceneSet', result.result?.id], result);
  return result;
};
