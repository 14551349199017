import { ThemeProps } from 'styled-components/native';

import { ProductReferences } from 'common/constants/enums';
import { QuizAttribute } from 'components/constants';
import { getThemeForProduct, Theme } from 'config/getThemeForProducts';
import { ControlAttrs } from 'config/getThemeForProducts/utils/createTheme';

import { ButtonStates, InputStates, InputStatesStyles } from './types';

export const getStylesForButtonStates = (
  key: keyof InputStatesStyles,
  theme?: Theme
) => {
  return ({
    isSelected,
    isActive,
    isHovering,
    quiz,
    isInput,
    highlight,
  }: ButtonStates): string => {
    if (typeof theme === 'undefined') {
      theme = getThemeForProduct(ProductReferences.SLEEPIO);
    }

    const controls =
      isInput && typeof theme.controls.input !== 'undefined'
        ? theme.controls.input
        : theme.controls.selection;

    // Also used for "pressed" on native
    const dictionary: InputStatesStyles =
      highlight && isSelected
        ? controls.default[ControlAttrs.highlightAndIsSelected]
        : isSelected
        ? controls.default[ControlAttrs.isSelected]
        : highlight
        ? controls.default[ControlAttrs.highlight]
        : isHovering
        ? controls.default[ControlAttrs.isHovering]
        : quiz === QuizAttribute.CORRECT
        ? controls.quiz[QuizAttribute.CORRECT]
        : quiz === QuizAttribute.INCORRECT
        ? controls.quiz[QuizAttribute.INCORRECT]
        : isActive
        ? controls.default[ControlAttrs.isActive]
        : {
            backgroundColor: controls.backgroundColor,
            color: controls.color,
            borderColor: controls.borderColor,
            editColor: '#00506b',
            border: `1px solid ${controls.borderColor}`,
            labelColor: '#555555',
          };

    return dictionary[key] || '';
  };
};

export const getStylesForSelectHorizontalButtonStates = (
  key: keyof InputStatesStyles
) => {
  return ({
    isSelected,
    isActive,
    isHovering,
    isPressing,
    highlight,
    theme,
  }: ButtonStates & ThemeProps<Theme>): string => {
    if (typeof theme === 'undefined') {
      theme = getThemeForProduct(ProductReferences.SLEEPIO);
    }
    // NOTE: having two potential theme objects is too confusing
    const controls =
      theme.controls &&
      typeof theme.controls.horizontalSelection !== 'undefined'
        ? theme.controls.horizontalSelection
        : theme.controls.selection;
    // Also used for "pressed" on native
    // cc:modstyle#1
    const dictionary: InputStatesStyles =
      highlight && isSelected
        ? controls.default[ControlAttrs.highlightAndIsSelected]
        : highlight
        ? controls.default[ControlAttrs.highlight]
        : isSelected
        ? controls.default[ControlAttrs.isSelected]
        : isPressing
        ? controls.default[ControlAttrs.isPressing]
        : isHovering
        ? controls.default[ControlAttrs.isHovering]
        : isActive
        ? controls.default[ControlAttrs.isActive]
        : {
            backgroundColor: controls.backgroundColor,
            color: controls.color,
            border: `1px solid ${controls.borderColor}`,
          };
    return dictionary[key] || '';
  };
};

export const getStylesForSleepDiaryResponseButtonStates = (
  key: keyof InputStatesStyles
) => {
  return ({
    isHovering,
    isPressing,
    theme,
  }: ButtonStates & ThemeProps<Theme>): string => {
    if (typeof theme === 'undefined') {
      theme = getThemeForProduct(ProductReferences.SLEEPIO);
    }

    if (typeof theme.controls.sleepDiaryResponse === 'undefined') {
      throw Error('Theme styling for Sleep Diary Response not found');
    }
    const controls = theme.controls.sleepDiaryResponse;

    // Also used for "pressed" on native
    const dictionary: InputStatesStyles = isPressing
      ? controls.isPressing
      : isHovering
      ? controls.isHovering
      : controls.default;
    return dictionary[key] || '';
  };
};

export const getStylesForInputStates = (key: keyof InputStatesStyles) => {
  return ({ isActive, isHovering, highlight }: InputStates): string => {
    const highlightStyle = {
      backgroundColor: 'white',
      color: '#757575',
      border: '1px solid #ffba44',
      fontStyle: 'italic',
    };

    const isHoveringStyle = {
      backgroundColor: '#f2f2f2',
      color: '#757575',
      border: '1px solid #f2f2f2',
      fontStyle: 'normal',
    };
    // Also used for "focused"
    const isActiveStyle = {
      backgroundColor: '#f2f2f2',
      color: '#282828',
      border: '1px solid #f2f2f2',
      fontStyle: 'normal',
    };

    const defaultStyle = {
      backgroundColor: 'white',
      color: '#757575',
      border: '1px solid #d6d6d6',
      fontStyle: 'italic',
    };

    const dictionary: InputStatesStyles = {
      backgroundColor:
        //

        highlight
          ? highlightStyle.backgroundColor
          : isHovering
          ? isHoveringStyle.backgroundColor
          : isActive
          ? isActiveStyle.backgroundColor
          : defaultStyle.backgroundColor,
      color:
        //
        highlight
          ? highlightStyle.color
          : isHovering
          ? isHoveringStyle.color
          : isActive
          ? isActiveStyle.color
          : defaultStyle.color,
      fontStyle:
        //

        highlight
          ? highlightStyle.fontStyle
          : isHovering
          ? isHoveringStyle.fontStyle
          : isActive
          ? isActiveStyle.fontStyle
          : defaultStyle.fontStyle,
      border:
        //
        highlight
          ? highlightStyle.border
          : isHovering
          ? isHoveringStyle.border
          : isActive
          ? isActiveStyle.border
          : defaultStyle.border,
    };
    return dictionary[key] || '';
  };
};
