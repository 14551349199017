import getParamsFromPath from 'lib/player/getParamsFromPath';
import getPathForParams from 'lib/player/getPathForParams';

/**
 * Naively increments the path by one
 */
export const getNextPath = (path: string): string => {
  const {
    productReference,
    sceneSetGraphId,
    sceneSetId: currentSceneSetId,
    sceneId: currentSceneId = 0,
  } = getParamsFromPath(path);

  return getPathForParams({
    productReference,
    sceneSetGraphId,
    sceneSetId: currentSceneSetId,
    sceneId: currentSceneId + 1,
  });
};
