import { DaylightCheckinPayload } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const start_checkin = async (
  args: start_checkin.Args,
  requestConfig?: AxiosRequestConfig
): Promise<start_checkin.Response> => {
  const request: start_checkin.Request = {
    service_name: 'DaylightCheckin',
    service_version: '1',
    service_method: 'start_checkin',
    args,
  };

  return await api(request, requestConfig);
};

start_checkin.queryKey = 'DaylightCheckin/start_checkin';

export declare namespace start_checkin {
  export type Args = {
    product_id: number;
    program_id: number;
    scene_set_graph_id: number;
    scene_set_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'DaylightCheckin';
    service_version: '1';
    service_method: 'start_checkin';
    args: Args;
  }

  export type Result = DaylightCheckinPayload;

  export type Response = APIResponse<Result>;
}
