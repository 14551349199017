import React from 'react';
import { Button, View } from 'react-native';

import { roles } from 'cross-platform/utils/roleProps';
import { addStory } from 'lib/story-books';

import { ErrorModal } from './ErrorModal';

export const hackWrap = (instance: JSX.Element): JSX.Element => (
  <View
    {...roles('Wrapper')}
    style={{
      width: '100%',
      height: 350, // IDEA: just use flex? Lost too much time fiddling with css
    }}
  >
    <View
      {...roles('Inner')}
      style={{
        position: 'relative',
        height: '100%',
      }}
    >
      {/* QUESTION: Why does this button need to exist for absolute to work? */}
      {instance}
    </View>
  </View>
);

addStory('components/SceneSetView/components/ErrorModal/Presentational', () => {
  return hackWrap(
    <>
      <Button title="ok" onPress={console.log} />
      <ErrorModal
        {...roles('Error Modal')}
        message="message"
        error={Error('Starts with error')}
        heading="heading"
        onClose={() => undefined}
      />
    </>
  );
});
