import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const should_force_upgrade = async (
  args: should_force_upgrade.Args,
  requestConfig?: AxiosRequestConfig
): Promise<should_force_upgrade.Response> => {
  const request: should_force_upgrade.Request = {
    service_name: 'AppVersion',
    service_version: '1',
    service_method: 'should_force_upgrade',
    args,
  };

  return await api(request, requestConfig);
};

should_force_upgrade.queryKey = 'AppVersion/should_force_upgrade';

export declare namespace should_force_upgrade {
  export type Args = {
    product_id: number;
    platform: string;
    version: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'AppVersion';
    service_version: '1';
    service_method: 'should_force_upgrade';
    args: Args;
  }

  export type Result = boolean;

  export type Response = APIResponse<Result>;
}
