/* eslint-disable react/prop-types */ // AC: Using ForwardRef triggers this without good cause
import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { isMobileWeb } from 'config/isMobileWeb';
import { roles } from 'cross-platform/utils/roleProps';
import { PseudoTreeStructureProps } from 'lib/getNthChildProps';
import useActionHandler from 'lib/player/useActionHandler';
import { stringify } from 'lib/stringify';

import { CardListProps } from '../../index';

import { CardPresentation } from './components/CardPresentation';
import { Touchable } from './components/CardPresentation/styled';

export type CardProps = get_user_session_screen_data.ResultStandard['session_list'][number] & {
  style?: StyleProp<ViewStyle>;
} & PseudoTreeStructureProps &
  Pick<CardListProps, 'variant'> & { isLarge: boolean };

/**
 * Clicked to access a session
 *
 * @param {CardProps} param0
 */
const Card = React.forwardRef<View, CardProps>(
  (
    {
      heading,
      status,
      status_text,
      play_action,
      style,
      isLastChild,
      isFirstChild,
      title,
      description,
      duration,
      lock_text,
      session_thumb_image_asset,
      variant,
    },
    ref
  ): React.ReactElement => {
    const actionHandler = useActionHandler(
      play_action || undefined
    ) as React.ComponentProps<typeof Touchable>['onPress'];

    const scaleYFn = useTransformContentStyleVertical();
    const scaleYValue = scaleYFn(1);
    if (play_action?.type === 'jump_to_sceneset' && !play_action?.payload) {
      throw Error(
        `Expected play_action.payload to be defined if type is "jump_to_sceneset"`
      );
    }

    return (
      <CardPresentation
        isLarge={!isMobileWeb}
        isLastChild={isLastChild}
        isFirstChild={isFirstChild}
        {...roles('Card--' + heading.text)}
        style={Array.isArray(style) ? StyleSheet.flatten(style) : style}
        onPress={actionHandler}
        data-play_action={stringify({ status, play_action })}
        heading={heading}
        status={status}
        status_text={status_text}
        play_action={play_action}
        scaleValue={scaleYValue}
        title={title}
        description={description}
        duration={duration}
        ref={ref}
        lock_text={lock_text}
        session_thumb_image_asset={session_thumb_image_asset}
        variant={variant}
      />
    );
  }
);
Card.displayName = 'Card';

export { Card };
