import { ReactElement } from 'react';

import { NotificationFeatureFlag } from 'config/localNotifications';

export type WithNotificationScreenRedirectComp = (props: {
  children: ReactElement;
}) => ReactElement | null;

export enum NotificationRedirectStatus {
  ERROR = 'error',
  LOADING = 'loading',
  REDIRECT = 'redirect',
  NO_REDIRECT = 'no-redirect',
}

export enum NotificationRedirectType {
  DEFAULT = 'default',
  PRE_SLEEP_DIARY = 'pre-sleep-diary',
  POST_SLEEP_DIARY = 'post-sleep-diary',
}

export type NotificationRedirectResponse =
  | { status: NotificationRedirectStatus.ERROR }
  | { status: NotificationRedirectStatus.LOADING }
  | { status: NotificationRedirectStatus.NO_REDIRECT }
  | { status: NotificationRedirectStatus.REDIRECT; payload: string };

export type UseGetNotificationRedirectStatusFunc = (args: {
  canNativeOsPermsRequestBeCalled: boolean;
  hasDefaultNotificationModalPopUpBeenPrompted: NotificationFeatureFlag;
  hasPreSleepDiaryNotificationModalPopUpBeenPrompted: NotificationFeatureFlag;
  hasPostSleepDiaryNotificationModalPopUpBeenPrompted: NotificationFeatureFlag;
}) => NotificationRedirectResponse;
