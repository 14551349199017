import { SceneSetGraph } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const read_bulk = async (
  args: read_bulk.Args,
  requestConfig?: AxiosRequestConfig
): Promise<read_bulk.Response> => {
  const request: read_bulk.Request = {
    service_name: 'SceneSetGraph',
    service_version: '1',
    service_method: 'read_bulk',
    args: { entity_ids: args.entity_ids },
  };

  return await api(request, requestConfig);
};

read_bulk.queryKey = 'SceneSetGraph/read_bulk';

export declare namespace read_bulk {
  export type Args = { entity_ids: number[] };

  export interface Request extends APIRequestBody {
    service_name: 'SceneSetGraph';
    service_version: '1';
    service_method: 'read_bulk';
    args: Args;
  }

  export type Result = SceneSetGraph[];

  export type Response = APIResponse<Result>;
}
