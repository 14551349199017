import React, { useRef } from 'react';
import { Platform } from 'react-native';

import { StyleObject } from '@bighealth/types/src/scene-components/client';

import AccessibilityWrapper from 'common/constants/accessibilityWrapper';
import { roles } from 'cross-platform/utils/roleProps';
import { useSessionsStyles } from 'sleepio/components/screens/SessionsScreen/hooks/useSessionsStyles';

import { CardPresentationProps } from '../CardPresentation';
import {
  ContentDetails,
  ContentImage,
  DescriptionText,
  FirstCol,
  Grid,
  IconByStatus,
  IconOverlay,
  Image,
  ImageWrapper,
  LabelText,
  LabelView,
  LockIcon,
  LockText,
  Row,
  SecondCol,
  TitleText,
  Touchable,
  Wrapper,
} from '../CardPresentation/styled';

type SessionCardProps = Omit<
  CardPresentationProps,
  | 'heading'
  | 'status_text'
  | 'variant'
  | 'isFirstChild'
  | 'isLastChild'
  | 'isLarge'
>;

/*
 * If we make this component accessible in iOS, it would read the whole text without pausing which makes it harder to understand
 */
const isContentTitleAccessible = Platform.select<boolean>({
  ios: false,
  android: true,
});

const SessionCard = ({
  status,
  play_action,
  onPress,
  scaleValue,
  style,
  title,
  description,
  duration,
  session_thumb_image_asset,
  lock_text,
}: SessionCardProps): React.ReactElement => {
  const { cardStyles } = useSessionsStyles();
  const imageRef = useRef(null);
  const contentRef = useRef(null);

  const { contentPadding } = cardStyles;

  return (
    <AccessibilityWrapper fieldsRefs={[imageRef, contentRef]}>
      <Wrapper scaleValue={scaleValue} status={status} style={style}>
        <ImageWrapper
          ref={imageRef}
          {...roles('ImageContent')}
          style={cardStyles.imageWrapper}
        >
          <Image
            {...roles('Image')}
            src={session_thumb_image_asset.storage_url}
            assetSize={{
              width: session_thumb_image_asset.width,
              height: session_thumb_image_asset.height,
            }}
            alt={session_thumb_image_asset.alt_text}
          />
          {status === 'LOCKED' ? (
            <IconOverlay locked>
              <LockIcon />
              <LockText {...lock_text} {...roles('LockText')} />
            </IconOverlay>
          ) : null}
          <ContentImage {...roles('Content')}>
            <LabelView {...roles('LabelView')}>
              <LabelText
                style={cardStyles.contentStyles.labelTextStyles as StyleObject}
                scaleValue={scaleValue}
                {...duration}
              ></LabelText>
            </LabelView>
          </ContentImage>
        </ImageWrapper>
        <ContentDetails
          ref={contentRef}
          style={contentPadding}
          {...roles('Content')}
        >
          <Grid>
            <Row>
              <FirstCol
                importantForAccessibility="yes"
                accessible={isContentTitleAccessible}
              >
                <TitleText
                  style={cardStyles.contentStyles.titleStyles as StyleObject}
                  scaleValue={scaleValue}
                  {...title}
                  {...roles('CardTitle')}
                />
                <DescriptionText
                  style={
                    cardStyles.contentStyles.descriptionStyles as StyleObject
                  }
                  scaleValue={scaleValue}
                  {...description}
                  {...roles('CardDescription')}
                />
              </FirstCol>
              <SecondCol {...roles('icon-status-' + status)}>
                <Touchable
                  onPress={onPress}
                  disabled={!play_action?.type}
                  accessible={true}
                  accessibilityLabel={'Play Session' + title?.text}
                  accessibilityRole={'button'}
                >
                  <IconByStatus
                    status={status}
                    scaleValue={scaleValue}
                    iconSize={cardStyles.contentStyles.newIconSize}
                  />
                </Touchable>
              </SecondCol>
            </Row>
          </Grid>
        </ContentDetails>
      </Wrapper>
    </AccessibilityWrapper>
  );
};

export { SessionCard };
