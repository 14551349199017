import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { getWhichDeveloperSceneSet } from 'developer/helpers';
import { jumpToSceneSetResponseHandler } from 'lib/api/helpers/jumpToSceneSetResponseHandler';

import { FEG_210_stub_check } from '../utils';

/**
 * Fetches scene set data from react-query if available, else calls the jump_to_specific_scene_set endpoint
 * and set react-query with response data
 * @param {jump_to_specific_scene_set.Args} args jump_to_specific_scene_set endpoint arguments
 * @param {string} productReference Scene Set Response
 * @returns {Promise<jump_to_specific_scene_set.Response>} Jump To Scene Set Response
 */
export const queryClientJumpToSceneSet = async (
  args: jump_to_specific_scene_set.Args,
  productReference: string
): Promise<jump_to_specific_scene_set.Response> => {
  const developerSceneSet = getWhichDeveloperSceneSet(
    args.current_graph_id,
    args.destination_scene_set_id
  );
  let result;
  if (developerSceneSet) {
    result = {
      status_code: 200,
      result: {
        id: args.destination_scene_set_id as number,
        reference: `${args.destination_scene_set_id}`,
        scene_set_json: developerSceneSet,
      },
    };
  } else {
    result = await getQueryClient().fetchQuery(
      // "-2" here is an arbitrary fallback. We could have chosen any number - or none at all - but picking a value
      // like this makes it clear in the react-query logs that something strange is happening
      ['SceneSet', args.destination_scene_set_id || -2],
      async () =>
        jumpToSceneSetResponseHandler(
          args.current_graph_id,
          productReference,
          args?.destination_scene_set_id,
          await jump_to_specific_scene_set(FEG_210_stub_check(args))
        ),
      {
        cacheTime: 0,
      }
    );
  }
  await getQueryClient().setQueryData(['SceneSet', result.result?.id], result);
  return result;
};
