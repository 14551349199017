import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';

export const createRenderRequest = (
  request: SleepDiaryPayloads['get_diary_entry_form']['request'],
  value: number
): SleepDiaryPayloads['get_diary_entry_form']['request'] => {
  return {
    ...request,
    args: {
      ...request.args,
      initial_responses_diary: {
        sleep_quality: value,
      },
    },
  };
};
