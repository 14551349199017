import { DropdownItem } from 'components/generic-question/Dropdown';
import { throwIfUndefined } from 'lib/type-guarded';

/**
 * Utility that takes the "most recent" valid quiz responses and trims the array up to the max length.
 * Correct responses should be at the end of the array so that they are always the first responses kept
 * when we limit the selection length.
 * @param max The max number of items allows in the returned array
 * @param selectedItems The array of items to be limited by max
 */
export const limitSelectionsLength = (
  max: number,
  selectedItems: DropdownItem[]
): DropdownItem[] => {
  return [...selectedItems].slice(-max);
};

/**
 * Utility returns an array of valid quiz response selections
 * @param selections The array of selected items to which quiz assessment filters should be applied
 * @param quizCorrectResponses The quiz assessment's correct responses
 * @param quizIncorrectResponses The quiz assessment's incorrect responses
 */
export const getValidQuizResponseSelections = (
  selections: DropdownItem[],
  quizCorrectResponses: DropdownItem[],
  quizIncorrectResponses: DropdownItem[]
): DropdownItem[] => {
  // We need to force the selection to exclude incorrect responses and all correct responses should be
  // at the end of the array.
  const updatedSelection = selections.filter(
    selectedItem =>
      !quizIncorrectResponses.find(
        incorrectResponse => incorrectResponse.id === selectedItem.id
      ) &&
      !quizCorrectResponses.find(
        correctResponse => correctResponse.id === selectedItem.id
      )
  );

  // The selection with incorrect responses removed, and correct responses
  // at the end of the array
  return [...updatedSelection, ...quizCorrectResponses];
};

/**
 * Set the isSelected attribute to true for each item in the allItems array that is also in the selectedItems array.
 * @param allItems The full list of options for the given response
 * @param selectedItems The updated source of truth for which items within all should be marked as selected
 */
export const updateIsSelectedOnAllItems = (
  allItems: DropdownItem[],
  selectedItems: DropdownItem[]
): DropdownItem[] => {
  return allItems.map(item => {
    const isItemFoundInBoth = !!selectedItems.find(
      selectedItem => selectedItem.id === item.id
    );

    // The below is more explicit than using:
    // return { ...item, isSelected: isItemFoundInBoth }
    // Additionally, it leaves any items that have not been explicitly set as undefined instead of false
    if (isItemFoundInBoth && !item.isSelected) {
      return { ...item, isSelected: true };
    } else if (!isItemFoundInBoth && item.isSelected) {
      return { ...item, isSelected: false };
    }

    return item;
  });
};

/**
 * Properly adds/removes the toggledItem to/from the given oldSelectedItems and returns a new array
 * @param oldSelectedItems The selected items prior to the toggledItem being clicked/tapped
 * @param toggledItem The item that was just clicked/tapped
 * @param singleSelectIds The IDs of any response options that should act like radio buttons
 * @returns An updated selection with the toggledItem either added to or removed from the selection
 */
export const getDesiredSelectionForToggle = (
  oldSelectedItems: DropdownItem[],
  toggledItem: DropdownItem,
  singleSelectIds: string[]
): DropdownItem[] => {
  // If the toggledItem was previously selected, deselect it
  if (oldSelectedItems.find(el => el.id === toggledItem.id)) {
    return oldSelectedItems
      .filter(el => el.id !== toggledItem.id)
      .map(throwIfUndefined);
  }

  // The toggledItem was not previously selected, so add it to the selection.
  // If the toggledItem is the special single select case, then we only want that item to be selected.
  // Otherwise, the toggledItem is not a single select case and we instead we want to preserve
  // all other selected items except for the single select cases.
  return singleSelectIds.includes(`${toggledItem?.id ?? ''}`)
    ? [toggledItem]
    : [
        ...oldSelectedItems.filter(
          el => !singleSelectIds.includes(`${el?.id ?? ''}`)
        ),
        toggledItem,
      ];
};
