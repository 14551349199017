import styled from 'styled-components/native';

import { Text } from 'components/sceneset-components/Text';

import { DOMAIN } from './constants';

export type SectionHeadingTextVariantProps = { isMobileWeb?: boolean };

export type SectionHeadingTextProps = React.ComponentProps<typeof Text>;

export const SectionHeadingText = styled(Text)<SectionHeadingTextProps>`
  color: ${props => props.theme.color.white};
  font-weight: 500;
  font-size: 24px;
`;
SectionHeadingText.displayName = `${DOMAIN}.SectionHeadingText`;
