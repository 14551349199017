import { View } from 'react-native';
import styled from 'styled-components/native';

import Image from 'cross-platform/Image';

import { SettingsContentText } from '../../SettingsScreen.styled';

export const TextContainer = styled(View)<{
  paddingTop?: number;
}>`
  padding-top: ${props =>
    props.paddingTop ? `${props.paddingTop}px` : '20px'};
`;

export const OpenSettingsText = styled(TextContainer)`
  flex-direction: row;
`;

export const UnderlinedText = styled(SettingsContentText)<{
  marginTop?: number;
}>`
  text-decoration: underline;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '0px')};
  color: ${({ theme }) => theme.color.text.placeholder};
`;

export const MainText = styled(SettingsContentText)<{
  marginTop?: number;
  marginBottom?: number;
}>`
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '0px')};
  margin-top: ${props =>
    props.marginBottom ? `${props.marginBottom}px` : '0px'};
  color: ${({ theme }) => theme.color.text.placeholder};
`;

export const TimeSelectionContainer = styled(View)<{
  padding?: number;
  marginTop?: number;
}>`
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.disabledInputGrey};
  justify-content: space-between;
  border-radius: 1px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.inputOutlineGrey};
  border-radius: 8px;
  padding: ${props => (props.padding ? `${props.padding}px` : '20px')};
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '10px')};
`;

export const SelectedTimeText = styled(SettingsContentText)`
  text-align: center;
  width: 90%;
`;

export const DatePickerActionText = styled(SettingsContentText)`
  align-self: flex-end;
  color: ${({ theme }) => theme.modal.input.dateTimePicker.doneTextColor};
`;

export const DatePickerView = styled.View`
  justify-content: flex-end;
  flex: 1;
`;

export const MainView = styled.View`
  justify-content: space-between;
  flex: 1;
`;

export const TopTextSection = styled.View`
  margin-bottom: 24px;
`;

export const TimePickerContainer = styled.View`
  flex: 1;
`;

export const WaringIcon = styled(Image)<{
  width?: number;
  height?: number;
}>`
  width: ${props => (props.width ? `${props.width}px` : '20px')};
  height: ${props => (props.height ? `${props.height}px` : '20px')};
  align-self: center;
  margin-right: 4px;
`;
