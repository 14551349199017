import React, { ReactElement } from 'react';
import Svg, { G, Polygon } from 'react-native-svg';

import { IconStyles } from './types';

export const Cross = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" style={style}>
      <G id="Material/Icons-black/close" stroke="none" fill="none">
        <Polygon
          id="Shape"
          fill={style?.color || 'currentColor'}
          points="19 6.4 17.6 5 12 10.6 6.4 5 5 6.4 10.6 12 5 17.6 6.4 19 12 13.4 17.6 19 19 17.6 13.4 12"
        />
      </G>
    </Svg>
  );
};
