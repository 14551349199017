import { Scene } from '@bighealth/types/src/scene-component';

export interface FlexAlignment {
  justifyContent: 'center' | 'flex-start' | 'flex-end';
  textAlign: 'center' | 'left' | 'right';
  flexDirection: 'column';
}

/**
 * Convert the product facing values for our text alignment
 * into flex compatible properties and values.
 */
export const getTextAlignmentConfig = (
  alignment?: Scene.Presentational.Alignment
): FlexAlignment => {
  let justifyContent: FlexAlignment['justifyContent'];
  if (alignment?.vertical === 'middle') {
    justifyContent = 'center';
  } else if (alignment?.vertical === 'bottom') {
    justifyContent = 'flex-end';
  } else if (alignment?.vertical === 'top') {
    justifyContent = 'flex-start';
  } else {
    justifyContent = 'flex-start';
  }

  let textAlign: FlexAlignment['textAlign'];
  if (alignment?.horizontal === 'middle') {
    textAlign = 'center';
  } else if (alignment?.horizontal) {
    textAlign = alignment?.horizontal;
  } else {
    textAlign = 'left';
  }

  return {
    justifyContent,
    textAlign,
    flexDirection: 'column',
  };
};
