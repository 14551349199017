import { enrichError } from 'lib/reporter/utils/errors/enrichError';
import { isError } from 'lib/reporter/utils/errors/isError';
import { isNetworkRequestError } from 'lib/reporter/utils/errors/isNetworkRequestError';

import {
  getServiceDetails,
  hasServiceDetails,
  RequestConfig,
} from '../requestConfigUtils';

export const transformError = (
  error: Error,
  requestConfig: RequestConfig
): Error => {
  if (isError(error)) {
    if (isNetworkRequestError(error) && hasServiceDetails(requestConfig)) {
      enrichError(error, getServiceDetails(requestConfig));
    }
  }

  return error;
};
