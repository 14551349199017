import { Question, Scene } from '@bighealth/types';

const extendSleepWindowScene = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      alignment: {
        horizontal: 'middle',
      },
      childNodes: [
        {
          component: 'ExtendSleepWindowForm',
          title: {
            text: 'How would you like to adjust your {user_data} sleep window?',
          },
          questionProps: {
            id: 4,
            optional: false,
            question_pre_text: '',
            question_title: undefined,
            response_config: {
              min_selections_required: 1,
              max_selections_required: 1,
              default_response_ids: [],
              correct_response_ids: [],
              max_number_attempts: 1,
              validation_schema: {
                $schema: 'http://json-schema.org/draft-07/schema#',
                description: 'answer option values',
                properties: {
                  selections: {
                    maxItems: 1,
                    minItems: 1,
                    type: 'array',
                  },
                },
                title: 'answers',
                type: 'object',
              },
              response_options: [
                {
                  display_text: 'Go to bed earlier at 10:45 PM',
                  id: 1,
                  value: 'change_start',
                },
                {
                  display_text: 'Get out of bed later at 4:15 PM',
                  id: 2,
                  value: 'change_end',
                },
                {
                  display_text:
                    'No thanks. I will keep my 11:00 PM bedtime and 4:00 AM wake time',
                  id: 3,
                  value: 'no_change',
                },
              ],
            },
            response_type: 'text',
            semantic_id: 'sleep_restriction_extend_window_extension',
          } as Question,
          submit_button: {
            text: 'Save',
            action: {
              type: 'submit',
            },
          },
        },
      ],
    },
  ],
});

export { extendSleepWindowScene };
