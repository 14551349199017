/*

SEMANTIC / HIERARCHICAL ENUMS
------------------------------
- Based on "meaning"
- Used conditionally select values (e.g.theming)
- Used as values in attributes
- Strong indication that new component should be created
  e.g

  const Quote = () => (
    <Content content={ContentAttribute.QUOTE} />
  )

*/
export enum QuizAttribute {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
}

export enum SizeAttribute {
  LARGE = 'LARGE',
  NORMAL = 'NORMAL',
  SMALL = 'SMALL',
}

// For controls that display content of a certain type
// Can be layout, or text
export enum ContentAttribute {
  QUOTE = 'QUOTE',
  NORMAL = 'NORMAL',
}

// Speech bubble attribute for components/layout/<containers> (used by Theme)
//  @see {components/elements/Quote}
export enum SpeechTypes {
  DEFAULT = 'DEFAULT',
  COLUMN_INFO = 'COLUMN_INFO',
}

export enum StreamTraceEventNames {
  MEDIA_PLAYER_BUTTON_PRESS = 'button|clicked|media_player',
}

export enum StreamTraceAreas {
  THERAPY = 'therapy',
}

export enum StreamTraceSections {
  THERAPY = 'therapy',
}

export enum StreamTraceEventTypes {
  UI = 'ui',
}

export enum StreamTraceActions {
  CLICKED = 'clicked',
}

export enum StreamTraceResources {
  BUTTON = 'button',
}
