import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

export const ManufacturerIcon = (): ReactElement => {
  return (
    <Svg width="45" height="40" viewBox="0 0 65 60" fill="none">
      <G>
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.161621 59.8804V31.1952L8.24619 19.243L16.3307 31.1952L24.4153 19.243L32.4999 31.1952L40.5844 19.243L48.669 31.1952V0.119507H64.8381V59.8804H0.161621Z"
          fill="black"
          stroke="black"
          stroke-width="1.06667"
        />
      </G>
    </Svg>
  );
};
