import * as React from 'react';
import styled from 'styled-components/native';

import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { COPYRIGHT_TEXT } from 'lib/strings';

import { Text } from './components/Text';
import { useProductStreamVersion } from './hooks/useProductStreamVersion/useProductStreamVersion';

const DOMAIN = 'LoginFooter';

const Wrapper = styled.View`
  width: 100%;
  height: 90px;
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: column;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

const GeneralInfo = styled.View`
  flex-direction: column;
  align-items: center;
`;
GeneralInfo.displayName = `${DOMAIN}.GeneralInfo`;

type FooterProps = React.ComponentProps<typeof Wrapper> & RoleProps;

// This footer is used only on native because web is handled by
// onboarding. It has a breakpoint for landscape and portrait styling
export const LoginFooter = (props: FooterProps): React.ReactElement => {
  const productStreamVersion = useProductStreamVersion({
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });

  const fontSize = 18;
  return (
    <Wrapper {...props} {...roles.pass(props, DOMAIN)}>
      <GeneralInfo>
        <Text
          text={productStreamVersion}
          style={{ fontSize, textAlign: 'center' }}
        />
        <Text text={COPYRIGHT_TEXT} style={{ fontSize }} />
      </GeneralInfo>
    </Wrapper>
  );
};
