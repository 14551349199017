// // Inspired by SleepioUserGoal.get_user_goal_progress_data
import { ObjectWithAllKeys } from 'lib/utils/generics/ObjectWithAllKeys';

import {
  GoalIcons,
  GoalLabelTexts,
  GoalReferences,
  ImageProps,
  TextProps,
} from '.';

// IDEA Handle lowercase
// WHEN happy to complicate types
export const goalReferenceByLabelText: ObjectWithAllKeys<
  GoalLabelTexts,
  GoalReferences
> = {
  'Overall Goal': 'overall_goal',
  'Daytime Goal': 'daytime_goal',
  'Emotional Goal': 'emotional_goal',
};

export const defaultProps: {
  goal: TextProps;
  comment: TextProps;
  imageMap: ObjectWithAllKeys<GoalReferences, Omit<ImageProps, 'sourceProps'>>;
  commentTextMap: ObjectWithAllKeys<
    GoalIcons,
    ObjectWithAllKeys<GoalReferences, string>
  >;
} = {
  goal: {
    style: {
      fontSize: 19,
      fontWeight: '700',
      marginBottom: 40,
    },
  },
  comment: {
    style: {
      fontSize: 19,
      marginBottom: 40,
    },
  },
  imageMap: {
    overall_goal: {
      style: {
        height: 53,
        marginBottom: 15,
      },
    },
    daytime_goal: {
      style: {
        height: 40,
        marginBottom: 15,
      },
    },
    emotional_goal: {
      style: {
        height: 32,
        marginBottom: 15,
      },
    },
  },
  commentTextMap: {
    up: {
      overall_goal: 'Looks like you made some good improvement. Well done!',
      daytime_goal:
        'Your hard work is paying off! We’re seeing some positive change here.',
      emotional_goal: 'Keep going! You’ve made great progress.',
    },
    neutral: {
      overall_goal:
        'Not much change from last week. Keep pushing for progress and if you’ve gone as far as you can, nice job!',
      daytime_goal:
        'Looks about the same as last week. Try one new thing this week to see how it impacts your day.',
      emotional_goal:
        'Similar to last week! Are you where you want to be with this goal? If not, think about what you can change.',
    },
    down: {
      overall_goal:
        'We see there’s not much improvement. Bad weeks happen but don’t lose hope. You got this!',
      daytime_goal:
        'There’s opportunity to improve. Use your goal as motivation when trying new techniques.',
      emotional_goal:
        'There’s room for improvement here. Visit the More for You section for some extra support.',
    },
  },
};
