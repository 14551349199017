import styled from 'styled-components/native';

import { NavBarStyles } from 'components/Navigation/constants';
import { ButtonSizes, UniversalActionButton } from 'components/UniveralButtons';

import * as presentationStyledModule from '../../../styled';
import { HeadingTextProps } from '../../../styled';

import { DOMAIN } from './constants';
import { ButtonProps, ButtonRowProps } from './types';
import { scalerHorizontal, scalerHorizontalMobileWeb } from './utils';

export const HeadingText = styled(presentationStyledModule.HeadingText).attrs({
  // See getTextAlignmentConfig
  alignment: { horizontal: 'middle' },
})<HeadingTextProps>`
  min-width: 168px;
  margin-bottom: 35px;
  max-width: ${props => {
    return (props.isMobileWeb ? scalerHorizontalMobileWeb : scalerHorizontal)(
      600
    )(props);
  }}px;
  margin-top: ${props =>
    42 + (props.isCollapsed ? NavBarStyles.navBarHeightPx : 0)}px;
`;
HeadingText.displayName = `${DOMAIN}.HeadingText`;

export const ButtonRow = styled.View<ButtonRowProps>`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  ${props => {
    if (props.noButtons) {
      return props.isMobileWeb
        ? {
            marginBottom: 10,
          }
        : {
            marginBottom: 0,
          };
    } else {
      return props.isMobileWeb
        ? {
            marginBottom: 45,
          }
        : {
            marginBottom: 32,
          };
    }
  }}
`;
ButtonRow.displayName = `${DOMAIN}.ButtonRow`;

export const Button = styled(UniversalActionButton).attrs({
  size: ButtonSizes.Small,
})<ButtonProps>`
  font-size: 20px;
  line-height: 20px;
  ${props => {
    return props?.isMobileWeb
      ? {
          paddingLeft: 16,
          paddingRight: 16,
        }
      : {};
  }}
`;
Button.displayName = `${DOMAIN}.Button`;
