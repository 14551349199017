import { ResponseInputComponentType } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import { Value } from 'components/forms/types';

import { getValidatedDateValue } from './helpers/getValidatedDateValue';

/**
 *
 * IDEA validate: max_response: number; min_response: number; max_number_lines: number & min_characters_limit: number
 */
const getValidatedValue = (
  component: ResponseInputComponentType,
  value?: Value
): Value => {
  if (value === null || typeof value == 'undefined') {
    return null;
  }
  switch (component) {
    case 'DateTimePicker':
    case 'DatePicker':
    case 'TimePicker':
    case 'DropdownTimePicker':
    case 'SelectHorizontalTime':
      return getValidatedDateValue(value);
    case 'NumberInput':
    case 'DropdownDurationPicker':
    case 'TextInput':
    case 'Button':
    case 'VerticalSelect':
    case 'Dropdown':
    case 'HorizontalScale':
    case 'SelectHorizontal':
    case 'PhoneNumberInput':
      return value;
    default:
      ((comp: never): void => {
        throw Error(`Expected ${comp} to be handled component`);
      })(component);
  }
};

export { getValidatedValue };
