import React from 'react';

import { VisualState } from 'components/LocalNotifications/NotificationsView';

import { SettingsScreen } from '../..';

export type NotificationsSettingsScreenWrapperProps = {
  visualState: VisualState;
  children: React.ReactNode;
};

const NotificationsSettingsScreenWrapper = (
  props: NotificationsSettingsScreenWrapperProps
): React.ReactElement => {
  const settingsScreenTitle =
    props.visualState === VisualState.A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT
      ? ''
      : 'App Reminder';
  return (
    <SettingsScreen title={settingsScreenTitle}>
      {props.children}
    </SettingsScreen>
  );
};

export { NotificationsSettingsScreenWrapper };
