import { Scene } from '@bighealth/types';

const getSleepDiaryScene = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      alignment: {
        horizontal: 'middle',
      },
      childNodes: [
        {
          component: 'SleepDiaryForm',
          diary_date: '2020-06-30',
          form_type: 'create_single_day_entry',
        },
      ],
    },
  ],
});

export { getSleepDiaryScene };
