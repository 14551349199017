// NOTE: This file is only for 📖 Storybook & ✅ Tests
import React from 'react';

import { ErrorDetails } from 'lib/error';
import { ErrorDetailsApi } from 'lib/error/hooks/useErrorDetailsState';

import { IsThrowApi } from '../components/ThrowButton';

type Returns = {
  throwing: IsThrowApi; // isThrow, setIsThrow
  erroring: ErrorDetailsApi; // errorDetails, setErrorDetails
};

const useThrowingErroringApi = (): Returns => {
  const [isThrow, setIsThrow] = React.useState(false);
  const throwing = { isThrow, setIsThrow };
  const [errorDetails, setErrorDetails] = React.useState<
    ErrorDetails | undefined
  >();
  const erroring = { errorDetails, setErrorDetails };
  return { throwing, erroring };
};

export { useThrowingErroringApi };
