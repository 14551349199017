import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CaptionLanguageOption } from '@bighealth/types/src';

import { useMediaPlayerButtonEvent } from 'lib/api/analytics/buttonHooks';
import { usePlayPauseMedia } from 'lib/player/media/usePlayPauseMedia';
import { setCaptionState, toggleCaptions } from 'state/player/actions';
import { CaptionState } from 'state/player/reducer';
import { getCaptionLanguage, getCaptionState } from 'state/player/selectors';

import { ClosedCaptionButton } from './ClosedCaptionButton/ClosedCaptionButton';
import { ClosedCaptionSelect } from './ClosedCaptionSelect/ClosedCaptionSelect';

type Props = {
  languageOptions: CaptionLanguageOption[];
};

export const ClosedCaption = ({ languageOptions }: Props): ReactElement => {
  const dispatch = useDispatch();
  const captionState = useSelector(getCaptionState);
  const [showClosedCaptionSelect, setShowClosedCaptionSelect] = useState(false);
  const playPauseMedia = usePlayPauseMedia();
  const sendMediaPlayerButtonEvent = useMediaPlayerButtonEvent();
  const captionLanguage = useSelector(getCaptionLanguage);
  const hasCaptions = languageOptions.length > 0;
  const hasCaptionLanguage =
    languageOptions.findIndex(
      o => o.key.toLowerCase() === captionLanguage.toLowerCase()
    ) !== -1;

  const handleClosedCaptionButtonPress = () => {
    if (hasCaptions) {
      setShowClosedCaptionSelect(!showClosedCaptionSelect);
    } else {
      sendMediaPlayerButtonEvent({
        component: 'closed_captions',
        component_state: captionState === CaptionState.ON ? 'off' : 'on',
      });
      dispatch(toggleCaptions());
    }
  };

  const handleClosedCaptionSelect = () => {
    setShowClosedCaptionSelect(false);
  };

  useEffect(() => {
    if (showClosedCaptionSelect) {
      sendMediaPlayerButtonEvent({
        component: 'play_pause',
        component_state: 'pause',
      });
      playPauseMedia.pause();
    }
  }, [playPauseMedia, sendMediaPlayerButtonEvent, showClosedCaptionSelect]);

  useEffect(() => {
    if (
      hasCaptions &&
      !hasCaptionLanguage &&
      captionState === CaptionState.ON
    ) {
      sendMediaPlayerButtonEvent({
        component: 'closed_captions',
        component_state: 'off',
      });
      dispatch(setCaptionState(CaptionState.OFF));
    }
  }, [
    captionState,
    dispatch,
    hasCaptionLanguage,
    hasCaptions,
    sendMediaPlayerButtonEvent,
  ]);

  return (
    <>
      <ClosedCaptionButton onPress={handleClosedCaptionButtonPress} />
      {showClosedCaptionSelect && (
        <ClosedCaptionSelect
          languageOptions={languageOptions}
          onSelect={handleClosedCaptionSelect}
        />
      )}
    </>
  );
};
