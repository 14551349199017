import { Middleware } from 'redux';

import rootReducer from '../store/rootReducer';

import { detectUserSettingChangeType } from './utils/detectUserSettingChangeType';
import { sendNotificationStateDataToBackend } from './utils/sendNotificationStateDataToBackend';
import { NotificationsActionTypes } from './actions';

/**
 * Middleware: Listen for Redux action.type and perform async (non-blocking) operation
 *
 * - Does NOT modify/mutate state
 *
 * Listen for `NotificationsActionTypes.SET_NOTIFICATION_USER_SETTING_BY_ID`
 * - On changes, detect the type of change
 * - Based on type of change, send this data to the BE for tracking purposes
 *
 * Code template source:
 * @see https://redux.js.org/tutorials/fundamentals/part-6-async-logic#using-middleware-to-enable-async-logic
 */
export const asyncNotificationMiddleware: Middleware<
  unknown,
  ReturnType<typeof rootReducer>
> = storeAPI => next => action => {
  switch (action.type) {
    case NotificationsActionTypes.SET_NOTIFICATION_USER_SETTING_BY_ID: {
      const userNotifcationSetting = storeAPI
        .getState()
        .notifications.configurations.find(c => c.id === action.payload.id);
      if (userNotifcationSetting === undefined) {
        // if not found, jump to the return
        break;
      }
      // unable to await these responses unless we use redux-sagas
      sendNotificationStateDataToBackend(
        detectUserSettingChangeType(userNotifcationSetting, action.payload),
        action.payload
      );
      break;
    }
  }

  return next(action);
};
