import { DAY } from 'lib/durations';

export const transformInitialVisibleValue = ({
  initialVisibleValueOriginal,
  selectedDate,
  all,
}: {
  initialVisibleValueOriginal: Date | undefined;
  selectedDate: Date | undefined;
  all: Date[];
}): Date | undefined => {
  if (selectedDate !== undefined) {
    return selectedDate;
  } else if (initialVisibleValueOriginal === undefined) {
    return undefined;
  } else if (all.length < 1) {
    // TODO: Remove this "if else clause" using types
    // WHEN: have time to update
    //  - deriveTimeResponseOptions
    //  - and the 4+ files that consume these functions
    // HOW: Change type of `deriveTimeResponseOptions` to
    // output a array with min of 1 element
    //   type ArrayTwoOrMore<T> = { 0: T } & Array<T>
    throw Error(
      'Expected all.length < 1 [Should never throw this error - just gap in types]'
    );
  } else if (+initialVisibleValueOriginal < +all[0]) {
    return new Date(+initialVisibleValueOriginal + DAY);
  }
  return initialVisibleValueOriginal;
};
