import React from 'react';

import { isMobileWeb } from 'config/isMobileWeb';

import { MobileWebExperience } from './components/MobileWebExperience';
import { useCheckParamRedirectHomeIfMobileWeb } from './hooks/useCheckParamRedirectHomeIfMobileWeb';
import { MobileWebGateComponent } from './types';

export const MobileWebGate: MobileWebGateComponent = ({ children }) => {
  useCheckParamRedirectHomeIfMobileWeb();

  if (isMobileWeb) {
    return <MobileWebExperience />;
  }
  return children;
};
