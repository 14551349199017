import React, { ReactElement, ReactNode } from 'react';
import { View } from 'react-native';
import { useHistory } from 'react-router-dom';

import { NavBarStyles } from 'components/Navigation/constants';
import {
  useGetDynamicContentStyles,
  useGetScreenDimensions,
} from 'components/ResponsiveLayout';
import { matchesSceneSet } from 'components/Routes/matchesRoutes';

import { ScrollRow } from './styled';

export const CalendarChartContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { screenWidth, screenHeight } = useGetScreenDimensions();
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  const isLandscape = screenWidth > screenHeight;

  const history = useHistory();
  const isContent = matchesSceneSet(history);

  if (isMobileWeb) {
    return (
      <ScrollRow>
        <View
          style={{
            width: isLandscape
              ? styles.sleepDiaryWeekWidthMobileWebLandscape
              : !isContent
              ? styles.sleepDiaryWeekWidthMobileWeb
              : styles.sleepDiaryWeekWidthMobileWebContentPortrait,
          }}
        >
          {children}
        </View>
      </ScrollRow>
    );
  }

  return <>{children}</>;
};
