export const hexToRGBA = (hex?: string, alpha = 1): string | undefined => {
  if (typeof hex !== 'string') {
    return hex;
  }
  // first character is #
  const red = parseInt(
    hex.length === 4 ? hex.slice(1, 2).repeat(2) : hex.slice(1, 3),
    16
  );
  const green = parseInt(
    hex.length === 4 ? hex.slice(2, 3).repeat(2) : hex.slice(3, 5),
    16
  );
  const blue = parseInt(
    hex.length === 4 ? hex.slice(3, 4).repeat(2) : hex.slice(5, 7),
    16
  );
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};
