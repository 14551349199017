import React, { ReactElement, ReactNode } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';

// WARNING do not use Questions type in here (e.g. ResponseOption)
export type Values = Record<QuestionId, DropdownItem[]>;
export type Errors = Record<QuestionId, string>;
export type OnSubmitCallback = (
  values: Record<QuestionId, DropdownItem[]>,
  helpers: FormikHelpers<Record<QuestionId, DropdownItem[]>>
) => void;
export type OnValidateCallback = (
  // TODO object's cannot have number properties, change to string
  // WHEN we want more accurate types, and are ready to refactor all forms
  values: Record<QuestionId, DropdownItem[]>
) => Errors;
export type FormProps = {
  onSubmit: OnSubmitCallback;
  onValidate?: OnValidateCallback;
  initialValues?: Record<QuestionId, DropdownItem[]>;
  initialErrors?: Errors;
  children: ReactNode;
};
export type FormTypes = { Values: Values; Errors: Errors }; // Convenience name-space
export const Form = ({
  onSubmit,
  onValidate = (): Errors => ({}),
  initialValues = {},
  initialErrors = {},
  children,
}: FormProps): ReactElement => {
  const handleSubmit: OnSubmitCallback = (values, options) => {
    onSubmit(values, options);
  };

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      validate={onValidate}
      onSubmit={(
        values: Values,
        helpers: FormikHelpers<Record<QuestionId, DropdownItem[]>>
      ): void => {
        handleSubmit(values, helpers);
      }}
    >
      <>{children}</>
    </Formik>
  );
};

Form.displayName = 'Form';
