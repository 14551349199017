import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_user_info } from '@bighealth/api/UserAccount/v1';

import { ThrobberBehaviorMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useQueryWithIsFetchingMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/hooks/useQueryWithIsFetchingMeta';
import { SECOND } from 'lib/durations';

export type UseQueryGetUserInfoReturnType = UseQueryResult<
  get_user_info.Response,
  APIErrorResponse
>;

export const useQueryGetUserInfo = (options?: {
  meta: ThrobberBehaviorMeta | undefined;
}): UseQueryGetUserInfoReturnType => {
  const result = useQueryWithIsFetchingMeta<
    get_user_info.Response,
    APIErrorResponse
  >({
    queryKey: get_user_info.queryKey,
    queryFn: () => {
      return get_user_info({ timeout: 10 * SECOND });
    },
    cacheTime: Infinity,
    meta: options?.meta,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
  return result;
};
