export const Constant = {
  Notification: {
    TURN_ON_REMINDER_BTN: 'Turn on reminder',
    DAILY_REMINDER_MAIN_TEXT:
      'Turn on daily reminder to practice at the same time every day.',
    DAILY_REMINDER_SUBTEXT:
      'Reminders can help you build a daily practice and feel better sooner.',
    GO_TO_SETTINGS: 'Go to Settings',
    NOTIF_TURNED_OFF: 'Your phone notifications are off.',
    FIVE_TO_TEN_MINS:
      '5-10 minutes of daily practice can help you feel better. Add this to your daily routine!',
    ASKED_TO_TURN_ON:
      'You will be asked to open your phone’s Settings and enable notification.',
    TAP_TO_EDIT: 'Tap to edit',
    DAILY_APP_REMINDER: 'Daily app reminder',
  },
};
