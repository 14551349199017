import styled from 'styled-components/native';

import Image, { ResizeMode } from 'cross-platform/Image';

export const ColumnContainer = styled.View`
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

export const RawContainer = styled.View`
  flex-direction: row;
  width: 100%;
`;

export const SettingsContainer = styled.View`
  position: absolute;
  right: 5%;
  top: 8%;
`;

export const ContainerContent = styled.View`
  width: 100%;
  height: 88%;
`;

export const ContainerNextSSGInfo = styled.View``;

export const ContainerText = styled.View<{ isNotFullWidth: boolean }>`
  width: ${({ isNotFullWidth }): number => (isNotFullWidth ? 80 : 100)}%;
  min-height: 80px;
`;

export const ContainerPlayButton = styled.View`
  flex-direction: row;
  width: 20%;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ContainerPracticeLibraryButton = styled.View`
  width: 100%;
  height: 12%;
  justify-content: flex-end;
`;

export const PlayContainer = styled.View`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  width: 200px;
  height: 150px;
  border: 2px solid black;
`;

export const BackgroundImage = styled(Image).attrs({
  fit: ResizeMode.cover,
})`
  width: 100%;
  height: 100%;
  position: absolute;
`;
