import { ReactElement } from 'react';
import * as React from 'react';
import { G, Polygon, Rect, Svg } from 'react-native-svg';

// TODO: use this in Sleepio as icon as well because it is responsive
// WHEN: we are ready to create packages
export const CtaCirclePlayIcon = ({
  width,
  height,
  viewBox,
  circleColor,
  triangleColor,
}: {
  width: number;
  height: number;
  viewBox: string;
  circleColor?: string;
  triangleColor?: string;
}): ReactElement => {
  const trianglePointUpLeft = `${width / 2.5}, ${height / 3}`;
  const trianglePointDownLeft = `${width / 2.5}, ${(height / 3) * 2}`;
  const trianglePointRight = `${(width / 3) * 2}, ${height / 2}`;

  return (
    <Svg width={width} height={height} viewBox={viewBox}>
      <G>
        <Rect
          width={width}
          height={height}
          fill={circleColor}
          rx={`${width / 2}`}
        />
        <Polygon
          fill={triangleColor}
          points={`${trianglePointUpLeft} ${trianglePointRight} ${trianglePointDownLeft}`}
          opacity="1"
        />
      </G>
    </Svg>
  );
};
