import { View } from 'react-native';
import styled from 'styled-components/native';

import { Scene } from '@bighealth/types/src/scene-component';

import { Text } from 'components/sceneset-components/Text';

interface PillWrapperProps {
  alignItems?: Scene.Presentational.StyleObject['alignItems'];
  backgroundColor?: string;
  borderRadius: number;
  borderColor?: string;
  borderWidth?: number;
  spaceBetween: number;
  paddingHorizontal: number;
  paddingVertical: number;
  minWidth: number;
}

export const PillWrapper = styled(View)<PillWrapperProps>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.color.background.light};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border-width: ${({ borderWidth }) => (borderWidth ? `${borderWidth}px` : 0)};
  margin-right: ${({ spaceBetween }) => spaceBetween}px;
  min-width: ${({ minWidth }) => `${minWidth}px`};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  /**
   * 'align-items' native default value is 'center'
   * however, for this component, to preserve backwards compat,
   *   setting default to 'flex-start'
   * value cannot be 'undefined' or Android will throw an error
   */
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
`;

interface PillTextProps {
  color?: string;
  fontSize: number;
  lineHeight: number;
}

export const PillText = styled(Text)<PillTextProps>`
  color: ${({ color: color, theme }) =>
    color ? color : theme.color.primaryDark};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ lineHeight }) => lineHeight}px;
`;
