export type GenericInputTypes = 'text' | 'number';

export type RawGenericInputProps = {
  dataPath?: string;
  onValueChange: (value: string) => void;
  value: string | number;
  type?: GenericInputTypes;
  onClick?: () => void;
  onPress?: () => void;
  isSelected?: boolean;
  placeholderText?: string;
  questionId?: string;
  id?: string;
};

export const GENERIC_INPUT_PLACEHOLDER = 'Type your answer here';
