import { WithNotificationScreenRedirectComp } from './types';

/**
 * WithNotificationScreenRedirect: web
 *
 * On web we don't support local notifications, please refer to WithNotificationScreenRedirect.tsx
 */
export const WithNotificationScreenRedirect: WithNotificationScreenRedirectComp = ({
  children,
}) => {
  return children;
};
