import React, { ReactElement, ReactNode, useContext } from 'react';

import { NavBarStyles } from 'components/Navigation/constants';
import {
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';

import { Container } from './styled';

export const ChartContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  let heightChart = styles.sleepDiaryWeekChartHeight;
  if (isMobileWeb) {
    heightChart = styles.sleepDiaryWeekChartHeightMobileWeb;
  }
  return <Container style={{ height: heightChart }}>{children}</Container>;
};
