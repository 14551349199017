import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_client_id = async (
  requestConfig?: AxiosRequestConfig
): Promise<get_client_id.Response> => {
  const request: get_client_id.Request = {
    service_name: 'Fitbit',
    service_version: '1',
    service_method: 'get_client_id',
    args: {},
  };
  return await api(request, requestConfig);
};

get_client_id.queryKey = 'Fitbit/get_client_id';

export declare namespace get_client_id {
  export interface Request extends APIRequestBody {
    service_name: 'Fitbit';
    service_version: '1';
    service_method: 'get_client_id';
    args: {};
  }

  export type Result = string;

  export type Response = APIResponse<Result>;
}
