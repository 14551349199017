import { APIResponse } from '@bighealth/api';

import { decodeAPITypes } from 'lib/api/decodeAPITypes';

const decodeAPITypesPipe = ({
  status_code,
  result,
}: APIResponse): APIResponse => ({
  status_code,
  result: decodeAPITypes(result),
});

export { decodeAPITypesPipe };
