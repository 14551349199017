import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { get_next_session_available_datetime } from '@bighealth/api/SessionProgress/v1/get_next_session_available_datetime';
import Tree from '@bighealth/tree';

import { FillAbsolute } from 'components/Containers';
import { PlayerControls } from 'components/Media';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { useSafeParams } from 'components/Routes/useSafeParams';
import componentsDictionary from 'components/sceneset-components/sceneSetComponentsDictionary';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryAllDataForSceneSet } from 'lib/api/reactQueryHelpers';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { isDevMode } from 'lib/isDevMode';
import { useInitializeTimedOrchestrators } from 'lib/player/media/MediaPlayerOrchestrator/useInitializeTimedOrchestrators';
import { getIsScenePlayable } from 'lib/player/sceneSetHelpers';
import { addParentNodes } from 'lib/player/tree-utils';
import { useHandleTimersForAppState } from 'lib/player/useHandleTimersForAppState';
import { getTheme } from 'lib/styles/getTheme';
import { showControls } from 'state/player/actions';
import { PlayerStatus } from 'state/player/reducer';
import { getPlayerStatus, getSceneSetRenderKey } from 'state/player/selectors';
import { setIsFirstInit } from 'state/user/actions';

import { OrientationLocker } from './components/OrientationLocker';
import { SceneSetDebugInfo } from './components/SceneSetDebugInfo';
import { ContentContextProvider } from './providers/ContentContextProvider';
import { IsInactiveStateProvider } from './providers/IsInactiveStateProvider';
import { DefaultOrientationProps } from './types';

const TreeContainer = styled.View`
  display: flex;
  width: 100%;
  height: 100%;
`;

const FillAbsoluteWithBackground = styled(FillAbsolute)`
  background-color: ${getTheme('homeScreen.backgroundColor')};
`;

const KeyedSceneSetView = ({
  isInactiveState,
  defaultOrientation,
}: {
  isInactiveState: boolean;
} & DefaultOrientationProps): ReactElement | null => {
  useUpdateTitle();
  const orchestratorsAreReady = useInitializeTimedOrchestrators();
  const dispatch = useDispatch();
  const { sceneId } = useSafeParams();
  const { isSuccess, data } = useQueryAllDataForSceneSet();
  const playerState = useSelector(getPlayerStatus);

  const currentSceneSet = data?.result?.sceneSet;
  const isPlayable = getIsScenePlayable(currentSceneSet, sceneId);
  useEffect(() => {
    if (isPlayable) {
      dispatch(showControls(true));
    }
  }, [dispatch, isPlayable]);

  if (typeof currentSceneSet === 'undefined') {
    return null;
  }
  const currentSceneSetWithParentNodes = addParentNodes(currentSceneSet);
  const currentScene =
    currentSceneSetWithParentNodes.childNodes?.[sceneId || 0];
  if (typeof currentScene === 'undefined') {
    return null;
  }
  if (
    !(playerState === PlayerStatus.READY && isSuccess && orchestratorsAreReady)
  ) {
    return null;
  }

  const sceneChildNodes = currentScene.childNodes;
  return (
    <OrientationLocker
      defaultOrientation={defaultOrientation}
      allowedOrientation={currentSceneSet.allowed_orientation}
    >
      <contentErrors.Provider>
        <ContentContextProvider>
          <FillAbsoluteWithBackground
            {...roles('SceneSetView')}
            style={
              currentSceneSet.session_background_color
                ? { backgroundColor: currentSceneSet.session_background_color }
                : {}
            }
          >
            <contentErrors.ErrorBoundary>
              <TreeContainer>
                <Tree
                  componentsDictionary={componentsDictionary}
                  component={'Scene'}
                  data={sceneChildNodes}
                />
              </TreeContainer>
            </contentErrors.ErrorBoundary>
            <IsInactiveStateProvider value={{ isInactiveState }}>
              <PlayerControls
                hideMediaBar={currentSceneSet.hide_media_bar || !isPlayable}
              />
            </IsInactiveStateProvider>
            {isDevMode() && <SceneSetDebugInfo />}
          </FillAbsoluteWithBackground>
          <contentErrors.ErrorModal />
        </ContentContextProvider>
      </contentErrors.Provider>
    </OrientationLocker>
  );
};

const SceneSetView = (props: DefaultOrientationProps): ReactElement => {
  const isInactiveState = useHandleTimersForAppState();
  const key = useSelector(getSceneSetRenderKey);
  const queryClient = getQueryClient();
  const dispatch = useDispatch();
  useEffect(() => {
    return function cleanup() {
      // FIXME
      // WHY: Invalidating the key here because on session finish
      //      we want to create a reminder of when the next session
      //      will be available for the user which comes from the BE.
      //      This should live some where else or use Push Notifications.
      queryClient.invalidateQueries({
        queryKey: [get_next_session_available_datetime.queryKey],
        exact: true,
      });
      // for preventing losing false state for IsFirstInit
      // after refresh being triggered when session is done
      dispatch(setIsFirstInit(false));
    };
  }, [dispatch, queryClient]);

  return (
    <KeyedSceneSetView key={key} isInactiveState={isInactiveState} {...props} />
  );
};

export default SceneSetView;
