import React, { ReactElement } from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

export const CircleCheckIcon = ({ size }: { size: number }): ReactElement => {
  const viewbox = `0 0 ${size * 2} ${size * 2}`;
  return (
    <Svg width={size * 2} height={size * 2} fill="none" viewBox={viewbox}>
      <G>
        <Circle cx={size} cy={size} r={size} fill={'#4CD964'} />
        <Path
          d="M32.3748 42.0997C30.9498 40.4117 28.4261 40.1985 26.738 41.6236C25.05 43.0486 24.8368 45.5723 26.2619 47.2603L32.3748 42.0997ZM41.1006 58.6365L38.0442 61.2168C38.8112 62.1254 39.9427 62.6456 41.1317 62.6364C42.3207 62.6272 43.4439 62.0894 44.1968 61.1691L41.1006 58.6365ZM66.5054 33.8964C67.9041 32.1864 67.6518 29.6663 65.9419 28.2676C64.2319 26.8689 61.7118 27.1212 60.3131 28.8312L66.5054 33.8964ZM26.2619 47.2603L38.0442 61.2168L44.1571 56.0562L32.3748 42.0997L26.2619 47.2603ZM44.1968 61.1691L66.5054 33.8964L60.3131 28.8312L38.0045 56.1039L44.1968 61.1691Z"
          fill="white"
        />
      </G>
    </Svg>
  );
};
