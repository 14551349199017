import React from 'react';
import { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';

import { ProductReferences } from 'common/constants/enums';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { Pill } from 'daylight/components';

import {
  getActiveStatePillStyles,
  getInactiveStatePillStyles,
  SelectablePillListWrapper,
} from './SelectablePillList.styled';

export const SelectablePillList = ({
  activeIndex,
  labels,
  onPress,
}: {
  labels: string[];
  activeIndex: number;
  onPress(tab: number): void;
}): null | ReactElement => {
  const theme = getThemeForProduct(ProductReferences.DAYLIGHT);

  if (labels.length < 2) {
    return null;
  }
  const activeStatePillStyles = getActiveStatePillStyles(theme);
  const inactiveStatePillStyles = getInactiveStatePillStyles(theme);

  return (
    <SelectablePillListWrapper
      testID="SelectablePillListWrapper"
      accessibilityRole="tablist"
    >
      {labels.map((label, index) => {
        const isActive = index === activeIndex;
        return (
          <TouchableOpacity
            testID={`${isActive ? 'Show ' : ''}${label}`}
            key={index}
            onPress={() => onPress(index)}
            accessibilityLabel={`${isActive ? 'Show ' : ''}${label}`}
            accessibilityRole="tab"
          >
            <Pill
              key={index}
              textComponent={{
                component: 'Text',
                style: isActive
                  ? activeStatePillStyles
                  : inactiveStatePillStyles,
                text: label,
              }}
            />
          </TouchableOpacity>
        );
      })}
    </SelectablePillListWrapper>
  );
};
