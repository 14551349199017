import { useCallback } from 'react';

import { matchPath, useHistory } from 'cross-platform/react-router';

type GetContentCallback = (tab?: string) => string;

type MatchParams = {
  params: {
    productReference: string;
    caseFile: string;
  };
};

export const useGetFullContentScreensPath = (): GetContentCallback => {
  const history = useHistory();
  let match;
  match = matchPath(history.location.pathname, {
    path: '/:productReference/:caseFile',
  }) as MatchParams;
  if (!match) {
    match = matchPath(history.location.pathname, {
      path: '/:productReference/:caseFile/:slug',
    }) as MatchParams;
  }
  const { productReference, caseFile } = match.params;
  return useCallback(
    (slug?) => {
      return `/${productReference}/${caseFile}/${slug || 'initial'}`;
    },
    [caseFile, productReference]
  );
};
