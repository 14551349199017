import { useSafeArea } from 'react-native-safe-area-context';

import { Modal } from '@bighealth/types/src/scene-components/client';

import { FlexBoxValues } from 'components/layout/FlexBox/enums';
import { DEFAULT_THEME, Theme } from 'config/getThemeForProducts';

import { ModalStyles } from '../constants';
import { getModalWidth, getScaledValueForWidth } from '../helpers';
import { getIsNarrowMobileWeb } from '../helpers/getIsNarrowMobileWeb';

import { useGetScreenDimensions } from './useGetScreenDimensions';

// TODO: align this type with the ModalStylesType in the Theme
// WHEN: FE engineers have bandwith
type ModalStyles = {
  maxWidth: number;
  minWidth: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  width: number;
  borderRadius: number;
  marginRight: number;
  height: number | string;
  contentInset: number;
  alignModal: FlexBoxValues.alignCenter | FlexBoxValues.flexEnd;
  justifyWithCloseButton?: boolean;
};

type ModalStyle = Modal['style'];
type ModalAlignment = Modal['alignment'];

export const useGetDynamicModalStyles = (
  theme: Theme = DEFAULT_THEME,
  contentModalStyle?: ModalStyle,
  alignment?: ModalAlignment
): ModalStyles => {
  const insets = useSafeArea();
  const { screenWidth, screenHeight } = useGetScreenDimensions();

  const {
    modalReferenceDimension,
    largeScreenBreakpointPx,
    minWidthPx,
    maxWidthPx,
    modalHorizontalPaddingPx,
    modalVerticalPaddingPx,
    modalBorderRadiusPx,
    modalBreakpointMarginRightPx,
    justifyWithCloseButton,
  } = theme.modal;

  const isLarge = screenWidth > largeScreenBreakpointPx;

  // If the horizontal alignment is blank, default to center aligned.
  // Most modals use the default.
  const isCenterAligned =
    alignment?.horizontal === undefined || alignment?.horizontal === 'middle';

  const minWidth = insets.right + minWidthPx;
  const maxWidth = insets.right + maxWidthPx;
  const width = getModalWidth(
    minWidth,
    maxWidth,
    screenWidth,
    largeScreenBreakpointPx
  );

  let paddingLeft = getScaledValueForWidth(
    width,
    modalHorizontalPaddingPx,
    modalReferenceDimension,
    contentModalStyle?.paddingLeft
  );
  let paddingRight = getScaledValueForWidth(
    width,
    modalHorizontalPaddingPx,
    modalReferenceDimension,
    contentModalStyle?.paddingRight
  );

  const paddingTop = getScaledValueForWidth(
    width,
    modalVerticalPaddingPx,
    modalReferenceDimension,
    contentModalStyle?.paddingTop
  );

  const paddingBottom = getScaledValueForWidth(
    width,
    modalVerticalPaddingPx,
    modalReferenceDimension,
    contentModalStyle?.paddingBottom
  );

  const borderRadius = isLarge
    ? getScaledValueForWidth(
        width,
        modalBorderRadiusPx,
        modalReferenceDimension,
        contentModalStyle?.borderRadius
      )
    : 0;

  const height = isLarge ? 'auto' : screenHeight;

  if (getIsNarrowMobileWeb(screenWidth)) {
    paddingLeft = 10;
    paddingRight = 10;
  }

  if (isCenterAligned) {
    return {
      borderRadius,
      contentInset: 0,
      height,
      marginRight: 0,
      maxWidth: width,
      minWidth: width,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      width,
      alignModal: FlexBoxValues.alignCenter,
      justifyWithCloseButton: justifyWithCloseButton,
    };
  }

  const marginRight = isLarge
    ? (screenWidth * modalBreakpointMarginRightPx) / maxWidthPx
    : 0;

  return {
    borderRadius,
    height,
    marginRight,
    maxWidth: width,
    minWidth: width,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    contentInset: insets.right,
    alignModal: FlexBoxValues.flexEnd,
    justifyWithCloseButton: justifyWithCloseButton,
  };
};
