import React, { ReactElement, useContext } from 'react';
import { View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { roles } from 'cross-platform/utils/roleProps';

import { CircleLockIcon } from '../icons';

interface LockedButtonProps {
  width: number;
  height: number;
  viewBox: string;
  backgroundColor?: string;
  iconColor?: string;
}
export const LockedButton = ({
  backgroundColor,
  iconColor,
  ...props
}: LockedButtonProps): ReactElement => {
  const theme = useContext(ThemeContext);

  return (
    <View {...roles('DaylightLockedButton')}>
      <CircleLockIcon
        {...props}
        circleColor={
          backgroundColor ? backgroundColor : theme.color.background.dark
        }
        lockColor={iconColor ? iconColor : theme.color.primaryLight}
      />
    </View>
  );
};
