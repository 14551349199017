import styled from 'styled-components/native';

const TooltipContent = styled.View`
  z-index: 2;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 88%;

  align-items: center;
  justify-content: center;
  border-radius: 4px;

  shadow-color: #000;
  shadow-offset: 0px 4px;
  shadow-opacity: 0.4;
  shadow-radius: 4px;
  elevation: 5; /* for Android */
`;
TooltipContent.displayName = 'TooltipContent';

const TooltipModalBackground = styled.View`
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  opacity: 1;
`;
TooltipModalBackground.displayName = 'TooltipContent';

const TooltipInteractiveModalBackground = styled.TouchableOpacity`
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  opacity: 1;

  position: absolute;
`;
TooltipInteractiveModalBackground.displayName =
  'TooltipInteractiveModalBackground';

// Detail tooltip components ...
const DetailContainer = styled.View`
  display: flex;
  flex-direction: column;

  padding: 16px;
`;
DetailContainer.displayName = 'DetailContainer';

const DetailContentRow = styled.View`
  display: flex;
  flex-direction: row;
`;
DetailContentRow.displayName = 'DetailContentRow';

const DetailTittle = styled.Text`
  font-weight: 500;
  color: ${props => props.theme.color.overlay.primary};
`;
DetailTittle.displayName = 'DetailTittle';

const DetailKey = styled.Text`
  font-weight: 500;
  color: ${props => props.theme.color.overlay.primary};
`;
DetailKey.displayName = 'DetailKey';

const DetailValue = styled(DetailKey)`
  font-weight: 700;
`;
DetailValue.displayName = 'DetailValue';

const DetailButtonText = styled(DetailKey)`
  text-decoration: underline;
`;
DetailButtonText.displayName = 'DetailButtonText';

const DetailButton = styled.TouchableOpacity`
  margin-top: 24px;
`;
DetailButton.displayName = 'DetailButton';

export {
  DetailButton,
  DetailButtonText,
  DetailContainer,
  DetailContentRow,
  DetailKey,
  DetailTittle,
  DetailValue,
  TooltipContent,
  TooltipInteractiveModalBackground,
  TooltipModalBackground,
};
