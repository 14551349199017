// NOTE: This file is only for 📖 Storybook & ✅ Tests
import React from 'react';

import { Footer } from 'components/Footer';
import { Container } from 'components/Screens/ContentScreens/components/ContentPlaceholder';
import {
  FallbackProps,
  FallbackRenderWithWrapper,
} from 'lib/error/ErrorBoundary/types';

import { VerticalScroll, Wrapper } from './styled';

const withContentPlaceholderWrapper: FallbackRenderWithWrapper = fn => (
  args: FallbackProps
) => (
  <Wrapper>
    <VerticalScroll>
      <Container>{fn?.(args)}</Container>
      <Footer />
    </VerticalScroll>
  </Wrapper>
);

export { withContentPlaceholderWrapper };
