import React, { ReactElement, useCallback } from 'react';

import { SceneActionTypes } from '@bighealth/types/dist/enums';

import { ButtonSizes, UniversalButton } from 'components/UniveralButtons';
import { WithBackupFormikContext } from 'components/WithBackupFormikContext';
import { roles } from 'cross-platform/utils/roleProps';
import { extrapolateSleepDiaryData } from 'lib/api';
import { useQueryProduct, useQueryProgram } from 'lib/api/reactQueryHelpers';
import useActionHandler from 'lib/player/useActionHandler';
import * as reporter from 'lib/reporter';
import { getTimezone } from 'lib/timezone';

type ExtrapolateButtonProps = {
  disabled: boolean;
  setIsLoading: (loading: boolean) => void;
  text: string;
  shouldExtrapolate: boolean;
};

/**
 * Extrapolate button that makes a network call to extrapolate diaries and, if successful
 * moves to the next SceneSet.
 *
 * This cant be handled in the top level Networked layer of the component because the
 * ability to call next SceneSet is specific to player based renderings of the component
 * and hooks cannot be called conditionally.
 * @param props
 * @constructor
 */
const ExtrapolateButtonWithoutFormikContext = (
  props: ExtrapolateButtonProps
): ReactElement | null => {
  const productId = useQueryProduct()?.data?.result.id;
  const programId = useQueryProgram()?.data?.result.id;
  // Get the product and program details for the network call.
  // Product
  // Even though the "continue" button isn't really "submitting", "SUBMIT" is
  // the expected action
  const nextSceneActionHandler = useActionHandler({
    type: SceneActionTypes.SUBMIT,
  });
  const { setIsLoading } = props;
  const extrapolateDiaryDataAndContinue = useCallback(async (): Promise<
    void
  > => {
    try {
      setIsLoading(true);
      await extrapolateSleepDiaryData({
        user_timezone: getTimezone(),
        product_id: productId as number,
        program_id: programId as number,
      });
      if (typeof nextSceneActionHandler === 'function') {
        await nextSceneActionHandler();
      }
    } catch (e) {
      reporter.log(e?.message, e);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, productId, nextSceneActionHandler]);

  const onPress = () => {
    if (props.shouldExtrapolate) {
      extrapolateDiaryDataAndContinue();
    } else {
      if (typeof nextSceneActionHandler === 'function') {
        nextSceneActionHandler();
      }
    }
  };

  return (
    <UniversalButton
      {...roles(`sleepDiaryCalendarContinueButton`)}
      isDisabled={props.disabled}
      onPress={onPress}
      text={props.text}
      size={ButtonSizes.Small}
    />
  );
};

const ExtrapolateButton = (props: ExtrapolateButtonProps): ReactElement => {
  return WithBackupFormikContext(props, ExtrapolateButtonWithoutFormikContext);
};

export { ExtrapolateButton };
