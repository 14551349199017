/* istanbul ignore file */ /* only used in storybooks */

import { SceneSet } from '@bighealth/types/src';

/**
 * NOTE faking state, by adding faux-sceneSet
 * useSafeParams() has hard dependency
 * on the existence of scene sets
 * @see {@link src/components/Routes/useSafeParams.tsx}
 */
const useFakeStorybookSceneSet = (): SceneSet[] | undefined => {
  return [
    {
      id: 1,
      component: 'SceneSet',
      // WARNING must match :sceneSetReference url-segment
      reference: 'storybook',
      childNodes: [],
    },
  ];
};

export { useFakeStorybookSceneSet };
