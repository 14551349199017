import { ContentModalAction, ContentModalActionTypes } from './actions';

export interface ContentModalState {
  totalMounted: number;
}

export const initialContentModalState: ContentModalState = {
  totalMounted: 0,
};

export const contentModalReducer = (
  state = initialContentModalState,
  action: ContentModalAction
): ContentModalState => {
  const { type } = action;
  switch (type) {
    case ContentModalActionTypes.ADD_MOUNTED:
      return {
        ...state,
        totalMounted: state.totalMounted + 1,
      };
    case ContentModalActionTypes.REMOVE_MOUNTED:
      return {
        ...state,
        totalMounted: state.totalMounted - 1,
      };
    default:
      return state;
  }
};
