import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/native';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { Scene } from '@bighealth/types/src/scene-component';

import { Markdown } from 'components/Markdown/Markdown';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import componentsDictionary from 'components/sceneset-components/sceneSetComponentsDictionary';
import { convertToNativeStyle } from 'lib/utils/styles/convertStyles';

import { convertMarkdownType } from './helpers/convertMarkdownType';
import { convertOnPress } from './helpers/convertOnPress';

type LayoutComponentProps = {
  children?: ReactNode;
  style?: Scene.Presentational.StyleObject;
  childNodes: get_user_session_screen_data.SessionScreenComponent[];
};

export const renderChildNodes = (
  childNodes: get_user_session_screen_data.SessionScreenComponent[]
): ReactElement[] =>
  childNodes.map((node, i) => <SessionScreenComponent key={i} {...node} />);

const SessionScreenComponent = (
  node: get_user_session_screen_data.SessionScreenComponent
): ReactElement | null => {
  switch (node.component) {
    case 'Markdown':
      return (
        <Markdown {...node} type={convertMarkdownType(node.type)}>
          {node.text}
        </Markdown>
      );
    case 'Button': {
      const Component = componentsDictionary[node.component];
      return (
        <Component
          {...node}
          style={convertToNativeStyle(node.style)}
          onPress={convertOnPress(node.onPress)}
        />
      );
    }
    case 'Text': {
      const Component = componentsDictionary[node.component];
      return <Component {...node} />;
    }
    case 'Image': {
      const Component = componentsDictionary[node.component];
      return <Component {...node} />;
    }
    case 'Group': {
      return <LayoutComponent {...node} />;
    }
    default:
      return null;
  }
};

const LayoutContainer = styled.View``;
LayoutContainer.displayName = 'LayoutContainer';

export const LayoutComponent = ({
  style = {},
  children,
  childNodes,
}: LayoutComponentProps): ReactElement => {
  const transformStylesToContext = useTransformStylesToContext();
  return (
    <LayoutContainer
      style={convertToNativeStyle(transformStylesToContext(style))}
    >
      {renderChildNodes(childNodes)}
      {children}
    </LayoutContainer>
  );
};
