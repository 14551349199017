import { LocalNotificationConfig } from 'config/localNotifications';

/**
 * No-op on web; Only relevant in native apps.
 *
 * @see getLocalNotificationsConfig.native
 *
 * provide product-specific local notifications config
 *
 * this should be used in generic components
 *
 * in src/<product>/components you can use the product-specific config file directly
 */
import { NO_OP_MESSAGE__NATIVE } from './utils/constants';

export const getLocalNotificationsConfig = (): LocalNotificationConfig => {
  throw Error(NO_OP_MESSAGE__NATIVE); // optional
};
