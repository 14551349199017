import React, { FunctionComponent, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { PLATGEN_SPA_BASE_URL } from 'config/envVars';

const BrowserRouterWithBase: FunctionComponent<PropsWithChildren<
  BrowserRouter['props']
>> = props => (
  <BrowserRouter basename={PLATGEN_SPA_BASE_URL || '/'} {...props} />
);

export default BrowserRouterWithBase;
