import styled, { ThemeProps } from 'styled-components/native';

import { Theme, ThemeProp } from 'config/getThemeForProducts';

import { Content as ContentUnstyled } from '../';

import { ControlProps } from '.';

export const getControlThemes = (props: ControlProps & ThemeProp): string => {
  const controls =
    props.isInput && typeof props?.theme.controls.input !== 'undefined'
      ? props?.theme.controls.input
      : props?.theme.controls.selection;

  return props.highlight
    ? `
    color: ${controls.default.highlight.color};
    background-color: ${controls.default.highlight.backgroundColor};
    border-color: ${controls.default.highlight.borderColor};
    `
    : props.quiz
    ? `
      color: ${controls.quiz[props.quiz].color};
      background-color: ${controls.quiz[props.quiz].backgroundColor};
      border-color: ${controls.quiz[props.quiz].borderColor};
      `
    : props.isPressing
    ? `
      color: ${controls.default.isPressing.color};
      background-color: ${controls.default.isPressing.backgroundColor};
      border-color: ${controls.default.isPressing.borderColor};
      `
    : props.isActive
    ? `
      color: ${controls.default.isActive.color};
      background-color: ${controls.default.isActive.backgroundColor};
      border-color: ${controls.default.isActive.borderColor};
      `
    : props.isSelected
    ? `
      color: ${controls.default.isSelected.color};
      background-color: ${controls.default.isSelected.backgroundColor};
      border-color: ${controls.default.isSelected.borderColor};
      `
    : `
      color: ${controls.color};
      background-color: ${controls.backgroundColor};
      border-color: ${controls.borderColor};
  `;
};
export const Content = styled(ContentUnstyled)<ControlProps>`
  border-style: ${props => props.theme.content.controls.borderStyle};
  border-width: ${props => props.theme.content.controls.borderWidth};
  opacity: ${({ theme, isDisabled }: ControlProps & ThemeProps<Theme>) =>
    isDisabled
      ? theme.content.controls.isDisabled.opacity
      : theme.content.controls.opacity};

  ${getControlThemes};
`;
