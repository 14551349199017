import React, { ReactElement } from 'react';
import { View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { roles } from 'cross-platform/utils/roleProps';
import { useQueryGoals } from 'lib/api/reactQueryHelpers';

import { Goal } from './Goal';

export const GoalsContainer = styled(View)`
  flex-direction: row;
`;

export type Props = {
  isInSceneSet?: boolean;
  style?: ViewStyle;
};

export const Goals = (props: Props): ReactElement => {
  const goalsResult = useQueryGoals(props);

  const entries = goalsResult.data?.result;

  return (
    <GoalsContainer {...roles('GoalsCollection')} style={props.style}>
      {entries?.map((entry, index) => (
        <Goal
          {...props}
          {...entry}
          key={entry.label.text}
          isLast={index === entries.length - 1}
        />
      ))}
    </GoalsContainer>
  );
};
