import { throwIfMissingEnvVar } from 'config/envVars/helpers';

export const SNAPENGAGE_ID = throwIfMissingEnvVar(
  'SNAPENGAGE_ID',
  process.env.SNAPENGAGE_ID
);

export const PLATGEN_SENTRY_DSN = throwIfMissingEnvVar(
  'PLATGEN_SENTRY_DSN',
  process.env.PLATGEN_SENTRY_DSN
);

export const PLATGEN_DATADOG_APPLICATION_ID =
  process.env.PLATGEN_DATADOG_APPLICATION_ID || '';

export const PLATGEN_DATADOG_CLIENT_TOKEN =
  process.env.PLATGEN_DATADOG_CLIENT_TOKEN || '';

export const PLATGEN_NHS_LINK = process.env.PLATGEN_NHS_LINK || '';
