import React from 'react';

import { Modal } from 'components/PopupModal/components/Modal';
import {
  ScalingContext,
  ScalingContextProvider,
} from 'components/ResponsiveLayout';
import * as roleProps from 'cross-platform/utils/roleProps';

import INeedHelpComponent from './components/shared/INeedHelpComponent';
import { OverlayTint, Panel, Wrapper } from './components/styled/styled';

type MFAINeedHelpModalProps = {
  onRequestClose: () => void;
  visible: boolean;
  redirectToDownloads: () => void;
  navigate: () => void;
};

const MFAModalINeedHelp = (
  props: MFAINeedHelpModalProps
): React.ReactElement => {
  return (
    <ScalingContextProvider scalingContext={ScalingContext.Modal}>
      <Wrapper
        visible={!!props.visible}
        {...roleProps.pass(props, undefined, { default: 'INeedHelpWrapper' })}
      >
        <Modal
          visible={!!props.visible}
          transparent={true}
          supportedOrientations={['portrait', 'landscape']}
        >
          <OverlayTint
            style={{ backgroundColor: 'none' }}
            {...roleProps.roles('INeedHelpOverlayTint')}
          >
            <Panel {...roleProps.roles('INeedHelpPanel')}>
              <INeedHelpComponent
                redirectToDownloads={props.redirectToDownloads}
                onRequestClose={props.onRequestClose}
                navigate={props.navigate}
              />
            </Panel>
          </OverlayTint>
        </Modal>
      </Wrapper>
    </ScalingContextProvider>
  );
};
export default MFAModalINeedHelp;
