import React, { ReactElement } from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { roles } from 'cross-platform/utils/roleProps';

import { IconStyles } from './types';

export const CaptionsOn = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  const width = size;
  const height = size * 0.71428571;

  return (
    <Svg
      {...roles('CaptionsOn')}
      accessibilityLabel="Captions are on"
      width={width}
      height={height}
      viewBox="0 0 28 20"
    >
      <Rect
        width="28"
        height="20"
        rx="4"
        fill={style?.backgroundColor || 'currentColor'}
      />
      <Path
        d="M10.6564 11.3307H12.5607C12.5225 11.9548 12.3505 12.5089 12.0448 12.993C11.7434 13.477 11.3209 13.8549 10.7774 14.1266C10.2382 14.3984 9.58858 14.5342 8.82856 14.5342C8.23413 14.5342 7.70126 14.4323 7.22996 14.2285C6.75867 14.0205 6.3553 13.7233 6.01988 13.3369C5.68869 12.9505 5.43606 12.4834 5.26198 11.9357C5.0879 11.388 5.00085 10.7745 5.00085 10.0951V9.45185C5.00085 8.7725 5.09002 8.15897 5.26835 7.61124C5.45092 7.05927 5.70992 6.5901 6.04535 6.20372C6.38503 5.81734 6.79051 5.52013 7.26181 5.31208C7.73311 5.10403 8.2596 5 8.84129 5C9.61405 5 10.2658 5.14012 10.7965 5.42035C11.3315 5.70058 11.7455 6.08696 12.0385 6.57948C12.3357 7.07201 12.514 7.63247 12.5735 8.26087H10.6628C10.6416 7.88723 10.5673 7.57091 10.4399 7.31191C10.3125 7.04866 10.1193 6.85122 9.86031 6.7196C9.60556 6.58373 9.26589 6.51579 8.84129 6.51579C8.52285 6.51579 8.24474 6.57524 8.00697 6.69412C7.7692 6.81301 7.56964 6.99346 7.40829 7.23548C7.24695 7.4775 7.12594 7.7832 7.04527 8.1526C6.96884 8.51775 6.93063 8.94659 6.93063 9.43911V10.0951C6.93063 10.5749 6.96672 10.9974 7.0389 11.3625C7.11108 11.7234 7.22147 12.0291 7.37008 12.2796C7.52293 12.5259 7.71825 12.7127 7.95602 12.8401C8.19804 12.9632 8.48888 13.0248 8.82856 13.0248C9.22767 13.0248 9.55673 12.9611 9.81573 12.8337C10.0747 12.7064 10.2722 12.5174 10.408 12.2669C10.5482 12.0164 10.6309 11.7043 10.6564 11.3307Z"
        fill={style?.color || 'currentColor'}
      />
      <Path
        d="M20.4951 11.3307H22.3994C22.3612 11.9548 22.1892 12.5089 21.8835 12.993C21.582 13.477 21.1596 13.8549 20.6161 14.1266C20.0769 14.3984 19.4272 14.5342 18.6672 14.5342C18.0728 14.5342 17.5399 14.4323 17.0686 14.2285C16.5973 14.0205 16.194 13.7233 15.8585 13.3369C15.5273 12.9505 15.2747 12.4834 15.1006 11.9357C14.9266 11.388 14.8395 10.7745 14.8395 10.0951V9.45185C14.8395 8.7725 14.9287 8.15897 15.107 7.61124C15.2896 7.05927 15.5486 6.5901 15.884 6.20372C16.2237 5.81734 16.6292 5.52013 17.1005 5.31208C17.5718 5.10403 18.0983 5 18.6799 5C19.4527 5 20.1045 5.14012 20.6352 5.42035C21.1702 5.70058 21.5842 6.08696 21.8771 6.57948C22.1743 7.07201 22.3527 7.63247 22.4121 8.26087H20.5014C20.4802 7.88723 20.4059 7.57091 20.2785 7.31191C20.1512 7.04866 19.958 6.85122 19.699 6.7196C19.4442 6.58373 19.1045 6.51579 18.6799 6.51579C18.3615 6.51579 18.0834 6.57524 17.8456 6.69412C17.6079 6.81301 17.4083 6.99346 17.2469 7.23548C17.0856 7.4775 16.9646 7.7832 16.8839 8.1526C16.8075 8.51775 16.7693 8.94659 16.7693 9.43911V10.0951C16.7693 10.5749 16.8054 10.9974 16.8776 11.3625C16.9497 11.7234 17.0601 12.0291 17.2087 12.2796C17.3616 12.5259 17.5569 12.7127 17.7947 12.8401C18.0367 12.9632 18.3275 13.0248 18.6672 13.0248C19.0663 13.0248 19.3954 12.9611 19.6544 12.8337C19.9134 12.7064 20.1108 12.5174 20.2467 12.2669C20.3868 12.0164 20.4696 11.7043 20.4951 11.3307Z"
        fill={style?.color || 'currentColor'}
      />
    </Svg>
  );
};
