import React, { ReactElement, ReactNode, useContext } from 'react';
import { View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { useBackHandler } from 'components/backHandler/useBackHandler';
import { FillAbsolute } from 'components/Containers';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { Header } from '../../Header/Header';

type SettingScreenType = {
  title: string;
  children?: ReactNode;
};

export const SettingsScreen = (props: SettingScreenType): ReactElement => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const { productReference } = useSafeParams();
  useBackHandler(productReference);
  const goBackToTodayScreen = () => {
    history.push(`/${productReference}/home`, { openSettingsNav: true });
  };
  const transformStyle = useTransformContentStyle();
  return (
    <FillAbsolute style={{ backgroundColor: theme.color.white }}>
      <View
        style={{
          padding: transformStyle(10),
          width: '100%',
        }}
      >
        <Header
          text={props.title}
          handleClose={() => goBackToTodayScreen()}
          headerSize={transformStyle(18)}
          closeIconSize={transformStyle(20)}
        />
      </View>
      {props.children}
    </FillAbsolute>
  );
};
