import matchPath from '../matchPath/matchPath';
import useHistory from '../useHistory/useHistory';

type Params = {
  [key: string]: string;
};

const useMatchedPath = <T extends Params>(path: string): typeof matchedPath => {
  const history = useHistory();
  const matchedPath = matchPath<T>(history.location.pathname, {
    path,
  });
  return matchedPath;
};

export default useMatchedPath;
