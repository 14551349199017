import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'modal-react-native-web';

import { App } from './App';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
const el = document.getElementById('root');
Modal.setAppElement(el);

// TODO: Refactor render to createRoot
// https://bighealth.atlassian.net/browse/FEG-302
export default ReactDOM.render(<App />, el);
