import { extrapolateSleepDiaryData } from 'lib/api';
import { getTimezone } from 'lib/timezone';

// WHY infer? Return object is defined by its declaration
export type ServiceCaller = ReturnType<typeof createServiceCaller>;

/**
 * Co-locations mapping state to service fetchers
 * This is at the cost of some up-front function creation
 *
 * This fn should combine passed-in server-side args (if any) with client-side args (if-any)
 * @param param0
 * @returns
 */
export const createServiceCaller = (options: {
  productId: number;
  programId: number;
}) => ({
  SleepDiary: {
    v1: {
      extrapolate_missing_diaries_from_past_seven_days: () => {
        const { productId, programId } = options;
        return extrapolateSleepDiaryData({
          // client-side args
          user_timezone: getTimezone(),
          product_id: productId,
          program_id: programId,
        }).catch(err => {
          if (
            err.response.data.result.__name__ ===
            'IneligibleForExtrapolationException'
          ) {
            /*
              Swallow error here
              
              WHY?

              Scenario:
              The edge conditions direct a User to a SS with a "Continue button" that will 
              trigger a call extrapolate_diaries when clicked.
              
              Edge-case:
              User opens another page with the same SS, and clicks both buttons
              User opens app, and fills out sleep diaries
              
              Result:
              The extrapolate_diaries is no longer valid 
              - and will generate "IneligibleForExtrapolationException"

              WARNING Relies on error serialization not changing
              https://github.com/sleepio/core-framework-python/blob/e64533a/bh/core_utils/bh_exception.py#L236
              */
          } else {
            throw err;
          }
        });
      },
    },
  },
});
