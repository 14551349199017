import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showControls } from 'state/player/actions';
import { getControlsAreVisible } from 'state/player/selectors';
import { setCurrentFocusedQuestionId } from 'state/question-response/actions';
import { getCurrentFocusedQuestionId } from 'state/question-response/selectors';

export type UseLatestFocusedQuestion = {
  currentFocusedQuestionId: number | undefined;
  setLatestFocusedQuestion: (id: number | undefined) => void;
};

export const useLatestFocusedQuestion = (): UseLatestFocusedQuestion => {
  const dispatch = useDispatch();
  const currentFocusedQuestionId = useSelector(getCurrentFocusedQuestionId);
  const controlsAreVisible = useSelector(getControlsAreVisible);

  const setLatestFocusedQuestion = useCallback(
    (id: number | undefined): void => {
      if (typeof id === 'number' && controlsAreVisible) {
        dispatch(showControls(false));
      }
      dispatch(setCurrentFocusedQuestionId(id));
    },
    [controlsAreVisible, dispatch]
  );

  return { setLatestFocusedQuestion, currentFocusedQuestionId };
};
