import { TextStyle } from 'react-native';

import { TextTypes } from 'common/constants/enums';
import { QuizAttribute } from 'components/constants';
import { DaylightDatePickerDisplayFormat } from 'components/generic-question/DateTimePickers/types';
import { GenericInputButtonAlign } from 'components/generic-question/GenericInputButton/types';
import {
  TabItemFlexType,
  TabItemTextSize,
} from 'components/Navigation/BottomNavigation/types';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { ButtonColorSchemes } from 'components/UniveralButtons/buttonColorSchemes';
import { ButtonSizes } from 'components/UniveralButtons/types';
import { CaptionState } from 'state/player/reducer';

import {
  ControlAttrs,
  createTheme,
  ThemeValuesType,
} from '../utils/createTheme';
import { FooterTheme } from '../utils/createTheme/footerSchemesTypes';
import { HeadingLevel } from '../utils/createTheme/headingSchemesTypes';
import { TextAreaStates } from '../utils/createTheme/textArea';
import { ThemeMode } from '../utils/createTheme/themeMode';

const daylightColors = {
  orange: '#FF754F',
  white: '#FFFFFF',
  text: '#363334',
  placeholderText: '#6A717D',
  emergencyText: '#B92D0F',
  selectedErrorText: '#2467B0',
  splashPageText: '#5F1902',
  focusedInputOrange: '#FFAA92',
  disabledInputGrey: '#FBFCFD',
  inputOutlineGrey: '#DFE1E6',
  selectedPink: '#FFDED4',
  deselectedGrey: '#F7F4F3',
  continueButtonBlack: '#111111',
  selectedBlue: '#0F2147',
  progressGreen: '#4CD964',
  checklistIndicatorGreen: '#A8FFB7',
  error: '#B92D0F',
  black: '#000000',
  grey: '#757575',
  mediumGrey: '#6A717D',
  deselectedSelectOption: '#989BA4',
  // controls
  progressColor: '#4CD964',
  progressColorDim: '#E4E4E4',
  lightGrey: '#F7F4F3',
  lightPink: '#FFBBBB',
  headerColor: '#231F20',
};

const buttonPrimaryConfiguration = {
  default: {
    color: daylightColors.white,
    backgroundColor: daylightColors.continueButtonBlack,
    borderColor: 'transparent',
  },
  disabled: {
    color: daylightColors.white,
    backgroundColor: 'rgba(1, 1, 1, 0.4)',
    borderColor: 'transparent',
  },
  pressed: {
    color: daylightColors.white,
    backgroundColor: daylightColors.continueButtonBlack,
    borderColor: 'transparent',
  },
  hover: {
    color: daylightColors.white,
    backgroundColor: daylightColors.continueButtonBlack,
    borderColor: 'transparent',
  },
};

const color = {
  primary: daylightColors.orange,
  primaryLight: daylightColors.white,
  primaryDark: daylightColors.black,
  primaryGrey: daylightColors.continueButtonBlack,
  background: {
    light: daylightColors.white,
    medium: daylightColors.lightGrey,
    dark: daylightColors.black,
    imageFallback: daylightColors.lightPink,
    component: daylightColors.white,
  },
  error: daylightColors.error,
  black: daylightColors.black,
  white: daylightColors.white,
  hint: daylightColors.text,
  success: daylightColors.text,
  text: {
    primary: daylightColors.text,
    primaryInverse: daylightColors.white,
    placeholder: daylightColors.placeholderText,
    splashPage: daylightColors.splashPageText,
    link: daylightColors.selectedErrorText,
    primarySharedInverse: daylightColors.text,
  },
  // to be removed
  textGoogle: {
    primary: daylightColors.white,
    secondary: daylightColors.orange,
  },
  textFacebook: {
    primary: daylightColors.white,
    secondary: daylightColors.orange,
  },
  overlay: {
    primary: daylightColors.black,
  },
  inputOutlineGrey: daylightColors.inputOutlineGrey,
  disabledInputGrey: daylightColors.disabledInputGrey,
  selectedPink: daylightColors.selectedPink,
  deselectedGrey: daylightColors.deselectedGrey,
  mediumGrey: daylightColors.mediumGrey,
  deselectedSelectOption: daylightColors.deselectedSelectOption,
};

const controls: ThemeValuesType['controls'] & {
  paddingVerticalHintErrorWarning: number;
  fontSizeHintErrorWarning: number;
} = {
  player: {
    smallTitleTextWeight: 500,
    color: color.primaryDark,
    iconColor: color.primaryDark,
    backgroundColor: color.primaryLight,
    progressColor: color.primary,
    progressColorDim: color.inputOutlineGrey,
    captionIconButton: {
      [CaptionState.OFF]: {
        color: color.primaryGrey,
      },
      [CaptionState.ON]: {
        color: color.disabledInputGrey,
        backgroundColor: color.primaryGrey,
      },
    },
    captionSelect: {
      backgroundColor: color.primaryDark,
      color: color.primaryLight,
      colorDim: color.deselectedSelectOption,
    },
  },
  closeButton: {
    iconSize: 16, // currently only used in Sleepio Web VIA NotificationCenter.tsx
    showText: true,
    iconColor: daylightColors.placeholderText,
    textColor: daylightColors.black,
    backgroundColor: daylightColors.white,
  },
  color: daylightColors.text,
  backgroundColor: daylightColors.deselectedGrey,
  borderColor: daylightColors.inputOutlineGrey,
  borderRadius: 8,
  borderWidth: '1px',
  borderStyle: 'solid',
  paddingHorizontal: 15,
  paddingHorizontalLarge: 25,
  paddingVertical: 10,
  paddingVerticalHintErrorWarning: 0,
  paddingVerticalLarge: 20,
  fontSize: 18,
  fontSizeHintErrorWarning: 16,
  fontSizeLarge: 20,
  opacity: 1,
  ctaMinWidth: 220,
  ctaMaxWidth: 343,
  ctaFixedWidth: 220,
  isDisabled: {
    opacity: 0.4,
  },
  selection: {
    color: daylightColors.text,
    backgroundColor: daylightColors.deselectedGrey,
    borderColor: daylightColors.inputOutlineGrey,
    quiz: {
      [QuizAttribute.CORRECT]: {
        color: daylightColors.text,
        backgroundColor: '008769',
        borderColor: '008769',
      },
      [QuizAttribute.INCORRECT]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.error,
        borderColor: daylightColors.error,
      },
    },
    default: {
      [ControlAttrs.isPressing]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.selectedPink,
        borderColor: daylightColors.selectedPink,
      },
      [ControlAttrs.isActive]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.deselectedGrey,
        borderColor: daylightColors.inputOutlineGrey,
        border: `1px solid ${daylightColors.inputOutlineGrey}`,
      },
      [ControlAttrs.isSelected]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.selectedPink,
        borderColor: daylightColors.selectedPink,
      },
      [ControlAttrs.highlight]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.selectedPink,
        borderColor: daylightColors.selectedPink,
      },
      [ControlAttrs.highlightAndIsSelected]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.selectedPink,
        borderColor: daylightColors.selectedPink,
      },
      [ControlAttrs.isHovering]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.selectedPink,
        borderColor: daylightColors.selectedPink,
      },
    },
  },
  input: {
    color: daylightColors.text,
    backgroundColor: daylightColors.disabledInputGrey,
    borderColor: daylightColors.inputOutlineGrey,
    quiz: {
      [QuizAttribute.CORRECT]: {
        color: daylightColors.text,
        backgroundColor: '008769',
        borderColor: '008769',
      },
      [QuizAttribute.INCORRECT]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.error,
        borderColor: daylightColors.error,
      },
    },
    default: {
      [ControlAttrs.isPressing]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.selectedPink,
        borderColor: daylightColors.selectedPink,
        editColor: daylightColors.disabledInputGrey,
      },
      [ControlAttrs.isActive]: {
        color: daylightColors.placeholderText,
        backgroundColor: daylightColors.disabledInputGrey,
        borderColor: daylightColors.inputOutlineGrey,
        border: `1px solid ${daylightColors.inputOutlineGrey}`,
        editColor: daylightColors.disabledInputGrey,
      },
      [ControlAttrs.isSelected]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.disabledInputGrey,
        borderColor: daylightColors.selectedPink,
        editColor: daylightColors.disabledInputGrey,
      },
      [ControlAttrs.highlight]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.disabledInputGrey,
        borderColor: daylightColors.selectedPink,
        editColor: daylightColors.disabledInputGrey,
      },
      [ControlAttrs.highlightAndIsSelected]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.disabledInputGrey,
        borderColor: daylightColors.selectedPink,
        editColor: daylightColors.disabledInputGrey,
      },
      [ControlAttrs.isHovering]: {
        color: daylightColors.text,
        backgroundColor: daylightColors.selectedPink,
        borderColor: daylightColors.selectedPink,
        editColor: daylightColors.selectedPink,
      },
    },
  },
  textArea: {
    textInputStyles: {
      backgroundColor: daylightColors.disabledInputGrey,
      borderColor: daylightColors.inputOutlineGrey,
      borderRadius: 8,
      borderWidth: 1,
      color: daylightColors.text,
      fontSize: 16,
      height: 171,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
      fontStyle: 'normal' as TextStyle['fontStyle'],
    },
    placeholderColor: daylightColors.placeholderText,
    placeholderFontStyle: 'italic' as TextStyle['fontStyle'],
    outlineBorderRadius: 12,
    outlineColor: daylightColors.selectedPink,
    outlineWidth: 4,
    states: {
      [TextAreaStates.ACTIVE]: {
        borderColor: daylightColors.orange,
        backgroundColor: daylightColors.white,
      },
    },
  },
};

const daylightValues: ThemeValuesType = {
  color: color,
  controls: controls,
  // TODO: re-design an unify 'modal' with 'controls'
  // WHEN: there is bandwidth
  modal: {
    // NOTE: All these values are relative to the maxWidthPx value
    modalReferenceDimension: 414,
    maxWidthPx: 414,
    minWidthPx: 320,
    modalVerticalPaddingPx: 52,
    modalHorizontalPaddingPx: 24,
    modalBreakpointMarginRightPx: 18,
    modalBorderRadiusPx: 4,
    largeScreenBreakpointPx: 1024,
    justifyWithCloseButton: false,
    fadeOutCloseButton: true,
    input: {
      height: 80,
      caretSize: 40,
      iconSize: 24,
      inputIconMarginRight: 9,
      borderRadius: 8,
      paddingBottom: 28,
      paddingRight: 16,
      paddingLeft: 16,
      paddingTop: 28,
      fontSize: 18,
      inputEditFontSize: 16,
      labelFontSize: 16,
      inputMarginBottom: 16,
      inputPaddingRightExtra: 69,
      inputScaleLabelFontSize: 16,
      inputScaleLabelMargin: 18,
      fontStyle: 'normal',
      dateTimePicker: {
        displayFormat: {
          time12h: DaylightDatePickerDisplayFormat.TIME_FORMAT_12H,
          time24h: DaylightDatePickerDisplayFormat.TIME_FORMAT_24H,
          date: DaylightDatePickerDisplayFormat.DATE_FORMAT,
          dateTime12h: DaylightDatePickerDisplayFormat.DATE_TIME_FORMAT_12H,
          dateTime24h: DaylightDatePickerDisplayFormat.DATE_TIME_FORMAT_24H,
        },
        placeholder: 'Select a time',
        showCaret: false,
        showBorder: true,
        doneTextColor: '#007AFF',
        placeholderAlign: GenericInputButtonAlign.center,
        setDefaultMinDate: true,
        textColor: daylightColors.black,
      },
      progressIndicator: {
        textColor: daylightColors.mediumGrey,
      },
    },
    question: {
      modalQuestionTitleFontSizePx: 21,
      modalQuestionTitleFontWeight: '700',
      modalQuestionTitleHeightPx: 26,
      modalQuestionTitleMarginBottomPx: 24,
      modalQuestionPretextFontSizePx: 16,
      modalQuestionPretextHeightPx: 19,
      modalQuestionPretextMarginBottomPx: 4,
      modalQuestionMarginBottom: 30,
    },
  },
  bottomNavigation: {
    backgroundColor: daylightColors.white,
    textColor: daylightColors.grey,
    textHighlight: daylightColors.orange,
    tabItemTextSize: TabItemTextSize.LARGE,
    tabItemSizeType: TabItemFlexType.NO_FLEX,
  },
  heading: {
    [HeadingLevel.LEVEL1]: { fontSize: 38, paddingBottom: 13 },
    [HeadingLevel.LEVEL2]: { fontSize: 30, paddingBottom: 13 },
    [HeadingLevel.LEVEL3]: { fontSize: 27, paddingBottom: 13 },
    [HeadingLevel.LEVEL4]: { fontSize: 24, paddingBottom: 13 },
    [HeadingLevel.LEVEL5]: { fontSize: 20, paddingBottom: 13 },
    [HeadingLevel.LEVEL6]: { fontSize: 16, paddingBottom: 13 },
  },
  text: {
    fontFamily: 'inherit',
    nativeFontFamily: FontFamilies.Medium,
    mono: {
      fontFamily: 'monospace',
    },
    [TextTypes.DEFAULT]: {
      color: color.text.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSize,
      fontWeight: '500',
    },
    [TextTypes.ERROR]: {
      color: color.error,
      paddingVertical: controls.paddingVerticalHintErrorWarning,
      fontSize: controls.fontSizeHintErrorWarning,
      fontWeight: '500',
    },
    [TextTypes.WARNING]: {
      color: color.error,
      paddingVertical: controls.paddingVerticalHintErrorWarning,
      fontSize: controls.fontSizeHintErrorWarning,
      fontWeight: '500',
    },
    [TextTypes.HINT]: {
      color: color.hint,
      paddingVertical: controls.paddingVerticalHintErrorWarning,
      fontSize: controls.fontSizeHintErrorWarning,
      fontWeight: '500',
    },
    [TextTypes.SUCCESS]: {
      color: color.success,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSize,
      fontWeight: '500',
    },
    [TextTypes.CONTENT]: {
      color: color.text.primary,
      paddingHorizontal: 0,
      paddingVertical: 0,
      fontSize: controls.fontSize,
      fontWeight: '500',
    },
  },
  footer: {
    [FooterTheme.Light]: {
      color: daylightColors.orange,
      backgroundColor: daylightColors.white,
    },
    [FooterTheme.Dark]: {
      color: daylightColors.white,
      backgroundColor: daylightColors.orange,
    },
  },
  buttons: {
    dimensions: {
      [ButtonSizes.Small]: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 64,
        paddingRight: 64,
        minWidth: 250,
        borderRadius: 88,
        fontSize: 18,
        lineHeight: 24,
        minHeight: 24 + 16 + 16,
        borderWidth: 2,
      },
    },
    [ButtonColorSchemes.Standard]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.Primary]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.AltPrimary]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.PrimaryOutline]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.PrimaryOnDark]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.Login]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.AltLogin]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.Facebook]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.Google]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.MultiFactorAuthentication]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.ProgressReview]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.Link]: {
      default: {
        color: '#000000',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      disabled: {
        color: '#111111',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      pressed: {
        color: '#00000066',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      hover: {
        color: '#00000066',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
    [ButtonColorSchemes.LinkInverse]: buttonPrimaryConfiguration,
    [ButtonColorSchemes.LinkMidBlue]: {
      default: {
        color: '#000000',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      disabled: {
        color: '#111111',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      pressed: {
        color: '#00000066',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      hover: {
        color: '#00000066',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
    [ButtonColorSchemes.Secondary]: {
      default: {
        color: daylightColors.text,
        backgroundColor: daylightColors.white,
        borderColor: 'transparent',
      },
      disabled: {
        color: daylightColors.text,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        borderColor: 'transparent',
      },
      pressed: {
        color: daylightColors.text,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderColor: 'transparent',
      },
      hover: {
        color: daylightColors.text,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderColor: 'transparent',
      },
    },
  },
  loadingOverlay: {
    [ThemeMode.Dark]: {
      overlay: {
        opacity: 0.7,
        backgroundColor: daylightColors.black,
        zIndex: 99,
      },
      throbber: {
        size: 48,
        fill: daylightColors.white,
        duration: 600,
      },
    },
    [ThemeMode.Light]: {
      overlay: {
        opacity: 0.7,
        backgroundColor: daylightColors.white,
      },
      throbber: {
        size: 48,
        fill: daylightColors.grey,
        duration: 600,
      },
    },
  },
};

const daylightTheme = createTheme(daylightValues);

export { daylightTheme };
