import { hasMetConditions } from './utils/hasMetConditions';
import { isEqualRedirection } from './utils/isEqualRedirection';
import {
  Redirection,
  RedirectorAction,
  RedirectorActionTypes,
} from './actions';

export interface RedirectorState {
  pending: Redirection | null;
  queued: Redirection[];
}

export const initialRedirectorState: RedirectorState = {
  pending: null,
  queued: [],
};

export const redirectorReducer = (
  state = initialRedirectorState,
  action: RedirectorAction
): RedirectorState => {
  switch (action.type) {
    case RedirectorActionTypes.PUSH:
      return {
        ...state,
        queued: [...state.queued, action.payload],
      };

    case RedirectorActionTypes.REMOVE_ALL:
      return {
        ...state,
        queued: [],
      };

    case RedirectorActionTypes.REDIRECTION_COMPLETE: {
      if (!state.pending) {
        throw Error('Redirected, but pending is empty');
      }
      return {
        ...state,
        pending: null,
      };
    }

    case RedirectorActionTypes.ROUTE_CHANGED: {
      if (!state.pending) {
        action.type;
        const pending = state.queued.find(hasMetConditions(action.payload));
        if (pending) {
          const queued = state.queued.filter(
            el => !isEqualRedirection(pending)(el)
          );
          if (
            queued.length != 0 && // Should only happen in dev
            queued.length !== state.queued.length - 1
          ) {
            throw Error(
              `Expected queued length to have shrunk by one after ${JSON.stringify(
                pending
              )} in ${JSON.stringify(queued)}`
            );
          }

          return {
            ...state,
            pending,
            queued,
          };
        }
      }
      return state; // no change
    }
    /**
     * Info: userInfo is reset by action "state/reset"
     */
    default:
      return state;
  }
};
