import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equals } from 'ramda';

import { useHistory } from 'cross-platform/react-router';
import {
  useMutationAuthPoll,
  useQueryGetUserInfo,
} from 'lib/api/reactQueryHelpers';
import { useInterval } from 'lib/hooks';
import { setUser } from 'lib/reporter';
import { clearUser } from 'lib/reporter/clearUser';
import { setUserInfo } from 'state/user/actions';
import { getUserInfo } from 'state/user/selectors';

import { ThrobberBehavior } from '../utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';

const POLL_INTERVAL_SECONDS = 60;

export const useInitUserInfo = (): void => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Info: userInfo is reset by action "state/reset"
  const userInfo = useSelector(getUserInfo);
  const { mutate: authPoll } = useMutationAuthPoll();

  useInterval(
    async () => {
      authPoll();
    },
    // Only poll if user is authenticated
    userInfo && userInfo?.uuid.length > 0 ? POLL_INTERVAL_SECONDS * 1000 : null
  );

  // Set user info in reporter (DataDog, Sentry)
  useEffect(() => {
    if (typeof userInfo?.uuid === 'string' && userInfo.uuid.length > 0) {
      setUser(userInfo?.uuid || '');
    } else {
      clearUser();
    }
  }, [userInfo]);

  const { data, refetch } = useQueryGetUserInfo({
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });

  useEffect(() => {
    if (typeof data?.result === 'undefined') {
      refetch(); // fires even if useQueryGetUserInfo is requesting
    } else if (!equals(userInfo, data?.result)) {
      dispatch(setUserInfo(data?.result));
    }
  }, [data, dispatch, history.location, refetch, userInfo]);
};
