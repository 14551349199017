export const WRAPPER_STYLING = `position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;`;

export const IS_WRAPPER_VISIBLE_DISPLAY = 'initial';

export const OVERLAY_TINT = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const PANEL = `
  flex-grow: 0;
  height: 672px;
  max-height: 100%;
  width: 555px;
  display: flex;
  margin-top: 54px;
  margin-bottom: 104px;
  align-self: center;
  `;

export const MFA_CONTENT_WRAPPER_PADDING_TOP = 36;

export const CONTINUE_BUTTON_VIEW_PADDING_TOP = `36px`;

export const COPY_TEXT_BLOCK_PADDING_TOP = `48px`;

export const ERROR_WRAPPER_PADDING_BOTTOM = `48px`;

export const DISPLAY_QR = `
  display: flex;
`;
