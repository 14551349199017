import { format, parseISO } from 'date-fns';

export const generateTooltipData = (
  tittle?: string,
  values?: [string?, string?, string?, string?],
  buttonAction: () => void = () => null
) => ({
  title: tittle,
  content: [
    {
      key: 'Sleep efficiency',
      value: values?.[0] || 'Loading ...',
    },
    {
      key: 'Time in Bed',
      value: values?.[1] || 'Loading ...',
    },
    {
      key: 'Time Asleep',
      value: values?.[2] || 'Loading ...',
    },
    {
      key: 'Sleep Quality',
      value: values?.[3] || 'Loading ...',
    },
  ],
  buttonText: 'Edit diary',
  buttonAction: buttonAction,
});

export const timeFormatter = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours} hrs ${remainingMinutes} min`;
};

export const tittleDateFormat = (date: string) => {
  const isoDate = parseISO(date);
  const formattedDate = format(isoDate, 'EEEE MMMM d');

  return formattedDate;
};
