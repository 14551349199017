import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { get_user_info } from '@bighealth/api/UserAccount/v1';

export enum UserActionTypes {
  SET_IS_FIRST_LOGIN = 'user/SET_IS_FIRST_LOGIN',
  SET_USER_INFO = 'user/SET_USER_INFO',
  CONFIRM_USER_INFO = 'user/CONFIRM_USER_INFO',
  ASSIGN_USER_AB_TEST_VARIANTS = 'user/ASSIGN_USER_AB_TEST_VARIANTS',
  RESET_USER_INFO = 'user/RESET_USER_INFO',
  SET_IS_FIRST_INIT = 'user/SET_IS_FIRST_INIT',
}

type SetIsFirstLoginAction = {
  type: UserActionTypes.SET_IS_FIRST_LOGIN;
  payload: boolean;
};

export type SetUserInfoAction = {
  type: UserActionTypes.SET_USER_INFO;
  payload: get_user_info.Result;
};

type SetUserABTestVariantsAction = {
  type: UserActionTypes.ASSIGN_USER_AB_TEST_VARIANTS;
  payload: get_user_session_screen_data.Result['ab_test_variants'];
};

type SetIsFirstInitAction = {
  type: UserActionTypes.SET_IS_FIRST_INIT;
  payload: boolean;
};
export type UserAction =
  | SetIsFirstLoginAction
  | SetUserInfoAction
  | SetUserABTestVariantsAction
  | SetIsFirstInitAction;

export const setIsFirstLogin = (
  isFirstLogin: boolean
): SetIsFirstLoginAction => ({
  type: UserActionTypes.SET_IS_FIRST_LOGIN,
  payload: isFirstLogin,
});

export const setUserInfo = (
  userInfo: get_user_info.Result
): SetUserInfoAction => ({
  type: UserActionTypes.SET_USER_INFO,
  payload: userInfo,
});

export const assignUserABTestVariants = (
  userABTestVariants?: get_user_session_screen_data.Result['ab_test_variants']
): SetUserABTestVariantsAction => ({
  type: UserActionTypes.ASSIGN_USER_AB_TEST_VARIANTS,
  payload: userABTestVariants,
});

export const setIsFirstInit = (isFirstInit: boolean): SetIsFirstInitAction => ({
  type: UserActionTypes.SET_IS_FIRST_INIT,
  payload: isFirstInit,
});
