import React, { ReactElement } from 'react';
import Svg, { G, Polygon } from 'react-native-svg';
import styled from 'styled-components/native';

import { IconStyles } from './types';

const Container = styled.View<{ pointerEvents?: 'none' }>``;

export const DropdownCaret = ({
  size,
  style,
  pointerEvents,
  accessible = true,
}: {
  size: number;
  style?: IconStyles;
  pointerEvents?: 'none';
  accessible?: boolean;
}): ReactElement => {
  return (
    <Container
      pointerEvents={pointerEvents}
      accessible={accessible}
      aria-hidden={!accessible}
    >
      <Svg width={size} height={size} viewBox="0 0 40 40" style={style}>
        <G id="Symbols" stroke="none" fill="currentColor">
          <G>
            <Polygon
              fill={style?.color || 'currentColor'}
              points="24.6 16 20 20.6 15.4 16 14 17.4 20 23.4 26 17.4"
            />
          </G>
        </G>
      </Svg>
    </Container>
  );
};
