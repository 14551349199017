import { APIRequestBody, APIResponse, APIResultValues } from '../types';
import { APIClient } from '../client';
import { AxiosRequestConfig } from 'axios';

export async function api<V extends APIResultValues>(
  request: APIRequestBody,
  requestConfig?: AxiosRequestConfig
): Promise<APIResponse<V>> {
  return await APIClient.fetch(request, requestConfig);
}
