import { get_assigned_program_with_user_and_product } from '@bighealth/api/UserAccountAuthorization/v1';

import { queryClientProduct } from './queryClientProduct';
import { queryClientProgram } from './queryClientProgram';

export const queryClientProgramWithProduct = async ({
  product_id,
  product_reference,
}: {
  product_id?: number;
  product_reference: string;
}): Promise<get_assigned_program_with_user_and_product.Response> => {
  let foundId: number;
  if (typeof product_id === 'number') {
    foundId = product_id;
  } else {
    const productResult = await queryClientProduct({
      product_reference,
    });
    foundId = productResult.result.id;
  }
  const programResult = await queryClientProgram({
    product_id: foundId as number,
  });
  return programResult;
};
