import { useCallback } from 'react';

import { useGetScalingContext } from 'components/ResponsiveLayout/hooks/useGetScalingContext';
import { useGetScreenDimensions } from 'components/ResponsiveLayout/hooks/useGetScreenDimensions';
import { useScaleToModal } from 'components/ResponsiveLayout/hooks/useScaleToModal';
import { ScalingContext } from 'components/ResponsiveLayout/providers/ScalingContextProvider';

// width of the iPhone SE
// Use SMALL_WIDTH if figma mobile designs are for a 375px screen width so that the ratios will dynamically increase based on user's phone widths
const SMALL_WIDTH = 375;
// width of the iPhone 11 Pro as Daylight reference
const DEFAULT_WIDTH = 414;

/**
 * We use this other hook for responsiveness in Daylight because
 * existing FE responsiveness refers to Web as default width for minimum reference
 */
export const useTransformContentStyle = (
  isSmallerScreenWidth?: boolean
): ((dimension: number) => number) => {
  const scalingContext = useGetScalingContext();
  const scaleToModal = useScaleToModal();
  const { screenWidth } = useGetScreenDimensions();
  const width = isSmallerScreenWidth ? SMALL_WIDTH : DEFAULT_WIDTH;

  return useCallback(
    (dimension: number) => {
      if (scalingContext === ScalingContext.Modal) {
        return scaleToModal(dimension);
      }

      if (isSmallerScreenWidth || screenWidth < width) {
        return (dimension * screenWidth) / width;
      }
      return dimension;
    },
    [scaleToModal, scalingContext, screenWidth, width, isSmallerScreenWidth]
  );
};
