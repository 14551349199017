import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { Text as TextUnstyled } from 'components/sceneset-components/Text';
import { HeadingLevel } from 'config/getThemeForProducts/utils/createTheme/headingSchemesTypes';
import { getUserInfo } from 'state/user/selectors';

const DOMAIN = 'UserInfo';

const Container = styled.View`
  margin-bottom: 20px;
  flex-direction: column;
`;
Container.displayName = `${DOMAIN}.Container`;

const Text = styled(TextUnstyled)`
  color: ${props => props.theme.color.text.primaryInverse};
`;
Text.displayName = `${DOMAIN}.Text`;

const Heading = styled(TextUnstyled)`
  color: ${props => props.theme.color.text.primaryInverse};
  ${props => props.theme.heading[HeadingLevel.LEVEL6]};
  font-weight: bold;
`;
Heading.displayName = `${DOMAIN}.Heading`;

export const UserInfo = (): ReactElement => {
  const userInfo = useSelector(getUserInfo);

  return (
    <Container>
      <Heading text={'User Info:'} />
      {typeof userInfo === 'undefined' ? (
        <Text text={'Not found'} />
      ) : (
        Object.entries(userInfo || {}).map(([key, value]) => (
          <Text key={key} text={`${key}: ${value}`} />
        ))
      )}
    </Container>
  );
};
