import { NotificationUserSetting } from '../reducer';
import { UserSettingChangeType } from '../types';

/**
 * Given two state excerpts, determine the type of change from prev to curr
 *
 * @param {NotificationUserSetting} prev - previous notification state
 * @param {NotificationUserSetting} curr - current notification state
 * @returns {UserSettingChangeType} UserSettingChangeType
 */
export const detectUserSettingChangeType = (
  prev: NotificationUserSetting,
  curr: NotificationUserSetting
): UserSettingChangeType => {
  if (prev.id !== curr.id) {
    // this is an error state
    return UserSettingChangeType.NO_CHANGE;
  } else if (
    prev.isOn === curr.isOn &&
    prev.timeOfDayHours24 === curr.timeOfDayHours24 &&
    prev.timeOfDayMinutes === curr.timeOfDayMinutes
  ) {
    return UserSettingChangeType.NO_CHANGE;
  } else if (prev.isOn === true && curr.isOn === false) {
    return UserSettingChangeType.TO_OFF;
  } else if (prev.isOn === false && curr.isOn === true) {
    return UserSettingChangeType.TO_ON;
  } else {
    return UserSettingChangeType.CHANGE_TIME_ONLY;
  }
};
