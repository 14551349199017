import { APIRequestBody, APIResponse } from '../../types';
import {
  ButtonResourceEvent,
  NotificationResourceEvent,
  ScreenResourceEvent,
} from '@bighealth/analytics';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const fire_ui_events = async (
  args: fire_ui_events_types.Args,
  requestConfig?: AxiosRequestConfig
): Promise<fire_ui_events_types.Response> => {
  const request: fire_ui_events_types.Request = {
    service_name: 'Event',
    service_version: '1',
    service_method: 'fire_ui_events',
    args,
  };

  return await api(request, requestConfig);
};

fire_ui_events.queryKey = 'Analytics/fire_ui_events';

export declare namespace fire_ui_events_types {
  export type Args = {
    events: (
      | ScreenResourceEvent
      | ButtonResourceEvent
      | NotificationResourceEvent
    )[];
  };

  export interface Request extends APIRequestBody {
    service_name: 'Event'; // AKA event-service-cluster
    service_version: '1';
    service_method: 'fire_ui_events';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
