import { createTabIcon } from 'components/Navigation/BottomNavigation/helpers/createTabIcon';
import { TabItemData } from 'components/Navigation/BottomNavigation/types';
import { ProgressIcon, TodayIcon } from 'daylight/components/icons';

export const daylightTabItems: TabItemData[] = [
  {
    text: 'Today',
    screenRoute: 'home',
    Icon: createTabIcon({ Icon: TodayIcon, displayName: 'TodayIcon' }),
    iconSize: 32,
  },
  {
    text: 'Progress',
    screenRoute: 'progress',
    Icon: createTabIcon({ Icon: ProgressIcon, displayName: 'ProgressIcon' }),
    iconSize: 32,
  },
];
