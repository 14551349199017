import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';

import { DropdownCaret } from 'components/icons';
import { SleepioLogoWhite } from 'components/icons';
import Link from 'components/Link';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { useGetPageDimensions } from 'components/Screens/ContentScreens';
import SessionAvailabilityIndicator from 'components/SessionAvailabilityIndicator';
import { Text } from 'components/Text';
import { useHistory } from 'cross-platform/react-router';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { openURL } from 'lib/navigation/openURL';

import { NavBarStyles } from './constants';
import { ExpandableNavItem } from './ExpandableNavItem';
import { LogoButton } from './LogoButton';
import {
  isCategoryNavItem,
  isLinkNavItem,
  isPressableNavItem,
  NavEntry,
  NavItemDisplayType,
} from './types';
import { useGetNavigationConfig } from './useGetNavigationConfig';
import { WindowAwareMenu } from './WindowAwareMenu';

const StyledNavBar = styled.View`
  width: 100%;
  background-color: ${props => props.theme.color.overlay.primary};
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: ${NavBarStyles.navBarZIndex} !important;
`;
StyledNavBar.displayName = 'StyledNavBar';

type ViewProps = {
  children?: ReactNode;
  style?: ViewStyle;
} & RoleProps;

const NavBar = (props: ViewProps): ReactElement => {
  const safeArea = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = {
    height: NavBarStyles.navBarHeightPx + safeArea.top,
    ...StyleSheet.flatten(style),
  };
  return <StyledNavBar style={newStyle} {...restProps} />;
};

const NavBarInner = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const PressableNavItem = styled.TouchableOpacity``;

const Label = styled(Text).attrs({ fontWeight: 400 })<{
  type?: NavItemDisplayType;
  fontWeight?: number;
}>`
  font-weight: 400;
  font-size: 20px;
  color: ${({ type }) => (type === 'subcategory' ? '#bfe2f0' : 'white')};
`;

const ItemIconContainer = styled.View<{ isDropdown?: boolean }>`
  margin-top: ${({ isDropdown }) => (isDropdown ? '7px' : 0)};
  margin-bottom: ${({ isDropdown }) => (isDropdown ? '7px' : 0)};
  margin-left: ${({ isDropdown }) => (isDropdown ? 0 : '48px')};
  flex-direction: row;
  align-items: center;
`;

const ItemContainer = styled.View<{ isDropdown?: boolean }>`
  margin-top: ${({ isDropdown }) => (isDropdown ? '7px' : 0)};
  margin-bottom: ${({ isDropdown }) => (isDropdown ? '7px' : 0)};
  margin-left: ${({ isDropdown }) => (isDropdown ? 0 : '48px')};
`;

const Row = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// The dropdown caret is much wider than appears on the screen so we crop i
const DropdownCaretContainer = styled.View`
  width: 28px;
  overflow: hidden;
`;

const LogoContainer = styled.View`
  margin-right: auto;
`;

const SessionIcon = (): ReactElement => {
  return (
    <View style={{ paddingLeft: 10 }}>
      <SessionAvailabilityIndicator iconSize={11} />
    </View>
  );
};

export type ActiveMenu = {
  label: string;
  position: {
    left: number;
    top: number;
  };
  items: NavEntry[];
};

export const ExpandedNav = (): ReactElement => {
  const config = useGetNavigationConfig();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState<ActiveMenu | null>(null);
  const { pagePaddingRight, pagePaddingLeft } = useGetDynamicContentStyles();

  const { pageContentWidth } = useGetPageDimensions();

  const history = useHistory();
  const path = history.location.pathname;
  const cachedPath = useRef(path);
  const safeArea = useSafeArea();

  useEffect(() => {
    if (path !== cachedPath.current) {
      // Lazy way of dismissing the menu without having to intercept click events on links etc
      setMenu(null);
      cachedPath.current = path;
    }
  }, [dispatch, path]);

  function generateItems(
    items: NavEntry[],
    isDropdown?: boolean
  ): (ReactElement | null)[] {
    return items
      .map((item): ReactElement | null => {
        const { label } = item;
        if (isPressableNavItem(item)) {
          return (
            <ItemContainer key={label} isDropdown={isDropdown}>
              <PressableNavItem
                onPress={item.onPress}
                testID={`Pressable-${label}`}
              >
                <Label type={item.type}>{label}</Label>
              </PressableNavItem>
            </ItemContainer>
          );
        } else if (isLinkNavItem(item)) {
          if (item.label === 'Sessions') {
            return (
              <ItemIconContainer key={label}>
                <Link
                  to={item.to}
                  title={`Go To ${label}`}
                  // Annoyingly testing-library/react-native can't select links via title property
                  // so we use testID instead
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  {...roles(`Go To ${label}`)}
                >
                  <Label type={item.type}>{label}</Label>
                </Link>
                <SessionIcon />
              </ItemIconContainer>
            );
          }

          return (
            <ItemContainer key={label} isDropdown={isDropdown}>
              {item.external ? (
                <Label type={item.type} onPress={() => openURL(item.to)}>
                  {label}
                </Label>
              ) : (
                <Link
                  to={item.to}
                  title={`Go To ${label}`}
                  // Annoyingly testing-library/react-native can't select links via title property
                  // so we use testID instead
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  {...roles(`Go To ${label}`)}
                >
                  <Label type={item.type}>{label}</Label>
                </Link>
              )}
            </ItemContainer>
          );
        } else if (isCategoryNavItem(item)) {
          return (
            <ItemContainer key={label} isDropdown={isDropdown}>
              <ExpandableNavItem
                testID={`Pressable-${label}`}
                isOpen={!!menu}
                onDismiss={() => {
                  setMenu(null);
                }}
                onOpen={dimensions => {
                  const { pageX, locationX } = dimensions;
                  if (menu?.label !== label) {
                    setMenu({
                      label,
                      position: {
                        left: pageX - locationX, // Keep it pinned to the start of the element
                        top:
                          NavBarStyles.navBarHeightPx -
                          NavBarStyles.navBarMenuVerticalOffsetPx -
                          safeArea.top,
                      },
                      items: item.subMenu,
                    });
                  }
                }}
              >
                <Row>
                  <Label type={'category'}>{label}</Label>
                  <DropdownCaretContainer>
                    <DropdownCaret size={40} style={{ color: 'white' }} />
                  </DropdownCaretContainer>
                </Row>
              </ExpandableNavItem>
            </ItemContainer>
          );
        }
        return null;
      })
      .filter(Boolean);
  }
  const items = config ? generateItems(config) : null;

  return (
    <>
      <NavBar
        testID={'ExpandedNav'}
        style={{
          paddingLeft: pagePaddingLeft,
          paddingRight: pagePaddingRight,
        }}
      >
        <NavBarInner
          style={{
            width: pageContentWidth,
          }}
        >
          <LogoContainer>
            <LogoButton>
              <SleepioLogoWhite height={39} />
            </LogoButton>
          </LogoContainer>
          {items}
        </NavBarInner>
      </NavBar>
      {menu ? (
        <WindowAwareMenu
          onDismiss={() => {
            setMenu(null);
          }}
          targetPosition={{
            top: menu.position.top,
            left: menu.position.left,
          }}
        >
          {generateItems(menu.items, true)}
        </WindowAwareMenu>
      ) : null}
    </>
  );
};
