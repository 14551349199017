import styled from 'styled-components/native';

import { Heading2, Heading5 } from 'components/Heading';
import { Text } from 'components/sceneset-components/Text';

const DOMAIN = 'SleepDiary';

export const Wrapper = styled.View`
  min-height: 200px;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

export const DateLabel = styled(Heading5)``;
DateLabel.displayName = `${DOMAIN}.DateLabel`;

export const HeaderView = styled.View`
  background-color: white;
`;
HeaderView.displayName = `${DOMAIN}.HeaderView`;

export const Heading = styled(Heading2)`
  padding-bottom: 18px;
  padding-top: 26px;
  padding-left: 24px;
`;
Heading.displayName = `${DOMAIN}.Heading`;

export const SectionHeadingView = styled.View`
  border: ${props => `0.7px solid ${props.theme.color.overlay.primary}`};
  background-color: ${props => `${props.theme.color.overlay.primary}`};
`;
SectionHeadingView.displayName = `${DOMAIN}.SectionHeadingView`;

export const SectionHeadingPadding = styled.View`
  padding-bottom: 32px;
`;
SectionHeadingPadding.displayName = `${DOMAIN}.SectionHeadingPadding`;

export const SectionHeading = styled(Heading5)`
  padding-top: 34px;
`;
SectionHeading.displayName = `${DOMAIN}.SectionHeading`;

export const SectionHeadingBottomPadding = styled.View`
  padding-bottom: 11px;
`;
SectionHeadingBottomPadding.displayName = `${DOMAIN}.SectionHeadingBottomPadding`;

export const SectionSubHeading = styled(Text)`
  padding-bottom: 24px;
  font-size: 18px;
  color: ${props => `${props.theme.color.overlay.primary}`};
`;
SectionSubHeading.displayName = `${DOMAIN}.SectionHeading`;

export const SectionWrapper = styled.View``;
SectionWrapper.displayName = `${DOMAIN}.SectionWrapper`;
