import React, { PropsWithChildren } from 'react';
import { TextProps } from 'react-native';

import { TextTypes } from 'common/constants/enums';

import HelperText from '../HelperText';

const WarningText = (props: PropsWithChildren<TextProps>) => (
  <HelperText presentationType={TextTypes.WARNING} {...props} />
);

export { WarningText };
