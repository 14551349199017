import React, { ReactElement } from 'react';
import { View, ViewProps } from 'react-native';

import { NavBarStyles } from 'components/Navigation/constants';
import { useGetScreenDimensions } from 'components/ResponsiveLayout/hooks/useGetScreenDimensions';

import { useContentCloseButtonStyles } from './hooks/useContentCloseButtonStyles';

// A Wrapper around CloseButton for Player Controls.
//
// Web and native have different styles.
export const CloseButtonContainer = (props: ViewProps): ReactElement => {
  const { screenWidth, screenHeight } = useGetScreenDimensions();
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  const isLandscape = screenWidth > screenHeight;

  const closeButtonPosition = useContentCloseButtonStyles({
    minTop: isMobileWeb ? (isLandscape ? 28 : 10) : 44,
    minRight: isMobileWeb ? (isLandscape ? 24 : 10) : 32.5,
    options: {
      absolutePosition: isMobileWeb && !isLandscape,
    },
  });

  return (
    <View {...props} style={{ position: 'absolute', ...closeButtonPosition }} />
  );
};
