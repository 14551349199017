import * as reporter from 'lib/reporter';

import { OnError } from '../types';

/**
 * *Higher-order component*
 *
 * Called on every error handler used by ErrorBoundary.onError
 *
 * @param onError
 * @returns
 */
const withDefaultErrorHandler = (onError?: OnError) =>
  /**
   * @param error
   * @param info
   */
  (error: Error, info: { componentStack: string }): void => {
    // Common functionality
    reporter.log(`Default error handler ${error.message}`, error, {
      silent: true,
    });
    // Call error handler
    onError?.(error, info);
  };

export { withDefaultErrorHandler };
