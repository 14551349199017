import React from 'react';
import { View } from 'react-native';

import { ProductReferences, ProgramIds } from 'common/constants/enums';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { Text } from 'components/Text';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';

type ProductDebugIcon = '🔆' | '🌙';
type DebugProgramId = '🌸' | number | undefined;

function getProductIcon(productReference: string): ProductDebugIcon {
  switch (productReference) {
    case ProductReferences.DAYLIGHT: {
      return '🔆';
    }
    default: {
      return '🌙';
    }
  }
}

// This function is only here to differentiate
// between Blossom and other programs.
// If (when?) Blossom becomes its own bona-fide
// Product, this function can be removed and
// the Blossom icon can get added as a case in
// the switch inside the getProductIcon function.
function getProgramId(id: number | undefined): DebugProgramId {
  return id === ProgramIds.BLOSSOM ? '🌸' : id;
}

export default function SceneSetDebugInfo(): JSX.Element {
  const {
    productReference,
    sceneId = 0,
    sceneSetGraphId,
    sceneSetId,
  } = useSafeParams();
  const { data } = useQueryProgram();

  const productIcon = getProductIcon(productReference);
  const programId = getProgramId(data?.result.id);

  return (
    <View
      style={{
        position: 'absolute',
        bottom: 35,
        padding: 5,
        paddingLeft: 20,
        backgroundColor: '#000000',
        opacity: 0.7,
      }}
    >
      <Text style={{ color: '#ffffff', fontFamily: 'Courier' }}>
        {`${productIcon} ${programId}: ${sceneSetGraphId}/${sceneSetId}/${sceneId}`}
      </Text>
    </View>
  );
}
