import { Scene } from '@bighealth/types/src/scene-component';
type ToggleVisibilityProps = Scene.Utils.ToggleVisibility;

export const getShouldInitiallyShowFromProps = (
  props: ToggleVisibilityProps
): boolean => {
  const { showAtSeconds, hideAtSeconds } = props;
  if (showAtSeconds === 0) {
    return true;
  }
  if (hideAtSeconds === 0) {
    return false;
  }

  if (
    typeof hideAtSeconds === 'undefined' &&
    typeof showAtSeconds === 'undefined'
  ) {
    return true;
  }
  if (typeof showAtSeconds === 'number' && typeof hideAtSeconds === 'number') {
    if (showAtSeconds < hideAtSeconds) {
      return showAtSeconds === 0;
    }
    return hideAtSeconds !== 0;
  }
  if (typeof showAtSeconds === 'number' && showAtSeconds > 0) {
    return false;
  }
  return typeof hideAtSeconds === 'number' && hideAtSeconds > 0;
};
