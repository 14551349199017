import { NotificationPreviewContent } from './types';

export const PREVIEW_TITLE = 'Stay on track with notifications';

export const PREVIEW_DESCRIPTION =
  'Sleepio users find more success when they see progress. We’re rooting for you!';

export const DOMAIN = 'NotificationsScreen';

export const DEFAULT_PREVIEW_CONTENT: NotificationPreviewContent = {
  title: 'Notifications can help you on your sleep journey',
  description:
    'People who turn on notifications are 30% more successful. You can always turn off notifications later.',
};

export const PRE_SLEEP_DIARY_PREVIEW_CONTENT: NotificationPreviewContent = {
  title: 'Before you go, would you like a reminder to continue with Sleepio?',
  description: 'We know improving sleep can be tough. We are here to help.',
};

export const POST_SLEEP_DIARY_PREVIEW_CONTENT: NotificationPreviewContent = {
  title: 'Get a daily reminder to complete your sleep diary',
  description:
    'Sleepio users find more success when logging their sleep info. It only takes two minutes a day.',
};
