/* eslint-disable camelcase */
import { clone } from 'ramda';

import { Question, SceneSet } from '@bighealth/types';
import { SceneActionTypes } from '@bighealth/types/dist/enums';

import { PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER } from 'config/envVars';
import { getScheduleNextSessionScene } from 'developer/data/utils/getScheduleNextSession';
import { getSleepDiaryWeekScene } from 'developer/data/utils/getSleepDiaryWeekScene';
import { decodeAPITypes } from 'lib/api/decodeAPITypes';
import { transformToJavaScriptDate } from 'lib/api/middleware/response/toJavaScriptDateWithRequest/utils/transformToJavaScriptDate';

import { extendSleepWindowScene } from './utils/extendSleepWindowScene';
import { getSceneForEffectServiceCalls } from './utils/getSceneForEffectServiceCalls';
import { getSceneForProgressGoalsV2 } from './utils/getSceneForProgressGoalsV2';
import { getSceneForSelectHorizontal } from './utils/getSceneForSelectHorizontal';
import { getSceneForSelectHorizontalTime } from './utils/getSceneForSelectHorizontalTime';
import { getSceneForShowTime } from './utils/getSceneForShowTime';
import { getSceneToThrowInModal } from './utils/getSceneToThrowInModal';
import { getSceneToThrowInScene } from './utils/getSceneToThrowInScene';
import { getSceneToThrowInSceneSet } from './utils/getSceneToThrowInSceneSet';
import { getSleepDiaryFormSceneList } from './utils/getSleepDiaryFormSceneList';
import { getSleepDiaryScene } from './utils/getSleepDiaryScene';
import { setSleepWindowScene } from './utils/setSleepWindowScene';

export const DEVELOPER_COMPONENTS_TEST_SCENESET_FROM_API: SceneSet = {
  id: 1,
  component: 'SceneSet',
  session_description: 'Session description',
  session_progress_percent: 25,
  childNodes: [
    // 0
    {
      component: 'Scene',
      childNodes: [
        {
          childNodes: [
            {
              action: {
                type: 'submit',
              },
              childNodes: [],
              component: 'Video',
              delaySeconds: 0,
              fromSeconds: 0,
              scaling: 'fillHeight',
              scene_component_definition_id: '1',
              sourceProps: {
                reference: 'progressReview-goalDeltaGood',
                file_name: 'goalDeltaGoodLocal.mp4',
                source_stage: 'local',
                description: 'None',
                height: 576,
                width: 1024,
                duration: null,
                content_length: null,
                etag: null,
                user: 'luca.giacomelli',
                mime_type: 'video/mp4',
                display_name: 'progressReview-goalDeltaGood',
                primary_key: 83,
              },
              style: {
                height: 0,
                left: 0,
                margin: 0,
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                padding: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                top: 0,
                width: 0,
              },
              toSeconds: 5.65,
            },
          ],
          component: 'MediaContainer',
          mediaProps: {
            height: 576,
            width: 1024,
          },
          scene_component_definition_id: '4',
        },
        {
          childNodes: [],
          component: 'ProgressGoals',
          scene_component_definition_id: '100',
        },
      ],
    },
    // 1
    {
      component: 'Scene',
      childNodes: [
        {
          component: 'MediaContainer',
          mediaProps: {
            height: 576,
            width: 1024,
          },
          childNodes: [
            {
              component: 'Video',
              fromSeconds: 0,
              toSeconds: 10,
              delaySeconds: 0,
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/intro-nosound.mp4',
                height: 576,
                width: 1024,
              },
              scaling: 'fillHeight',
            },
          ],
        },
        {
          childNodes: [
            {
              component: 'OverlayParagraph',
              text: 'Page one',
            },
            {
              component: 'Text',
              text: '200 text',
              style: {
                fontWeight: 200,
              },
            },
            {
              component: 'Text',
              text: '400 text',
              style: {
                fontWeight: 400,
              },
            },
            {
              component: 'Text',
              text: '500 text',
              style: {
                fontWeight: 500,
              },
            },
            {
              component: 'Text',
              text: '700 text',
              style: {
                fontWeight: 700,
              },
            },
            {
              component: 'Text',
              text: '900 text',
              style: {
                fontWeight: 900,
              },
            },
            {
              component: 'Text',
              text: '200 text',
              italic: true,
              style: {
                fontWeight: 200,
              },
            },
            {
              component: 'Text',
              text: '400 text',
              italic: true,
              style: {
                fontWeight: 400,
              },
            },
            {
              component: 'Text',
              text: '500 text',
              italic: true,
              style: {
                fontWeight: 500,
              },
            },
            {
              component: 'Text',
              text: '700 text',
              italic: true,
              style: {
                fontWeight: 700,
              },
            },
            {
              component: 'Text',
              text: '900 text',
              italic: true,
              style: {
                fontWeight: 900,
              },
            },
            {
              component: 'TextInput',
              questionProps: {
                created_utc: { $datetime: '2020-04-30T12:34:52.267738' },
                updated_utc: { $datetime: '2020-05-04T12:31:13.172865' },
                deleted_utc: null,
                id: 3,
                semantic_id: 'first_text_input',
                uuid: 'de83d271-d258-4d21-8828-31ad8b1cb54d',
                language: 'en',
                version: 1,
                question_pre_text: 'Some pre-text',
                question_title: 'I am first_text_input title',
                response_pre_text: null,
                visual_representation_json: {},
                visual_representation_markup: '',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      label: 'Sup',
                      value: '$input',
                      semantic_id: 'first_text_input_1',
                      display_text: 'Hello',
                      max_number_lines: 3,
                      min_characters_limit: 4,
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': {
                        type: 'string',
                        pattern: '^(?:[^\r\n]*(?:\r\n?|\n)){0,2}[^\r\n]*$',
                        minLength: 4,
                      },
                      selections: {
                        type: 'array',
                        maxItems: 1,
                        minItems: 1,
                      },
                    },
                    description: 'answer option values',
                  },
                  max_number_attempts: 1,
                  correct_response_ids: [1],
                  default_response_ids: [1],
                  max_selections_required: 1,
                  min_selections_required: 1,
                },
                response_type: { $ResponseType: 'TEXT' },
                primary_key: 3,
                display_name: '3',
              },
            },
            {
              component: 'NumberInput',
              questionProps: {
                id: 6,
                optional: false,
                question_pre_text: 'I am NumberInput preText',
                question_title: 'I am NumberInput with one entry',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 1,
                  default_response_ids: [1],
                  correct_response_ids: [],
                  max_number_attempts: 1,
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '9': {
                        type: 'number',
                        minimum: 10,
                        exclusiveMaximum: 100,
                      },
                      selections: {
                        type: 'array',
                        maxItems: 1,
                        minItems: 1,
                      },
                    },
                    description: 'answer option values',
                  },
                  response_options: [
                    {
                      id: 9,
                      semantic_id: 'first_number',
                      display_text: 'Hello',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: '$input',
                    },
                  ],
                },
                response_type: 'number',
                semantic_id: '6NumberInput',
              } as Question,
            },
            {
              action: {
                type: SceneActionTypes.SUBMIT,
              },
              align: 'bottom',
              component: 'Button',
              text: "Let's go",
              type: 'SECONDARY_BUTTON',
            },
          ],
          component: 'Modal',
        },
      ],
    },
    // 2
    {
      childNodes: [
        {
          component: 'GenericContainer',
          style: {
            position: 'absolute',
            top: 100,
            left: 100,
          },
          childNodes: [
            {
              component: 'OverlayParagraph',
              text:
                "I'm an OverlayParagraph in a floating GenericContainer, positioned absolute.",
            },
          ],
        },
        {
          childNodes: [
            {
              component: 'OverlayParagraph',
              text: 'Page two',
            },
            {
              action: {
                type: SceneActionTypes.NEXT,
              },
              align: 'bottom',
              component: 'Button',
              text: "Let's go",
              type: 'SECONDARY_BUTTON',
            },
          ],
          component: 'Modal',
        },
      ],
      component: 'Scene',
    },
    // 3
    {
      childNodes: [
        {
          component: 'MediaContainer',
          mediaProps: {
            width: 600,
            height: 1000,
          },
          childNodes: [
            {
              component: 'Star',
              radius: 100,
              value: 50,
              fill: '#EDE275',
              valueFill: 'gold',
              scaling: 'relative',
              stroke: {
                color: 'blue',
                weight: 6,
              },
              style: {
                left: 0,
                top: 200,
              },
            },
            {
              component: 'Rectangle',
              borderRadius: 5,
              scaling: 'relative',
              style: {
                width: 200,
                height: 75,
                top: 50,
                left: 250,
              },
              fill: 'red',
              stroke: {
                color: 'green',
                weight: 6,
              },
            },
            {
              component: 'Circle',
              scaling: 'relative',
              radius: 200,
              fill: 'yellow',
              stroke: {
                color: 'red',
                weight: 6,
              },
            },
          ],
        },
        {
          childNodes: [
            {
              component: 'OverlayParagraph',
              text: 'Page three - Shapes',
            },
            {
              component: 'PieChart',
              fill: 'red',
              radius: 400,
              stroke: {
                weight: 200,
              },
              values: [
                {
                  value: 30,
                  fill: 'blue',
                },
                {
                  value: 20,
                  fill: 'green',
                },
                {
                  value: 50,
                  fill: 'yellow',
                },
              ],
            },
            {
              component: 'Star',
              radius: 100,
              value: 50,
              fill: '#EDE275',
              valueFill: 'gold',
              scaling: 'relative',
              stroke: {
                color: 'blue',
                weight: 6,
              },
            },
            {
              component: 'Rectangle',
              borderRadius: 5,
              scaling: 'relative',
              style: {
                width: 200,
                height: 75,
                top: 50,
                left: 50,
              },
              fill: 'red',
              stroke: {
                color: 'green',
                weight: 6,
              },
            },
            {
              component: 'Circle',
              scaling: 'relative',
              radius: 200,
              fill: 'yellow',
              stroke: {
                color: 'red',
                weight: 6,
              },
            },
            {
              action: {
                type: SceneActionTypes.NEXT,
              },
              align: 'bottom',
              component: 'Button',
              text: "Let's go",
              type: 'SECONDARY_BUTTON',
            },
            {
              component: 'Star',
              radius: 500,
              value: 50,
              fill: 'gold',
              stroke: {
                color: 'blue',
                weight: 6,
              },
            },
            {
              component: 'Rectangle',
              style: {
                width: 200,
                height: 75,
                top: 50,
                left: 50,
              },
              fill: 'yellow',
            },
            {
              component: 'Circle',
              radius: 200,
            },
          ],
          component: 'Modal',
          showBackButton: true,
        },
      ],
      component: 'Scene',
    },
    // 4
    {
      childNodes: [
        {
          childNodes: [
            {
              component: 'TextInput',
              questionProps: {
                id: 2,
                optional: false,
                question_pre_text: 'I am TextInput preText',
                question_title: 'I am TextInput with one entry',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 10,
                  default_response_ids: [],
                  correct_response_ids: [],
                  max_number_attempts: 1,
                  validation_schema: {
                    type: 'object',
                    properties: {
                      [String(1000)]: {
                        type: 'string',
                        minLength: 3,
                      },
                    },
                    required: [String(1000)],
                  },
                  response_options: [
                    {
                      id: 1000,
                      semantic_id: 'foo',
                      display_text: 'Display text',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: '$input',
                    },
                  ],
                },
                response_type: 'text',
                semantic_id: '2TextInput',
              } as Question,
            },
            {
              component: 'DateTimePicker',
              questionProps: {
                id: 3,
                optional: false,
                question_pre_text: 'DateTimePicker question_pre_text',
                question_title: 'DateTimePicker question_title',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 10,
                  default_response_ids: [],
                  correct_response_ids: [],
                  max_number_attempts: 1,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 6,
                      semantic_id: 'foo',
                      display_text: 'Hello',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: '$input',
                    },
                  ],
                },
                response_type: 'datetime',
                semantic_id: '3DateTimePicker',
              } as Question,
            },
            {
              component: 'DatePicker',
              questionProps: {
                id: 4,
                optional: false,
                question_pre_text: 'DatePicker w/min_response NYE-NYD2100 ',
                question_title: 'DatePicker question_title',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 10,
                  default_response_ids: [],
                  correct_response_ids: [],
                  max_number_attempts: 1,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 7,
                      semantic_id: 'foo',
                      display_text: 'Hello',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      min_response: ({
                        $date: '2099-12-31T00:00:00.000Z', // New years EVE, 2099
                      } as unknown) as Date, // WARNING types for post-transformation
                      max_response: ({
                        $date: '2100-01-02T00:00:00.000Z', // New years DAY, 2100
                      } as unknown) as Date, // WARNING types for post-transformation
                      value: '$input',
                    },
                  ],
                },
                response_type: 'date',
                semantic_id: '4DatePicker',
              } as Question,
            },
            {
              component: 'TimePicker',
              questionProps: {
                id: 5,
                optional: false,
                question_pre_text: 'TimePicker question_pre_text',
                question_title: 'TimePicker question_title',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 10,
                  default_response_ids: [],
                  correct_response_ids: [],
                  max_number_attempts: 1,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 8,
                      semantic_id: 'foo',
                      display_text: 'Hello',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: '$input',
                    },
                  ],
                },
                response_type: 'time',
                semantic_id: '5TimePicker',
              } as Question,
            },

            {
              component: 'HorizontalScale',
              questionProps: {
                id: 7,
                optional: false,
                question_pre_text: 'HorizontalScale question_pre_text',
                question_title:
                  'HorizontalScale question_title (2 options & quiz)',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 10,
                  default_response_ids: [],
                  correct_response_ids: [11],
                  max_number_attempts: 3,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 11,
                      semantic_id: 'foo',
                      display_text: 'Correct',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'one',
                    },
                    {
                      id: 12,
                      semantic_id: 'foo',
                      display_text: 'Wrong',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'two',
                    },
                    {
                      id: 13,
                      semantic_id: 'Wrong2',
                      display_text: 'Wrong2',
                      label: 'Wrong2',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'Wrong2',
                    },
                    {
                      id: 14,
                      semantic_id: 'Wrong3',
                      display_text: 'Wrong3',
                      label: 'Wrong3',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'Wrong3',
                    },
                  ],
                },
                response_type: 'number',
                semantic_id: '7HorizontalScale (quiz)',
              } as Question,
            },
            {
              component: 'Dropdown',
              questionProps: {
                id: 8,
                optional: false,
                question_pre_text: 'Dropdown question_pre_text',
                question_title: 'Dropdown (2 options)',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 10,
                  default_response_ids: [],
                  correct_response_ids: [],
                  max_number_attempts: 0,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 13,
                      semantic_id: 'foo',
                      display_text: 'First',
                      label: 'Sup1',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'one',
                    },
                    {
                      id: 14,
                      semantic_id: 'foo',
                      display_text: 'Second',
                      label: 'Sup2',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'two',
                    },
                  ],
                },
                response_type: 'text',
                semantic_id: '8dropdown',
              } as Question,
            },
            {
              component: 'VerticalSelect',
              questionProps: {
                id: 9,
                optional: false,
                question_pre_text: 'question_pre_text',
                question_title: 'VerticalSelect (w/Text input)',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 2,
                  default_response_ids: [],
                  correct_response_ids: [],
                  max_number_attempts: 1,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 1,
                      semantic_id: 'dog',
                      display_text: 'Dog',
                      label: 'Dog',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'dog',
                    },
                    {
                      id: 2,
                      semantic_id: 'cat',
                      display_text: 'Cat',
                      label: 'Cat',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'cat',
                    },
                    {
                      id: 3,
                      semantic_id: 'fish',
                      display_text: 'Fish',
                      label: 'Fish',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'fish',
                    },
                    {
                      id: 4,
                      semantic_id: 'other',
                      display_text: 'Other',
                      label: 'Other',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: '$input',
                    },
                  ],
                },
                response_type: 'text',
                semantic_id: '9VerticalSelect',
              } as Question,
            },

            {
              component: 'DropdownTimePicker',
              questionProps: {
                created_utc: { $datetime: '2020-09-11T04:37:57.874508' },
                updated_utc: { $datetime: '2022-05-20T19:54:51.633956' },
                deleted_utc: null,
                id: 10,
                semantic_id: 'sleep_efficiency_time_get_out_of_bed',
                uuid: 'cd82f378-edbd-40a5-b5f4-27e464cc473c',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'DropdownTimePicker',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 0,
                      value: '$input',
                      semantic_id: '0',
                      max_response: { $time: '05:55:00' },
                      min_response: { $time: '06:00:00' },
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '0': {
                        not: {
                          maximum: 21600000,
                          exclusiveMinimum: 21300000,
                        },
                        type: 'number',
                      },
                      selections: {
                        type: 'array',
                        maxItems: 1,
                        minItems: 1,
                      },
                    },
                    description: 'answer option values',
                    errorMessage: {
                      _: 'Enter a time',
                      properties: {
                        '0': 'Enter a time between 06:00:00 and 05:55:00',
                      },
                    },
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 1,
                },
                response_type: { $ResponseType: 'TIME' },
                display_name: 'sleep_efficiency_time_get_out_of_bed',
                primary_key: 10,
                previous_responses: [],
              },
            },
            {
              component: 'DropdownDurationPicker',
              questionProps: {
                created_utc: { $datetime: '2020-09-11T04:32:15.818507' },
                updated_utc: { $datetime: '2020-09-16T13:45:24.311637' },
                deleted_utc: null,
                id: 11,
                semantic_id: 'sleep_efficiency_awakenings_total_time',
                uuid: 'c70e2fa6-61e1-49f7-aa95-290ce8301ed9',
                language: 'en',
                version: 1,
                question_pre_text: null,
                question_title: 'DropdownDurationPicker',
                response_pre_text: null,
                visual_representation_json: '{}',
                visual_representation_markup: '<markup/>',
                optional: false,
                response_config: {
                  response_options: [
                    {
                      id: 1,
                      value: '$input',
                      semantic_id: 'ro_se_awakenings_total_time',
                      max_response: 28800000,
                      min_response: 60000,
                    },
                  ],
                  validation_schema: {
                    type: 'object',
                    title: 'answers',
                    $schema: 'http://json-schema.org/draft-07/schema#',
                    properties: {
                      '1': {
                        type: 'number',
                        minimum: 60000,
                        exclusiveMaximum: 28800000,
                      },
                      selections: {
                        type: 'array',
                        maxItems: 1,
                        minItems: 0,
                      },
                    },
                    description: 'answer option values',
                  },
                  correct_response_ids: [],
                  default_response_ids: [],
                  max_selections_required: 1,
                  min_selections_required: 0,
                },
                response_type: { $ResponseType: 'NUMBER' },
                primary_key: 11,
                display_name: '126',
                previous_responses: [],
              },
            },
            {
              action: {
                type: SceneActionTypes.SUBMIT,
              },
              align: 'bottom',
              component: 'Button',
              text: 'Submit',
              type: 'SECONDARY_BUTTON',
            },
          ],
          component: 'Modal',
          showBackButton: true,
        },
      ],
      component: 'Scene',
    },
    getSleepDiaryScene(), // 5
    getSleepDiaryWeekScene(), // 6
    ...getSleepDiaryFormSceneList(), // 7 - 12
    setSleepWindowScene(), // 13
    extendSleepWindowScene(), // 14
    getScheduleNextSessionScene(), //15
    getSceneToThrowInModal(), // 16
    getSceneToThrowInScene(), // 17
    getSceneToThrowInSceneSet(), // 18
    getSceneForShowTime(), // 19
    getSceneForSelectHorizontal(), // 20
    getSceneForSelectHorizontalTime(), // 21
    getSceneForEffectServiceCalls(), // 22
    getSceneForProgressGoalsV2(), // 23
  ],
};

export const DEVELOPER_RESPONSIVE_TEST_SCENESET: SceneSet = {
  id: 2,
  component: 'SceneSet',
  childNodes: [
    {
      component: 'Scene',
      childNodes: [
        {
          component: 'MediaContainer',
          mediaProps: {
            height: 576,
            width: 1024,
          },
          childNodes: [
            {
              component: 'Video',
              fromSeconds: 0,
              toSeconds: 10,
              delaySeconds: 0,
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/intro-nosound.mp4',
                height: 576,
                width: 1024,
              },
              scaling: 'fillHeight',
            },
            {
              component: 'Image',
              sourceProps: {
                height: 123,
                width: 123,
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER + '/assets/icon.png',
              },
              style: {
                bottom: 70,
                left: 70,
                width: 300,
              },
            },
            {
              component: 'PlaceholderSpeechBubble',
              style: {
                top: 284,
                left: 428,
                height: 100,
                width: 100,
                borderRadius: 100,
                fontSize: 21,
              },
              text: 'Hello',
            },
            {
              component: 'PlaceholderSpeechBubble',
              style: {
                top: 60,
                left: 50,
                height: 200,
                width: 200,
                fontSize: 30,
                borderRadius: 200,
              },
              text: 'YEAH!',
            },
          ],
        },
        {
          component: 'Modal',
          showBackButton: true,
          childNodes: [
            {
              component: 'Image',
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER + '/assets/200w.gif',
                height: 576,
                width: 1024,
              },
              style: {
                height: 100,
                width: 100,
              },
            },
            {
              component: 'VerticalSelect',
              questionProps: {
                id: 9,
                optional: false,
                question_pre_text: 'question_pre_text',
                question_title: 'VerticalSelect (w/Text input)',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 2,
                  default_response_ids: [],
                  correct_response_ids: [],
                  max_number_attempts: 1,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 1,
                      semantic_id: 'dog',
                      display_text: 'Dog',
                      label: 'Dog',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'dog',
                    },
                    {
                      id: 2,
                      semantic_id: 'cat',
                      display_text: 'Cat',
                      label: 'Cat',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'cat',
                    },
                    {
                      id: 3,
                      semantic_id: 'fish',
                      display_text: 'Fish',
                      label: 'Fish',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'fish',
                    },
                    {
                      id: 4,
                      semantic_id: 'other',
                      display_text: 'Other',
                      label: 'Other',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: '$input',
                    },
                  ],
                },
                response_type: 'text',
                semantic_id: '9VerticalSelect',
              } as Question,
            },
            {
              component: 'HorizontalScale',
              questionProps: {
                id: 7,
                optional: false,
                question_pre_text: 'HorizontalScale question_pre_text',
                question_title:
                  'HorizontalScale question_title (2 options & quiz)',
                response_config: {
                  min_selections_required: 1,
                  max_selections_required: 10,
                  default_response_ids: [],
                  correct_response_ids: [11],
                  max_number_attempts: 3,
                  validation_schema: {},
                  response_options: [
                    {
                      id: 11,
                      semantic_id: 'foo',
                      display_text: 'Correct',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'one',
                    },
                    {
                      id: 12,
                      semantic_id: 'foo',
                      display_text: 'Wrong',
                      label: 'Sup',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'two',
                    },
                    {
                      id: 13,
                      semantic_id: 'Wrong2',
                      display_text: 'Wrong2',
                      label: 'Wrong2',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'Wrong2',
                    },
                    {
                      id: 14,
                      semantic_id: 'Wrong3',
                      display_text: 'Wrong3',
                      label: 'Wrong3',
                      max_number_lines: 3,
                      min_characters_limit: 120,
                      value: 'Wrong3',
                    },
                  ],
                },
                response_type: 'number',
                semantic_id: '7HorizontalScale (quiz)',
              } as Question,
            },
          ],
        },
      ],
    },
  ],
};

const cloned = clone(DEVELOPER_COMPONENTS_TEST_SCENESET_FROM_API);
const withDecodedAPITypes = decodeAPITypes(cloned);
const withDates = transformToJavaScriptDate<SceneSet>(withDecodedAPITypes);
export const DEVELOPER_COMPONENTS_TEST_SCENESET: SceneSet = withDates;
export const DEVELOPER_AV_TEST_SCENESET: SceneSet = {
  id: 3,
  component: 'SceneSet',
  childNodes: [
    {
      component: 'Scene',
      childNodes: [
        {
          component: 'MediaContainer',
          mediaProps: {
            height: 392,
            width: 602,
          },
          childNodes: [
            {
              component: 'Video',
              fromSeconds: 0,
              toSeconds: 15,
              delaySeconds: 0,
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/intro.mp4',
                height: 576,
                width: 1024,
              },
              scaling: 'fillHeight',
            },
            {
              component: 'PlaceholderSpeechBubble',
              style: {
                top: 50,
                right: 250,
                height: 50,
                width: 70,
                fontSize: 10,
              },
              text: 'Delay 0s. From 0-10.',
              scaling: 'relative',
            },
            {
              component: 'Video',
              delaySeconds: 0,
              fromSeconds: 0,
              toSeconds: 10,
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/timer1.mp4',
                height: 392,
                width: 602,
              },
              scaling: 'relative',
              style: {
                right: 100,
                top: 50,
                height: 100,
                width: 154,
              },
            },
            {
              component: 'PlaceholderSpeechBubble',
              style: {
                top: 200,
                right: 250,
                height: 50,
                width: 70,
                fontSize: 10,
              },
              scaling: 'relative',
              text: 'Delay 5s. From 5-15s. Triggers next',
            },
            {
              component: 'Video',
              delaySeconds: 5,
              fromSeconds: 5,
              toSeconds: 15,
              action: {
                type: SceneActionTypes.NEXT,
              },
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/timer2.mp4',
                height: 392,
                width: 602,
              },
              scaling: 'relative',
              style: {
                right: 100,
                top: 200,
                height: 100,
                width: 154,
              },
            },
          ],
        },
      ],
    },
    {
      component: 'Scene',
      childNodes: [
        {
          component: 'MediaContainer',
          mediaProps: {
            height: 392,
            width: 602,
          },
          childNodes: [
            {
              component: 'Video',
              delaySeconds: 0,
              fromSeconds: 15,
              toSeconds: 30,
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/intro.mp4',
                height: 576,
                width: 1024,
              },
              scaling: 'fillHeight',
            },
            {
              component: 'PlaceholderSpeechBubble',
              style: {
                top: 50,
                right: 250,
                height: 50,
                width: 70,
                fontSize: 10,
              },
              text: 'Freeze frame at 10s',
              scaling: 'relative',
            },
            {
              component: 'Video',
              delaySeconds: 0,
              fromSeconds: 10,
              toSeconds: 10,
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/timer1.mp4',
                height: 392,
                width: 602,
              },
              scaling: 'relative',
              style: {
                right: 100,
                top: 50,
                height: 100,
                width: 154,
              },
            },
            {
              component: 'PlaceholderSpeechBubble',
              style: {
                top: 200,
                right: 250,
                height: 50,
                width: 70,
                fontSize: 10,
              },
              text: 'Continues. From 15-30.',
              scaling: 'relative',
            },
            {
              component: 'Video',
              delaySeconds: 0,
              fromSeconds: 15,
              toSeconds: 30,
              sourceProps: {
                storage_url:
                  PLATGEN_WEBAPP_DEV_URL_FOR_BROWSER +
                  '/assets/sleepio/timer2.mp4',
                height: 392,
                width: 602,
              },
              scaling: 'relative',
              style: {
                right: 100,
                top: 200,
                height: 100,
                width: 154,
              },
            },
          ],
        },
      ],
    },
  ],
};
