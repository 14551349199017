import React, { ReactElement } from 'react';
import styled from 'styled-components/native';

import { DownloadsContentListProps } from '@bighealth/types';

import { DownloadName, Entry, Extension, iconsDictionary } from '../components';
import { ExternalLinkContainer } from '../components/ExternalLinkContainer';

export const DownloadsContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const ExtensionContainer = styled.View`
  width: 15%;
`;

export const NameContainer = styled.View`
  width: 65%;
`;

export const IconContainer = styled.View`
  flex-direction: row-reverse;
  width: 20%;
`;

export const DownloadsContentEntry = ({
  name,
  link,
  extension,
}: DownloadsContentListProps): ReactElement => {
  const IconComponent = iconsDictionary['download-icon'];

  return (
    <ExternalLinkContainer link={link} download={name}>
      <Entry>
        <DownloadsContainer>
          <ExtensionContainer>
            <Extension text={extension} />
          </ExtensionContainer>

          <NameContainer>
            <DownloadName text={name} />
          </NameContainer>

          <IconContainer>
            <IconComponent />
          </IconContainer>
        </DownloadsContainer>
      </Entry>
    </ExternalLinkContainer>
  );
};
