import { LibraryScreen } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_library_content = async (
  args: get_library_content.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_library_content.Response> => {
  const request: get_library_content.Request = {
    service_name: 'LibraryArticle',
    service_version: '1',
    service_method: 'get_library_content',
    args,
  };

  return await api(request, requestConfig);
};

get_library_content.queryKey = 'LibraryArticle/get_library_content';

export declare namespace get_library_content {
  export type Args = {
    product_id: number;
    search_words?: string[];
  };

  export interface Request extends APIRequestBody {
    service_name: 'LibraryArticle';
    service_version: '1';
    service_method: 'get_library_content';
    args: Args;
  }

  export type Result = LibraryScreen;

  export type Response = APIResponse<Result>;
}
