import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Rect } from 'react-native-svg';

import {
  Rectangle as RectangleProps,
  ViewStylesObject,
} from '@bighealth/types/src/scene-components/client';

import { useTransformStylesToContext } from 'components/ResponsiveLayout/hooks/useTransformStylesToContext';
import { roles } from 'cross-platform/utils/roleProps';

export default function Rectangle(props: RectangleProps): React.ReactElement {
  const scaleValues = useTransformStylesToContext();
  // @TODO Make useTransformStylesToContext an optional generic
  // WHEN we find it annoying to destructure and remap values like this.
  const { width: borderRadius, height: strokeWidth } = scaleValues({
    width: props.borderRadius || 0,
    height:
      typeof props.stroke?.weight === 'undefined' ? 10 : props.stroke.weight,
  });
  const transformedStyles = scaleValues(props.style as ViewStylesObject);
  if (props.style?.top || props.style?.left) {
    transformedStyles.position = 'absolute';
  }

  return (
    <Svg
      {...roles('Rectangle')}
      pointerEvents={'none'}
      width={transformedStyles.width}
      height={transformedStyles.height}
      style={transformedStyles as StyleProp<ViewStyle>}
    >
      <Rect
        rx={borderRadius}
        ry={borderRadius}
        fill={props.fill}
        x={strokeWidth}
        y={strokeWidth}
        width={(transformedStyles.width as number) - strokeWidth * 2}
        height={(transformedStyles.height as number) - strokeWidth * 2}
        stroke={props.stroke?.color}
        strokeWidth={strokeWidth}
        strokeOpacity={props.stroke?.opacity}
        strokeLinecap="round"
      />
    </Svg>
  );
}
