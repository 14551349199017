import styled from 'styled-components/native';

import { FillAbsolute } from 'components/Containers';

export const DebugInstructions = styled.Text`
  color: white;
`;
DebugInstructions.displayName = 'DebugInstructions';

export const Container = styled(FillAbsolute)`
  align-items: center;
`;

export const LimitWidth = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: ${props => props.theme.color.primary};
`;

export const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  align-items: stretch;
  margin-bottom: 30px;
`;
