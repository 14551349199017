import React, { ReactElement, useState } from 'react';
import { View } from 'react-native';

import { ResponseOption } from '@bighealth/types';
import { SleepDiaryFormProps } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { Content } from 'components/layout/Content';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

import { Form, SleepDiaryOptionalProps } from '..';

import { payload, payloadV2 } from '.';

export const SleepDiaryWithDefaults = ({
  onSubmit,
  onFormClose,
  data,
}: SleepDiaryOptionalProps & {
  data: SleepDiaryFormProps;
}): ReactElement | null => {
  const [submitted, setSubmitted] = useState<Record<string, ResponseOption[]>>(
    {}
  );
  const sceneSets = useFakeStorybookSceneSet();
  if (!sceneSets) {
    return null;
  }
  return (
    <View style={{ width: '100%' }}>
      <Form
        {...data}
        onSubmit={(values: Record<React.ReactText, ResponseOption[]>): void => {
          setSubmitted(values);
          onSubmit?.(values);
        }}
        onClose={onFormClose}
      />
      <Content mono>{JSON.stringify(submitted, null, 2)}</Content>
    </View>
  );
};

export const SleepDiaryStory = ({
  onSubmit,
  onFormClose,
}: SleepDiaryOptionalProps): ReactElement => (
  <EphemeralStateProvider>
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <SleepDiaryWithDefaults
        data={payload}
        onSubmit={onSubmit}
        onFormClose={onFormClose}
      />
    </CrossPlatformThemeProvider>
  </EphemeralStateProvider>
);
addStory('sleep-diary/Form WithDefaults', () => <SleepDiaryStory />);

export const SleepDiaryStoryV2 = ({
  onSubmit,
  onFormClose,
}: SleepDiaryOptionalProps): ReactElement => {
  return (
    <EphemeralStateProvider>
      <CrossPlatformThemeProvider
        theme={getThemeForProduct(ProductReferences.SLEEPIO)}
      >
        <SleepDiaryWithDefaults
          data={payloadV2}
          onSubmit={onSubmit}
          onFormClose={onFormClose}
        />
      </CrossPlatformThemeProvider>
    </EphemeralStateProvider>
  );
};

addStory('sleep-diary/Form WithDefaultsV2', () => <SleepDiaryStoryV2 />);
