import { Article } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const read_full_article = async (
  args: read_full_article.Args,
  requestConfig?: AxiosRequestConfig
): Promise<read_full_article.Response> => {
  const request: read_full_article.Request = {
    service_name: 'LibraryArticle',
    service_version: '1',
    service_method: 'read_full_article',
    args,
  };

  return await api(request, requestConfig);
};

read_full_article.queryKey = 'LibraryArticle/read_full_article';

export declare namespace read_full_article {
  export type Args = {
    entity_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'LibraryArticle';
    service_version: '1';
    service_method: 'read_full_article';
    args: Args;
  }

  export type Result = Article;

  export type Response = APIResponse<Result>;
}
