import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const disable_otp = async (
  args: disable_otp.Args,
  requestConfig?: AxiosRequestConfig
): Promise<disable_otp.Response> => {
  const request: disable_otp.Request = {
    service_name: 'UserAccount',
    service_version: '1',
    service_method: 'disable_otp',
    args,
  };

  return await api(request, requestConfig);
};

disable_otp.queryKey = 'UserAccount/disable_otp';

export declare namespace disable_otp {
  export type Args = {
    otp: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserAccount';
    service_version: '1';
    service_method: 'disable_otp';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
