import React, { ReactNode, useState } from 'react';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { Heading5 } from 'components/Heading';
import { Content } from 'components/layout/Content';
import { Col } from 'components/layout/Grid';
import { roles } from 'cross-platform/utils/roleProps';
import { addStory } from 'lib/story-books';

import { InputFieldData } from './index.test.native';
import { InputField } from './InputField';
import { Form, FormTypes } from '.';

addStory('form', () => {
  const fields: InputFieldData[] = [
    {
      name: 'email',
      label: 'Email',
      fieldTypeProps: { fieldType: 'TEXT' },
      items: [{ value: '', type: '$input' }],
    },
    {
      name: 'password1',
      label: 'Password',
      fieldTypeProps: { fieldType: 'TEXT' },
      items: [{ value: '', type: '$input' }],
    },
    {
      name: 'select-dropdown',
      label: 'Select dropdown',
      items: [
        { displayText: 'Apple', value: 'apple' },
        { displayText: 'Orange', value: 'orange' },
        { displayText: 'Peach', value: 'peach' },
      ],
      fieldTypeProps: {
        fieldType: 'SELECTION',
      },
    },
    {
      name: 'select-vertical',
      label: 'Select vertical',
      quizInfo: {
        // eslint-disable-next-line camelcase
        correct_response_ids: [1],
        // eslint-disable-next-line camelcase
        max_number_attempts: 1,
      },
      items: [
        { id: 1, displayText: 'Dog', value: 'dog' },
        { id: 2, displayText: 'Cat', value: 'cat' },
        { id: 3, displayText: 'Cow', value: 'cow' },
      ],
      fieldTypeProps: {
        fieldType: 'SELECTION',
        layout: 'VERTICAL',
      },
    },
  ];

  const [values, setValues] = useState<Record<string, DropdownItem[]>>();
  return (
    <Col width="100%">
      <Heading5>Submitted values</Heading5>
      <Content mono>{JSON.stringify(values, null, 2)}</Content>
      <Heading5>Status</Heading5>
      <Heading5>Form</Heading5>
      <Form
        onSubmit={(values, options): void => {
          setValues(values);
          options.setSubmitting(false);
        }}
        onValidate={(values): FormTypes['Errors'] => {
          const errors: FormTypes['Errors'] = {};
          fields.forEach(el => {
            if (!values[el.name]?.[0]?.value) {
              errors[el.name] = 'Required!';
            }
          });
          return errors;
        }}
      >
        {//IDEA make this its  own function
        fields.map(
          (field: InputFieldData): ReactNode => (
            <InputField
              key={field.name}
              name={field.name}
              label={field.label}
              fieldTypeProps={field.fieldTypeProps}
              quizInfo={field.quizInfo}
              items={field.items}
              {...roles(`field-${field.name}`)}
            />
          )
        )}
      </Form>
    </Col>
  );
});
