import deepEqual from 'fast-deep-equal';

import { Redirection } from 'cross-platform/react-router/Router/utils/Redirector/state/actions';
/**
 * TODO take into account key order
 * WHEN expand conditions
 */
const stringify = (obj: Record<string, unknown>) => JSON.stringify(obj);

export const isEqualRedirection = (a: Redirection) => (b: Redirection) => {
  if (deepEqual(a.location, b.location)) {
    const aCondStr = a.conditions.map(stringify);
    const bCondStr = b.conditions.map(stringify);
    const aCondSet = new Set(aCondStr);
    const bCondSet = new Set(bCondStr);
    return (
      aCondSet.size === bCondSet.size &&
      Array.from(aCondSet.values()).every(aCond => bCondSet.has(aCond))
    );
  }
};
