import { Asset } from './reducer';

export enum DownloaderActionTypes {
  SET_FILES_TO_DOWNLOAD = 'downloader/SET_ASSETS_TO_DOWNLOAD',
  MOVE_ASSET_TO_CACHED_LIST = 'downloader/MOVE_ASSET_TO_CACHED_LIST',
}

export type DownloaderAction = {
  type: DownloaderActionTypes;
  payload: Asset | Asset[];
};

export const setAssetsToDownload = (assets: Asset[]): DownloaderAction => ({
  type: DownloaderActionTypes.SET_FILES_TO_DOWNLOAD,
  payload: assets,
});

export const moveAssetToCache = (asset: Asset): DownloaderAction => ({
  type: DownloaderActionTypes.MOVE_ASSET_TO_CACHED_LIST,
  payload: asset,
});
