import { ResponseOption } from '@bighealth/types';

const checkResponseOption = (
  a: ResponseOption[],
  ids: ResponseOption['id'][]
): boolean =>
  a.length === ids.length &&
  a.every((aEl: ResponseOption): boolean => !!ids.find(id => aEl.id === id));

export { checkResponseOption };
