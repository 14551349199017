import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';

import { getFallAsleepTimeGreaterThan12Hours } from './rules/getFallAsleepTimeGreaterThan12Hours';
import { getLeaveBedTimeGreaterThan12Hours } from './rules/getLeaveBedTimeGreaterThan12Hours';
import { getSleepGreaterThan12Hours } from './rules/getSleepGreaterThan12Hours';
import { getTimeAwakeGreaterThanTimeAsleep } from './rules/getTimeAwakeGreaterThanTimeAsleep';

export type WarningAndHighlight = { warning: string; highlight: boolean };

// IDEA: Do not hardcode warning strings in client code
// WHEN: PG-1119 Make sleep diary warning and error messages configurable on mobile
// INFO: https://bighealth.slack.com/archives/C017YFDNPMK/p1600848129008700

/**
 * Custom rules to hide fields based on values
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getWarningsAndHighlights = (
  inputs: Record<QuestionId, DropdownItem[]>,
  diaryDate?: Date
): typeof firstWarningOrNone => {
  const firstWarningOrNone =
    getFallAsleepTimeGreaterThan12Hours(inputs, diaryDate) ||
    getLeaveBedTimeGreaterThan12Hours(inputs, diaryDate) ||
    getSleepGreaterThan12Hours(inputs, diaryDate) ||
    getTimeAwakeGreaterThanTimeAsleep(inputs, diaryDate) ||
    // or none
    {};
  return firstWarningOrNone;
};

export { getWarningsAndHighlights };
