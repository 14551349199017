import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_settings } from '@bighealth/api/UserDaylightMetadata/v1';

import { useQueryProduct } from './useQueryProduct';

export const useQueryDaylightSettings = (): UseQueryResult<
  get_settings.Response,
  APIErrorResponse
> => {
  const productId = useQueryProduct().data?.result?.id;

  const result = useQuery<get_settings.Response, APIErrorResponse>({
    queryKey: get_settings.queryKey,
    queryFn: () => get_settings({}),
    enabled: typeof productId === 'number',
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
  return result;
};
