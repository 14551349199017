import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';
import { NotificationsConfigEntity } from '@bighealth/types/src/services/NotificationsConfig';

export const find_notifications_configs = async (
  args: find_notifications_configs.Args,
  requestConfig?: AxiosRequestConfig
): Promise<find_notifications_configs.Response> => {
  const request: find_notifications_configs.Request = {
    service_name: 'NotificationsConfig',
    service_version: '1',
    service_method: 'find_notifications_configs',
    args,
  };

  return await api(request, requestConfig);
};

find_notifications_configs.queryKey =
  'NotificationsConfig/find_notifications_configs';

export declare namespace find_notifications_configs {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'NotificationsConfig';
    service_version: '1';
    service_method: 'find_notifications_configs';
    args: Args;
  }

  export type Result = NotificationsConfigEntity[];

  export type Response = APIResponse<Result>;
}
