import { useEffect } from 'react';
import { format } from 'date-fns';

import { DateValueObject } from '@bighealth/types/src';
import { CalendarProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

import { useHistory } from 'cross-platform/react-router';
import { useQuery } from 'lib/router/useQuery';

const DATE_VALUE_OBJECT_FORMAT = 'yyyy-MM-dd';

export enum openDayValues {
  TODAY = 'today',
}
export const OPEN_DAY = 'open-day';

/**
 * TODO Fix type of diaries.days.date to be ValueObject
 * WHEN want more type accuracy
 *
 */
export const asMaybeDateValueObject = (
  date?: string
): DateValueObject | undefined => {
  if (typeof date === 'undefined') {
    return undefined;
  }
  return (date as unknown) as DateValueObject;
};

/**
 * On mount - check query params for instructions to open url
 *
 * @param diaries list diaries from backend
 * @param openDiary callback to open a given diary
 * @see {mock} config/jest/mocks/components/SleepDiaryWeekly/useTryOpenDate.js
 */
export const useTryOpenDate = (
  diaries: CalendarProps,
  openDiary: (date?: string) => void
): void => {
  const query = useQuery();
  const history = useHistory();
  useEffect(() => {
    const date =
      query.get(OPEN_DAY) === openDayValues.TODAY
        ? format(new Date(), DATE_VALUE_OBJECT_FORMAT)
        : undefined;

    query.delete(OPEN_DAY); // remove param
    history.push({
      ...history.location,
      search: '?' + query.toString(),
    });
    if (typeof date === 'undefined') {
      return; // exit early
    }

    const day = diaries.days.find(
      el => asMaybeDateValueObject(el.date)?.$date === date
    );

    if (typeof day?.date === 'undefined') {
      return; // exit early
    }
    openDiary(day.date);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get(OPEN_DAY)]);
};
