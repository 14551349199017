import { Switch as SwitchUnstyled } from 'react-native';
import styled from 'styled-components/native';

import { Warning } from 'components/icons';
import {
  NOTIFICATION_TYPE,
  NotificationViewArgs,
} from 'components/LocalNotifications/NotificationsView';
import { Text } from 'components/sceneset-components/Text';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';
import { hexToRGBA } from 'lib/utils/styles/hexToRGBA';

import { DOMAIN } from './constants';

export const Main = styled.View`
  flex-grow: 1;
  margin-top: 24px;
  max-width: 500px;
  margin-left: 22px;
  margin-right: 22px;
  align-self: center;
`;
Main.displayName = `${DOMAIN}.Main`;

export const ToggleWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
ToggleWrapper.displayName = `${DOMAIN}.ToggleWrapper`;

export const ToggleText = styled(Text)<{
  isReminderDisabled: NotificationViewArgs['scheduleNotificationState'][NOTIFICATION_TYPE.DAILY_REMINDER]['isReminderDisabled'];
}>`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: ${props =>
    props.isReminderDisabled
      ? props.theme.color.textLocalNotifications?.secondary
      : props.theme.color.textLocalNotifications?.primary};
`;
ToggleText.displayName = `${DOMAIN}.ToggleText`;

export const Description = styled(Text)`
  font-size: 16px;
  margin-top: 24px;
  font-weight: 500;
  line-height: 21px;
  color: ${props => props.theme.color.textLocalNotifications?.secondary};
`;
Description.displayName = `${DOMAIN}.Description`;

export const WarningWrapper = styled.View`
  margin-top: 24px;
  flex-direction: row;
  align-items: center;
`;
WarningWrapper.displayName = `${DOMAIN}.WarningWrapper`;

export const WarningIcon = styled(Warning).attrs({ size: 20 })`
  margin-right: 10px;
  color: ${props => props.theme.color.warning};
`;
WarningIcon.displayName = `${DOMAIN}.WarningIcon`;

export const WarningTextWrapper = styled.View`
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
`;
WarningTextWrapper.displayName = `${DOMAIN}.WarningTextWrapper`;

export const WarningText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${props => props.theme.color.textLocalNotifications?.secondary};
`;
WarningText.displayName = `${DOMAIN}.WarningText`;

export const WarningWithUnderline = styled(WarningText).attrs({
  underline: true,
})`
  text-decoration-line: underline;
`;
WarningWithUnderline.displayName = `${DOMAIN}.WarningWithUnderline`;

export const GoToSettingsWrapper = styled.TouchableOpacity``;
GoToSettingsWrapper.displayName = `${DOMAIN}.GoToSettingsWrapper`;

export const TimePickerWrapper = styled.View`
  margin-top: 18px;
`;
TimePickerWrapper.displayName = `${DOMAIN}.WarningWithUnderline`;

export const Switch = styled(SwitchUnstyled).attrs(props => ({
  trackColor: {
    false: hexToRGBA(props.theme.color.textLocalNotifications?.secondary, 0.26),
    true: props.theme.color.textLocalNotifications?.primary,
  },
  thumbColor: props.theme.color.white,
  ios_backgroundColor: props.value
    ? props.theme.color.textLocalNotifications?.primary
    : hexToRGBA(props.theme.color.textLocalNotifications?.secondary, 0.26),
}))``;
Switch.displayName = `${DOMAIN}.Switch`;

export const Line = styled.View`
  flex-direction: row;
  justify-content: center;
  border: 1px solid ${sleepioColors.borderColorGray};
  height: 1px;
  margin-top: 24px;
  margin-bottom: 28px;
`;
Line.displayName = `${DOMAIN}.Line`;
