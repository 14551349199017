import styled from 'styled-components/native';

import { FillAbsolute, ScrollableContainer } from 'components/Containers';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { Text } from 'components/Text';
import TextInput from 'components/TextInput';

export const LimitWidth = styled.View`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  max-width: 580px;
  width: 100%;
`;

export const LogoContainer = styled.TouchableOpacity`
  margin-top: 25px;
  align-self: center;
  margin-bottom: 28px;
`;

export const LogoContainerPortrait = styled(LogoContainer)`
  margin-bottom: 40px;
  flex-direction: row;
  justify-content: center;
`;

export const Input = styled(TextInput)<{ hasErrors?: boolean }>`
  font-size: 16px;
  font-style: normal;
  border-radius: 8px;
  text-decoration-line: none;
  color: ${props => props.theme.color.text.primary};
  background-color: ${props =>
    props.value
      ? props.theme.color.white
      : props.theme.color.disabledInputGrey};
  border: ${({ hasErrors, ...props }) =>
    hasErrors
      ? `solid 1.5px ${props.theme.color.error};`
      : `1px solid ${props.theme.color.inputOutlineGrey}`};
`;

export const MFAVerificationText = styled<typeof Text>(Text)`
  font-size: 20px;
  color: ${({ theme }) => theme.color.overlay.primary};
  text-align: center;
  font-family: '${FontFamilies.Medium}';
  margin-bottom: 32px;
`;
MFAVerificationText.displayName = 'MFAVerificationText';

export const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: stretch;
  margin-left: 15px;
  margin-right: 15px;
`;

export const PaddingContainer = styled.View`
  flex-direction: column;
  margin-bottom: 24px;
  display: flex;
  align-items: stretch;
`;

export const Container = styled(FillAbsolute)`
  align-items: center;
  background-color: ${props => props.theme.color.lightGray};
`;

export const LoginScroll = styled(ScrollableContainer).attrs({
  contentContainerStyle: {
    flexGrow: 1,
    alignItems: 'center',
  },
})`
  width: 100%;
`;
LoginScroll.displayName = 'LoginScroll';

export const ErrorContainer = styled.View`
  margin-top: 8px;
`;

export const ValidationText = styled<typeof Text>(Text)`
  font-size: 16px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: #d0021b;
`;

export const LinkText = styled<typeof Text>(Text)`
  text-decoration: underline;
  font-family: '${FontFamilies.Medium}';
`;
LinkText.displayName = 'LinkText';

export const NeedHelpText = styled<typeof Text>(Text)`
  font-size: 20px;
  color: ${({ theme }) => theme.color.overlay.primary};
  margin-top: 24px;
  text-align: center;
`;
NeedHelpText.displayName = 'NeedHelpText';

export const LabelText = styled<typeof Text>(Text)`
  font-size: 16px;
  color: ${props => props.theme.color.midBlue};
  margin-bottom: 5px;
  font-weight: 400;
`;
LabelText.displayName = 'LableText';

export const NHSText = styled<typeof Text>(Text)`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.midBlue};
  margin-bottom: 10px;
  margin-top: 55px;
`;
NHSText.displayName = 'NHSText';

export const NeedHelpLink = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.theme.color.overlay.primary};
  text-decoration: underline;
  margin-top: 4px;
`;

NeedHelpLink.displayName = 'NeedHelpLink';
