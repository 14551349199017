import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Platform } from 'react-native';
import { useDispatch } from 'react-redux';

import { VisibilityOverride } from 'common/constants/enums';
import useScreenReaderStatus from 'common/hooks/useScreenReaderStatus/useScreenReaderStatus';
import { showControls as setControlsAreVisible } from 'state/player/actions';
import { PlaybackState } from 'state/player/reducer';

export const FADE_OUT_DELAY = 3000;
export const DEFAULT_DURATION = 500;
export const USE_NATIVE_DRIVER = Platform.OS !== 'web';

let animationTimeout: ReturnType<typeof setTimeout>;

export type UseFadingAnimationProps = {
  playerState: PlaybackState;
  controlsAreVisible: boolean;
  visibilityOverride?: VisibilityOverride;
  modal?: boolean;
};

export type UseFadingAnimationReturn = {
  fadeAnim: Animated.Value;
  hidden: boolean;
};

export const getRequiresFadeOut = (
  controlsAreVisible: boolean,
  playerState: PlaybackState,
  visibilityOverride?: VisibilityOverride
): boolean =>
  (playerState === PlaybackState.PLAYING ||
    playerState === PlaybackState.FINISHED ||
    playerState === PlaybackState.UNINITIALIZED) &&
  controlsAreVisible &&
  visibilityOverride !== VisibilityOverride.SHOW;

export const useFadingAnimation = ({
  playerState,
  controlsAreVisible,
  visibilityOverride,
  modal,
}: UseFadingAnimationProps): UseFadingAnimationReturn => {
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const { screenReaderEnabled } = useScreenReaderStatus();

  const playerStateRef = useRef(playerState);
  const controlsAreVisibleRef = useRef(controlsAreVisible);
  playerStateRef.current = playerState;
  controlsAreVisibleRef.current = controlsAreVisible;

  const delayedFadeOutAnimation = useCallback(() => {
    // Using setTimeout instead of Animated.delay
    // to make sure the state is correct after delay finishes
    clearTimeout(animationTimeout);
    animationTimeout = setTimeout(() => {
      if (
        !getRequiresFadeOut(
          controlsAreVisibleRef.current,
          playerStateRef.current
        )
      ) {
        return;
      }
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: DEFAULT_DURATION,
        useNativeDriver: USE_NATIVE_DRIVER,
      }).start(() => {
        setHidden(true);
        dispatch(setControlsAreVisible(false));
      });
    }, FADE_OUT_DELAY);
  }, [dispatch, fadeAnim]);

  const fadeOutAnimation = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: DEFAULT_DURATION,
      useNativeDriver: USE_NATIVE_DRIVER,
    }).start(({ finished }) => {
      if (finished && !controlsAreVisible) {
        setHidden(true);
      }
    });
  }, [controlsAreVisible, fadeAnim]);

  const fadeInAnimation = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: DEFAULT_DURATION,
      useNativeDriver: USE_NATIVE_DRIVER,
    }).start(() => {
      setHidden(false);
      if (
        getRequiresFadeOut(controlsAreVisible, playerState, visibilityOverride)
      ) {
        delayedFadeOutAnimation();
      }
    });
  }, [
    controlsAreVisible,
    delayedFadeOutAnimation,
    fadeAnim,
    playerState,
    visibilityOverride,
  ]);

  useEffect(() => {
    const listenerId = fadeAnim.addListener(() => {
      // @TODO Discover why we needed addListener to make fadeIn animation work
      // when toggling player controls very quickly on iOS and fix/remove it
      // @WHEN When testing animation behaviour during fast clicking the video container on pause/finished
      // @WHY We think that the fadeAnim value is not updated correctly or fast enough by native driver
      // fixes iOS animation bug
    });
    return () => {
      fadeAnim.stopAnimation();
      clearTimeout(animationTimeout);
      fadeAnim.removeListener(listenerId);
    };
  }, [dispatch, fadeAnim]);

  useEffect(() => {
    clearTimeout(animationTimeout);
    // If screen reader active, then leave controls showing
    // otherwise screen reader cannot Play / Pause video.
    // If there is a question in the modal AND screen reader is active
    // then hide the Play / Pause controls so user can click Continue on form.
    if (!modal && screenReaderEnabled) {
      dispatch(setControlsAreVisible(true));
    } else if (modal && screenReaderEnabled) {
      dispatch(setControlsAreVisible(false));
    }
    if (controlsAreVisible) {
      fadeInAnimation();
    } else {
      fadeOutAnimation();
    }
    // fadeInAnimation and fadeOutAnimation
    // as dependencies triggers endless animation loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsAreVisible, playerState, visibilityOverride]);

  return useMemo(() => ({ hidden, fadeAnim }), [fadeAnim, hidden]);
};
