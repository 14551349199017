import React, { ReactElement } from 'react';
import { Switch } from 'react-native';
import styled from 'styled-components/native';

export const SwitchContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;
SwitchContainer.displayName = 'SwitchContainer';

export type SwitchPropsWeb = {
  activeThumbColor: string;
};

export const SettingsSwitch = (
  props: React.ComponentProps<typeof Switch> & SwitchPropsWeb
): ReactElement => {
  return (
    <SwitchContainer>
      <Switch {...props} />
    </SwitchContainer>
  );
};
