import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const auth_poll = async (
  requestConfig?: AxiosRequestConfig
): Promise<auth_poll.Response> => {
  const request: auth_poll.Request = {
    service_name: 'ClientGateway',
    service_version: '1',
    service_method: 'auth_poll',
    args: {},
  };

  return await api(request, requestConfig);
};

auth_poll.queryKey = 'ClientGateway/auth_poll';

export declare namespace auth_poll {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'ClientGateway';
    service_version: '1';
    service_method: 'auth_poll';
    args: Args;
  }

  export type Result = { authenticated: boolean };

  export type Response = APIResponse<Result>;
}
