import { ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';

import {
  useGetMediaScale,
  useMediaResponsiveLayoutContext,
  useScreenResponsiveLayoutContext,
} from 'components/ResponsiveLayout';

type CloseButtonPosition = {
  top: number;
  right: number;
  position?: ViewStyle['position'];
};

// If the content is already in safe area, there is no need to move it
export const getSafeOffset = (space: number, safePosition: number): number => {
  if (space >= safePosition) {
    return 0;
  } else {
    return safePosition - space;
  }
};

type useContentCloseButtonStylesProps = {
  minTop: number;
  minRight: number;
  options?: { absolutePosition: boolean };
};

export const useContentCloseButtonStyles = ({
  minTop,
  minRight,
  options,
}: useContentCloseButtonStylesProps): CloseButtonPosition => {
  const safeArea = useSafeArea();
  const mediaScale = useGetMediaScale();
  const { mediaWidth, mediaHeight } = useMediaResponsiveLayoutContext();
  const { screenWidth, screenHeight } = useScreenResponsiveLayoutContext();

  const deviceMediaWidth = mediaWidth * mediaScale;
  const deviceMediaHeight = mediaHeight * mediaScale;

  const verticalPadding = (screenHeight - deviceMediaHeight) / 2;
  const horizontalPadding = (screenWidth - deviceMediaWidth) / 2;

  if (options?.absolutePosition === true) {
    return {
      top: minTop,
      right: minRight,
      position: 'absolute',
    };
  }

  const top =
    verticalPadding + minTop + getSafeOffset(verticalPadding, safeArea.top);
  const right =
    horizontalPadding +
    minRight +
    getSafeOffset(horizontalPadding, safeArea.right);

  return {
    top,
    right,
  };
};
