import { DiaryEntry } from '@bighealth/types/src/services/SleepDiaryPayloads/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const update_single_day_entry_with_date = async (
  args: update_single_day_entry_with_date.Args,
  requestConfig?: AxiosRequestConfig
): Promise<update_single_day_entry_with_date.Response> => {
  const request: update_single_day_entry_with_date.Request = {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'update_single_day_entry_with_date',
    args,
  };

  return await api(request, requestConfig);
};

update_single_day_entry_with_date.queryKey =
  'SleepDiary/update_single_day_entry_with_date';

export declare namespace update_single_day_entry_with_date {
  export type Args = DiaryEntry;

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'update_single_day_entry_with_date';
    args: Args;
  }

  export type Result = DiaryEntry;

  export type Response = APIResponse<Result>;
}
