import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { AnalyticsField } from '@bighealth/analytics';
import { APIErrorResponse } from '@bighealth/api';
import { fire_ui_events } from '@bighealth/api/Event/v1/fire_ui_events';

import { useQueryProductStreamVersion } from 'components/Footer/hooks/useQueryProductStreamVersion';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useHistory } from 'cross-platform/react-router';
import { sendScreenViewedAnalytics } from 'lib/api/analytics/sendScreenViewedAnalytics';
import { useGetURL } from 'lib/api/analytics/useGetURL';
import { useQueryProduct, useQueryProgram } from 'lib/api/reactQueryHelpers';

export const useQuerySendScreenViewedAnalytics = (
  field: AnalyticsField
): UseQueryResult<void, APIErrorResponse> => {
  const { sceneSetGraphId = -1 } = useSafeParams();
  const programId = useQueryProgram().data?.result.id as number;
  const productId = useQueryProduct().data?.result.id as number;
  const getUrl = useGetURL();
  const pathname = useHistory().location.pathname;
  const memoedField = useMemo<AnalyticsField>(
    () => ({
      area: field.area,
      section: field.section,
    }),
    [field.area, field.section]
  );
  const productVersionStreamResult = useQueryProductStreamVersion.get_latest_version(
    { meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN } }
  );
  const result = useQuery<void, APIErrorResponse>({
    queryKey: [fire_ui_events.queryKey, pathname],
    enabled:
      typeof productId === 'number' &&
      typeof programId === 'number' &&
      !!productVersionStreamResult?.data?.result,
    queryFn: async () => {
      const url = await getUrl();
      return sendScreenViewedAnalytics({
        field: memoedField,
        productId,
        programId,
        sceneSetGraphId,
        url,
        productVersionStream: productVersionStreamResult?.data?.result,
      });
    },
    cacheTime: 0,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: 0,
  });
  return result;
};
