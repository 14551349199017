import styled from 'styled-components/native';

export const VerticalScroll = styled.ScrollView.attrs({
  vertical: true,
  persistentScrollbar: true,
  contentContainerStyle: { flexGrow: 1, overflow: 'hidden' },
})`
  width: 100%;
  flex: 1;
`;
VerticalScroll.displayName = 'VerticalScroll';
