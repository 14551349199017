import { EdgeInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Text } from 'components/sceneset-components/Text';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import Image from 'cross-platform/Image';

import { DOMAIN } from './constants';

export const Main = styled.View`
  flex-grow: 1;
  margin-top: 32px;
  padding-left: 22px;
  padding-right: 22px;
`;
Main.displayName = `${DOMAIN}.Main`;

export const PreviewImage = styled(Image)`
  width: 270px;
  height: 200px;
  align-self: center;
`;
PreviewImage.displayName = `${DOMAIN}.PreviewImage`;

export const Title = styled(Text)`
  width: 100%;
  font-size: 24px;
  margin-top: 27px;
  font-weight: 500;
  max-width: 500px;
  line-height: 31px;
  align-self: center;
  color: ${props => props.theme.color.textLocalNotifications?.primary};
`;
Title.displayName = `${DOMAIN}.Title`;

export const Description = styled(Text)`
  width: 100%;
  font-size: 17px;
  margin-top: 24px;
  font-weight: 500;
  max-width: 500px;
  line-height: 25px;
  align-self: center;
  color: ${props => props.theme.color.textLocalNotifications?.primary};
`;
Description.displayName = `${DOMAIN}.Description`;

export const Footer = styled.View<{ safeArea: EdgeInsets }>`
  width: 100%;
  margin-top: 27px;
  max-width: 310px;
  align-self: center;
  margin-bottom: ${props => props.safeArea.bottom}px;
`;
Footer.displayName = `${DOMAIN}.Footer`;

export const PrimaryButton = styled(UniversalButton).attrs({
  size: ButtonSizes.Small,
  colorScheme: ButtonColorSchemes.AltPrimary,
})`
  font-size: 18px;
  font-weight: 500;
`;
PrimaryButton.displayName = `${DOMAIN}.PrimaryButton`;

export const SecondaryButton = styled(UniversalButton).attrs({
  size: ButtonSizes.Small,
  colorScheme: ButtonColorSchemes.Link,
})`
  font-size: 18px;
  font-weight: 500;
`;
SecondaryButton.displayName = `${DOMAIN}.SecondaryButton`;
