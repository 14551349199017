import React from 'react';
import Svg, { G, Path, Polygon, Rect } from 'react-native-svg';

// Note: The ability to switch color themes may not be needed post-launch. The
// "normal" default theme will be used with the login screen redesign. The "light"
// theme exists as a back up if we must launch with the current blue login screen.
type svgButtonProps = {
  colorTheme?: 'normal' | 'light';
};

// Note: This svg is from the NHS Connect github account.
// https://github.com/nhsconnect/nhslogin/blob/main/src/buttons/Other/standard/normal/btn_NHSlogin_normal.svg
export const SvgNHSButton = ({ colorTheme = 'normal' }: svgButtonProps) => {
  const fill1 = '#003087';
  const fill2 = colorTheme === 'normal' ? '#005eb8' : '#fff';
  const fill3 = colorTheme === 'normal' ? '#fff' : '#005eb8';
  return (
    <Svg viewBox="0 0 320.22 59">
      <G id="Layer_2" data-name="Layer 2">
        <G id="Layer_1-2" data-name="Layer 1">
          <Rect fill={fill1} y="4" width="320.22" height="55" rx="4" />
          <Rect fill={fill2} width="320.22" height="55" rx="4" />
          <Rect fill={fill2} x="2" y="2" width="317.84" height="51" />
          <Rect fill={fill2} x="101.05" y="14" width="200.79" height="27" />
          <Path
            fill={fill3}
            d="M112.38,33.89a13.83,13.83,0,0,1-3.06.42c-4.6,0-7.37-2.38-7.37-6.71,0-4.54,2.76-7.18,7.37-7.18a8.78,8.78,0,0,1,3,.56l-.19,2.27a5.51,5.51,0,0,0-2.9-.71c-2.72,0-4.51,2.06-4.51,4.87s1.77,4.79,4.56,4.79a7.6,7.6,0,0,0,3-.56Z"
          />
          <Path
            fill={fill3}
            d="M118.68,34.31a4.89,4.89,0,0,1-5.18-5.12,5.19,5.19,0,1,1,10.37,0A4.89,4.89,0,0,1,118.68,34.31Zm2.5-5.42c0-1.58-.85-2.95-2.5-2.95s-2.48,1.39-2.48,2.95c0,2.09.8,3.42,2.48,3.42S121.18,31,121.18,28.89Z"
          />
          <Path
            fill={fill3}
            d="M132.42,34.08V28.52c0-1.48-.31-2.58-1.67-2.58-1.64,0-2.27,1.58-2.27,3.33v4.81H125.9V24.17h2.44v1.35h0a3.7,3.7,0,0,1,3.25-1.58c2.27,0,3.37,1.56,3.37,3.89v6.25Z"
          />
          <Path
            fill={fill3}
            d="M143.55,34.08a6.56,6.56,0,0,1-1.89.23,2.92,2.92,0,0,1-3.16-3.21v-5h-1.9V24.17h1.9v-2l2.58-.83v2.81h2.29v1.89h-2.29v4.6c0,1,.33,1.65,1.23,1.65a2.21,2.21,0,0,0,1.16-.27Z"
          />
          <Path
            fill={fill3}
            d="M145.2,22.36V19.9h2.58v2.46Zm0,11.72V24.17h2.58v9.91Z"
          />
          <Path
            fill={fill3}
            d="M157,34.08V28.52c0-1.48-.31-2.58-1.67-2.58-1.64,0-2.28,1.58-2.28,3.33v4.81H150.5V24.17h2.44v1.35h0a3.69,3.69,0,0,1,3.25-1.58c2.27,0,3.37,1.56,3.37,3.89v6.25Z"
          />
          <Path
            fill={fill3}
            d="M168.91,34.08V32.74h0a4.14,4.14,0,0,1-3.26,1.57c-2.21,0-3.36-1.48-3.36-3.88V24.17h2.58v5.66c0,2,.73,2.48,1.67,2.48,1.56,0,2.27-1.44,2.27-3.35V24.17h2.58v9.91Z"
          />
          <Path
            fill={fill3}
            d="M182,33.52a6.76,6.76,0,0,1-3.38.79c-3.29,0-5.2-1.88-5.2-5.13,0-2.78,1.45-5.24,4.6-5.24,3.37,0,4.72,2.08,4.72,6h-6.85a2.58,2.58,0,0,0,2.84,2.5,6.66,6.66,0,0,0,3.27-1Zm-1.73-5.36c-.09-1.43-.77-2.33-2.11-2.33a2.23,2.23,0,0,0-2.27,2.33Z"
          />
          <Path
            fill={fill3}
            d="M196,34.08a6.56,6.56,0,0,1-1.89.23,2.91,2.91,0,0,1-3.15-3.21v-5H189V24.17h1.91v-2l2.58-.83v2.81h2.28v1.89H193.5v4.6c0,1,.32,1.65,1.23,1.65a2.2,2.2,0,0,0,1.15-.27Z"
          />
          <Path
            fill={fill3}
            d="M202.1,34.31a4.89,4.89,0,0,1-5.18-5.12,5.19,5.19,0,1,1,10.37,0A4.89,4.89,0,0,1,202.1,34.31Zm2.5-5.42c0-1.58-.85-2.95-2.5-2.95s-2.48,1.39-2.48,2.95c0,2.09.8,3.42,2.48,3.42S204.6,31,204.6,28.89Z"
          />
          <Path
            fill={fill3}
            d="M222.52,34.08l-5.14-10h0v10h-2.57V20.65h3.3l5.12,10.05h0v-10h2.58V34.08Z"
          />
          <Path
            fill={fill3}
            d="M236.88,34.08V28.19h-5.39v5.89H228.8V20.65h2.69v5.43h5.39V20.65h2.69V34.08Z"
          />
          <Path
            fill={fill3}
            d="M242.71,31.37a6.49,6.49,0,0,0,3,.83c1,0,2.39-.5,2.39-1.73,0-1-.91-1.47-2.23-2-1.79-.69-3.66-1.44-3.66-4,0-2.89,2.17-4.1,4.71-4.1a10,10,0,0,1,3.2.48l-.25,2.21a7.07,7.07,0,0,0-2.73-.57,1.87,1.87,0,0,0-2.12,1.86c0,1,1.15,1.41,2.27,1.85,1.81.67,3.62,1.5,3.62,4s-1.85,4.06-5.06,4.06a11.82,11.82,0,0,1-3.43-.52Z"
          />
          <Path fill={fill3} d="M258.57,34.08V19.65h2.58V34.08Z" />
          <Path
            fill={fill3}
            d="M268.41,34.31a4.89,4.89,0,0,1-5.18-5.12,5.19,5.19,0,1,1,10.37,0A4.89,4.89,0,0,1,268.41,34.31Zm2.5-5.42c0-1.58-.85-2.95-2.5-2.95s-2.48,1.39-2.48,2.95c0,2.09.8,3.42,2.48,3.42S270.91,31,270.91,28.89Z"
          />
          <Path
            fill={fill3}
            d="M282.15,32.5h0A3.53,3.53,0,0,1,279,34.08c-2.73,0-4-2.08-4-5,0-2.39,1.23-5.14,4.2-5.14a3.3,3.3,0,0,1,3,1.58h0V24.17h2.47v9c0,3.35-1.73,5.19-5.2,5.19a8.68,8.68,0,0,1-3.46-.67l.21-2.19a7.3,7.3,0,0,0,2.91.86c2.42,0,3-1.56,3-3.61Zm0-3.48c0-1.52-.54-3.08-2.18-3.08s-2.28,1.33-2.28,3.14c0,1.35.71,3,2.19,3C281.53,32.08,282.15,30.62,282.15,29Z"
          />
          <Path
            fill={fill3}
            d="M287.44,22.36V19.9H290v2.46Zm0,11.72V24.17H290v9.91Z"
          />
          <Path
            fill={fill3}
            d="M299.26,34.08V28.52c0-1.48-.31-2.58-1.67-2.58-1.64,0-2.27,1.58-2.27,3.33v4.81h-2.58V24.17h2.44v1.35h0a3.7,3.7,0,0,1,3.25-1.58c2.27,0,3.37,1.56,3.37,3.89v6.25Z"
          />
          <Polygon
            fill={fill2}
            points="18.54 14.54 18.54 40.46 84.42 40.46 84.42 14.54 18.54 14.54 18.54 14.54"
          />
          <Path
            fill={fill3}
            d="M85.05,41V14H18V41ZM45,16.61,40.33,38.34H33.07l-4.57-15h-.06l-3,15H19.89l4.67-21.73h7.28l4.48,15.07h.06l3.07-15.07Zm20.77,0L61.2,38.34H55.35L57.29,29H50.37l-1.94,9.31H42.58l4.54-21.73H53l-1.72,8.31h6.91l1.72-8.31Zm16.81.59-1.41,4.33a11,11,0,0,0-4.82-1c-2.31,0-4.19.34-4.19,2.09,0,3.08,8.48,1.93,8.48,8.53,0,6-5.6,7.56-10.67,7.56a24.58,24.58,0,0,1-6.76-1.12l1.38-4.42a11.37,11.37,0,0,0,5.38,1.25c1.81,0,4.66-.35,4.66-2.59,0-3.49-8.48-2.18-8.48-8.31,0-5.61,4.94-7.29,9.73-7.29a17.91,17.91,0,0,1,6.7,1Z"
          />
        </G>
      </G>
    </Svg>
  );
};
