import { Scene } from '@bighealth/types';
/**
 * Debug data
 */
const getSceneForEffectServiceCalls = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      childNodes: [
        {
          text: 'Test service call',
          component: 'Button',
          action: {
            type: 'repeat_current_sceneset',
            effects: {
              triggerServiceCalls: {
                SleepDiary: {
                  v1: {
                    extrapolate_missing_diaries_from_past_seven_days: true,
                  },
                },
              },
            },
          },
        },
      ],
    },
  ],
});

export { getSceneForEffectServiceCalls };
