import React, { createContext, useReducer } from 'react';

import { noOpFunction } from 'lib/noOpFunction';

type ContentContextState = [
  ContentState,
  React.Dispatch<Partial<ContentState>>
];

type ContentState = {
  isSingleClickEnabledQuestion: boolean;
};

const initialState: ContentState = {
  isSingleClickEnabledQuestion: false,
};

export const ContentStateContext = createContext<ContentContextState>([
  initialState,
  noOpFunction,
]);

export const ContentContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const state = useReducer(
    (currentState: ContentState, stateToUpdate: Partial<ContentState>) => ({
      ...currentState,
      ...stateToUpdate,
    }),
    initialState
  );

  return (
    <ContentStateContext.Provider value={state}>
      {children}
    </ContentStateContext.Provider>
  );
};
