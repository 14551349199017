import styled from 'styled-components/native';

import { DOMAIN } from './constants';

export const HeadingContainer = styled.View`
  max-width: 600px;
`;
HeadingContainer.displayName = `${DOMAIN}.HeadingContainer`;

export const TipContainer = styled.View`
  max-width: 600px;
`;
TipContainer.displayName = `${DOMAIN}.TipContainer`;
