import { useCallback } from 'react';

import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';

import { useContentRedirectContext } from 'components/ProvidersContainer/ContentRedirectProvider';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { PreviousHistoryState } from 'components/Routes/types';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';
import {
  useLazyQueryTransitionSceneSetWithAssets,
  useQueryProduct,
  useQueryProgram,
} from 'lib/api/reactQueryHelpers';
import getPathForParams from 'lib/player/getPathForParams';
import { getIsSceneAtEndOfSceneSet } from 'lib/player/sceneSetHelpers';
import { getNextPath } from 'lib/player/sceneSetHelpers/getNextPath';

import { calcDidSceneSetChange } from './useActionHandler/helpers/calcDidSceneSetChange';

export type UseNavigateToNextContent = () => void;

/**
 * A hook that roles up some of the calculations necessary to determine which
 * Scene (potentially within the current or a new SceneSet) the browser
 * should navigate to.
 *
 */
export const useNavigateToNextContent = (): UseNavigateToNextContent => {
  const {
    productReference,
    sceneSetGraphId,
    sceneSetId,
    sceneId,
  } = useSafeParams<SceneSetParams>();
  const history = useHistory<PreviousHistoryState>();
  const programId = useQueryProgram()?.data?.result.id;
  const productId = useQueryProduct()?.data?.result.id;
  const queryClient = getQueryClient();

  const currentPath = history.location.pathname;
  const transitionSceneSetWithAssets = useLazyQueryTransitionSceneSetWithAssets();
  const currentSceneSet = queryClient.getQueryData<
    jump_to_specific_scene_set.Response
  >(['SceneSet', sceneSetId])?.result?.scene_set_json;
  const isSceneAtEndOfSceneSet = getIsSceneAtEndOfSceneSet(
    currentSceneSet,
    sceneId
  );
  const { exitContent } = useContentRedirectContext();

  return useCallback(async () => {
    if (isSceneAtEndOfSceneSet) {
      const nextSceneSetResult = await transitionSceneSetWithAssets({
        current_graph_id: sceneSetGraphId,
        current_product_id: productId as number,
        current_program_id: programId as number,
        current_scene_set_id: sceneSetId,
        enable_inter_graph_transitions: true,
      });
      const nextSceneSet = nextSceneSetResult?.result;
      const didSceneSetChange = calcDidSceneSetChange(
        currentPath,
        history.location.pathname
      );
      if (didSceneSetChange) {
        // Do not fetch the next SceneSet if the current path (history.location.pathname)
        // is not equal to the previous SceneSet (currentPath).
        return;
      }
      if (nextSceneSet) {
        // We're still in the same SSG here so we can keep that in the path
        const path = getPathForParams({
          productReference,
          sceneSetGraphId: nextSceneSet.scene_set_graph_id ?? sceneSetGraphId,
          sceneSetId: nextSceneSet.id,
          sceneId: 0,
        });
        history.push(path, {
          pathname: history.location.pathname,
        });
      } else {
        exitContent({ fallback: 'home', productReference });
        return;
      }
    } else {
      // Just move to the next Scene
      if (currentSceneSet) {
        const nextPath = getNextPath(currentPath);
        history.push(nextPath, {
          pathname: history.location.pathname,
        });
      }
    }
  }, [
    currentPath,
    currentSceneSet,
    exitContent,
    history,
    isSceneAtEndOfSceneSet,
    productId,
    productReference,
    programId,
    sceneSetGraphId,
    sceneSetId,
    transitionSceneSetWithAssets,
  ]);
};
