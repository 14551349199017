import * as React from 'react';
import styled from 'styled-components/native';

import { useNavigationBarContext } from 'components/Navigation/providers/NavigationBarProvider';
import { CONTENT_MIN_REFERENCE_DIMENSION } from 'components/ResponsiveLayout';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { Text as TextUnstyled } from 'components/sceneset-components/Text';
import { FooterTheme } from 'config/getThemeForProducts/utils/createTheme/footerSchemesTypes';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { COPYRIGHT_TEXT } from 'lib/strings';
import { getTheme } from 'lib/styles/getTheme';

import { useProductStreamVersion } from './hooks/useProductStreamVersion/useProductStreamVersion';
import { useScreenWidth } from './hooks/useScreenWidth';

type FooterState = {
  footerTheme?: FooterTheme;
};

const DOMAIN = 'Footer';

const Text = styled(TextUnstyled)<{ footerTheme: string }>`
  font-size: 20px;
  align-items: center;
  color: ${getTheme('footer[footerTheme].color')};
`;
Text.displayName = `${DOMAIN}.Text`;

const PortraitText = styled(Text)`
  font-size: 16px;
`;
PortraitText.displayName = `${DOMAIN}.PortraitText`;

const Wrapper = styled.View<WrapperProps>`
  width: 100%;
  height: 71px;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${getTheme('footer[footerTheme].backgroundColor')};
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

const PortraitWrapper = styled(Wrapper)`
  height: 80px;
  padding-left: 37px;
  padding-right: 37px;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

type FooterProps = React.ComponentProps<typeof Wrapper> &
  RoleProps &
  FooterState;
type WrapperProps = { color: string; footerTheme: string };

export const GenericFooter = (
  props: FooterProps
): React.ReactElement | null => {
  const { footerTheme = FooterTheme.Light } = props;
  const screenWidth = useScreenWidth();
  const productStreamVersion = useProductStreamVersion({
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });
  const { isCollapsed } = useNavigationBarContext();
  const isNarrow = screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION;

  if (isCollapsed(screenWidth)) {
    return null;
  }

  if (isNarrow) {
    return (
      <PortraitWrapper
        {...props}
        footerTheme={footerTheme}
        {...roles.pass(props, DOMAIN)}
      >
        <PortraitText
          text={COPYRIGHT_TEXT}
          footerTheme={footerTheme}
          style={{ marginBottom: 8 }}
        />
        <PortraitText text={productStreamVersion} footerTheme={footerTheme} />
      </PortraitWrapper>
    );
  }

  return (
    <Wrapper
      {...props}
      footerTheme={footerTheme}
      {...roles.pass(props, DOMAIN)}
    >
      <Text text={productStreamVersion} footerTheme={footerTheme} />
      <Text text={COPYRIGHT_TEXT} footerTheme={footerTheme} />
    </Wrapper>
  );
};
