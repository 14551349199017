import { SceneSet } from '@bighealth/types';

import { getSceneHasAV } from './getSceneHasAV';

export const getIsScenePlayable = (
  sceneSet: SceneSet | undefined,
  sceneId: number | undefined
): boolean => {
  if (!sceneSet) {
    return false;
  }
  const sceneIdAsNumber = sceneId || 0;
  const scene = sceneSet.childNodes[sceneIdAsNumber];
  if (!scene) {
    return false;
  }
  return getSceneHasAV(scene);
};
