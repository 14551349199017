/* eslint-disable @typescript-eslint/no-unused-vars */
import { HistoryType } from 'components/Routes/matchesRoutes';

import {
  MessagingProfileDataFields,
  OverloadedTrackEventFcn,
  TrackableMessagingEventNames,
} from './messagingUtils.types';

export const initMessaging = async (
  _history: HistoryType,
  _programId: number | undefined
) => {
  return;
};

export const setMessagingEmail = async (
  _email: string | null,
  _programId?: number | undefined
) => {
  return;
};

export const getMessagingEmail = async (
  _programId: number | undefined
): Promise<string | undefined> => {
  return;
};

export const updateUserMessagingProfile = async (
  _dataFields: MessagingProfileDataFields,
  _programId: number | undefined
) => {
  return;
};

export const isMessagingEnabled = (
  _programId: number | undefined
): undefined => {
  return;
};

export const trackEvent: OverloadedTrackEventFcn = (
  _eventName: TrackableMessagingEventNames,
  _programId: number | undefined,
  _dataFields?: unknown
) => {
  return;
};
