import { LocalNotificationConfig, NotificationGroupConfigId } from './types';

export const getConfigFromNotificationsToShowById = (
  notificationsToShow: LocalNotificationConfig['notificationsToShow'],
  notificationGroupConfigId: NotificationGroupConfigId
) => {
  return (
    notificationsToShow.find(
      notifGroup =>
        notifGroup?.bhNotificationGroupConfig?.id === notificationGroupConfigId
    ) ?? null
  );
};
