import { View } from 'react-native';
import styled from 'styled-components/native';

import { SettingsContentText } from '../../SettingsScreen.styled';

export const LogoContainerView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 150px;
  margin: 0 auto 40px;
  width: 100%
  border: ${({ theme }) => theme.color.inputOutlineGrey};
  border-radius: 8px;
`;

export const ButtonContainer = styled(View)<{
  paddingTop?: number;
}>`
  padding-bottom: 20px;
  padding-vertical: 10px;
  padding-horizontal: 20px;
`;

export const ReminderSubText = styled(SettingsContentText)<{
  bold?: boolean;
  fontSize?: number;
  marginBottom?: number;
  lineHeight?: number;
}>`
  text-align: center;
  color: ${({ theme }) => theme.color.text.placeholder};
`;

export const FooterView = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const UIScreenView = styled.View`
  position: relative;
  height: 100%;
`;
