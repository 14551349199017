import React, { useState } from 'react';

import { mockedMFAScenes } from '../../MFAMockedData';
import {
  MFAEnableComponentOptions,
  MFASceneOptionDisabledType,
  MFASceneOptionType,
  MFASceneTypes,
} from '../../types';
import MFASectionWrapper, { MFASectionWrapperBase } from '../MFASectionWrapper';
import { isDaylight } from '../shared/crossPlatformGetProductTmp';
import MFAToggleView from '../shared/MFAToggleView';
import { isEnableFlow } from '../utils';

import MFAEnableCopyClipboard from './MFAEnableCopyClipboard';
import MFAEnableVerifyCode from './MFAEnableVerifyCode';

// The Base Wrapper for most of the views on a onContinue function
//  But the this file is where the Navigation for onContinue lives at
//  so remove it from the TYPE.
type MFAEnabledFlowProps = Omit<MFASectionWrapperBase, 'onContinue'> & {
  toggleMFAFlag: () => void;
};

const initialMfaSceneKeyName: MFASceneOptionType = isDaylight()
  ? MFASceneTypes.ToggleView
  : MFASceneTypes.AuthenticationStart;

const MFAEnabledFlow = (props: MFAEnabledFlowProps): React.ReactElement => {
  const { onRequestClose, toggleMFAFlag } = props;
  const EnableFlow = mockedMFAScenes['enable'];
  const [MFASceneKeyName, setMFASceneKeyName] = useState<MFASceneOptionType>(
    initialMfaSceneKeyName
  );

  const navigate = (
    navigateSection: MFASceneOptionDisabledType | MFASceneOptionType | undefined
  ) => {
    // Got tired of fighting with Typescript
    // Will fix before merge.
    if (navigateSection === undefined) {
      return;
    }
    if (!isEnableFlow(navigateSection)) {
      return;
    }
    setMFASceneKeyName(navigateSection);
  };

  // Get the content of the Screen for the Workflow
  const sceneContent = EnableFlow[MFASceneKeyName];

  // Default is to render the MFASectionWrapper
  let component: React.ReactElement;

  if (!isDaylight() && MFASceneKeyName === MFASceneTypes.AuthenticationStart) {
    sceneContent.previous = undefined;
  }

  // See if component is for Showing the MFA Code or Verify MFA
  // Logic for these are local to those components.
  switch (sceneContent.component) {
    case MFAEnableComponentOptions.MFAToggle:
      component = (
        <MFAToggleView
          onRequestClose={onRequestClose}
          onContinue={navigate}
          isMFAEnabled={false}
          {...sceneContent}
        />
      );
      break;
    case MFAEnableComponentOptions.MFAEnableCopyClipboard:
      component = (
        <MFAEnableCopyClipboard
          onRequestClose={onRequestClose}
          onContinue={navigate}
          {...sceneContent}
        />
      );
      break;
    case MFAEnableComponentOptions.MFAEnableVerifyCode:
      component = (
        <MFAEnableVerifyCode
          onRequestClose={onRequestClose}
          onContinue={navigate}
          toggleMFAFlag={toggleMFAFlag}
          {...sceneContent}
        />
      );
      break;
    default:
      component = (
        <MFASectionWrapper
          onRequestClose={onRequestClose}
          onContinue={navigate}
          content={sceneContent}
          toggleMFAFlag={toggleMFAFlag}
          screenIdentifier={`MFA_EnableFlow_${MFASceneKeyName}`}
        />
      );
      break;
  }
  return component;
};

export default MFAEnabledFlow;
