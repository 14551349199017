import React, { useMemo } from 'react';

import * as roleProps from 'cross-platform/utils/roleProps';

import { ScreenContext, ScreenContextDisabled } from '../../types';
import { MFASectionWrapperBase } from '../MFASectionWrapper';
import {
  ErrorHintText,
  ErrorHintWrapper,
  ErrorTextMFA,
  ErrorWrapper,
} from '../styled/styled';

type MFAErrorMessageParams = Pick<MFASectionWrapperBase, 'onContinue'> & {
  content: ScreenContext | ScreenContextDisabled;
};
const MFAErrorMessage = (props: MFAErrorMessageParams): React.ReactElement => {
  const { content, onContinue } = props;

  const roleLabels = useMemo(
    () =>
      [content.inputHint?.error, content.inputHint?.text_for_continue]
        .filter(value => !!value)
        .join(' '),
    [content]
  );

  return (
    <ErrorWrapper>
      <ErrorTextMFA
        {...roleProps.pass(props, undefined, {
          default: 'MFAModalErrorMessage',
        })}
        role="alert"
        accessibilityRole="alert"
        accessibilityLabel={roleLabels}
        aria-label={roleLabels}
      >
        {content.inputHint?.error ? content.inputHint.error : null}
        {content.inputHint?.text_for_continue && (
          <ErrorHintWrapper>
            <ErrorHintText
              accessibilityRole="button"
              role="button"
              onPress={() => onContinue(content.previous)}
              accessibilityLabel={content.inputHint?.text_for_continue}
              accessible
            >
              {content.inputHint?.text_for_continue}
            </ErrorHintText>
          </ErrorHintWrapper>
        )}
      </ErrorTextMFA>
    </ErrorWrapper>
  );
};

export default MFAErrorMessage;
