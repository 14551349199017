import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { IconStyles } from './types';

export const Home = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 23 22" style={style}>
      <G stroke="none" fill="none" fillRule="evenodd">
        <Path
          fill={style?.color || 'currentColor'}
          d="M12.114 1.15285C11.9456 1.00081 11.7267 0.916656 11.4998 0.916656C11.2729 0.916656 11.0541 1.00081 10.8857 1.15285L1.719 9.42666C1.62404 9.51237 1.54807 9.61701 1.49597 9.73385C1.44388 9.85068 1.41681 9.97713 1.4165 10.1051V20.1666C1.4165 20.4097 1.51308 20.6429 1.68499 20.8148C1.8569 20.9867 2.09006 21.0833 2.33317 21.0833H8.74984C8.99295 21.0833 9.22611 20.9867 9.39802 20.8148C9.56993 20.6429 9.6665 20.4097 9.6665 20.1666V15.5827H13.3332V20.1666C13.3332 20.4097 13.4297 20.6429 13.6017 20.8148C13.7736 20.9867 14.0067 21.0833 14.2498 21.0833H20.6665C20.9096 21.0833 21.1428 20.9867 21.3147 20.8148C21.4866 20.6429 21.5832 20.4097 21.5832 20.1666V10.1051C21.5829 9.97713 21.5558 9.85068 21.5037 9.73385C21.4516 9.61701 21.3756 9.51237 21.2807 9.42666L12.114 1.15285Z"
        />
      </G>
    </Svg>
  );
};
