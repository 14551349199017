import React, { ReactElement, useContext } from 'react';
import {
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { ThemeContext } from 'styled-components';

import { StyleObject } from '@bighealth/types/src/scene-components/client';

import {
  ModalStyles,
  useTransformStylesToContext,
} from 'components/ResponsiveLayout';

const flatten = StyleSheet.flatten;

export const ButtonContainer: React.FC<ViewProps> = ({
  style,
  ...rest
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyles = useTransformStylesToContext(theme);
  const styles = {
    ...flatten(style),
    marginBottom: ModalStyles.modalQuestionMarginBottom,
  };
  return (
    <View
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};
