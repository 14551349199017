import React from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { useGetDynamicContentStyles } from 'components/ResponsiveLayout/hooks';
import {
  BackIconSvg,
  MFACloseButtonSvg,
} from 'components/Screens/ContentScreens';
import { roles } from 'cross-platform/utils/roleProps';

import {
  BackButtonWrapper,
  CloseButtonWrapper,
  HeaderView,
} from '../styled/styled';

type MFAModalHeaderProps = {
  navigate: () => void;
  onRequestClose: () => void;
  showBackButton?: boolean;
};

const MFAModalHeader = (props: MFAModalHeaderProps): React.ReactElement => {
  const safeArea = useSafeArea();
  const styles = useGetDynamicContentStyles();

  const onBackButtonPress = () => {
    props.navigate();
  };
  const onCloseButtonPress = () => {
    props.onRequestClose();
  };
  return (
    <HeaderView>
      {props.showBackButton ? (
        <BackButtonWrapper
          accessibilityLabel="Go back"
          aria-label="Go back"
          accessibilityRole="button"
          role="button"
          safeArea={safeArea}
          onPress={onBackButtonPress}
          {...roles('MFAModalBackButton')}
          style={{
            paddingTop: styles.sleepDiaryFormCloseButtonPaddingPortrait,
          }}
        >
          <BackIconSvg />
        </BackButtonWrapper>
      ) : null}
      <CloseButtonWrapper
        aria-label="Close modal"
        accessibilityLabel="Close modal"
        role="button"
        accessibilityRole="button"
        safeArea={safeArea}
        onPress={onCloseButtonPress}
        {...roles('MFAModalCloseButton')}
        style={{
          paddingTop: styles.sleepDiaryFormCloseButtonPaddingPortrait,
        }}
      >
        <MFACloseButtonSvg />
      </CloseButtonWrapper>
    </HeaderView>
  );
};

export default MFAModalHeader;
