import React, { ReactElement } from 'react';

import { LoginFooter } from 'components/Footer';
import { roles } from 'cross-platform/utils/roleProps';
import { useRedirectIsAuthenticated } from 'lib/api/hooks/useRedirectIsAuthenticated';

import { EmptyBackground } from './styled';

type EmptyScreenProps = {
  showFooter?: boolean;
};

const EmptyScreen = ({ showFooter = true }: EmptyScreenProps): ReactElement => {
  useRedirectIsAuthenticated(true);

  return (
    <EmptyBackground {...roles('EmptyScreen')}>
      {showFooter && <LoginFooter />}
    </EmptyBackground>
  );
};

export default EmptyScreen;
