import * as React from 'react';
import { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

import { Accessibility } from '@bighealth/types';
import {
  SceneAction,
  ViewStylesObject,
} from '@bighealth/types/src/scene-components/client';

import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { accessibility } from 'cross-platform/utils/accessibilityProps';
import { roles } from 'cross-platform/utils/roleProps';
import { mediaPlayerOrchestrator } from 'lib/player/media/MediaPlayerOrchestrator';
import { toggleVisibilityOrchestrator } from 'lib/player/media/ToggleVisibilityOrchestrator';
import useActionHandler from 'lib/player/useActionHandler';
import { showControls } from 'state/player/actions';

import { Image, ImageProps } from './Image';

const uniqueContainerStyleProperties = [
  'position',
  'top',
  'right',
  'bottom',
  'left',
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
];

const commonStyleProperties = ['height', 'width'];

// Small hack to make TypeScript assignment in the for loop a bit easier to read!
type SimpleStyle = Record<
  typeof uniqueContainerStyleProperties[number],
  number | undefined | string
>;

export const getStylesForContainers = (
  style: ViewStylesObject = {}
): {
  containerStyles: ViewStylesObject;
  imageStyles: ViewStylesObject;
} => {
  const containerStyles: SimpleStyle = {};
  const imageStyles: SimpleStyle = {};
  for (const [property, value] of Object.entries(style)) {
    if (uniqueContainerStyleProperties.includes(property)) {
      containerStyles[property] = value;
    } else if (commonStyleProperties.includes(property)) {
      imageStyles[property] = value;
      containerStyles[property] = value;
    } else {
      imageStyles[property] = value;
    }
  }
  return { containerStyles, imageStyles };
};

type ActionImageProps = ImageProps & {
  action?: SceneAction;
  accessibility?: Accessibility;
};

export const ActionImage = ({
  action,
  style,
  ...rest
}: ActionImageProps): ReactElement => {
  const transformStylesToContext = useTransformStylesToContext();
  const pressAction = useActionHandler(action);
  const dispatch = useDispatch();
  const handlePress = async (): Promise<void> => {
    // SceneComponents with click handlers should release any locks as they are
    // a signal that the user wants to do something. For instance, if the locks
    // are set as a result of the app being backgrounded then when the user
    // presses a button they are indicating they wish to proceed normally
    mediaPlayerOrchestrator.releasePlayLock();
    toggleVisibilityOrchestrator.releasePlayLock();
    dispatch(showControls(false));
    if (typeof pressAction === 'function') {
      await pressAction();
    }
  };
  if (typeof action?.type === 'string') {
    const { containerStyles, imageStyles } = getStylesForContainers(style);
    // The image is scaled so we need to scale the container here too
    const scaledContainerStyles = transformStylesToContext(containerStyles);
    return (
      <TouchableOpacity
        {...roles('ActionImage')}
        {...accessibility(rest?.accessibility)}
        onPress={handlePress}
        style={scaledContainerStyles}
      >
        <Image {...rest} style={imageStyles} />
      </TouchableOpacity>
    );
  }
  return (
    <Image
      {...roles('NonActionImage')}
      {...accessibility(rest?.accessibility)}
      {...rest}
      style={style}
      altText={rest?.altText}
    />
  );
};
