import { DiaryEntry } from '@bighealth/types/dist/services/SleepDiaryPayloads/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const create_single_day_entry = async (
  args: create_single_day_entry.Args,
  requestConfig?: AxiosRequestConfig
): Promise<create_single_day_entry.Response> => {
  const request: create_single_day_entry.Request = {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'create_single_day_entry',
    args,
  };

  return await api(request, requestConfig);
};

create_single_day_entry.queryKey = 'SleepDiary/create_single_day_entry';

export declare namespace create_single_day_entry {
  export type Args = DiaryEntry;

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'create_single_day_entry';
    args: Args;
  }

  export type Result = DiaryEntry;

  export type Response = APIResponse<Result>;
}
