import React, { ReactElement } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClientProvider } from 'react-query';

import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import {
  ScalingContextProvider,
  ScreenResponsiveLayoutProvider,
} from 'components/ResponsiveLayout';
import { Router } from 'cross-platform/react-router';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import ReduxProvider from 'state/ReduxProvider';

import { ErrorBoundaryForApp } from '../../App/components/ErrorBoundaryForApp';

import { ContentRedirectProvider } from './ContentRedirectProvider';
import { getQueryClient } from './getQueryClient';
import { KeyboardDimensionsProviders } from './KeyboardDimensionsProviders';
import ProductThemeProvider from './ProductThemeProvider';
import { ScreenOrientationProvider } from './ScreenOrientationProvider';

const ProvidersContainer = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  return (
    <Router>
      <ErrorBoundaryForApp
        fallbackRenderWithWrapper={withContentPlaceholderWrapper}
      >
        <ProvidersContainerWithoutRouter>
          {children}
        </ProvidersContainerWithoutRouter>
      </ErrorBoundaryForApp>
    </Router>
  );
};

export const ProvidersContainerWithoutRouter = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <KeyboardDimensionsProviders>
        <ReduxProvider>
          <EphemeralStateProvider>
            <SafeAreaProvider>
              <ScreenResponsiveLayoutProvider>
                <ScalingContextProvider>
                  <ProductThemeProvider>
                    <ScreenOrientationProvider>
                      <ContentRedirectProvider>
                        {children}
                      </ContentRedirectProvider>
                    </ScreenOrientationProvider>
                  </ProductThemeProvider>
                </ScalingContextProvider>
              </ScreenResponsiveLayoutProvider>
            </SafeAreaProvider>
          </EphemeralStateProvider>
        </ReduxProvider>
      </KeyboardDimensionsProviders>
    </QueryClientProvider>
  );
};

export default ProvidersContainer;
