import React from 'react';

import { StoryPopupModalWithState } from 'components/PopupModal/index.story';
import { addStory } from 'lib/story-books';

import { payload } from './components/Form/stories';
import { SleepDiaryWithDefaults } from './components/Form/stories/WithDefaults.story';

addStory('sleep-diary/Form WithDefaults PopupModal', () => (
  <StoryPopupModalWithState>
    <SleepDiaryWithDefaults data={payload} />
  </StoryPopupModalWithState>
));
