import storage from '@react-native-community/async-storage';
import deepEqual from 'fast-deep-equal';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { get_user_info } from '@bighealth/api/UserAccount/v1';

import { UserAction, UserActionTypes } from './actions';

export type ABTestVariants = Partial<
  /* "&" new variant types here */
  get_user_session_screen_data.Result['ab_test_variants']
>;

export type ABTestVariantsProps = {
  abTestVariants?: ABTestVariants;
};

export const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['user'],
};

export interface UserState extends PersistPartial {
  isFirstLogin: boolean;
  isFirstInit: boolean;
  user?: {
    info?: get_user_info.Result;
    abTestVariants?: ABTestVariants;
  };
  _persist: Readonly<{
    version: number;
    rehydrated: boolean;
  }>;
}

export const initialUserState: UserState = {
  isFirstLogin: true,
  isFirstInit: true,
  _persist: {
    version: 0,
    rehydrated: false,
  },
};

export const userReducer = (
  state = initialUserState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case UserActionTypes.SET_IS_FIRST_LOGIN:
      return {
        ...state,
        isFirstLogin: action.payload,
      };
    case UserActionTypes.ASSIGN_USER_AB_TEST_VARIANTS: {
      const newABTestVariants = {
        ...(state.user?.abTestVariants || {}),
        ...action.payload,
      };
      if (deepEqual(state.user?.abTestVariants, newABTestVariants)) {
        return state; // No change
      }
      return {
        ...state,
        user: {
          ...(state.user || {}),
          abTestVariants: newABTestVariants,
        },
      };
    }
    case UserActionTypes.SET_USER_INFO:
      return {
        ...state,
        user: {
          ...(state.user || {}),
          info: action.payload,
        },
      };
    case UserActionTypes.SET_IS_FIRST_INIT:
      return {
        ...state,
        isFirstInit: action.payload,
      };
    /**
     * Info: userInfo is reset by action "state/reset"
     */
    default:
      return state;
  }
};
