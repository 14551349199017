import React from 'react';
import { TextProps } from 'react-native';

import { TextTypes } from 'common/constants/enums';

import HelperText from '../HelperText';

const ErrorText = (props: TextProps) => (
  <HelperText presentationType={TextTypes.ERROR} {...props} />
);

export { ErrorText };
