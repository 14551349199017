import { Question } from '@bighealth/types/src';

import {
  AWAKENINGS_IN_NIGHT,
  SLEEP_QUALITY,
  TIME_FINAL_AWAKENING,
  TIME_GET_OUT_OF_BED,
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
} from 'components/SleepDiaryForm/constants';

import { cloneAndTransformQuestionProps } from '../cloneAndTransformQuestionProps';

const transformBySemanticId = (questionProps: Question): Question => {
  switch (questionProps.semantic_id) {
    case TIME_TRY_TO_SLEEP:
    case TIME_FINAL_AWAKENING:
    case TIME_GET_OUT_OF_BED:
    case TIME_INTO_BED:
    case AWAKENINGS_IN_NIGHT:
    case SLEEP_QUALITY:
      return cloneAndTransformQuestionProps(questionProps, {
        clearValidationSchema: true,
      });
    default:
      return questionProps;
  }
};

export { transformBySemanticId };
