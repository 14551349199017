import { useCallback } from 'react';

import { useParams } from 'cross-platform/react-router';

type GetContentCallback = (tab?: string) => string;

export const useGetIFUScreenPath = (): GetContentCallback => {
  const { productReference } = useParams<Record<string, string | undefined>>();

  // Today it is SLEEPIO which IFU's live under /sleepio/downloads/:slug
  // Tomorrow, it could be for DAYLIGHT which could live under /daylight/SOMEWHERE_ELSE/:slug
  let download_param: string;
  switch (productReference) {
    case 'sleepio':
      download_param = 'downloads';
      break;

    default:
      download_param = 'downloads';
      break;
  }
  return useCallback(
    (slug?) => {
      return `/${productReference}/${download_param}/${slug || 'initial'}`;
    },
    [productReference, download_param]
  );
};
