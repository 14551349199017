import {
  NotificationFeatureFlag,
  NotificationGroupConfigId,
} from 'config/localNotifications';

import { NotificationUserSetting } from './reducer';

export enum NotificationsActionTypes {
  ADD_NOTIFICATION_USER_SETTING = 'notifications/ADD_NOTIFICATION_USER_SETTING',
  INCREMENT_OS_NOTIFICATION_PERMISSIONS_REQUEST_COUNT = 'notifications/INCREMENT_OS_NOTIFICATION_PERMISSIONS_REQUEST_COUNT',
  REMOVE_NOTIFICATION_USER_SETTING_BY_ID = 'notifications/REMOVE_NOTIFICATION_USER_SETTING_BY_ID',
  SET_HAS_PRE_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED = 'notifications/SET_HAS_PRE_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED',
  SET_HAS_POST_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED = 'notifications/SET_HAS_POST_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED',
  SET_HAS_DEFAULT_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED = 'notifications/SET_HAS_DEFAULT_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED',
  SET_NOTIFICATION_FEATURE_FLAG = 'notifications/SET_NOTIFICATION_FEATURE_FLAG',
  SET_NOTIFICATION_USER_SETTING_BY_ID = 'notifications/SET_NOTIFICATION_USER_SETTING_BY_ID',
  SET_NOTIFICATION_USER_SETTINGS = 'notifications/SET_NOTIFICATION_USER_SETTINGS',
}

export type IncrementOsNotificationPermissionsRequestCount = {
  type: NotificationsActionTypes.INCREMENT_OS_NOTIFICATION_PERMISSIONS_REQUEST_COUNT;
};

export type AddNotificationUserSetting = {
  type: NotificationsActionTypes.ADD_NOTIFICATION_USER_SETTING;
  payload: NotificationUserSetting;
};

export type RemoveNotificationUserSettingById = {
  type: NotificationsActionTypes.REMOVE_NOTIFICATION_USER_SETTING_BY_ID;
  payload: NotificationGroupConfigId;
};

export type SetNotificationUserSettingById = {
  type: NotificationsActionTypes.SET_NOTIFICATION_USER_SETTING_BY_ID;
  payload: NotificationUserSetting;
};

export type SetNotificationFeatureFlag = {
  type: NotificationsActionTypes.SET_NOTIFICATION_FEATURE_FLAG;
  payload: NotificationFeatureFlag;
};

export type SetHasDefaultNotificationModalPopUpBeenPrompted = {
  type: NotificationsActionTypes.SET_HAS_DEFAULT_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED;
};

export type SetHasPreSleepDiaryNotificationModalPopUpBeenPrompted = {
  type: NotificationsActionTypes.SET_HAS_PRE_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED;
};

export type SetHasPostSleepDiaryNotificationModalPopUpBeenPrompted = {
  type: NotificationsActionTypes.SET_HAS_POST_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED;
};

export type SetAllNotificationUserSettingById = {
  type: NotificationsActionTypes.SET_NOTIFICATION_USER_SETTINGS;
  payload: NotificationUserSetting[];
};

export type NotificationsAction =
  | AddNotificationUserSetting
  | IncrementOsNotificationPermissionsRequestCount
  | RemoveNotificationUserSettingById
  | SetHasDefaultNotificationModalPopUpBeenPrompted
  | SetHasPreSleepDiaryNotificationModalPopUpBeenPrompted
  | SetHasPostSleepDiaryNotificationModalPopUpBeenPrompted
  | SetNotificationFeatureFlag
  | SetNotificationUserSettingById
  | SetAllNotificationUserSettingById;

export const incrementOsNotificationPermissionsRequestCount = (): IncrementOsNotificationPermissionsRequestCount => ({
  type:
    NotificationsActionTypes.INCREMENT_OS_NOTIFICATION_PERMISSIONS_REQUEST_COUNT,
});
export const addNotificationUserSetting = (
  notificationUserSetting: NotificationUserSetting
): AddNotificationUserSetting => ({
  type: NotificationsActionTypes.ADD_NOTIFICATION_USER_SETTING,
  payload: notificationUserSetting,
});
export const removeNotificationUserSettingById = (
  notificationId: string
): RemoveNotificationUserSettingById => ({
  type: NotificationsActionTypes.REMOVE_NOTIFICATION_USER_SETTING_BY_ID,
  payload: notificationId,
});
export const setNotificationUserSettingById = (
  notificationUserSetting: NotificationUserSetting
): SetNotificationUserSettingById => ({
  type: NotificationsActionTypes.SET_NOTIFICATION_USER_SETTING_BY_ID,
  payload: notificationUserSetting,
});
export const setNotificationFeatureFlag = (
  notificationFeatureFlag: NotificationFeatureFlag
): SetNotificationFeatureFlag => {
  return {
    type: NotificationsActionTypes.SET_NOTIFICATION_FEATURE_FLAG,
    payload: notificationFeatureFlag,
  };
};
export const setHasDefaultNotificationModalPopUpBeenPrompted = (): SetHasDefaultNotificationModalPopUpBeenPrompted => {
  return {
    type:
      NotificationsActionTypes.SET_HAS_DEFAULT_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED,
  };
};
export const setHasPreSleepDiaryNotificationModalPopUpBeenPrompted = (): SetHasPreSleepDiaryNotificationModalPopUpBeenPrompted => {
  return {
    type:
      NotificationsActionTypes.SET_HAS_PRE_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED,
  };
};
export const setHasPostSleepDiaryNotificationModalPopUpBeenPrompted = (): SetHasPostSleepDiaryNotificationModalPopUpBeenPrompted => {
  return {
    type:
      NotificationsActionTypes.SET_HAS_POST_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED,
  };
};
export const setAllNotificationUserSettingById = (
  notificationUserSettings: NotificationUserSetting[]
): SetAllNotificationUserSettingById => {
  return {
    type: NotificationsActionTypes.SET_NOTIFICATION_USER_SETTINGS,
    payload: notificationUserSettings,
  };
};
