import { ProductReferences } from 'common/constants/enums';
import { PLATFORM_ASSETS_URL } from 'config/envVars/assets';

import { Assets } from '.';

const getAssetsForProduct = (productReference: string): Assets => {
  switch (productReference) {
    case ProductReferences.DAYLIGHT:
      return {
        splashLogoUrl: `${PLATFORM_ASSETS_URL}assets/daylight/splash_logo.png`,
        homeScreenHeaderUrl: `${PLATFORM_ASSETS_URL}assets/daylight/homescreen_header.png`,
        settingsIcon: `${PLATFORM_ASSETS_URL}assets/gear-settings.png`,
        splashScreenStaticUrl: `${PLATFORM_ASSETS_URL}assets/daylight/splash_logo.png`,
        productAvatar: `${PLATFORM_ASSETS_URL}assets/daylight/monster.png`,
        doubter: `${PLATFORM_ASSETS_URL}assets/daylight/doubter.png`,
        dayLightLogo: `${PLATFORM_ASSETS_URL}assets/daylight/daylight_logo.png`,
        sleepioLogo: `${PLATFORM_ASSETS_URL}assets/daylight/sleepio_logo.png`,
      };
    case ProductReferences.SLEEPIO:
      return {
        splashLogoUrl: `${PLATFORM_ASSETS_URL}assets/sleepio/splash_logo.png`,
        splashLogoDarkStrokeLightFill: `${PLATFORM_ASSETS_URL}assets/sleepio/splash_logo_dark_stroke_light_fill.png`,
        homeScreenHeaderUrl: `${PLATFORM_ASSETS_URL}assets/sleepio/homescreen_header.png`,
        settingsIcon: `${PLATFORM_ASSETS_URL}assets/gear-settings.png`,
        notificationsPreview: `${PLATFORM_ASSETS_URL}assets/sleepio/notifications-preview.png`,
        greenCircleCheckMark: `${PLATFORM_ASSETS_URL}assets/sleepio/green-circle-check-mark.png`,
      };
    default:
      throw new Error(`Unsupported product reference ${productReference}`);
  }
};

export { getAssetsForProduct };
