import { Scene, SceneChild } from '@bighealth/types';

export const getSceneHasAV = (scene: Scene): boolean => {
  if (!scene) {
    return false;
  }
  let hasAV = false;
  const traverseTree = ({
    childNodes: rootChildNodes,
    component: rootComponent,
  }: SceneChild): SceneChild => {
    if (rootComponent === 'Video' || rootComponent === 'Audio') {
      hasAV = true;
    }
    return {
      component: rootComponent,
      childNodes: rootChildNodes
        ? rootChildNodes.map(
            ({ component, childNodes }): SceneChild => {
              return traverseTree({
                childNodes,
                component,
              });
            }
          )
        : undefined,
    };
  };
  traverseTree((scene as unknown) as SceneChild);
  return hasAV;
};
