import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';

import {
  ScalingContext,
  ScalingContextProvider,
} from './ScalingContextProvider';

export type SectionScaleValues = {
  max?: number;
  min?: number;
  base: number;
  current: number;
};

// if a context will not provide scaling values,
// min, max, base and current will fallback to 1.
//
// useScaleToSection will use fallback values to run correctly
// all 1s will always result a scale factor of 1, so the content will stay the same
const defaultValues: SectionScaleValues = {
  max: 1,
  min: 1,
  base: 1,
  current: 1,
};

const SectionResponsiveLayoutContext = createContext(defaultValues);

export const useSectionResponsiveLayoutContext = (): SectionScaleValues =>
  useContext(SectionResponsiveLayoutContext);

type SectionResponsiveLayoutProviderProps = {
  children?: ReactNode;
} & SectionScaleValues;

export const SectionResponsiveLayoutProvider = ({
  children,
  ...values
}: SectionResponsiveLayoutProviderProps): ReactElement => {
  return (
    <SectionResponsiveLayoutContext.Provider value={values}>
      <ScalingContextProvider scalingContext={ScalingContext.Section}>
        {children}
      </ScalingContextProvider>
    </SectionResponsiveLayoutContext.Provider>
  );
};
