import React, { useRef, useState } from 'react';
import { ReactElement } from 'react';
import ScreenOrientation, {
  useOrientationChange,
} from 'react-native-orientation-locker';

import {
  DefaultOrientation,
  DefaultOrientationProps,
} from 'components/SceneSetView/types';

type OrientationLockerProps = {
  children: ReactElement;
  allowedOrientation?: string;
};

const getOrientationLockFunction = (
  orientation: string | DefaultOrientation
): (() => void) => {
  if (
    orientation === 'landscape' ||
    orientation === DefaultOrientation.LANDSCAPE
  ) {
    return ScreenOrientation.lockToLandscape;
  } else if (
    orientation === 'portrait' ||
    orientation === DefaultOrientation.PORTRAIT
  ) {
    return ScreenOrientation.lockToPortrait;
  }
  return ScreenOrientation.unlockAllOrientations;
};

export const OrientationLocker = ({
  children,
  defaultOrientation,
  allowedOrientation,
}: OrientationLockerProps & DefaultOrientationProps): ReactElement => {
  const lockedOrientation = useRef<string | undefined | null>(null);
  const lockOrientation = (orientation?: string) => {
    if (lockedOrientation.current !== orientation) {
      if (orientation != undefined) {
        getOrientationLockFunction(orientation)();
      } else {
        ScreenOrientation.unlockAllOrientations();
      }
      lockedOrientation.current = orientation;
    }
  };

  if (typeof allowedOrientation !== 'undefined') {
    lockOrientation(allowedOrientation);
  } else if (typeof defaultOrientation !== 'undefined') {
    lockOrientation(defaultOrientation);
  } else {
    lockOrientation(undefined);
  }

  return children;
};

export const AutoRotateOrientationLocker = ({
  children,
  allowedOrientation,
}: OrientationLockerProps): ReactElement => {
  const [
    calculatedAllowedOrientation,
    setCalculatedAllowedOrientation,
  ] = useState<string | null | undefined>(null);
  useOrientationChange(() => {
    ScreenOrientation.getAutoRotateState(autoRotationState => {
      const o = autoRotationState ? undefined : allowedOrientation;
      if (o !== calculatedAllowedOrientation) {
        setCalculatedAllowedOrientation(o);
      }
    });
  });
  return calculatedAllowedOrientation === null ? (
    children
  ) : (
    <OrientationLocker allowedOrientation={calculatedAllowedOrientation}>
      {children}
    </OrientationLocker>
  );
};
