import React, { ReactElement } from 'react';
import styled from 'styled-components/native';

import { ChevronIconSvg } from 'components/Screens/ContentScreens/components/iconsDictionary';

import { IconStyles } from './types';

const Container = styled.View<{
  isHovering?: boolean;
  pointerEvents?: 'none';
}>``;

const DirectionTable = {
  up: 180,
  right: 270,
  down: 0,
  left: 90,
} as const;

export type ChevronProps = {
  size?: number;
  isHovering?: boolean;
  style?: IconStyles;
  pointerEvents?: 'none';
  direction: keyof typeof DirectionTable;
  directionOption?: string;
};

export const Chevron = ({
  size,
  style,
  pointerEvents,
  direction,
  isHovering,
  directionOption,
}: ChevronProps): ReactElement => {
  return (
    <Container
      pointerEvents={pointerEvents}
      style={{
        transform: [{ rotate: DirectionTable[direction] + 'deg' }],
        ...style,
      }}
      aria-label={directionOption || direction}
      accessibilityLabel={directionOption || direction}
      accessibilityRole="alert"
      aria-hidden={true} // to solve windows accessibility issues
    >
      <ChevronIconSvg isHovering={isHovering} size={size} style={style} />
    </Container>
  );
};
