import { ReactElement } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MonitorView = ({
  children,
}: {
  children: ReactElement;
  viewName: string;
}): ReactElement | null => {
  return children;
};

export { MonitorView };
