import React, { ReactElement } from 'react';

import { Image as ImagePropsWithAction } from '@bighealth/types/src/scene-components/client';

import { ActionImage } from 'components/Media';
import { WithBackupFormikContext } from 'components/WithBackupFormikContext';
import { OptionalFields } from 'lib/utils/generics/OptionalFields';

import { getStylesFromProps } from '../helpers';

export type ImageProps = OptionalFields<ImagePropsWithAction, 'action'>;

const SceneSetImageWithoutBackupFormikContext = (
  props: ImageProps
): ReactElement => {
  const { sourceProps, ...rest } = props;
  const src = sourceProps?.storage_url || '';
  const altText = sourceProps?.alt_text || '';
  return (
    <ActionImage
      {...rest}
      src={src}
      style={getStylesFromProps(props)}
      altText={altText}
    />
  );
};

export const SceneSetImage = (props: ImageProps): ReactElement => {
  return WithBackupFormikContext(
    props,
    SceneSetImageWithoutBackupFormikContext
  );
};

export default SceneSetImage;
