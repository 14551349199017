/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';

import { APIClient } from '@bighealth/api';
import { login_user } from '@bighealth/api/GeneralLogin/v1';
import { LoginDomains } from '@bighealth/types';

import { LOGIN_ERROR } from 'components/Screens/LoginScreen/constants';
import { generalLoginWithEmailAndPassword } from 'lib/api';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import * as reporter from 'lib/reporter';

import { redirectToLegacy } from './helpers/redirectToLegacy/redirectToLegacy';
import { redirectToUrl } from './helpers/redirectToUrl/redirectToUrl';
import {
  APICallFactory,
  ErrorState,
  isErrorState,
  LoginCallback,
  LoginPhases,
  LoginResultStatus,
  LoginWithEmailPasswordConfig,
  OkState,
} from './types';

export function useGeneralLogin(): (ErrorState | OkState) & {
  login: LoginCallback;
} {
  const [loginState, setLoginState] = useState<OkState | ErrorState>({
    status: LoginPhases.IDLE,
    error: null,
  });
  const productId = useQueryProduct()?.data?.result.id;

  const login: LoginCallback = useCallback(
    async config => {
      let apiCallFactory: APICallFactory | null = null;
      const { email, password } = config as LoginWithEmailPasswordConfig;
      apiCallFactory = (): Promise<login_user.Response> =>
        generalLoginWithEmailAndPassword(email, password, {
          productId: productId as number,
        });

      if (typeof apiCallFactory === 'function') {
        try {
          setLoginState({
            status: LoginPhases.FETCHING,
            error: null,
          });
          APIClient.reset();
          const res: login_user.Response = await apiCallFactory();
          if (res.result.status === LoginResultStatus.REDIRECT) {
            const resultDomain = res.result.domain;
            switch (resultDomain) {
              // The cases of platform users
              case LoginDomains.PLATFORM:
                setLoginState({
                  status: LoginPhases.SUCCESS, // TODO: Evan to remove the mapping
                  error: null,
                });
                break;
              case LoginDomains.OTP:
                setLoginState({
                  status: LoginPhases.OTP,
                  error: null,
                });
                break;
              // This status is not used by daylight and should only be encountered for legacy sleepio users
              case LoginDomains.LEGACY:
                redirectToLegacy(res.result.redirect);
                setLoginState({
                  status: LoginPhases.IDLE,
                  error: null,
                });
                break;
              // For onboarding and any other unknown domains, we expect to redirect to a given URL
              case LoginDomains.ONBOARDING:
              default:
                redirectToUrl(res.result.redirect);
                setLoginState({
                  status: LoginPhases.IDLE,
                  error: null,
                });
                break;
            }

            // if the status is 'error', we set the state to ERROR
          } else if (res.result.status === 'error') {
            const error = new Error(LOGIN_ERROR);
            reporter.log(error.message, error, { silent: true });
            setLoginState({
              status: LoginPhases.ERROR,
              error,
            });
          }
        } catch (e) {
          const error =
            e instanceof Error
              ? e
              : typeof e?.message === 'string'
              ? new Error(e?.message)
              : new Error(`${e}`);
          reporter.log(error.message, error, { silent: true });
          setLoginState({
            status: LoginPhases.ERROR,
            error,
          });
        }
      }
    },
    [productId]
  );

  if (isErrorState(loginState)) {
    return {
      error: loginState.error,
      status: LoginPhases.ERROR,
      login,
    };
  }
  return {
    status: loginState.status,
    error: null,
    login,
  };
}
