import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLocalNotificationsConfig } from 'components/LocalNotifications/getLocalNotificationsConfig';
import { NotificationsView } from 'components/LocalNotifications/NotificationsView';
import { NotificationRedirectType } from 'components/Routes/SleepioSwitch/components/WithNotificationScreenRedirect/types';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { getConfigFromNotificationsToShowById } from 'config/localNotifications/getConfigFromNotificationsToShowById';
import {
  SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID,
  SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID,
} from 'config/localNotifications/sleepioConfig';
import { useHistory } from 'cross-platform/react-router';
import { useQuery } from 'lib/router/useQuery';
import {
  addNotificationUserSetting,
  setHasDefaultNotificationModalPopUpBeenPrompted,
  setHasPostSleepDiaryNotificationModalPopUpBeenPrompted,
  setHasPreSleepDiaryNotificationModalPopUpBeenPrompted,
} from 'state/notifications/actions';
import { getNotificationConfigurations } from 'state/notifications/selectors';

import { Preview } from './components/Preview';
import { SleepDiaryReminder } from './components/SleepDiaryReminder';
import {
  DEFAULT_PREVIEW_CONTENT,
  POST_SLEEP_DIARY_PREVIEW_CONTENT,
  PRE_SLEEP_DIARY_PREVIEW_CONTENT,
} from './constants';

export const NotificationScreen = (): ReactElement => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = query.get('redirect');
  const notificationRedirectType = query.get('type');
  const { productReference } = useSafeParams();
  const notificationConfigurations = useSelector(getNotificationConfigurations);

  // creating default notification configurations for diary reminder when
  // - not set previously by user
  // - remote did not provide configurations
  useEffect(() => {
    const { notificationsToShow } = getLocalNotificationsConfig();
    const matchingNotificationConfig = getConfigFromNotificationsToShowById(
      notificationsToShow,
      SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID
    );
    const matchingSessionNotificationConfig = getConfigFromNotificationsToShowById(
      notificationsToShow,
      SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID
    );
    const foundNotificationConfiguration = notificationConfigurations.find(
      config => config.id === SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID
    );
    const foundSessionNotificationConfiguration = notificationConfigurations.find(
      config => config.id === SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID
    );

    if (
      typeof foundNotificationConfiguration === 'undefined' &&
      typeof foundSessionNotificationConfiguration === 'undefined' &&
      matchingNotificationConfig &&
      matchingSessionNotificationConfig
    ) {
      dispatch(
        addNotificationUserSetting({
          id: matchingNotificationConfig.bhNotificationGroupConfig.id,
          isOn: false, // initialize to false
          timeOfDayHours24:
            matchingNotificationConfig.bhNotificationGroupConfig.defaults
              .timeOfDayHours24, // always local time
          timeOfDayMinutes:
            matchingNotificationConfig.bhNotificationGroupConfig.defaults
              .timeOfDayMinutes, // relative to hours
        })
      );
      dispatch(
        addNotificationUserSetting({
          id: matchingSessionNotificationConfig.bhNotificationGroupConfig.id,
          isOn: false, // initialize to false
          timeOfDayHours24:
            matchingSessionNotificationConfig.bhNotificationGroupConfig.defaults
              .timeOfDayHours24, // always local time
          timeOfDayMinutes:
            matchingSessionNotificationConfig.bhNotificationGroupConfig.defaults
              .timeOfDayMinutes, // relative to hours
          date:
            matchingSessionNotificationConfig.bhNotificationGroupConfig.defaults
              .date,
        })
      );
    }
  }, [dispatch, notificationConfigurations]);

  useEffect(() => {
    switch (notificationRedirectType) {
      case NotificationRedirectType.PRE_SLEEP_DIARY:
        dispatch(setHasPreSleepDiaryNotificationModalPopUpBeenPrompted());
        break;
      case NotificationRedirectType.POST_SLEEP_DIARY:
        dispatch(setHasPostSleepDiaryNotificationModalPopUpBeenPrompted());
        break;
      default:
        dispatch(setHasDefaultNotificationModalPopUpBeenPrompted());
        break;
    }
  }, [dispatch, notificationRedirectType]);

  const onClose = () => {
    if (typeof redirect === 'string') {
      history.push(`/${productReference}/${redirect}`);
    } else {
      history.push(`/${productReference}/settings`);
    }
  };

  const determineNotificationPreviewContent = () => {
    switch (notificationRedirectType) {
      case NotificationRedirectType.PRE_SLEEP_DIARY:
        return PRE_SLEEP_DIARY_PREVIEW_CONTENT;
      case NotificationRedirectType.POST_SLEEP_DIARY:
        return POST_SLEEP_DIARY_PREVIEW_CONTENT;
      default:
        return DEFAULT_PREVIEW_CONTENT;
    }
  };

  return (
    <NotificationsView
      renderPreviewView={args => (
        <Preview
          {...args}
          onClose={onClose}
          productReference={productReference}
          content={determineNotificationPreviewContent()}
        />
      )}
      renderReminderView={args => (
        <SleepDiaryReminder
          {...args}
          onClose={onClose}
          productReference={productReference}
        />
      )}
      notificationGroupConfigId={SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID}
      sessionsNotificationGroupConfigId={
        SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID
      }
    />
  );
};
