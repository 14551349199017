import React, { ReactElement, useEffect, useState } from 'react';

import { useSafeParams } from 'components/Routes/useSafeParams';
import useHistory from 'cross-platform/react-router/useHistory';
import { useQueryGetEntryGraphAndSceneSetIds } from 'lib/api/reactQueryHelpers';

export const FirstSessionRedirect = (): ReactElement => {
  const history = useHistory();
  const { productReference } = useSafeParams();
  const { data, isError } = useQueryGetEntryGraphAndSceneSetIds();

  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (!redirected) {
      // If mobile web, user doesn't have access to the content
      // If error, we don't want the user to get stuck on an empty screen
      if (isError) {
        setRedirected(true);
        history.push(`/${productReference}/home`);
      } else if (typeof data?.result !== 'undefined') {
        setRedirected(true);
        history.push(
          `/${productReference}/${data.result.scene_set_graph_id}/${data.result.scene_set_id}`
        );
      }
    }
  }, [data, history, isError, productReference, redirected]);

  return <></>;
};
