import { TextStyle } from 'react-native';

import { TextTypes } from 'common/constants/enums';
import { QuizAttribute } from 'components/constants';
import { DatePickerDisplayFormat } from 'components/generic-question/DateTimePickers/types';
import {
  TabItemFlexType,
  TabItemTextSize,
} from 'components/Navigation/BottomNavigation/types';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { CaptionState } from 'state/player/reducer';

import {
  ControlAttrs,
  createTheme,
  ThemeValuesType,
} from '../utils/createTheme';
import { FooterTheme } from '../utils/createTheme/footerSchemesTypes';
import { HeadingLevel } from '../utils/createTheme/headingSchemesTypes';
import { TextAreaStates } from '../utils/createTheme/textArea';
import { ThemeMode } from '../utils/createTheme/themeMode';

import { facebookColors, googleColors } from './common';

// prettier-ignore
// NOTE: Names and color "groups" are very experimental and will hopefully evolve
export const sleepioColors = {
  veryDarkBlue: '#001748',
  // Blues from Sleepio V2 Zeplin
  blackBlue: '#00212c',
  darkBlue: '#003366',
  altDarkBlue: '#163258',
  darkBlueDim: '#2c4479',
  blue: '#00506b',
  lightBlue: '#1a80a2',
  lightishBlue: '#3d5d88',
  veryLightBlue: '#bfe2f0',
  veryLightBlueDim: '#A9C8D6',
  superLightBlue: '#daf1fd',
  disabledInputGrey: '#fbfcfd',
  // gray Blue (very loose color group)
  grayBlue: '#144D7E',
  // Mono (very loose color group)
  black: '#282828',
  grey: '#757575',
  mediumGrey: '#6A717D',
  lightGrey: '#d6d6d6',
  veryLightGrey: '#f2f2f2',
  lightGray: '#F7F6F5',
  white: '#ffffff',
  whiteTint: 'rgba(255, 255, 255, 0.3)',
  // semantic (very loose color group)
  error: '#d0021b',
  warning: '#ffba44',
  success: '#008769',
  // bottom navigation colors
  navBackground: '#002346',
  navText: '#8091A3',
  navHighlight: '#5CFFE1',
  // controls
  controlsIconColor: '#FFFFFF',
  controlsTextColor: '#FFFFFF',
  controlsTextColorDim: '#989BA4',
  controlsBackgroundColor: '#2C4479',
  progressColorDim: '#163258',
  controlsMediaBackgroundColor: '#163258',
  progressMediaColorDim: '#507EB9',
  progressCaptionColor: "#163258",
  progressMediaColor: '#C7DDFF',
  progressColor: '#A5CBFF',
  throbber: '#b2b2b2',
  // controls for select horizontal
  borderColorGray: '#DFE1E6',
  warningBackgroundColor: '#FFF7E5',
  warningBorderColor: '#8D6B25',
  hoverBackgroundColor: '#f7f6f5',
  // controls for diary card response
  mediumBlue: '#234370',
  // local notifications
  darkMidnightBlue: '#163258',
  slateGrey: '#6a717d',
  cadmiumOrange: '#f8821a',
  // sleep diary week
  darkOrange: '#DB6632',
  splashPageText: '#000000',
};

const color = {
  primary: sleepioColors.blue,
  primaryLight: sleepioColors.lightBlue,
  primaryDark: sleepioColors.veryDarkBlue,
  primaryGrey: sleepioColors.grayBlue,
  background: {
    light: sleepioColors.veryLightGrey,
    medium: sleepioColors.grey,
    dark: sleepioColors.black,
    imageFallback: sleepioColors.grey,
    sleepDiaryForm: sleepioColors.lightGray,
    sleepEfficiencyCard: sleepioColors.mediumBlue,
  },
  error: sleepioColors.error,
  black: sleepioColors.black,
  white: sleepioColors.white,
  hint: sleepioColors.lightBlue,
  success: sleepioColors.success,
  warning: sleepioColors.cadmiumOrange,
  hoverBlue1: sleepioColors.lightishBlue,
  midBlue: sleepioColors.altDarkBlue,
  text: {
    primary: sleepioColors.black,
    primaryInverse: sleepioColors.white,
    placeholder: sleepioColors.grey,
    splashPage: sleepioColors.altDarkBlue,
  },
  textGoogle: {
    primary: googleColors.white,
    secondary: googleColors.red,
  },
  textFacebook: {
    primary: facebookColors.white,
    secondary: facebookColors.blue,
  },
  textLocalNotifications: {
    primary: sleepioColors.darkMidnightBlue,
    secondary: sleepioColors.slateGrey,
  },
  overlay: {
    primary: sleepioColors.altDarkBlue,
  },
  inputOutlineGrey: sleepioColors.borderColorGray,
  disabledInputGrey: sleepioColors.disabledInputGrey,
  mediumGrey: sleepioColors.mediumGrey,
  lightGray: sleepioColors.lightGray,
};

const controls: ThemeValuesType['controls'] & {
  /**
   * TODO: wrong place for types?
   * HOW: Move to ThemeValuesType['controls']
   * WHEN: have time to test
   */
  paddingVerticalHintErrorWarning: number;
} = {
  player: {
    smallTitleTextWeight: 400,
    color: sleepioColors.controlsTextColor,
    iconColor: sleepioColors.controlsIconColor,
    progressColor: sleepioColors.progressMediaColor,
    progressColorDim: sleepioColors.progressMediaColorDim,
    backgroundColor: sleepioColors.controlsMediaBackgroundColor,
    captionIconButton: {
      [CaptionState.OFF]: {
        color: sleepioColors.progressMediaColor,
      },
      [CaptionState.ON]: {
        color: sleepioColors.progressCaptionColor,
        backgroundColor: sleepioColors.progressMediaColor,
      },
    },
    captionSelect: {
      backgroundColor: sleepioColors.controlsBackgroundColor,
      color: sleepioColors.controlsTextColor,
      colorDim: sleepioColors.controlsTextColorDim,
    },
  },
  closeButton: {
    iconSize: 16,
    showText: false,
    iconColor: sleepioColors.controlsIconColor,
    textColor: sleepioColors.controlsTextColor,
    backgroundColor: sleepioColors.controlsBackgroundColor,
  },
  color: sleepioColors.black,
  backgroundColor: sleepioColors.veryLightBlue,
  borderColor: sleepioColors.veryLightBlue,
  borderRadius: 4,
  borderWidth: '2px',
  borderStyle: 'solid',
  paddingHorizontal: 30,
  paddingHorizontalLarge: 45,
  paddingVertical: 15,
  paddingVerticalHintErrorWarning: 0,
  paddingVerticalLarge: 20,
  fontSize: 15,
  fontSizeLarge: 17,
  opacity: 1,
  ctaMinWidth: 220,
  ctaMaxWidth: 343,
  ctaFixedWidth: 220,
  isDisabled: {
    opacity: 0.4,
  },
  selection: {
    color: sleepioColors.black,
    backgroundColor: sleepioColors.veryLightBlue,
    borderColor: sleepioColors.veryLightBlue,
    quiz: {
      [QuizAttribute.CORRECT]: {
        color: 'white',
        backgroundColor: sleepioColors.success,
        borderColor: sleepioColors.success,
        labelColor: 'white',
        editColor: 'white',
        border: '1px solid #008769',
      },
      [QuizAttribute.INCORRECT]: {
        color: 'white',
        backgroundColor: sleepioColors.error,
        borderColor: sleepioColors.error,
        labelColor: 'white',
        editColor: 'white',
        border: '1px solid #d4321a',
      },
    },
    default: {
      [ControlAttrs.highlightAndIsSelected]: {
        color: sleepioColors.black,
        backgroundColor: sleepioColors.warning,
        borderColor: sleepioColors.warning,
        labelColor: '#555555',
        editColor: '#ffba44',
        border: '1px solid #ffba44',
      },
      [ControlAttrs.isPressing]: {
        color: 'white',
        backgroundColor: sleepioColors.blackBlue,
        borderColor: sleepioColors.blackBlue,
        labelColor: '#555555',
        editColor: '#ffba44',
        border: '1px solid #ffba44',
      },
      [ControlAttrs.isActive]: {
        color: 'white',
        backgroundColor: sleepioColors.blue,
        borderColor: sleepioColors.blue,
        labelColor: 'white',
        editColor: 'white',
        border: `1px solid ${sleepioColors.blue}`,
      },
      [ControlAttrs.isSelected]: {
        color: 'white',
        backgroundColor: sleepioColors.lightBlue,
        borderColor: sleepioColors.lightBlue,
        labelColor: 'white',
        editColor: 'white',
        border: `1px solid ${sleepioColors.lightBlue}`,
      },
      [ControlAttrs.highlight]: {
        color: sleepioColors.black,
        backgroundColor: sleepioColors.warning,
        borderColor: sleepioColors.warning,
        labelColor: '#555555',
        editColor: '#ffba44',
        border: '1px solid #ffba44',
      },
      [ControlAttrs.isHovering]: {
        backgroundColor: '#00506b',
        color: 'white',
        borderColor: '#00506b',
        labelColor: 'white',
        editColor: 'white',
        border: '1px solid #00506b',
      },
    },
  },
  horizontalSelection: {
    color: sleepioColors.progressColorDim,
    backgroundColor: sleepioColors.white,
    borderColor: sleepioColors.borderColorGray,
    default: {
      [ControlAttrs.highlightAndIsSelected]: {
        color: sleepioColors.progressColorDim,
        backgroundColor: sleepioColors.warningBackgroundColor,
        borderColor: sleepioColors.warningBorderColor,
        labelColor: '#555555',
        editColor: '#ffba44',
        border: `1px solid ${sleepioColors.warningBorderColor}`,
      },
      [ControlAttrs.isPressing]: {
        color: 'white',
        backgroundColor: sleepioColors.progressColorDim,
        borderColor: sleepioColors.progressColorDim,
        labelColor: 'white',
        editColor: '#ffba44',
        border: `1px solid ${sleepioColors.borderColorGray}`,
      },
      [ControlAttrs.isActive]: {
        color: 'white',
        backgroundColor: sleepioColors.progressColorDim,
        borderColor: sleepioColors.progressColorDim,
        labelColor: 'white',
        editColor: 'white',
        border: `1px solid ${sleepioColors.progressColorDim}`,
      },
      [ControlAttrs.isSelected]: {
        color: 'white',
        backgroundColor: sleepioColors.progressColorDim,
        borderColor: sleepioColors.progressColorDim,
        labelColor: 'white',
        editColor: 'white',
        border: `1px solid ${sleepioColors.progressColorDim}`,
      },
      [ControlAttrs.highlight]: {
        color: sleepioColors.progressColorDim,
        backgroundColor: sleepioColors.warningBackgroundColor,
        borderColor: sleepioColors.warningBorderColor,
        labelColor: '#555555',
        editColor: '#ffba44',
        border: `1px solid ${sleepioColors.warningBorderColor}`,
      },
      [ControlAttrs.isHovering]: {
        backgroundColor: sleepioColors.hoverBackgroundColor,
        color: sleepioColors.progressColorDim,
        borderColor: '#00506b',
        labelColor: 'white',
        editColor: 'white',
        border: `1px solid ${sleepioColors.borderColorGray}`,
      },
    },
  },
  sleepDiaryResponse: {
    default: {
      color: sleepioColors.white,
      backgroundColor: sleepioColors.mediumBlue,
      borderColor: sleepioColors.progressColorDim,
      border: `1px solid ${sleepioColors.progressColorDim}`,
    },
    [ControlAttrs.isPressing]: {
      color: sleepioColors.white,
      backgroundColor: sleepioColors.progressColorDim,
      borderColor: sleepioColors.progressColorDim,
      labelColor: sleepioColors.white,
      border: `1px solid ${sleepioColors.progressColorDim}`,
    },
    [ControlAttrs.isHovering]: {
      backgroundColor: sleepioColors.lightishBlue,
      color: sleepioColors.white,
      borderColor: sleepioColors.progressColorDim,
      labelColor: sleepioColors.white,
      border: `1px solid ${sleepioColors.progressColorDim}`,
    },
  },
  textArea: {
    textInputStyles: {
      backgroundColor: sleepioColors.veryLightGrey,
      borderColor: sleepioColors.lightGrey,
      borderRadius: 8,
      borderWidth: 1,
      color: sleepioColors.black,
      fontSize: 16,
      height: 171,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
      fontStyle: 'normal' as TextStyle['fontStyle'],
    },
    placeholderColor: sleepioColors.grey,
    placeholderFontStyle: 'italic' as TextStyle['fontStyle'],
    outlineBorderRadius: 12,
    outlineColor: sleepioColors.veryLightBlueDim,
    outlineWidth: 4,
    states: {
      [TextAreaStates.ACTIVE]: {
        borderColor: sleepioColors.blue,
        backgroundColor: sleepioColors.white,
      },
    },
  },
};

export const sleepioValues: ThemeValuesType = {
  color: color,
  bottomNavigation: {
    backgroundColor: sleepioColors.navBackground,
    textColor: sleepioColors.navText,
    textHighlight: sleepioColors.navHighlight,
    tabItemTextSize: TabItemTextSize.MEDIUM,
    tabItemSizeType: TabItemFlexType.FLEX,
  },
  localNotifications: {
    borderColor: sleepioColors.borderColorGray,
  },
  heading: {
    [HeadingLevel.LEVEL1]: { fontSize: 38, paddingBottom: 13 },
    [HeadingLevel.LEVEL2]: { fontSize: 30, paddingBottom: 13 },
    [HeadingLevel.LEVEL3]: { fontSize: 27, paddingBottom: 13 },
    [HeadingLevel.LEVEL4]: { fontSize: 24, paddingBottom: 13 },
    [HeadingLevel.LEVEL5]: { fontSize: 20, paddingBottom: 13 },
    [HeadingLevel.LEVEL6]: { fontSize: 16, paddingBottom: 13 },
  },
  text: {
    // For web we inherit from body's "GT0WalsheimProRegular", " -apple-system", BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    // Why inherit?
    // THe problem is we mix react-native and react-native-web by importing things like Text from RN. In the app this is ok, but in tests we create
    // a problem where Babel can't parse that long-form font family with fallbacks. To ensure web and mobile and react-native-web all line up
    // in practice and in tests we need to force the web font-family to inherit from body.
    fontFamily: 'inherit',
    nativeFontFamily: FontFamilies.Regular,
    mono: {
      fontFamily: 'monospace',
    },
    [TextTypes.DEFAULT]: {
      color: color.text.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSize,
      fontWeight: '400',
    },
    [TextTypes.ERROR]: {
      color: color.error,
      paddingVertical: controls.paddingVerticalHintErrorWarning,
      fontSize: controls.fontSize,
      fontWeight: '400',
    },
    [TextTypes.WARNING]: {
      color: color.error,
      paddingVertical: controls.paddingVerticalHintErrorWarning,
      fontSize: controls.fontSize,
      fontWeight: '400',
    },
    [TextTypes.HINT]: {
      color: color.hint,
      paddingVertical: controls.paddingVerticalHintErrorWarning,
      fontSize: controls.fontSize,
      fontWeight: '400',
    },
    [TextTypes.SUCCESS]: {
      color: color.success,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSize,
      fontWeight: '400',
    },
    [TextTypes.CONTENT]: {
      color: '#000000',
      paddingHorizontal: 0,
      paddingVertical: 0,
      // use react default
      fontSize: undefined,
      fontWeight: '400',
    },
  },
  footer: {
    [FooterTheme.Light]: {
      color: sleepioColors.altDarkBlue,
      backgroundColor: sleepioColors.white,
    },
    [FooterTheme.Dark]: {
      color: sleepioColors.white,
      backgroundColor: sleepioColors.altDarkBlue,
    },
  },
  // TODO: create the buttons here and make 'buttons' non-optional.
  // WHEN: Sleepio team uses the theme for the buttons
  buttons: undefined,
  modal: {
    // NOTE: All these values are relative to the modalReferenceDimension value
    modalReferenceDimension: 670,
    maxWidthPx: 670,
    minWidthPx: 600,
    modalVerticalPaddingPx: 36,
    modalHorizontalPaddingPx: 60,
    modalBreakpointMarginRightPx: 18,
    modalBorderRadiusPx: 4,
    largeScreenBreakpointPx: 1024,
    justifyWithCloseButton: true,
    fadeOutCloseButton: false,
    input: {
      height: 57,
      caretSize: 40,
      iconSize: 24,
      inputIconMarginRight: 9,
      borderRadius: 8,
      paddingBottom: 19,
      paddingRight: 16,
      paddingLeft: 16,
      paddingTop: 19,
      fontSize: 16,
      fontWeight: '500',
      inputEditFontSize: 16,
      labelFontSize: 16,
      inputMarginBottom: 10,
      inputPaddingRightExtra: 69,
      inputScaleLabelFontSize: 16,
      inputScaleLabelMargin: 18,
      dateTimePicker: {
        displayFormat: {
          time12h: DatePickerDisplayFormat.TIME_FORMAT_12H,
          time24h: DatePickerDisplayFormat.TIME_FORMAT_12H,
          date: DatePickerDisplayFormat.DATE_FORMAT,
          dateTime12h: DatePickerDisplayFormat.DATE_TIME_FORMAT_12H,
          dateTime24h: DatePickerDisplayFormat.DATE_TIME_FORMAT_12H,
        },
        placeholder: 'Please Select',
        showCaret: true,
        showBorder: false,
        doneTextColor: sleepioColors.black,
        textColor: sleepioColors.black,
      },
      progressIndicator: {
        textColor: sleepioColors.mediumGrey,
      },
    },
    question: {
      modalQuestionTitleFontSizePx: 20,
      modalQuestionTitleFontWeight: '400',
      modalQuestionTitleHeightPx: 56,
      modalQuestionTitleMarginBottomPx: 16,
      modalQuestionPretextFontSizePx: 16,
      modalQuestionPretextHeightPx: 19,
      modalQuestionPretextMarginBottomPx: 4,
      modalQuestionMarginBottom: 30, // Design is 40 but we need to accommodate margin bottom from inputs,
    },
  },
  controls: controls,
  loadingOverlay: {
    [ThemeMode.Dark]: {
      overlay: {
        opacity: 1,
        backgroundColor: 'transparent',
      },
      throbber: {
        size: 70,
        fill: sleepioColors.throbber,
        duration: 600,
      },
    },
    [ThemeMode.Light]: {
      overlay: {
        opacity: 1,
        backgroundColor: 'transparent',
      },
      throbber: {
        size: 70,
        fill: sleepioColors.throbber,
        duration: 600,
      },
    },
  },
};

const sleepioTheme = createTheme(sleepioValues);

export { sleepioTheme };
