import React, { FunctionComponent } from 'react';
import styled from 'styled-components/native';

import { ProductReferences } from 'common/constants/enums';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import {
  useQueryJumpToSceneSet,
  useQuerySceneSetGraphsBulk,
} from 'lib/api/reactQueryHelpers';
import { getSessionDescription } from 'lib/player/getSessionDescription';
import { getSessionName } from 'lib/player/getSessionName';
import { getSessionSmallTitle } from 'lib/player/getSessionSmallTitle';

import { DescriptionText, TitleText } from './styled';

const PlayerTextContainer = styled.View`
  flex: 1;
  align-self: flex-start;
`;

const Row = styled.View``;

const SessionDetails: FunctionComponent = () => {
  const { productReference, sceneSetGraphId, sceneSetId } = useSafeParams<
    SceneSetParams
  >();
  const sceneSetGraphsResponse = useQuerySceneSetGraphsBulk();
  const sceneSet = useQueryJumpToSceneSet(sceneSetId)?.data?.result
    ?.scene_set_json;
  if (!sceneSet) {
    return null;
  }

  // TODO: handle the default values of the product better
  let sessionSmallTitle = undefined;
  if (typeof sceneSet.session_small_title !== 'undefined') {
    sessionSmallTitle = getSessionSmallTitle(sceneSet);
  } else {
    if (productReference == ProductReferences.SLEEPIO) {
      sessionSmallTitle = getSessionName(
        sceneSetGraphsResponse.data?.result,
        sceneSetGraphId
      );
    }
  }

  let sessionDescription = getSessionDescription(sceneSet);
  if (!sessionDescription && productReference == ProductReferences.DAYLIGHT) {
    sessionDescription = getSessionName(
      sceneSetGraphsResponse.data?.result,
      sceneSetGraphId
    );
  }
  const sessionDetails = {
    title: sessionSmallTitle,
    description: sessionDescription,
  };

  return (
    <>
      <PlayerTextContainer>
        <Row>
          <TitleText
            style={{
              fontSize: 14,
              // 1.2 is used here for two reasons:
              // 1) The design docs don't specify the height correctly but using 1.2 matches it
              // 2) The design docs actually show a line height equal to the font size however,
              //    React Native in seems to crop text when the line-height is exactly equal
              //    to the font size. There is much argument about this but our fix is fine
              //    https://github.com/facebook/react-native/issues/7687
              lineHeight: 14 * 1.2,
              marginBottom: 4,
            }}
          >
            {sessionDetails.title}
          </TitleText>
          <DescriptionText
            style={{
              fontSize: 20,
              lineHeight: 20 * 1.2,
              marginBottom: 0,
              fontWeight: '700',
            }}
          >
            {sessionDetails.description}
          </DescriptionText>
        </Row>
      </PlayerTextContainer>
    </>
  );
};

export { SessionDetails };
