import { BorderStyles, ItemLocation } from './types';

export const getBorderStyles = (
  scaledBorderRadius: number,
  location?: ItemLocation
): BorderStyles => {
  const showLeftBorder = location !== ItemLocation.START;
  const showLeftRadii = location === ItemLocation.START;
  const showRightBorder = location !== ItemLocation.END;
  const showRightRadii = location === ItemLocation.END;
  return {
    borderTopLeftRadius: showLeftRadii ? scaledBorderRadius : 0,
    borderTopRightRadius: showRightRadii ? scaledBorderRadius : 0,
    borderBottomLeftRadius: showLeftRadii ? scaledBorderRadius : 0,
    borderBottomRightRadius: showRightRadii ? scaledBorderRadius : 0,
    borderLeftWidth: showLeftBorder ? 1 : 0,
    borderRightWidth: showRightBorder ? 1 : 0,
  };
};
