import { ProductReferences } from 'common/constants/enums';

const getProductStreamKey = ({
  productReference,
}: {
  productReference: ProductReferences;
}): string => {
  switch (productReference) {
    case ProductReferences.SLEEPIO:
    case ProductReferences.DAYLIGHT:
      return `${productReference}-program`;
    case ProductReferences.HELLO_WORLD:
      throw Error(`HelloWorld is a stub product reference`);
    default:
      throw Error(`Unknown product reference: "${productReference}"`);
  }
};

export { getProductStreamKey };
