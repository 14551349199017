import { useEffect, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { useStateApi } from 'lib/useStateApi/useStateApi';

export type UseMeasureReturns = {
  page: (e: LayoutChangeEvent) => void;
  item: (e: LayoutChangeEvent) => void;
  rowSize: number;
};

/**
 * Can measure a (fixed-width item) child, and surrounding (page) parent
 * to work out how many children will fit horizontally.
 */
export const useMeasure = ({
  isPaginated,
}: {
  isPaginated: boolean;
}): UseMeasureReturns => {
  const pageW = useStateApi<number | undefined>(undefined);
  const itemW = useStateApi<number | undefined>(undefined);
  const [rowSize, setRowSize] = useState(1);
  useEffect(() => {
    if (isPaginated && pageW.value !== undefined && itemW.value !== undefined) {
      const newPageWidth = Math.floor(pageW.value / itemW.value);
      setRowSize(Math.max(1, newPageWidth));
    }
  }, [isPaginated, pageW.value, itemW.value]);

  const item = (e: LayoutChangeEvent) =>
    e.nativeEvent.layout.width > 0 && itemW.set(e.nativeEvent.layout.width);

  const page = (e: LayoutChangeEvent) =>
    e.nativeEvent.layout.width > 0 && pageW.set(e.nativeEvent.layout.width);

  return { page, item, rowSize };
};
