import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_user_info = async (
  requestConfig?: AxiosRequestConfig
): Promise<get_user_info.Response> => {
  const request: get_user_info.Request = {
    service_name: 'UserAccount',
    service_version: '1',
    service_method: 'get_user_info',
    args: {},
  };

  return await api(request, requestConfig);
};

get_user_info.queryKey = 'UserAccount/get_user_info';

export declare namespace get_user_info {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'UserAccount';
    service_version: '1';
    service_method: 'get_user_info';
    args: Args;
  }

  export type Result = { uuid: string; user_identity_provider: string };

  export type Response = APIResponse<Result>;
}
