import { DOMAIN } from '../constants';

export enum RedirectorActionTypes {
  PUSH = `${DOMAIN}/PUSH`,
  REMOVE_ALL = `${DOMAIN}/REMOVE_ALL`,
  ROUTE_CHANGED = `${DOMAIN}/ROUTE_CHANGED`,
  REDIRECTION_COMPLETE = `${DOMAIN}/REDIRECTION_COMPLETE`,
}

type Location = {
  pathname: string;
  search: string;
  hash: string;
  key?: string;
};

type AtLeastOne<
  T,
  U = {
    [K in keyof T]: Pick<T, K>;
  }
> = Partial<T> & U[keyof U];

export type RedirectLocation = AtLeastOne<Location>;

// Redirection queue ops
type Condition = { type: 'LOCATION_MATCH'; payload: RedirectLocation };

export type Redirection = {
  location: RedirectLocation;
  conditions: Condition[];
};

// Redirection queue op: Push
export type PushAction = {
  type: RedirectorActionTypes.PUSH;
  payload: Redirection;
};
export const push = (payload: PushAction['payload']): PushAction => ({
  type: RedirectorActionTypes.PUSH,
  payload,
});

// Redirection queue op: Remove all
export type RemoveAllAction = {
  type: RedirectorActionTypes.REMOVE_ALL;
};
export const removeAll = (): RemoveAllAction => ({
  type: RedirectorActionTypes.REMOVE_ALL,
});

// Notify route changed
export type RouteChangedAction = {
  type: RedirectorActionTypes.ROUTE_CHANGED;
  payload: {
    location: Location;
    action: 'PUSH' | 'POP' | 'REPLACE';
  };
};
export const routeChanged = (
  payload: RouteChangedAction['payload']
): RouteChangedAction => ({
  type: RedirectorActionTypes.ROUTE_CHANGED,
  payload,
});

// Notify redirection complete
export type RedirectionCompleteAction = {
  type: RedirectorActionTypes.REDIRECTION_COMPLETE;
};
export const redirectionComplete = (): RedirectionCompleteAction => ({
  type: RedirectorActionTypes.REDIRECTION_COMPLETE,
});

export type RedirectorAction =
  | PushAction
  | RemoveAllAction
  | RouteChangedAction
  | RedirectionCompleteAction;
