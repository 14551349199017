import { Platform } from 'react-native';

import { isMobileWeb } from 'config/isMobileWeb';

export const getPlatform = (): string => {
  if (isMobileWeb) {
    return 'mobile-web';
  }
  return Platform.OS;
};
