import React, { ReactElement, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import AvailabilityIndicator from 'components/SessionAvailabilityIndicator';
import { Text } from 'components/Text';
import { RoleProps } from 'cross-platform/utils/roleProps';

import { NavBarStyles } from './constants';
import { NavItemDisplayType } from './types';

const domain = `CollapsedNav`;
export const NavBarInner = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: ${NavBarStyles.navBarPadding}px;
  z-index: ${NavBarStyles.navBarZIndex} !important;
`;
NavBarInner.displayName = `${domain}.NavBarInner`;

type ViewProps = {
  children?: ReactNode;
  style?: ViewStyle;
} & RoleProps;

export const NavBar = (props: ViewProps): ReactElement => {
  const safeArea = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = {
    paddingRight: safeArea.right + NavBarStyles.navBarPadding,
    height: NavBarStyles.navBarHeightPx + safeArea.top,
    ...StyleSheet.flatten(style),
  };
  return <NavBarInner style={newStyle} {...restProps} />;
};

export const PressableNavItem = styled.TouchableOpacity``;
PressableNavItem.displayName = `${domain}.PressableNavItem`;

export const Label = styled(Text)<{ type?: NavItemDisplayType }>`
  font-size: ${({ type }) => (type === 'subcategory' ? '16px' : '20px')};
  color: ${({ type }) => (type === 'subcategory' ? '#bfe2f0' : 'white')};
`;
Label.displayName = `${domain}.Label`;

export const CategoryTitle = styled(Text)<{ type?: NavItemDisplayType }>`
  font-size: 20px;
  color: white;
  margin-bottom: 16px;
`;
CategoryTitle.displayName = `${domain}.CategoryTitle`;

export const ItemContainer = styled.View<{ type?: NavItemDisplayType }>`
  margin-bottom: ${({ type }) => (type === 'subcategory' ? '20px' : '26px')};
`;
ItemContainer.displayName = `${domain}.ItemContainer`;

export const ItemIconContainer = styled.View<{ type?: NavItemDisplayType }>`
  margin-bottom: ${({ type }) => (type === 'subcategory' ? '20px' : '26px')};
  flex-direction: row;
  align-items: center;
`;
ItemIconContainer.displayName = `${domain}.ItemIconContainer`;

export const ItemContainerGroup = styled.View`
  margin-bottom: 6px;
`;
ItemContainerGroup.displayName = `${domain}.ItemContainerGroup`;

export const MenuContainer = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})`
  background-color: #002141;
  top: 0;
  right: 0;
  position: absolute;
  min-width: 300px;
`;
MenuContainer.displayName = 'MenuContainer';

export const MenuItemsContainer = styled.View.attrs({
  role: 'menu',
  accessibilityRole: 'menu',
})`
  padding: 40px;
  flex-basis: auto;
  min-height: auto;
`;
MenuItemsContainer.displayName = 'MenuItemsContainer';

export const PortalStretcher = styled.View`
  flex: 1;
`;
PortalStretcher.displayName = 'PortalStretcher';

export const OpenButton = styled.TouchableOpacity``;
OpenButton.displayName = 'OpenButton';

export const CloseButton = styled.TouchableOpacity`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 12px;
  border-radius: 50px;
  background-color: #2c4479;
`;
CloseButton.displayName = 'CloseButton';

export const HamBurgerIcon = ({ color }: { color: string }): ReactElement => {
  return (
    <View>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <View
            key={index}
            style={{
              backgroundColor: color,
              height: 3,
              width: 24,
              marginVertical: 3,
            }}
          />
        ))}
    </View>
  );
};

export const SessionIcon = (): ReactElement => {
  return (
    <View style={{ paddingLeft: 10 }}>
      <AvailabilityIndicator
        iconSize={11}
        testID={'sessionAvailabilityIndicator'}
      />
    </View>
  );
};

const LogoContainerInner = styled.View`
  margin-right: auto;
`;
LogoContainerInner.displayName = `${domain}.LogoContainerInner`;
export const LogoContainer: React.FC<ViewProps> = (
  props: ViewProps
): ReactElement => {
  const { top } = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = { marginTop: top, ...StyleSheet.flatten(style) };
  return <LogoContainerInner style={newStyle} {...restProps} />;
};

const HamburgerContainerInner = styled.View``;
HamburgerContainerInner.displayName = `${domain}.HamburgerContainerInner`;

export const HamburgerContainer: React.FC<ViewProps> = (
  props: ViewProps
): ReactElement => {
  const { top } = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = { marginTop: top, ...StyleSheet.flatten(style) };
  return <HamburgerContainerInner style={newStyle} {...restProps} />;
};

export const MenuSafe: React.FC<ViewProps> = (
  props: ViewProps
): ReactElement => {
  const { top } = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = {
    marginTop: top,
    ...StyleSheet.flatten(style),
  };
  return <View style={newStyle} {...restProps} />;
};
