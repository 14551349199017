import React, { ReactElement } from 'react';
import { Formik } from 'formik';

import { ProductReferences } from 'common/constants/enums';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { noOpFunction } from 'lib/noOpFunction';
import { getProductName } from 'lib/productInfo/getProductName';
import { SessionsScreen } from 'sleepio/components/screens/SessionsScreen';

import { HomeScreen } from '..';

const HomeScreenSwitcher = (): ReactElement => {
  useUpdateTitle();
  return getProductName() === ProductReferences.SLEEPIO ? (
    <Formik initialValues={[]} onSubmit={noOpFunction}>
      <SessionsScreen />
    </Formik>
  ) : (
    <HomeScreen />
  );
};

export { HomeScreenSwitcher };
