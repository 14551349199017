import { mergeAll } from 'ramda';

import { DeepPartial, Question } from '@bighealth/types/src';

import { ResponseInputState } from 'components/forms/ResponseOptions/ResponseInput/types';
import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { getInitialValues } from 'components/SleepDiaryForm/components/FlowingForm/helpers/getFlowState/getInitialValuesAndOptions/rules/getInitialValues';
import { QuestionId } from 'state/question-response/actions';

import { getFinalAwakeningFromTryToSleep } from './rules/getFinalAwakeningFromTryToSleep';
import { getTimeOutOfBedConfigFromFinalAwakening } from './rules/getTimeOutOfBedConfigFromFinalAwakening';
import { getTryToSleepConfigFromIntoBed } from './rules/getTryToSleepConfigFromIntoBed';

// IDEA rename getInitialValuesAndOptions to interFieldValues
// WHY getInitialValuesAndOptions is name of storybook. interFieldValues is more descriptive of the flow state
// WHEN code stabilizes

export type Config = Partial<{
  initialValue: Value;
  state?: ResponseInputState;
  // NOTE: named return field "questionProp" as more generic (for other components/domains)
  //       but also considered "questionPropOverride" (how its used within SleepDiary AND its type)
  questionProps: DeepPartial<Question>;
}>;

/**
 * Custom rules to set selected and selectable values based on values
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 * @param {Record<QuestionId, Question>} initialQuestionProps question props to generate initial values and props from
 */
const getInitialValuesAndOptions = (
  inputs: Record<QuestionId, DropdownItem[]>,
  initialQuestionProps?: Record<QuestionId, Question | undefined>,
  diaryDate?: Date
): Record<QuestionId, Config> => {
  if (initialQuestionProps) {
    return getInitialValues(initialQuestionProps);
  }
  const timeTryToSleep =
    getTryToSleepConfigFromIntoBed(inputs, diaryDate) || {};
  const finalAwakening =
    getFinalAwakeningFromTryToSleep(inputs, diaryDate) || {};
  const outOfBed =
    getTimeOutOfBedConfigFromFinalAwakening(inputs, diaryDate) || {};
  return mergeAll([timeTryToSleep, finalAwakening, outOfBed]);
};

export { getInitialValuesAndOptions };
