import React, { ReactElement } from 'react';
import { AccessibilityProps, View } from 'react-native';

import { Text as TextProps } from '@bighealth/types/src/scene-components/client';

import { TextPresentation } from './TextPresentation';

export const Text = ({
  text,
  children,
  ...props
}: React.PropsWithChildren<TextProps> & AccessibilityProps): ReactElement => {
  const textPresentation = (
    <TextPresentation {...props}>{text}</TextPresentation>
  );
  if (
    props.style &&
    (props.style.left !== undefined || props.style.top !== undefined)
  ) {
    return (
      <>
        {textPresentation}
        {children}
      </>
    );
  }
  return (
    /* 
      The 'pointerEvents = none' property is used to prevent the component from interfering with the user's 
      interaction and the ToggleControlsHandler, which is responsible for managing the states of the video playback bar, 
      while preserving the current object accessibility.
    */
    <View
      pointerEvents={'none'}
      style={{ display: 'flex' }}
      accessible={props?.accessible}
    >
      {textPresentation}
      {children}
    </View>
  );
};
