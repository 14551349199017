import { isMobileWeb } from 'config/isMobileWeb';
import { useHistory, useParams } from 'cross-platform/react-router';
import { useQuery } from 'lib/router/useQuery';

export const useCheckParamRedirectHomeIfMobileWeb = (): void => {
  const query = useQuery();
  const history = useHistory();
  const { productReference } = useParams<{ productReference: string }>();
  const redirectHomeIfMobileWeb = query.get('redirect_home_if_mobile_web');

  if (isMobileWeb && redirectHomeIfMobileWeb === 'true') {
    history.push(`/${productReference}/home`);
  }
};
