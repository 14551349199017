import { Dispatch, SetStateAction } from 'react';

import { DiaryEntry } from '@bighealth/types/src/services/SleepDiaryPayloads/types';

import { WithImplementation } from 'lib/api/hooks';
import { SleepDiaryService } from 'lib/api/SleepDiaryService';

type FetchDataFunc = WithImplementation<
  | typeof SleepDiaryService.create_single_day_entry
  | typeof SleepDiaryService.update_single_day_entry_with_date
  | typeof SleepDiaryService.create_from_weekly_average_estimate
  | typeof SleepDiaryService.submit_single_diary_form
  | typeof SleepDiaryService.create_baseline_entities
>;

// recursively unwraps complex types from promise
type Awaited<T> = T extends PromiseLike<infer U>
  ? { 0: Awaited<U>; 1: U }[U extends PromiseLike<unknown> ? 0 : 1]
  : T;

export type SleepDiaryFormResponses = Awaited<ReturnType<FetchDataFunc>>;

export type OnSuccessCallback = (payload?: SleepDiaryFormResponses) => void;

type Args = {
  fetchData: FetchDataFunc;
  fetchDataArgs: [DiaryEntry];
  onSuccess: OnSuccessCallback;
  onLoadingChange: Dispatch<SetStateAction<boolean>>;
  onError: Dispatch<SetStateAction<Error | undefined>>;
};

async function fetchSubmitDataAsync({
  fetchData,
  fetchDataArgs,
  onLoadingChange,
  onSuccess,
  onError,
}: Args): Promise<void> {
  try {
    onLoadingChange(true);
    const data = await fetchData(...fetchDataArgs);
    onSuccess(data);
  } catch (e) {
    onError(e);
    onSuccess();
  } finally {
    onLoadingChange(false);
  }
}

export { fetchSubmitDataAsync };
