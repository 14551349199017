import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import {
  AWAKENINGS_IN_NIGHT,
  AWAKENINGS_TOTAL_TIME,
  TIME_FINAL_AWAKENING,
  TIME_TRY_TO_SLEEP,
  TO_FALL_ASLEEP_TOTAL_TIME,
} from 'components/SleepDiaryForm/constants';
import { DAY } from 'lib/durations';
import { QuestionId } from 'state/question-response/actions';

import { getSelected } from '../../helpers/getSelected';
import { getDifferenceWithDiaryDate } from '../helpers/getDifferenceWithDiaryDate';
import { WarningAndHighlight } from '..';

const getAwakeningsTotalTime = (awakenings: Value, totalTime: Value): Value => {
  if (typeof awakenings === 'number' && awakenings === 0) {
    return 0;
  }
  return totalTime || 0;
};

export const getTimeAwakeGreaterThanTimeAsleep = (
  inputs: Record<QuestionId, DropdownItem[]>,
  diaryDate?: Date
): Record<QuestionId, Partial<WarningAndHighlight>> | null => {
  const tryToSleep = getSelected(inputs[TIME_TRY_TO_SLEEP])?.valueOf();
  const finalAwakening = getSelected(inputs[TIME_FINAL_AWAKENING])?.valueOf();

  const awakeningsTotalTime = getAwakeningsTotalTime(
    getSelected(inputs[AWAKENINGS_IN_NIGHT]),
    getSelected(inputs[AWAKENINGS_TOTAL_TIME])
  );

  const toFallAsleepTotalTime =
    getSelected(inputs[TO_FALL_ASLEEP_TOTAL_TIME]) || 0;

  if (
    typeof tryToSleep === 'number' &&
    typeof awakeningsTotalTime === 'number' &&
    typeof toFallAsleepTotalTime === 'number' &&
    typeof finalAwakening === 'number'
  ) {
    // Crosses over midnight 🕛
    const finalAwakeningFuture =
      finalAwakening < tryToSleep ? finalAwakening + DAY : finalAwakening;

    const difference = getDifferenceWithDiaryDate(
      finalAwakeningFuture,
      tryToSleep,
      diaryDate
    );
    const timeAsleep = difference - toFallAsleepTotalTime;

    if (timeAsleep < awakeningsTotalTime) {
      return {
        [AWAKENINGS_TOTAL_TIME]: {
          highlight: true,
          warning: 'This exceeds total sleep time. Please check again.',
        },
      };
    }
  }
  return null;
};
