import React from 'react';

import { ProductReferences } from 'common/constants/enums';
import {
  // CarouselMenu,
  ListMenu,
  MenuProps,
} from 'components/SceneSetGraphMenu';

export enum ButtonTypes {
  LARGE_CTA = 'LARGE_CTA',
  PRIMARY_BUTTON = 'PRIMARY_BUTTON',
  SECONDARY_BUTTON = 'SECONDARY_BUTTON',
  FACEBOOK_BUTTON = 'FACEBOOK_BUTTON',
  GOOGLE_BUTTON = 'GOOGLE_BUTTON',
}
export enum TextTypes {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
}

type Copy = {
  splashText: string;
};

export const getTextForProduct = (productReference: string): Copy => {
  const isDaylight = productReference === ProductReferences.DAYLIGHT;
  return {
    splashText: isDaylight
      ? 'Your expert through tough times'
      : 'Continue your journey to better sleep',
  };
};

type Components = {
  [key: string]: React.FC<MenuProps>;
};

export const getComponentsForProduct = (
  productReference: string
): Components => {
  const isDaylight = productReference === ProductReferences.DAYLIGHT;
  return {
    Menu: isDaylight ? ListMenu : ListMenu,
  };
};

export { getAssetsForProduct } from './getAssetsForProduct';
