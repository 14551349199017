type Options<T> = {
  value: T;
  onSelect: (value: T) => void;
  setIsHovering: (isHovering: boolean) => void;
  setIsPressing: (isPressing: boolean) => void;
};
export const getButtonInteractionProps = <T>({
  value,
  onSelect,
  setIsPressing,
  setIsHovering,
}: Options<T>) => ({
  onMouseEnter: () => {
    setIsHovering(true);
  },

  onMouseLeave: () => {
    setIsHovering(false);
    setIsPressing(false);
  },

  onMouseDown: () => {
    setIsPressing(true);
  },

  onMouseUp: () => {
    setIsPressing(false);
  },

  onPress: () => {
    onSelect(value);
  },
});
