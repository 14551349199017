import React, { ReactElement, ReactNode } from 'react';

import { DebugButtonThrow } from 'components/DebugButtonThrow';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { useQuerySendSceneAnalytics } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQuerySendSceneAnalytics';
import { qaConfigFlags } from 'lib/showQAMenu/qaConfigFlags';

import {
  SceneComponentContextProvider,
  SceneComponentContextType,
} from './SceneComponentContext';

/**
 *
 * "Scene"
 * @param routeProps
 * @constructor
 */

export const Scene = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => {
  useQuerySendSceneAnalytics();
  if (qaConfigFlags.getValue('Throw in SceneSet button')) {
    return <DebugButtonThrow />;
  }
  return (
    <SceneComponentContextProvider
      sceneComponentContext={SceneComponentContextType.Scene}
    >
      <contentErrors.ErrorBoundary>{children}</contentErrors.ErrorBoundary>
    </SceneComponentContextProvider>
  );
};
