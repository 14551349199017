import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const is_feature_available_for_user = async (
  args: is_feature_available_for_user.Args,
  requestConfig?: AxiosRequestConfig
): Promise<is_feature_available_for_user.Response> => {
  const request: is_feature_available_for_user.Request = {
    service_name: 'SleepioFeatureAccess',
    service_version: '1',
    service_method: 'is_feature_available_for_user',
    args,
  };

  return await api(request, requestConfig);
};

is_feature_available_for_user.queryKey =
  'SleepioFeatureAccess/is_feature_available_for_user';

export declare namespace is_feature_available_for_user {
  export type Args = {
    program_id: number;
    feature_name: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepioFeatureAccess';
    service_version: '1';
    service_method: 'is_feature_available_for_user';
    args: Args;
  }

  export type Result = boolean;

  export type Response = APIResponse<Result>;
}
