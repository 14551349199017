import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_user_goal_progress } from '@bighealth/api/SleepioUserGoal/v1';

import { useQueryProduct } from './useQueryProduct';
import { useQueryProgram } from './useQueryProgram';

export const useQueryGoals = (config: {
  isInSceneSet?: boolean;
}): UseQueryResult<get_user_goal_progress.Response, APIErrorResponse> => {
  const programId = useQueryProgram().data?.result.id;
  const productId = useQueryProduct()?.data?.result.id;

  const result = useQuery<get_user_goal_progress.Response, APIErrorResponse>({
    queryKey: get_user_goal_progress.queryKey,
    queryFn: () =>
      get_user_goal_progress({
        product_id: productId as number,
        program_id: programId as number,
        request_source: config.isInSceneSet ? 'session' : 'casefile',
      }),
    enabled: typeof programId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
