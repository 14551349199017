import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { PLATGEN_SENTRY_DSN } from 'config/envVars';

import updateNetworkBreadcrumb from '../utils/updateNetworkBreadcrumb';

export const init = (): void => {
  Sentry.init({
    dsn: PLATGEN_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    beforeBreadcrumb(breadcrumb, hint) {
      switch (breadcrumb.category) {
        case 'xhr':
          if (breadcrumb?.data?.url) {
            breadcrumb.data = updateNetworkBreadcrumb(
              breadcrumb?.data,
              hint?.input
            );
          }
          return breadcrumb;
        default:
          return breadcrumb;
      }
    },
  });
};
