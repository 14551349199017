import { DAY, MINUTE } from 'lib/durations';

export const filterInvalidOptions = (options: Date[], date?: Date): Date[] => {
  if (typeof date === 'undefined') {
    return options;
  }
  const dateWithTime = new Date(date.getTime());

  const timezone = new Date(0).getTimezoneOffset();
  const baseDate = new Date(0 + timezone * MINUTE);
  const validOptions: Date[] = [];

  for (const date of options) {
    const days = Math.floor((date.getTime() - baseDate.getTime()) / DAY);
    dateWithTime.setDate(dateWithTime.getDate() + days);

    dateWithTime.setHours(date.getHours());
    dateWithTime.setMinutes(date.getMinutes());

    if (dateWithTime.getHours() === date.getHours()) {
      validOptions.push(date);
    }
    dateWithTime.setDate(dateWithTime.getDate() - days);
  }

  return validOptions;
};
