import React, { PropsWithChildren, ReactElement } from 'react';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import { useTheme } from 'styled-components/native';

import { TextTypes } from 'common/constants/enums';
import { AccessibleWrapper } from 'components/AccessibleWrapper';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

import { Text } from '../Text';

type TextTypesUnionSubset = Exclude<
  TextTypes,
  TextTypes.DEFAULT | TextTypes.SUCCESS | TextTypes.CONTENT
>;

type HelperTextVariantMappingToRoleType = {
  [T in TextTypesUnionSubset]: string;
};
// Values are Title case to match `role` value
export const helperMessagePresentationStyleMappingToRole: HelperTextVariantMappingToRoleType = {
  [TextTypes.HINT]: 'Hint',
  [TextTypes.WARNING]: 'Warning',
  [TextTypes.ERROR]: 'Error',
};

export type HelperTextProps = PropsWithChildren<
  RoleProps & {
    presentationType: TextTypesUnionSubset;
    style?: StyleProp<TextStyle>;
  }
>;

/**
 * Helper Text Component
 *
 * Wrapper component that can output HintText, WarningText, or ErrorText components
 *  in a consistent manner
 *
 * `role` and `style` properties are optionally able to be provided during implementation
 *   in the event that the defaults need to be overridden
 *
 * @example
 *  <HelperText
 *    presentationType="Hint" // or "Warning" or "Error"
 *    {...roles('Hint')} // [optional], other values: "Warning" or "Error"
 *    style={{ someProp: 'someVal' }} // [optional], other values: "Warning" or "Error"
 *  >
 *   Hint | Error | Warning
 *  </HelperText>
 * @returns
 */
export const HelperText = (props: HelperTextProps): ReactElement => {
  const theme = useTheme();
  const transformStyles = useTransformStylesToContext(theme);
  const untransformedStyles = theme.text[props.presentationType];
  const transformedStyles = transformStyles(untransformedStyles);

  return (
    <AccessibleWrapper>
      <Text
        {...roles(
          helperMessagePresentationStyleMappingToRole[props.presentationType]
        )}
        {...props}
        style={StyleSheet.flatten([transformedStyles, props.style])}
      />
    </AccessibleWrapper>
  );
};

export default HelperText;
