import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { LoginFooter } from 'components/Footer';
import { ExclamationCircle } from 'components/icons';
import { CloseButton } from 'components/PopupModal/components/CloseButton';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { Container, LimitWidth } from 'components/Screens/LoginScreen/styled';
import {
  NeedHelpLink,
  NeedHelpText,
} from 'components/Screens/LoginScreen/styled';
import { Redirect } from 'cross-platform/react-router';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { useFeatureFlags } from 'lib/api/hooks/useFeatureFlags';
import { useAuthenticateWithOIDC } from 'lib/api/hooks/useLogin/useAuthenticateWithOIDC';
import { getRedirectUri } from 'lib/navigation/getRedirectUri';
import { openInAppBrowser } from 'lib/navigation/openInAppBrowser';
import { openURL } from 'lib/navigation/openURL';
import * as reporter from 'lib/reporter';
import { useQuery } from 'lib/router/useQuery';
import { Throbber } from 'sleepio/components/Throbber';

import {
  EmptyBackground,
  ErrorIconContainer,
  FormattedText,
  TextContainer,
} from './styled';

const errorMessage =
  'Could not find a Sleepio account for this user. For more information, visit:';

const loginScreen = 'login';

const IDP_LOGOUT_URI = 'idp_logout_uri';

export const LoginCallbackScreen = (): ReactElement => {
  const query = useQuery();
  const { productReference } = useSafeParams();
  const {
    authenticate,
    isAuthenticated,
    error,
    loading,
    redirect,
  } = useAuthenticateWithOIDC();
  const { getFeatureFlags, featureFlags, flagsRequested } = useFeatureFlags();
  const [paramError, setParamError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [code, setCode] = useState('');
  const [state, setState] = useState('');
  const [redirected, setRedirected] = useState(false);
  const history = useHistory();
  const safeArea = useSafeArea();

  const logoutIDP = useCallback(
    async (screen = 'login-callback') => {
      // We must set inProgress to false before IDP logout, otherwise when redirected
      // back to this screen we will attempt a logout again, creating a loop.
      setInProgress(false);
      const idpLogoutUrl = featureFlags?.[IDP_LOGOUT_URI];
      if (idpLogoutUrl) {
        const redirectUri = getRedirectUri(productReference, screen);
        const logoutUrl = `${idpLogoutUrl}?returnTo=${redirectUri}`;
        await openInAppBrowser(logoutUrl);
      }
    },
    [featureFlags, productReference]
  );

  const handleClose = () => {
    history.push(`/${productReference}/login-screen`);
  };

  // Get the feature flags, used to get the IDP logout URL
  useEffect(() => {
    if (!flagsRequested) {
      getFeatureFlags();
    }
  }, [flagsRequested, getFeatureFlags]);

  // Handle a redirect outside of the app, e.g. to complete onboarding on web.
  // When redirecting, we must logout of the IDP.
  useEffect(() => {
    if (redirect && !redirected) {
      setRedirected(true);
      logoutIDP(loginScreen);
      openURL(redirect);
    }
  }, [redirect, redirected, logoutIDP]);

  // Grab the code and state query parameters
  useEffect(() => {
    const codeParam = query.get('code');
    const stateParam = query.get('state');
    const errorParam = query.get('error');
    const errorDescriptionParam = query.get('error_description');

    if (
      typeof codeParam === 'string' &&
      typeof stateParam === 'string' &&
      !inProgress &&
      !error
    ) {
      setInProgress(true);
      setCode(codeParam);
      setState(stateParam);
    } else if (!inProgress) {
      // Missing required params to request auth tokens
      setParamError(true);
      // Capture log if the OIDC provider returned an error
      if (errorParam) {
        const errorMessage = `${errorParam}${
          errorDescriptionParam ? ` - ${errorDescriptionParam}` : ''
        }`;
        reporter.log(`OIDC login fail: ${errorMessage}`, undefined, {
          silent: true,
        });
      }
    }
  }, [query, inProgress, error]);

  // Handle the request for auth tokens when code and state are set
  useEffect(() => {
    if (code && state && !loading && !error && !isAuthenticated) {
      authenticate(code, state);
    } else if (!loading && error && !isAuthenticated && inProgress) {
      // Authentication failed so we must logout of the IDP
      logoutIDP();
    }
  }, [
    code,
    state,
    authenticate,
    loading,
    error,
    isAuthenticated,
    inProgress,
    logoutIDP,
  ]);

  if (isAuthenticated) {
    return <Redirect to={`/${productReference}/home`} />;
  }

  // The user has been redirected out of the app, e.g. to complete the LST flow.
  // So we navigate the app back to the login screen.
  if (redirected) {
    return <Redirect to={`/${productReference}/${loginScreen}`} />;
  }

  const hasError = paramError || error;

  const loadingDisplay = (
    <EmptyBackground {...roles('LoginCallbackScreen-loading')}>
      <Throbber {...roles('Throbber')} />
    </EmptyBackground>
  );

  const errorDisplay = (
    <Container {...roles('LoginCallbackScreen-error')}>
      <CloseButton
        {...roles('close-button')}
        onPress={() => handleClose()}
        style={{
          position: 'absolute',
          top: safeArea.top + 10,
          right: safeArea.right + 24,
        }}
      />
      <ErrorIconContainer>
        <ExclamationCircle size={60} style={{ color: '#FD5A3A' }} />
      </ErrorIconContainer>
      <LimitWidth>
        <TextContainer {...roles('login-callback-error')}>
          <FormattedText>{errorMessage}</FormattedText>
          <FormattedText
            style={{
              paddingTop: 16,
            }}
          >
            sleepio.com/nhs
          </FormattedText>
        </TextContainer>
      </LimitWidth>
      <NeedHelpText {...roles('NeedHelpText')}>Need help? Contact</NeedHelpText>
      <NeedHelpLink
        onPress={() => openURL('mailto:hello@sleepio.com')}
        style={{ textAlign: 'center' }}
      >
        hello@sleepio.com
      </NeedHelpLink>
      <LoginFooter />
    </Container>
  );

  return <>{hasError ? errorDisplay : loadingDisplay}</>;
};

export default LoginCallbackScreen;
