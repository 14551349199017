import React, { ComponentProps } from 'react';

import { FormTypes } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { SleepDiaryForm } from 'components/SleepDiaryForm';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

const Story = () => {
  type Props = ComponentProps<typeof SleepDiaryForm>;
  const props: Props = {
    renderRequest: {
      service_name: 'SleepDiary',
      service_version: '1',
      service_method: 'get_diary_entry_form',
      args: {
        diary_date: {
          $date: '2020-06-03',
        },
        form_type: 'create_single_day_entry' as FormTypes,
        initial_responses_diary: {
          time_to_fall_asleep_minutes: 30,
          user_provided_get_into_bed_time: { $time: '6:00 PM' },
          // "try_to_sleep_time": { $time: '23:30'},
          number_of_awakenings: 2,
          total_awakenings_duration_minutes: 30,
          // "final_awakening_time": { $time: '07:30'},
          // "get_out_of_bed_time": { $time: '08:00'},
          sleep_quality: 1,
        },
      },
    },
  };
  const sceneSet = useFakeStorybookSceneSet();
  if (!sceneSet) {
    return null;
  }
  return <SleepDiaryForm {...props} />;
};

addStory('sleep-diary/SleepDiaryForm', () => {
  return (
    <EphemeralStateProvider>
      <CrossPlatformThemeProvider
        theme={getThemeForProduct(ProductReferences.SLEEPIO)}
      >
        <Story />
      </CrossPlatformThemeProvider>
    </EphemeralStateProvider>
  );
});
