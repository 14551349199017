import { applyMiddleware, createStore, Reducer, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  persistReducer as newPersistReducer,
  persistStore,
} from 'redux-persist';

import { asyncNotificationMiddleware } from 'state/notifications/middleware';

import { persistConfig } from './persist/config';
import { rehydrateMiddleware } from './persistMiddleware';
import rootReducer from './rootReducer';

const persistReducer = newPersistReducer<typeof rootReducer>(
  persistConfig,
  (rootReducer as unknown) as Reducer<typeof rootReducer>
);

export const store: Store = createStore(
  persistReducer,
  composeWithDevTools(
    applyMiddleware(rehydrateMiddleware),
    applyMiddleware(asyncNotificationMiddleware)
  )
);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
