import React, { ReactElement } from 'react';

import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';

import {
  Presentational,
  PresentationalProps,
} from 'components/PopupModal/components/Presentational';
import { SleepDiaryForm } from 'components/SleepDiaryForm';
import { NetworkedFlowingFormProps } from 'components/SleepDiaryForm/components/NetworkedFlowingForm';
import * as roleProps from 'cross-platform/utils/roleProps';

export type SleepDiaryInModalProps = PresentationalProps & {
  renderRequest?: SleepDiaryPayloads['get_diary_entry_form']['request'];
  onSuccessfulSubmit: NetworkedFlowingFormProps['onSuccessfulSubmit'];
} & roleProps.RoleProps;

export const SleepDiaryInModal = ({
  renderRequest,
  onClose,
  onSuccessfulSubmit,
  visible,
  ...rest
}: SleepDiaryInModalProps): ReactElement => (
  <Presentational
    visible={visible}
    onClose={onClose}
    name="SleepDiaryModal"
    {...roleProps.pass(rest, undefined, { default: 'PresentationWrapper' })}
  >
    {renderRequest ? (
      <SleepDiaryForm
        {...roleProps.roles('SleepDiaryForm')}
        renderRequest={renderRequest}
        onSuccessfulSubmit={onSuccessfulSubmit}
      />
    ) : null}
  </Presentational>
);
