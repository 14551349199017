import { useMemo } from 'react';
import { useLocation } from 'react-router-dom'; // Note: web only

/**
 * Hook that uses react-router's useLocation to parse
 * the query string:
 *
 * @usage
 *    const query = useQuery();
 *    query.get("name");
 */
function useQuery(): URLSearchParams {
  const search = useLocation().search;
  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
}

export { useQuery };
