import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { CaptionLanguageOption } from '@bighealth/types/src';

import { CompactCheck } from 'components/icons';
import { roles } from 'cross-platform/utils/roleProps';
import { useMediaPlayerButtonEvent } from 'lib/api/analytics/buttonHooks';
import { setCaptionLanguage, setCaptionState } from 'state/player/actions';
import { CaptionState } from 'state/player/reducer';
import { getCaptionLanguage, getCaptionState } from 'state/player/selectors';

import {
  CloseCaptionSelectOption,
  CloseCaptionSelectOptionText,
  ClosedCaptionSelectStyled,
} from './ClosedCaptionSelect.styled';

type Props = {
  languageOptions: CaptionLanguageOption[];
  onSelect?: () => void;
};

export const ClosedCaptionSelect = ({
  languageOptions,
  onSelect,
}: Props): ReactElement | null => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sendMediaPlayerButtonEvent = useMediaPlayerButtonEvent();
  const captionState = useSelector(getCaptionState);
  const captionLanguage = useSelector(getCaptionLanguage);
  const selectOptions = [
    ...languageOptions,
    {
      key: '',
      text: 'Off',
    },
  ];
  const [value, setValue] = useState(
    selectOptions.find(
      o => o.key.toLowerCase() === captionLanguage.toLowerCase()
    )
      ? captionLanguage
      : ''
  );

  const handleCaptionLanguage = (captionLanguage: string) => {
    // Fire events and update state when a new option is selected
    if (value !== captionLanguage) {
      sendMediaPlayerButtonEvent({
        component: 'closed_captions',
        component_state: captionLanguage === '' ? 'off' : captionLanguage,
      });
      setValue(captionLanguage);
      dispatch(setCaptionLanguage(captionLanguage));
    }
  };

  const handleCaptionState = (captionLanguage: string) => {
    const newCaptionState =
      captionLanguage === '' ? CaptionState.OFF : CaptionState.ON;

    if (captionState === newCaptionState) {
      return;
    }

    dispatch(setCaptionState(newCaptionState));
  };

  return (
    <ClosedCaptionSelectStyled
      {...roles('ClosedCaptionSelect')}
      style={{
        opacity: 1,
      }}
    >
      {selectOptions.map(option => {
        return (
          <CloseCaptionSelectOption
            {...roles('ClosedCaptionSelectOption')}
            key={option.key}
            onPress={() => {
              handleCaptionLanguage(option.key);
              handleCaptionState(option.key);

              if (onSelect) {
                onSelect();
              }
            }}
          >
            {value === option.key && (
              <CompactCheck
                size={15}
                style={theme.content.controls.player.captionSelect}
              />
            )}
            <CloseCaptionSelectOptionText selected={value === option.key}>
              {option.text}
            </CloseCaptionSelectOptionText>
          </CloseCaptionSelectOption>
        );
      })}
    </ClosedCaptionSelectStyled>
  );
};
