import * as React from 'react';

import { RoleProps } from 'cross-platform/utils/roleProps';

import { WithNetworkAndState } from '../WithNetworkAndState';

import { Providers } from './components/Providers';

/**
 * SessionScreen: Providers and "page" wrappers
 */
export const WithNetworkAndStateAndWrappers: React.FC<RoleProps> = () => {
  return (
    <Providers>
      <WithNetworkAndState />
    </Providers>
  );
};
