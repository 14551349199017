import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { AssetEntity } from '@bighealth/types/src/services/Asset';

const renderData: get_user_session_screen_data.Response = {
  result: {
    session_status: {
      heading: {
        text: "Let's complete your session",
      },
      buttons: [
        {
          text: 'Resume session',
          colorScheme: 'primary_on_dark',
          action: {
            type: 'jump_to_sceneset',
            payload: {
              sceneSetGraphId: 1,
              destinationSceneSetId: 1,
            },
          },
        },
        {
          text: 'Add to Sleep Diary',
          colorScheme: 'standard',
          action: {
            type: 'NAV_TO',
            to: '/sleep_diary',
          },
        },
      ],
    },
    session_list: [
      {
        status: 'IN_PROGRESS',
        heading: {
          text: 'Worry Time',
        },
        status_text: {
          text: 'In progress',
        },
        play_action: {
          type: 'jump_to_sceneset',
          payload: {
            sceneSetGraphId: 2,
            destinationSceneSetId: 22,
          },
        },
        lock_text: {
          text: 'lock_text',
        },
        session_thumb_image_asset: {
          storage_url: '//via.placeholder.com/800x450',
        } as AssetEntity,
      },
      {
        status: 'COMPLETED',
        heading: {
          text: 'sneska_1253_testing_ssg',
        },
        status_text: {
          text: 'Completed',
        },
        play_action: {
          type: 'jump_to_sceneset',
          payload: {
            sceneSetGraphId: 3,
            destinationSceneSetId: 1,
          },
        },
        lock_text: {
          text: 'lock_text',
        },
        session_thumb_image_asset: {
          storage_url: '//via.placeholder.com/800x450',
        } as AssetEntity,
      },
      {
        status: 'LOCKED',
        heading: {
          text: 'Dani Test Graph 1',
        },
        status_text: {
          text: 'Locked',
        },
        play_action: null,
        lock_text: {
          text: 'lock_text',
        },
        session_thumb_image_asset: {
          storage_url: '//via.placeholder.com/800x450',
        } as AssetEntity,
      },
      {
        status: 'LOCKED',
        heading: {
          text: 'Dani Test Graph 2',
        },
        status_text: {
          text: 'Locked',
        },
        play_action: null,
        lock_text: {
          text: 'lock_text',
        },
        session_thumb_image_asset: {
          storage_url: '//via.placeholder.com/800x450',
        } as AssetEntity,
      },
      {
        status: 'LOCKED',
        heading: {
          text: 'Dani Test Graph 3',
        },
        status_text: {
          text: 'Locked',
        },
        play_action: null,
        lock_text: {
          text: 'lock_text',
        },
        session_thumb_image_asset: {
          storage_url: '//via.placeholder.com/800x450',
        } as AssetEntity,
      },
    ],
    optional_content_list: [
      {
        status: 'LOCKED',
        heading: {
          text: 'Optional',
        },
        status_text: {
          text: 'Locked',
        },
        play_action: null,
        lock_text: {
          text: 'lock_text',
        },
        session_thumb_image_asset: {
          storage_url: '//via.placeholder.com/800x450',
        } as AssetEntity,
      },
    ],
    progress_review_data: {
      heading: { text: 'Progress Review Available' },
      buttons: [
        {
          colorScheme: 'alt-primary',
          text: 'Start',
        },
      ],
      action: {
        type: 'jump_to_sceneset',
        payload: { destinationSceneSetId: 8819, sceneSetGraphId: 935 },
      },
    },
    background_image_asset: {
      created_utc: {
        $datetime: '2020-12-16T16:13:26.276158',
      },
      updated_utc: {
        $datetime: '2020-12-16T16:13:26.276166',
      },
      deleted_utc: null,
      id: 3812,
      reference: 'session_screen_bg',
      file_name: 'session-progress-bg@3x.jpg',
      storage_url:
        'http://platgen-local-assets.bighealth.com.local:4572/test-assets/session_screen_bg__2020.12.16.16.13.26.113652.jpg',
      source_stage: 'local',
      description: 'Used by the SessionProgress get_user_session_screen_data',
      height: 2205,
      width: 4317,
      duration: null,
      content_length: null,
      etag: null,
      user: 'admin',
      mime_type: 'image/jpeg',
      display_name: 'session_screen_bg',
      primary_key: 3812,
    },
    has_new_session_available: false,
    has_in_progress_session_available: true,
  },
  status_code: 200,
};

const sessionOneModal: get_user_session_screen_data.PreviewModal = {
  header: {
    style: {},
    childNodes: [
      {
        component: 'Text',
        text: 'Welcome Sleepio',
        style: {},
        alignment: { horizontal: 'middle' },
      },
    ],
  },
  main: {
    column_1: {
      style: {},
      childNodes: [],
    },
    column_2: {
      style: {},
      childNodes: [],
    },
  },
};
export { renderData, sessionOneModal };
