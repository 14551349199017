export enum ContentModalActionTypes {
  ADD_MOUNTED = 'contentModal/ADD_MOUNTED',
  REMOVE_MOUNTED = 'contentModal/REMOVE_MOUNTED',
}

export type ContentModalAction = {
  type: ContentModalActionTypes;
};

export const addMounted = (): ContentModalAction => ({
  type: ContentModalActionTypes.ADD_MOUNTED,
});

export const removeMounted = (): ContentModalAction => ({
  type: ContentModalActionTypes.REMOVE_MOUNTED,
});
