import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

import { getContentWhiteSpace } from '../../../styled/getContentWhiteSpace';
import { DOMAIN } from '../constants';
import { scrollStyleCallback } from '../utils/scrollStyleCallback';

import { ScrollRowProps } from './types';

export const ScrollRowInternal = styled(ScrollView).attrs<ScrollRowProps>(
  props => ({
    horizontal: true,
    persistentScrollbar: true,
    showsHorizontalScrollIndicator: true,
    contentContainerStyle: {
      paddingLeft: getContentWhiteSpace<undefined>(undefined)(props),
      paddingRight: getContentWhiteSpace<undefined>(undefined)(props),
    },
  })
)<ScrollRowProps>`
  ${props => {
    return props.isMobileWeb
      ? {
          marginBottom: 5,
          paddingBottom: 5,
        }
      : {
          marginTop: 20,
          marginBottom: 30,
          paddingBottom: 30,
        };
  }}
  ${scrollStyleCallback}
`;
ScrollRowInternal.displayName = `${DOMAIN}.ScrollRowInternal`;
