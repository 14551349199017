import React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components/native';

import { Text } from 'components/primitives/cross-platform';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';

const Bubble = styled.View`
  position: absolute;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

type InputStyle = {
  top: number;
  left: number;
  height: number;
  width: number;
  fontSize: number;
};

export const PlaceholderSpeechBubble = ({
  text,
  style,
}: {
  style: InputStyle;
  text: string;
}): ReactElement => {
  const { fontSize, ...bubbleStyles } = style;
  const transformStylesToContext = useTransformStylesToContext();
  const transformedBubbleStyles = transformStylesToContext(bubbleStyles);
  const transformedTextStyles = transformStylesToContext({ fontSize });
  return (
    <Bubble
      {...roles(`PlaceholderSpeechBubble-${text}`)}
      style={transformedBubbleStyles}
    >
      <Text style={transformedTextStyles}>{text}</Text>
    </Bubble>
  );
};
