import { ReactNode } from 'react';
import { AccessibilityProps } from 'react-native';

import { PressHandler } from 'components/Button/types';
import { ButtonStates } from 'lib/styles/inputStateStyles';

type ChildFunction = (buttonStates?: {
  isPressing?: boolean;
  onPressIn?: PressHandler;
  onPressOut?: () => void;
}) => ReactNode;

export enum GenericInputButtonAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}

export type GenericInputButtonProps = {
  onClick?: () => void;
  onPress?: () => void;
  displayValue?: string;
  isEditable?: boolean;
  isInput?: boolean;
  testID?: string;
  children?: ReactNode | ChildFunction;
  onPressIn?: PressHandler;
  onPressOut?: PressHandler;
  showCaret?: boolean;
  showBorder?: boolean;
  textAlign?: GenericInputButtonAlign;
  id?: string;
  label?: string;
  isMultiSelect?: boolean;
  onKeyDown?: any;
  onSelect?: any;
  questionId?: any;
  shouldFullAlign?: boolean;
  // Because we sometimes want to pass this information
  // from a parent component.
} & ButtonStates &
  AccessibilityProps;
