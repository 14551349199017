// useRefreshNotifications
/**
 * No-op on web; Only relevant in native apps.
 *
 * @see useRefreshNotifications.tsx
 */

import { NO_OP_MESSAGE__NATIVE } from 'components/LocalNotifications/utils/constants';

import { UseRefreshNotificationsFunc } from '../types';

export const useRefreshNotifications: UseRefreshNotificationsFunc = () => {
  throw Error(NO_OP_MESSAGE__NATIVE);
};
