import React, { ReactElement } from 'react';
import { View } from 'react-native';

import { PracticeGroupPayload, PracticeItemPayload } from '@bighealth/types';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { PracticeItem } from '../PracticeItem/PracticeItem';

import { PracticeGroupHeader } from './PracticeGroup.styled';

export const PracticeGroup = ({
  practiceGroup,
  locked,
}: {
  practiceGroup: PracticeGroupPayload;
  locked: boolean;
}): ReactElement => {
  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();
  return (
    <View {...roles('practiceGroup')}>
      <PracticeGroupHeader
        testID="PracticeGroupHeader"
        locked={locked}
        fontSize={transformStyle(24)}
        marginVertical={transformVerticalStyle(24)}
      >
        {practiceGroup.name}
      </PracticeGroupHeader>
      {practiceGroup.practice_items.map((item, indexItem, practiceItems) => (
        <PracticeItem
          key={indexItem}
          practiceItem={item as PracticeItemPayload}
          isLastItemInGroup={indexItem === practiceItems.length - 1}
          locked={locked || item.button === undefined}
        />
      ))}
    </View>
  );
};
