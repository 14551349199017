import React, { ReactElement, ReactNode } from 'react';

const SceneSet = ({
  children,
}: {
  children: ReactNode;
}): ReactElement | null => {
  return <>{children}</>;
};

export default SceneSet;
