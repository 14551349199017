import React, { ReactElement, ReactNode } from 'react';
import { Animated } from 'react-native';

import { VisibilityOverride } from 'common/constants/enums';
import { roles } from 'cross-platform/utils/roleProps';

export const MediaFadeInOut = ({
  children,
  hidden,
  fadeAnim,
  visibilityOverride,
}: {
  children: ReactNode;
  hidden?: boolean;
  fadeAnim?: Animated.Value;
  visibilityOverride?: VisibilityOverride;
}): ReactElement | null => {
  if (visibilityOverride === VisibilityOverride.SHOW) {
    return <>{children}</>;
  }

  if (visibilityOverride === VisibilityOverride.HIDE || hidden) {
    return null;
  }

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
      }}
      {...roles('MediaFadeInOut')}
    >
      {children}
    </Animated.View>
  );
};
