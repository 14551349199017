import { Scene } from '@bighealth/types';
/**
 * Debug data
 */
const getSceneForSelectHorizontalTime = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      childNodes: [
        {
          questionProps: {
            created_utc: { $datetime: '2020-09-10T18:52:03.445344' },
            updated_utc: { $datetime: '2022-05-20T19:54:48.664009' },
            deleted_utc: null,
            id: 110,
            semantic_id: 'sleep_efficiency_time_into_bed',
            uuid: 'e1678773-e3bd-427b-88eb-00e683844e8c',
            language: 'en',
            version: 1,
            question_pre_text: null,
            question_title: 'What time did you get into bed?',
            response_pre_text: null,
            visual_representation_json: '{}',
            visual_representation_markup: '<markup/>',
            optional: false,
            response_config: {
              initial_visible_response_value: { $time: '22:00:00' },
              response_options: [
                {
                  id: 1,
                  value: '$input',
                  semantic_id: '0',
                  max_response: { $time: '17:55:00' },
                  min_response: { $time: '18:00:00' },
                },
              ],
              validation_schema: {
                type: 'object',
                title: 'answers',
                $schema: 'http://json-schema.org/draft-07/schema#',
                properties: {
                  '1': {
                    not: { maximum: 64800000, exclusiveMinimum: 64500000 },
                    type: 'number',
                  },
                  selections: { type: 'array', maxItems: 1, minItems: 1 },
                },
                description: 'answer option values',
                errorMessage: {
                  _: 'Enter a time',
                  properties: {
                    '1': 'Enter a time between 18:00:00 and 17:55:00',
                  },
                },
              },
              correct_response_ids: [],
              default_response_ids: [],
              max_selections_required: 1,
              min_selections_required: 1,
            },
            response_type: { $ResponseType: 'TIME' },
            display_name: 'sleep_efficiency_time_into_bed',
            primary_key: 110,
            previous_responses: [],
          },
          component: 'SelectHorizontalTime',
        },
      ],
    },
  ],
});

export { getSceneForSelectHorizontalTime };
