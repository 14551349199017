import { useQuery, UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_practice_library_data } from '@bighealth/api/DaylightPracticeLibrary/v2/get_practice_library_data';

import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { SECOND } from 'lib/durations';

import { useQueryProgram } from './useQueryProgram';

const PRACTICE_LIBRARY_CACHE_TIME = 3 * SECOND;

export const useQueryDaylightPracticeLibraryScreenData = (): UseQueryResult<
  get_practice_library_data.Response,
  APIErrorResponse
> => {
  const programId = useQueryProgram().data?.result?.id;
  const result = useQuery<get_practice_library_data.Response, APIErrorResponse>(
    {
      queryKey: get_practice_library_data.queryKey,
      queryFn: () =>
        get_practice_library_data({
          program_id: programId as number,
        }),
      enabled: typeof programId === 'number',
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: PRACTICE_LIBRARY_CACHE_TIME,
      retry: false,
      meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
    }
  );

  return result;
};
