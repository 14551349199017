import React, { ComponentProps, ReactElement, useContext } from 'react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'ramda';

import { SceneActionTypes } from '@bighealth/types/dist/enums';

import { ContentStateContext } from 'components/SceneSetView/providers/ContentContextProvider';
import { UniversalActionButton } from 'components/UniveralButtons';
import { WithBackupFormikContext } from 'components/WithBackupFormikContext';

type ButtonProps = ComponentProps<typeof UniversalActionButton>;

const SceneSetButtonWithoutBackupFormikContext = (
  props: ButtonProps
): ReactElement => {
  const { isValid, isSubmitting, touched } = useFormikContext();
  const [{ isSingleClickEnabledQuestion }] = useContext(ContentStateContext);

  let isDisabled = false;
  if (props.action) {
    if (
      props.action.type === SceneActionTypes.NEXT ||
      props.action.type === SceneActionTypes.SUBMIT ||
      props.action.type === SceneActionTypes.JUMP_TO_SCENESET_BY_ID
    ) {
      if (isSingleClickEnabledQuestion) {
        // if user navigated to previous question
        if (isValid && isEmpty(touched)) {
          isDisabled = false;
        } else {
          isDisabled = true;
        }
      } else {
        isDisabled = !isValid || isSubmitting;
      }
    } else if (props.action.type === SceneActionTypes.SKIP_TO_NEXT) {
      isDisabled = isSubmitting;
    }
  }
  return <UniversalActionButton {...props} isDisabled={isDisabled} />;
};

const Button = (props: ButtonProps): ReactElement => {
  return WithBackupFormikContext(
    props,
    SceneSetButtonWithoutBackupFormikContext
  );
};

export default Button;
