import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { enable_otp } from '@bighealth/api/UserAccountMFA/v1';

import { useQueryProduct } from './useQueryProduct';

export const useQueryMFAEnable = (): UseQueryResult<
  enable_otp.Response,
  APIErrorResponse
> => {
  const productId = useQueryProduct().data?.result?.id;

  const result = useQuery<enable_otp.Response, APIErrorResponse>({
    queryKey: enable_otp.queryKey,
    queryFn: () => enable_otp(),
    enabled: typeof productId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    staleTime: 0,
    retry: false,
  });
  return result;
};
