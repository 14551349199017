import { View } from 'react-native';
import styled from 'styled-components/native';

import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { Text } from 'components/Text';

const DOMAIN = 'NeedHelpModal';

export const Title = styled<typeof Text>(Text)`
  font-size: 24px;
  color: ${props => props.theme.color.overlay.primary};
  text-align: left;
  font-family: '${FontFamilies.Medium}';
`;
Title.displayName = `${DOMAIN}.Title`;

export const ContentText = styled<typeof Text>(Text)`
  font-size: 18px;
  color: ${props => props.theme.color.overlay.primary};
  text-align: left;
  font-family: '${FontFamilies.Medium}';
  margin-top: 32px;
`;
ContentText.displayName = `${DOMAIN}.ContentText`;

export const Content = styled.View``;
Content.displayName = `${DOMAIN}.Content`;

export const Body = styled<typeof View>(View)`
  margin: 24px;
`;
Body.displayName = `${DOMAIN}.Body`;

export const Header = styled<typeof View>(View)`
  border-bottom-width: 1px;
  border-bottom-color: #dfe1e6;
`;
Header.displayName = `${DOMAIN}.Header`;
