import {
  DefaultParagraph,
  ErrorBoundaryDetails,
  ErrorBoundaryParagraph,
} from 'components/Markdown/styled';
import {
  DefaultLinkRenderer,
  ErrorBoundaryLinkRenderer,
} from 'components/Markdown/utils/linkRenderer';

export const renderers = {
  ERROR_BOUNDARY: {
    paragraph: ErrorBoundaryParagraph,
    text: ErrorBoundaryDetails,
    link: ErrorBoundaryLinkRenderer,
  },
  DEFAULT: {
    paragraph: DefaultParagraph,
    link: DefaultLinkRenderer,
  },
};
