import { goalReferenceByLabelText } from '../constants';

import { ProgressEntryCast } from './guardAndCastProgressEntry';

/**
 * Get reference identifier for mapping
 *
 * @see SLEEPIO-4269 "Defensive code: comment"
 * @param castGoal
 * @return
 */
export const getReference = (castGoal: ProgressEntryCast) =>
  castGoal.reference !== undefined
    ? castGoal.reference
    : goalReferenceByLabelText[castGoal.label.text];
