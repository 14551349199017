import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const authenticate_with_google = async (
  args: authenticate_with_google.Args,
  requestConfig?: AxiosRequestConfig
): Promise<authenticate_with_google.Response> => {
  const request: authenticate_with_google.Request = {
    service_name: 'UserAccountAuthentication',
    service_version: '1',
    service_method: 'authenticate_with_google',
    args,
  };

  return await api(request, requestConfig);
};

authenticate_with_google.queryKey =
  'UserAccountAuthentication/authenticate_with_google';

export declare namespace authenticate_with_google {
  export type Args = {
    google_id_token: string;
    device_platform: string;
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserAccountAuthentication';
    service_version: '1';
    service_method: 'authenticate_with_google';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
