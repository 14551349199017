import React, { ReactElement } from 'react';
import { Text } from 'react-native';

import { FillAbsoluteCenter } from 'components/Containers';
import { roles } from 'cross-platform/utils/roleProps';

export const ErrorScreen = ({
  message = 'Error',
}: {
  message?: string;
}): ReactElement => {
  return (
    <FillAbsoluteCenter
      {...roles('daylightErrorScreen')}
      style={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <Text>{message}</Text>
    </FillAbsoluteCenter>
  );
};
