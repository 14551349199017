import React, { useState } from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';

import { TouchableOpacity } from 'components/primitives/cross-platform';
import { addStory } from 'lib/story-books';

import { Presentational } from './components/Presentational';

const Button = styled(TouchableOpacity)`
  margin: 30px;
  padding: 5px;
  border: 1px;
  background: lightgrey;
`;
const Tall = styled.View`
  width: 100px;
  height: 1200px;
  background-color: lightslategray;
`;

const Long = styled.View`
  width: 1200px;
  height: 100px;
  background-color: lightyellow;
`;

export const StoryPopupModalWithState = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <Presentational visible={visible} onClose={() => setVisible(false)}>
        {props.children}
      </Presentational>
      <Button onPress={() => setVisible(true)}>
        <Text>Click to Open Modal</Text>
      </Button>
    </>
  );
};
addStory('PopupModal/components/Presentational Tall w/state', () => (
  <StoryPopupModalWithState>
    <Tall>
      <Text>Tall</Text>
    </Tall>
  </StoryPopupModalWithState>
));
addStory('PopupModal/components/Presentational Long w/state', () => (
  <StoryPopupModalWithState>
    <Long>
      <Text>Long</Text>
    </Long>
  </StoryPopupModalWithState>
));
