import { APIResponse } from '@bighealth/api';

/**
 * MUST BE KEPT IN SYNC WITH VALID API RESPONSE OPTIONS
 * generalLoginWithEmailAndPassword: response.result.status
 */
export enum LoginResultStatus {
  REDIRECT = 'redirect',
}

/**
 * This is used internally.
 * It will generally be in parity with the BE LoginResultDomain value
 * However, there might be cases where it differs, therefores we cannot collapse these two enums into one
 */
export enum LoginPhases {
  IDLE = 'IDLE',
  FETCHING = 'FETCHING',
  OTP = 'OTP',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export type LoginWithFacebookConfig = {
  facebookToken: string;
};

export type LoginWithGoogleConfig = {
  googleToken: string;
};

export type LoginWithEmailPasswordConfig = {
  email: string;
  password: string;
};

const isFacebookConfig = (
  payload: Record<string, string>
): payload is LoginWithFacebookConfig => {
  return !!payload.facebookToken;
};

const isGoogleConfig = (
  payload: Record<string, string>
): payload is LoginWithGoogleConfig => {
  return !!payload.googleToken;
};

const isEmailPasswordConfig = (
  payload: Record<string, string>
): payload is LoginWithEmailPasswordConfig => {
  return !!(payload.email && payload.password);
};

export enum LoginMethods {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  EMAIL_PASSWORD = 'EMAIL_PASSWORD',
  OTHER = 'OTHER',
}

export type LoginTypes =
  | LoginWithEmailPasswordConfig
  | LoginWithFacebookConfig
  | LoginWithGoogleConfig;

export const getLoginMethodForConfig = (config: LoginTypes): LoginMethods => {
  if (isEmailPasswordConfig(config as LoginWithEmailPasswordConfig)) {
    return LoginMethods.EMAIL_PASSWORD;
  }
  if (isFacebookConfig(config as LoginWithFacebookConfig)) {
    return LoginMethods.FACEBOOK;
  }
  if (isGoogleConfig(config as LoginWithGoogleConfig)) {
    return LoginMethods.GOOGLE;
  }
  return LoginMethods.OTHER;
};

export type ErrorState = {
  error: Error;
  status: LoginPhases.ERROR;
};

export type OkState = {
  error: null;
  status: Exclude<LoginPhases, LoginPhases.ERROR>;
};

// eslint-disable-next-line
export const isErrorState = (state: any): state is ErrorState => {
  return !!(state.status === LoginPhases.ERROR && state.error);
};

export type LoginCallback = (config: LoginTypes) => Promise<void>;

export type APICallFactory = () => Promise<APIResponse>;
