import { AccessibilityRole, Role } from 'react-native';

import { Accessibility } from '@bighealth/types';

export type AccessibilityProps = {
  role?: Role;
  accessibilityRole?: AccessibilityRole;
  'aria-level'?: number;
  tabIndex?: number;
};

export const accessibility = (props?: Accessibility): AccessibilityProps => ({
  role: props?.role as Role,
  accessibilityRole: props?.accessibilityRole as AccessibilityRole,
  'aria-level': props?.level,
  tabIndex: props?.tabIndex,
});
