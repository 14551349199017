import { useMemo } from 'react';

import {
  useMediaResponsiveLayoutContext,
  useScreenResponsiveLayoutContext,
} from '../providers';
import {
  MediaContainerScalingBehavior,
  useMediaContainerScalingBehaviorContext,
} from '../providers/MediaContainerScalingBehaviorProvider';

interface Dimension {
  mediaWidth: number;
  mediaHeight: number;
}

// Content will not scale up or down
const SCALING_FALLBACK = 1;

/**
 * Returns the given scale if it is a valid number, fallback if it is NaN.
 * @param num A scale of some kind
 * @param fallback The number to fall back to if num isNaN
 */
const fallbackForNaN = (num: number, fallback: number) =>
  isNaN(num) ? fallback : num;

/**
 * Checks the size of the current media against the size of the screen in addition to the
 * MediaContainerBehavior (all pulled from context) to determine a scale that should be
 * applied to the media's width and height.
 * [Read this for more information](https://bighealth.atlassian.net/wiki/spaces/PE/pages/469795443).
 */
export const useGetMediaScale = (): number => {
  const { mediaHeight, mediaWidth } = useMediaResponsiveLayoutContext();
  const { screenHeight, screenWidth } = useScreenResponsiveLayoutContext();
  const scalingBehavior =
    useMediaContainerScalingBehaviorContext() ??
    MediaContainerScalingBehavior.CONTAIN;

  const scaleMemo = useMemo(() => {
    const heightScale = screenHeight / mediaHeight;
    const widthScale = screenWidth / mediaWidth;

    // We know that scaling the media height by the height scale will fit the media to the screen vertically,
    // so we only need to check the media width using that scale to determine if the media falls
    // within or covers the screen.
    const widthScaledByScreenHeight = mediaWidth * heightScale;
    const shouldUseHeightScale =
      scalingBehavior === MediaContainerScalingBehavior.COVER
        ? widthScaledByScreenHeight >= screenWidth
        : widthScaledByScreenHeight <= screenWidth;

    const scaleToUse = shouldUseHeightScale ? heightScale : widthScale;

    return fallbackForNaN(scaleToUse, SCALING_FALLBACK);
  }, [scalingBehavior, mediaHeight, mediaWidth, screenHeight, screenWidth]);

  return scaleMemo;
};

/**
 * Returns the current media's width and height adjusted so that it properly falls within or
 * covers the screen.
 */
export const useGetMediaDimensions = (): Dimension => {
  const mediaScale = useGetMediaScale();
  const { mediaHeight, mediaWidth } = useMediaResponsiveLayoutContext();

  const memoDimensions = useMemo(() => {
    return {
      mediaWidth: Math.ceil(mediaWidth * mediaScale),
      mediaHeight: Math.ceil(mediaHeight * mediaScale),
    };
  }, [mediaHeight, mediaScale, mediaWidth]);
  return memoDimensions;
};
