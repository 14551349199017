import { ContentPageProps, ContentScreen } from '@bighealth/types';

export const getFirstSlugForScreen = (
  data: ContentPageProps[] | undefined | null
): string | undefined => {
  if (data === null) {
    return undefined;
  }
  if (isNonEmptyPageData(data)) {
    return data[0].slug;
  }
  return undefined;
};

export const getIFUSlugName = (
  data: ContentPageProps[] | undefined | null
): string | undefined => {
  if (data === null) {
    return undefined;
  }
  if (isNonEmptyPageData(data)) {
    const IFUObj = data.find(itm => itm.slug.includes('-ifu'));
    if (IFUObj) {
      return IFUObj.slug;
    }
  }
  return undefined;
};

export function isNonEmptyPageData<P extends ContentScreen>(
  data: P | undefined | null
): data is P {
  return !!data;
}
