import React, { useContext } from 'react';
import { ReactElement } from 'react';
import { View } from 'react-native';
import { Formik } from 'formik';
import { ThemeContext } from 'styled-components/native';

import { SceneAction } from '@bighealth/types/dist/scene-components/client';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { ActionButton, Header } from 'daylight/components';
import { PlayButtonIcon } from 'daylight/components/icons';
import { useTransformContentStyle } from 'daylight/hooks';
import { noOpFunction } from 'lib/noOpFunction';

import { ActionButtonContainer } from './GateScreen.styled';

export const buttonText = 'Start program set-up';

export const GateScreen = ({
  testID,
  headerTitle,
  message,
  handleClose,
  action,
}: {
  testID: string;
  headerTitle: string;
  message?: string;
  handleClose?: () => void;
  action?: SceneAction;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyle = useTransformContentStyle(true);
  const transformVerticalStyle = useTransformContentStyleVertical();

  return (
    <View
      {...roles(testID)}
      style={{
        flex: 1,
        backgroundColor: theme.color.deselectedGrey,
        width: '100%',
      }}
    >
      <Header
        text={headerTitle}
        headerSize={transformStyle(18)}
        closeIconSize={transformStyle(16)}
        handleClose={handleClose}
      />
      {typeof message !== 'undefined' && (
        <Text
          style={{
            paddingTop: transformStyle(36),
            paddingRight: transformStyle(40),
            paddingLeft: transformStyle(40),
            paddingBottom: transformStyle(18),
            color: theme.text.DEFAULT.color,
            fontSize: transformStyle(18),
            textAlign: 'center',
            fontWeight: theme.text.DEFAULT.fontWeight,
            lineHeight: transformStyle(24),
          }}
        >
          {message}
        </Text>
      )}
      {action ? (
        <ActionButtonContainer>
          {/* Formik must be present because of the useActionHandler hook in the button */}
          <Formik initialValues={[]} onSubmit={noOpFunction}>
            <ActionButton
              action={action}
              onPress={noOpFunction}
              isDisabled={false}
              text={buttonText}
              color={theme.color.white}
              fontSize={transformStyle(18)}
              fontWeight={theme.text.DEFAULT.fontWeight}
              style={{
                paddingVertical: transformVerticalStyle(16),
                marginHorizontal: '5%',
                backgroundColor: '#111111',
                borderRadius: theme.buttons?.dimensions.small.borderRadius,
                width: transformStyle(242),
              }}
              icon={
                <View style={{ marginRight: 10 }}>
                  <PlayButtonIcon />
                </View>
              }
            />
          </Formik>
        </ActionButtonContainer>
      ) : null}
    </View>
  );
};
