import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updatePropertyInQuestionPath } from 'state/question-path/actions';
import { QuestionPathStateValue } from 'state/question-path/reducer';
import { getQuestionPathState } from 'state/question-path/selectors';

import { useQuestionPath } from '../useQuestionPath';

export const useQuestionPathState = <P extends keyof QuestionPathStateValue>(
  questionId: number,
  property: P,
  defaultValue: QuestionPathStateValue[P]
): [QuestionPathStateValue[P], (value: QuestionPathStateValue[P]) => void] => {
  const dispatch = useDispatch();
  const questionPath = useQuestionPath(questionId);
  const setter = useCallback(
    (value: QuestionPathStateValue[P]) =>
      dispatch(
        updatePropertyInQuestionPath(questionPath, {
          property,
          value,
        })
      ),
    [dispatch, property, questionPath]
  );
  const value =
    useSelector(getQuestionPathState(questionPath))?.[property] || defaultValue;

  return [value, setter];
};
