import { Platform } from 'react-native';

/**
 * Determines if the application is running on a web platform.
 * @returns {boolean} true if the platform is web, false otherwise.
 */
export const isWeb = (): boolean => {
  return Platform.OS === 'web';
};

/**
 * Determines if the application is running on android platform.
 * @returns {boolean} true if the platform is android, false otherwise.
 */
export const isAndroid = (): boolean => {
  return Platform.OS === 'android';
};
