import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_next_session_available_datetime } from '@bighealth/api/SessionProgress/v1';

import { useQueryProgram } from './useQueryProgram';

export type UseQueryNextSessionAvailableDatetimeReturnType = UseQueryResult<
  get_next_session_available_datetime.Response,
  APIErrorResponse
>;

export const useQueryNextSessionAvailableDatetime = (): UseQueryNextSessionAvailableDatetimeReturnType => {
  const programId = useQueryProgram().data?.result.id ?? 0;

  const result = useQuery<
    get_next_session_available_datetime.Response,
    APIErrorResponse
  >({
    queryKey: [get_next_session_available_datetime.queryKey],
    queryFn: () =>
      get_next_session_available_datetime({
        program_id: programId,
      }),
    enabled: typeof programId === 'number' && programId !== 0,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
