import { CaptionState, PlaybackState } from 'state/player/reducer';

export enum PlayerActions {
  SET_PLAYER_STATUS_READY = 'player/SET_PLAYER_STATUS_READY',
  SHOW_CONTROLS = 'player/SHOW_CONTROLS',
  SHOW_CONTROLS_CLOSE_BUTTON = 'player/SHOW_CONTROLS_CLOSE_BUTTON',
  SET_PLAYER_STATE = 'player/SET_PLAYER_STATE',
  RESET = 'player/RESET',
  INCREMENT_SCENESET_RENDER_KEY = 'player/INCREMENT_SCENESET_RENDER_KEY',
  SET_CAPTION_STATE = 'player/SET_CAPTION_STATE',
  TOGGLE_CAPTIONS = 'player/TOGGLE_CAPTIONS',
  SET_CAPTION_LANGUAGE = 'player/SET_CAPTION_LANGUAGE',
}

export interface ActionWithoutPayload<TType> {
  type: TType;
}

export interface ActionWithPayload<TType, TPayload>
  extends ActionWithoutPayload<TType> {
  type: TType;
  payload: TPayload;
}

export type PlayerAction =
  | ActionWithoutPayload<PlayerActions.INCREMENT_SCENESET_RENDER_KEY>
  | ActionWithoutPayload<PlayerActions.RESET>
  | ActionWithPayload<PlayerActions.SET_PLAYER_STATE, PlaybackState>
  | ActionWithoutPayload<PlayerActions.SET_PLAYER_STATUS_READY>
  | ActionWithPayload<PlayerActions.SHOW_CONTROLS, boolean>
  | ActionWithPayload<PlayerActions.SHOW_CONTROLS_CLOSE_BUTTON, boolean>
  | ActionWithPayload<PlayerActions.SET_CAPTION_STATE, CaptionState>
  | ActionWithoutPayload<PlayerActions.TOGGLE_CAPTIONS>
  | ActionWithPayload<PlayerActions.SET_CAPTION_LANGUAGE, string>;

// It seems weird we don't have a "player not ready" state but it works fine. Possibly I've forgotten something.
// @TODO: Find out why we don't have a "not ready" state and fix it if needed
// @WHEN: When we work on the player next
export const setPlayerStatusReady = (): PlayerAction => ({
  type: PlayerActions.SET_PLAYER_STATUS_READY,
});

export const showControls = (isVisible: boolean): PlayerAction => ({
  type: PlayerActions.SHOW_CONTROLS,
  payload: isVisible,
});

export const showControlsCloseButton = (isVisible: boolean): PlayerAction => ({
  type: PlayerActions.SHOW_CONTROLS_CLOSE_BUTTON,
  payload: isVisible,
});

export const setPlayerState = (payload: PlaybackState): PlayerAction => ({
  type: PlayerActions.SET_PLAYER_STATE,
  payload,
});

export const incrementSceneSetRenderKey = (): PlayerAction => ({
  type: PlayerActions.INCREMENT_SCENESET_RENDER_KEY,
});

export const setCaptionState = (captionState: CaptionState): PlayerAction => ({
  type: PlayerActions.SET_CAPTION_STATE,
  payload: captionState,
});

export const toggleCaptions = (): PlayerAction => ({
  type: PlayerActions.TOGGLE_CAPTIONS,
});

export const setCaptionLanguage = (language: string): PlayerAction => ({
  type: PlayerActions.SET_CAPTION_LANGUAGE,
  payload: language,
});
