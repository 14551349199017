import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { get_user_info } from '@bighealth/api/UserAccount/v1';

import { DomainIDs } from 'common/constants/enums';

import { GlobalState } from '../store/initialState';

export const getIsFirstLogin = (state: GlobalState): boolean =>
  state[DomainIDs.USER].isFirstLogin;

export const getUserInfo = (
  state: GlobalState
): get_user_info.Result | undefined => state[DomainIDs.USER].user?.info;

export const getUserABTestVariants = (
  state: GlobalState
): get_user_session_screen_data.Result['ab_test_variants'] | undefined =>
  state[DomainIDs.USER].user?.abTestVariants;

export const getIsFirstInit = (state: GlobalState): boolean =>
  state[DomainIDs.USER].isFirstInit;
