import { DOMAIN as PARENT_DOMAIN } from '../../constants';

export const DOMAIN = `${PARENT_DOMAIN}.SleepDiaryReminder`;

export const SLEEP_DIARY_REMINDER_TEXT = 'Daily sleep diary reminder';

export const SLEEP_DIARY_REMINDER_DESCRIPTION =
  'Entering your sleep info is key to improving sleep. Make it easier with a helpful reminder.';

export const SLEEP_DIARY_REMINDER_WARNING =
  'Your phone’s notifications are off for Sleepio. ';

export const SLEEP_DIARY_REMINDER_ACTION = 'Go to Settings';

export const SESSION_REMINDER_TEXT = 'Session Reminders';

export const SESSIONS_REMINDER_DESCRIPTION =
  'It takes time to get back into the habit of better sleep. Get helpful tips and reminders to continue on your journey.';
