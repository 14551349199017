import React from 'react';
import { ViewProps } from 'react-native';

import { FlexBox, FlexBoxProps } from '../../FlexBox';

type Props = Pick<
  FlexBoxProps,
  | 'flexGrow'
  | 'flexShrink'
  | 'width'
  | 'style'
  | 'flexWrap'
  | 'children'
  | 'flexBasis'
> &
  ViewProps;
const Col = ({ ...props }: Props): React.ReactElement => <FlexBox {...props} />;
Col.displayName = 'Col';

export { Col };
