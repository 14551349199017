import { useContext } from 'react';

import { useTransformContentStyle } from 'components/ResponsiveLayout/hooks/useGetDynamicContentStyles';
import { useScaleToMedia } from 'components/ResponsiveLayout/hooks/useScaleToMedia';
import {
  ScalingContext,
  ScalingContextContext,
} from 'components/ResponsiveLayout/providers/ScalingContextProvider';

const playerReferenceStyles = {
  iconSize: 42,
  iconMarginBottom: 23,
  titleFontSize: 20,
  titleMarginBottom: 24,
  contentFontSize: 20,
  horizontalPadding: 20,
  verticalPadding: 30,
  horizontalSpacing: 16,
  borderRadius: 6,
  sceneTitleFontSize: 30,
  sceneTitleMarginBottom: 23,
  containerWidth: 640,
  shadowElevation: 10,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowRadius: 32,
};

type Styles = typeof contentReferenceStyles;

const contentReferenceStyles = {
  iconSize: 40,
  iconMarginBottom: 17,
  titleFontSize: 24,
  titleMarginBottom: 16,
  contentFontSize: 24,
  horizontalPadding: 0,
  verticalPadding: 0,
  horizontalSpacing: 16,
  borderRadius: 0,
  sceneTitleFontSize: 0,
  sceneTitleMarginBottom: 0,
  containerWidth: 0,
  shadowElevation: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowRadius: 0,
};

export const useGoalsScalingStyles = (): Styles => {
  const scalingContext = useContext(ScalingContextContext);
  const scaleToContent = useTransformContentStyle();
  const scaleToMedia = useScaleToMedia();
  const styles = {} as Styles;
  if (scalingContext === ScalingContext.ContentScreen) {
    for (const [property, value] of Object.entries(contentReferenceStyles)) {
      styles[property as keyof Styles] = scaleToContent(value);
    }
  } else {
    for (const [property, value] of Object.entries(playerReferenceStyles)) {
      styles[property as keyof Styles] = scaleToMedia(value);
    }
  }
  return styles;
};
