import { RequestConfig, ServiceConfig } from './types';

export const isServiceMethodFlagged = (
  requestConfig: RequestConfig,
  flaggedServiceConfigs: ServiceConfig[]
): boolean => {
  for (const serviceConfig of flaggedServiceConfigs) {
    if (serviceConfig.service === requestConfig.service_name) {
      if (
        serviceConfig.methods.length === 1 &&
        serviceConfig.methods[0] === '*'
      ) {
        return true;
      }

      for (const serviceMethod of serviceConfig.methods) {
        if (serviceMethod === requestConfig.service_method) {
          return true;
        }
      }
    }
  }

  return false;
};
