import styled from 'styled-components';

const DOMAIN = 'GenericLabel';

import { ThemeProp } from 'config/getThemeForProducts';

// For web, use HTML Label
export const Label = styled.label<ThemeProp>`
  font-family: ${p => p.theme.text.fontFamily};
`;
Label.displayName = `${DOMAIN}.Label`;
