import styled from 'styled-components/native';

import { UniversalButton } from 'components/UniveralButtons';

export const Wrapper = styled.View`
  flex-grow: 1;
  background-color: ${props => props.theme.color.background.sleepDiaryForm};
`;
Wrapper.displayName = 'NetworkedFlowingForm.Wrapper';

export const LoadingWrapper = styled.View`
  flex-grow: 1;
  padding-top: 25px;
  background-color: white;
`;
LoadingWrapper.displayName = 'NetworkedFlowingForm.LoadingWrapper';

export const ErrorWrapper = styled.View`
  flex-grow: 1;
`;
ErrorWrapper.displayName = 'NetworkedFlowingForm.ErrorWrapper';

export const ErrorButton = styled(UniversalButton)``;
ErrorButton.displayName = 'NetworkedFlowingForm.ErrorButton';
