import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';
import { NotificationsConfigEntity } from '@bighealth/types/src/services/NotificationsConfig';

export const create_or_update_notifications_config = async (
  args: create_or_update_notifications_config.Args,
  requestConfig?: AxiosRequestConfig
): Promise<create_or_update_notifications_config.Response> => {
  const request: create_or_update_notifications_config.Request = {
    service_name: 'NotificationsConfig',
    service_version: '1',
    service_method: 'create_or_update_notifications_config',
    args,
  };

  return await api(request, requestConfig);
};

create_or_update_notifications_config.queryKey =
  'NotificationsConfig/create_or_update_notifications_config';

export declare namespace create_or_update_notifications_config {
  export type Args = {
    time: {
      $time: string;
    };
    notification_group_id: string;
    enabled_utc?: {
      $datetime: string;
    };
  };

  export interface Request extends APIRequestBody {
    service_name: 'NotificationsConfig';
    service_version: '1';
    service_method: 'create_or_update_notifications_config';
    args: Args;
  }

  export type Result = NotificationsConfigEntity;

  export type Response = APIResponse<Result>;
}
