import React, { ReactElement, useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useDispatch } from 'react-redux';

import { ButtonContainerWithHalfWidth } from 'components/Button/components';
import { LoginFooter } from 'components/Footer';
import { useFetchRemoteNotificationsConfigs } from 'components/LocalNotifications/localNotificationsState';
import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import { Logo } from 'components/Screens/LoginScreen/components/HeaderLogo';
import {
  ButtonContainer,
  Container,
  ErrorContainer,
  Input,
  LabelText,
  LimitWidth,
  LoginScroll,
  NeedHelpLink,
  NeedHelpText,
  NHSText,
  PaddingContainer,
  ValidationText,
} from 'components/Screens/LoginScreen/styled';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
  UniversalLinkButton,
} from 'components/UniveralButtons';
import { ButtonTypes } from 'config/index';
import { Redirect, useHistory, useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { useFeatureFlags } from 'lib/api/hooks/useFeatureFlags';
import { LoginPhases } from 'lib/api/hooks/useLogin';
import { useGeneralLogin } from 'lib/api/hooks/useLogin/useGeneralLogin';
import { useRedirectIsAuthenticated } from 'lib/api/hooks/useRedirectIsAuthenticated';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { isDevMode } from 'lib/isDevMode';
import * as reporter from 'lib/reporter';
import { useQuery } from 'lib/router/useQuery';
import { showQAMenu } from 'lib/showQAMenu';

import MFAVerification from './components/MFAVerification/MFAVerification';
import { NHSLoginButton } from './components/OIDCLogin';
import { getErrorString } from './utils/getErrorString';
import { INVALID_EMAIL, MISSING_EMAIL, MISSING_PASSWORD } from './constants';

type Params = {
  productReference: string;
};

const NHS_ENABLED_FLAG = 'sleepio_nhs_login_enabled';

const LoginScreen = (): ReactElement => {
  const dispatch = useDispatch();
  useRedirectIsAuthenticated();
  useUpdateTitle('Login');

  const [email, setEmail] = useState({
    isValid: false,
    value: '',
    isPresent: false,
    isSubmitted: false,
  });
  const [password, setPassword] = useState({
    isValid: false,
    isSubmitted: false,
    value: '',
  });
  const {
    isLoading,
    fetchRemoteNotificationsConfigs,
  } = useFetchRemoteNotificationsConfigs();
  const [showMFAVerification, setShowMFAVerification] = useState(false);
  const screenOrientation = useScreenOrientation();
  const [loginError, setLoginError] = useState<undefined | Error>(undefined);
  const [otpError, setOtpError] = useState<undefined | Error>(undefined);
  const { login, error, status } = useGeneralLogin();
  const { getFeatureFlags, featureFlags, flagsRequested } = useFeatureFlags();
  const query = useQuery();
  const { productReference } = useParams<Params>();
  const history = useHistory();
  const canSignIn = email.isValid && password.isValid;
  const hasEmailPresentError = email.isSubmitted && !email.isPresent;
  const hasEmailValidError =
    email.isSubmitted && !email.isValid && email.isPresent;
  const hasPasswordError = password.isSubmitted && !password.isValid;
  const isPortrait =
    screenOrientation === 'PORTRAIT' ||
    screenOrientation === 'PORTRAIT-UPSIDEDOWN';

  useEffect(() => {
    if (error) {
      setPassword(prevState => ({ ...prevState, value: '' }));
      setLoginError(error);
    }
  }, [error]);

  useEffect(() => {
    if (!flagsRequested) {
      getFeatureFlags();
    }
  }, [flagsRequested, getFeatureFlags]);

  const handleSubmit = async (): Promise<void> => {
    setEmail(prevState => ({ ...prevState, isSubmitted: true }));
    setPassword(prevState => ({ ...prevState, isSubmitted: true }));
    setLoginError(undefined);
    setOtpError(undefined);

    if (canSignIn) {
      try {
        await login({ email: email.value, password: password.value });
        await fetchRemoteNotificationsConfigs();
      } catch (e) {
        reporter.log(e.message, e);
      }
    }
  };

  const onChangeEmail = (value: string): void => {
    setEmail(prevState => ({
      ...prevState,
      value: value,
      isValid: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value),
      isPresent: value.length > 0,
      isSubmitted: false,
    }));
  };

  const onChangePassword = (value: string): void => {
    setPassword(prevState => ({
      ...prevState,
      value: value,
      isValid: value.length > 0,
      isSubmitted: false,
    }));
  };

  const onOtpErrorHandler = (message: string | undefined) => {
    setShowMFAVerification(false);
    setPassword(prevState => ({ ...prevState, value: '' }));
    setOtpError(new Error(message));
  };

  useEffect(() => {
    if (status === LoginPhases.OTP) {
      setShowMFAVerification(true);
    }
  }, [status]);

  if (status === LoginPhases.SUCCESS) {
    // NOTE: the login call returns the refresh token in the Response Headers.
    // We redirect to home and then we fetch the Program, we get Tokens not Found and we
    // refresh the tokens using the refresh token in the headers.
    return (
      <Redirect
        to={`/${productReference}${query.get('redirect') || '/home'}`}
      />
    );
  }

  const onLogoPress = (): void => {
    if (isDevMode()) {
      showQAMenu(productReference as string, history, dispatch);
    }
  };

  // New Design for Sleepio has different styling vs Daylight
  // but this is still shared with Daylight.   Default is Daylight for now.
  const labelTextSize = productReference === 'sleepio' ? 18 : 16;
  return (
    <Container>
      <LoginScroll>
        <Logo
          onPress={onLogoPress}
          isPortrait={isPortrait}
          variant={'sign_up'}
        />
        <LimitWidth>
          <ButtonContainer>
            {showMFAVerification ? (
              <>
                <MFAVerification
                  productReference={productReference}
                  email={email.value}
                  onError={onOtpErrorHandler}
                />
              </>
            ) : (
              <>
                <LabelText style={{ fontSize: labelTextSize }}>Email</LabelText>
                <PaddingContainer>
                  <Input
                    {...roles('LoginInput-email')}
                    onChangeText={onChangeEmail}
                    placeholder={'Email'}
                    placeholderTextColor={'#757575'}
                    keyboardType={'email-address'}
                    type={'email'}
                    hasErrors={Boolean(
                      hasEmailPresentError || hasEmailValidError || error
                    )}
                    required
                    autoCapitalize={'none'}
                    returnKeyType={'next'}
                    autoCorrect={false}
                    value={email.value}
                  />
                  {hasEmailPresentError ? (
                    <ErrorContainer>
                      <ValidationText>{MISSING_EMAIL}</ValidationText>
                    </ErrorContainer>
                  ) : null}
                  {hasEmailValidError ? (
                    <ErrorContainer>
                      <ValidationText>{INVALID_EMAIL}</ValidationText>
                    </ErrorContainer>
                  ) : null}
                </PaddingContainer>
                <LabelText style={{ fontSize: labelTextSize }}>
                  Password
                </LabelText>
                <PaddingContainer style={{ marginBottom: 8 }}>
                  <Input
                    {...roles('GenericInput-password')}
                    onChangeText={onChangePassword}
                    placeholder={'Password'}
                    placeholderTextColor={'#757575'}
                    required
                    value={password.value}
                    secureTextEntry
                    hasErrors={Boolean(hasPasswordError || error)}
                  />
                  {hasPasswordError ? (
                    <ErrorContainer>
                      <ValidationText>{MISSING_PASSWORD}</ValidationText>
                    </ErrorContainer>
                  ) : null}
                  {loginError ? (
                    <ErrorContainer>
                      <ValidationText>
                        {getErrorString(loginError)}
                      </ValidationText>
                    </ErrorContainer>
                  ) : null}
                </PaddingContainer>
                {otpError ? (
                  <PaddingContainer>
                    <ErrorContainer>
                      <ValidationText>
                        {getErrorString(otpError)}
                      </ValidationText>
                    </ErrorContainer>
                  </PaddingContainer>
                ) : null}
                <UniversalLinkButton
                  {...roles('forgot-password-link')}
                  text={'Forgot password?'}
                  colorScheme={ButtonColorSchemes.LinkMidBlue}
                  to={`/${productReference}/forgot-password`}
                  title={'Forgot password'}
                  size={ButtonSizes.SmallText}
                  style={{ marginBottom: 48 }}
                  textStyleProps={{
                    textDecorationLine: 'underline',
                    alignSelf: 'flex-start',
                    fontSize: 16,
                  }}
                />
                <ButtonContainerWithHalfWidth type={ButtonTypes.PRIMARY_BUTTON}>
                  <UniversalButton
                    {...roles('login-button')}
                    onPress={handleSubmit}
                    text={'Log in'}
                    colorScheme={ButtonColorSchemes.AltPrimary}
                    size={ButtonSizes.Small}
                    isDisabled={isLoading || status === LoginPhases.FETCHING}
                  />
                </ButtonContainerWithHalfWidth>
                {featureFlags?.[NHS_ENABLED_FLAG] ? (
                  <>
                    <NHSText>In England?</NHSText>
                    <NHSLoginButton />
                  </>
                ) : null}
              </>
            )}
          </ButtonContainer>
        </LimitWidth>
        <NeedHelpText>Need help? Contact</NeedHelpText>
        <NeedHelpLink
          onPress={() => Linking.openURL('mailto:hello@sleepio.com')}
        >
          hello@sleepio.com
        </NeedHelpLink>
        <LoginFooter />
      </LoginScroll>
    </Container>
  );
};

export default LoginScreen;
