import React, { ReactElement } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';
import { Path, Svg } from 'react-native-svg';

import {
  closeButtonHeight,
  closeButtonWidth,
} from 'components/PopupModal/constants';

export type Props = {
  onPress?: TouchableOpacityProps['onPress'];
  width?: ViewStyle['width'];
  height?: ViewStyle['height'];
  style?: ViewStyle & { color?: string };
} & TouchableOpacityProps;

const CloseButton = (props: Props): ReactElement => {
  const { onPress, width, height, style, ...rest } = props;
  const flatStyles = StyleSheet.flatten(style);

  return (
    <TouchableOpacity
      {...rest}
      onPress={onPress}
      style={{ ...flatStyles, width, height }}
      accessibilityRole="button"
      accessibilityLabel="close"
    >
      <Svg style={{ width, height }} viewBox="0 0 32 32">
        <Path
          fill={(flatStyles as Props['style'])?.color || '#163258'}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.333 8.547l-1.88-1.88-7.454 7.453-7.453-7.453-1.88 1.88L14.119 16l-7.453 7.454 1.88 1.88 7.453-7.454 7.454 7.454 1.88-1.88L17.879 16l7.454-7.453z"
        />
      </Svg>
    </TouchableOpacity>
  );
};

CloseButton.defaultProps = {
  width: closeButtonWidth,
  height: closeButtonHeight,
};
export { CloseButton };
