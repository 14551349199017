import { useDispatch, useSelector } from 'react-redux';

import { ResponseOption } from '@bighealth/types';

import { QuizInfo } from 'components/forms/InputField';
import { DropdownItem } from 'components/generic-question/Dropdown';
import {
  QuestionPathProperties,
  updatePropertyInQuestionPath,
} from 'state/question-path/actions';
import {
  addQuestionResponseCorrectAnswers,
  clearQuestionResponseCorrectAnswers,
  pushQuestionResponseQuizAnswersByPath,
  QuestionId,
} from 'state/question-response/actions';
import {
  getQuestionResponsesCorrectAnswers,
  getQuestionResponsesQuizAttempts,
  getQuestions,
} from 'state/question-response/selectors';

import { useQuestionPathGetter } from '../useQuestionPathGetter';

import { isCorrectCallbackWithCorrectResponseIds } from './utils/isCorrectWithCorrectResponseIds';
import {
  TryForceComplete,
  tryForceCompleteWithQuestionsAndQuizAttempts,
} from './utils/tryForceCompleteWithQuestionsAndQuizAttempts';

export type ClearCallback = () => void;

export type RegisterQuizInfoForQuestionCallback = (
  questionId: QuestionId,
  quizInfo?: QuizInfo
) => void;

export type CheckQuizAnswersCallback = (
  values: Record<QuestionId, ResponseOption[]>
) => boolean;

export type GetCorrectAnswersCallback = (
  questionId: QuestionId
) => QuizInfo['correct_response_ids'];

export type SubmitQuizAnswersCallback = (
  values: Record<QuestionId, ResponseOption[]>
) => void;

export type CorrectAnswersByQuestion = Record<QuestionId, QuizInfo>;

export type UseQuizForCurrentSceneReturn = {
  checkQuizAnswers: CheckQuizAnswersCallback;
  registerQuizInfoForQuestion: RegisterQuizInfoForQuestionCallback;
  clear: ClearCallback;
  getCorrectAnswers: GetCorrectAnswersCallback;
  tryForceComplete: TryForceComplete;
  submitQuizAnswers: SubmitQuizAnswersCallback;
};

/**
 * Internals that don't need context for easy testing
 * @param registerQuizInfoForQuestionCallback
 * @param clearCallback
 * @param getCorrectAnswersCallback
 * @param submitQuizAnswersCallback
 */
export const useQuizForCurrentSceneInternal = (
  registerQuizInfoForQuestionCallback: RegisterQuizInfoForQuestionCallback,
  clearCallback: ClearCallback,
  getCorrectAnswersCallback: GetCorrectAnswersCallback,
  submitQuizAnswersCallback: SubmitQuizAnswersCallback,
  getPath: (questionId: number) => string
): UseQuizForCurrentSceneReturn => {
  const answers = useSelector(getQuestionResponsesCorrectAnswers);
  const questions = useSelector(getQuestions);
  const allQuizAttempts = useSelector(getQuestionResponsesQuizAttempts);

  const dispatch = useDispatch();
  const setSelectionQueue = (
    questionPath: string,
    value: DropdownItem[]
  ): void => {
    dispatch(
      updatePropertyInQuestionPath(questionPath, {
        property: QuestionPathProperties.selectionQueue,
        value,
      })
    );
  };
  return {
    registerQuizInfoForQuestion: registerQuizInfoForQuestionCallback,
    checkQuizAnswers: isCorrectCallbackWithCorrectResponseIds(answers),
    tryForceComplete: tryForceCompleteWithQuestionsAndQuizAttempts(
      questions,
      allQuizAttempts,
      getPath,
      setSelectionQueue
    ),
    getCorrectAnswers: getCorrectAnswersCallback,
    submitQuizAnswers: submitQuizAnswersCallback,
    clear: clearCallback,
  };
};

/**
 * External hook
 */
const useQuizForCurrentScene = (): UseQuizForCurrentSceneReturn => {
  const dispatch = useDispatch();
  const getPath = useQuestionPathGetter();

  const correctAnswers = useSelector(getQuestionResponsesCorrectAnswers);

  // registerQuizInfoForQuestionCallback
  const registerQuizInfoForQuestionCallback: RegisterQuizInfoForQuestionCallback = (
    name,
    quizInfo
  ) => {
    if (quizInfo) {
      dispatch(addQuestionResponseCorrectAnswers({ [name]: quizInfo }));
    }
  };

  // clearCallback
  const clearCallback: ClearCallback = () => {
    dispatch(clearQuestionResponseCorrectAnswers());
  };

  //  getCorrectAnswersCallback
  const getCorrectAnswersCallback: GetCorrectAnswersCallback = name =>
    correctAnswers[name]?.correct_response_ids;

  // submitQuizAnswersCallback
  const submitQuizAnswersCallback = (
    values: Record<string, ResponseOption[]>
  ): void => {
    Object.entries(values).map(([name, questionValue]) => {
      dispatch(pushQuestionResponseQuizAnswersByPath(name, questionValue));
    });
  };

  return useQuizForCurrentSceneInternal(
    registerQuizInfoForQuestionCallback, // IDEA move to useQuizForCurrentQuestion
    clearCallback,
    getCorrectAnswersCallback,
    submitQuizAnswersCallback,
    getPath
  );
};

export { useQuizForCurrentScene };
