import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import * as ProductVersionStream from '@bighealth/api/ProductStreamVersion/v1';

import { ProductReferences } from 'common/constants/enums';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { ThrobberBehaviorMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useQueryWithIsFetchingMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/hooks/useQueryWithIsFetchingMeta';
import { isDevMode } from 'lib/isDevMode/index.web';
import * as reporter from 'lib/reporter';
import { GENERAL_ERROR_MESSAGE } from 'lib/strings';

import { getProductStreamKey } from './utils/getProductStreamKey';

export type get_latest_versionReturnType =
  | UseQueryResult<
      ProductVersionStream.get_latest_version.Response,
      APIErrorResponse
    >
  | undefined;

const useGetLatestVersion = (options?: {
  meta: ThrobberBehaviorMeta | undefined;
}): get_latest_versionReturnType => {
  const productReference = useSafeParams<SceneSetParams>()
    ?.productReference as ProductReferences;
  if (typeof productReference === 'undefined') {
    throw TypeError('No productReference is set');
  }

  const query = useQueryWithIsFetchingMeta<
    ProductVersionStream.get_latest_version.Response,
    APIErrorResponse
  >({
    queryKey: ProductVersionStream.get_latest_version.queryKey,
    queryFn: () => {
      return ProductVersionStream.get_latest_version({
        product_stream_key: getProductStreamKey({ productReference }),
      });
    },
    enabled: true, // SLEEPIO-656 FIXME: Ensure force upgrade causes a refresh of this cache
    cacheTime: 5 * 1000,
    onError: err => {
      // These errors must stop the (content) experience
      const debugStr = isDevMode() ? `: ${err}` : '';
      reporter.log(`${GENERAL_ERROR_MESSAGE}${debugStr}`, err, {
        silent: true,
      });
    },
    meta: options?.meta,
    refetchOnWindowFocus: false,
    retry: process.env.NODE_ENV === 'test' ? false : 3, // `retry` seems to mem leak in tests
  });
  return query;
};

export const useQueryProductStreamVersion = {
  get_latest_version: useGetLatestVersion,
};
