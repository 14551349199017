// NOTE: This file is only for 📖 Storybook & ✅ Tests
import React from 'react';
import { Button, View } from 'react-native';

import { DebugThrow } from 'components/sceneset-components/DebugThrow';
import { RoleProps } from 'cross-platform/utils/roleProps';
import * as roleProps from 'cross-platform/utils/roleProps';

export type IsThrowApi = {
  isThrow: boolean;
  setIsThrow: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = {
  text: string;
  api: IsThrowApi;
  error?: Error;
};

const ThrowButton = (props: Props & RoleProps): JSX.Element => {
  return (
    <View>
      <Button
        {...roleProps.pass(props)}
        title={props.text}
        onPress={() => {
          props.api.setIsThrow(true);
        }}
      />
      {props.api.isThrow ? <DebugThrow error={props?.error} /> : null}
    </View>
  );
};

export { ThrowButton };
