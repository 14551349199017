import { Scene } from '@bighealth/types';

import { SleepDiaryFormMocks } from 'components/sceneset-components/SleepDiaryForm/__mocks__/sleepDiaryFormMocks';

const getSleepDiaryFormSceneList = (): Scene[] =>
  [
    ...Object.entries(SleepDiaryFormMocks.v1),
    ...Object.entries(SleepDiaryFormMocks.v2),
  ].map(([_name, mock]) => ({
    component: 'Scene',
    childNodes: [
      {
        component: 'Modal',
        alignment: {
          horizontal: 'middle',
        },
        childNodes: [
          {
            component: 'SleepDiaryForm',
            ...mock,
          },
        ],
      },
    ],
  }));

export { getSleepDiaryFormSceneList };
