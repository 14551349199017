import React, { ReactElement } from 'react';

import { ScheduleContentListProps } from '@bighealth/types';

import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';

import {
  ActivityContainer,
  ActivityText,
  Entry,
  Tag,
  TagsContainer,
} from '../components';

export const ScheduleContentEntry = ({
  activities,
  tags,
}: ScheduleContentListProps): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const validActivities = activities.filter(
    activity => activity.trim().length > 0
  );
  return (
    <Entry>
      {tags?.length ? (
        <TagsContainer>
          {tags.map((props, idx) => (
            <Tag key={idx} {...props} />
          ))}
        </TagsContainer>
      ) : null}
      {validActivities.length > 0 ? (
        validActivities.map((activity, idx) => (
          <ActivityContainer
            key={idx}
            style={{
              paddingBottom:
                idx === activities.length - 1
                  ? 0
                  : styles.entryPointContainerMarginBottom,
            }}
          >
            <ActivityText>{activity}</ActivityText>
          </ActivityContainer>
        ))
      ) : (
        <ActivityContainer
          style={{
            paddingBottom: 0,
          }}
        >
          <ActivityText />
        </ActivityContainer>
      )}
    </Entry>
  );
};
