const EMPTY_SELECTION = '';

type Args = {
  options: number[];
  selectedValue: number | typeof EMPTY_SELECTION | undefined;
};

const findSelectedOption = ({
  options,
  selectedValue,
}: Args): number | undefined =>
  selectedValue !== EMPTY_SELECTION
    ? options.find(option => selectedValue === option)
    : undefined;

export { findSelectedOption };
