import React, { createContext, useContext } from 'react';

type IsInactiveState = {
  isInactiveState: boolean | undefined;
};

const initialState: IsInactiveState = {
  isInactiveState: undefined,
};

export const IsInactiveStateContext = createContext(initialState);
export const useIsInactiveStateContext = (): IsInactiveState =>
  useContext(IsInactiveStateContext);

export const IsInactiveStateProvider = ({
  value,
  children,
}: {
  value: IsInactiveState;
  children: React.ReactElement;
}): React.ReactElement => {
  return (
    <IsInactiveStateContext.Provider value={value}>
      {children}
    </IsInactiveStateContext.Provider>
  );
};
