/* eslint-disable camelcase */
import {
  DeepWritable,
  JSONSchema6,
  NumberResponseOption,
  ResponseOption,
  SleepDiaryQuestion,
} from '@bighealth/types';

import deepMapWhere, { PredicateFn, Source } from 'lib/deep-map-where';
import { MINUTE } from 'lib/durations';

/**
 * Transforms payloads as they are received by the client app
 *
 * Usually client code is expected to treat response payloads as read-only.
 *
 * But this special "middleware" code mimics backend logic
 * @see {@link https://expressjs.com/en/guide/using-middleware.html}
 *
 * It thus has a higher "permission" than normal client-app code,
 * which is why we use DeepWritable to allow changes to the payloads.
 */
const transformQuestionFromMinutesToMilliseconds = <I,>(
  apiPayload: I,
  predicate: PredicateFn
): I =>
  deepMapWhere(
    apiPayload as Source,
    predicate,
    (v): SleepDiaryQuestion => {
      const q: DeepWritable<SleepDiaryQuestion> = v.questionProps as SleepDiaryQuestion;

      const responseType =
        v.questionProps?.response_type?.toLowerCase?.() ||
        v.questionProps?.response_type?.$ResponseType?.toLowerCase?.();
      // IMPORTANT For performance reasons, this code path is protected by upstream-filtering logic in findQuestionsWithMinutes()
      // @see src/lib/api/middleware/response/fromMinutesToMilliseconds/utils/transformResponseResult/index.tsx
      switch (responseType) {
        case 'number':
          // change units of q.response_config.response_options
          q.response_config.response_options = q.response_config.response_options.map(
            (option: ResponseOption): ResponseOption => {
              const dateOption = option as NumberResponseOption;
              const { max_response, min_response, value } = dateOption;
              if (typeof value !== 'number') {
                throw TypeError('Expected number');
              }
              return {
                ...dateOption,
                value: value * MINUTE,
                max_response: max_response
                  ? max_response * MINUTE
                  : max_response,
                min_response: min_response
                  ? min_response * MINUTE
                  : min_response,
              };
            }
          );
          {
            // change units of q.response_config.validation_schema.properties
            const oldProperties = (q.response_config
              .validation_schema as JSONSchema6).properties;
            if (oldProperties) {
              const newProperties: JSONSchema6['properties'] = {};
              for (const [name, value] of Object.entries(oldProperties)) {
                if (typeof value === 'object') {
                  const { minimum, maximum } =
                    (value as JSONSchema6['properties']) || {};

                  if (
                    typeof maximum !== 'number' &&
                    typeof maximum !== 'undefined'
                  ) {
                    throw TypeError(
                      `typeof "maximum" should be undefined or number, instead got ${typeof maximum}`
                    );
                  } else if (
                    typeof minimum !== 'number' &&
                    typeof minimum !== 'undefined'
                  ) {
                    throw TypeError(
                      `typeof "minimum" should be undefined or number, instead got ${typeof minimum}`
                    );
                  }
                  newProperties[name] = {
                    ...value,
                    maximum:
                      typeof maximum === 'number' ? maximum * MINUTE : maximum,
                    minimum:
                      typeof minimum === 'number' ? minimum * MINUTE : minimum,
                  };
                } else {
                  newProperties[name] = value;
                }
              }
              (q.response_config
                .validation_schema as JSONSchema6).properties = newProperties;
            }
          }
          {
            // change units of q.previous_responses
            if (Array.isArray(q?.previous_responses)) {
              // @ts-expect-error types intentionally incorrect
              q.previous_responses = q.previous_responses?.map(response => ({
                ...response,
                value:
                  typeof response.value === 'number'
                    ? response.value * MINUTE
                    : response.value,
              }));
            }
          }
          break;
      }
      return { ...v, questionProps: q };
    }
  ) as I;

export { transformQuestionFromMinutesToMilliseconds };
