import React, { ReactElement } from 'react';

import { Scene } from '@bighealth/types/src/scene-component';

import { useSafeParams } from 'components/Routes/useSafeParams';

import { ToggleableVisibility } from './ToggleableVisibility';
import { useToggleVisibilityOrchestrator } from './useToggleVisibilityOrchestrator';

type ToggleVisibilityProps = Scene.Utils.ToggleVisibility & {
  // "any" here to match React's internal definitions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any>;
};

const WithVisibility = ({
  children,
  hideAtSeconds,
  showAtSeconds,
}: ToggleVisibilityProps): ReactElement => {
  const node = useToggleVisibilityOrchestrator();
  return (
    <ToggleableVisibility
      showAtSeconds={showAtSeconds}
      hideAtSeconds={hideAtSeconds}
      ref={node}
    >
      {children}
    </ToggleableVisibility>
  );
};

export const shouldUseTimers = (
  props: Scene.Utils.ToggleVisibility
): boolean => {
  const { showAtSeconds, hideAtSeconds } = props;
  // Rather than combining these into one big condition, splitting out for readability of intent
  // and clarity of how the code relates to the tests

  // Explicitly no timers set
  if (
    typeof showAtSeconds === 'undefined' &&
    typeof hideAtSeconds === 'undefined'
  ) {
    return false;
  }

  // Implicitly no timers set (show only with no hide)
  if (showAtSeconds === 0 && typeof hideAtSeconds === 'undefined') {
    return false;
  }

  // Implicitly using timers and initially hidden
  if (
    typeof showAtSeconds === 'number' &&
    showAtSeconds > 0 &&
    typeof hideAtSeconds === 'undefined'
  ) {
    return true;
  }

  // Explicit timers
  if (
    typeof showAtSeconds === 'number' &&
    showAtSeconds > 0 &&
    typeof hideAtSeconds === 'number' &&
    hideAtSeconds > 0
  ) {
    return true;
  }

  return true;
};

const ForceRestartTimersEachScene = (
  props: ToggleVisibilityProps
): ReactElement => {
  const { sceneSetId, sceneId } = useSafeParams();
  if (!shouldUseTimers(props)) {
    return <>{props.children}</>;
  }
  // Why are we setting the `key` prop?
  //
  // The problem is that from Scene to Scene these visibility wrappers remain the same
  // node. That is to say, they just re-render. But when we cross Scenes actually we want
  // to re-trigger the timers.
  //
  // A naive way of implementing this is telling React that the component needs must be
  // re-created which we do using a key based on the Scene and SceneSet Id.
  //
  // The problem comes when the component wrapped by this is stateful; by forcing the
  // creation of a new it loses state. So for instance a form (that's not hooked into
  // redux) will clear or a gif will start animating from the start.
  //
  // At present those aren't real problems so this naive implementation will suffice
  //
  return <WithVisibility key={`${sceneSetId}-${sceneId}`} {...props} />;
};

export const WrapWithToggleVisibility = ForceRestartTimersEachScene;
