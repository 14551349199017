import { View } from 'react-native';
import styled from 'styled-components/native';

import { TextAreaOutlineProps } from './types';

export const TextAreaOutline = styled(View)<TextAreaOutlineProps>`
  border: ${({ theme }) => theme.controls.textArea.outlineWidth}px solid
    ${({ hide, theme }) =>
      hide ? 'transparent' : theme.controls.textArea.outlineColor};
  border-radius: ${({ theme }) =>
    theme.controls.textArea.outlineBorderRadius}px;
`;
