import { EdgeInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Cross } from 'components/icons';
import { Text } from 'components/sceneset-components/Text';

import { DOMAIN } from './constants';

export const Container = styled.View<{ safeArea: EdgeInsets }>`
  width: 100%;
  height: 64px;
  justify-content: center;
  border-bottom-width: 1px;
  margin-top: ${props => props.safeArea.top}px;
  margin-right: ${props => props.safeArea.right}px;
  border-bottom-color: ${props => props.theme.localNotifications?.borderColor};
`;
Container.displayName = `${DOMAIN}.Container`;

export const Title = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  align-self: center;
  color: ${props => props.theme.color.black};
`;
Title.displayName = `${DOMAIN}.Title`;

export const CrossStyled = styled(Cross)`
  color: ${props => props.theme.color.black};
`;
CrossStyled.displayName = `${DOMAIN}.CrossStyled`;

export const CloseButton = styled.TouchableOpacity<{ safeArea: EdgeInsets }>`
  position: absolute;
  right: ${props => (props.safeArea.right > 0 ? props.safeArea.right : 20)}px;
`;
CloseButton.displayName = `${DOMAIN}.CloseButton`;
