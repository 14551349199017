import { ProductReferences } from 'common/constants/enums';

/**
 * WARNING product name is related, but not the same as product reference
 *
 * The conceptual product might be "Sleepio", which determines the
 * behavior of the client code.
 *
 * Currently productReference may be "sleepio" or "sleepio_qa" or
 * anything else. This is determined by Product entity in the backend
 *
 * FIXME Correctly determine the conceptual product name of a Product entity
 * WHAT https://bighealth.atlassian.net/browse/PG-1371
 */
const getProductName = (): ProductReferences => {
  return ProductReferences.SLEEPIO;
};

export { getProductName };
