import React from 'react';
import { Linking, Modal, Platform, SafeAreaView } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';

import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import { roles } from 'cross-platform/utils/roleProps';
import { isLandscape } from 'lib/isLandscape';

import { LinkText } from '../../styled';

import { CloseButton } from './CloseButton';
import { Body, ContentText, Header, Title } from './styled';

type NeedHelpModalProps = {
  show: boolean;
  onClose: () => void;
};

const NeedHelpModal = ({ show, onClose }: NeedHelpModalProps) => {
  const safeArea = useSafeArea();
  const screenOrientation = useScreenOrientation();
  const topPadding = 59;
  const landscapeTopPadding =
    safeArea.top < topPadding ? safeArea.top + topPadding : safeArea.top;
  const portraitTopPadding = safeArea.top === 0 ? topPadding : safeArea.top;
  const headerStyles = {
    paddingTop: isLandscape(screenOrientation)
      ? landscapeTopPadding
      : Platform.OS === 'android'
      ? portraitTopPadding + 40
      : portraitTopPadding,
  };

  return (
    <Modal
      {...roles('need-help-modal')}
      visible={show}
      onRequestClose={onClose}
      animationType={'slide'}
      supportedOrientations={['landscape', 'portrait']}
    >
      <SafeAreaView>
        <Header style={headerStyles}>
          <CloseButton onPress={onClose} />
        </Header>
        <Body>
          <Title>Need help?</Title>
          <ContentText>
            You can email us for technical support at{' '}
            <LinkText
              onPress={() => Linking.openURL('mailto:hello@bighealth.com')}
            >
              hello@bighealth.com
            </LinkText>{' '}
            and we&apos;ll respond as soon as possible.
          </ContentText>
        </Body>
      </SafeAreaView>
    </Modal>
  );
};

export default NeedHelpModal;
