import { DownloaderAction, DownloaderActionTypes } from './actions';
export interface Asset {
  remoteURI: string;
  localURI?: string;
}

export interface DownloaderState {
  locallyCachedAssets: Asset[];
  remoteAssetsToDownload: Asset[];
}

export const initialDownloaderState: DownloaderState = {
  locallyCachedAssets: [],
  remoteAssetsToDownload: [],
};

export const downloaderReducer = (
  state = initialDownloaderState,
  action: DownloaderAction
): DownloaderState => {
  const { type, payload } = action;
  switch (type) {
    case DownloaderActionTypes.SET_FILES_TO_DOWNLOAD:
      return {
        ...state,
        remoteAssetsToDownload: payload as Asset[],
      };
    case DownloaderActionTypes.MOVE_ASSET_TO_CACHED_LIST: {
      const { locallyCachedAssets, remoteAssetsToDownload } = state;
      const thisAsset = payload as Asset;
      const thisAssetRemoteURI = thisAsset.remoteURI;
      const newRemoteAssetsToDownload = remoteAssetsToDownload.filter(
        ({ remoteURI }) => remoteURI !== thisAssetRemoteURI
      );
      return {
        ...state,
        locallyCachedAssets: [...locallyCachedAssets, thisAsset],
        remoteAssetsToDownload: newRemoteAssetsToDownload,
      };
    }
    default:
      return state;
  }
};
