import React from 'react';
import QRCode from 'react-qr-code';

import { roles } from 'cross-platform/utils/roleProps';

import { QRCodeWrapper, QRText } from './styled/styled';

type Props = { value: string };
const MFAQRCode = (props: Props): React.ReactElement => {
  return (
    <>
      <QRText>Or scan this code with your authentication app.</QRText>
      <QRCodeWrapper
        role="img"
        accessibilityRole="image"
        aria-label="QR code"
        accessibilityLabel="QR code"
        {...roles('MFAQRCodeWrapper')}
      >
        <QRCode size={175} value={props.value} viewBox={`0 0 175 174`} />
      </QRCodeWrapper>
    </>
  );
};

export default MFAQRCode;
