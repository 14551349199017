import * as History from 'history';

import { matchPath } from 'cross-platform/react-router';
import { DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID } from 'developer/constants';

export type HistoryType = History.History;

export const matchesForgotPassword = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/forgot-password',
    exact: true,
  });

export const matchesTitleScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference',
    exact: true,
  });

export const matchesLogin = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/login',
    exact: true,
  });

export const matchesLoginCallbackScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/login-callback',
    exact: true,
  });

export const matchesLoginScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/login-screen',
    exact: true,
  });

export const matcheDeveloperComponents = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: `/:productReference/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}`,
    exact: false,
  });

export const matcheDebug = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: `/:productReference/qa`,
    exact: false,
  });

export const matchesHomeScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/home',
    exact: true,
  });

export const matchesResourcesScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/resources',
    exact: true,
  });

type PathParams = {
  productReference: string;
  sceneSetGraphId?: string;
  sceneSetId?: string;
  sceneId?: string;
};

export const matchesSceneSetByPathname = (pathname: string): boolean => {
  const short = matchPath<PathParams>(pathname, {
    path: '/:productReference/:sceneSetGraphId/:sceneSetId',
    exact: true,
  });
  const long = matchPath<PathParams>(pathname, {
    path: '/:productReference/:sceneSetGraphId/:sceneSetId/:sceneId',
    exact: true,
  });

  if (short) {
    const params = [short.params.sceneSetGraphId, short.params.sceneSetId];
    for (const param of params) {
      if (Number.isNaN(Number(param))) {
        return false;
      }
    }
    return true;
  }
  if (long) {
    const params = [
      long.params.sceneSetGraphId,
      long.params.sceneSetId,
      long.params.sceneId,
    ];
    for (const param of params) {
      if (Number.isNaN(Number(param))) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const matchesSceneSet = (history: HistoryType): boolean => {
  return matchesSceneSetByPathname(history.location.pathname);
};
