import styled from 'styled-components/native';

import Image, { ResizeMode } from 'cross-platform/Image';

export const ContentView = styled.View`
  flex-direction: column;
  background-color: white;
  align-items: center;
  height: 100%;
`;
export const ImageView = styled.View`
  padding-top: 64px;
`;

export const DoubterImageWrapper = styled.View<{ locked: boolean }>`
  align-items: center;
  opacity: ${({ locked }) => (locked ? 0.2 : 1)};
`;

export const DoubterImage = styled(Image).attrs({
  fit: ResizeMode.contain,
})<{ height: number; width: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
