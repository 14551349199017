import { useCallback, useEffect, useState } from 'react';

import { useSafeParams } from 'components/Routes/useSafeParams';
import {
  UseServiceMethodCall,
  useServiceMethodCall,
} from 'lib/api/hooks/useServiceMethodCall';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import { getRedirectUri } from 'lib/navigation/getRedirectUri';
import { openInAppBrowser } from 'lib/navigation/openInAppBrowser';
import * as reporter from 'lib/reporter';

import { ActionTypes } from '../useServiceMethodCall/ActionTypes';

export const errorLogMessages = {
  login: 'Log in with OIDC attempt failed',
  authUrlRequest: 'Request for OIDC authorization url failed.',
  authUrlResponse: 'Empty authorization url response',
  authPage: 'Unable to open OIDC authorization page',
};

export const useLoginWithOIDC = (redirectScreen = 'login-callback') => {
  const productId = useQueryProduct()?.data?.result.id;
  const { productReference } = useSafeParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [oidcAuthUrlState, requestOidcAuthUrl] = useServiceMethodCall({
    serviceName: 'UserAccountAuthentication',
    serviceVersion: '1',
    serviceMethod: 'get_oidc_authorization_url',
    args: {},
  }) as ReturnType<UseServiceMethodCall>;

  const handleError = (errorMsg: string, error?: Error) => {
    setLoading(false);
    setError(true);
    reporter.log(errorMsg, error, { silent: true });
  };

  // -----------------------------------------------------------------
  // Get the authorization url and open in-app browser for OIDC login
  // -----------------------------------------------------------------
  const login = useCallback(
    async (loginType: string) => {
      try {
        const redirectUri = getRedirectUri(productReference, redirectScreen);
        await requestOidcAuthUrl({
          redirect_uri: redirectUri,
          product_id: productId,
          login_type: loginType,
        });
      } catch (err) {
        handleError(errorLogMessages.login, err);
      }
    },
    [requestOidcAuthUrl, productId, productReference, redirectScreen]
  );

  const openAuthPage = useCallback(async (authUrl: string) => {
    try {
      await openInAppBrowser(authUrl);
      // No longer loading. Either the in-app browser is closed by the user or
      // they are redirected to the redirect_uri (ie. /login-callback) for final
      // authentication.
      setLoading(false);
    } catch (err) {
      handleError(errorLogMessages.authPage, err);
    }
  }, []);

  // Handle call to get authorization url from Platgen
  useEffect(() => {
    switch (oidcAuthUrlState?.status) {
      case ActionTypes.FETCHING:
        setLoading(true);
        setError(false);
        break;
      case ActionTypes.SUCCESS:
        if (oidcAuthUrlState.response?.result?.url) {
          setLoading(false);
          const authUrl = oidcAuthUrlState.response?.result?.url;
          openAuthPage(authUrl);
        } else {
          handleError(errorLogMessages.authUrlResponse);
        }
        break;
      case ActionTypes.ERROR: {
        handleError(errorLogMessages.authUrlRequest);
        break;
      }
      default:
        setLoading(false);
        setError(false);
        break;
    }
  }, [oidcAuthUrlState, openAuthPage]);

  return {
    loading: loading,
    error: error,
    login,
  };
};
