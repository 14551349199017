import { SceneSetGraph } from '@bighealth/types';

export const getSessionName = (
  sceneSetGraphs: SceneSetGraph[] | undefined,
  sceneSetGraphId: number
): string | undefined => {
  if (typeof sceneSetGraphs === 'undefined') {
    return undefined;
  }
  const found = sceneSetGraphs.find(ssg => ssg.id === sceneSetGraphId);
  return found?.name;
};
