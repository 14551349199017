import { EdgeInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { ErrorText } from 'components/Text/ErrorText';
import { Text } from 'components/Text/Text';
import TextInput from 'components/TextInput';

import {
  CONTINUE_BUTTON_VIEW_PADDING_TOP,
  COPY_TEXT_BLOCK_PADDING_TOP,
  DISPLAY_QR,
  ERROR_WRAPPER_PADDING_BOTTOM,
  IS_WRAPPER_VISIBLE_DISPLAY,
  MFA_CONTENT_WRAPPER_PADDING_TOP,
  OVERLAY_TINT,
  PANEL,
  WRAPPER_STYLING,
} from './constants';
import { ComponentsDomain, PopDomain } from './domains';

export const MFAModalMianView = styled.ScrollView``;
MFAModalMianView.displayName = `${ComponentsDomain}.MFAModalMianView`;

export const Wrapper = styled.View<{ visible?: boolean }>`
  ${WRAPPER_STYLING}
  display: ${p => (p.visible ? IS_WRAPPER_VISIBLE_DISPLAY : 'none')};
`;
Wrapper.displayName = `${PopDomain}.Wrapper`;
Wrapper.defaultProps = {
  visible: false,
};

export const OverlayTint = styled(Wrapper)<{
  overlayTintColor?: string;
}>`
  background-color: ${p => p.overlayTintColor};
  ${OVERLAY_TINT}
`;
OverlayTint.displayName = `${PopDomain}.OverlayTint`;
OverlayTint.defaultProps = {
  overlayTintColor: 'rgba(0, 51, 102, 0.8)',
};

export const Panel = styled.View`
  max-width: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.modal.input.borderRadius}px;
  ${PANEL}
`;
Panel.displayName = `${PopDomain}.Panel`;

export const SectionView = styled.ScrollView`
  flex: 1;
`;
SectionView.displayName = `${PopDomain}.SectionView`;

export const HeaderView = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
HeaderView.displayName = `${ComponentsDomain}.HeaderView`;

export const BackButtonWrapper = styled.TouchableOpacity<{
  safeArea: EdgeInsets;
}>`
  padding-left: ${props => Math.max(props.safeArea.left, 16)}px;
  padding-bottom: 16px;
`;
BackButtonWrapper.displayName = `${ComponentsDomain}.BackButtonWrapper`;

export const CloseButtonWrapper = styled.TouchableOpacity<{
  safeArea: EdgeInsets;
}>`
  align-items: flex-end;
  padding-right: ${props => Math.max(props.safeArea.right, 16)}px;
  padding-bottom: 16px;
  margin-left: auto;
`;
CloseButtonWrapper.displayName = `${ComponentsDomain}.CloseButtonWrapper`;

export const HeaderLine = styled.View`
  height: 1px;
  background-color: ${props => props.theme.color.inputOutlineGrey};
`;
HeaderLine.displayName = `${PopDomain}.HeaderLine`;

export const MFAContentWrapper = styled.View<{ safeArea: EdgeInsets }>`
  padding-left: ${props => props.safeArea.left + 24}px;
  padding-right: ${props => props.safeArea.right + 24}px;
  padding-top: ${MFA_CONTENT_WRAPPER_PADDING_TOP}px;
`;
MFAContentWrapper.displayName = `${PopDomain}.MFAContentWrapper`;

export const TitleSubtitleView = styled.View``;
TitleSubtitleView.displayName = `${ComponentsDomain}.TitleSubtitleView`;

export const Title = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 24px;
  color: ${props => props.theme.color.overlay.primary};
`;
Title.displayName = `${ComponentsDomain}.Title`;

export const Subtitle = styled(Text)`
  font-size: 18px;
  padding-bottom: 24px;
  font-weight: 500;
  color: ${props => props.theme.color.overlay.primary};
`;
Subtitle.displayName = `${ComponentsDomain}.Subtitle`;

export const ContinueButtonView = styled.View`
  padding-top: ${CONTINUE_BUTTON_VIEW_PADDING_TOP};
  align-self: center;
`;
ContinueButtonView.displayName = `${ComponentsDomain}.ContinueButtonView`;

export const OtpValue = styled.TouchableOpacity`
  padding: ${props => props.theme.modal.input.paddingLeft}px;
  border: ${props => props.theme.controls.borderStyle};
  border-radius: ${props => props.theme.controls.borderRadius}px;
  background-color: ${props => props.theme.color.disabledInputGrey};
  margin-bottom: ${props => props.theme.modal.input.inputMarginBottom}px;
  width: 100%;
  align-self: center;
`;
OtpValue.displayName = `${ComponentsDomain}.OtpValue`;

export const CopyTextBlock = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: ${COPY_TEXT_BLOCK_PADDING_TOP};
`;
CopyTextBlock.displayName = `${ComponentsDomain}.CopyTextBlock`;

export const CodeTextInputWrapper = styled.View`
  width: 100%;
  align-self: center;
`;
CodeTextInputWrapper.displayName = `${ComponentsDomain}.CodeTextInputWrapper`;

export const CodeTextLabel = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: ${props => props.theme.color.overlay.primary};
`;
CodeTextLabel.displayName = `${ComponentsDomain}.CodeTextLabel`;

export const CodeTextInput = styled(TextInput)<{
  hasErrors?: boolean;
  isPlaceholder?: boolean;
}>`
  font-size: 16px;
  font-weight: 500;
  font-style: ${({ isPlaceholder }) => (isPlaceholder ? 'italic' : 'normal')};
  border-radius: ${props => props.theme.modal.input.borderRadius}px;
  border: ${({ hasErrors }) =>
    hasErrors
      ? `solid 2px rgba(185, 45, 15, 1);`
      : `solid 1px rgba(223, 225, 230, 1);`};
  color: ${({ isPlaceholder, theme }) =>
    isPlaceholder ? theme.color.text.placeholder : theme.color.overlay.primary};
`;
CodeTextInput.displayName = `${ComponentsDomain}.CodeTextInput`;

export const ErrorWrapper = styled.View`
  padding-top: 12px;
  padding-bottom: ${ERROR_WRAPPER_PADDING_BOTTOM};
`;
ErrorWrapper.displayName = `${ComponentsDomain}.ErrorWrapper`;

export const ErrorTextMFA = styled(ErrorText)`
  font-size: 16px;
  font-weight: 500;
`;
ErrorTextMFA.displayName = `${ComponentsDomain}.ErrorTextMFA`;

export const ErrorHintWrapper = styled.TouchableOpacity``;
ErrorHintWrapper.displayName = `${ComponentsDomain}.ErrorHintWrapper`;

export const ErrorHintText = styled(ErrorText)`
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: ${props => props.theme.color.error};
`;
ErrorHintText.displayName = `${ComponentsDomain}.ErrorHintText`;

export const INeedHelpComponentWrapper = styled.TouchableOpacity``;
INeedHelpComponentWrapper.displayName = `${ComponentsDomain}.INeedHelpComponentWrapper`;

export const INeedHelpText = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.theme.color.overlay.primary};
  text-decoration: underline;
  margin: 0 auto;
  padding: 24px;
`;
INeedHelpText.displayName = `${ComponentsDomain}.INeedHelpText`;

export const SubtitleINeedHelp = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.color.overlay.primary};
`;
SubtitleINeedHelp.displayName = `${ComponentsDomain}.SubtitleINeedHelp`;

export const SubtitleLink = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.color.overlay.primary};
  text-decoration: underline;
`;
SubtitleLink.displayName = `${ComponentsDomain}.SubtitleLink`;

export const QRCodeWrapper = styled.View`
  padding-bottom: 0px;
  align-self: center;
  ${DISPLAY_QR}
`;
QRCodeWrapper.displayName = `${ComponentsDomain}.SubtitleLink`;

export const QRText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 24px;
  color: ${props => props.theme.color.overlay.primary};
  ${DISPLAY_QR}
`;
QRText.displayName = `${ComponentsDomain}.QRText`;
