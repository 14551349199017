/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { DdLogs } from '@datadog/mobile-react-native';

const stubWarn: typeof DdLogs.warn = async (..._args) => {};
const stubInfo: typeof DdLogs.info = async (..._args) => {};
const stubError: typeof DdLogs.error = async (..._args) => {};

export const DataDog = {
  warn: stubWarn,
  info: stubInfo,
  error: stubError,
};
