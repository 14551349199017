import React from 'react';

export { GoalReferences } from '@bighealth/types';

import { SceneSetImage } from '../../Media/Image';
import { Text } from '../../Text';
import { ImageProps, TextProps } from '..';

/**
 * Presentational component to render progress on a goal
 *
 * Note: Image includes label text, so label is not rendered
 *
 * @param {TextProps} props.goal
 * @param {TextProps} props.comment
 * @param {TextProps} props.image
 * @returns
 */
export const ProgressDetails = (props: {
  goal: TextProps;
  comment: TextProps;
  image: ImageProps;
}) => (
  <>
    <SceneSetImage {...props.image} />
    <Text {...props.goal} />
    <Text {...props.comment} />
  </>
);

ProgressDetails.displayName = 'ProgressDetails';
