import React, { ReactElement } from 'react';
import styled from 'styled-components/native';

import { SubmitDiaryResult } from '@bighealth/types/dist/services/SleepDiaryPayloads/submit_single_diary_form';
import { SleepDiaryFormProps } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import {
  HeaderView,
  Heading as FormHeadingUnstyled,
} from 'components/SleepDiaryForm/styled';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import ImageUnstyled from 'cross-platform/Image';
import { roles } from 'cross-platform/utils/roleProps';

import { TextOrMarkdown } from './components/TextOrMarkdown';
import { DOMAIN } from './constants';
import {
  HeadingContainer as HeadingContainerWithWidth,
  TipContainer as TipContainerWithWidth,
} from './Reinforcement.styled';

const FormHeading = styled(FormHeadingUnstyled)`
  font-size: 18px;
  font-weight: 400;
  align-self: center;
  color: ${props => props.theme.color.overlay.primary};
`;
FormHeading.displayName = `${DOMAIN}.FormHeading`;

const Wrapper = styled.View``;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

const Image = styled(ImageUnstyled)`
  width: 174px;
  height: 174px;
`;
Image.displayName = `${DOMAIN}.Image`;

const Content = styled.View`
  flex-grow: 1;
  margin-top: 10px;
  align-items: center;
`;
Content.displayName = `${DOMAIN}.Content`;

const HeadingContainer = styled(HeadingContainerWithWidth)`
  margin-left: 24px;
  margin-right: 24px;
`;
HeadingContainer.displayName = `${DOMAIN}.HeadingContainer`;

const Heading = styled(TextOrMarkdown)`
  font-size: 24px;
  margin-top: 24px;
  font-weight: 400;
  color: ${props => props.theme.color.overlay.primary};
`;
Heading.displayName = `${DOMAIN}.Heading`;

const TipContainer = styled(TipContainerWithWidth)`
  flex-grow: 1;
  padding: 24px;
  margin-top: 48px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 8px;
  background-color: white;
`;
TipContainer.displayName = `${DOMAIN}.TipContainer`;

const TipHeading = styled(TextOrMarkdown)`
  font-size: 18px;
  font-weight: 700;
  color: ${props => props.theme.color.overlay.primary};
`;
TipHeading.displayName = `${DOMAIN}.TipHeading`;

const TipMessage = styled(TextOrMarkdown)`
  font-size: 18px;
  font-weight: 500;
  margin-top: 17px;
  color: ${props => props.theme.color.overlay.primary};
`;
TipMessage.displayName = `${DOMAIN}.TipMessage`;

const CloseButton = styled(UniversalButton).attrs({
  colorScheme: ButtonColorSchemes.AltPrimary,
  size: ButtonSizes.Small,
})`
  margin-top: 60px;
  margin-bottom: 54px;
`;
CloseButton.displayName = `${DOMAIN}.CloseButton`;

export type ReinforcementProps = SubmitDiaryResult & {
  onClose: () => void;
  date_label?: SleepDiaryFormProps['date_label'];
};

export const Reinforcement = (props: ReinforcementProps): ReactElement => {
  return (
    <Wrapper>
      <HeaderView>
        <FormHeading
          {...roles(`${DOMAIN}.FormHeading`)}
          {...(props?.date_label || {})}
        />
      </HeaderView>
      <Content {...roles(`${DOMAIN}.Content`)}>
        {props.reinforcement_data.streak_data.image !== null ? (
          <Image
            {...roles(`${DOMAIN}.Image`)}
            alt={props.reinforcement_data.streak_data.image.alt_text}
            src={props.reinforcement_data.streak_data.image.storage_url}
          />
        ) : null}
        <HeadingContainer>
          <Heading
            {...roles(`${DOMAIN}.Heading`)}
            {...props.reinforcement_data.streak_data.heading}
          />
        </HeadingContainer>
        <TipContainer>
          <TipHeading
            {...roles(`${DOMAIN}.TipHeading`)}
            {...props.reinforcement_data.streak_data.subheading}
          />
          <TipMessage
            {...roles(`${DOMAIN}.TipMessage`)}
            {...props.reinforcement_data.streak_data.message}
          />
        </TipContainer>
        <CloseButton
          {...roles(`${DOMAIN}.CloseButton`)}
          text={'Close'}
          onPress={props.onClose}
        />
      </Content>
    </Wrapper>
  );
};
