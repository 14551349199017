import React, { ReactElement } from 'react';

import { ProgressBar } from 'components/ProgressBar/';

export const SessionProgressBar = ({
  sessionProgressPercent,
}: {
  sessionProgressPercent?: number;
}): ReactElement => {
  return (
    <>
      {typeof sessionProgressPercent !== 'undefined' ? (
        <ProgressBar
          value={sessionProgressPercent}
          max={100}
          style={{
            height: 4,
            borderRadius: 9,
            marginTop: 13,
            marginBottom: 6,
          }}
        />
      ) : null}
    </>
  );
};
