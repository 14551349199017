import { fire_ui_events, fire_ui_events_types } from '@bighealth/api/Event/v1';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { getAnalyticsDate } from 'lib/api/analytics/getAnalyticsDate';
import * as reporter from 'lib/reporter';
import { isError } from 'lib/reporter/utils/errors/isError';

export const queryFireUIEvent = async (
  events: fire_ui_events_types.Args['events']
): Promise<void> => {
  try {
    await getQueryClient().fetchQuery(
      [fire_ui_events.queryKey, getAnalyticsDate(), JSON.stringify(events)],
      () =>
        fire_ui_events({
          events,
        }),
      {
        cacheTime: 0,
      }
    );
  } catch (error) {
    reporter.silent.log(
      `fire_ui_events but failed: ${error} ${events}`,
      isError(error) ? error : undefined
    );
  }
};
