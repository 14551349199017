import { View } from 'react-native';
import styled from 'styled-components/native';

import { Text } from 'components/Text';
import { getThemeFont } from 'lib/styles/getThemeFont';
import {
  ButtonStates,
  getStylesForSelectHorizontalButtonStates,
} from 'lib/styles/inputStateStyles';

import { DOMAIN, FONT_SIZE, SPACE_BETWEEN } from './constants';

const getButton = getStylesForSelectHorizontalButtonStates;

export const Wrapper = styled.View<{ isFirst?: boolean; isLast?: boolean }>`
  padding-left: ${p => (p.isFirst ? 0 : SPACE_BETWEEN)}px;
  padding-right: ${p => (p.isLast ? 0 : SPACE_BETWEEN)}px;
  padding-bottom: ${SPACE_BETWEEN * 2}px;
  min-width: ${50}px;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

export const ButtonStatic = styled.TouchableOpacity<
  ButtonStates & {
    onPress?: () => void;
    isFixed?: boolean;
  }
>`
  /* width: fixed */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-grow: ${p => (p.isFixed ? 0 /* give fixed width priority */ : 1)};
  border-radius: 8px;
  margin: 0px;
  border: ${getButton('border')};
  background-color: ${getButton('backgroundColor')};
  padding-left: ${p => (p.isFixed ? 0 /* give fixed width priority */ : 8)}px;
  padding-right: ${p => (p.isFixed ? 0 /* give fixed width priority */ : 8)}px;
`;
ButtonStatic.displayName = `${DOMAIN}.ButtonStatic`;

export const LabelText = styled(Text)<ButtonStates>`
  ${getThemeFont}
  display: flex;
  justify-content: space-between;
  color: ${getButton('color')};
  font-size: ${FONT_SIZE}px;
  font-weight: 500;
  align-items: center;
  text-align: center;
`;
LabelText.displayName = `${DOMAIN}.LabelText`;

export const LabelView = styled(View)`
  pointer-events: none;
  display: flex;
  flex-direction: row;
  margin-top: 8.5px;
  margin-bottom: 8.5px;
  align-items: center;
`;
LabelView.displayName = `${DOMAIN}.LabelView`;
