import React, { ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components/native';

import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { BottomNavigation } from 'components/Navigation/BottomNavigation';
import { roles } from 'cross-platform/utils/roleProps';

import { daylightTabItems } from './daylightTabItems';

const Wrapper = styled.View``;

const HeightWithNav = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

export type GlobalNavigationState = {
  setShowLoadingOverlay: (show: boolean) => void;
};

export const WithGlobalNavigation = ({
  children,
}: {
  children: ReactNode | ((navState: GlobalNavigationState) => ReactNode);
}): ReactElement => {
  const [bottomNavigationHeight, setBottomNavigationHeight] = useState(0);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  return (
    <Wrapper
      {...roles('GlobalNavigation')}
      style={{
        flex: 1,
      }}
    >
      <HeightWithNav
        style={{
          bottom: bottomNavigationHeight,
        }}
      >
        {typeof children === 'function'
          ? children({ setShowLoadingOverlay })
          : children}
      </HeightWithNav>
      <BottomNavigation
        tabItemsData={daylightTabItems}
        setBottomNavigationHeight={setBottomNavigationHeight}
      />
      {showLoadingOverlay && <LoadingOverlay />}
    </Wrapper>
  );
};
