import React, { FunctionComponent, useCallback } from 'react';
import { clone } from 'ramda';

import {
  DateResponseOption,
  DeepWritable,
  Question,
  ResponseOptionValue,
} from '@bighealth/types';
import { Scene } from '@bighealth/types/src/scene-component';

import { Value } from 'components/forms/types';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryProduct, useQueryProgram } from 'lib/api/reactQueryHelpers';
import { MINUTE, WEEK } from 'lib/durations';
import useActionHandler from 'lib/player/useActionHandler';
import * as reporter from 'lib/reporter';
import { getTimezone } from 'lib/timezone';

import { FormPresentation } from '../FormPresentation';

import { submitScheduleNextSession } from './helpers/submitScheduleNextSession';
import { LoadingWrapper, Wrapper } from './styled';

export declare type ScheduleNextSessionProps = {
  title: Omit<Scene.Components.Text, 'component'>;
  questionProps: Question;
  submit_button: Scene.Components.Button;
  continue_button: Scene.Components.Button;
  initialValue: Value;
  schedule_delay_in_minutes?: number;
};

/**
 *
 * @param props
 */
const FormState: FunctionComponent<ScheduleNextSessionProps> = props => {
  const productId = useQueryProduct()?.data?.result.id;

  const { isLoading, data } = useQueryProgram();
  const intervalMinutes =
    typeof props?.schedule_delay_in_minutes === 'number'
      ? props?.schedule_delay_in_minutes
      : data?.result.inter_graph_interval_minutes;

  const actionHandler = useActionHandler(props?.submit_button.action);

  // edit the question props with the minimum date available
  // IDEA: if the user is inactive for a long period of time, then
  // the min Date should change. For now we can assume is negligible
  const newQuestionProps = clone(props?.questionProps);
  const responseOption = newQuestionProps.response_config
    .response_options[0] as DeepWritable<DateResponseOption>;

  // TODO: fix the bug of the DateTimePicker
  // WHEN: PG-1193
  responseOption.min_response = new Date(
    Date.now() + (intervalMinutes ? intervalMinutes * MINUTE : WEEK)
  );

  const goToNextContent = useCallback(async (): Promise<void> => {
    if (typeof actionHandler === 'function') {
      await actionHandler();
    }
  }, [actionHandler]);

  const handleSubmit = useCallback(
    async (value: ResponseOptionValue): Promise<void> => {
      // @TODO: WARNING! This isn't wrapped in `useWithResponseHooks`. Must be fixed
      // useWithResponseHooks was removed from this code because it wasn't doing anything
      // and was breaking tests
      // @WHEN: when react-query is added
      const [, error] = await submitScheduleNextSession({
        productId: productId as number,
        nextSessionDate: value as Date,
        userTimezone: getTimezone(),
      });

      if (!error) {
        try {
          if (typeof actionHandler === 'function') {
            await actionHandler();
          }
        } catch (e) {
          reporter.log(`actionHandler: ${e}`, e);
        }
      }
    },
    [actionHandler, productId]
  );

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingWrapper {...roles('ScheduleNextSessionForm.Loading')} />
      ) : (
        <FormPresentation
          title={props?.title}
          questionProps={newQuestionProps}
          initialValue={props?.initialValue}
          submit_button={props?.submit_button}
          continue_button={props?.continue_button}
          schedule_delay_in_minutes={props?.schedule_delay_in_minutes}
          onSubmit={handleSubmit}
          onContinue={goToNextContent}
        />
      )}
    </Wrapper>
  );
};

export { FormState };
