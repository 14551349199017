import { Scene } from '@bighealth/types';

/**
 * Debug data
 */
const getSceneForShowTime = (): Scene =>
  ({
    childNodes: [
      {
        childNodes: [
          {
            alignment: {},
            childNodes: [],
            component: 'Text',
            scene_component_definition_id: '8',
            showAtSeconds: 5,
            style: {
              color: '#003366',
              fontSize: 24,
              left: 238,
              top: 236,
            },
            text: 'Show at 5',
          },
        ],
        component: 'MediaContainer',
        mediaProps: {
          height: 576,
          width: 1024,
        },
        scene_component_definition_id: '4',
      },
    ],
    component: 'Scene',
    scene_component_definition_id: '3',
  } as Scene);

export { getSceneForShowTime };
