import styled from 'styled-components/native';

export const Wrapper = styled.View`
  min-height: 200px;
`;

export const LoadingWrapper = styled.View`
  flex-grow: 1;
`;
LoadingWrapper.displayName = 'ScheduleNextSessionForm.LoadingWrapper';
