import React, { ReactElement, useContext, useState } from 'react';
import { Text, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { INVALID_EMAIL } from 'components/Screens/LoginScreen/constants';
import { roles } from 'cross-platform/utils/roleProps';
import { MultiLineInput } from 'daylight/components/inputs/MultiLineInput/MultiLineInput';
import { useTransformContentStyle } from 'daylight/hooks';

import { EmailProperties } from './types';

export const EmailInput = ({
  placeholder,
  onChange,
  label,
  emailProps,
}: {
  placeholder: string;
  label: string;
  onChange: (value: string) => void;
  emailProps: EmailProperties;
}): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyle = useTransformContentStyle();
  const [isEmailFocused, setIsEmailFocused] = useState<boolean>(false);

  return (
    <View>
      <Text
        {...roles('email-input-label')}
        style={{ padding: 10, fontFamily: `${FontFamilies.Regular}` }}
      >
        {label}
      </Text>
      <MultiLineInput
        {...roles('email-input-forgot-password')}
        onChangeText={onChange}
        onFocus={() => setIsEmailFocused(true)}
        onBlur={() => setIsEmailFocused(false)}
        placeholder={placeholder}
        placeholderTextColor={theme.color.text.placeholder}
        keyboardType={'email-address'}
        type={'email'}
        autoFocus={false}
        hasErrors={!emailProps.isValid && emailProps.isSubmitted}
        isFocused={isEmailFocused}
        required={true}
        autoCapitalize={'none'}
        returnKeyType={'next'}
        autoCorrect={false}
        value={emailProps.value}
      />
      {!emailProps.isValid && emailProps.isSubmitted ? (
        <Text
          {...roles('invalid-email-error-message')}
          style={{
            color: '#D72020',
            paddingTop: 10,
            fontSize: transformStyle(16),
            fontFamily: `${FontFamilies.Regular}`,
          }}
        >
          {INVALID_EMAIL}
        </Text>
      ) : null}
    </View>
  );
};
