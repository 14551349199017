import { DomainIDs } from 'common/constants/enums';

import { GlobalState } from '../store/initialState';

/**
 * IDEA this could be the only redirector file
 * with dependency on hello-world
 */
export const getPendingRedirection = (state: GlobalState) =>
  state[DomainIDs.REDIRECTOR].pending;
