import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { get_home_screen_data } from '@bighealth/api/DaylightHomeScreen/v1';
import { update_settings } from '@bighealth/api/DaylightUserDailyReminder/v1/update_settings';
import { get_settings } from '@bighealth/api/UserSleepioMetadata/v1';

import * as reporter from 'lib/reporter';

/*
 update_setting: doesn't require variables like user_id, it is captured behind the scenes
 This mutation updates the reminder settings specifying that the user was prompted and requires no args
*/
export const useMutationDailyReminderPrompted = (): UseMutationResult<
  update_settings.Response,
  Error,
  void,
  unknown
> => {
  const queryClient = useQueryClient();
  const result = useMutation(
    () =>
      update_settings({
        settings: {
          daily_reminder_prompted: true,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(get_settings.queryKey);
        queryClient.invalidateQueries(get_home_screen_data.queryKey);
      },
      onError: (error: Error) => {
        reporter.deprecated.warn(
          `useMutationDailyReminderPrompted ${error.message}`
        );
      },
    }
  );
  return result;
};
