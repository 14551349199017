import React from 'react';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { ProductReferences } from 'common/constants/enums';
import {
  useTransformContentStyle,
  useTransformContentStyleVertical,
} from 'components/ResponsiveLayout';
import { getAssetsForProduct } from 'config/getAssetsForProduct';
import { ResizeMode } from 'cross-platform/Image';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

import {
  BackgroundContainer,
  QuestionWrapper,
  Row,
  Wrapper,
} from '../../styled';
import LinkButton from '../LinkButton';

import { EfficiencyHeadingText, HeadingText, ImageContainer } from './styled';

export type SleepEfficiencyProps = RoleProps & {
  data: get_user_session_screen_data.SleepEfficiency;
};

const SleepEfficiency = (props: SleepEfficiencyProps): React.ReactElement => {
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);

  const { greenCircleCheckMark } = getAssetsForProduct(
    ProductReferences.SLEEPIO
  );

  return (
    <Wrapper>
      <BackgroundContainer
        style={{ width: '100%' }}
        {...roles('SleepEfficiency')}
      >
        <QuestionWrapper>
          <Row>
            <ImageContainer
              src={greenCircleCheckMark}
              fit={ResizeMode.contain}
              alt={`Green Check Icon`}
            />
            <EfficiencyHeadingText
              {...roles('EfficiencyTitle')}
              scaleValue={scaleValue}
              scaleYValue={scaleYValue}
              {...props.data.title}
            />
          </Row>
          <Row>
            <HeadingText
              {...roles('EfficiencyHeader')}
              scaleValue={scaleValue}
              scaleYValue={scaleYValue}
              isEfficiencyValue={false}
              {...props.data.heading}
            />
            <HeadingText
              {...roles('EfficiencyValue')}
              scaleValue={scaleValue}
              scaleYValue={scaleYValue}
              {...props.data.sleep_efficiency}
              isEfficiencyValue={true}
            />
          </Row>
          <Row>
            {props.data.buttons.map((btn, idx) => {
              return <LinkButton key={idx} {...btn} />;
            })}
          </Row>
        </QuestionWrapper>
      </BackgroundContainer>
    </Wrapper>
  );
};

export { SleepEfficiency };
