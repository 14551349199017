import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_all_downloads } from '@bighealth/api/SleepioDownload/v1';

import { useQueryProgram } from './useQueryProgram';

export const useQueryAllDownloads = (): UseQueryResult<
  get_all_downloads.Response,
  APIErrorResponse
> => {
  const programId = useQueryProgram()?.data?.result.id;

  const result = useQuery<get_all_downloads.Response, APIErrorResponse>({
    queryKey: get_all_downloads.queryKey,
    queryFn: () =>
      get_all_downloads({
        program_id: programId as number,
      }),
    cacheTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  return result;
};
