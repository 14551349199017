import React, { ReactElement, ReactNode } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { PageTitle as PageTitleUnstyled } from 'components/Screens/ContentScreens';
import { ErrorText as ErrorTextUnstyled } from 'components/Text/ErrorText';
import { UniversalButton } from 'components/UniveralButtons';
import { RoleProps } from 'cross-platform/utils/roleProps';

// IDEA Remove TEXT_FONT_SIZE
// HOW Styles that use TEXT_FONT_SIZE replaced with style object
// WHEN Want to sort out types of Text
export const TEXT_FONT_SIZE = 24; // Variable to avoid type issues.

const StyledWrapper = styled.View`
  flex-direction: column;
  background-color: white;
`;
StyledWrapper.displayName = 'StyledWrapper';

type ViewProps = {
  style?: ViewStyle;
  children?: ReactNode;
} & RoleProps;

export const Wrapper = (props: ViewProps): ReactElement => {
  const safeArea = useSafeArea();
  const { style, ...restProps } = props;
  const newStyle = {
    paddingTop: safeArea.top,
    ...StyleSheet.flatten(style),
  };
  return <StyledWrapper style={newStyle} {...restProps} />;
};

export const PageTitle = styled(PageTitleUnstyled)`
  color: #003366;
  font-size: 46px;
`;
PageTitle.displayName = 'PageTitle';

export const DevModeErrorText = styled(ErrorTextUnstyled)`
  margin-bottom: 24px;
  padding-vertical: 20px;
`;
DevModeErrorText.displayName = 'DevModeErrorText';

export const MarkdownWrapper = styled.View`
  margin-bottom: 24px;
`;
MarkdownWrapper.displayName = 'MarkdownWrapper';

export const Button = styled(UniversalButton)`
  max-width: 353px;
  margin-bottom: 24px;
`;
Button.displayName = 'Button';

export const Line = styled.View`
  display: flex;
`;
Line.displayName = 'Line';
