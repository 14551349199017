import { qaConfigFlags } from './qaConfigFlags';

/**
 * Note: Factory function
 * @param key
 */
export const qaLogFactory = (
  key: string,
  log: typeof console.log = console.log
) =>
  /**
   * Only logs if qa flag is turned on
   * @param args
   */
  (...args: any[]): void => {
    if (qaConfigFlags.getValue(key)) {
      log(...args);
    }
  };
