import { AuthorizationStatus } from '@notifee/react-native';

import { ScreenResourceEvent } from '@bighealth/analytics';

import { getStringFromAuthCode } from 'components/LocalNotifications/utils/getStringFromAuthCode';
import { UIEventCreator } from 'lib/api/reactQueryHelpers/useLazyQueries/useLazyQueryFireUIEvent';

import { AREA } from './constants';

const section = `${AREA}--auth`;
export const permissionScreenEventFactory = (
  status?: AuthorizationStatus | null
): UIEventCreator =>
  async function uiEventCreator(args): Promise<[ScreenResourceEvent]> {
    return [
      {
        occurred_at: args.occurredAt,
        product_id: args.productId,
        program_id: args.programId,
        action: 'viewed',
        name: `screen|viewed|${section}--${getStringFromAuthCode(status)}`,
        resource: 'screen',
        version: '1.0.0',
        type: 'ui',
        body: {
          fields: {
            feature: {
              area: AREA,
              section,
            },
            route: args.url.pathname,
          },
          meta: {
            http: {
              current_url: args.url.href,
              referrer: args.url.href,
              user_agent: args.userAgent,
              client_app_node_package_version: args.clientAppNodePackageVersion,
              product_stream_version: args.productVersionStream,
            },
          },
          relations: {
            scene_set_graph: {
              id: args.sceneSetGraphId,
            },
          },
        },
      },
    ];
  };
