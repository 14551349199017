import { EdgeInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { Text } from 'components/sceneset-components/Text';
import {
  ScaleValueProps,
  ScaleYValueProps,
} from 'sleepio/components/screens/SessionsScreen/utils/scaler';

import { DOMAIN } from './constants';

export type IsMobileWebProps = { isMobileWeb?: boolean };
export type IsLandscapeProps = { isLandscape?: boolean };

export type HeadingTextVariantProps = {
  isCollapsed?: boolean;
} & IsLandscapeProps &
  IsMobileWebProps;

export type HeadingTextProps = React.ComponentProps<typeof Text> &
  ScaleValueProps &
  ScaleYValueProps &
  HeadingTextVariantProps & { edgeInsets?: EdgeInsets } & {
    isProgressReviewWidgetVisible: boolean;
  };

export const HeadingText = styled(Text)<HeadingTextProps>`
  color: ${props => props.theme.color.text.primaryInverse};
  font-weight: 400;
  font-size: ${props => (props.isCollapsed ? 40 : 64)};
`;
HeadingText.displayName = `${DOMAIN}.HeadingText`;
