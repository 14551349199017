import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_progress_data } from '@bighealth/api/DaylightSessionProgress/v1/get_progress_data';

import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { SECOND } from 'lib/durations';

import { useQueryProgram } from './useQueryProgram';

export const useQueryDaylightProgressData = (): UseQueryResult<
  get_progress_data.Response,
  APIErrorResponse
> => {
  const programId = useQueryProgram().data?.result?.id;

  const result = useQuery<get_progress_data.Response, APIErrorResponse>({
    queryKey: get_progress_data.queryKey,
    queryFn: () =>
      get_progress_data({
        program_id: programId as number,
      }),
    enabled: typeof programId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // We keep in memory the result of the query
    // because it is unlikely that the progress screen configuration will change during this time
    cacheTime: 20 * SECOND,
    retry: false,
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });
  return result;
};
