import { AccessibilityProps, LayoutChangeEvent, ViewProps } from 'react-native';

import { AssetEntity } from '@bighealth/types/src/services/Asset';

import { QuizAttribute } from 'components/constants';
import { DropdownItem } from 'components/generic-question/Dropdown';

export type BorderStyles = {
  borderTopLeftRadius: number;
  borderTopRightRadius: number;
  borderBottomLeftRadius: number;
  borderBottomRightRadius: number;
  borderLeftWidth: number;
  borderRightWidth: number;
};

export enum ItemLocation {
  START = 'START',
  END = 'END',
  MIDDLE = 'MIDDLE',
}

export type HorizontalScaleItemProps = {
  quiz?: QuizAttribute;
  label?: string;
  location?: ItemLocation;
  onSelect: (value: DropdownItem['value']) => void;
  selected?: boolean;
  value: DropdownItem['value'];
  displayText?: string;
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
};

export type SelectHorizontalItemProps = ViewProps & {
  label?: string;
  location?: ItemLocation;
  onSelect: (value: DropdownItem['value']) => void;
  selected?: boolean;
  highlight?: boolean;
  value: DropdownItem['value'];
  displayText?: string;
  image?: AssetEntity;
  innerRef?: React.RefObject<unknown>;
  onLayout?: (e: LayoutChangeEvent) => void;
  isFirst?: boolean;
  isLast?: boolean;
  isFixed?: boolean;
};
