/**
 * onWeb, date is instantiated
 *    - new Date() (first time)
 *    - new Date(0) +date.hours + date.minutes(every time after that)
 *
 * Background:
 *    - Dates with no params use today's date/timezone
 *    - Dates with constuctor with the UTC (which in)
 *    @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#parameters}
 *    - London's UTC was +1 in 1970
 *    @see {@link https://www.timeanddate.com/time/zone/uk/london?year=1970}
 *
 * The first time react-datepicker options are generated:
 *    - there is no selected
 *    - which means new Date() is used as the "base"
 * @see {@link https://github.com/Hacker0x01/react-datepicker/blob/master/src/time.jsx#L127}
 *
 * The second time react-datepicker options are generated:
 *    - there is an existing selected date
 *    - we want dates in UTC on epoch, so the base date is created below as new Date(0)
 *    - WARNING: this means Date objects are GMT+0100 in London during winter
 */
const createTimeDate = (date: Date): Date => {
  const setToEpoch = new Date(date.getTime());
  setToEpoch.setDate(1);
  setToEpoch.setMonth(0);
  setToEpoch.setFullYear(1970);
  return setToEpoch;
};

export { createTimeDate };
