import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';

import { useHistory } from 'cross-platform/react-router';

type ExitContentConfig = {
  fallback: string;
  productReference: string;
};

type ContentRedirect = {
  exitContent: (config: ExitContentConfig) => void;
  setRedirect: (str: string) => void;
};

const initialValues: ContentRedirect = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  exitContent: (_config: ExitContentConfig) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setRedirect: (_str: string) => undefined,
};

const ContentRedirectContext = createContext(initialValues);
export const useContentRedirectContext = (): ContentRedirect =>
  useContext(ContentRedirectContext);

type ContentRedirectProviderProps = {
  children: ReactNode;
};

// Provides an ability to register a destination where a user
// should be redirected after exiting the content
//
// The provider is available at any place in the application
// and provides two methods:
// setRedirect - registers a redirect in state
// exitContent - is used by actionHandler, ModalCloseButton and ContentCloseButton
// to leave content screens
export const ContentRedirectProvider = ({
  children,
}: ContentRedirectProviderProps): ReactElement => {
  const history = useHistory();
  const [redirect, setRedirect] = useState('');

  const exitContent = ({ fallback, productReference }: ExitContentConfig) => {
    if (redirect === '') {
      history.push(`/${productReference}/${fallback}`);
    } else {
      setRedirect('');
      history.push(`/${productReference}/${redirect}`);
    }
  };

  return (
    <ContentRedirectContext.Provider value={{ setRedirect, exitContent }}>
      {children}
    </ContentRedirectContext.Provider>
  );
};
