import React, { ReactElement } from 'react';

import { Scene } from '@bighealth/types/src/scene-component';

import { ProgressCard } from '../ProgressCard/ProgressCard';

export const TimeToCheckinView = ({
  lowerText,
  boldText,
}: {
  lowerText?: Scene.Components.Text;
  boldText?: Scene.Components.Text;
}): ReactElement => {
  return <ProgressCard lowerText={lowerText} boldText={boldText} />;
};
