import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { VisibilityOverride } from 'common/constants/enums';
import { ControlsPlay, Pause } from 'components/icons';
import { printableProps } from 'components/layout/lib/printableProps';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { useIsInactiveStateContext } from 'components/SceneSetView/providers/IsInactiveStateProvider';
import { roles } from 'cross-platform/utils/roleProps';
import { useMediaPlayerButtonEvent } from 'lib/api/analytics/buttonHooks';
import { useQueryJumpToSceneSet } from 'lib/api/reactQueryHelpers';
import { usePlayPauseMedia } from 'lib/player/media/usePlayPauseMedia';
import { getIsScenePlayable } from 'lib/player/sceneSetHelpers';
import { qaLogFactory } from 'lib/showQAMenu/qaLogFactory';
import { PlaybackState } from 'state/player/reducer';
import { getControlsAreVisible, getPlayerState } from 'state/player/selectors';

const qaMediaPlayerLog = qaLogFactory('MediaPlayer log');

const PressButton = styled.TouchableOpacity<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
PressButton.displayName = 'PressButton';

const PauseStyled = styled(Pause)`
  color: ${props => props.theme.content.controls.player.iconColor};
`;
PauseStyled.displayName = 'PauseStyled';

const ControlsPlayStyled = styled(ControlsPlay)`
  color: ${props => props.theme.content.controls.player.iconColor};
`;
ControlsPlayStyled.displayName = 'ControlsPlayStyled';

export const MediaPlayerControls = ({
  visibilityOverride,
}: {
  visibilityOverride?: VisibilityOverride;
}): ReactElement | null => {
  const { isInactiveState } = useIsInactiveStateContext();
  const { sceneSetId, sceneId } = useSafeParams<SceneSetParams>();
  const sendMediaPlayerButtonEvent = useMediaPlayerButtonEvent();
  let showControls = useSelector(getControlsAreVisible);
  const playerState = useSelector(getPlayerState);
  const playPauseMedia = usePlayPauseMedia();
  const sceneSet = useQueryJumpToSceneSet(sceneSetId)?.data?.result
    ?.scene_set_json;
  const sceneHasMediaContent = getIsScenePlayable(sceneSet, sceneId);

  if (visibilityOverride === VisibilityOverride.SHOW) {
    showControls = true;
  } else if (visibilityOverride === VisibilityOverride.HIDE) {
    showControls = false;
  }

  qaMediaPlayerLog(
    printableProps({
      sceneHasMediaContent,
      playerState,
      showControls,
      isInactiveState,
    })
  );

  return (
    <PressButton
      accessibilityRole="button"
      role="button"
      {...roles('PlayPauseButton')}
      accessibilityLabel={
        playerState === PlaybackState.PLAYING ? 'Pause' : 'Play'
      }
      disabled={
        !sceneHasMediaContent ||
        playerState === PlaybackState.UNINITIALIZED ||
        playerState === PlaybackState.FINISHED ||
        !showControls ||
        !!isInactiveState
      }
      onPress={async () => {
        if (sceneHasMediaContent) {
          if (playerState === PlaybackState.PLAYING) {
            sendMediaPlayerButtonEvent({
              component: 'play_pause',
              component_state: 'pause',
            });
            playPauseMedia.pause();
          } else {
            sendMediaPlayerButtonEvent({
              component: 'play_pause',
              component_state: 'play',
            });
            // It doesn't matter which order these are called in. Just happens set state before the await is easier to test
            await playPauseMedia.play();
          }
        }
      }}
    >
      {playerState === PlaybackState.PLAYING ? (
        <PauseStyled size={56} {...roles('PauseButtonIcon')} />
      ) : (
        <ControlsPlayStyled size={56} {...roles('PlayButtonIcon')} />
      )}
    </PressButton>
  );
};
