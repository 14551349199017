import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_client_id } from '@bighealth/api/Fitbit/v1';

export const useQueryFitBitClientId = (): UseQueryResult<
  get_client_id.Response,
  APIErrorResponse
> => {
  const result = useQuery<get_client_id.Response, APIErrorResponse>({
    queryKey: get_client_id.queryKey,
    queryFn: () => get_client_id(),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  return result;
};
