import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showControls } from 'state/player/actions';
import { getControlsAreVisible } from 'state/player/selectors';

interface FocusType {
  blur: () => void;
  focus: () => void;
}

export function useFocusStateHandler<
  N extends React.MutableRefObject<FocusType | null>
>(node: N, defaultValue: boolean): [boolean, (isEditing: boolean) => void] {
  const isAutoFocusable = useRef(false);
  const [isEditing, setIsEditing] = useState(defaultValue);

  useEffect(() => {
    if (isAutoFocusable.current && isEditing) {
      node?.current?.focus();
    }
  }, [isAutoFocusable, isEditing, node]);

  const internalSetEditing = (newIsEditing: boolean): void => {
    isAutoFocusable.current = true;
    setIsEditing(newIsEditing);
  };
  return [isEditing, internalSetEditing];
}

export function usePlayerControlsDismiss<
  N extends React.MutableRefObject<FocusType | null>
>(node: N): () => void {
  const dispatch = useDispatch();
  const controlsAreVisible = useSelector(getControlsAreVisible);

  useEffect(() => {
    if (controlsAreVisible) {
      node?.current?.blur();
    }
  }, [controlsAreVisible, node]);

  const playerControlsDismiss = useCallback(() => {
    if (controlsAreVisible) {
      dispatch(showControls(false));
    }
  }, [controlsAreVisible, dispatch]);

  return playerControlsDismiss;
}
