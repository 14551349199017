import styled from 'styled-components/native';

import { Text } from 'components/sceneset-components/Text';

const DOMAN = 'SleepEfficiencyLinkButton';

export * from './styled.platform';

export const LinkText = styled(Text).attrs({ underline: true })<{
  isHovering: boolean;
  isPressing: boolean;
}>`
  font-size: 16px;
  opacity: ${props => (props.isHovering || props.isPressing ? 0.7 : 1)};
  color: ${props => props.theme.color.white};
  text-decoration: underline;
  text-decoration-color: ${props => props.theme.color.white};
`;
LinkText.displayName = `${DOMAN}.LinkText`;
