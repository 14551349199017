import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

export const useGetSvgDimensions = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): { width: number; height: number; viewBox: string } => {
  const transformStyle = useTransformContentStyle();
  const tW = transformStyle(width);
  const tH = transformStyle(height);
  return {
    width: tW,
    height: tH,
    viewBox: `0 0 ${tW} ${tH}`,
  };
};
