import { Text, View } from 'react-native';
import styled from 'styled-components/native';

import { FontFamilies } from 'components/sceneset-components/Text/fonts';

export const PaddedContainer = styled.View`
  flex-direction: column;
  background-color: white;
  height: 100%;
`;

export const BackButtonContainer = styled(View)<{
  paddingTop?: number;
}>`
  flex-direction: row;
  padding-top: ${props =>
    props.paddingTop ? `${props.paddingTop}px` : '58px'};
`;

export const ResetDirectionContainer = styled(View)<{
  padding?: number;
}>`
  flex-direction: column;
  padding: ${props => (props.padding ? `${props.padding}px` : '24px')};
`;
export const ButtonContainer = styled(View)<{
  paddingTop?: number;
}>`
  flex-direction: column;
  padding-top: ${props =>
    props.paddingTop ? `${props.paddingTop}px` : '50px'};
`;

export const EmailSentContainer = styled(View)<{
  padding?: number;
}>`
  flex-direction: column;
  padding: ${props => (props.padding ? `${props.padding}px` : '24px')};
  align-items: center;
`;

export const ForgotPasswordText = styled(Text)<{
  bold?: boolean;
  fontSize?: number;
  marginBottom?: number;
  lineHeight?: number;
}>`
font-family: '${FontFamilies.Regular}';
margin-bottom: ${props =>
  props.marginBottom ? `${props.marginBottom}px` : '0px'};
color: ${({ theme }) => theme.color.text.primary};
font-weight: ${props => (props.bold ? '700' : '500')};
font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '18px')};
line-height: ${props =>
  props.lineHeight ? `${props.lineHeight}px` : '24.3px'};
`;
ForgotPasswordText.displayName = 'ForgotPasswordText';
