import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { ScrollView } from 'react-native';

import { InfoCard } from '@bighealth/types';

import { ProductReferences } from 'common/constants/enums';
import { useBackHandler } from 'components/backHandler/useBackHandler';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { getAssetsForProduct } from 'config/index';
import { useHistory } from 'cross-platform/react-router';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { Header } from 'daylight/components';
import { useTransformContentStyle } from 'daylight/hooks';
import { sendButtonClickedAnalytics } from 'lib/api/analytics/sendButtonClickedAnalytics';
import { useQueryDaylightPracticeLibraryScreenData } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQueryDaylightPracticeLibraryScreenData';
import { useQueryGetButtonClickedAnalyticsRequestValues } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQuerySendButtonClickedAnalytics';
import * as reporter from 'lib/reporter';

import { SelectablePillList } from '../../SelectablePillList';
import { ErrorScreen } from '../ErrorScreen/ErrorScreen';
import { GateScreen } from '../GateScreen/GateScreen';

import { PracticeLibraryInfoCard } from './components/PracticeLibraryInfoCard';
import { Ghost, PracticeGroup } from './components';
import {
  ContentWrapper,
  MonsterImage,
  MonsterImageWrapper,
  ScreenWrapper,
} from './PracticeLibraryScreen.styled';
import { PracticeLibraryScreenProvider } from './PracticeLibraryScreenProvider';

const screenTitle = 'Practice Library';

// TODO: Move this into layout or container
interface CloseableScreenProps extends RoleProps {
  title: string;
  titleFontSize: number;
  iconSize: number;
  onClose: () => void;
}

const CloseableScreen = ({
  title,
  iconSize,
  titleFontSize,
  onClose,
  children,
  ...rest
}: PropsWithChildren<CloseableScreenProps>): ReactElement => {
  return (
    <ScreenWrapper {...rest}>
      <Header
        text={title}
        headerSize={titleFontSize}
        closeIconSize={iconSize}
        handleClose={onClose}
      />
      <ContentWrapper>{children}</ContentWrapper>
    </ScreenWrapper>
  );
};

function InfoCardComponent({ infoCard }: { infoCard: InfoCard | undefined }) {
  if (!infoCard) {
    return null;
  }
  return <PracticeLibraryInfoCard info={infoCard} />;
}

export const PracticeLibraryScreen = (): ReactElement => {
  const history = useHistory();
  const { productReference } = useSafeParams();
  const { productAvatar } = getAssetsForProduct(ProductReferences.DAYLIGHT);
  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();
  useBackHandler(productReference);
  const {
    getUrl,
    productId,
    productVersionStreamResult,
    programId,
  } = useQueryGetButtonClickedAnalyticsRequestValues();

  const [activeIndex, setActiveIndex] = useState(0);

  const practiceLibraryData = useQueryDaylightPracticeLibraryScreenData();

  const onTabPress = (tabIndex: number) => {
    // update state
    setActiveIndex(tabIndex);

    // send analytics event, send and forget
    const section = (
      practiceLibraryData?.data?.result.categories[tabIndex].name ??
      `tabIndex_${tabIndex}`
    ).replace(' ', '_');
    sendButtonClickedAnalytics({
      field: {
        area: 'practice_library',
        section,
      },
      productId,
      programId,
      getUrl,
      productVersionStream: productVersionStreamResult?.data?.result,
    });
  };

  const handleClose = async (): Promise<void> => {
    try {
      history.push(`/${productReference}/home`);
    } catch (e) {
      reporter.log(`error in the navigation to home screen`, e as Error);
    }
  };

  if (practiceLibraryData.status === 'error') {
    // TODO: enable a user to go back to TodayScreen
    return <ErrorScreen message="Could not fetch practice library data" />;
  }

  if (typeof practiceLibraryData.data?.result.warning_message === 'string') {
    return (
      <GateScreen
        testID="practiceLibraryGateScreen"
        headerTitle={screenTitle}
        message={practiceLibraryData.data.result.warning_message}
        handleClose={handleClose}
        action={practiceLibraryData.data.result.action}
      />
    );
  }

  return (
    <PracticeLibraryScreenProvider>
      <>
        <CloseableScreen
          title={screenTitle}
          titleFontSize={transformStyle(18)}
          iconSize={transformStyle(16)}
          onClose={handleClose}
          {...roles('practiceLibrary')}
        >
          {practiceLibraryData.status === 'success' ? (
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ paddingBottom: transformStyle(40) }}
            >
              <SelectablePillList
                labels={practiceLibraryData.data?.result?.categories?.map(
                  ({ name }) => name
                )}
                activeIndex={activeIndex}
                onPress={onTabPress}
              />
              <InfoCardComponent
                infoCard={
                  practiceLibraryData.data?.result?.categories[activeIndex]
                    .info_card
                }
              />
              {practiceLibraryData.data.result.categories[
                activeIndex
              ].groups.map((group, index) => {
                const locked =
                  activeIndex === 0 && index === 0
                    ? false // The first group in CORE program (first category) is never locked
                    : group.practice_items.find(
                        item => item.button !== undefined
                      ) === undefined;
                return (
                  <PracticeGroup
                    key={index}
                    practiceGroup={group}
                    locked={locked}
                  />
                );
              })}
              {activeIndex === 0 && (
                <MonsterImageWrapper
                  testID="MonsterImageWrapper"
                  locked={
                    /**
                     * if button is always defined (every category's group's practice_item has a button),
                     *  then show the monster in full opacity (unlocked state)
                     *  otherwise show at low opacity (locked state)
                     */
                    practiceLibraryData.data.result.categories[0].groups.find(
                      group =>
                        group.practice_items.find(
                          item => item.button === undefined
                        ) !== undefined
                    ) !== undefined
                  }
                >
                  <MonsterImage
                    src={productAvatar}
                    width={transformStyle(165)}
                    height={transformVerticalStyle(101)}
                    alt="Avatar picture: Person looking to the left"
                  />
                </MonsterImageWrapper>
              )}
            </ScrollView>
          ) : (
            <Ghost />
          )}
        </CloseableScreen>
        {(practiceLibraryData.status === 'idle' ||
          practiceLibraryData.status === 'loading') && <LoadingOverlay />}
      </>
    </PracticeLibraryScreenProvider>
  );
};
