import { uniq } from 'ramda';

import {
  Caption,
  CaptionLanguageOption,
  SceneChild,
  SceneSet,
} from '@bighealth/types';

import { CCMENU_ENABLED } from 'config/envVars/feature-flags';

/**
 * Returns the captions data used in a given SceneSet
 * @param sceneSet
 */
export const getCaptionLanguageOptionsFromSceneSet = (
  sceneSet?: SceneSet
): CaptionLanguageOption[] | [] => {
  if (!sceneSet) {
    return [];
  }
  const captions: CaptionLanguageOption[] = [];

  // @TODO replace with deepMapWhere
  // WHEN before merge into develop
  // @see https://github.com/sleepio/react-native-app/blob/legacy-daylight/src/common/lib/deepMapWhere/index.test.js
  // @TODO Replace SceneChild with new interfaces.
  // WHEN ASAP
  const traverseTree = ({
    childNodes: rootChildNodes,
    component: rootComponent,
    sourceProps,
  }: SceneChild & {
    sourceProps?: {
      captions_data?: Caption[];
    };
  }): SceneChild => {
    if (sourceProps?.captions_data && sourceProps.captions_data.length > 0) {
      captions.push(
        ...sourceProps.captions_data.map((c: Caption) => ({
          key: c.lang_tag,
          text: c.lang_label,
        }))
      );
    }

    // Pretty much returns the same thing (for convenience)
    return {
      component: rootComponent,
      sourceProps,
      childNodes: rootChildNodes
        ? rootChildNodes.map(
            ({ component, childNodes, sourceProps }): SceneChild => {
              return traverseTree({
                childNodes,
                component,
                sourceProps: sourceProps as {
                  captions_data?: Caption[];
                },
              });
            }
          )
        : undefined,
    };
  };

  if (!CCMENU_ENABLED) {
    return captions;
  }
  // @TODO: Remove this unknown and sort out the definition of SceneSet
  traverseTree((sceneSet as unknown) as SceneChild); // We're treating the SceneSet like a childNode here (for convenience)

  return uniq(captions).filter(Boolean);
};

export default getCaptionLanguageOptionsFromSceneSet;
