import React from 'react';

import { ContentAttribute } from 'components/constants';
import { Content } from 'components/layout/Content';
import { SpeechLocation } from 'config/getThemeForProducts/utils/createTheme/SpeechLocationSchemesTypes';

type QuoteProps = {
  children: React.ReactNode;
};

export const QuoteLeft = (props: QuoteProps): React.ReactElement => (
  <Content
    content={ContentAttribute.QUOTE}
    speech={{ location: SpeechLocation.LEFT }}
    {...props}
  />
);

export const QuoteRight = (props: QuoteProps): React.ReactElement => (
  <Content
    content={ContentAttribute.QUOTE}
    speech={{ location: SpeechLocation.RIGHT }}
    {...props}
  />
);
