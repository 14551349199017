import React from 'react';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { RoleProps } from 'cross-platform/utils/roleProps';

import { DiaryQuestion } from './components/DiaryQuestion';
import { SleepEfficiency } from './components/SleepEfficiency';

type DiaryCardsProps = RoleProps & {
  data: get_user_session_screen_data.SleepDiaryData;
};

const DiaryCards = (props: DiaryCardsProps): React.ReactElement => {
  return (
    <>
      {props.data.map((card, idx) => {
        if (card.type === 'question') {
          return <DiaryQuestion key={idx} data={card} />;
        }
        if (card.type === 'efficiency') {
          return <SleepEfficiency key={idx} data={card} />;
        }
        return null;
      })}
    </>
  );
};

export { DiaryCards };
