import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Question } from '@bighealth/types';

import {
  clearQuestionResponses,
  pushQuestionResponse,
} from 'state/question-response/actions';

export type ClearCallback = () => void;
export type RegisterCallback = (question: Question) => void;
export type UseQuestionReturn = {
  register: RegisterCallback;
  clear: ClearCallback;
};

const useQuestion = (): UseQuestionReturn => {
  const dispatch = useDispatch();

  // registerQuestionCallback
  const register: RegisterCallback = useCallback(
    question => {
      dispatch(pushQuestionResponse(question));
    },
    [dispatch]
  );

  // clearCallback
  const clear: ClearCallback = useCallback(() => {
    dispatch(clearQuestionResponses());
  }, [dispatch]);

  return { register, clear };
};

export { useQuestion };
