import * as React from 'react';
import styled from 'styled-components/native';

import { Row } from 'components/layout/Grid';
import { Circle } from 'components/sceneset-components/Shape';
import { Text } from 'components/sceneset-components/Text';
import Image from 'cross-platform/Image';
import {
  ButtonStates,
  getStylesForSleepDiaryResponseButtonStates,
} from 'lib/styles/inputStateStyles';
import {
  ScaleValueProps,
  ScaleYValueProps,
} from 'sleepio/components/screens/SessionsScreen/utils/scaler';

const DOMAIN = 'DiaryResponses';
const getButton = getStylesForSleepDiaryResponseButtonStates;

export const ResponseWrapper = styled.TouchableOpacity<ButtonStates>`
  display: flex;
  flex-direction: row;
  border: ${getButton('border')};
  background-color: ${getButton('backgroundColor')};
  padding: 8px;
  border-radius: 8px;
  margin: 6px;
  align-items: center;
  min-height: 42px;
  cursor: pointer;
`;
ResponseWrapper.displayName = `${DOMAIN}.ResponseWrapper`;

export const ResponsesRow = styled(Row)<{ loading: boolean }>`
  width: 100%;
  align-content: flex-end;
  justify-content: center;
  margin-top: 16px;
  padding-bottom: 8px;
  opacity: ${props => (props.loading ? 0.5 : 1.0)};
`;
ResponsesRow.displayName = `${DOMAIN}.ResponsesRow`;

export const ResponseImage = styled(Image)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

ResponseImage.displayName = `${DOMAIN}.ResponsesImage`;

type ResponseTextProps = React.ComponentProps<typeof Text> &
  ScaleValueProps &
  ScaleYValueProps;

// IDEA Combine X or Y
export const ResponseText = styled(Text)<ResponseTextProps>`
  font-size: 16px;
  color: ${getButton('color')};
`;
ResponseText.displayName = `${DOMAIN}.ResponseText`;

export const ResponseCircle = styled(Circle)`
  margin-right: 10px;
`;
ResponseCircle.displayName = `${DOMAIN}.ResponseCircle`;
