import { useCallback } from 'react';

import { useGetMediaScale } from './useGetMediaDimensions';
import { UseScaleToContextCallback } from './useScaleToScalingContext';

export const useScaleToMedia = (): UseScaleToContextCallback => {
  const mediaScale = useGetMediaScale();
  const callback: UseScaleToContextCallback = useCallback(
    dimensionToScale => {
      return dimensionToScale * mediaScale;
    },
    [mediaScale]
  );
  return callback;
};
