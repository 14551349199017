import { format } from 'date-fns';

import { SleepEfficiencyTrendPageDataDescResponse } from '@bighealth/types';

import {
  LineChartData,
  LineChartDatum,
} from 'components/Screens/ContentScreens/ProgressScreen/LineChart';

export function leftPad<D, P>(
  data: D[],
  padWith: P,
  requiredLength: number
): (D | P)[] {
  if (requiredLength <= data.length) {
    return data;
  }
  return [...Array(requiredLength - data.length).fill(padWith), ...data];
}

export function rightPad<D, P>(
  data: D[],
  padWith: P,
  requiredLength: number
): (D | P)[] {
  if (requiredLength <= data.length) {
    return data;
  }
  return [...data, ...Array(requiredLength - data.length).fill(padWith)];
}

export const getChartDataFromAPIData = (
  apiData: SleepEfficiencyTrendPageDataDescResponse
): LineChartData =>
  apiData.items
    .sort((prev, next) => +new Date(prev.date) - +new Date(next.date))
    .map(({ date, value }) => [+new Date(date), value]);

export const convertYToUnits = (y: number): string => y + '%';

export const convertXToUnits = (x: number | undefined): string =>
  typeof x === 'undefined' ? '—' : format(x, 'MMM d');

type Empty = [undefined, null];

export const getPaddedChartDataFromAPIData = (
  apiData?: SleepEfficiencyTrendPageDataDescResponse
): LineChartDatum[] | undefined => {
  if (typeof apiData === 'undefined') {
    return undefined;
  }
  const itemsPerPage = apiData?.items_per_page || 6;
  let data = getChartDataFromAPIData(apiData);
  if (!apiData?.has_prev) {
    data = leftPad<LineChartDatum, Empty>(
      data,
      [undefined, null],
      itemsPerPage
    );
  }
  if (!apiData?.has_next) {
    data = rightPad<LineChartDatum, Empty>(
      data,
      [undefined, null],
      itemsPerPage
    );
  }
  return data;
};
