/**
 * This is a fairly elaborate workaround for the following two issues
 * 1) window.fetch isn't defined in jest
 * 2) mockImplementation with type safety requires type casting.
 *
 * In all it's a bit fiddly!
 */

const network = (input: RequestInfo, init?: RequestInit): Promise<Response> =>
  fetch(input, init);

export default network;
