import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const book_next_session = async (
  args: book_next_session.Args,
  requestConfig?: AxiosRequestConfig
): Promise<book_next_session.Response> => {
  const request: book_next_session.Request = {
    service_name: 'SessionProgress',
    service_version: '1',
    service_method: 'book_next_session',
    args,
  };

  return await api(request, requestConfig);
};

book_next_session.queryKey = 'SessionProgress/book_next_session';

export declare namespace book_next_session {
  export type Args = {
    product_id: number;
    next_session_datetime: {
      $datetime: string;
    };
    user_timezone: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SessionProgress';
    service_version: '1';
    service_method: 'book_next_session';
    args: Args;
  }

  export type Result = {
    status_code: number;
    message: string;
  };

  export type Response = APIResponse<Result>;
}
