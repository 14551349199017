const fallbackFonts = 'Verdana, sans-serif';

export const FontFamilies = {
  Black: `GTWalsheim-Black, ${fallbackFonts}`,
  BlackItalic: `GTWalsheim-Black-Oblique, ${fallbackFonts}`,
  Medium: `GTWalsheim-Medium, ${fallbackFonts}`,
  MediumCondensed: `GTWalsheim-Condensed-Medium, ${fallbackFonts}`,
  MediumItalic: `GTWalsheim-Condensed-Medium-Oblique, ${fallbackFonts}`,
  Light: `GTWalsheim-Condensed-Light, ${fallbackFonts}`,
  LightItalic: `GTWalsheim-Condensed-Light-Oblique, ${fallbackFonts}`,
  Regular: `GTWalsheim-Regular, ${fallbackFonts}`,
  Bold: `GTWalsheim-Bold, ${fallbackFonts}`,
  Italic: `GTWalsheim-Regular-Oblique, ${fallbackFonts}`,
  BoldItalic: `GTWalsheim-Bold-Oblique, ${fallbackFonts}`,
} as const;
