import {
  ItemCarouselTools,
  ItemCarouselToolsOptions,
} from 'domain/carousel/ItemListCarousel/hooks/useItemCarouselTools/useItemCarouselTools';

import React from 'react';

export type ScrollToArg = Partial<{
  x: number;
  y: number;
  animated: boolean;
}>;

type Previous = { remeasure: number; update?: Partial<ScrollToArg> };
const useScrollTo = (
  tools: ItemCarouselTools,
  { createScrollTo }: ItemCarouselToolsOptions,
  remeasure: number
): { previous: Previous } | undefined => {
  const [previous, setPrevious] = React.useState<Previous>({ remeasure });
  const targetMeasurements = tools.targetItemApi.measurement;
  const targetNode = tools.targetItemApi.node;
  const scrollerNode = tools?.scrollerRef?.current;
  React.useEffect(() => {
    const update = createScrollTo(tools);
    if (
      typeof targetMeasurements === 'undefined' ||
      scrollerNode == null ||
      targetNode == null ||
      (JSON.stringify(update) === JSON.stringify(previous?.update) &&
        JSON.stringify(remeasure) === JSON.stringify(previous?.remeasure))
    ) {
      return;
    }
    scrollerNode.scrollTo(update);
    setPrevious({ remeasure, update });
  }, [
    targetMeasurements,
    createScrollTo,
    scrollerNode,
    targetNode,
    previous,
    tools,
    remeasure,
  ]);
  return { previous };
};

export { useScrollTo };
