import React, { ReactElement, useContext } from 'react';
import { Animated } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

import { ContentPageProps } from '@bighealth/types';

import Link from 'components/Link';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { iconsDictionary } from 'components/Screens/ContentScreens/components/iconsDictionary'; // Fixes jest module resolution issue
import { Text } from 'components/Text';
import { useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { usePulsingOpacityAnimation } from 'lib/animations';

import { useGetFullContentScreensPath, useGetNavDimensions } from '../hooks';

const NavItemSkeletonStatic = styled(Animated.View)`
  overflow: hidden;
  background-color: #ddefff;
`;

export const NavItemSkeletonAnimated = (): ReactElement => {
  const fadeAnimation = usePulsingOpacityAnimation();
  const styles = useGetDynamicContentStyles();
  return (
    <NavItemSkeletonStatic
      {...roles('NavItemSkeletonAnimated')}
      style={{
        opacity: fadeAnimation,
        paddingLeft: styles.navItemHorizontalPadding,
        paddingRight: styles.navItemHorizontalPadding,
        paddingTop: styles.navItemVerticalPadding,
        paddingBottom: styles.navItemVerticalPadding,
        borderRadius: styles.navItemBorderRadius,
        marginBottom: styles.navItemMarginBottom,
        minHeight: styles.navItemMinHeight,
      }}
    />
  );
};

const LinkContainerStatic = styled(Link)<{ testID?: string }>`
  display: flex;
  overflow: hidden;
  width: 100%;
`;

const LinkContentContainerStatic = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TextContainer = styled.View`
  flex-direction: column;
  margin-right: auto;
`;

const IconContainer = styled.View``;

export const NavItem = ({
  title,
  subtitle,
  slug,
  icon,
}: ContentPageProps): ReactElement => {
  const { page } = useParams<{ page?: string }>();
  const getScheduleScreensPath = useGetFullContentScreensPath();
  const isActive = page === slug;
  const styles = useGetDynamicContentStyles();
  const { navWidth } = useGetNavDimensions();
  const showIcon = navWidth > 250; // Arbitrary eyeballing from point where icon breaks layout
  const IconComponent =
    typeof icon === 'undefined'
      ? null
      : iconsDictionary[icon as keyof typeof iconsDictionary] || null;
  const path = getScheduleScreensPath(slug);
  const theme = useContext(ThemeContext);
  return (
    <LinkContainerStatic
      {...roles(`NavItemLink-${path}`)}
      style={{
        marginBottom: styles.navItemMarginBottom,
        borderRadius: styles.navItemBorderRadius,
        backgroundColor: isActive
          ? 'rgba(221,239,255, 1)'
          : 'rgba(221,239,255, 0.4)',
      }}
      to={path}
      title={title || ''}
    >
      <LinkContentContainerStatic
        style={{
          paddingLeft: styles.navItemHorizontalPadding,
          paddingRight: styles.navItemHorizontalPadding,
          paddingTop: styles.navItemVerticalPadding,
          paddingBottom: styles.navItemVerticalPadding,
        }}
      >
        <TextContainer>
          <Text
            style={{
              fontWeight: isActive ? 'bold' : 'normal',
              fontSize: styles.navItemTitleFontSize,
              color: theme.color.overlay.primary,
            }}
          >
            {title}
          </Text>
          {subtitle ? (
            <Text
              style={{
                fontSize: styles.navItemSubtitleFontSize,
                color: '#001948',
                marginTop: styles.navItemTitleMarginBottom,
              }}
            >
              {subtitle}
            </Text>
          ) : null}
        </TextContainer>
        {IconComponent && showIcon ? (
          <IconContainer style={{}}>
            <IconComponent {...roles(`NavItemIcon-${icon}`)} />
          </IconContainer>
        ) : null}
      </LinkContentContainerStatic>
    </LinkContainerStatic>
  );
};
