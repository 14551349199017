import { Measurement } from 'domain/carousel/ItemListCarousel/hooks/useItemCarouselTools/types';

import * as React from 'react';
import { View } from 'react-native';

export type ViewNodeApi = {
  node: View | undefined;
  setNode: React.Dispatch<React.SetStateAction<View | undefined>>;
  measurement: Measurement;
  setMeasurement: React.Dispatch<React.SetStateAction<Measurement>>;
};
/**
 * IMPORTANT
 * Using `View`, but could be changed to all that support instance method `measure()`
 * Critically, this does NOT include `ScrollView`, which would `NativeMethodsMixin`,
 * which does not work with react-native-web without extra config (ew!)
 *
 * @returns
 */
const useViewApi = (options: { remeasure: number }): ViewNodeApi => {
  const [node, setNode] = React.useState<View | undefined>(undefined);
  const [measurement, setMeasurement] = React.useState<Measurement>({ x: 0 });

  React.useEffect(() => {
    if (typeof measurement !== 'undefined' && typeof node !== 'undefined') {
      node.measure((x, y, width, height, pageX, pageY) => {
        const measured = { x, y, width, height, pageX, pageY };
        if (JSON.stringify(measured) !== JSON.stringify(measurement)) {
          setMeasurement(measured);
        }
      });
    }
  }, [measurement, node, options.remeasure]);

  return {
    node,
    setNode,
    measurement,
    setMeasurement,
  };
};

export { useViewApi };
