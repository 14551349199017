import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_diary_daily_detailed_info = async (
  args: get_diary_daily_detailed_info.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_diary_daily_detailed_info.Response> => {
  const request: get_diary_daily_detailed_info.Request = {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'get_diary_daily_detailed_info',
    args,
  };

  return await api(request, requestConfig);
};

get_diary_daily_detailed_info.queryKey =
  'SleepDiary/get_diary_daily_detailed_info';

export declare namespace get_diary_daily_detailed_info {
  export type Args = {
    start_date: string;
    number_of_days: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'get_diary_daily_detailed_info';
    args: Args;
  }

  export type Result = {
    [key: string]: {
      total_time_in_bed_minutes: number;
      total_time_asleep_minutes: number;
      sleep_quality_percentage: number;
      sleep_efficiency: number;
    };
  };

  export type Response = APIResponse<Result>;
}
