export enum DebugActions {
  SET_SCREEN_REFERENCE = 'debug/SET_SCREEN_REFERENCE',
  CLEAR_SCREEN_REFERENCE = 'debug/CLEAR_SCREEN_REFERENCE',
}

export type DebugAction = {
  type: DebugActions;
  payload?: string;
};

export const setScreenReference = (screenReference: string): DebugAction => ({
  type: DebugActions.SET_SCREEN_REFERENCE,
  payload: screenReference,
});

export const clearScreenReference = (): DebugAction => ({
  type: DebugActions.CLEAR_SCREEN_REFERENCE,
});
