import { create_responses_for_questions } from '@bighealth/api/Response/v2';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';

export const queryClientSubmitBulkResponses = async (
  args: create_responses_for_questions.Args
): Promise<create_responses_for_questions.Response> => {
  return await getQueryClient().fetchQuery(
    create_responses_for_questions.queryKey,
    () => create_responses_for_questions(args)
  );
};
