import { submit_single_diary_form } from '@bighealth/api/SleepDiary/v1';

import { SleepDiaryFormResponses } from '../fetchSubmitDataAsync';

export const isSubmitSingleDiaryFormResult = (
  payload?: SleepDiaryFormResponses
): payload is submit_single_diary_form.Response => {
  if (
    typeof payload !== 'undefined' &&
    'sleep_diary' in payload.result &&
    'reinforcement_data' in payload.result
  ) {
    return true;
  }
  return false;
};
