import { SleepWindowTypes } from '@bighealth/types/src/scene-components/SleepWindowTypes';

export const mockFormPresentationProps: SleepWindowTypes['Props'] = {
  fields: {
    from_time: {
      label: {
        text: 'Go to bed after:',
        color: '#666666',
      },
      input: {
        initialValue: '23:00:00',
        color: '#00506B',
      },
    },
    to_time: {
      label: {
        text: 'Get out of bed before:',
        color: '#666666',
      },
      input: {
        initialValue: '05:15:00',
        color: '#1a80a2',
      },
    },
  },
  submit_button: {
    text: 'Save and Continue',
    action: {
      type: 'sleep-window/SUBMIT',
      payload: {
        service_name: 'SleepWindow',
        service_version: '1',
        service_method: 'create_with_times',
        args: {
          // fe to populate
        },
      },
    },
  },
  on_submit_success: {
    type: 'submit',
  },
};
