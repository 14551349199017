// IDEA curry and memoize
// WARNING to myself(Ash) moving to hook and making stateful is risky
// WHY AVOID? state updates fighting, duplicated state (hard to handle initialValues)
import { DropdownItem } from 'components/generic-question/Dropdown';

import {
  getDesiredSelectionForToggle,
  getValidQuizResponseSelections,
  limitSelectionsLength,
  updateIsSelectedOnAllItems,
} from './helpers/queueHelpers';

/**
 * Creates a copy of the given object, marked as selected
 * @param item The drop down item that is to be marked selected
 */
const markItemSelected = (item: DropdownItem): DropdownItem => {
  return {
    ...item,
    isSelected: true,
  };
};

/**
 * Marks all items that are in both the allItems array and the desiredSelections array as selected
 * and limits the selections length.
 * @param maxSelected The maximum number of items that can be marked as selected
 * @param allItems The full list of options for the given response
 * @param desiredSelections The array of items within allItems that we want to be marked as selected
 */
export const setSelection = (
  maxSelected: number,
  allItems: DropdownItem[],
  desiredSelections: DropdownItem[] = []
): DropdownItem[] => {
  const selected = limitSelectionsLength(maxSelected, desiredSelections);
  return updateIsSelectedOnAllItems(allItems, selected);
};

/**
 * Marks itemToAdd as selected within the allItems array. Does nothing with preexisting selection
 * except to limit the selection length.
 * @param maxSelected The maximum number of items that can be marked as selected
 * @param allItems The full list of options for the given response
 * @param itemToAdd The item to be marked as selected
 */
export const addItem = (
  maxSelected: number,
  allItems: DropdownItem[],
  itemToAdd?: DropdownItem
): DropdownItem[] => {
  const oldSelected = allItems.filter(item => !!item.isSelected);
  const itemNotSelected = !oldSelected.find(
    el => !itemToAdd || el.id === itemToAdd.id
  );
  if (
    typeof itemToAdd !== 'undefined' &&
    (itemNotSelected || maxSelected > oldSelected.length)
  ) {
    oldSelected.push(itemToAdd);
  }

  const selected = limitSelectionsLength(maxSelected, oldSelected);
  return updateIsSelectedOnAllItems(allItems, selected);
};

/**
 * Toggles the isSelected property of the toggledItem within all, applying quiz responses
 * and limiting the selection length.
 * @param maxSelected The maximum number of items that can be marked isSelected
 * @param singleSelectIds The IDs of the options that act like radio buttons instead of checkboxes
 * @param allItems The full list of options for the given response
 * @param oldSelectedItems The current selection within all
 * @param toggledItem The item that was just clicked/pressed
 * @param quizCorrectResponses If applicable, the quiz assessment's correct responses
 * @param quizIncorrectResponses If applicable, the quiz assessment's incorrect responses
 */
export const toggleItem = (
  maxSelected: number,
  singleSelectIds: string[],
  allItems: DropdownItem[],
  oldSelectedItems: DropdownItem[],
  toggledItem?: DropdownItem,
  quizCorrectResponses: DropdownItem[] = [],
  quizIncorrectResponses: DropdownItem[] = []
): { all: DropdownItem[]; selectedQueue: DropdownItem[] } => {
  if (typeof toggledItem === 'undefined') {
    return {
      all: allItems,
      selectedQueue: limitSelectionsLength(maxSelected, oldSelectedItems).map(
        markItemSelected
      ),
    };
  }

  const desiredSelections = getDesiredSelectionForToggle(
    oldSelectedItems,
    toggledItem,
    singleSelectIds
  );

  const quizResponseSelections = getValidQuizResponseSelections(
    desiredSelections,
    quizCorrectResponses,
    quizIncorrectResponses
  );

  const selections = limitSelectionsLength(
    maxSelected,
    quizResponseSelections
  ).map(markItemSelected);

  return {
    selectedQueue: selections,
    all: updateIsSelectedOnAllItems(allItems, selections),
  };
};
