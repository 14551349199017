import styled from 'styled-components/native';

export const Button = styled.TouchableOpacity`
  width: 9%;
  align-items: center;
  justify-content: center;
`;

export const ButtonView = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`;
