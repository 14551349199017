/**
 * Flattens the types of styles React Native can receive (namely: undefined, object, array of object)
 * into a single object
 * @param styles
 */
import { StyleSheet } from 'react-native';
const flatten = StyleSheet.flatten;

// Border, background etc can go on the container for now
const INPUT_STYLES = [
  'padding',
  'paddingLeft',
  'paddingRight',
  'paddingBottom',
  'paddingTop',
  'paddingHorizontal',
  'paddingVertical',
  'height',
];

/**
 * Splits input styles into container and inputbox styles to match native to non-native formatting
 * The way native and web inputs receive styles is different. So we'll make them behave
 * by splitting some styles onto the input component and some onto the container component
 * @param styles
 */
export function splitStylesToInputAndContainer<T>(
  styles?: T
): { containerStyles: T; inputStyles: T } {
  if (typeof styles === 'undefined') {
    return { containerStyles: {} as T, inputStyles: {} as T };
  }
  const containerStyles = {} as T;
  const inputStyles = {} as T;
  let key: string;
  const flattenedStyles = flatten(styles);
  for (key in flattenedStyles) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = (flattenedStyles as any)[key];
    if (typeof value !== 'undefined') {
      if (INPUT_STYLES.includes(key as string)) {
        // "as string" since our INPUT_STYLES array is always string[]
        inputStyles[key as keyof T] = value;
      } else {
        containerStyles[key as keyof T] = value;
      }
    }
  }
  return {
    containerStyles: containerStyles as T,
    inputStyles: inputStyles as T,
  };
}

/* Because the editor doesn't support boolean
 * it only supports enums.
 */
export function isEditorBooleanTrue(
  value: 'true' | 'false' | boolean | undefined
): boolean {
  if (typeof value === 'boolean') {
    return !!value;
  }

  return value === 'true';
}
