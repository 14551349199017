import styled from 'styled-components/native';

import { DOMAN } from './constants';

export const PressButton = styled.TouchableOpacity`
  padding: 8px;
  margin: 8px;
  cursor: pointer;
`;
PressButton.displayName = `${DOMAN}.PressButton`;
