import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { find_with_reference } from '@bighealth/api/Product/v2';

import { useSafeParams } from 'components/Routes/useSafeParams';
import { ThrobberBehaviorMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { SECOND } from 'lib/durations';

export type useQueryProductReturnType = UseQueryResult<
  find_with_reference.Response,
  APIErrorResponse
>;

export const useQueryProduct = (options?: {
  meta: ThrobberBehaviorMeta | undefined;
}): useQueryProductReturnType => {
  const { productReference } = useSafeParams();
  return useQuery({
    queryKey: find_with_reference.queryKey,
    queryFn: () =>
      find_with_reference(
        {
          reference: productReference,
        },
        { timeout: 10 * SECOND }
      ),
    cacheTime: Infinity,
    meta: options?.meta,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
};
