import React, { ReactElement, useContext } from 'react';
import { ScrollView, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

import { useBackHandler } from 'components/backHandler/useBackHandler';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { Text as SceneText } from 'components/sceneset-components/Text';
import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { Header } from 'daylight/components/Header/Header';
import { GlobalNavigationState } from 'daylight/components/higherOrderComponents';
import { ProgressLine } from 'daylight/components/icons/ProgressLine';
import { useTransformContentStyle } from 'daylight/hooks';
import { useQueryDaylightProgressData } from 'lib/api/reactQueryHelpers';

import { ErrorScreen } from '../ErrorScreen/ErrorScreen';
import { GateScreen } from '../GateScreen/GateScreen';

import { Ghost } from './Ghost/Ghost';
import { GoalView } from './GoalView/GoalView';
import {
  PADDING_PROGRESS_CARD,
  ProgressCard,
} from './ProgressCard/ProgressCard';
import { ProgressItem } from './ProgressItem/ProgressItem';
import { TimeToCheckinView } from './TimeToCheckinView/TimeToCheckinView';

const ContainerCompletion = styled.View``;

const Shadow = styled.View``;

export const ProgressScreen = ({
  setShowLoadingOverlay,
}: GlobalNavigationState): ReactElement => {
  const screenData = useQueryDaylightProgressData();

  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();

  const theme = useContext(ThemeContext);
  const { productReference } = useSafeParams();
  useBackHandler(productReference);
  const title_screen = 'My Progress';

  setShowLoadingOverlay(
    screenData.status === 'idle' || screenData.status === 'loading'
  );

  if (screenData.status === 'error') {
    return <ErrorScreen message="Could not fetch progress data" />;
  }

  if (typeof screenData.data?.result.warning_message === 'string') {
    return (
      <GateScreen
        testID={'progressGateScreen'}
        headerTitle={title_screen}
        message={screenData.data.result.warning_message}
        action={screenData.data.result.action}
      />
    );
  }

  // TODO: use themes for these values
  const bigTextSize = transformStyle(60);
  const textSize = transformStyle(18);
  const padding = textSize / 2;

  return (
    <View
      {...roles('progressScreen')}
      style={{
        backgroundColor: theme.color.deselectedGrey,
        flex: 1,
      }}
    >
      <Header
        text={title_screen}
        headerSize={transformStyle(18)}
        closeIconSize={transformStyle(16)}
      />

      {screenData.status === 'success' ? (
        <ScrollView>
          <View
            style={{
              padding: transformStyle(2 * padding),
            }}
          >
            {screenData.data.result.goal_headline ? (
              <View>
                <GoalView
                  upperText={screenData.data.result.goal_headline.upper_text}
                  lowerText={screenData.data.result.goal_headline.lower_text}
                  boldText={screenData.data.result.goal_headline.bold_text}
                />

                <View
                  style={{
                    paddingVertical: transformVerticalStyle(padding),
                  }}
                />
              </View>
            ) : null}

            {screenData.data.result.checkin_completion.upper_text &&
            screenData.data.result.exercise_completion.upper_text ? (
              <View>
                <ContainerCompletion
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <ProgressCard
                    upperComponent={
                      <SceneText
                        {...screenData.data.result.checkin_completion
                          .upper_text}
                        style={{
                          ...screenData.data.result.checkin_completion
                            .upper_text?.style,
                          // TODO: use themes and remove this overwriting here
                          // because the backend will specify the enum
                          fontSize: transformStyle(bigTextSize),
                        }}
                      />
                    }
                    lowerText={
                      screenData.data.result.checkin_completion.lower_text
                    }
                    width={'49%'}
                  />
                  <View
                    style={{
                      paddingHorizontal: '2%',
                    }}
                  />
                  <ProgressCard
                    upperComponent={
                      <SceneText
                        {...screenData.data.result.exercise_completion
                          .upper_text}
                        style={{
                          ...screenData.data.result.exercise_completion
                            .upper_text?.style,
                          fontSize: transformStyle(bigTextSize),
                        }}
                      />
                    }
                    lowerText={
                      screenData.data.result.exercise_completion.lower_text
                    }
                    width={'49%'}
                  />
                </ContainerCompletion>

                <View
                  style={{
                    paddingVertical: transformVerticalStyle(padding),
                  }}
                />
              </View>
            ) : null}

            <TimeToCheckinView
              lowerText={screenData.data.result.time_to_checkin.lower_text}
              boldText={screenData.data.result.time_to_checkin.bold_text}
            />

            <View
              style={{
                paddingVertical: transformVerticalStyle(padding),
              }}
            />

            <ProgressCard>
              <Text
                style={{
                  fontSize: textSize,
                  color: theme.color.text.primary,
                }}
              >
                {'Progress'}
              </Text>

              <View
                style={{
                  position: 'absolute',
                  left: transformStyle(PADDING_PROGRESS_CARD + 3),
                  height: '100%',
                  top: transformVerticalStyle(PADDING_PROGRESS_CARD + 64),
                }}
              >
                <ProgressLine
                  height={
                    (screenData.data.result.progress_exercises.length - 1) *
                    transformStyle(48)
                  }
                  color={'#E0E0E0'}
                />
              </View>

              <View
                style={{
                  marginTop: transformStyle(7),
                }}
              >
                {screenData.data.result.progress_exercises.map(
                  (exercise, index) => (
                    <View
                      key={index}
                      style={{
                        flexDirection: 'row',
                        height: transformStyle(48),
                        paddingVertical: transformStyle(12),
                      }}
                    >
                      <ProgressItem
                        exercise={exercise}
                        colorDot={'#888888'}
                        sizeDot={transformStyle(20)}
                        colorText={theme.color.text.primary}
                        sizeText={textSize}
                      />
                    </View>
                  )
                )}
              </View>
            </ProgressCard>
          </View>
        </ScrollView>
      ) : (
        <Ghost />
      )}
      <Shadow
        style={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
          backgroundColor: 'white',
          height: 1,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: -2,
          },
          shadowOpacity: 0.9,
          shadowRadius: 8,
          elevation: 20,
        }}
      />
    </View>
  );
};
