import React from 'react';

import { FlexBox, FlexBoxProps } from '../../FlexBox';

type Props = Pick<
  FlexBoxProps,
  | 'flexGrow'
  | 'flexShrink'
  | 'flexBasis'
  | 'children'
  | 'style'
  | 'justifyContent'
  | 'flexWrap'
  | 'onLayout'
>;
const Row = ({ ...props }: Props): React.ReactElement => (
  <FlexBox flexDirection="row" width="100%" {...{ flexGrow: 1, ...props }} />
);

export { Row };
