import { useCallback, useRef, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { mediaPlayerOrchestrator } from 'lib/player/media/MediaPlayerOrchestrator';
import { toggleVisibilityOrchestrator } from 'lib/player/media/ToggleVisibilityOrchestrator';
import { qaLogFactory } from 'lib/showQAMenu/qaLogFactory';
import { setPlayerState } from 'state/player/actions';
import { PlaybackState } from 'state/player/reducer';
import { getPlayerState } from 'state/player/selectors';

const qaqaMediaPlayerLog = qaLogFactory('MediaPlayer log');

export type AppStateChangeCallbacks = {
  appState: AppStateStatus;
  handleAppFocus: () => void;
  handleAppBlur: () => void;
  handleAppChange: (nextAppState: AppStateStatus) => void;
};

export const useAppStateHandlers = (): AppStateChangeCallbacks => {
  const dispatch = useDispatch();
  const playerState = useSelector(getPlayerState);
  const [appState, setAppState] = useState(AppState.currentState);

  const appStateRef = useRef(appState);
  const playerStateRef = useRef(playerState);

  appStateRef.current = appState;
  playerStateRef.current = playerState;

  const releasePlayLock = () => {
    qaqaMediaPlayerLog(`app state change: releasePlayLock`);

    mediaPlayerOrchestrator.releasePlayLock();
    toggleVisibilityOrchestrator.releasePlayLock();
  };

  const handleAppFocus = useCallback(() => {
    releasePlayLock();

    // avoiding unnecessary dispatches
    if (playerStateRef.current !== PlaybackState.PLAYING) {
      dispatch(setPlayerState(PlaybackState.PLAYING));
      qaqaMediaPlayerLog(`app state change: playing w/dispatch`);
    } else {
      qaqaMediaPlayerLog(`app state change: playing`);
    }

    mediaPlayerOrchestrator.play();
    toggleVisibilityOrchestrator.play();
  }, [dispatch]);

  const handleAppBlur = useCallback(() => {
    mediaPlayerOrchestrator.pause();
    toggleVisibilityOrchestrator.pause();

    // avoiding unnecessary dispatches
    if (playerStateRef.current !== PlaybackState.PAUSED) {
      dispatch(setPlayerState(PlaybackState.PAUSED));
      qaqaMediaPlayerLog(`app state change: pausing w/dispatch`);
    } else {
      qaqaMediaPlayerLog(`app state change: pausing`);
    }

    // setting locks for scene transitions
    mediaPlayerOrchestrator.setPlayLock();
    toggleVisibilityOrchestrator.setPlayLock();
  }, [dispatch]);

  const handleAppChange = useCallback(
    (nextAppState: AppStateStatus) => {
      qaqaMediaPlayerLog(
        `app state change: ${appStateRef.current} >  ${nextAppState}`
      );
      if (
        appStateRef.current === 'active' &&
        (nextAppState === 'inactive' || nextAppState === 'background')
      ) {
        handleAppBlur();
      } else if (
        (appStateRef.current === 'inactive' ||
          appStateRef.current === 'background') &&
        nextAppState === 'active'
      ) {
        handleAppFocus();
      } else {
        releasePlayLock();
      }

      setAppState(nextAppState);
    },
    [handleAppBlur, handleAppFocus]
  );

  return {
    appState,
    handleAppChange,
    handleAppBlur,
    handleAppFocus,
  };
};

export default useAppStateHandlers;
