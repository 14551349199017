import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const subscribe_to_emails = async (
  args: subscribe_to_emails.Args,
  requestConfig?: AxiosRequestConfig
): Promise<subscribe_to_emails.Response> => {
  const request: subscribe_to_emails.Request = {
    service_name: 'UserSleepioMetadata',
    service_version: '1',
    service_method: 'subscribe_to_emails',
    args,
  };

  return await api(request, requestConfig);
};

subscribe_to_emails.queryKey = 'UserSleepioMetadata/subscribe_to_emails';

export declare namespace subscribe_to_emails {
  export type Args = {
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserSleepioMetadata';
    service_version: '1';
    service_method: 'subscribe_to_emails';
    args: Args;
  }

  export type Result = {
    message: string;
  };

  export type Response = APIResponse<Result>;
}
