import { DEFAULT_THEME, Theme } from 'config/getThemeForProducts';
import { ModalInputSchemesType } from 'config/getThemeForProducts/utils/createTheme/modalSchemesTypes';

import { useTransformStylesToContext } from './useTransformStylesToContext';

export const useGetDynamicInputStyles = (
  theme: Theme = DEFAULT_THEME
): ModalInputSchemesType => {
  const transformStyles = useTransformStylesToContext(theme);
  const inputStyles = theme.modal.input;
  return transformStyles(inputStyles) as ModalInputSchemesType;
};
