import {
  FocusEventHandler,
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ButtonProps } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import {
  ButtonColorSchemes,
  buttonColorSchemes,
  ButtonColorSchemesType,
} from './buttonColorSchemes';
import { ButtonInteractionColors } from './types';

export type UseButtonInteractionStatesResponse = {
  style: ButtonInteractionColors;
  nativeEvents: {
    onPressIn?: ButtonProps['onPress'];
    onPressOut?: ButtonProps['onPress'];
  };
  webEvents: {
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
    onMouseDown?: MouseEventHandler;
    onMouseUp?: MouseEventHandler;
  };
};

enum EventType {
  Hover = 'HOVER',
  Focus = 'FOCUS',
  PressedOrClicked = 'PRESSED_OR_CLICKED',
}

type ButtonState = {
  [EventType.Hover]: boolean;
  [EventType.Focus]: boolean;
  [EventType.PressedOrClicked]: boolean;
};

type StateChange = {
  type: EventType;
  isActive: boolean;
};

const initialState: ButtonState = {
  [EventType.Hover]: false,
  [EventType.Focus]: false,
  [EventType.PressedOrClicked]: false,
};

// Button is triggering focus after button click
const isFocusAfterClick = (newState: StateChange, buttonState: ButtonState) =>
  newState.type === EventType.Focus &&
  newState.isActive &&
  buttonState[EventType.PressedOrClicked];

export const useButtonInteractionStates = ({
  isDisabled,
  onPressIn,
  onPressOut,
  onMouseEnter,
  onMouseLeave,
  colorScheme = ButtonColorSchemes.Standard,
  onFocus,
  onBlur,
  onMouseDown,
  onMouseUp,
}: {
  isDisabled?: boolean;
  colorScheme?: keyof ButtonColorSchemesType;
  onPressIn?: ButtonProps['onPress'];
  onPressOut?: ButtonProps['onPress'];
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  onMouseDown?: MouseEventHandler;
  onMouseUp?: MouseEventHandler;
}): UseButtonInteractionStatesResponse => {
  const [buttonState, setButtonState] = useState(initialState);

  const handleEvent = useCallback(
    <T,>(eventHandler: T, newStates: StateChange[]) => (e: unknown) => {
      if (!isDisabled) {
        const newButtonState: ButtonState = { ...buttonState };
        for (const newState of newStates) {
          if (!isFocusAfterClick(newState, buttonState)) {
            newButtonState[newState.type] = newState.isActive;
          }
        }

        setButtonState(newButtonState);
      }

      if (typeof eventHandler === 'function') {
        eventHandler(e);
      }
    },
    [buttonState, isDisabled]
  );
  const productTheme = useContext(ThemeContext);

  let buttonStyles = buttonColorSchemes;
  if (typeof productTheme !== 'undefined' && productTheme.buttons) {
    buttonStyles = productTheme.buttons;
  }

  let outColors: ButtonInteractionColors;
  if (isDisabled) {
    outColors = buttonStyles[colorScheme].disabled;
  } else if (buttonState[EventType.PressedOrClicked]) {
    outColors = buttonStyles[colorScheme].pressed;
  } else if (buttonState[EventType.Hover]) {
    outColors = buttonStyles[colorScheme].hover;
  } else if (buttonState[EventType.Focus]) {
    outColors = buttonStyles[colorScheme].hover;
  } else {
    outColors = buttonStyles[colorScheme].default;
  }

  return useMemo(
    () => ({
      nativeEvents: {
        onPressIn: e =>
          handleEvent(onPressIn, [
            { type: EventType.PressedOrClicked, isActive: true },
          ])(e),
        onPressOut: e =>
          handleEvent(onPressOut, [
            { type: EventType.PressedOrClicked, isActive: false },
          ])(e),
      },
      webEvents: {
        onFocus: e =>
          handleEvent(onFocus, [{ type: EventType.Focus, isActive: true }])(e),
        onBlur: e =>
          handleEvent(onBlur, [{ type: EventType.Focus, isActive: false }])(e),
        onMouseEnter: e =>
          handleEvent(onMouseEnter, [
            { type: EventType.Hover, isActive: true },
          ])(e),
        onMouseLeave: e =>
          handleEvent(onMouseLeave, [
            { type: EventType.PressedOrClicked, isActive: false },
            { type: EventType.Hover, isActive: false },
          ])(e),
        onMouseDown: e =>
          handleEvent(onMouseDown, [
            { type: EventType.PressedOrClicked, isActive: true },
            { type: EventType.Hover, isActive: true },
          ])(e),
        onMouseUp: e =>
          handleEvent(onMouseUp, [
            { type: EventType.PressedOrClicked, isActive: false },
            { type: EventType.Hover, isActive: true },
          ])(e),
      },
      style: outColors,
    }),
    [
      handleEvent,
      onBlur,
      onFocus,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onPressIn,
      onPressOut,
      outColors,
    ]
  );
};
