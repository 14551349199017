import { AxiosError } from 'axios';

import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_OTP_ERROR_MESSAGE,
  INVALID_OTP_ERROR_MESSAGE,
} from 'lib/strings';

import { LOGIN_ERROR } from '../constants';

export const getErrorString = (
  error: Error | AxiosError | undefined
): string => {
  let errorString: string | undefined;
  const errorMessages = [
    LOGIN_ERROR,
    GENERAL_OTP_ERROR_MESSAGE,
    INVALID_OTP_ERROR_MESSAGE,
  ];

  if (error && errorMessages.includes(error?.message)) {
    return error.message;
  }
  // TODO remove unneeded casting
  // WHEN we have time to burn
  const maybeAxiosError: AxiosError = (error as unknown) as AxiosError;
  if (maybeAxiosError?.response?.status === 401) {
    errorString = LOGIN_ERROR;
  } else {
    // IDEA show error to developers
    // hide details from non developers
    errorString = GENERAL_ERROR_MESSAGE;
  }
  return errorString || GENERAL_ERROR_MESSAGE;
};
