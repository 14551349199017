/* istanbul ignore file */

// IDEA: Test snapshots, but we seem to get by without it
import React, { ReactElement, useState } from 'react';
import { Button, Text } from 'react-native';
import { ScrollView, StyleProp } from 'react-native';
import { RouteChildrenProps } from 'react-router-dom';
import { Formik } from 'formik';

import { ProductReferences } from 'common/constants/enums';
import { Content } from 'components/layout/Content';
import { Grid } from 'components/layout/Grid';
import { Col } from 'components/layout/Grid/Col';
import { Row } from 'components/layout/Grid/Row';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { Link, Route, Switch } from 'cross-platform/react-router';
import { noOpFunction } from 'lib/noOpFunction';
import { stories } from 'lib/story-books';

import 'components/Text/Text/index.story';
import 'components/Text/HelperText/index.story';
import 'components/Text/ErrorText/index.story';
import 'components/Heading/index.story';
import 'components/forms/Label/index.story';
import 'components/layout/Content/index.story';
import 'components/layout/FlexBox/index.story';
import 'components/layout/Grid/index.story';
import 'components/generic-question/SingleSelectQuestion/index.story';
import 'components/ControlButton/index.story';
import 'components/forms/index.story';
import 'components/forms/ResponseOptions/index.story';
import 'components/SleepDiaryForm/components/NetworkedFlowingForm/index.story';
import 'components/SleepDiaryForm/components/Form/stories/17Sep2020.story';
import 'components/SleepDiaryForm/components/Form/stories/hidden.story';
import 'components/SleepDiaryForm/components/Form/stories/highlight.story';
import 'components/SleepDiaryForm/components/Form/stories/WithDefaults.story';
import 'components/SleepDiaryForm/components/Form/stories/initialValueAndOptions.story';
import 'components/SleepDiaryForm/components/FlowingForm/stories/warningsAndHighlights.story';
import 'components/SleepDiaryForm/index.story';
import 'components/PopupModal/index.story';
import 'components/ProgressBar/ProgressBar.story';
import 'components/sceneset-components/SetSleepWindow/stories/FormState.story';
import 'components/generic-question/index.story';
import 'components/generic-question/DateTimePickers/stories/DateTimePicker.story';
import 'components/generic-question/DateTimePickers/stories/DatePicker.story';
import 'components/generic-question/DateTimePickers/stories/TimePicker.story';
import 'components/generic-question/Dropdown/index.story';
import 'components/generic-question/DropdownDurationPicker/index.story';
import 'components/generic-question/DropdownTimePicker/index.story';
import 'components/SleepDiaryWeek/stories/Presentation.story';
import 'components/SleepDiaryWeek/stories/State.story';
import 'components/sceneset-components/SleepDiaryForm/stories/index.story';
import 'components/SleepDiaryWeek/stories/Network.story';
import 'components/sceneset-components/ScheduleNextSession/index.story';
import './perf/Style.story';
import 'sleepio/components/screens/SessionsScreen/components/Presentation/index.story';
import 'components/Screens/ContentScreens/components/iconsDictionary.story';
import 'lib/error/stories/index.story';
import 'lib/error/stories/DefaultErrorFallback.story';
import 'lib/error/stories/onRecover.story';
import 'lib/error/stories/fallbackRenderWithWrapper.story';
import 'components/SceneSetView/components/ErrorModal/Presentational/ErrorModal.story';
import 'components/SceneSetView/components/ErrorModal/Contextualized/stories/index.story';
import 'lib/error/stories/app-demo/index.story';

// IDEA: Make generic for general-use
type DebugStyleType = {
  backgroundColor: string;
};
const bg = ([
  backgroundColor,
]: TemplateStringsArray): StyleProp<DebugStyleType> => ({
  backgroundColor,
});

const StoryBook = ({
  match,
}: RouteChildrenProps<{ story: string }>): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  const storiesSorted = Object.entries(stories).sort();
  const url = match ? match.url : '';
  const story = match?.params?.story
    ? match.params.story.replace(/^\//, '') // remove leading slash
    : '';
  const baseUrl = url
    .split(story)
    .join('')
    .replace(/\/$/, ''); // remove trailing slash

  return (
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <ScrollView style={{ width: '100%' }}>
        <Formik initialValues={[]} onSubmit={noOpFunction}>
          <Grid>
            <Row>
              <Content width="100%">
                <Text>{`Storybook: ${story}`}</Text>
                <Button
                  title={isExpanded ? 'shrink' : 'expand'}
                  onPress={() => {
                    setIsExpanded(!isExpanded);
                  }}
                />
              </Content>
            </Row>
            <Row style={bg`#EEE`}>
              <Col flexGrow={1} width="100%">
                {isExpanded ? (
                  <Content width="100%">
                    {storiesSorted.map(([name]) => (
                      <Link to={`${baseUrl}/${name}`} key={name}>
                        <Text
                          style={{
                            color: name === story ? 'white' : 'black',
                            backgroundColor:
                              name === story ? 'black' : 'transparent',
                          }}
                        >
                          {name}
                        </Text>
                      </Link>
                    ))}
                  </Content>
                ) : null}
                {isExpanded ? (
                  <Content width="100%">
                    <Button
                      title={isExpanded ? 'shrink' : 'expand'}
                      onPress={() => {
                        setIsExpanded(!isExpanded);
                      }}
                    />
                  </Content>
                ) : null}
              </Col>
              <Col width="100%">
                <Switch>
                  {storiesSorted.map(([name, renderFn]) => (
                    <Route path={`${baseUrl}/${name}`} exact key={name}>
                      {renderFn()}
                    </Route>
                  ))}
                </Switch>
              </Col>
            </Row>
          </Grid>
        </Formik>
      </ScrollView>
    </CrossPlatformThemeProvider>
  );
};

export { StoryBook };
