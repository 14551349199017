import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_schedule } from '@bighealth/api/SleepioSchedule/v1';

import { useQueryProgram } from './useQueryProgram';

export const useQuerySchedule = (): UseQueryResult<
  get_schedule.Response,
  APIErrorResponse
> => {
  const programResult = useQueryProgram();
  const programId = programResult.data?.result.id;

  const result = useQuery<get_schedule.Response, APIErrorResponse>({
    queryKey: get_schedule.queryKey,
    queryFn: () =>
      get_schedule({
        program_id: programId as number,
      }),
    enabled: typeof programId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
