import { useEffect, useState } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';

import { useQuerySleepDiaryDaysDetail } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQuerySleepDiaryDaysDetail';

import {
  generateTooltipData,
  timeFormatter,
  tittleDateFormat,
} from './util/commons';
import { DetailTooltipControl, TooltipData } from './util/types';

const WINDOW_CENTER_LAMBDA_OFFSET = 50;
const LOCATION_OFFSET = 35;
const X_AXIS_EXTRA_OFFSET = 100;

const useDetailTooltipControl = (): DetailTooltipControl => {
  const [displayTooltip, _setDisplayTooltip] = useState<boolean>(false);
  const [targetDiaryDate, setTargetDiaryDate] = useState<string>('');
  const [tooltipLocationInfo, setTooltipLocationInfo] = useState<
    DetailTooltipControl['tooltipLocationInfo']
  >({
    locationX: 'center',
    locationY: 'center',
    offsetX: 0,
    offsetY: 0,
  });
  const [tooltipData, setTooltipData] = useState<TooltipData>(
    generateTooltipData()
  );
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const { data, refetch } = useQuerySleepDiaryDaysDetail(
    {
      number_of_days: 1,
      start_date: targetDiaryDate as string,
    },
    undefined
  );

  const setDisplayTooltip = (state: boolean) => {
    if (!state) {
      setTooltipData(generateTooltipData());
      setTargetDiaryDate('');
    }
    _setDisplayTooltip(state);
  };

  const calculatePosition = (location: { x: number; y: number }) => {
    /*
     * This function calculates the position of the tooltip based on the location of the suggest location(touch event).
     * The tooltip will be displayed near the side of the suggest location(touch event).
     * If the suggest location(touch event) is near the center of the screen and the device is in landscape mode, the tooltip will be displayed on the vertical center of the screen.
     */

    // The tooltip will be positioned relative to the screen area where the click event occurs. To achieve this, we need to perform some validations to determine in which section of the screen it will be positioned.
    const isLandscapeMode = windowWidth < windowHeight;
    const halfScreenWidth = windowWidth / 2;
    const isHorizontalLocationNearScreenCenter =
      halfScreenWidth - WINDOW_CENTER_LAMBDA_OFFSET < location.x &&
      location.x < halfScreenWidth + WINDOW_CENTER_LAMBDA_OFFSET;
    const isTopSideLocated = location.y < windowHeight / 2;
    const isLeftSideLocated = location.x < halfScreenWidth;

    // Due to the location base on suggest location(touch event) some offsets are needed to make sure the tooltip doesn't go off the screen
    const locationOffset = isLandscapeMode ? LOCATION_OFFSET : 0;
    const extraXAxisOffset = isHorizontalLocationNearScreenCenter
      ? X_AXIS_EXTRA_OFFSET
      : 0;
    const getXOffsetFromRight = () =>
      windowWidth - location.x - (locationOffset + extraXAxisOffset) / 2;
    const getXOffsetFromLeft = () =>
      location.x - (locationOffset + extraXAxisOffset) / 2;
    const getYOffsetFromBottom = () =>
      windowHeight - location.y - locationOffset / 2;
    const getYOffsetFromTop = () => location.y - locationOffset;

    setTooltipLocationInfo(() => {
      /* 
        The tooltip will be positioned relative to the screen area where the click event occurs, if no position is sent, it will get its default positioning (center)
      */
      const info = { ...tooltipLocationInfo };
      const xLocationExist = location.x !== null && location.x !== undefined;
      const yLocationExist = location.y !== null && location.y !== undefined;

      if (xLocationExist) {
        info.locationX = isLeftSideLocated ? 'left' : 'right';
        info.offsetX = isLeftSideLocated
          ? getXOffsetFromLeft()
          : getXOffsetFromRight();
      } else {
        info.locationX = 'center';
        info.offsetY = 0;
      }

      if (isLandscapeMode && yLocationExist) {
        info.locationY = isTopSideLocated ? 'top' : 'bottom';
        info.offsetY = isTopSideLocated
          ? getYOffsetFromTop()
          : getYOffsetFromBottom();
      } else {
        info.locationY = 'center';
        info.offsetY = 0;
      }
      return info;
    });
  };

  const triggerTooltip = (
    location: { x: number; y: number },
    date: string,
    action: () => void
  ) => {
    /* 
      The purpose of this function is to be use to properly display the tooltip preparing a position where to display it and propagating some extra actions on tooltip interactions.
      - location parameter is an object with x and y coordinates, representing the position where the tooltip should be displayed. 
      - date parameter is a string, used to provide additional information for the tooltip interactions. 
      - action parameter is callback function that can be executed on tooltip interactions.
    */
    calculatePosition(location);

    setTooltipData(
      // On tooltip interactions(content footer button) we want to execute action callback function before close the tooltip.
      generateTooltipData(tittleDateFormat((date as any).$date), [], () => {
        action?.();
        setDisplayTooltip(false);
      })
    );

    setTargetDiaryDate(date);

    setDisplayTooltip(true);
  };

  useEffect(() => {
    const dimensionsChangeListener = Dimensions.addEventListener(
      'change',
      () => {
        setDisplayTooltip(false);
      }
    );
    return () => dimensionsChangeListener?.remove();
  }, []);

  useEffect(() => {
    (async () => {
      if (targetDiaryDate) {
        await refetch();
      }
    })();
  }, [targetDiaryDate, refetch]);

  useEffect(() => {
    if (data?.result) {
      const dataResult = data?.result;
      const dairyResponse = Object.values(dataResult)[0];
      setTooltipData(currentState =>
        generateTooltipData(
          currentState.title,
          [
            dairyResponse?.sleep_efficiency + '%',
            timeFormatter(dairyResponse?.total_time_in_bed_minutes),
            timeFormatter(dairyResponse?.total_time_asleep_minutes),
            dairyResponse?.sleep_quality_percentage + '%',
          ],
          currentState.buttonAction
        )
      );
    }
  }, [data]);

  return {
    displayTooltip,
    tooltipData,
    tooltipLocationInfo,
    setDisplayTooltip,
    triggerTooltip,
  };
};

export { useDetailTooltipControl };
