import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { is_feature_available_for_user } from '@bighealth/api/SleepioFeatureAccess/v1';

import { useQueryProgram } from './useQueryProgram';

export type UseQueryIsFeatureAvailableForUserReturnType = UseQueryResult<
  is_feature_available_for_user.Response,
  APIErrorResponse
>;

export type UseQueryIsFeatureAvailableForUserArgs = {
  featureName: string;
};

export enum SLEEPIO_FEATURES {
  DiaryReminderNotification = 'diary-reminder-notification',
}

export const useQueryIsFeatureAvailableForUser = ({
  featureName,
}: UseQueryIsFeatureAvailableForUserArgs): UseQueryIsFeatureAvailableForUserReturnType => {
  const programResult = useQueryProgram();
  const programId = programResult.data?.result.id;
  const result = useQuery<
    is_feature_available_for_user.Response,
    APIErrorResponse
  >({
    queryKey: [is_feature_available_for_user.queryKey, featureName],
    queryFn: () =>
      is_feature_available_for_user({
        feature_name: featureName,
        program_id: programId as number,
      }),
    enabled: typeof programId !== 'undefined',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
