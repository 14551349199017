import styled from 'styled-components/native';

import { FontFamilies } from 'components/sceneset-components/Text/fonts';

export const WeekTrackerWrapper = styled.View`
  height: 128px;
  background-color: ${props => props.theme.color.deselectedGrey};
`;

export const WeekTrackerTitleText = styled.Text`
  font-weight: 500;  
  font-size: 14px;
  padding: 16px 0 18px 0;
  text-align: center;
  font-family: '${FontFamilies.Regular}';
`;

export const DayTrackerWrapper = styled.View`
  padding: 0 16px;
  flex-direction: row;
  justify-content: space-evenly;
`;
