import ScreenOrientation from 'react-native-orientation-locker';

import { getRUMConfig } from 'config/getRUMConfig';
import { useRealUserMetrics } from 'lib/realUserMetrics';
import * as reporter from 'lib/reporter';
import { useSnapEngage } from 'lib/snapEngage/useSnapEngage';

export const useInit = (): void => {
  // We unlock all orientations at launch to be safe because in some areas in
  // the app, we lock to portrait.
  ScreenOrientation.unlockAllOrientations();
  useSnapEngage();
  useRealUserMetrics(getRUMConfig());
  reporter.init();
};
