import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components/native';

import { QuoteMarkIcon } from 'components/Quote/QuoteMarkIcon';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';

const Container = styled.View`
  flex-direction: row;
  align-items: flex-start;
  border-color: #e5e5e5;
  border-style: solid;
`;

const QuoteText = styled(Text)`
  color: ${sleepioColors.black};
`;

const CONTAINER_PADDING = 32;

export const Quote = ({ text }: { text: string }): ReactElement => {
  const transformStyles = useTransformStylesToContext();
  return (
    <Container
      pointerEvents={'none'}
      style={transformStyles({
        paddingLeft: CONTAINER_PADDING,
        paddingRight: CONTAINER_PADDING,
        paddingBottom: CONTAINER_PADDING,
        paddingTop: CONTAINER_PADDING,
        borderRadius: 4,
        borderWidth: 1,
        // Note: it's not usual/good to add a margin to a component but the intention here is to make it extra east for content creators
        marginBottom: 19,
      })}
    >
      <QuoteMarkIcon
        style={transformStyles({
          width: 15,
          height: 14,
          marginRight: 10,
          marginTop: 3, // To bump it inline with the top of the text, not the text's container
          flexShrink: 0,
        })}
      />
      <QuoteText style={transformStyles({ fontSize: 20 })}>{text}</QuoteText>
    </Container>
  );
};
