import { useCallback, useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import {
  matchesLogin,
  matchesTitleScreen,
} from 'components/Routes/matchesRoutes';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';
import {
  queryClientProduct,
  queryClientProgram,
} from 'lib/api/reactQueryHelpers';
import * as reporter from 'lib/reporter';
import { isError } from 'lib/reporter/utils/errors/isError';

export const useRedirectIsAuthenticated = (
  fetchOnAppStateChange = false
): void => {
  const { productReference } = useSafeParams();
  const [appState, setAppState] = useState(AppState.currentState);
  const history = useHistory();
  const appStateRef = useRef(appState);
  appStateRef.current = appState;

  const checkIfAuthenticated = useCallback(() => {
    (async function fetchProgram() {
      const isRedirectable =
        matchesLogin(history) || matchesTitleScreen(history);
      if (!isRedirectable) {
        return;
      }
      try {
        const response = await queryClientProduct({
          product_reference: productReference,
        });
        await queryClientProgram({
          product_id: response.result.id,
        });
        history.push(`/${productReference}/home`);
      } catch (e) {
        type MaybeResponseData = { response: { data: unknown } };
        // do nothing - let the APIClient error configuration handle this
        if ((e as MaybeResponseData)?.response?.data !== undefined) {
          reporter.deprecated.warn(
            '[UseRedirectIsAuthenticated]: Error while determining redirect destination based on product and program',
            (e as MaybeResponseData)?.response?.data
          );
        } else {
          reporter.silent.log(
            `[UseRedirectIsAuthenticated]: Error with unexpected shape while determining redirect destination based on product and program: ${e}`,
            isError(e) ? e : undefined
          );
        }
      }
    })();
  }, [history, productReference]);

  useEffect(() => {
    checkIfAuthenticated();
  }, [checkIfAuthenticated, history, productReference]);

  const handleAppStateChange = useCallback(
    (nextAppState: AppStateStatus) => {
      if (
        fetchOnAppStateChange &&
        nextAppState === 'active' &&
        appStateRef.current.match(/inactive|background/)
      ) {
        checkIfAuthenticated();
      }
      setAppState(nextAppState);
    },
    [checkIfAuthenticated, fetchOnAppStateChange]
  );

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      handleAppStateChange
    );
    return () => {
      subscription.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
