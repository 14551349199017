import { ErrorInfo } from 'react';

import { isDevMode } from '../../isDevMode';
import { captureException } from '../utils/captureException';
import { captureMessage } from '../utils/captureMessage';
import { systemAlert } from '../utils/systemAlert';

type LogOptions = {
  silent?: boolean;
  silentUnlessDevOnly?: boolean;
  errorInfo?: ErrorInfo;
};

export function log(
  message: string,
  error?: Error | unknown,
  { silent, silentUnlessDevOnly, errorInfo }: LogOptions = {}
): void {
  if (!silent || (silentUnlessDevOnly && isDevMode())) {
    systemAlert(message);
  }
  if (silent === true) {
    // TODO: DataDog log if NOT "isDevMode()" (or add an "info" )
    console.info(message, error, errorInfo);
  } else {
    console.error(message, error, errorInfo);
  }
  if (error) {
    captureException(error);
  } else {
    captureMessage(message);
  }
}

export default log;
