import { useEffect } from 'react';
import { AppState } from 'react-native';

import useAppStateHandlers from './useAppStateHandlers/useAppStateHandlers';

export const useHandleTimersForAppState = (): boolean => {
  const { appState, handleAppChange } = useAppStateHandlers();

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppChange);
    return () => {
      subscription.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // "change" eventListener is slow on iOS and for this reason player's locks release
  // is delayed, giving a user 1 second window to press play button when locks are on
  // this prop is used at MediaPlayerControls to disable play button
  return appState !== 'active';
};
