import React, { FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components/native';

import { TextTypes } from 'common/constants/enums';
import { Check, Warning } from 'components/icons';
import {
  contentStyles,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';
import { SuccessText } from 'components/Text/SuccessText';
import { WarningText } from 'components/Text/WarningText';
import { roles } from 'cross-platform/utils/roleProps';

import { FitbitUserMessageInfo } from '../../types';

export const UserMessageText = styled.View`
  flex-direction: row;
`;
UserMessageText.displayName = 'UserMessageText';

export type FitbitUserMessageProps = {
  withScaling?: boolean;
  messageInfo: FitbitUserMessageInfo | null;
};

export const FitbitUserMessage: FunctionComponent<FitbitUserMessageProps> = ({
  messageInfo,
  withScaling = true,
}: FitbitUserMessageProps) => {
  const theme = useTheme();
  const scaledStyles = useGetDynamicContentStyles();
  const styles = withScaling ? scaledStyles : contentStyles;

  const iconMarginTop =
    (styles.settingsSwitchText - styles.settingsEntryIconSize) / 2 +
    styles.settingsEntryMessagePadding;

  if (messageInfo === null) {
    return null;
  }

  if (messageInfo.type === 'success') {
    return (
      <UserMessageText>
        <Check
          size={styles.settingsEntryIconSize}
          style={{
            marginRight: 4,
            marginTop: iconMarginTop,
            color: theme.text[TextTypes.SUCCESS].color,
          }}
        />
        <SuccessText
          style={{
            fontSize: styles.settingsSwitchText,
            paddingTop: styles.settingsEntryMessagePadding,
          }}
          {...roles('FitbitUserMessage--success')}
        >
          {messageInfo.message}
        </SuccessText>
      </UserMessageText>
    );
  } else if (messageInfo.type === 'error') {
    return (
      <UserMessageText>
        <Warning
          size={styles.settingsEntryIconSize}
          style={{
            marginRight: 4,
            marginTop: iconMarginTop,
            color: theme.text[TextTypes.ERROR].color,
          }}
        />
        <WarningText
          style={{
            fontSize: styles.settingsSwitchText,
            paddingTop: styles.settingsEntryMessagePadding,
          }}
          {...roles('FitbitUserMessage--error')}
        >
          {messageInfo.message}
        </WarningText>
      </UserMessageText>
    );
  }

  return null;
};
