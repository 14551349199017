import { Question, Scene } from '@bighealth/types';

const getScheduleNextSessionScene = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      alignment: {
        horizontal: 'middle',
      },
      childNodes: [
        {
          component: 'ScheduleNextSessionForm',
          title: {
            component: 'Text',
            text: 'Schedule your next session',
            style: {
              color: '#000000',
              fontWeight: 700,
              fontSize: 32,
            },
          },
          questionProps: {
            id: 4,
            optional: false,
            question_pre_text: '',
            question_title: undefined,
            response_config: {
              min_selections_required: 1,
              max_selections_required: 1,
              default_response_ids: [],
              correct_response_ids: [],
              max_number_attempts: 1,
              validation_schema: {},
              response_options: [
                {
                  id: 1,
                  value: '$input',
                },
              ],
            },
            response_type: 'datetime',
            semantic_id: 'schedule_next_session',
          } as Question,
          submit_button: {
            text: 'Save',
            action: {
              type: 'submit',
            },
          },
          continue_button: {
            text: 'Continue without reminder',
            action: {
              type: 'submit',
            },
          },
        },
      ],
    },
  ],
});

export { getScheduleNextSessionScene };
