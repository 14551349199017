import React, { ReactElement } from 'react';
import styled from 'styled-components/native';

import { ProductReferences } from 'common/constants/enums';
import { FillAbsolute, FillWithInsets } from 'components/Containers';
import { getComponentsForProduct } from 'config';
import { useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { useQueryHomeScreen } from 'lib/api/reactQueryHelpers';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { getProductName } from 'lib/productInfo/getProductName';
import { getTheme } from 'lib/styles/getTheme';

/**
 * `getMenuRoute()` returns which route string used to display the default menu (HomeScreen)
 *
 * WHY?
 *
 * SHORT VERSION
 *
 * Certain `HomeScreen/index.tsx` features use matched routes, which needs to switch based
 * on whether Default menu is to be found at `/home` or `/menu`.
 *
 * LONG VERSION
 *
 * There are two SceneSet menu's in the code base:
 *    1. Default menu - `HomeScreen/index.tsx`
 *        - displays all available SceneSets on all SceneSetGraphs
 *        - Was written first
 *        - route:
 *            - `/home` for all default Products
 *            - `/menu` for Sleepio product
 *            - Pre-Product #2 (e.g. Depressio), is technically out-of-scope
 *              but useful to QA
 *    2. Sleepio menu - `SessionsScreen/index.tsx`
 *        - a.k.a "Visit the Prof" page
 *        - displays "progress-able" SceneSets from SessionProgress service
 *        - route: `/home` (only Sleepio)
 *
 * The Default Menu uses route matching to:
 *    - register back button
 *    - fetchProgramAndGraphs (if needed)
 *
 * This function ensures the correct route string is returned, so the above works correctly
 *
 */
export const getMenuRoute = (): string => {
  return getProductName() === ProductReferences.SLEEPIO
    ? '/:productReference/menu'
    : '/:productReference/home';
};

const Fill = styled(FillAbsolute)`
  background-color: ${getTheme('homeScreen.backgroundColor')};
`;

const WithInsets = styled(FillWithInsets)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const HomeScreen = (): ReactElement => {
  useUpdateTitle();
  const sceneSetGraphs = useQueryHomeScreen()?.data?.result;

  const { productReference } = useParams<{ productReference: string }>();
  const { Menu } = getComponentsForProduct(productReference as string);
  const menuItems = sceneSetGraphs
    ? sceneSetGraphs.filter(Boolean).map(sceneSetGraph => ({ sceneSetGraph }))
    : undefined;
  return (
    <Fill {...roles('homeScreen')}>
      <WithInsets>{menuItems ? <Menu items={menuItems} /> : null}</WithInsets>
    </Fill>
  );
};

export default HomeScreen;
