import React, { ReactElement, ReactNode } from 'react';
import { View } from 'react-native';

import { useGoalsScalingStyles } from 'components/Goals/useGoalsScalingStyles';
import { roles } from 'cross-platform/utils/roleProps';

export const GoalContainer = ({
  children,
  showMarginRight,
}: {
  isInSceneSet?: boolean;
  showMarginRight?: boolean;
  children: ReactNode;
}): ReactElement => {
  const styles = useGoalsScalingStyles();
  return (
    <View
      {...roles('GoalContainer')}
      style={{
        flex: 1,
        paddingTop: styles.verticalPadding,
        paddingBottom: styles.verticalPadding,
        paddingLeft: styles.horizontalPadding,
        paddingRight: styles.horizontalPadding,
        borderRadius: styles.borderRadius,
        backgroundColor: 'white',
        marginRight: showMarginRight ? styles.horizontalSpacing : 0,
        elevation: styles.shadowElevation,
        shadowOffset: {
          width: styles.shadowOffsetX,
          height: styles.shadowOffsetY,
        },
        shadowColor: 'rgb(0, 80, 107)',
        shadowOpacity: 0.22,
        shadowRadius: styles.shadowRadius,
      }}
    >
      {children}
    </View>
  );
};
