import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import ScreenOrientation, {
  OrientationType,
  useOrientationChange,
} from 'react-native-orientation-locker';

export const ScreenOrientationContext = React.createContext<OrientationType>(
  ScreenOrientation.getInitialOrientation()
);

export const ScreenOrientationProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [screenOrientation, setScreenOrientation] = useState(
    ScreenOrientation.getInitialOrientation()
  );
  useOrientationChange(() => {
    // we have to explicitly request resulting orientation
    // to ensure orientation tracking is not confused when user
    // rotates Android device because the whole UI is recreated
    ScreenOrientation.getOrientation((o: OrientationType) => {
      setScreenOrientation(o);
    });
  });
  return (
    <ScreenOrientationContext.Provider value={screenOrientation}>
      {children}
    </ScreenOrientationContext.Provider>
  );
};

export const useScreenOrientation = (): OrientationType =>
  useContext(ScreenOrientationContext);
