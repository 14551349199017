import React, { ReactElement } from 'react';

import { CommonPropType } from '../index.story';

import { DropdownTimePicker } from '.';

export const DropdownTimePickerStory = (
  props: CommonPropType
): ReactElement => (
  <DropdownTimePicker onValueChange={console.log} highlight={props.highlight} />
);
