export * from './values';

export enum MarkdownType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
  ERROR_BOUNDARY = 'ERROR_BOUNDARY',
}

export const ERROR_BOUNDARY_FONT_SIZE = 24;

export const DEFAULT_FONT_SIZE = 18;

export const DEFAULT_LINE_HEIGHT = 24;
