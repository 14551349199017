import { ScrollView as ScrollViewRN, ScrollViewProps } from 'react-native';
import _ from 'lodash';
import styled from 'styled-components/native';

import { DOMAIN } from '../../../constants';

import { getScrollViewProps } from './getScrollViewProps';

export const ScrollView = styled(ScrollViewRN).attrs<ScrollViewProps>({
  horizontal: true,
  ...getScrollViewProps(),
  contentContainerStyle: { flex: 1, width: '100%' },
})`
  width: 100%;
`;
ScrollView.displayName = `${DOMAIN}.ScrollView`;

export const FixedView = styled.View`
  width: 100%;
  flex: 1;
`;
FixedView.displayName = `${DOMAIN}.FixedView`;
