import React, { ReactElement, useEffect, useState } from 'react';

import { SleepDiaryWeekTypes } from '@bighealth/types/src/scene-components/sleep-diary';
import { ArgPeriod } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

import { usePostponeSleepDiaryNotifications } from 'components/LocalNotifications/NotificationsView/hooks/usePostponeNotifications';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { useQuerySleepDiaryWeekly } from 'lib/api/reactQueryHelpers';

import { StatefulSleepDiaryWeek } from '../State';

import { Wrapper } from './styled';

// TODO: move the hook outside of the SessionScreen to make it avaialable in the whole codebase
/**
 * The Networked layer of the SleepDiaryWeek component.
 *
 * Handles the network calls for:
 *  - Retrieving the Sleep Diaries and other data for the component
 *  - The extrapolate call for extrapolating diaries in certain use cases
 *  - Moving to the next SceneSet.
 *
 * See the type definition for further explanation of each of the props.
 * @param props
 * @constructor
 */
const Networked = (
  props: SleepDiaryWeekTypes['PreNetworkCallConfiguration'] & {
    initialPayload?: SleepDiaryWeekTypes['NetworkResponse'] &
      SleepDiaryWeekTypes['ContentCreatorPresentationConfiguration'];
  }
): ReactElement | null => {
  const {
    period,
    isReadOnly,
    isOutsideContent,
    includeMetadata,
    calendarWeekOffset,
  } = props;
  // Used to determine when the last time the Sleep Diary Form, which this
  // component presents, was last submitted.
  // Changes to lastSubmit will force the component to re-request the Sleep
  // Diary and other data that this component displays, so the component updates
  // when Sleep Diaries are added.
  const [lastSubmit, setLastSubmit] = useState<number | undefined>(undefined);
  const [offset, setOffset] = useState<number | undefined>(calendarWeekOffset);
  const commonPayload = {
    is_read_only: isReadOnly,
    is_outside_content: isOutsideContent,
    include_metadata: includeMetadata,
  };
  const variablePayload =
    period === 'calendar_week'
      ? {
          period: period as ArgPeriod.calendar_week,
          calendar_week_offset: offset,
        }
      : {
          period: period as ArgPeriod.last_seven_days,
        };
  const { sceneId, sceneSetId, sceneSetGraphId } = useSafeParams<
    SceneSetParams
  >();
  const { isLoading, data, refetch } = useQuerySleepDiaryWeekly(
    {
      ...commonPayload,
      ...variablePayload,
    },
    lastSubmit
  );

  useEffect(() => {
    // checking for refetch existence because we don't mock it in tests
    // remove it as soon we will start mocking refetch property
    if (typeof refetch === 'function') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneId, sceneSetId, sceneSetGraphId]);

  const [, setIsLoading] = useState<boolean>(false);

  const pageForwardPress = () => {
    if (typeof offset === 'number') {
      setOffset(offset - 1);
    }
  };

  const pageBackwardPress = () => {
    if (typeof offset === 'number') {
      setOffset(offset + 1);
    }
  };

  const { postponeDailyNotifications } = usePostponeSleepDiaryNotifications();

  const onDiarySubmit = (
    data: React.SetStateAction<number | undefined>,
    selectedDailyDate: string | undefined
  ) => {
    setLastSubmit(data);

    // Postpone daily diary notification by one day if the diary is submitted for today
    if (selectedDailyDate) {
      const selectedDailyDateString = ((selectedDailyDate as unknown) as {
        $date: string;
      })['$date'];
      const today = new Date();
      const dailyDate = new Date(selectedDailyDateString);
      if (
        today.getUTCMonth() === dailyDate.getUTCMonth() &&
        today.getUTCDate() === dailyDate.getUTCDate()
      ) {
        postponeDailyNotifications();
      }
    }
  };

  if (isLoading) {
    return null;
  }
  if (data?.result === undefined) {
    return null;
  }
  return (
    <Wrapper>
      <StatefulSleepDiaryWeek
        {...data.result}
        {...props}
        pageBackwardPress={pageBackwardPress}
        pageForwardPress={pageForwardPress}
        onDiarySubmit={onDiarySubmit}
        setIsLoading={setIsLoading}
      />
    </Wrapper>
  );
};

export { Networked };
