import React, { ReactNode } from 'react';
import { ScrollView, ScrollViewProps, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

const DOMAIN = 'InputsAsArray';

export type ScrollRowProps = ScrollViewProps & {
  contentContainerStyle?: ViewStyle;
  children?: ReactNode;
};

export const SelectHorizontalScrollView: React.FC<ScrollRowProps> = styled(
  ScrollView
).attrs({
  horizontal: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: { flex: 1, width: '100%', flexWrap: 'wrap' },
})<ScrollRowProps>``;
SelectHorizontalScrollView.displayName = `${DOMAIN}.SelectHorizontalScrollView`;
