import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { is_session_available_on_mobile_web } from '@bighealth/api/SessionProgress/v1';

import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { isMobileWeb } from 'config/isMobileWeb';

export type UseQueryIsSessionAvailableOnMobileWebReturnType = UseQueryResult<
  is_session_available_on_mobile_web.Response,
  APIErrorResponse
>;

export const useQueryIsSessionAvailableOnMobileWeb = (): UseQueryIsSessionAvailableOnMobileWebReturnType => {
  const { sceneSetGraphId } = useSafeParams<SceneSetParams>();

  const result = useQuery<
    is_session_available_on_mobile_web.Response,
    APIErrorResponse
  >({
    queryKey: [is_session_available_on_mobile_web.queryKey, sceneSetGraphId],
    queryFn: () =>
      is_session_available_on_mobile_web({
        graph_id: sceneSetGraphId,
      }),
    enabled: isMobileWeb,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
