import React, { FunctionComponent, ReactNode } from 'react';
import { AccessibilityProps, GestureResponderEvent } from 'react-native';
import styled from 'styled-components/native';

const StyledContainer = styled.View`
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const BarChartColumnFillContainer: FunctionComponent<{
  children: ReactNode;
  disabled?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
} & AccessibilityProps> = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

StyledContainer.displayName = 'StyledContainer';
BarChartColumnFillContainer.displayName = 'BarChartColumnFillContainer';

export { BarChartColumnFillContainer, StyledContainer };
