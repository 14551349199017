import { ScrollView as ScrollViewUnstyled } from 'react-native';
import styled from 'styled-components/native';

export const Wrapper = styled.View<{ visible?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${props => (props.visible ? 'initial' : 'none')};
`;
Wrapper.displayName = 'PopupModal.Wrapper';

export const OverlayTint = styled.View<{
  overlayTintColor?: string;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${p => p.overlayTintColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
OverlayTint.displayName = 'PopupModal.OverlayTint';
OverlayTint.defaultProps = {
  overlayTintColor: 'rgba(0, 51, 102, 0.8)',
};

export const Panel = styled.View<{ isMobileWeb: boolean }>`
  max-width: 100%;
  background-color: white;
  width: 666px;
  display: flex;
  flex-direction: column;
  flex-grow: ${props => (props.isMobileWeb ? 1 : 0)};
  margin-top: ${props => (props.isMobileWeb ? 0 : 54)}px;
  margin-bottom: ${props => (props.isMobileWeb ? 0 : 104)}px;
`;
Panel.displayName = 'PopupModal.Panel';

export const ScrollView = ScrollViewUnstyled;
