import React, { FunctionComponent, useCallback } from 'react';

import { Question, ResponseOptionValue } from '@bighealth/types';
import { Scene } from '@bighealth/types/src/scene-component';

import { useSafeParams } from 'components/Routes/useSafeParams';
import useActionHandler from 'lib/player/useActionHandler';
import * as reporter from 'lib/reporter';

import { FormPresentation } from '../FormPresentation';

import { submitExtendSleepWindow } from './helpers/submitExtendSleepWindow';

export declare type ExtendSleepWindowProps = {
  // we need the title because we have the variable slot in the question title
  // and it is not supported
  title: Omit<Scene.Components.Text, 'component'>;
  questionProps: Question;
  submit_button: Scene.Components.Button;
};

/**
 * Connects to state
 * @param props
 */
const FormState: FunctionComponent<ExtendSleepWindowProps> = props => {
  const { sceneSetGraphId } = useSafeParams();
  const actionHandler = useActionHandler(props?.submit_button.action);
  const handleSubmit = useCallback(
    async (value: ResponseOptionValue): Promise<void> => {
      if (typeof value === 'string') {
        const changeAction = value;
        // in case of 'no_change' the minutes will be ignored
        let minutesOfIncrement = 15;
        if (changeAction === 'change_start') {
          minutesOfIncrement = -15;
        }
        const [, error] = await submitExtendSleepWindow({
          changeAction: changeAction,
          minutesIncrement: minutesOfIncrement,
          sceneSetGraphId: sceneSetGraphId,
        });

        if (error === null) {
          try {
            if (typeof actionHandler === 'function') {
              await actionHandler();
            }
          } catch (e) {
            reporter.log(e?.message, e);
          }
        }
      }
    },
    [actionHandler, sceneSetGraphId]
  );
  return (
    <FormPresentation
      title={props?.title}
      questionProps={props?.questionProps}
      submit_button={props?.submit_button}
      onSubmit={handleSubmit}
    />
  );
};

export { FormState };
