import styled from 'styled-components/native';

import { Row } from 'components/layout/Grid';

import { ScaleYValueProps } from '../../../utils/scaler';
import { DOMAIN } from '../components/SessionStatus/styled/constants';

const BottomRow = styled(Row)<ScaleYValueProps>``;
BottomRow.displayName = `${DOMAIN}.BottomRow`;

export { BottomRow };
