import { DiaryEntry } from '@bighealth/types/dist/services/SleepDiaryPayloads/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';
import { SubmitDiaryResult } from '@bighealth/types/dist/services/SleepDiaryPayloads/submit_single_diary_form';

export const submit_single_diary_form = async (
  args: submit_single_diary_form.Args,
  requestConfig?: AxiosRequestConfig
): Promise<submit_single_diary_form.Response> => {
  const request: submit_single_diary_form.Request = {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'submit_single_diary_form',
    args,
  };

  return await api(request, requestConfig);
};

submit_single_diary_form.queryKey = 'SleepDiary/submit_single_diary_form';

export declare namespace submit_single_diary_form {
  export type Args = DiaryEntry;

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'submit_single_diary_form';
    args: Args;
  }

  export type Response = APIResponse<SubmitDiaryResult>;
}
