import React, { FunctionComponent, useMemo } from 'react';
import { Platform } from 'react-native';

import { SimpleTooltip, TooltipProps } from './SimpleTooltip';
import {
  DetailButton,
  DetailButtonText,
  DetailContainer,
  DetailContentRow,
  DetailKey,
  DetailTittle,
  DetailValue,
} from './styled';

export interface DetailTooltipData {
  title?: string;
  content?: {
    key: string;
    value: string;
  }[];
  buttonText?: string;
  buttonAction?: () => void;
}

export interface LocationInfo {
  offsetX?: number;
  offsetY?: number;
  locationX?: 'left' | 'right' | 'center';
  locationY?: 'top' | 'bottom' | 'center';
}

export interface DetailTooltipProps extends Omit<TooltipProps, 'children'> {
  data: DetailTooltipData;
  locationInfo: LocationInfo;
}

const DetailTooltip: FunctionComponent<DetailTooltipProps> = ({
  data,
  locationInfo,
  containerStyle,
  ...rest
}) => {
  const { title, content, buttonText, buttonAction } = data;
  const isAndroidDevice = useMemo(() => Platform.OS === 'android', []);
  const { locationX, locationY, offsetX, offsetY } = locationInfo;
  const xAxisAlign =
    locationX === 'center' || !locationX
      ? 'center'
      : locationX === 'left'
      ? 'flex-start'
      : 'flex-end';

  const yAxisJustify =
    locationY === 'center' || !locationY
      ? 'center'
      : locationY === 'top'
      ? 'flex-start'
      : 'flex-end';

  const generateKey = (index: number, key: string) => {
    return `${index}_${key.replace(/[^a-zA-Z]/g, '_')}`;
  };

  return (
    <SimpleTooltip
      containerStyle={{
        ...containerStyle,
        alignItems: xAxisAlign,
        justifyContent: yAxisJustify,
      }}
      contentStyle={
        !!offsetX || !!offsetY
          ? {
              marginLeft:
                offsetX && locationX !== 'right' ? offsetX : undefined,
              marginRight:
                offsetX && locationX === 'right' ? offsetX : undefined,
              marginTop: offsetY && locationY === 'top' ? offsetY : undefined,
              marginBottom:
                offsetY && locationY !== 'top' ? offsetY : undefined,
            }
          : {}
      }
      {...rest}
    >
      <DetailContainer accessible={isAndroidDevice}>
        {!!title && (
          <DetailTittle accessibilityRole="header">{title}</DetailTittle>
        )}
        {!!content &&
          content.map((item, index) => (
            <DetailContentRow
              key={generateKey(index, item.key)}
              accessible={true}
              accessibilityRole="text"
            >
              <DetailKey>{`${item.key}:`}</DetailKey>
              <DetailValue>{item.value}</DetailValue>
            </DetailContentRow>
          ))}
        {!!buttonText && (
          <DetailButton
            onPress={buttonAction}
            accessible={true}
            accessibilityRole="link"
          >
            <DetailButtonText>{buttonText}</DetailButtonText>
          </DetailButton>
        )}
      </DetailContainer>
    </SimpleTooltip>
  );
};

export { DetailTooltip };
