import { View } from 'react-native';
import styled from 'styled-components/native';

import Image, { ResizeMode } from 'cross-platform/Image';

export const ScreenWrapper = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.background.medium};
`;

export const ContentWrapper = styled.View`
  flex: 1;
`;

export const MonsterImageWrapper = styled.View<{ locked: boolean }>`
  align-items: center;
  opacity: ${({ locked }) => (locked ? 0.2 : 1)};
`;

export const MonsterImage = styled(Image).attrs({
  fit: ResizeMode.contain,
})<{ height: number; width: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
