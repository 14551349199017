import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_settings = async (
  args: get_settings.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_settings.Response> => {
  const request: get_settings.Request = {
    service_name: 'UserDaylightMetadata',
    service_version: '1',
    service_method: 'get_settings',
    args,
  };

  return await api(request, requestConfig);
};

get_settings.queryKey = 'UserDaylightMetadata/get_settings';

export declare namespace get_settings {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'UserDaylightMetadata';
    service_version: '1';
    service_method: 'get_settings';
    args: Args;
  }

  export type Result = {
    account: {
      first_name: string;
      last_name: string;
      email_address: string;
    };
    mfa_enabled: boolean;
  };

  export type Response = APIResponse<Result>;
}
