import React from 'react';
import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { History, LocationState } from 'history';
import { Dispatch } from 'redux';

import { QA_MENU_STRINGS } from './constants';
import { getQAOptions } from './getQAOptions';

interface QAMenuModalProps {
  visible: boolean;
  onClose: () => void;
  productReference: string;
  history: History<LocationState>;
  dispatch: Dispatch;
}

const QAMenuModal = ({
  visible,
  onClose,
  productReference,
  history,
  dispatch,
}: QAMenuModalProps) => {
  const options = getQAOptions(productReference, history, dispatch);
  console.log('options', options);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>{QA_MENU_STRINGS['Menu name']}</Text>
          <Text style={styles.modalInstructions}>
            {QA_MENU_STRINGS['Instructions']}
          </Text>
          <ScrollView style={styles.optionsContainer}>
            {options.map((option, index) => (
              <TouchableOpacity
                key={index}
                style={styles.option}
                onPress={() => {
                  option?.onPress?.();
                  onClose();
                }}
              >
                <Text style={styles.optionText}>{option.text}</Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <Text style={styles.closeButtonText}>Close menu</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80%',
    maxHeight: '80%',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  modalInstructions: {
    marginBottom: 15,
    textAlign: 'center',
  },
  optionsContainer: {
    width: '100%',
  },
  option: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  optionText: {
    fontSize: 16,
  },
  closeButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: '#2196F3',
    borderRadius: 5,
  },
  closeButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export { QAMenuModal };
