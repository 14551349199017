import { compose } from 'ramda';

import { APIRequestBody, APIResponse } from '@bighealth/api';

import { aliasPipe } from './response/aliasPipe/';
import { decodeAPITypesPipe } from './response/decodeAPITypesPipe/';
import { fromMinutesToMilliseconds } from './response/fromMinutesToMilliseconds';
import { toJavaScriptDateWithRequest } from './response/toJavaScriptDateWithRequest/';

export type ResponsePipe = (response: APIResponse) => APIResponse;

/**
 * Middleware for transformation  - AVOID UNLESS ABSOLUTELY REQUIRED
 * @see {@link https://expressjs.com/en/guide/using-middleware.html} For inspiration
 */
// IDEA memoise
export const middleware = {
  responses: (
    request: APIRequestBody,
    isForce = false
  ): ((unpackedResponse: APIResponse) => APIResponse) =>
    compose(
      fromMinutesToMilliseconds(request),
      toJavaScriptDateWithRequest(request, isForce),
      decodeAPITypesPipe,
      aliasPipe // Last executed first
    ),

  // IDEA If we NEED, we can transform outgoing like so:
  // requests: (request: APIRequestBody): APIRequestBody => request,
};
