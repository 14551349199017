import { ServiceCaller } from '../createServiceCaller';
import { EffectServiceCalls } from '../withEffectFactory';

export const doEffectServiceCalls = async (
  serviceCalls: EffectServiceCalls,
  serviceCaller: ServiceCaller
) => {
  if (
    serviceCalls.SleepDiary?.v1
      ?.extrapolate_missing_diaries_from_past_seven_days
  ) {
    return await serviceCaller.SleepDiary.v1.extrapolate_missing_diaries_from_past_seven_days();
  }
  return;
};
