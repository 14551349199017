import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AssetEntity } from '../../../types/src/services/Asset';
import { SceneAction } from '@bighealth/types/dist/scene-components/client';
import { AxiosRequestConfig } from 'axios';

export const get_user_screen_data = async (
  args: get_user_screen_data.Args,
  requestConfig?: AxiosRequestConfig
): Promise<get_user_screen_data.Response> => {
  const request: get_user_screen_data.Request = {
    service_name: 'SleepNow',
    service_version: '1',
    service_method: 'get_user_screen_data',
    args,
  };

  return await api(request, requestConfig);
};

get_user_screen_data.queryKey = 'SleepNow/get_user_screen_data';

export enum SleepNowStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export declare namespace get_user_screen_data {
  export type Args = {
    program_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepNow';
    service_version: '1';
    service_method: 'get_user_screen_data';
    args: Args;
  }

  export type SleepNowTechnique = {
    id: number;
    program_id: number;
    scene_set_graph_id: number;
    preview_image_asset_id: number;
    name: string;
    description: string;
    duration_label: string;
    reference: string;
    asset: AssetEntity;
    play_action: SceneAction;
    session_status: SleepNowStatus;
  };

  export type Result = SleepNowTechnique[];
  export type Response = APIResponse<Result>;
}
