import styled from 'styled-components/native';

import { TextTypes } from 'common/constants/enums';
import { ThemeProp } from 'config/getThemeForProducts';

import { Text } from '../Text';

const SuccessText = styled(Text)`
  color: ${(p: ThemeProp) => p.theme.text[TextTypes.SUCCESS].color};
`;
export { SuccessText };
