/**
 * Tip: this file was generated by a vim regex against styled-components csstypes file.
 */

const DO_NOT_SCALE = false; // Makes the "falsey" entries below a bit easier to scan

export const scalableCSSPropertiesDictionary = {
  backgroundPosition: true,
  backgroundPositionX: true,
  backgroundPositionY: true,
  backgroundSize: true,
  blockSize: true,
  borderBlockEndWidth: true,
  borderBlockStartWidth: true,
  borderBlockWidth: true,
  borderBottomLeftRadius: true,
  borderBottomRightRadius: true,
  borderBottomWidth: true,
  borderEndEndRadius: true,
  borderEndStartRadius: true,
  borderImageOutset: true,
  borderImageWidth: true,
  borderInlineEndWidth: true,
  borderInlineStartWidth: true,
  borderInlineWidth: true,
  borderLeftWidth: true,
  borderRadius: true,
  borderRightWidth: true,
  borderSpacing: true,
  borderStartEndRadius: true,
  borderStartStartRadius: true,
  borderTopLeftRadius: true,
  borderTopRightRadius: true,
  borderTopWidth: true,
  borderWidth: true,
  bottom: true,
  columnGap: true,
  columnRuleWidth: true,
  columnWidth: true,
  flexBasis: true,
  flexGrow: DO_NOT_SCALE,
  flexShrink: DO_NOT_SCALE,
  fontSize: true,
  gridAutoColumns: true,
  gridAutoRows: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  height: true,
  inlineSize: true,
  inset: true,
  insetBlock: true,
  insetBlockEnd: true,
  insetBlockStart: true,
  insetInline: true,
  insetInlineEnd: true,
  insetInlineStart: true,
  left: true,
  letterSpacing: true,
  lineHeight: true, // Note since this can be in both px and relative, this one is risky to scale
  lineHeightStep: true,
  margin: true,
  marginBlock: true,
  marginBlockEnd: true,
  marginBlockStart: true,
  marginBottom: true,
  marginInline: true,
  marginInlineEnd: true,
  marginInlineStart: true,
  marginLeft: true,
  marginRight: true,
  marginTop: true,
  maskBorderOutset: true,
  maskBorderWidth: true,
  maskPosition: true,
  maskSize: true,
  maxBlockSize: true,
  maxHeight: true,
  maxInlineSize: true,
  maxWidth: true,
  minBlockSize: true,
  minHeight: true,
  minInlineSize: true,
  minWidth: true,
  motionDistance: true,
  objectPosition: true,
  offsetAnchor: true,
  offsetDistance: true,
  offsetPosition: true,
  opacity: DO_NOT_SCALE,
  order: DO_NOT_SCALE,
  orphans: DO_NOT_SCALE,
  outlineOffset: true,
  outlineWidth: true,
  padding: true,
  paddingBlock: true,
  paddingBlockEnd: true,
  paddingBlockStart: true,
  paddingBottom: true,
  paddingInline: true,
  paddingInlineEnd: true,
  paddingInlineStart: true,
  paddingLeft: true,
  paddingRight: true,
  paddingTop: true,
  perspective: DO_NOT_SCALE,
  perspectiveOrigin: true,
  right: true,
  rowGap: true,
  scale: DO_NOT_SCALE,
  scrollMargin: true,
  scrollMarginBlock: true,
  scrollMarginBlockEnd: true,
  scrollMarginBlockStart: true,
  scrollMarginBottom: true,
  scrollMarginInline: true,
  scrollMarginInlineEnd: true,
  scrollMarginInlineStart: true,
  scrollMarginLeft: true,
  scrollMarginRight: true,
  scrollMarginTop: true,
  scrollPadding: true,
  scrollPaddingBlock: true,
  scrollPaddingBlockEnd: true,
  scrollPaddingBlockStart: true,
  scrollPaddingBottom: true,
  scrollPaddingInline: true,
  scrollPaddingInlineEnd: true,
  scrollPaddingInlineStart: true,
  scrollPaddingLeft: true,
  scrollPaddingRight: true,
  scrollPaddingTop: true,
  shapeImageThreshold: true,
  shapeMargin: true,
  tabSize: true,
  textIndent: true,
  top: true,
  transformOrigin: DO_NOT_SCALE,
  translate: DO_NOT_SCALE,
  verticalAlign: true,
  widows: DO_NOT_SCALE,
  width: true,
  wordSpacing: true,
  MozBorderEndWidth: true,
  MozColumnGap: true,
  MozColumnRuleWidth: true,
  MozColumnWidth: true,
  MozForceBrokenImageIcon: true,
  MozMarginEnd: true,
  MozMarginStart: true,
  MozOutlineRadiusBottomleft: true,
  MozOutlineRadiusBottomright: true,
  MozOutlineRadiusTopleft: true,
  MozOutlineRadiusTopright: true,
  MozPaddingEnd: true,
  MozPaddingStart: true,
  MozPerspective: true,
  MozPerspectiveOrigin: true,
  MozTabSize: true,
  MozTransformOrigin: true,
  msFlexPositive: true,
  msGridColumns: true,
  msGridRows: true,
  msHyphenateLimitZone: true,
  msOrder: DO_NOT_SCALE,
  msScrollLimitXMax: true,
  msScrollLimitXMin: true,
  msScrollLimitYMax: true,
  msScrollLimitYMin: true,
  msTransformOrigin: DO_NOT_SCALE,
  msWrapMargin: true,
  OObjectPosition: true,
  OTabSize: true,
  OTransformOrigin: true,
  WebkitBackgroundSize: true,
  WebkitBorderBeforeWidth: true,
  WebkitBorderBottomLeftRadius: true,
  WebkitBorderBottomRightRadius: true,
  WebkitBorderTopLeftRadius: true,
  WebkitBorderTopRightRadius: true,
  WebkitBoxReflect: true,
  WebkitColumnGap: true,
  WebkitColumnRuleWidth: true,
  WebkitColumnWidth: true,
  WebkitFlexBasis: true,
  WebkitFlexGrow: DO_NOT_SCALE,
  WebkitFlexShrink: DO_NOT_SCALE,
  WebkitMarginEnd: true,
  WebkitMarginStart: true,
  WebkitMaskPosition: true,
  WebkitMaskPositionX: true,
  WebkitMaskPositionY: true,
  WebkitMaskSize: true,
  WebkitMaxInlineSize: true,
  WebkitOrder: DO_NOT_SCALE,
  WebkitPaddingEnd: true,
  WebkitPaddingStart: true,
  WebkitPerspective: true,
  WebkitPerspectiveOrigin: true,
  WebkitShapeMargin: true,
  WebkitTextStrokeWidth: true,
  WebkitTransformOrigin: true,
  MozColumnRule: true,
  MozColumns: true,
  msFlex: DO_NOT_SCALE,
  WebkitBorderBefore: true,
  WebkitBorderRadius: true,
  WebkitColumnRule: true,
  WebkitColumns: true,
  WebkitFlex: true,
  WebkitMask: true,
  WebkitTextStroke: true,
  boxFlex: true,
  boxFlexGroup: DO_NOT_SCALE,
  boxOrdinalGroup: DO_NOT_SCALE,
  gridColumnGap: true,
  gridGap: true,
  gridRowGap: true,
  offsetBlock: true,
  offsetBlockEnd: true,
  offsetBlockStart: true,
  offsetInline: true,
  offsetInlineEnd: true,
  offsetInlineStart: true,
  scrollSnapCoordinate: true,
  scrollSnapDestination: true,
  KhtmlBoxFlex: true,
  KhtmlBoxFlexGroup: DO_NOT_SCALE,
  KhtmlBoxOrdinalGroup: DO_NOT_SCALE,
  KhtmlOpacity: DO_NOT_SCALE,
  MozBackgroundSize: true,
  MozBorderRadius: true,
  MozBorderRadiusBottomleft: true,
  MozBorderRadiusBottomright: true,
  MozBorderRadiusTopleft: true,
  MozBorderRadiusTopright: true,
  MozBoxFlex: DO_NOT_SCALE,
  MozBoxOrdinalGroup: DO_NOT_SCALE,
  MozOpacity: DO_NOT_SCALE,
  MozOutline: true,
  MozOutlineRadius: true,
  MozOutlineWidth: true,
  OBackgroundSize: true,
  WebkitBoxFlex: DO_NOT_SCALE,
  WebkitBoxFlexGroup: DO_NOT_SCALE,
  WebkitBoxOrdinalGroup: DO_NOT_SCALE,
  baselineShift: true,
  fillOpacity: DO_NOT_SCALE,
  floodOpacity: DO_NOT_SCALE,
  stopOpacity: DO_NOT_SCALE,
  strokeDasharray: true,
  strokeDashoffset: true,
  strokeMiterlimit: true,
  strokeOpacity: DO_NOT_SCALE,
  strokeWidth: true,
  msHeight: true,
  msMaxHeight: true,
  msMaxWidth: true,
  msMinHeight: true,
  msMinWidth: true,
  msWidth: true,
};

export type ScalableCSSProperty = keyof typeof scalableCSSPropertiesDictionary;
