/* eslint-disable react/prop-types */ // AC: Using ForwardRef triggers this without good cause
import * as React from 'react';
import { View } from 'react-native';

import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { stringify } from 'lib/stringify';
import { useSessionsStyles } from 'sleepio/components/screens/SessionsScreen/hooks/useSessionsStyles';
import { ScaleValueProps } from 'sleepio/components/screens/SessionsScreen/utils/scaler';

import { CardProps } from '../../index';
import { OptionalCard } from '../OptionalCard';
import { SessionCard } from '../SessionCard';

import { Touchable } from './styled';

export type CardPresentationProps = CardProps &
  RoleProps & {
    onPress: () => void;
  } & ScaleValueProps;

/**
 * Clicked to access a session
 *
 * @param {CardProps} param0
 */
const CardPresentation = React.forwardRef<View, CardPresentationProps>(
  (
    {
      status,
      play_action,
      onPress,
      scaleValue,
      style,
      isFirstChild,
      title,
      description,
      duration,
      session_thumb_image_asset,
      lock_text,
      variant,
      isLarge = false,
      ...rest
    },
    ref
  ): React.ReactElement => {
    const { cardStyles } = useSessionsStyles();

    const wrapperStyles = {
      // Card Wrapper style transform #1
      ...cardStyles.wrapperStyles,
      marginLeft: isFirstChild ? 0 : cardStyles.wrapperStyles.marginLeft,
    };
    // Card Wrapper style transform #2
    const mergedStyles = Array.isArray(style)
      ? [wrapperStyles, ...style]
      : [wrapperStyles, style];

    // Choose which card we want to render
    // Booth require the same options for now.
    // Ideally, this should be defined upstream without
    //   a need for a variant.
    const Card =
      variant === 'optional_content_list' ? OptionalCard : SessionCard;

    return (
      <View ref={ref}>
        <Touchable
          {...roles.pass(rest)}
          onPress={onPress}
          disabled={!play_action?.type}
          data-play_action={stringify(play_action)}
          accessible={false}
        >
          <Card
            status={status}
            play_action={play_action}
            session_thumb_image_asset={session_thumb_image_asset}
            scaleValue={scaleValue}
            duration={duration}
            title={title}
            description={description}
            lock_text={lock_text}
            onPress={onPress}
            style={mergedStyles}
          />
        </Touchable>
      </View>
    );
  }
);
CardPresentation.displayName = 'CardPresentation';
export { CardPresentation };
