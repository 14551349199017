import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { ExpandableNavItemProps } from './types';

const Button = styled.button`
  appearance: none;
  background-color: transparent;
  outline: 0;
  padding: 0;
  margin: 0;
  border: none;
`;

export type OnOpen = ({
  pageX,
  locationX,
}: {
  pageX: number;
  locationX: number;
}) => void;

export const ExpandableNavItem = ({
  onOpen,
  children,
  testID,
}: ExpandableNavItemProps): ReactElement => {
  return (
    <Button
      data-testid={testID}
      onMouseOver={e => {
        const rect = e.currentTarget.getBoundingClientRect();
        const locationX = e.clientX - rect.left; //x position within the element.
        const { pageX } = e;
        onOpen({ pageX, locationX });
      }}
    >
      {children}
    </Button>
  );
};
