import { Middleware } from 'redux';
import { REHYDRATE } from 'redux-persist';

import deepMapWhere from 'lib/deep-map-where';

import rootReducer from './rootReducer';

export const rehydrateMiddleware: Middleware<
  void,
  typeof rootReducer
> = () => next => action => {
  if (action.type === REHYDRATE && action.payload) {
    const out = deepMapWhere(
      action.payload,
      key => key === '$datetime',
      (val: string) => new Date(val)
    );

    return next({ ...action, payload: out });
  }
  return next(action);
};
