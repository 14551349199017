import styled from 'styled-components/native';
import { MarkOptional } from 'ts-essentials';

import { ButtonTypes } from 'common/constants/enums';
import { sleepioTheme } from 'config/getThemeForProducts/themes/sleepio';
import { getTheme } from 'lib/styles/getTheme';
import { scalerVertical } from 'sleepio/components/screens/SessionsScreen/components/Presentation/components/SessionStatus/styled';
import { ScaleYValueProps } from 'sleepio/components/screens/SessionsScreen/utils/scaler';

export const ButtonContainerWithMinMax = styled.View<{ type?: ButtonTypes }>`
  min-width: ${getTheme('controls[type].minWidth')}px;
  max-width: ${getTheme('controls[type].maxWidth')}px;
  width: 100%;
  align-self: center;
`;
export const ButtonContainerWithHalfWidth = styled.View<{
  type?: ButtonTypes;
  adjustedWidth?: number;
}>`
  flex-direction: row;
  width: 50%;
  align-self: center;
  justify-content: center;
`;
ButtonContainerWithMinMax.defaultProps = {
  type: ButtonTypes.PRIMARY_BUTTON,
};

type LayoutProps = { type?: ButtonTypes } & MarkOptional<
  ScaleYValueProps,
  'scaleYValue'
>;

export const ButtonContainerFixedWidth = styled.View<LayoutProps>`
  width: ${getTheme('controls[type].width')}px;
  min-width: ${getTheme('controls[type].width')}px;
  max-width: ${getTheme('controls[type].width')}px;
  align-self: center;
`;
ButtonContainerFixedWidth.defaultProps = {
  type: ButtonTypes.PRIMARY_BUTTON,
};

export const getWidth = (props: LayoutProps): number => {
  const { type = ButtonTypes.PRIMARY_BUTTON } = props;
  const themeWidth = sleepioTheme.controls[type].width;
  if (typeof props.scaleYValue === 'number') {
    const scaledValue = scalerVertical(themeWidth)(props as ScaleYValueProps);
    return sleepioTheme.controls[type].minWidth > scaledValue
      ? sleepioTheme.controls[type].minWidth
      : scaledValue;
  }
  return themeWidth;
};

export const ButtonContainerFixedWidthScalable = styled.View<LayoutProps>`
  min-width: ${getWidth}px;
  max-width: ${getWidth}px;
  width: ${getWidth}px;
  align-self: center;
  margin-bottom: 0;
`;

ButtonContainerFixedWidth.defaultProps = {
  type: ButtonTypes.PRIMARY_BUTTON,
};
