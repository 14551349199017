import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { fire_ui_events } from '@bighealth/api/Event/v1/fire_ui_events';

// import * as ProductVersionStream from '@bighealth/api/ProductStreamVersion/v1';
import { useQueryProductStreamVersion } from 'components/Footer/hooks/useQueryProductStreamVersion';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useHistory } from 'cross-platform/react-router';
import { sendSceneAnalytics } from 'lib/api/analytics/sendSceneAnalytics';
import { useGetURL } from 'lib/api/analytics/useGetURL';
import { useQueryProduct, useQueryProgram } from 'lib/api/reactQueryHelpers';

export const useQuerySendSceneAnalytics = (): UseQueryResult<
  void,
  APIErrorResponse
> => {
  const programId = useQueryProgram().data?.result.id as number;
  const productId = useQueryProduct().data?.result.id as number;
  const getUrl = useGetURL();
  const pathname = useHistory().location.pathname;
  const { sceneSetGraphId, sceneSetId, sceneId } = useSafeParams();

  const productVersionStreamResult = useQueryProductStreamVersion.get_latest_version(
    { meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN } }
  );

  const result = useQuery<void, APIErrorResponse>({
    queryKey: [fire_ui_events.queryKey, pathname],
    enabled:
      typeof productId === 'number' &&
      typeof programId === 'number' &&
      !!productVersionStreamResult?.data?.result,
    queryFn: async () => {
      const url = await getUrl();
      return sendSceneAnalytics({
        programId,
        productId,
        sceneSetGraphId,
        sceneSetId: sceneSetId as number,
        sceneId: sceneId || 0,
        url,
        productVersionStream: productVersionStreamResult?.data?.result,
      });
    },
    cacheTime: 0,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: 0,
  });
  return result;
};
