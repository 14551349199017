import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import {
  CONTENT_MIN_REFERENCE_DIMENSION,
  useScreenResponsiveLayoutContext,
} from 'components/ResponsiveLayout';
import { isLandscape } from 'lib/isLandscape';

export const useIsSettingsNarrow = (): boolean => {
  const screenOrientation = useScreenOrientation();
  const { screenWidth, screenHeight } = useScreenResponsiveLayoutContext();

  return isLandscape(screenOrientation)
    ? screenHeight <= CONTENT_MIN_REFERENCE_DIMENSION
    : screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION;
};
