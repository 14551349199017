import { FITBIT_AUTH_SCOPES, FITBIT_OAUTH2_PAGE_URL } from '../../constants';
import { getFitbitCallbackUri } from '../getFitbitCallbackUri';

export const getFitbitRedirectUrl = (
  productReference: string,
  redirectScreen: string,
  clientId: string
): string => {
  const scopeParam = `scope=${FITBIT_AUTH_SCOPES.join('+')}`;
  const responseTypeParam = `response_type=code`;
  const clientIdParam = `client_id=${clientId}`;
  const redirectUri = encodeURIComponent(
    getFitbitCallbackUri(productReference, redirectScreen)
  );

  return `${FITBIT_OAUTH2_PAGE_URL}?${responseTypeParam}&${scopeParam}&${clientIdParam}&redirect_uri=${redirectUri}`;
};
