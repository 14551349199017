import { Accessibility } from '@bighealth/types/src';

import { AccessibilityRoles, Roles } from './enums';

// TODO: tabIndex isn´t the best idea to use on heading tags, but was need it to solve accessibility issues on windows Chrome
const H1: Accessibility = {
  role: Roles.HEADING,
  accessibilityRole: AccessibilityRoles.HEADER,
  level: 1,
  tabIndex: -1,
};

const H2: Accessibility = {
  role: Roles.HEADING,
  accessibilityRole: AccessibilityRoles.HEADER,
  level: 2,
  tabIndex: -1,
};

const H3: Accessibility = {
  role: Roles.HEADING,
  accessibilityRole: AccessibilityRoles.HEADER,
  level: 3,
  tabIndex: -1,
};

const Button: Accessibility = {
  role: Roles.BUTTON,
  accessibilityRole: AccessibilityRoles.BUTTON,
};

const P: Accessibility = {
  role: Roles.PARAGRAPH,
  accessibilityRole: AccessibilityRoles.PARAGRAPH,
};

export default { H1, H2, H3, Button, P };
