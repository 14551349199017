// TODO: move the Text from the sceneset-components to the components folder as a shared folder
// WHEN: during Daylight Rebuild
// WHY: we are using the Scene Component Text because can be returned by the backend
import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { Formik } from 'formik';

import { PracticeItemPayload } from '@bighealth/types';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
// TODO: move the Text from the sceneset-components to the components folder as a shared folder
// WHEN: during Daylight Rebuild
// WHY: we are using the Scene Component Text because can be returned by the backend
import { Text } from 'components/sceneset-components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { LockedButton, Pill, PlayButton } from 'daylight/components';
import { useGetSvgDimensions, useTransformContentStyle } from 'daylight/hooks';
import { noOpFunction } from 'lib/noOpFunction';

import { usePracticeLibraryScreenContext } from '../../PracticeLibraryScreenProvider';

import {
  BackgroundImage,
  ButtonWrapper,
  ContentTextWrapper,
  ContentWrapper,
  HeaderWrapper,
  PillWrapper,
  Wrapper,
} from './PracticeItem.styled';

export const PracticeItem = ({
  practiceItem,
  isLastItemInGroup,
  locked,
}: {
  practiceItem: PracticeItemPayload;
  isLastItemInGroup: boolean;
  locked: boolean;
}): ReactElement => {
  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();

  const practiceLibraryScreenContext = usePracticeLibraryScreenContext();
  const { isSomeActionInProgress, setIsSomeActionInProgress } =
    practiceLibraryScreenContext || {};

  const srcBackgroundImage =
    practiceItem.preview_asset?.sourceProps.storage_url;

  const titleStyle = practiceItem.title.style || {};
  const descriptionStyle = practiceItem.description?.style || {};

  const buttonDimensionProps = useGetSvgDimensions({
    width: transformStyle(62),
    height: transformStyle(62),
  });

  return (
    <Wrapper
      {...roles('practiceItem')}
      sideAndBottomMargin={transformStyle(16)}
      useBottomMargin={!isLastItemInGroup}
      locked={locked || !!isSomeActionInProgress}
    >
      {srcBackgroundImage ? (
        <HeaderWrapper height={transformVerticalStyle(170)}>
          <BackgroundImage
            {...roles('BackgroundImage')}
            src={srcBackgroundImage}
          />
          <PillWrapper
            marginBottom={transformVerticalStyle(21)}
            marginLeft={transformStyle(24)}
          >
            {practiceItem.labels &&
              practiceItem.labels.map((label, index) => {
                return <Pill key={index} textComponent={label} />;
              })}
          </PillWrapper>
        </HeaderWrapper>
      ) : null}

      <ContentWrapper
        paddingVertical={transformStyle(16)}
        paddingHorizontal={transformVerticalStyle(24)}
        useTopBorderRadius={srcBackgroundImage === undefined}
      >
        <ContentTextWrapper
          marginRight={transformStyle(14)}
          paddingVertical={transformVerticalStyle(4)}
        >
          <Text
            text={practiceItem.title.text}
            style={{
              ...titleStyle,
              fontSize: transformStyle(titleStyle.fontSize || 21),
              fontWeight: titleStyle.fontWeight || 700,
              marginTop: transformVerticalStyle(4),
              marginBottom: transformVerticalStyle(4),
            }}
          />
          {practiceItem.description?.text && (
            <Text
              text={practiceItem.description.text}
              style={{
                ...descriptionStyle,
                fontSize: transformStyle(descriptionStyle.fontSize || 16),
                fontWeight: descriptionStyle.fontWeight || 500,
              }}
            />
          )}
        </ContentTextWrapper>
        <ButtonWrapper>
          {!locked ? (
            <Formik initialValues={[]} onSubmit={noOpFunction}>
              <PlayButton
                {...practiceItem.button}
                style={{
                  ...(Array.isArray(practiceItem.button?.style)
                    ? StyleSheet.flatten(practiceItem.button?.style)
                    : practiceItem.button?.style),
                  width: buttonDimensionProps.width,
                  height: buttonDimensionProps.height,
                }}
                onPress={noOpFunction}
                {...(!!setIsSomeActionInProgress && {
                  setDisabled: setIsSomeActionInProgress,
                  disabled: !!isSomeActionInProgress,
                })}
              />
            </Formik>
          ) : (
            <LockedButton {...buttonDimensionProps} />
          )}
        </ButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
