/**
 * Response sourced directly from:
 *    WHAT:     SleepDiary.get_diary_entry_form()
 *    WHERE:    Local
 *    WHEN:     14th Jan 2021
 *    WHY:      Needed an example of a question generated by the
 *              "question creation script" that represented duration
 *              as minutes (to test min-to-ms transformation)
 *    NOTES:
 *      - Added 15 minutes and removed a bunch of unneeded values
 *
 */
const getFallAsleepTotalTimeAsDropdownQuestion = () => ({
  questionProps: {
    created_utc: {
      $datetime: '2020-09-11T04:16:37.376035',
    },
    updated_utc: {
      $datetime: '2020-11-09T20:20:21.784329',
    },
    deleted_utc: null,
    id: 124,
    semantic_id: 'sleep_efficiency_to_fall_asleep_total_time',
    uuid: 'b9a4a9f2-c2d8-43ae-b24e-d09086a6cc80',
    language: 'en',
    version: 1,
    question_pre_text: null,
    question_title: 'How long did it take you to fall asleep?',
    response_pre_text: null,
    visual_representation_json: '{}',
    visual_representation_markup: '<markup/>',
    optional: false,
    response_config: {
      response_options: [
        {
          id: 0,
          score: 10,
          value: 0,
          semantic_id: '0',
          display_text: '0 mins',
          max_response: 99999,
          min_response: 0,
        },
        {
          // Not generated by question creation script
          id: 15,
          score: 11,
          value: 15,
          semantic_id: '15',
          display_text: '15 mins',
          max_response: 99999,
          min_response: 0,
        },
        {
          id: 30,
          score: 11,
          value: 30,
          semantic_id: '30',
          display_text: '30 mins',
          max_response: 99999,
          min_response: 0,
        },
        {
          id: 60,
          score: 11,
          value: 60,
          semantic_id: '60',
          display_text: '1 hour',
          max_response: 99999,
          min_response: 0,
        },
        {
          id: 120,
          score: 21,
          value: 120,
          semantic_id: '120',
          display_text: '2 hours',
          max_response: 99999,
          min_response: 0,
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '0': {
            type: 'number',
            maximum: 0,
            minimum: 0,
          },
          '15': {
            // Not generated by question creation script
            type: 'number',
            maximum: 15,
            minimum: 15,
          },
          '30': {
            type: 'number',
            maximum: 30,
            minimum: 30,
          },
          '60': {
            type: 'number',
            maximum: 60,
            minimum: 60,
          },
          '120': {
            type: 'number',
            maximum: 120,
            minimum: 120,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: {
      $ResponseType: 'NUMBER',
    },
    display_name: 'sleep_efficiency_to_fall_asleep_total_time',
    primary_key: 124,
    previous_responses: [
      {
        multi_select_option_id: 60,
        value: 60,
        semantic_id: '60',
      },
    ],
  },
  component: 'Dropdown',
});

export { getFallAsleepTotalTimeAsDropdownQuestion };
