/**
 * NOTE Initially created as react-datepicker adds milliseconds
 * Something like:
 * @see {@link https://github.com/Hacker0x01/react-datepicker/issues/1991}
 */
export const getDateWithoutMilliseconds = (date: Date | null): Date | null => {
  if (date === null) {
    return null;
  }
  date.setMilliseconds(0);
  return date;
};
