import React from 'react';

import {
  ScalingContext,
  ScalingContextProvider,
} from 'components/ResponsiveLayout';
import { addStory } from 'lib/story-books';

import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from '.';

addStory('heading ScalingContext.Modal', () => (
  <ScalingContextProvider scalingContext={ScalingContext.Modal}>
    <Heading1 text="Heading1" />
    <Heading2 text="Heading2" />
    <Heading3 text="Heading3" />
    <Heading4 text="Heading4" />
    <Heading5 text="Heading5" />
    <Heading6 text="Heading6" />
  </ScalingContextProvider>
));
