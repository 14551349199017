import { Query } from 'react-query';
/**
 * @see {@link ./README.md} for more info
 */
const checkMetaCommon = (query: Query): boolean | undefined => {
  const queryKey = query.options?.queryKey;
  const [firstQueryKeySegment] = Array.isArray(queryKey)
    ? queryKey
    : [queryKey];
  switch (firstQueryKeySegment) {
    // Don't show the loading spinner when sending analytics in background.
    // Therefore we ignore the call in the fetching count if the analytics query key is found.
    case 'Analytics/fire_ui_events':
      return false;
    default:
  }

  return undefined;
};

export { checkMetaCommon };
