import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Switch } from 'react-native';

import {
  NOTIFICATION_TYPE,
  NotificationViewArgs,
} from 'components/LocalNotifications/NotificationsView';
import { useTransformContentStyle } from 'daylight/hooks';

import { Constant } from '../Constants';
import { SettingsContentText } from '../SettingsScreen.styled';

import { NotificationsSettingsScreen } from './notification/NotificationsSettingsScreen';
import { NotificationsSettingsScreenWrapper } from './NotificationsSettingsScreenWrapper';
import * as Styled from './NotificationsView.styled';

const PracticeReminder = (
  props: PropsWithChildren<NotificationViewArgs>
): React.ReactElement => {
  const transformStyle = useTransformContentStyle();
  const notificationState =
    props.scheduleNotificationState[NOTIFICATION_TYPE.DAILY_REMINDER];
  const [date, setDate] = useState(notificationState.date);

  useEffect(() => {
    setDate(notificationState.date);
  }, [notificationState.date, props.scheduleNotificationState]);

  const switchHandler = useCallback(() => {
    if (notificationState.isReminderOn) {
      props.cancelNotification({
        type: NOTIFICATION_TYPE.DAILY_REMINDER,
        data: date,
      });
    } else {
      props.scheduleNotification({
        type: NOTIFICATION_TYPE.DAILY_REMINDER,
        data: date,
      });
    }
  }, [date, notificationState.isReminderOn, props]);

  return (
    <NotificationsSettingsScreenWrapper visualState={props.visualState}>
      <Styled.UIScreenView>
        <>
          <Styled.SwitchToggleView>
            <SettingsContentText
              fontSize={transformStyle(18)}
              marginBottom={transformStyle(10)}
              lineHeight={transformStyle(24.3)}
            >
              {Constant.Notification.DAILY_APP_REMINDER}
            </SettingsContentText>
            <Switch
              testID="Notifications-toggle"
              onValueChange={switchHandler}
              value={notificationState.isReminderOn}
              disabled={notificationState.isReminderDisabled}
            />
          </Styled.SwitchToggleView>

          <NotificationsSettingsScreen
            openSettingsScreen={props.openSettingsScreen}
            date={date}
            uiState={props.visualState}
            scheduleNotification={props.scheduleNotification}
            setDate={setDate}
          />
        </>
      </Styled.UIScreenView>
    </NotificationsSettingsScreenWrapper>
  );
};

export { PracticeReminder };
