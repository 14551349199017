import React, { memo, ReactElement } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { DarkGrayBox, WhiteBox } from 'daylight/components/ghosts/ghosts';
import { CtaCirclePlayIcon } from 'daylight/components/icons';
import { useTransformContentStyle } from 'daylight/hooks';

const Wrapper = styled.View`
  flex: 1;
  justify-content: flex-end;
  background-color: #ddd;
`;
Wrapper.displayName = 'TodayScreenGhostWrapper';

const Row = styled.View`
  flex-direction: row;
`;
Row.displayName = 'Row';

const ButtonWrapper = styled.View`
  justify-content: center;
  align-items: center;
  flex: 1;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const Ghost = memo(
  (): ReactElement => {
    const transformV = useTransformContentStyleVertical();
    const transform = useTransformContentStyle();
    return (
      <Wrapper
        {...roles('todayScreenGhost')}
        style={{
          paddingHorizontal: transform(24),
          paddingTop: transformV(24),
          paddingBottom: transformV(93),
        }}
      >
        <View>
          <Row>
            <WhiteBox
              height={transformV(29)}
              width="20%"
              style={{
                marginRight: transform(7),
                marginBottom: transformV(13),
              }}
            />
            <WhiteBox
              height={transformV(29)}
              width="40%"
              style={{
                marginRight: transform(7),
                marginBottom: transformV(13),
              }}
            />
          </Row>
          <Row>
            <View style={{ width: '75%' }}>
              <DarkGrayBox
                height={transformV(24)}
                width="100%"
                style={{ marginBottom: transformV(13) }}
              />
              <DarkGrayBox
                height={transformV(48)}
                width="100%"
                style={{ marginBottom: transformV(13) }}
              />
            </View>
            <ButtonWrapper>
              <CtaCirclePlayIcon
                height={transform(56)}
                width={transform(56)}
                viewBox="0 0 56 56"
                circleColor="black"
                triangleColor="white"
              />
            </ButtonWrapper>
          </Row>
        </View>
      </Wrapper>
    );
  }
);

Ghost.displayName = 'TodayScreenGhost';

export { Ghost };
