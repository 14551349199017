import styled from 'styled-components/native';

import { DOMAIN } from '../components/SessionStatus/styled/constants';
export { BottomRow } from './BottomRow';
export * from './Content';
export * from './HeadingText';
export * from './SectionHeader';
export * from 'components/sceneset-components/Text';

export const Grid = styled.View.attrs({
  width: '100%',
})`
  flex-basis: 100%;
  width: 100%;
  margin-left: 0;
`;
Grid.displayName = `${DOMAIN}.Grid`;

export const TopRow = styled.View``;
TopRow.displayName = `${DOMAIN}.TopRow`;

export const ContentWrapper = styled.View`
  flex-grow: 1;
`;
ContentWrapper.displayName = `${DOMAIN}.ContentWrapper`;
