import { Question } from '@bighealth/types/src';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';

// Check if there are questions for every item,
// as question updates lag items
const questionsAreLoaded = (
  items: Record<QuestionId, DropdownItem[]>,
  questions: Question[]
): boolean => {
  const itemsKeys = Object.keys(items);
  const storedQuestionIds = questions.map(({ semantic_id }) => semantic_id);
  return itemsKeys.every(key => storedQuestionIds.includes(key));
};

export { questionsAreLoaded };
