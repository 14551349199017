import styled from 'styled-components/native';

import TextInput from 'components/TextInput';

export const MultiLineInput = styled(TextInput)<{
  hasErrors?: boolean;
  isFocused?: boolean;
}>`
  font-size: 16px;
  font-style: normal;
  border-radius: 8px;
  text-decoration-line: none;
  box-shadow: ${({ isFocused, ...props }) =>
    isFocused
      ? `0px 0px 4px ${props.theme.color.selectedPink};`
      : `0px 0px 0px;`};
  color: ${props => props.theme.color.text.primary};
  background-color: ${props =>
    props.value
      ? props.theme.color.white
      : props.theme.color.disabledInputGrey};
  border: ${({ hasErrors, isFocused, ...props }) =>
    hasErrors
      ? `solid 1.5px ${props.theme.color.error};`
      : isFocused
      ? `solid 1px ${props.theme.color.primary};`
      : `1px solid ${props.theme.color.inputOutlineGrey}`};
`;
