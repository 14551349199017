import * as React from 'react';

import { CardList, Props } from '../SessionList';

import { Card } from './styled';

/**
 * (Horizontal) scrolling list of OptionalList cards
 *
 * @param {Props} props
 */
export const OptionalList = (props: Props): React.ReactElement => (
  <CardList {...props} variant="optional_content_list" card={Card} />
);
