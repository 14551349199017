import React, { ComponentProps, ReactElement, useState } from 'react';
import { Text } from 'react-native';

import { ResponseOption } from '@bighealth/types';

import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { Content } from 'components/layout/Content';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { mockRes17Sep2020 } from 'components/SleepDiaryForm/__mocks__/17Sep2020';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';
import { QuestionId } from 'state/question-response/actions';

import { getFlowState } from '../../FlowingForm/helpers/getFlowState';
import { Form, SleepDiaryOptionalProps } from '..';

type FlowState = ReturnType<typeof getFlowState>;
type Props = ComponentProps<typeof Form>;

export const SleepDiary17Sep2020 = ({
  onSubmit,
}: SleepDiaryOptionalProps): ReactElement | null => {
  const [submitted, setSubmitted] = useState<Record<string, ResponseOption[]>>(
    {}
  );
  const [items, setItems] = useState<Record<QuestionId, DropdownItem[]>>({});
  const [flowState, setFlowState] = useState<FlowState | undefined>();

  const sceneSet = useFakeStorybookSceneSet();
  if (!sceneSet) {
    return null;
  }

  return (
    <>
      <Text>Mock data source SleepDiary.get_diary_entry_form</Text>
      <Text> FROM: SleepDiary.get_diary_entry_form()</Text>
      <Text> WHERE: Stage</Text>
      <Text> WHEN: 17th Sep 2020</Text>
      <Form
        {...mockRes17Sep2020.result}
        {...flowState}
        fieldProps={flowState}
        onSubmit={(values: Record<React.ReactText, ResponseOption[]>): void => {
          setSubmitted(values);
          onSubmit?.(values);
        }}
        onValidate={
          (items => {
            setFlowState(getFlowState(items));
            setItems(items);
          }) as Props['onValidate']
        }
      />
      <Content mono>{JSON.stringify({ items }, null, 2)}</Content>
      <Content mono>{JSON.stringify({ flowState }, null, 2)}</Content>
      <Content mono>{JSON.stringify({ submitted }, null, 2)}</Content>
    </>
  );
};

addStory('sleep-diary/Form With17Sep2020Defaults', () => (
  <EphemeralStateProvider>
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <SleepDiary17Sep2020 />
    </CrossPlatformThemeProvider>
  </EphemeralStateProvider>
));
