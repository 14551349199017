import { HttpStatusCode } from '@bighealth/api';

const isFetchError = (statusCode: HttpStatusCode | 0): boolean => {
  // https://fetch.spec.whatwg.org/#concept-network-error
  const unknownError = statusCode === 0;
  const httpStatusCodeError = statusCode >= 400;
  return unknownError || httpStatusCodeError;
};

export default isFetchError;
