import React from 'react';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';

import {
  useTransformContentStyle,
  useTransformContentStyleVertical,
} from 'components/ResponsiveLayout';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

import {
  BackgroundContainer,
  HeadingText,
  QuestionWrapper,
  Row,
  TitleText,
  Wrapper,
} from '../../styled';

import { SleepDiaryInModal } from './components/SleepDiaryInModal';
import { ResponsesRow } from './Response/styled/styled';
import { createRenderRequest } from './utils/createRenderRequest';
import { useRenderRequest } from './utils/renderRequest';
import { useReloadHome } from './utils/useReloadHome';
import { Response } from './Response';

type DiaryQuestionProps = RoleProps & {
  data: get_user_session_screen_data.SleepDiaryQuestion;
};
type MaybeRequest =
  | SleepDiaryPayloads['get_diary_entry_form']['request']
  | undefined;

const DiaryQuestion = (props: DiaryQuestionProps): React.ReactElement => {
  const [renderRequest, setRenderRequest] = useRenderRequest<MaybeRequest>();
  const reloadHome = useReloadHome();
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);

  return (
    <>
      {typeof renderRequest !== 'undefined' ? (
        <SleepDiaryInModal
          {...roles('SleepDiaryInModal')}
          visible={!!renderRequest}
          onClose={() => setRenderRequest(undefined)}
          onSuccessfulSubmit={() => {
            setRenderRequest(undefined);
            reloadHome();
          }}
          renderRequest={renderRequest}
        />
      ) : null}
      <Wrapper>
        <BackgroundContainer
          style={{ width: '100%' }}
          {...roles('DiaryQuestion.BackgroundContainer')}
        >
          <QuestionWrapper>
            <Row>
              <TitleText
                {...roles('DiaryTitle')}
                scaleValue={scaleValue}
                scaleYValue={scaleYValue}
                {...props.data.title}
              />
            </Row>
            <Row>
              <HeadingText
                {...roles('DiaryHeader')}
                scaleValue={scaleValue}
                scaleYValue={scaleYValue}
                {...props.data.heading}
              />
            </Row>
            <ResponsesRow loading={!!renderRequest}>
              {props.data.response_options.map(opt => (
                <Response
                  key={opt.id}
                  {...opt}
                  onPress={() => {
                    if (
                      typeof renderRequest === 'undefined' &&
                      typeof props.data.diary_request === 'object' &&
                      typeof opt.value === 'number'
                    ) {
                      setRenderRequest(
                        createRenderRequest(props.data.diary_request, opt.value)
                      );
                    }
                  }}
                />
              ))}
            </ResponsesRow>
          </QuestionWrapper>
        </BackgroundContainer>
      </Wrapper>
    </>
  );
};
export { DiaryQuestion };
