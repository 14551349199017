// requestOsNotificationPermissions
/**
 * No-op on web; Only relevant in ios|android files.
 *
 * @see requestOsNotificationPermissions.ios|android
 */

import { NO_OP_MESSAGE__NATIVE } from '../utils/constants';

import { RequestOsNotificationPermissions } from './types';

export const requestOsNotificationPermissions: RequestOsNotificationPermissions = async () => {
  throw Error(NO_OP_MESSAGE__NATIVE); // optional
};
