import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { get_home_screen_data } from '@bighealth/api/DaylightHomeScreen/v1/get_home_screen_data';

import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { SECOND } from 'lib/durations';

import { useQueryProduct } from './useQueryProduct';
import { useQueryProgram } from './useQueryProgram';

const DAYLIGHT_PRODUCT_ID = 2;

export const useQueryDaylightHomeScreenData = (
  screenReference?: string
): UseQueryResult<get_home_screen_data.Response, APIErrorResponse> => {
  const productId = useQueryProduct()?.data?.result.id;
  const programId = useQueryProgram().data?.result?.id;

  const result = useQuery<get_home_screen_data.Response, APIErrorResponse>({
    queryKey: get_home_screen_data.queryKey,
    queryFn: () =>
      get_home_screen_data({
        product_id: productId || DAYLIGHT_PRODUCT_ID,
        program_id: programId as number,
        screen_reference: screenReference,
      }),
    enabled: typeof programId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // We keep in memory the result of the query
    // because it is unlikely that the today screen configuration will change during this time
    cacheTime: 3 * SECOND,
    // cacheTime: 0,
    retry: false,
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });
  return result;
};
