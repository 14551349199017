import { DebugAction, DebugActions } from 'state/debug/actions';

export interface DebugState {
  screenReference?: string;
}

export const initialDebugState: DebugState = {};

export const debugReducer = (
  state = initialDebugState,
  action: DebugAction
): DebugState => {
  const { type, payload } = action;
  switch (type) {
    case DebugActions.SET_SCREEN_REFERENCE:
      return {
        ...state,
        screenReference: payload,
      };
    case DebugActions.CLEAR_SCREEN_REFERENCE:
      return {
        ...state,
        screenReference: undefined,
      };
    default:
      return state;
  }
};
