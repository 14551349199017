import { adjust_with_change_action_and_increment } from '@bighealth/api/SleepWindow/v1';

import { adjustWithChangeActionAndIncrement } from 'lib/api';
import * as reporter from 'lib/reporter';

async function submitExtendSleepWindow({
  changeAction,
  minutesIncrement,
  sceneSetGraphId,
}: {
  changeAction: string;
  minutesIncrement: number;
  sceneSetGraphId: number;
}): Promise<
  [adjust_with_change_action_and_increment.Result | null, Error | null]
> {
  try {
    const response = await adjustWithChangeActionAndIncrement({
      changeAction: changeAction,
      minutesIncrement: minutesIncrement,
      sceneSetGraphId: sceneSetGraphId,
    });
    if (response.status_code !== 200) {
      reporter.log('Error when trying to extend the sleep window');
      return [null, new Error('Error when trying to extend the sleep window')];
    }
    return [response.result, null];
  } catch (e) {
    reporter.log(e?.message, e);
    return [null, e];
  }
}

export { submitExtendSleepWindow };
