import { PersistPartial } from 'redux-persist/es/persistReducer';

import { DomainIDs } from 'common/constants/enums';
import {
  ContentModalState,
  initialContentModalState,
} from 'state/content-modal/reducer';
import { DebugState, initialDebugState } from 'state/debug/reducer';
import {
  initialNotificationsState,
  NotificationsState,
} from 'state/notifications/reducer';
import {
  initialQuestionPathState,
  QuestionPathState,
} from 'state/question-path/reducer';
import {
  initialQuestionResponseState,
  QuestionResponseState,
} from 'state/question-response/reducer';
import {
  initialSleepDiaryModalState,
  SleepDiaryModalState,
} from 'state/sleep-diary-modal/reducer';
import { initialUserState, UserState } from 'state/user/reducer';

import {
  initialRedirectorState,
  RedirectorState,
} from '../../crossPlatform/react-router/Router/utils/Redirector/state/reducer';
import { DownloaderState, initialDownloaderState } from '../downloader/reducer';
import { initialPlayerState, PlayerState } from '../player/reducer';

export interface GlobalState {
  [DomainIDs.USER]: UserState & PersistPartial;
  [DomainIDs.PLAYER]: PlayerState & PersistPartial;
  [DomainIDs.DOWNLOADER]: DownloaderState;
  [DomainIDs.QUESTION_RESPONSE]: QuestionResponseState;
  [DomainIDs.QUESTION_PATH]: QuestionPathState;
  [DomainIDs.REDIRECTOR]: RedirectorState;
  [DomainIDs.CONTENT_MODAL]: ContentModalState;
  [DomainIDs.NOTIFICATIONS]: NotificationsState & PersistPartial;
  [DomainIDs.SLEEP_DIARY_MODAL]: SleepDiaryModalState;
  [DomainIDs.DEBUG]: DebugState;
}

export const initialState: GlobalState = {
  [DomainIDs.USER]: initialUserState,
  [DomainIDs.PLAYER]: initialPlayerState,
  [DomainIDs.DOWNLOADER]: initialDownloaderState,
  [DomainIDs.QUESTION_RESPONSE]: initialQuestionResponseState,
  [DomainIDs.QUESTION_PATH]: initialQuestionPathState,
  [DomainIDs.REDIRECTOR]: initialRedirectorState,
  [DomainIDs.CONTENT_MODAL]: initialContentModalState,
  [DomainIDs.NOTIFICATIONS]: initialNotificationsState,
  [DomainIDs.SLEEP_DIARY_MODAL]: initialSleepDiaryModalState,
  [DomainIDs.DEBUG]: initialDebugState,
};

export default initialState;
