import { useSafeParams } from 'components/Routes/useSafeParams';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { PLATGEN_COMMUNITY_URL } from 'config/envVars';
import { isMobileWeb } from 'config/isMobileWeb';
import { useLazyQueryLogout, useQueryProduct } from 'lib/api/reactQueryHelpers';

import { NavMenuConfig } from './types';

/**
 * Hook that provide the configuration for the Navigation Bar.
 * Based on the Screen Width, we return different configurations
 * in order to gate the Mobile Web experience to the Native Application
 */
export const useGetNavigationConfig = (): NavMenuConfig | null => {
  const productResult = useQueryProduct({
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });
  const { productReference } = useSafeParams();

  const logout = useLazyQueryLogout();
  const getFullPath = (slug: string): string => {
    return `/${productReference}/${slug}`;
  };

  if (!productResult?.data?.result.id) {
    return null;
  }

  if (isMobileWeb) {
    return [
      {
        label: 'Sessions',
        to: getFullPath('home'),
        type: 'category',
      },
      {
        label: 'Sleep Diary',
        to: getFullPath('sleep-diary'),
        type: 'category',
      },
      {
        label: 'Case File',
        subMenu: [
          {
            label: 'Downloads',
            to: getFullPath('downloads'),
            type: 'subcategory',
          },
        ],
      },
      {
        label: 'Resources',
        to: getFullPath('resources'),
        type: 'category',
      },
      {
        label: 'Sign Out',
        onPress: async () => {
          await logout();
        },
        type: 'category',
      },
    ];
  }

  return [
    {
      label: 'Sessions',
      to: getFullPath('home'),
      type: 'category',
    },
    {
      label: 'Sleep Diary',
      to: getFullPath('sleep-diary'),
      type: 'category',
    },
    {
      label: 'Case File',
      subMenu: [
        {
          label: 'Progress',
          to: getFullPath('casefile/progress'),
          type: 'subcategory',
        },
        {
          label: 'Schedule',
          to: getFullPath('schedule'),
          type: 'subcategory',
        },
        {
          label: 'Downloads',
          to: getFullPath('downloads'),
          type: 'subcategory',
        },
      ],
    },
    {
      label: 'Library',
      to: getFullPath('library'),
      type: 'category',
    },
    {
      label: 'Community',
      to: `${PLATGEN_COMMUNITY_URL}/complete/sleepio`,
      type: 'category',
      external: true,
    },
    {
      label: 'Account',
      subMenu: [
        {
          label: 'Settings',
          to: getFullPath('settings'),
          type: 'subcategory',
        },
        {
          label: 'Resources',
          to: getFullPath('resources'),
          type: 'subcategory',
        },
        {
          label: 'Sign Out',
          onPress: async () => {
            await logout();
          },
          type: 'subcategory',
        },
      ],
    },
  ];
};
