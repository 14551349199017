import { Question } from '@bighealth/types';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { Config } from 'components/SleepDiaryForm/components/FlowingForm/helpers/getFlowState/getInitialValuesAndOptions';
import { getFinalAwakeningFromTryToSleep } from 'components/SleepDiaryForm/components/FlowingForm/helpers/getFlowState/getInitialValuesAndOptions/rules/getFinalAwakeningFromTryToSleep';
import { getTimeOutOfBedConfigFromFinalAwakening } from 'components/SleepDiaryForm/components/FlowingForm/helpers/getFlowState/getInitialValuesAndOptions/rules/getTimeOutOfBedConfigFromFinalAwakening';
import { getTryToSleepConfigFromIntoBed } from 'components/SleepDiaryForm/components/FlowingForm/helpers/getFlowState/getInitialValuesAndOptions/rules/getTryToSleepConfigFromIntoBed';
import { parseInitialValue } from 'components/SleepDiaryForm/components/Form/helpers/parseInitialValues';
import {
  AWAKENINGS_IN_NIGHT,
  AWAKENINGS_TOTAL_TIME,
  SLEEP_QUALITY,
  TIME_FINAL_AWAKENING,
  TIME_GET_OUT_OF_BED,
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
  TO_FALL_ASLEEP_TOTAL_TIME,
} from 'components/SleepDiaryForm/constants';
import { mergeDeepAndByIndex } from 'lib/utils/mergeDeepAndByIndex';
import { QuestionId } from 'state/question-response/actions';

// I have to parse and calculate values in a very specific order due to the data range of certain fields, being dependent on the field "above" it in the form.
// For example, the date time dropdowns have a value returned from the api that is in the format HH:MM:SS with no actual date information.
// This function gets the values of the previous form value dates to extrapolate the ranges for the next dates in the form.
// Fields need to be calculated in this order:

const formOrder = [
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
  TO_FALL_ASLEEP_TOTAL_TIME,
  AWAKENINGS_IN_NIGHT,
  AWAKENINGS_TOTAL_TIME,
  TIME_FINAL_AWAKENING,
  TIME_GET_OUT_OF_BED,
  SLEEP_QUALITY,
];

export const getInitialValues = (
  initialQuestionProps: Record<QuestionId, Question | undefined>
): Record<QuestionId, Config> => {
  const initialValuesAndOptions: Record<QuestionId, Config> = {};
  const inputsMap: Record<QuestionId, DropdownItem[]> = {};
  for (const questionId of formOrder) {
    let tempMap;
    switch (questionId) {
      case TIME_TRY_TO_SLEEP:
        tempMap = getTryToSleepConfigFromIntoBed(inputsMap) || {
          [questionId]: { questionProps: {} },
        };
        break;
      case TIME_FINAL_AWAKENING:
        tempMap = getFinalAwakeningFromTryToSleep(inputsMap) || {
          [questionId]: { questionProps: {} },
        };
        break;
      case TIME_GET_OUT_OF_BED:
        tempMap = getTimeOutOfBedConfigFromFinalAwakening(inputsMap) || {
          [questionId]: { questionProps: {} },
        };
        break;
      default:
        tempMap = {
          [questionId]: { questionProps: {} },
        };
    }
    // set the initial value and prop overrides
    initialValuesAndOptions[questionId] = {
      initialValue: parseInitialValue(
        mergeDeepAndByIndex(
          initialQuestionProps[questionId],
          tempMap[questionId].questionProps
        )
      ),
      questionProps: tempMap[questionId].questionProps,
    };
    // create a faux input map to help the next question calculate date ranges
    inputsMap[questionId] = [
      {
        value: initialValuesAndOptions[questionId].initialValue || null,
        isSelected: true,
      },
    ];
  }
  return initialValuesAndOptions;
};
