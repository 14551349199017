import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { APIErrorResponse } from '@bighealth/api';
import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { ThrobberBehaviorMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { useQueryWithIsFetchingMeta } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/hooks/useQueryWithIsFetchingMeta';
import { assignUserABTestVariants } from 'state/user/actions';

import { useQueryProgram } from './useQueryProgram';

export const useQueryAllForSessionScreen = (options?: {
  meta: ThrobberBehaviorMeta | undefined;
}): UseQueryResult<get_user_session_screen_data.Response, APIErrorResponse> => {
  const dispatch = useDispatch();
  const programId = useQueryProgram().data?.result?.id;
  const result = useQueryWithIsFetchingMeta<
    get_user_session_screen_data.Response,
    APIErrorResponse
  >({
    queryKey: get_user_session_screen_data.queryKey,
    queryFn: () =>
      get_user_session_screen_data({
        program_id: programId as number,
        // If session_screen_version is not present (in prev versions of code),
        // backend will respond with old-style payload response (SLEEPIO-2138)
        session_screen_version: 3,
      }),
    enabled: typeof programId === 'number',
    meta: options?.meta,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });

  useEffect(() => {
    dispatch(assignUserABTestVariants(result.data?.result.ab_test_variants));
  }, [dispatch, result.data]);

  return result;
};
