import { Query } from 'react-query';

import { IsFetchingMeta } from './hooks/useQueryWithIsFetchingMeta';

/**
 * NOTE: Only day this should be injected into app from sleepio package
 *
 * @see {@link ./README.md} for more info
 */
const checkMetaSleepio = (query: Query): boolean | undefined => {
  const queryKey = query.options?.queryKey;
  const meta: IsFetchingMeta | undefined = query?.meta;
  const [firstQueryKeySegment] = Array.isArray(queryKey)
    ? queryKey
    : [queryKey];
  switch (firstQueryKeySegment) {
    case 'SessionProgress/get_user_session_screen_data':
    case 'ProductStreamVersion/get_latest_version':
      switch (meta?.pathname) {
        case '/sleepio/login':
          return true;
        default:
      }
  }
  return undefined;
};

export { checkMetaSleepio };
