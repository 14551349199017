import storage from '@react-native-community/async-storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { NotificationsAction, NotificationsActionTypes } from './actions';

export const notificationsPersistConfig = {
  key: 'notifications',
  storage,
  blacklist: ['notifications'],
};

export type NotificationUserSetting = {
  id: string;
  isOn: boolean;
  timeOfDayHours24: number; // always local time
  timeOfDayMinutes: number; // relative to hours
  date?: Date;
  programId?: number;
};

/**
 * Constant for defining if Notifications should be shown
 *
 * - (could also be an IIFE that returns the Notifications FeatureFlag)
 *
 * - Will return TRUE for DAYLIGHT DEV and QA and FALSE for other cases,
 *   based on bundleID
 * - TODO This is a temporary fix until DP-1842 is ready for delivery into STAGE env
 *
 * - This may eventually be set dynamically VIA endpoint call.
 *   - When that happens this constant will need to be moved
 *     to localNotificationState file and be accessed by a custom hook
 *   - A null will then represent "unknown" (default) and
 *     boolean will represent a known on/off state
 *   - When set to OFF remotely, we may want to remove existing delivered
 *     and/or queued notifications
 */
export type NotificationFeatureFlag = boolean | null;

export interface NotificationsState extends PersistPartial {
  _persist: Readonly<{
    version: number;
    rehydrated: boolean;
  }>;
  configurations: NotificationUserSetting[];
  FF_NOTIFICATIONS_ON: NotificationFeatureFlag;
  hasPreSleepDiaryNotificationModalPopUpBeenPrompted: boolean;
  hasPostSleepDiaryNotificationModalPopUpBeenPrompted: boolean;
  hasDefaultNotificationModalPopUpBeenPrompted: boolean;
  osNotificationPermissionsRequestCount: number;
}

export const initialNotificationsState: NotificationsState = {
  _persist: {
    version: 0,
    rehydrated: false,
  },
  configurations: [],
  FF_NOTIFICATIONS_ON: null,
  hasPreSleepDiaryNotificationModalPopUpBeenPrompted: false,
  hasPostSleepDiaryNotificationModalPopUpBeenPrompted: false,
  hasDefaultNotificationModalPopUpBeenPrompted: false,
  osNotificationPermissionsRequestCount: 0,
};

export const notificationsReducer = (
  state = initialNotificationsState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case NotificationsActionTypes.SET_HAS_DEFAULT_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED:
      return {
        ...state,
        hasDefaultNotificationModalPopUpBeenPrompted: true,
      };
    case NotificationsActionTypes.SET_HAS_PRE_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED:
      return {
        ...state,
        hasPreSleepDiaryNotificationModalPopUpBeenPrompted: true,
      };
    case NotificationsActionTypes.SET_HAS_POST_SLEEP_DIARY_NOTIFICATION_MODAL_POP_UP_BEEN_PROMPTED:
      return {
        ...state,
        hasPostSleepDiaryNotificationModalPopUpBeenPrompted: true,
      };
    case NotificationsActionTypes.ADD_NOTIFICATION_USER_SETTING: {
      const { configurations = [] } = state;
      const indexOfConfigWithMatchingId = configurations?.findIndex(
        c => c.id === action.payload.id
      );
      const updatedConfigurations = [...configurations];
      if (indexOfConfigWithMatchingId === -1) {
        updatedConfigurations.push(action.payload);
      } else {
        updatedConfigurations.splice(
          indexOfConfigWithMatchingId,
          1,
          action.payload
        );
      }
      return {
        ...state,
        configurations: updatedConfigurations,
      };
    }
    case NotificationsActionTypes.REMOVE_NOTIFICATION_USER_SETTING_BY_ID: {
      const { configurations = [] } = state;
      const indexOfConfigWithMatchingId = configurations.findIndex(
        c => c.id === action.payload
      );
      const updatedConfigurations = [...configurations];
      if (indexOfConfigWithMatchingId !== -1) {
        updatedConfigurations.splice(indexOfConfigWithMatchingId, 1);
      }
      return {
        ...state,
        configurations: updatedConfigurations,
      };
    }
    case NotificationsActionTypes.SET_NOTIFICATION_USER_SETTING_BY_ID: {
      const { configurations = [] } = state;
      const indexOfConfigWithMatchingId = configurations?.findIndex(
        c => c.id === action.payload.id
      );
      const updatedConfigurations = [...configurations];
      if (indexOfConfigWithMatchingId === -1) {
        updatedConfigurations.push(action.payload);
      } else {
        updatedConfigurations[indexOfConfigWithMatchingId] = action.payload;
      }
      return {
        ...state,
        configurations: updatedConfigurations,
      };
    }
    case NotificationsActionTypes.INCREMENT_OS_NOTIFICATION_PERMISSIONS_REQUEST_COUNT:
      return {
        ...state,
        osNotificationPermissionsRequestCount:
          state.osNotificationPermissionsRequestCount + 1,
      };
    case NotificationsActionTypes.SET_NOTIFICATION_FEATURE_FLAG: {
      return {
        ...state,
        FF_NOTIFICATIONS_ON: action.payload,
      };
    }
    case NotificationsActionTypes.SET_NOTIFICATION_USER_SETTINGS: {
      return {
        ...state,
        configurations: action.payload,
      };
    }
    /**
     * Info: notificationsInfo is reset by action "state/reset"
     */
    default:
      return state;
  }
};
