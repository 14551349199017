import React, { ReactElement, useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { getInteractionProps } from 'components/generic-question/InputsAsArray/helpers/utils/getInteractionProps';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import useActionHandler from 'lib/player/useActionHandler';

import { LinkText, PressButton } from './styled/styled';

const flatten = StyleSheet.flatten;

type ButtonProps = RoleProps & {
  text: string;
  action: SceneAction;
  style?: StyleProp<ViewStyle>;
  isDisabled?: boolean;
};

/**
 * A button that takes an action and either converts that to a Link (in the case of
 * action.type being "NEXT" or an onPress handler in the case where the action is,
 * for instance, "SUBMIT"
 */
const LinkButton = ({
  text,
  action,
  style,
  ...rest
}: ButtonProps & {
  isHovering?: boolean;
  isPressing?: boolean;
}): ReactElement => {
  const actionHandler = useActionHandler(action);
  const [isPressing, setIsPressing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handlePress = (): void => {
    if (typeof actionHandler === 'function') {
      actionHandler();
    }
  };

  return (
    <PressButton
      {...roles(`LinkButton`)}
      style={flatten(style)}
      isHovering={isHovering}
      isPressing={isPressing}
      activeOpacity={1}
      {...getInteractionProps({
        value: undefined,
        onSelect: handlePress,
        setIsPressing,
        setIsHovering,
      })}
      {...rest}
    >
      <LinkText isHovering={isHovering} isPressing={isPressing} text={text} />
    </PressButton>
  );
};

export default LinkButton;
