import React, { FunctionComponent, useCallback, useState } from 'react';
import { format } from 'date-fns';

import { SleepWindowTypes } from '@bighealth/types/src/scene-components/SleepWindowTypes';

import { DatePickerDisplayFormat } from 'components/generic-question/DateTimePickers';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { MINUTE } from 'lib/durations';
import { getDateFromHoursMinutesSeconds } from 'lib/getDateFromHoursMinutesSeconds';
import useActionHandler from 'lib/player/useActionHandler';
import * as reporter from 'lib/reporter';

import { FormPresentation, FormPresentationProps } from '../FormPresentation';

import { submitSleepWindow } from './helpers/submitSleepWindow';

/**
 * Connects to state
 * @param props
 */
const FormState: FunctionComponent<SleepWindowTypes['Props']> = props => {
  const fromTime = getDateFromHoursMinutesSeconds(
    props?.fields.from_time.input.initialValue,
    false
  );
  const toTime = getDateFromHoursMinutesSeconds(
    props?.fields.to_time.input.initialValue,
    false
  );
  const [fromTimeDate, setFromTime] = useState<Date>(fromTime);
  const [toTimeDate, setToTime] = useState<Date>(toTime);

  const { sceneSetGraphId } = useSafeParams();

  const actionHandler = useActionHandler(props?.on_submit_success);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const [, error] = await submitSleepWindow({
      fromTime: fromTimeDate,
      toTime: toTimeDate,
      sceneSetGraphId: sceneSetGraphId,
    });
    if (!error) {
      try {
        if (typeof actionHandler === 'function') {
          await actionHandler();
        }
      } catch (e) {
        reporter.log(e?.message, e);
      }
    }
  }, [actionHandler, fromTimeDate, sceneSetGraphId, toTimeDate]);

  const handlePressSubtract = (): void => {
    const newFromTimeDate = new Date(fromTimeDate.getTime() - 15 * MINUTE);
    const newToTimeDate = new Date(toTimeDate.getTime() - 15 * MINUTE);
    setFromTime(newFromTimeDate);
    setToTime(newToTimeDate);
  };

  const handlePressAdd = (): void => {
    const newFromTimeDate = new Date(fromTimeDate.getTime() + 15 * MINUTE);
    const newToTimeDate = new Date(toTimeDate.getTime() + 15 * MINUTE);
    setFromTime(newFromTimeDate);
    setToTime(newToTimeDate);
  };

  const onSubmit: FormPresentationProps['onSubmit'] = handleSubmit;
  const onPressedSubtract: FormPresentationProps['onPressedSubtract'] = handlePressSubtract;
  const onPressedAdd: FormPresentationProps['onPressedAdd'] = handlePressAdd;

  return (
    <FormPresentation
      fields={{
        from_time: {
          label: props?.fields.from_time.label,
          input: {
            initialValue: format(
              fromTimeDate,
              DatePickerDisplayFormat.TIME_FORMAT_12H
            ),
            color: props?.fields.from_time.input.color,
          },
        },
        to_time: {
          label: props?.fields.to_time.label,
          input: {
            initialValue: format(
              toTimeDate,
              DatePickerDisplayFormat.TIME_FORMAT_12H
            ),
            color: props?.fields.to_time.input.color,
          },
        },
      }}
      submit_button={props?.submit_button}
      on_submit_success={props?.on_submit_success}
      onSubmit={onSubmit}
      onPressedSubtract={onPressedSubtract}
      onPressedAdd={onPressedAdd}
    />
  );
};

export { FormState };
