import React, { ReactElement } from 'react';
import { Formik } from 'formik';

import { Scene } from '@bighealth/types/src/scene-component';

import { PlayButton } from 'daylight/components';
import { ContainerPlayButton } from 'daylight/components/screens/TodayScreen/TodayScreen.styled';
import { noOpFunction } from 'lib/noOpFunction';

type HomeScreenButton = Scene.Components.Button & {
  iconColor?: string;
  textColor?: string;
};

export const SSGPlayButton = ({
  props,
  width,
  height,
  iconColor,
}: {
  props: HomeScreenButton;
  width: number;
  height: number;
  iconColor: string;
}): ReactElement => {
  return (
    <ContainerPlayButton
      style={{ width, height }} // Necessary to be able to have accessibility in android. Does not affect styles/position at all
    >
      <Formik initialValues={[]} onSubmit={noOpFunction}>
        <PlayButton
          {...props}
          style={{
            backgroundColor: props.style?.backgroundColor || '#000000',
            iconColor: iconColor,
            width: width,
            height: height,
          }}
          onPress={noOpFunction}
        />
      </Formik>
    </ContainerPlayButton>
  );
};
