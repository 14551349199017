import React, { ReactElement } from 'react';

import { Scene } from '@bighealth/types/src/scene-component';

import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { PillText, PillWrapper } from './Pill.styled';

export type PillComponent = Omit<Scene.Components.Text, 'style'> & {
  style?: Scene.Components.Text['style'] & {
    borderColor?: string; // expects "#hex"
    borderWidth?: number; // in px
    minWidth?: number; // in px
  };
};

const Pill = ({
  textComponent,
}: {
  textComponent: PillComponent;
}): ReactElement => {
  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();
  const {
    alignItems,
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius,
    marginRight,
    paddingHorizontal,
    paddingVertical,
    color,
    fontWeight,
    fontSize,
    lineHeight,
    minWidth,
  } = textComponent.style || {};

  return (
    <PillWrapper
      testID={`Visual wrapper for ${textComponent.text}`}
      alignItems={alignItems}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={borderRadius || 6}
      borderWidth={borderWidth}
      spaceBetween={transformStyle(marginRight || 8)}
      paddingHorizontal={transformStyle(paddingHorizontal || 9)}
      paddingVertical={transformVerticalStyle(paddingVertical || 6)}
      minWidth={transformStyle(minWidth || 0)}
    >
      <PillText
        text={textComponent.text}
        color={color}
        fontSize={transformStyle(fontSize || 14)}
        lineHeight={transformStyle(lineHeight || 16)}
        style={{ fontWeight: fontWeight || 700 }}
      />
    </PillWrapper>
  );
};

export { Pill };
