import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { PressHandler } from 'components/Button/types';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { Triangle } from 'components/sceneset-components/SetSleepWindow/components/Triangle/index';
import { Text } from 'components/Text';
import { pass } from 'cross-platform/utils/roleProps';

import { CircleButton } from '../CircleButton';

export const Title = ({
  text,
  color,
}: {
  text: string;
  color?: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text style={{ color: color, fontSize: styles.titleSetSleepWindow }}>
      {text}
    </Text>
  );
};

export const Label = ({
  text,
  color,
  ...rest
}: {
  text: string;
  color?: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text
      style={{
        color: color,
        fontSize: styles.labelSetSleepWindow,
        textAlign: 'center',
        marginBottom: styles.labelMarginBottom,
      }}
      {...rest}
    >
      {text}
    </Text>
  );
};

export const Input = ({
  initialValue,
  color,
}: {
  initialValue: string;
  color: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text
      style={{
        color: color,
        fontSize: styles.inputSetSleepWindow,
        width: styles.inputWidth,
        textAlign: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    >
      {initialValue}
    </Text>
  );
};

export const CircleButtonSleepWindow = styled(CircleButton).attrs({
  backgroundColor: '#00506B',
})``;

export const InputContainer = styled.View`
  min-width: 130px;
`;

export const SetSleepWindowTriangle = styled(Triangle)``;

export const TriangleContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SetSleepWindowButton = ({
  title,
  onPress,
  ...rest
}: {
  title: string;
  onPress: PressHandler;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={{
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#00506b',
        alignItems: 'center',
        padding: styles.submitButtonSetSleepWindowPadding,
        borderRadius: styles.submitButtonSetSleepWindowBorderRadius,
        width: styles.submitButtonSetSleepWindowWidth,
        height: styles.submitButtonSetSleepWindowHeight,
      }}
      onPress={onPress}
      {...pass(rest)}
    >
      <Text
        style={{
          color: '#ffffff',
          fontSize: styles.submitButtonSetSleepWindowFontSize,
        }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export const SubmitButtonContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const wrappers = {
  Field: styled.View`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 46px;
  `,
  Controls: styled.View`
    display: flex;
    flex-direction: row;
  `,
};

wrappers.Field.displayName = 'wrappers.Field';
wrappers.Controls.displayName = 'wrappers.Controls';
