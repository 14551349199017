import { NotificationUserSetting } from './reducer';

export enum UserSettingChangeType {
  CHANGE_TIME_ONLY = 'CHANGE_TIME_ONLY',
  NO_CHANGE = 'NO_CHANGE',
  TO_OFF = 'TO_OFF',
  TO_ON = 'TO_ON',
}

export type SendNotificationStateDataToBackendFunc = (
  changeType: UserSettingChangeType,
  payload: NotificationUserSetting
) => void;
