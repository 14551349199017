import React, { ReactElement, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { TextInput } from 'components/primitives/cross-platform';
import { TextAreaStates } from 'config/getThemeForProducts/utils/createTheme/textArea';
import { roles } from 'cross-platform/utils/roleProps';

import { usePlayerControlsDismiss } from '../hooks';
import { GENERIC_INPUT_PLACEHOLDER } from '..';

import { TextAreaOutline } from './TextAreaInput.styled';
import { RawTextAreaInputProps } from './types';

export const TextAreaInput = ({
  onValueChange,
  value,
  placeholderText,
  onPress,
}: RawTextAreaInputProps): ReactElement => {
  const [textAreaState, setTextAreaState] = useState(TextAreaStates.INACTIVE);

  const textAreaThemeValues = useTheme().controls.textArea;

  const baseInputStyle = textAreaThemeValues.textInputStyles;
  const inputStyle = {
    ...baseInputStyle,
    ...(textAreaThemeValues.states[textAreaState] ?? {}),
  };
  if (!value) {
    inputStyle.fontStyle = textAreaThemeValues.placeholderFontStyle ?? 'italic';
  }

  const { onFocus, onBlur } = useInputFocusContext();

  const inputNode = useRef(null);
  const playerControlsDismiss = usePlayerControlsDismiss(inputNode);

  const handleTextChange = (value: string) => {
    onValueChange(value);
  };

  const handleFocus = () => {
    onFocus();
    playerControlsDismiss();
    onPress?.();
    setTextAreaState(TextAreaStates.ACTIVE);
  };

  const handleBlur = () => {
    onBlur();
    setTextAreaState(TextAreaStates.INACTIVE);
  };

  return (
    <TextAreaOutline hide={textAreaState !== TextAreaStates.ACTIVE}>
      <TextInput
        {...roles('GenericInput-textArea')}
        placeholder={placeholderText ?? GENERIC_INPUT_PLACEHOLDER}
        placeholderTextColor={textAreaThemeValues.placeholderColor}
        multiline
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChangeText={handleTextChange}
        value={`${value}`}
        ref={inputNode}
        style={inputStyle}
        textAlignVertical="top"
      />
    </TextAreaOutline>
  );
};
