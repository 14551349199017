import styled from 'styled-components/native';

import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { Text } from 'components/Text';

interface HeaderProps {
  locked: boolean;
  fontSize: number;
  marginVertical: number;
}

export const PracticeGroupHeader = styled(Text)<HeaderProps>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 700;
  font-family: ${FontFamilies.Regular};
  text-align: center;
  margin: ${({ marginVertical }) => marginVertical}px auto;
  opacity: ${({ locked }) => (locked ? 0.2 : 1)};
  color: ${({ theme }) => theme.color.primaryDark};
`;
