export enum ButtonColorSchemes {
  Facebook = 'facebook',
  Standard = 'standard',
  Login = 'login',
  AltLogin = 'alt-login',
  Google = 'google',
  Link = 'link',
  LinkMidBlue = 'link-mid-blue',
  Primary = 'primary',
  AltPrimary = 'alt-primary',
  PrimaryOutline = 'primary-outline',
  PrimaryOnDark = 'primary_on_dark',
  Secondary = 'secondary',
  LinkInverse = 'link-inverse',
  MultiFactorAuthentication = 'multi-factor-authentication',
  ProgressReview = 'progress-review',
}

type ButtonSchemeProps = {
  color: string;
  backgroundColor: string;
  borderColor: string;
  paddingLeft?: number;
  paddingRight?: number;
};

export type ButtonColorSchemesType = Record<
  | ButtonColorSchemes.Standard
  | ButtonColorSchemes.Primary
  | ButtonColorSchemes.AltPrimary
  | ButtonColorSchemes.PrimaryOutline
  | ButtonColorSchemes.PrimaryOnDark
  | ButtonColorSchemes.Secondary
  | ButtonColorSchemes.Login
  | ButtonColorSchemes.AltLogin
  | ButtonColorSchemes.Link
  | ButtonColorSchemes.LinkInverse
  | ButtonColorSchemes.LinkMidBlue
  | ButtonColorSchemes.Facebook
  | ButtonColorSchemes.Google
  | ButtonColorSchemes.MultiFactorAuthentication
  | ButtonColorSchemes.ProgressReview,
  {
    default: ButtonSchemeProps;
    disabled: ButtonSchemeProps;
    pressed: ButtonSchemeProps;
    hover: ButtonSchemeProps;
  }
>;

// TODO: this will be deprecated in favour of themes.
// WHEN: when Sleepio team has the bandwidth: look at src/config/getThemeForProducts/themes/daylight.tsx for Daylight
export const buttonColorSchemes: ButtonColorSchemesType = {
  [ButtonColorSchemes.AltPrimary]: {
    default: {
      color: '#163258',
      backgroundColor: '#FF8E75',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'white',
      backgroundColor: 'rgba(35, 31, 32, 0.5)', // Same as #231F20 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#163258',
      backgroundColor: '#FF7152',
      borderColor: 'transparent',
    },
    hover: {
      color: '#163258',
      backgroundColor: 'rgba(255, 142, 117, 0.8)', // Same as #FF8E75 but with 80% opacity
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.PrimaryOutline]: {
    default: {
      color: 'white',
      backgroundColor: '#163258',
      borderColor: 'white',
    },
    disabled: {
      color: 'white',
      backgroundColor: '#838282',
      borderColor: 'white',
    },
    pressed: {
      color: 'white',
      backgroundColor: '#163C71',
      borderColor: 'white',
    },
    hover: {
      color: 'white',
      backgroundColor: 'rgba(22, 50, 88, 0.8)', // Same as #163258 but with 80% opacity
      borderColor: 'white',
    },
  },
  [ButtonColorSchemes.Standard]: {
    default: {
      color: 'white',
      backgroundColor: '#00506b',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'white',
      backgroundColor: 'rgba(0, 80, 107, 0.5)', // Same as #00506b but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: 'white',
      backgroundColor: '#00212c',
      borderColor: 'transparent',
    },
    hover: {
      color: 'white',
      backgroundColor: '#017BA3',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.Login]: {
    default: {
      color: 'white',
      backgroundColor: '#6A717D',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#d6d6d6',
      backgroundColor: 'rgba(77, 180, 0, 0.5)', // Same as #4db400 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#6A717D',
      backgroundColor: '#d6d6d6',
      borderColor: 'transparent',
    },
    hover: {
      color: '#003366',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.AltLogin]: {
    default: {
      color: '#082143',
      backgroundColor: 'transparent',
      borderColor: '#082143',
    },
    disabled: {
      color: '#6A717D',
      backgroundColor: 'transparent',
      borderColor: '#6A717D',
    },
    pressed: {
      color: '#234370',
      backgroundColor: 'transparent',
      borderColor: '#234370',
    },
    hover: {
      color: '#163258',
      backgroundColor: 'transparent',
      borderColor: '#163258',
    },
  },
  [ButtonColorSchemes.PrimaryOnDark]: {
    default: {
      color: '#FFF',
      backgroundColor: '#008A09',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#FFF',
      backgroundColor: 'rgba(0, 138, 9, 0.5)', // Same as #008a09 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#FFF',
      backgroundColor: '#006507',
      borderColor: 'transparent',
    },
    hover: {
      color: '#FFF',
      backgroundColor: '#006507',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.Primary]: {
    default: {
      color: '#FFF',
      backgroundColor: '#4db400',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)', // Same as #4db400 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
    hover: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.Secondary]: {
    default: {
      color: '#FFF',
      backgroundColor: '#4db400',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)', // Same as #4db400 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
    hover: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.Facebook]: {
    default: {
      color: 'white',
      backgroundColor: 'transparent',
      borderColor: 'white',
    },
    disabled: {
      color: 'gray',
      backgroundColor: 'transparent',
      borderColor: 'gray',
    },
    pressed: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    hover: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  [ButtonColorSchemes.Google]: {
    default: {
      color: 'white',
      backgroundColor: 'transparent',
      borderColor: 'white',
    },
    disabled: {
      color: 'gray',
      backgroundColor: 'transparent',
      borderColor: 'gray',
    },
    pressed: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    hover: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  [ButtonColorSchemes.Link]: {
    default: {
      color: '#00506b',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    disabled: {
      color: 'rgba(0, 80, 107, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    pressed: {
      color: 'rgba(0, 80, 107, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    hover: {
      color: 'rgba(0, 80, 107, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  [ButtonColorSchemes.LinkInverse]: {
    default: {
      color: '#ffffff',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    disabled: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    pressed: {
      color: 'rgba(255, 255, 255, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    hover: {
      color: 'rgba(255, 255, 255, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  [ButtonColorSchemes.LinkMidBlue]: {
    default: {
      color: '#163258',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    disabled: {
      color: '#163258',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    pressed: {
      color: '#163258',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    hover: {
      color: '#163258',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  [ButtonColorSchemes.MultiFactorAuthentication]: {
    default: {
      color: '#003366',
      backgroundColor: '#ff8e75',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#FFFFFF',
      backgroundColor: '#939393',
      borderColor: 'transparent',
    },
    pressed: {
      color: '#FFFFFF',
      backgroundColor: '#939393',
      borderColor: 'transparent',
    },
    hover: {
      color: '#FFFFFF',
      backgroundColor: '#939393',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.ProgressReview]: {
    default: {
      color: '#FFF',
      backgroundColor: '#163258',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#FFF',
      backgroundColor: '#163258', // Same as #4db400 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#FFF',
      backgroundColor: 'rgba(22, 50, 88, 0.5)',
      borderColor: 'transparent',
    },
    hover: {
      color: '#FFF',
      backgroundColor: 'rgba(22, 50, 88, 0.5)',
      borderColor: 'transparent',
    },
  },
} as const;
