import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { APIClient, APIRequestBody } from '@bighealth/api';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import {
  HistoryType,
  matcheDebug,
  matcheDeveloperComponents,
  matchesForgotPassword,
  matchesLogin,
  matchesLoginCallbackScreen,
  matchesLoginScreen,
  matchesSceneSet,
} from 'components/Routes/matchesRoutes';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { API_ENDPOINT_FULL_URL } from 'config/envVars';
import {
  isServiceMethodFlagged,
  RequestConfig,
} from 'lib/api/helpers/requestConfigUtils';
import { useFeatureFlags } from 'lib/api/hooks/useFeatureFlags';
import { middleware } from 'lib/api/middleware';
import { getHeaderForwardedHost } from 'lib/getHeaderForwardedHost';
import { getRedirectUri } from 'lib/navigation/getRedirectUri';
import { openInAppBrowser } from 'lib/navigation/openInAppBrowser';
import * as reporter from 'lib/reporter';
import { stringify } from 'lib/stringify';
import { getTimezone } from 'lib/timezone';
import { STATE_RESET } from 'state/store/actions';

import { getRedirectQuery } from '../helpers/getRedirectQuery';
import { getUserAgentHeaders } from '../helpers/getUserAgentHeaders';
import { loginSuccessHandler } from '../helpers/loginSuccessHandler/loginSuccessHandler';
import { logoutSuccessHandler } from '../helpers/logoutSuccessHandler/logoutSuccessHandler';
import { transformError } from '../helpers/transformError';

import { NO_REDIRECT_SERVICE_METHODS } from './constants';

export const onErrorFactory = ({
  history,
  productReference,
}: {
  history: HistoryType;
  productReference: string;
}) =>
  /**
   * onError
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: Error | any): void => {
    let errorString = `${error}`;

    try {
      errorString = stringify(error);
    } catch (stringifyError) {
      // stick with default
    }

    let requestConfig: RequestConfig = {};
    if (error?.toJSON) {
      try {
        requestConfig = {
          ...JSON.parse(error.toJSON()?.config?.data || '{}'),
        };
      } catch (parseError) {
        const logStr = `JSON.parse of error variable "${errorString}" failed with ${parseError}`;
        reporter.log(`onError - ${logStr}`, Error(logStr), {
          silent: true,
        });
      }
    }
    /**
     * FIXME do not hard-code behavior based on service name
     * HOW pass in params e.g. onError
     * https://bighealth.atlassian.net/browse/FEG-41
     */
    if (
      matchesSceneSet(history) &&
      !isServiceMethodFlagged(requestConfig, NO_REDIRECT_SERVICE_METHODS)
    ) {
      history.push(`/${productReference}/home`);
    }

    const transformedError = transformError(error, requestConfig);
    reporter.log(
      `onError - ${transformedError?.message || `${error}`}`,
      transformedError,
      {
        silent: true,
      }
    );
  };

export const useInitializeAPIClient = (): boolean => {
  const [clientInitialized, setInitialized] = useState(false);
  const queryClient = getQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();
  const { productReference } = useSafeParams();
  const loggingOut = useRef(false);
  const { getFeatureFlags, featureFlags, flagsRequested } = useFeatureFlags();

  // Retrieve feature flags, needed to get the IDP logout URL
  useEffect(() => {
    if (!flagsRequested) {
      getFeatureFlags();
    }
  }, [flagsRequested, getFeatureFlags]);

  const asyncConfigure = useCallback(async () => {
    const userAgentHeaders = await getUserAgentHeaders();

    APIClient.configure({
      url: API_ENDPOINT_FULL_URL,
      headers: {
        'Content-Type': 'application/json',
        'Bh-User-Timezone': getTimezone(),
        'Access-Control-Allow-Origin': '*',
        'X-Forwarded-Host': getHeaderForwardedHost(),
        ...userAgentHeaders,
      },
      withCredentials: true,
      method: 'post',
      onError: onErrorFactory({ history, productReference }),
      onTokenRefreshSuccess: response => {
        if (typeof response?.result.user_uuid === 'undefined') {
          throw Error(`Expected token refresh result to include user_uuid`);
        }
        loginSuccessHandler({
          userUUID: response?.result.user_uuid,
        }); // Async
      },
      onTokenRefreshFail: () => {
        (async function handleTokenRefreshFail() {
          // We can't trust this function to be called just once so
          // we're going to lock the handling of logout
          // until we're done so it's only done once

          // The resources page doesn't require authentication although it does include
          // some components like the session dot which _do_ require authentication.
          // For the sake of expediency (i.e. a temporary solution) we bypass auth checking
          // for that path here.
          // @TODO remove this temporary fix and instead create a provider that can turn on
          // and off auth requirements
          // @WHEN when we add more unauthenticated routes
          if (
            !matchesForgotPassword(history) &&
            !matchesLogin(history) &&
            !matchesLoginCallbackScreen(history) &&
            !matchesLoginScreen(history) &&
            !matcheDeveloperComponents(history) &&
            !matcheDebug(history) &&
            !loggingOut.current
          ) {
            loggingOut.current = true;
            try {
              await queryClient.cancelQueries();
            } catch (e) {
              // We don't need to do anything with cancelQueries error
              reporter.log(`Error while cancelling queries ${e}`, undefined, {
                silent: true,
              });
            } finally {
              queryClient.clear();
              dispatch({ type: STATE_RESET });
              const urlQuery = getRedirectQuery(
                history.location.pathname,
                productReference
              );
              history.push(`/${productReference}/login${urlQuery}`);

              // Log out of the IDP (i.e. Auth0)
              if (featureFlags?.idp_logout_uri) {
                const redirectUri = getRedirectUri(productReference, 'login');
                const logoutUrl = `${featureFlags.idp_logout_uri}?returnTo=${redirectUri}`;
                await openInAppBrowser(logoutUrl);
              }

              logoutSuccessHandler();
              // We do genuinely want this
              // eslint-disable-next-line require-atomic-updates
              loggingOut.current = false;
            }
          }
        })();
      },
      middleware: async (request, response) => {
        return middleware.responses(request as APIRequestBody)(response);
      },
    });
    setInitialized(true);
  }, [dispatch, history, productReference, queryClient, featureFlags]);

  useEffect(() => {
    asyncConfigure();
  }, [asyncConfigure]);
  return clientInitialized;
};
