import { AxiosRequestConfig } from 'axios';

import { APIRequestBody, APIResponse } from '@bighealth/api';
import { login_user } from '@bighealth/api/GeneralLogin/v1';
import { book_next_session } from '@bighealth/api/SessionProgress/v1';
import {
  create_baseline_entities,
  create_from_weekly_average_estimate,
  create_single_day_entry,
  extrapolate_missing_diaries_from_past_seven_days,
  get_diary_entry_form,
  get_sleep_diary_component_data_for_time_period,
  submit_single_diary_form,
  update_single_day_entry_with_date,
} from '@bighealth/api/SleepDiary/v1';
import { get_sleep_efficiency_trend_page_data_desc } from '@bighealth/api/SleepEfficiencyTrend/v1';
import { create_with_times } from '@bighealth/api/SleepWindow/v1';
import { adjust_with_change_action_and_increment } from '@bighealth/api/SleepWindow/v1';
import {
  authenticate_with_email,
  authenticate_with_facebook,
  authenticate_with_google,
  consolidate_password,
  logout as user_logout,
} from '@bighealth/api/UserAccountAuthorization/v1';
import { FormTypes } from '@bighealth/types/dist/scene-components/sleep-diary/entry-form';
import { DiaryEntry } from '@bighealth/types/dist/services/SleepDiaryPayloads/types';

import { API_ENDPOINT_FULL_URL, PLATGEN_ONBOARDING_URL } from 'config/envVars';
import { middleware } from 'lib/api/middleware';
import { getHeaderForwardedHost } from 'lib/getHeaderForwardedHost';
import { getLocaleHHmmssFromDate } from 'lib/getLocaleHoursMinutesSecondsFromDate';
import { getPlatform } from 'lib/platform';
import * as reporter from 'lib/reporter';
import isFetchError from 'lib/statusCode/isFetchError';
import { getTimezone } from 'lib/timezone';

import network from './fetch';

// --------------------------------------------------------------------------------
// Legacy api calls remapped to new package
//

export const generalLoginWithEmailAndPassword = async (
  email: string,
  password: string,
  config: { productId: number; nextUrl?: string }
): Promise<login_user.Response> =>
  await login_user({
    email,
    password,
    product_id: config.productId,
    device_platform: getPlatform(),
    next_url: config.nextUrl,
  });

export const loginWithEmailAndPassword = async (
  email: string,
  password: string,
  config: { productId: number },
  requestConfig?: AxiosRequestConfig
): Promise<authenticate_with_email.Response> =>
  await authenticate_with_email(
    {
      email,
      password,
      device_platform: getPlatform(),
      product_id: config.productId,
    },
    requestConfig
  );

export const loginWithFacebookToken = async (
  fbAccessToken: string,
  config: { productId: number },
  requestConfig?: AxiosRequestConfig
): Promise<authenticate_with_facebook.Response> =>
  await authenticate_with_facebook(
    {
      facebook_access_token: fbAccessToken,
      device_platform: getPlatform(),
      product_id: config.productId,
    },
    requestConfig
  );

export const loginWithGoogleToken = async (
  googleToken: string,
  config: { productId: number },
  requestConfig?: AxiosRequestConfig
): Promise<authenticate_with_google.Response> =>
  await authenticate_with_google(
    {
      google_id_token: googleToken,
      device_platform: getPlatform(),
      product_id: config.productId,
    },
    requestConfig
  );

export const consolidatePassword = async (
  password: string
): Promise<consolidate_password.Response> =>
  await consolidate_password({
    password,
    device_platform: getPlatform(),
  });

export const logout = async (): Promise<user_logout.Response> =>
  await user_logout();

export const fetchSleepEfficiency = async (args: {
  page: number;
  itemsPerPage: number;
  productId: number;
}): Promise<get_sleep_efficiency_trend_page_data_desc.Response> =>
  await get_sleep_efficiency_trend_page_data_desc({
    page: args.page,
    items_per_page: args.itemsPerPage,
    user_timezone: getTimezone(),
    product_id: args.productId,
  });

export const createWithTimes = async (args: {
  fromTime: Date;
  toTime: Date;
  sceneSetGraphId: number;
}): Promise<create_with_times.Response> =>
  await create_with_times({
    from_time: { $time: getLocaleHHmmssFromDate(args.fromTime, false) },
    to_time: { $time: getLocaleHHmmssFromDate(args.toTime, false) },
    scene_set_graph_id: args.sceneSetGraphId,
  });

export const adjustWithChangeActionAndIncrement = async (args: {
  changeAction: string;
  minutesIncrement: number;
  sceneSetGraphId: number;
}): Promise<adjust_with_change_action_and_increment.Response> =>
  await adjust_with_change_action_and_increment({
    change_action: args.changeAction,
    minutes_increment: args.minutesIncrement,
    scene_set_graph_id: args.sceneSetGraphId,
  });

export const bookNextSession = async (args: {
  productId: number;
  nextSessionDateStr: string;
  userTimezone: string;
}): Promise<book_next_session.Response> =>
  await book_next_session({
    product_id: args.productId,
    next_session_datetime: {
      $datetime: args.nextSessionDateStr,
    },
    user_timezone: args.userTimezone,
  });

export const fetchSleepDiaryWeeklyData = async (
  args: get_sleep_diary_component_data_for_time_period.Args
): Promise<get_sleep_diary_component_data_for_time_period.Response> =>
  await get_sleep_diary_component_data_for_time_period(args);

export const extrapolateSleepDiaryData = async (
  args: extrapolate_missing_diaries_from_past_seven_days.Args
): Promise<extrapolate_missing_diaries_from_past_seven_days.Response> =>
  await extrapolate_missing_diaries_from_past_seven_days(args);

export const getDiaryEntryForm = async (
  dateStr: string | undefined,
  formType: FormTypes
): Promise<get_diary_entry_form.Response> =>
  await get_diary_entry_form({
    diary_date: dateStr
      ? {
          $date: dateStr,
        }
      : undefined,
    form_type: formType,
  });

export const createBaselineEntities = async (
  diaryEntry: DiaryEntry
): Promise<create_baseline_entities.Response> =>
  await create_baseline_entities(diaryEntry);

export const createFromWeeklyAverageEstimate = async (
  diaryEntry: DiaryEntry
): Promise<create_from_weekly_average_estimate.Response> =>
  await create_from_weekly_average_estimate(diaryEntry);

export const createSingleDayEntry = async (
  diaryEntry: DiaryEntry
): Promise<create_single_day_entry.Response> =>
  await create_single_day_entry(diaryEntry);

export const updateSingleEntryWithDate = async (
  diaryEntry: DiaryEntry
): Promise<update_single_day_entry_with_date.Response> =>
  await update_single_day_entry_with_date(diaryEntry);

// submit_single_diary_form
export const submitSingleDiaryForm = async (
  diaryEntry: DiaryEntry
): Promise<submit_single_diary_form.Response> =>
  await submit_single_diary_form(diaryEntry);

// --------------------------------------------------------------------------------
// Legacy and direct api calls
//

export const api = async (body: APIRequestBody): Promise<APIResponse> => {
  const jsonBody: string = JSON.stringify(body);
  const init: RequestInit = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Bh-User-Timezone': getTimezone(),
      'X-Forwarded-Host': getHeaderForwardedHost(),
    },
    body: jsonBody,
  };
  const fetchRes = await network(API_ENDPOINT_FULL_URL, init);
  if (isFetchError(fetchRes.status)) {
    return {
      status_code: fetchRes.status,
      result: null,
    };
  }
  const resJson: APIResponse = await fetchRes.json().catch(
    (error: Error): Error => {
      reporter.log(`Error when parsing json response`, error);
      return error;
    }
  );

  if (resJson instanceof Error) {
    return {
      // 422 is unprocessable entity.
      // AC: Why Client code generating server errors?
      status_code: 422,
      result: null,
    };
  }

  return middleware.responses(body)(resJson);
};

export const onboardingApi = async (
  uriPath: string,
  body: Record<string, unknown>
): Promise<APIResponse> => {
  const fetchRes = await network(`${PLATGEN_ONBOARDING_URL}${uriPath}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Forwarded-Host': getHeaderForwardedHost(),
    },
    body: JSON.stringify(body),
  });

  if (isFetchError(fetchRes.status)) {
    return {
      status_code: fetchRes.status,
      result: null,
    };
  }
  const responseJSON: APIResponse = await fetchRes.json().catch(
    (error: Error): Error => {
      reporter.log(`Error when parsing json response`, error);
      return error;
    }
  );

  if (responseJSON instanceof Error) {
    return {
      // 422 is unprocessable entity.
      // AC: Why Client code generating server errors?
      status_code: 422,
      result: null,
    };
  }

  return responseJSON;
};

export type ForgotPassword = (
  email: string,
  product_id: number
) => Promise<APIResponse>;

export type RequestBodyForgotPassword = Readonly<{
  email: string;
  product_id: number;
}>;

export const sendRequestForForgotPassword: ForgotPassword = async (
  email,
  product_id
) => {
  const response = await onboardingApi(
    '/api/service_method_proxy/GeneralLogin/1/forgot_password',
    { email, product_id } as RequestBodyForgotPassword
  );

  return response;
};
