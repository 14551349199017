import { PathParams } from 'lib/player/getParamsFromPath';

const getPathForParams = ({
  productReference,
  sceneSetGraphId,
  sceneSetId,
  sceneId,
}: PathParams): string => {
  const params = [productReference, sceneSetGraphId, sceneSetId, sceneId];
  const path = params.filter(param => typeof param !== 'undefined').join('/');
  return `/${path}/`;
};

export default getPathForParams;
