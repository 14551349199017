const YEAR = new Date().getFullYear();

export const COPYRIGHT_TEXT = `© ${YEAR} Big Health`;
export const PRODUCT_STREAM_VERSION = 'XX'; // TODO https://bighealth.atlassian.net/browse/PG-1356
export const VERSION_TEXT = `Sleepio Onboarding Version ${PRODUCT_STREAM_VERSION}`;
export const GENERAL_ERROR_MESSAGE = `Something went wrong`;
export const TRY_AGAIN_LATER_ERROR_MESSAGE = `Something went wrong, please try again later`;
export const GENERAL_OTP_ERROR_MESSAGE =
  'There was an issue validating your verification code.';
export const INVALID_OTP_ERROR_MESSAGE =
  'Invalid verification code. Try again.';

export const UNIQUE_DEVICE_IDENTIFICATION = ''; // FIXME Set UDI of format (XX) XXXXXXXXXXXXXX X
