import React, { useRef } from 'react';
import { Platform, View } from 'react-native';

import AccessibilityWrapper from 'common/constants/accessibilityWrapper';
import { roles } from 'cross-platform/utils/roleProps';

import { CardPresentationProps } from '../CardPresentation';
import {
  IconByStatus,
  IconOverlay,
  LockIcon,
  LockText,
  TitleText,
  Touchable,
} from '../CardPresentation/styled';

import {
  Image,
  LabelText,
  OptionalContantWrapper,
  OptionalContent,
  Wrapper,
} from './styled';

type OptionalCardProps = Omit<
  CardPresentationProps,
  | 'heading'
  | 'status_text'
  | 'variant'
  | 'isFirstChild'
  | 'isLastChild'
  | 'isLarge'
>;

// If we make the content 'accessible' on iOS it would read the whole card without pausing which makes it a little harder to understand
const isOptionalContentAccessible = Platform.select<boolean>({
  ios: false,
  android: true,
});

const OptionalCard = ({
  status,
  scaleValue,
  onPress,
  play_action,
  title,
  duration,
  session_thumb_image_asset,
  lock_text,
}: OptionalCardProps): React.ReactElement => {
  const contentRef = useRef(null);
  const imageRef = useRef(null);

  return (
    <AccessibilityWrapper fieldsRefs={[imageRef, contentRef]}>
      <Wrapper scaleValue={scaleValue} status={status} style={{}}>
        <OptionalContantWrapper {...roles('OptionalCard')}>
          <View ref={imageRef}>
            <Image
              {...roles('OptionalContentImage')}
              src={session_thumb_image_asset.storage_url}
              assetSize={{
                width: session_thumb_image_asset.width,
                height: session_thumb_image_asset.height,
              }}
              alt={session_thumb_image_asset.alt_text}
            />
          </View>
          <OptionalContent
            accessible={isOptionalContentAccessible}
            importantForAccessibility="yes"
            ref={contentRef}
          >
            <TitleText
              style={{ fontWeight: 500, fontSize: 20, marginBottom: 8 }}
              scaleValue={scaleValue}
              {...title}
              {...roles('OptionalCardTitle')}
            />
            <LabelText scaleValue={scaleValue} {...duration}></LabelText>
            <Touchable
              onPress={onPress}
              disabled={!play_action?.type}
              accessible={true}
              accessibilityLabel={'Play ' + title?.text}
              accessibilityRole={'button'}
              {...roles('OptionalCardButton')}
              style={{
                position: 'absolute',
                bottom: 16,
                right: 24,
              }}
            >
              <IconByStatus
                status={status}
                scaleValue={scaleValue}
                iconSize={33}
              />
            </Touchable>
          </OptionalContent>
          {status === 'LOCKED' ? (
            <IconOverlay locked {...roles('OptionalCardLock')}>
              <LockIcon />
              <LockText {...lock_text} {...roles('OptionalLockText')} />
            </IconOverlay>
          ) : null}
        </OptionalContantWrapper>
      </Wrapper>
    </AccessibilityWrapper>
  );
};

export { OptionalCard };
