/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SceneChild } from '@bighealth/types';

import { ComponentMediaProps } from 'components/Media/types';
import {
  getNextSceneSiblingByPredicate,
  SceneComponentWithParent,
} from 'lib/player/tree-utils';

interface WithSourceProps extends SceneComponentWithParent {
  sourceProps?: {
    storage_url: string;
  };
}

type MaybeAVProps = ComponentMediaProps & SceneChild;

export function getIsAssetContinuous(
  componentProps:
    | MaybeAVProps
    | {
        __parent__?: SceneComponentWithParent;
      }
): boolean {
  const nextNode = getNextSceneSiblingByPredicate(
    // @TODO remove this casting via `unknown`
    // @WHEN when it becomes a problem
    // componentProps don't have `component` on them. But `SceneComponentWithParent`
    // requires it in the general sense. Casting here avoids some complex intermediate
    // types
    (componentProps as unknown) as SceneComponentWithParent,
    (rawSceneComponentWithParent: WithSourceProps) => {
      const scFrom = (rawSceneComponentWithParent.fromSeconds || 0) as number;
      const scType = rawSceneComponentWithParent.__component__;
      const scTo = rawSceneComponentWithParent.toSeconds as number;
      const scDelay = (rawSceneComponentWithParent.delaySeconds || 0) as number; // Fallback needed due to legacy content content issues
      const scSrc = rawSceneComponentWithParent.sourceProps?.storage_url;
      // @ts-ignore
      const avFrom = componentProps.fromSeconds as number;
      // @ts-ignore
      const avTo = componentProps.toSeconds as number;
      // @ts-ignore
      const avSrc = componentProps.sourceProps?.storage_url;
      return (
        // Can bail early by testing for whether it's an AV asset
        !!scType?.match(/Video|Audio/) &&
        // Check the source files are the same
        scSrc === avSrc &&
        // Check the current node isn't zero length
        avTo - avFrom > 0 &&
        // Check the next node isn't zero length
        scTo - scFrom > 0 &&
        // Check there is no delay
        scDelay === 0 &&
        // Check the next node starts where the last node left off
        scFrom === avTo
      );
    }
  );
  return !!nextNode;
}
