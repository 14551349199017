import { ProgressEntry } from '@bighealth/types/src';

import { RequiredFields } from 'lib/utils/generics/RequiredFields';

import { GoalLabelTexts } from '../..';

export type ProgressEntryCast = RequiredFields<ProgressEntry, 'icon'> & {
  label: ProgressEntry['label'] & { text: GoalLabelTexts };
};

/**
 * The current ProgressEntry type is too imprecise,
 * as ProgressGoalsV2 uses values for picking from maps.
 *
 * This function returns a locked-down version of this.
 *
 * It does this by guarding to ensure values are as expected, then casting
 *
 * @param {ProgressEntry} val
 * @returns {ProgressEntryCast} A more-specified version of the input
 */
export const guardAndCastProgressEntry = (
  val: ProgressEntry
): ProgressEntryCast => {
  // Guard - endpoint doesn't return empty
  // https://github.com/sleepio/sleepio-service-cluster/blob/1f84bad/src/big_health/services/sleepio_user_val/service.py#L187
  if (val.icon === undefined) {
    throw TypeError(`val.icon is undefined for ${JSON.stringify(val.label)}`);
  }
  // Cast
  const valWithIcon = val as RequiredFields<ProgressEntry, 'icon'>;

  // Guard
  if (
    !(
      valWithIcon.label.text === 'Emotional Goal' ||
      valWithIcon.label.text === 'Daytime Goal' ||
      valWithIcon.label.text === 'Overall Goal'
    )
  ) {
    throw TypeError(
      `val.label.text of "${valWithIcon.label.text}" is not expected allowed values of 'Emotional Goal', 'Daytime Goal', or 'Overall Goal'`
    );
  }
  // Cast
  const valWithValidLabel = valWithIcon as typeof valWithIcon & {
    label: ProgressEntry['label'] & { text: GoalLabelTexts };
  };
  return valWithValidLabel as ProgressEntryCast;
};
