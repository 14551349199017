import { ProductReferences } from 'common/constants/enums';

export const getProductBasedOnBundleId = (): ProductReferences => {
  throw new Error(
    'Unable to get product based on bundle ID unless in native context'
  );
};

// Note: strictly this isn't used in web since the default route should never happen
export const getDefaultRoute = (): string => `/${ProductReferences.SLEEPIO}`;
