import { TextStyle } from 'react-native';

import { ButtonTypes, TextTypes } from 'common/constants/enums';
import { QuizAttribute, SpeechTypes } from 'components/constants';
import {
  TabItemFlexType,
  TabItemTextSize,
} from 'components/Navigation/BottomNavigation/types';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { ButtonColorSchemesType } from 'components/UniveralButtons/buttonColorSchemes';
import {
  ButtonDimensions,
  ButtonSizes,
} from 'components/UniveralButtons/types';
import { CaptionState } from 'state/player/reducer';

import { FooterTheme } from './footerSchemesTypes';
import { HeadingLevel } from './headingSchemesTypes';
import { ModalSchemesType } from './modalSchemesTypes';
import { OverlayStyles } from './overlayTypes';
import { SpeechLocation } from './SpeechLocationSchemesTypes';
import { TextAreaStates, TextAreaStyles } from './textArea';
import { ThemeMode } from './themeMode';

export enum ControlAttrs {
  highlightAndIsSelected = 'highlightAndIsSelected', // In SD Figma design; not 100% actually happens
  isPressing = 'isPressing',
  isActive = 'isActive',
  isSelected = 'isSelected',
  isHovering = 'isHovering',
  /**
   * TODO FEG-254 Change highlight to a variant
   * HOW add to theme
   * WHEN want pressing/selected/hovering states for highlighted items
   * WHO anyone
   */
  highlight = 'highlight',
}

export type ControlStateTypes = {
  color: string;
  backgroundColor: string;
  borderColor: string;
  quiz: Record<
    QuizAttribute.CORRECT | QuizAttribute.INCORRECT,
    {
      color: string;
      backgroundColor: string;
      borderColor: string;
      labelColor?: string;
      editColor?: string;
      border?: string;
    }
  >;
  default: Record<
    | ControlAttrs.highlightAndIsSelected // cc:modstyle#5
    | ControlAttrs.highlight
    | ControlAttrs.isActive
    | ControlAttrs.isPressing
    | ControlAttrs.isSelected
    | ControlAttrs.isHovering,
    {
      color: string;
      backgroundColor: string;
      borderColor: string;
      labelColor?: string;
      editColor?: string;
      border?: string;
    }
  >;
};

export type HorizontalControlStateTypes = {
  color: string;
  backgroundColor: string;
  borderColor: string;
  default: Record<
    | ControlAttrs.highlightAndIsSelected
    | ControlAttrs.highlight
    | ControlAttrs.isActive
    | ControlAttrs.isPressing
    | ControlAttrs.isSelected
    | ControlAttrs.isHovering,
    {
      color: string;
      backgroundColor: string;
      borderColor: string;
      labelColor?: string;
      editColor?: string;
      border?: string;
    }
  >;
};

export type SleepDiaryResponseControlStateTypes = Record<
  ControlAttrs.isPressing | ControlAttrs.isHovering | 'default',
  {
    color: string;
    backgroundColor: string;
    borderColor: string;
    labelColor?: string;
    editColor?: string;
    border?: string;
  }
>;

/**
 * Roughly equivalent to variables.scss
 * @see {@link https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss}
 */
export type ThemeValuesType = {
  color: {
    primary: string;
    primaryLight: string;
    primaryDark: string;
    primaryGrey: string;
    lightGray?: string;
    error: string;
    black: string;
    white: string;
    hint: string;
    warning?: string;
    hoverBlue1?: string;
    midBlue?: string;
    success: string;
    text: {
      primary: string;
      primaryInverse: string;
      placeholder?: string;
      splashPage?: string;
      link?: string;
      primarySharedInverse?: string;
    };
    textGoogle: {
      primary: string;
      secondary: string;
    };
    textFacebook: {
      primary: string;
      secondary: string;
    };
    textLocalNotifications?: {
      primary: string;
      secondary: string;
    };
    overlay: {
      primary: string;
    };
    // TODO: find a better way to not pollute the common type
    inputOutlineGrey?: string;
    selectedPink?: string;
    disabledInputGrey?: string;
    deselectedGrey?: string;
    mediumGrey?: string;
    background: {
      light: string;
      medium: string;
      dark: string;
      imageFallback: string;
      component?: string;
      sleepDiaryForm?: string;
      sleepEfficiencyCard?: string;
    };
  };
  bottomNavigation: {
    backgroundColor: string;
    textColor: string;
    textHighlight: string;
    tabItemTextSize: TabItemTextSize;
    tabItemSizeType?: TabItemFlexType;
  };
  localNotifications?: {
    borderColor: string;
  };
  heading: Record<
    | HeadingLevel.LEVEL1
    | HeadingLevel.LEVEL2
    | HeadingLevel.LEVEL3
    | HeadingLevel.LEVEL4
    | HeadingLevel.LEVEL5
    | HeadingLevel.LEVEL6,
    { fontSize: number; paddingBottom: number }
  >;
  text: {
    fontFamily: string;
    nativeFontFamily: typeof FontFamilies[keyof typeof FontFamilies];
    mono: {
      fontFamily: string;
    };
  } & Record<
    | TextTypes.CONTENT
    | TextTypes.DEFAULT
    | TextTypes.ERROR
    | TextTypes.HINT
    | TextTypes.SUCCESS
    | TextTypes.WARNING,
    {
      color: string;
      paddingHorizontal?: number;
      paddingVertical: number;
      fontSize: number | undefined;
      fontWeight?: TextStyle['fontWeight'];
    }
  >;

  footer: Record<
    FooterTheme.Light | FooterTheme.Dark,
    { color: string; backgroundColor: string }
  >;
  controls: {
    player: {
      color: string;
      iconColor: string;
      progressColor: string;
      backgroundColor: string;
      progressColorDim: string;
      smallTitleTextWeight: number;
      captionIconButton: {
        [k in Exclude<CaptionState, CaptionState.HIDDEN>]: Partial<{
          color: string;
          backgroundColor: string;
        }>;
      };
      captionSelect: {
        backgroundColor: string;
        color: string;
        colorDim: string;
      };
    };
    closeButton: {
      iconSize: number;
      showText: boolean;
      iconColor: string;
      textColor: string;
      backgroundColor: string;
    };
    input?: ControlStateTypes;
    // Styles for the textArea component
    textArea: TextAreaStyles & {
      // State styles are merged with and override the base styles
      // in textArea when the component is in the given state
      states: Partial<Record<TextAreaStates, TextStyle>>;
    };
    selection: ControlStateTypes; // cc:modstyle#4
    horizontalSelection?: HorizontalControlStateTypes;
    sleepDiaryResponse?: SleepDiaryResponseControlStateTypes;
    color: string;
    backgroundColor: string;
    borderColor: string;
    borderRadius: number;
    borderWidth: string;
    borderStyle: string;
    paddingHorizontal: number;
    paddingHorizontalLarge: number;
    paddingVertical: number;
    paddingVerticalLarge: number;
    fontSize: number;
    fontSizeLarge: number;
    opacity: number;
    ctaMinWidth: number;
    ctaMaxWidth: number;
    ctaFixedWidth: number;
    isDisabled: {
      opacity: number;
    };
  };
  buttons?: ButtonColorSchemesType & {
    dimensions: Record<ButtonSizes.Small, ButtonDimensions>;
  };
  modal: ModalSchemesType;
  loadingOverlay: Record<ThemeMode, OverlayStyles>;
};

/**
 * Roughly equivalent to bootstrap.scss
 * * @see {@link https://github.com/twbs/bootstrap/blob/v4-dev/scss/bootstrap.scss}
 * * @see {@link https://github.com/twbs/bootstrap/blob/v4-dev/scss/_buttons.scss}
 */
/**
 * TODO fix theme types
 *  @see {@link https://github.com/styled-components/styled-components/issues/1589}
 */

export type Theme = {
  color: ThemeValuesType['color'];
  heading: ThemeValuesType['heading'];
  text: ThemeValuesType['text'];
  bottomNavigation: ThemeValuesType['bottomNavigation'];
  localNotifications?: ThemeValuesType['localNotifications'];
  footer: Record<
    FooterTheme.Light | FooterTheme.Dark,
    { color: string; backgroundColor: string }
  >;
  homeScreen: {
    backgroundColor: string;
    titleColor: string;
  };
  overlay: {
    color: string;
    backgroundColor: string;
    headerColor: string;
    headerBackgroundColor: string;
  };
  speech: Record<
    SpeechTypes.DEFAULT | SpeechTypes.COLUMN_INFO,
    {
      [SpeechLocation.LEFT]: {
        marginLeft: number;
      };
      [SpeechLocation.RIGHT]: {
        marginRight: number;
      };
    }
  >;
  layout: {
    paddingHorizontal: number;
    paddingVertical: number;
  };
  content: {
    backgroundColor: string;
    // TODO Move back to root
    // WHEN old  controls is removed
    controls: ThemeValuesType['controls'];
  };
  // TODO REMOVE old controls
  // WHEN ASAP
  controls: ThemeValuesType['controls'] & {
    closeButtonColor: string;
  } & Record<
      | ButtonTypes.FACEBOOK_BUTTON
      | ButtonTypes.GOOGLE_BUTTON
      | ButtonTypes.LARGE_CTA
      | ButtonTypes.PRIMARY_BUTTON
      | ButtonTypes.SECONDARY_BUTTON,
      {
        backgroundColor: string;
        borderWidth: string;
        borderStyle: string;
        borderRadius: number;
        borderColor: string;
        color: string;
        fontSize: number;
        paddingHorizontal: number;
        paddingVertical: number;
        minWidth: number;
        maxWidth: number;
        width: number;
      }
    >;
  buttons?: ThemeValuesType['buttons'];
  modal: ThemeValuesType['modal'];
  loadingOverlay: ThemeValuesType['loadingOverlay'];
};

// Equivalent to theme.scss (Semantic - raw values only for static)
const createTheme = ({
  color,
  footer,
  controls, // cc:modstyle#3
  heading,
  text,
  bottomNavigation,
  localNotifications,
  buttons,
  modal,
  loadingOverlay: pendingOverlay,
}: ThemeValuesType): Theme => ({
  color,
  bottomNavigation: bottomNavigation,
  localNotifications: localNotifications,
  footer: {
    [FooterTheme.Light]: {
      color: footer[FooterTheme.Light].color,
      backgroundColor: footer[FooterTheme.Light].backgroundColor,
    },
    [FooterTheme.Dark]: {
      color: footer[FooterTheme.Dark].color,
      backgroundColor: footer[FooterTheme.Dark].backgroundColor,
    },
  },
  heading: {
    [HeadingLevel.LEVEL1]: {
      fontSize: heading[HeadingLevel.LEVEL1].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL1].paddingBottom,
    },
    [HeadingLevel.LEVEL2]: {
      fontSize: heading[HeadingLevel.LEVEL2].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL2].paddingBottom,
    },
    [HeadingLevel.LEVEL3]: {
      fontSize: heading[HeadingLevel.LEVEL3].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL3].paddingBottom,
    },
    [HeadingLevel.LEVEL4]: {
      fontSize: heading[HeadingLevel.LEVEL4].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL4].paddingBottom,
    },
    [HeadingLevel.LEVEL5]: {
      fontSize: heading[HeadingLevel.LEVEL5].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL5].paddingBottom,
    },
    [HeadingLevel.LEVEL6]: {
      fontSize: heading[HeadingLevel.LEVEL6].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL6].paddingBottom,
    },
  },
  text,
  overlay: {
    backgroundColor: color.overlay.primary,
    headerColor: color.primaryLight,
    headerBackgroundColor: color.primaryDark,
    color: color.text.primary,
  },
  homeScreen: {
    backgroundColor: color.primaryGrey,
    titleColor: color.text.primary,
  },
  speech: {
    [SpeechTypes.DEFAULT]: {
      [SpeechLocation.LEFT]: {
        marginLeft: controls.paddingHorizontal,
      },
      [SpeechLocation.RIGHT]: {
        marginRight: controls.paddingHorizontal,
      },
    },
    [SpeechTypes.COLUMN_INFO]: {
      [SpeechLocation.LEFT]: {
        marginLeft: controls.paddingHorizontalLarge,
      },
      [SpeechLocation.RIGHT]: {
        marginRight: controls.paddingHorizontalLarge,
      },
    },
  },
  layout: {
    paddingHorizontal: controls.paddingHorizontal,
    paddingVertical: controls.paddingVertical,
  },
  content: {
    backgroundColor: color.text.primaryInverse,
    // TODO Move back to root
    // WHEN old controls is removed
    controls,
  },
  // TODO REMOVE old controls
  // WHEN ASAP
  controls: {
    closeButtonColor: color.primaryLight,
    [ButtonTypes.LARGE_CTA]: {
      backgroundColor: color.primaryLight,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: controls.borderColor,
      borderRadius: controls.borderRadius,
      color: color.primary,
      fontSize: controls.fontSize,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.PRIMARY_BUTTON]: {
      backgroundColor: color.text.primary,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: controls.borderColor,
      borderRadius: controls.borderRadius,
      color: color.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSizeLarge,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.SECONDARY_BUTTON]: {
      backgroundColor: 'transparent',
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: color.text.primary,
      borderRadius: controls.borderRadius,
      color: color.text.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVertical,
      fontSize: controls.fontSize,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.FACEBOOK_BUTTON]: {
      backgroundColor: color.textFacebook.secondary,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: color.textFacebook.secondary,
      borderRadius: controls.borderRadius,
      color: color.text.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVertical,
      fontSize: controls.fontSize,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.GOOGLE_BUTTON]: {
      backgroundColor: color.textGoogle.primary,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: color.text.primary,
      borderRadius: controls.borderRadius,
      color: color.textGoogle.secondary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVertical,
      fontSize: controls.fontSize,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    // cc:modstyle#2 passing in structured objects is poor lexical flow
    ...controls,
  },
  buttons: buttons,
  modal: modal,
  loadingOverlay: pendingOverlay,
});
/**
 *
 *  TODO enable proper TypeScript support (Sooner the better)
 *  WHEN have time to fix type errors it uncovers
 *  WHY
 *
 *  before:
 *  ```js
 *  const WarningText = styled(Text)`
 *    color: ${(p: ThemeProps) => p.theme.text[TextTypes.WARNING].color};
 *  `;
 *  ```
 *  after:
 *  ```js
 *  const WarningText = styled(Text)`
 *    color: ${p => p.theme.text[TextTypes.WARNING].color};
 *  `;
 *  ```
 */
// declare module 'styled-components' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   export interface DefaultTheme extends Theme {} // https://github.com/styled-components/styled-components-website/issues/447
// }

export { createTheme };
