import { Question } from '@bighealth/types';

import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';

// TODO Use this in ResponseForm
// WHY the sibling castResponseOptionByResponseType, and its only used in SleepDiaryForm
// WHEN want to pay off some debt
// TODO Create generic using castResponseOptionByResponseType
// WHY its the same code
// WHEN we modify both together
const castDropdownItemsByResponseType = (
  items: Record<QuestionId, DropdownItem[]>,
  questions: Question[]
): Record<QuestionId, DropdownItem[]> => {
  const newItems: typeof items = {};
  for (const [semanticId, itemValues] of Object.entries(items)) {
    const question = questions.find(q => q.semantic_id === semanticId);
    if (typeof question === 'undefined') {
      throw Error(
        `No question found that has semantic_id of ${semanticId} in [${questions
          .map(q => `"${q.semantic_id}"`)
          .join(', ')}]`
      );
    }
    newItems[semanticId] =
      question.response_type.toLowerCase() === 'number'
        ? itemValues.map(item => {
            const newItemValue = Number(item.value);
            if (isNaN(newItemValue)) {
              throw TypeError(
                `Expected item.value for semantic_id ${semanticId} (${item.value}) to cast to number, instead got NaN`
              );
            }
            return {
              ...item,
              value: newItemValue,
            };
          })
        : itemValues;
  }
  return newItems;
};

export { castDropdownItemsByResponseType };
