import React, { ReactElement, useState } from 'react';

import { roles } from 'cross-platform/utils/roleProps';
import { ResponseImage } from 'sleepio/components/screens/SessionsScreen/components/Presentation/components/DiaryCards/components/DiaryQuestion/Response/styled/styled';

import { SelectHorizontalItemProps } from '../HorizontalScale/types';

import { getButtonInteractionProps } from './utils/getButtonInteractionProps';
import { DOMAIN } from './constants';
import { ButtonStatic, LabelText, LabelView, Wrapper } from './styled';

export const SelectHorizontalItem = ({
  selected,
  value,
  onSelect,
  displayText,
  image,
  highlight,
  onLayout,
  style,
  isFirst,
  isLast,
  isFixed,
}: SelectHorizontalItemProps): ReactElement => {
  const [isPressing, setIsPressing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Wrapper
      onLayout={onLayout}
      style={style}
      isFirst={isFirst}
      isLast={isLast}
    >
      <ButtonStatic
        testID={`${DOMAIN}-${value}:${selected ? 'selected' : 'unselected'}`}
        isSelected={selected}
        isHovering={isHovering}
        isPressing={isPressing}
        {...getButtonInteractionProps({
          value,
          onSelect,
          setIsPressing,
          setIsHovering,
        })}
        activeOpacity={1}
        highlight={highlight}
        isFixed={isFixed /* internal padding */}
        accessibilityRole="button"
        accessibilityState={{ selected }}
        role="button"
        aria-pressed={selected}
        aria-label={displayText} // this ariaLabel is needed it, after add ariaHidden to solve windows accessibility issues
      >
        <LabelView>
          {image ? (
            <ResponseImage
              {...roles(`img`)}
              src={image.storage_url}
              alt={image.alt_text || ''}
            />
          ) : null}
          <LabelText
            isHovering={isHovering}
            isPressing={isPressing}
            isSelected={selected}
            highlight={highlight}
            {...roles('p', { role: 'paragraph' })}
            aria-hidden={true} // to solve windows accessibility issues
          >
            {displayText}
          </LabelText>
        </LabelView>
      </ButtonStatic>
    </Wrapper>
  );
};
