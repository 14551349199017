import React from 'react';
import { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '../store';

const ReduxProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>{children}</PersistGate>
  </Provider>
);

export default ReduxProvider;
