import { QuestionSetPayload } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const read = async (
  args: read.Args,
  requestConfig?: AxiosRequestConfig
): Promise<read.Response> => {
  const request: read.Request = {
    service_name: 'QuestionSet',
    service_version: '2',
    service_method: 'read',
    args,
  };

  return await api(request, requestConfig);
};

read.queryKey = 'QuestionSet/read';

export declare namespace read {
  export type Args = {
    entity_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'QuestionSet';
    service_version: '2';
    service_method: 'read';
    args: Args;
  }

  export type Result = QuestionSetPayload;

  export type Response = APIResponse<Result>;
}
