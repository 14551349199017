import storage from '@react-native-community/async-storage';
import { Action, CombinedState, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { DomainIDs } from 'common/constants/enums';
import { redirectorReducer } from 'cross-platform/react-router/Router/utils/Redirector/state/reducer';
import * as reporter from 'lib/reporter';
import { contentModalReducer } from 'state/content-modal/reducer';
import { debugReducer } from 'state/debug/reducer';
import {
  notificationsPersistConfig,
  notificationsReducer,
} from 'state/notifications/reducer';
import { authPersistConfig, playerReducer } from 'state/player/reducer';
import { questionPathReducer } from 'state/question-path/reducer';
import { sleepDiaryModalReducer } from 'state/sleep-diary-modal/reducer';
import { STATE_RESET } from 'state/store/actions';
import { userPersistConfig, userReducer } from 'state/user/reducer';

import { downloaderReducer } from '../downloader/reducer';
import { questionResponseReducer } from '../question-response/reducer';

import { GlobalState, initialState } from './initialState';

export const rootReducersCombined = combineReducers({
  [DomainIDs.USER]: persistReducer(userPersistConfig, userReducer),
  [DomainIDs.PLAYER]: persistReducer(authPersistConfig, playerReducer),
  [DomainIDs.DOWNLOADER]: downloaderReducer,
  [DomainIDs.QUESTION_RESPONSE]: questionResponseReducer,
  [DomainIDs.QUESTION_PATH]: questionPathReducer,
  [DomainIDs.REDIRECTOR]: redirectorReducer,
  [DomainIDs.CONTENT_MODAL]: contentModalReducer,
  [DomainIDs.SLEEP_DIARY_MODAL]: sleepDiaryModalReducer,
  [DomainIDs.NOTIFICATIONS]: persistReducer(
    notificationsPersistConfig,
    notificationsReducer
  ),
  [DomainIDs.DEBUG]: debugReducer,
});

type CombinedStateType = CombinedState<GlobalState>;

const rootReducer = (
  state: CombinedStateType,
  action: Action
): CombinedStateType => {
  if (action.type === STATE_RESET) {
    try {
      // @NOTE: These are async, if they fail they'll
      // bubble up to this try/catch as an unhandled promise rejection
      // and we handle here. Might be worth moving this into
      // the component that implements the logout action.
      storage.removeItem('persist:auth');
      // TODO: uncomment me
      // WHEN: we would want to reset the cache
      // NOW: we only store state[DomainIDs.USER] in cache now and it should not be reset
      // storage.removeItem('persist:root');

      // persisting the user's state during logout
      initialState[DomainIDs.USER].isFirstLogin =
        state[DomainIDs.USER].isFirstLogin;

      // persisting the notification state during logout
      initialState[
        DomainIDs.NOTIFICATIONS
      ].osNotificationPermissionsRequestCount =
        state[DomainIDs.NOTIFICATIONS].osNotificationPermissionsRequestCount;
    } catch (storageError) {
      reporter.log(
        'An error occurred while trying to clear state',
        storageError
      );
    }
    return rootReducersCombined(initialState, action);
  } else {
    return rootReducersCombined(state, action);
  }
};
export default rootReducer;
