import { useCallback, useEffect, useState } from 'react';

import { LoginDomains } from '@bighealth/types';

import {
  UseServiceMethodCall,
  useServiceMethodCall,
} from 'lib/api/hooks/useServiceMethodCall';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import { getPlatform } from 'lib/platform';
import * as reporter from 'lib/reporter';

import { ActionTypes } from '../useServiceMethodCall/ActionTypes';

export const errorLogMessages = {
  authenticate: 'Authenticate with OIDC attempt failed',
  authTokensRequest: 'Request for auth tokens failed.',
  authTokensResponse: 'Empty auth token response.',
};

export const useAuthenticateWithOIDC = () => {
  const productId = useQueryProduct()?.data?.result.id;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [oidcAuthTokensState, requestOidcAuthTokens] = useServiceMethodCall({
    serviceName: 'GeneralLogin',
    serviceVersion: '1',
    serviceMethod: 'login_with_oidc_auth_code',
    args: {},
  }) as ReturnType<UseServiceMethodCall>;

  const handleError = (errorMsg: string, error?: Error) => {
    setLoading(false);
    setError(true);
    reporter.log(errorMsg, error, { silent: true });
  };

  // -----------------------------------------------------------------
  // Authenticate with PlatGen using code and state returned from OIDC provider
  // -----------------------------------------------------------------
  const authenticate = useCallback(
    async (oidc_code: string, oidc_state: string) => {
      try {
        setLoading(true);
        await requestOidcAuthTokens({
          oidc_code,
          oidc_state,
          product_id: productId,
          device_platform: getPlatform(),
        });
      } catch (err) {
        handleError(errorLogMessages.authenticate, err);
      }
    },
    [requestOidcAuthTokens, productId]
  );

  // Handle receiving the tokens.
  useEffect(() => {
    switch (oidcAuthTokensState?.status) {
      case ActionTypes.FETCHING:
        setLoading(true);
        setError(false);
        break;
      case ActionTypes.SUCCESS:
        if (oidcAuthTokensState.response?.result) {
          setLoading(false);
          setError(false);

          // Status is either 'redirect' or 'error'
          const resultStatus = oidcAuthTokensState.response.result?.status;
          // Domain for OIDC login will either be 'platform' or 'onboarding'
          const resultDomain = oidcAuthTokensState.response.result?.domain;

          // The onboarding domain signifies that the user needs to be redirect
          // to complete the flow before authenticating into the app.
          if (resultDomain === LoginDomains.ONBOARDING) {
            setRedirect(oidcAuthTokensState.response.result?.redirect);
          } else if (resultStatus === 'error') {
            // Response with an 'error' result status is likely caused when the
            // OIDC provider login is successful but the platform user does not
            // exist.
            const errorMsg =
              oidcAuthTokensState.response.result?.message ||
              errorLogMessages.authTokensRequest;
            handleError(errorMsg);
          } else {
            setAuthenticated(true);
          }
        } else {
          handleError(errorLogMessages.authTokensResponse);
        }
        break;
      case ActionTypes.ERROR: {
        handleError(errorLogMessages.authTokensRequest);
        break;
      }
      default:
        setLoading(false);
        setError(false);
        break;
    }
  }, [oidcAuthTokensState]);

  return {
    loading: loading,
    error: error,
    isAuthenticated: isAuthenticated,
    redirect: redirect,
    authenticate,
  };
};
