import styled from 'styled-components/native';

import { Col } from 'components/layout/Grid';
import { ButtonStates } from 'lib/styles/inputStateStyles';
import {
  NavButton as NavButtonUnstyled,
  NavButtonProps,
} from 'sleepio/components/screens/SessionsScreen/components/Presentation/components/SessionList/components/NavButton';

import {
  ARROW_WIDTH,
  FIXED_WIDTH,
  FONT_SIZE,
  SPACE_BETWEEN,
} from '../SelectHorizontal/constants';
import { SelectHorizontalItem } from '../SelectHorizontal/SelectHorizontalItem';

import { SELECT_HORIZONTAL_TIME_DOMAIN } from './constants';

export const Wrapper = styled.View`
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
`;
Wrapper.displayName = `${SELECT_HORIZONTAL_TIME_DOMAIN}.Wrapper`;

export const Option = styled(SelectHorizontalItem)<ButtonStates>`
  flex-grow: 0;
  flex-shrink: 0;
`;
Option.displayName = `${SELECT_HORIZONTAL_TIME_DOMAIN}.Option`;

export const Spacer = styled.View<ButtonStates>`
  visibility: none;
  margin-bottom: ${SPACE_BETWEEN}px;
  min-height: ${/* Eye-balled; Not perfect */ FONT_SIZE + SPACE_BETWEEN + 1}px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${FIXED_WIDTH};
`;
Spacer.displayName = `${SELECT_HORIZONTAL_TIME_DOMAIN}.Spacer`;

export const NavButton = styled(NavButtonUnstyled).attrs({
  width: ARROW_WIDTH,
  backgroundColor: 'transparent',
  foregroundColor: '#6A717D',
  scale: 1.5,
})<{ rows?: number } & NavButtonProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => (props.rows || 1) * SPACE_BETWEEN}px;
  width: ${props => props.width}px;
`;
NavButton.displayName = `${SELECT_HORIZONTAL_TIME_DOMAIN}.NavButton`;

export const ColNavButton = styled(Col).attrs({
  width: 'auto',
  flexShrink: 1,
  flexGrow: 0,
  alignSelf: 'center',
})`
  min-width: ${ARROW_WIDTH}px;
`;
ColNavButton.displayName = `${SELECT_HORIZONTAL_TIME_DOMAIN}.ColNavButton`;

export const ColOptions = styled.View`
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: column;
`;
ColOptions.displayName = `${SELECT_HORIZONTAL_TIME_DOMAIN}.ColOptions`;

export const RowOption = styled.View<{ isFirst?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
`;
RowOption.displayName = `${SELECT_HORIZONTAL_TIME_DOMAIN}.RowOption`;
