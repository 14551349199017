/**
 * @TODO move this method to reporter folder
 * @WHEN we will have a second use for this function
 */
export const withConfirm = (
  _title: string,
  message: string,
  callback: () => void
): void => {
  if (window.confirm(message)) {
    callback();
  }
};
