import * as React from 'react';
import { ReactElement, useContext } from 'react';
import { Circle, Line, Polyline, Svg, Text } from 'react-native-svg';
import { ThemeContext } from 'styled-components/native';

import { FontFamilies } from 'components/sceneset-components/Text/fonts';

import { chartUtils } from './chartUtils';
import { LineChartProps } from './types';

export const LineChart = (props: LineChartProps): ReactElement => {
  const {
    baseHeight,
    baseWidth,
    convertXToUnits,
    convertYToUnits,
    data,
    lineColor,
    lineStrokeWidth,
    markerRadius,
    // minY:, @TODO Add this when we need it
    ruleColor,
    ruleStrokeWidth,
    yTicksCount,
  } = props;

  const { scaleX, flipAndScaleY, linePoints } = chartUtils(props);
  const theme = useContext(ThemeContext);

  return (
    <Svg
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      preserveAspectRatio="xMinYMin"
      // react-native-svg claims this property doesn't exist, but it does. Just using ts-ignore here rather than
      // adding extra type definitions for the library
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      overflow={'visible'}
      style={{
        width: '100%',
      }}
    >
      {Array(yTicksCount)
        .fill(null)
        .map((_, index) => (
          // The horizontal rule lines
          <Line
            key={index}
            x1={0}
            x2={baseWidth}
            y1={(index * baseHeight) / (yTicksCount - 1)}
            y2={(index * baseHeight) / (yTicksCount - 1)}
            vectorEffect="non-scaling-stroke"
            stroke={ruleColor}
            strokeWidth={ruleStrokeWidth}
            opacity={0.29}
          />
        ))}

      {data
        ? data.map(([x, y], index) => {
            if (
              typeof x === 'undefined' ||
              typeof y === 'undefined' ||
              y === null
            ) {
              return null;
            }
            return (
              // The dots
              <Circle
                key={`${x}-${y}`}
                r={markerRadius}
                fill={lineColor}
                cx={scaleX(index)}
                cy={flipAndScaleY(y)}
              />
            );
          })
        : null}

      {data
        ? data.map(([x, y], index) => {
            if (
              x === 0 ||
              typeof x === 'undefined' ||
              y === null ||
              typeof y === 'undefined'
            ) {
              return null;
            }
            return (
              // The text labels above the data points
              <Text
                fontFamily={FontFamilies.Regular}
                key={`${x}-${y}`}
                x={scaleX(index)}
                y={flipAndScaleY(y) - 20}
                fontSize={24}
                textAnchor={'middle'}
                fill={lineColor}
              >
                {convertYToUnits(y)}
              </Text>
            );
          })
        : null}

      {data
        ? data.map(([x], index) => {
            return (
              // The x-axis labels
              <Text
                fontWeight={700}
                fontFamily={FontFamilies.Regular}
                key={x || index}
                x={scaleX(index)}
                y={baseHeight + 30}
                textAnchor={'middle'}
                fill={theme.color.overlay.primary}
                fontSize={24}
              >
                {convertXToUnits(x)}
              </Text>
            );
          })
        : null}

      {data ? (
        <Polyline
          points={linePoints}
          fill={'none'}
          stroke={lineColor}
          strokeWidth={lineStrokeWidth}
        />
      ) : null}
    </Svg>
  );
};
