import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'cross-platform/react-router';
import * as reporter from 'lib/reporter';
import { getPendingRedirection } from 'state/Redirector/selectors';

import {
  Redirection,
  redirectionComplete,
  routeChanged,
} from './state/actions';

/**
 * The deferred redirection behavior needs access to values
 * in the render loop - this component does that.
 *
 * 1. Dispatches route changed actions for redirection reducer
 * 2. If a pending redirection appears in redux state, it redirects
 */
const Redirector = (): null => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pending: Redirection | null = useSelector(getPendingRedirection);

  useEffect(() => {
    const { pathname, search, hash, key } = history.location;
    const location = { pathname, search, hash, key };
    dispatch(routeChanged({ location, action: 'PUSH' }));
  }, [dispatch, history.location]);

  useEffect(() => {
    if (pending) {
      reporter.debug('Pushing ', pending.location);
      history.push(pending.location);
      dispatch(redirectionComplete());
    }
  }, [dispatch, history, pending]);

  return null;
};

export { Redirector };
