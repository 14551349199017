import React, { ReactElement, useContext } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';
import { ThemeContext } from 'styled-components/native';

import { CloseIcon } from 'daylight/components/icons';

import {
  HeaderContentWrapper,
  HeaderTitle,
  HeaderWrapper,
  IconWrapper,
} from './Header.styled';

export const Header = ({
  text,
  headerSize,
  closeIconSize,
  closeIconSide = 'right',
  handleClose,
}: {
  closeIconSide?: 'right' | 'left';
  text: string;
  headerSize: number;
  closeIconSize: number;
  handleClose?: () => void;
}): ReactElement => {
  const { top } = useSafeArea();
  const theme = useContext(ThemeContext);
  return (
    <HeaderWrapper paddingTop={top}>
      <HeaderContentWrapper>
        <HeaderTitle fontSize={headerSize}>{text}</HeaderTitle>
        {handleClose ? (
          <IconWrapper side={closeIconSide}>
            <CloseIcon
              size={closeIconSize}
              color={theme.text.DEFAULT.color}
              onPress={handleClose}
            />
          </IconWrapper>
        ) : null}
      </HeaderContentWrapper>
    </HeaderWrapper>
  );
};
