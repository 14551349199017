import * as React from 'react';
import { ScrollView } from 'react-native';

import { ScrollToArg, useScrollTo } from '../../../utils/hooks/useScrollTo';
import { useViewApi, ViewNodeApi } from '../../../utils/hooks/useViewApi';

export type ItemCarouselToolsOptions = {
  remeasure: number;
  createScrollTo: (carousel: ItemCarouselTools) => ScrollToArg;
};

export type ItemCarouselTools = {
  scrollerWrapperApi: ViewNodeApi;
  targetItemApi: ViewNodeApi;
  itemListApi: ViewNodeApi;
  scrollerRef: React.RefObject<ScrollView>;
  previousScrollTo?: ScrollToArg;
};
const useItemCarouselTools = (
  options: ItemCarouselToolsOptions
): ItemCarouselTools => {
  const { remeasure } = options;
  const scrollerRef = React.useRef<ScrollView>(null); // scrollTo called on this
  const scrollerWrapperApi = useViewApi({ remeasure });
  const targetItemApi = useViewApi({ remeasure });
  const itemListApi = useViewApi({ remeasure });

  const tools: ItemCarouselTools = {
    targetItemApi,
    itemListApi,
    scrollerWrapperApi,
    scrollerRef,
  };
  const { previous } = useScrollTo(tools, options, remeasure) || {};
  return {
    ...tools,
    previousScrollTo: previous?.update,
  };
};

export { useItemCarouselTools };
