import React, { ReactElement } from 'react';
import Svg, { Path } from 'react-native-svg';

/**
 * Play Button icon
 * @param width: the width of the icon
 * @param height: the height of the icon
 * @param color: the fill color of the icon
 */

export const PlayButtonIcon = ({
  width,
  height,
  color,
}: {
  width?: number;
  height?: number;
  color?: string;
}): ReactElement => {
  const viewBox = width && height && `0 0 ${width} ${height}`;

  return (
    <Svg
      width={width || '12'}
      height={height || '13'}
      viewBox={viewBox || '0 0 12 13'}
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M2.41532 0.96875C2.16181 0.96875 1.91255 1.03337 1.69105 1.15642C1.4655 1.2714 1.27512 1.44519 1.1401 1.6595C1.00314 1.8769 0.928383 2.12768 0.923956 2.38458L0.923892 2.39196V11.5291L0.923956 11.5365C0.928383 11.7934 1.00314 12.0442 1.1401 12.2616C1.27512 12.4759 1.4655 12.6497 1.69105 12.7647C1.91255 12.8877 2.16181 12.9523 2.41532 12.9523C2.67242 12.9523 2.92516 12.8859 3.149 12.7594C3.15393 12.7566 3.1588 12.7537 3.16361 12.7507L10.5606 8.17577C10.7849 8.06272 10.9742 7.89049 11.1081 7.67751C11.2462 7.45757 11.3196 7.20312 11.3196 6.94339C11.3196 6.68367 11.2462 6.42921 11.1081 6.20928C10.9741 5.99608 10.7844 5.82371 10.5599 5.71067L3.16239 1.16958C3.15798 1.16687 3.15351 1.16424 3.149 1.16169C2.92516 1.03521 2.67242 0.96875 2.41532 0.96875Z"
        fill={color || 'white'}
      />
    </Svg>
  );
};
