import React from 'react';
import { Button } from 'react-native';

import { DebugThrow } from 'components/sceneset-components/DebugThrow';
import { isDevMode } from 'lib/isDevMode';

function DebugButtonThrow(props: {
  error?: Error;
  title?: string;
}): JSX.Element | null {
  const [isThrow, setIsThrow] = React.useState(false);
  if (isDevMode() !== true) {
    return null;
  }
  if (isThrow) {
    return <DebugThrow error={props.error} />;
  }
  return (
    <Button
      title={props.title || 'Throw error'}
      onPress={() => setIsThrow(true)}
    />
  );
}

export { DebugButtonThrow };
