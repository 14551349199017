import { DropdownItem } from 'components/generic-question/Dropdown';
import {
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
} from 'components/SleepDiaryForm/constants';
import { HOUR } from 'lib/durations';
import { QuestionId } from 'state/question-response/actions';

import { getSelected } from '../../helpers/getSelected';
import { Config } from '..';

import { createQuestionProps } from './helpers/createQuestionProps';
import { getDiaryDate } from './helpers/getDiaryDate';
/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getTryToSleepConfigFromIntoBed = (
  inputs: Record<QuestionId, DropdownItem[]>,
  diaryDate?: Date
): Record<QuestionId, Config> | null => {
  const timeInBed = getSelected(inputs[TIME_INTO_BED])?.valueOf();
  if (typeof timeInBed !== 'number') {
    return null;
  }
  const min = timeInBed;
  const max = timeInBed + 8 * HOUR;

  diaryDate = getDiaryDate(timeInBed, diaryDate);

  return {
    [TIME_TRY_TO_SLEEP]: createQuestionProps(min, max, diaryDate),
  };
};

export { getTryToSleepConfigFromIntoBed };
