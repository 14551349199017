import { SceneActionTypes } from '@bighealth/types/dist/enums';

import { ScheduleNextSessionProps } from '../components/FormState';

export const mockFormPresentationProps: ScheduleNextSessionProps = {
  title: {
    text: 'Schedule your next session',
  },
  schedule_delay_in_minutes: 10,
  questionProps: {
    id: 4,
    optional: false,
    question_pre_text: '',
    question_title: undefined,
    language: 'en',
    version: '1',
    visual_representation_markup: '<markup/>',
    response_config: {
      min_selections_required: 1,
      max_selections_required: 1,
      default_response_ids: [],
      correct_response_ids: [],
      max_number_attempts: 1,
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '1': { type: 'number' },
          selections: { type: 'array', maxItems: 1, minItems: 0 },
        },
        description: 'answer option values',
      },
      response_options: [
        {
          id: 1,
          semantic_id: 'datepicker',
          display_text: '',
          label: '',
          value: '$input',
        },
      ],
    },
    response_type: 'datetime',
    semantic_id: 'schedule_next_session',
  },
  initialValue: new Date(1605266045794),
  submit_button: {
    text: 'Save',
    component: 'Button',
    action: {
      type: SceneActionTypes.SUBMIT,
    },
  },
  continue_button: {
    text: 'Continue',
    component: 'Button',
    action: {
      type: SceneActionTypes.SUBMIT,
    },
  },
};
