import React, { ReactElement, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';

import { useBlurQuestion } from 'components/Modal/useBlurQuestion';
import { RoleProps } from 'cross-platform/utils/roleProps';
import { showControls } from 'state/player/actions';
import { getControlsAreVisible } from 'state/player/selectors';

/**
 * Web component for the dismisser: we differentiate between Native and Web
 * because the TouchableOpacity (used in Native) blocks the
 * Input components to receive spaces.
 */
export const ControlsDismisser = ({
  role,
  style,
  children,
}: {
  role: RoleProps;
  style: CSSProperties;
  children: ReactNode;
  isForm: boolean;
}): ReactElement => {
  const dispatch = useDispatch();
  const blurQuestion = useBlurQuestion();
  const controlsAreVisible = useSelector(getControlsAreVisible);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { testID, ...restRole } = role;

  return (
    <div
      {...restRole}
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      onClick={() => {
        if (controlsAreVisible) {
          dispatch(showControls(false));
        }
        blurQuestion();
      }}
    >
      {children}
    </div>
  );
};
