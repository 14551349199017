import React, { FunctionComponent, SetStateAction, useState } from 'react';

import { SleepDiaryWeekTypes } from '@bighealth/types/src/scene-components/sleep-diary';

import { Presentation } from '../Presentation';

/**
 * Connects to non-networked state
 * @param props
 */

type StateProps = SleepDiaryWeekTypes['PreNetworkCallConfiguration'] &
  SleepDiaryWeekTypes['NetworkResponse'];

const StatefulSleepDiaryWeek: FunctionComponent<StateProps & {
  pageForwardPress: () => void;
  pageBackwardPress: () => void;
  onDiarySubmit: (
    data: SetStateAction<number | undefined>,
    selectedDailyDate: string | undefined
  ) => void;
  setIsLoading: (loading: boolean) => void;
}> = props => {
  const [diaryDateForModal, setDiaryDateForModal] = useState<
    undefined | string
  >(undefined);

  const setDiaryDateForModalPresentation = (diary_date: undefined | string) => {
    setDiaryDateForModal(diary_date);
  };

  const onDiarySubmit = (timeStamp: SetStateAction<number | undefined>) => {
    props.onDiarySubmit(timeStamp, diaryDateForModal);
  };

  return (
    <Presentation
      {...props}
      onDiarySubmit={onDiarySubmit}
      onPressCalendarTile={setDiaryDateForModalPresentation}
      onCloseDiaryModal={setDiaryDateForModalPresentation}
      diaryDateForModal={diaryDateForModal}
    />
  );
};

export { StatefulSleepDiaryWeek };
