import React, { ReactElement } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';
import { Path, Svg } from 'react-native-svg';

import { useScreenOrientation } from 'components/ProvidersContainer/ScreenOrientationProvider';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { isLandscape } from 'lib/isLandscape';

import { usePlatformTopPaddingModifier } from './usePlatformTopPaddingModifer/usePlatformTopPaddingModifier';

export type Props = {
  onPress?: TouchableOpacityProps['onPress'];
  style?: ViewStyle & { color?: string };
} & TouchableOpacityProps;

const CloseButton = (props: Props): ReactElement => {
  const { onPress, style, ...rest } = props;
  const flatStyles = StyleSheet.flatten(style);
  const styles = useGetDynamicContentStyles();
  const screenOrientation = useScreenOrientation();
  const topPadding = isLandscape(screenOrientation)
    ? styles.sleepDiaryFormHeadingVerticalPadding
    : 0;
  const platformTopPaddingModifier = usePlatformTopPaddingModifier();
  const closeButtonStyle: ViewStyle = {
    position: 'absolute',
    right: 24,
    top: topPadding + platformTopPaddingModifier,
    width: 32,
    height: 32,
  };

  return (
    <TouchableOpacity
      {...roles('NeedHelpModal.CloseButton')}
      {...rest}
      onPress={onPress}
      style={{ ...closeButtonStyle, ...flatStyles }}
    >
      <Svg style={{ width: 32, height: 32 }} viewBox="0 0 32 32">
        <Path
          fill={(flatStyles as Props['style'])?.color || '#163258'}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.333 8.547l-1.88-1.88-7.454 7.453-7.453-7.453-1.88 1.88L14.119 16l-7.453 7.454 1.88 1.88 7.453-7.454 7.454 7.454 1.88-1.88L17.879 16l7.454-7.453z"
        />
      </Svg>
    </TouchableOpacity>
  );
};

export { CloseButton };
