import styled from 'styled-components/native';

import { Text } from 'components/Text';

export const TitleText = styled(Text)`
  font-weight: ${props =>
    props.theme.content.controls.player.smallTitleTextWeight};
  color: ${props => props.theme.content.controls.player.color};
`;
TitleText.displayName = 'TitleText';

export const DescriptionText = styled(Text)`
  color: ${props => props.theme.content.controls.player.color};
`;
DescriptionText.displayName = 'DescriptionText';
