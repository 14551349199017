import React, { ReactElement } from 'react';
import Svg, { Path } from 'react-native-svg';

import { roles } from 'cross-platform/utils/roleProps';

import { dayOfWeekColors } from '../WeekTracker/DayTracker.styled';

// Note: This icon works well only with the width and height of 29
// By default, this svg renders a white checkmark with the option for a dynamic circle background color

export const CompletedCircleTestId = 'CompletedCircle';
export const SmallCircleCheckIcon = ({
  circleFillColor,
}: {
  circleFillColor?: string;
}): ReactElement => {
  return (
    <Svg
      {...roles('CompletedCircle')}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.525 28.0449C18.2381 28.0449 21.799 26.5699 24.4245 23.9444C27.05 21.3189 28.525 17.758 28.525 14.0449C28.525 10.3319 27.05 6.77094 24.4245 4.14543C21.799 1.51992 18.2381 0.0449219 14.525 0.0449219C10.812 0.0449219 7.25104 1.51992 4.62553 4.14543C2.00002 6.77094 0.525024 10.3319 0.525024 14.0449C0.525024 17.758 2.00002 21.3189 4.62553 23.9444C7.25104 26.5699 10.812 28.0449 14.525 28.0449ZM21.531 10.4829C21.7801 10.1718 21.8953 9.77454 21.8514 9.37846C21.8076 8.98238 21.6081 8.61996 21.297 8.37092C20.9859 8.12188 20.5886 8.00663 20.1926 8.05051C19.7965 8.0944 19.4341 8.29382 19.185 8.60492L12.097 17.4729L9.25902 15.3449C9.10144 15.2267 8.92212 15.1407 8.7313 15.0919C8.54048 15.043 8.3419 15.0321 8.14689 15.06C7.95189 15.0879 7.76428 15.1538 7.59478 15.2542C7.42529 15.3546 7.27721 15.4873 7.15902 15.6449C7.04083 15.8025 6.95484 15.9818 6.90595 16.1727C6.85707 16.3635 6.84624 16.562 6.8741 16.7571C6.90196 16.9521 6.96795 17.1397 7.06831 17.3092C7.16867 17.4787 7.30144 17.6267 7.45902 17.7449L11.459 20.7449C11.7707 20.9791 12.1613 21.0827 12.5481 21.0338C12.9349 20.9849 13.2874 20.7873 13.531 20.4829L21.531 10.4829Z"
        fill={circleFillColor || dayOfWeekColors.successGreen}
      />
    </Svg>
  );
};
