import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { AxiosRequestConfig } from 'axios';

export const get_resources_screen_data = async (
  requestConfig?: AxiosRequestConfig
): Promise<get_resources_screen_data.Response> => {
  const request: get_resources_screen_data.Request = {
    service_name: 'SleepioResources',
    service_version: '1',
    service_method: 'get_resources_screen_data',
    args: {},
  };

  return await api(request, requestConfig);
};

get_resources_screen_data.queryKey =
  'SleepioResources/get_resources_screen_data';

export declare namespace get_resources_screen_data {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'SleepioResources';
    service_version: '1';
    service_method: 'get_resources_screen_data';
    args: Args;
  }

  export type ResourceLink = {
    text: string;
    destination: string;
  };

  export type AboutUsComponent = {
    title: string;
    subtitle: string;
  };

  export type Result = {
    product_mark?: string;
    us_address_data: string;
    contact_us_data: string;
    uk_address_data?: string;
    disclaimer_data?: string;
    about_us: AboutUsComponent;
    about_us_narrow: AboutUsComponent;
    left_column_items: ResourceLink[];
  };

  export type Response = APIResponse<Result>;
}
