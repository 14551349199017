import React, { ReactElement, ReactNode } from 'react';
import { ScrollView, ViewStyle } from 'react-native';

import { roles } from 'cross-platform/utils/roleProps';

export type VerticalScrollProps = {
  children: ReactNode;
};

export const VerticalScroll = ({
  children,
}: VerticalScrollProps): ReactElement => {
  const contentContainerStyle: ViewStyle = {
    flexGrow: 1,
  };

  return (
    <ScrollView
      {...roles('VerticalScroll')}
      scrollIndicatorInsets={{ right: 100 }}
      contentContainerStyle={contentContainerStyle}
    >
      {children}
    </ScrollView>
  );
};
