import React, { ReactElement } from 'react';

import { NotificationsView as SharedNotificationsView } from 'components/LocalNotifications/NotificationsView';
import { DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID } from 'config/localNotifications/daylightConfig';
import { SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID } from 'config/localNotifications/sleepioConfig';

import { ContextScreenBeforeOsNotifPermsRequest } from './notification/ContextScreenBeforeOsNotifPermsRequest';
import { PracticeReminder } from './PracticeReminder';

export const NotificationsView = (): ReactElement => {
  return (
    <SharedNotificationsView
      renderPreviewView={args => {
        return <ContextScreenBeforeOsNotifPermsRequest {...args} />;
      }}
      renderReminderView={args => {
        return <PracticeReminder {...args} />;
      }}
      notificationGroupConfigId={DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID}
      sessionsNotificationGroupConfigId={
        SLEEPIO_SESSIONS__NOTIFICATION_GROUP_CONFIG_ID
      }
    />
  );
};
