import styled from 'styled-components/native';

import { TextProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

import { Text } from 'components/sceneset-components/Text';

export const Container = styled.View`
  background-color: white;
  border-radius: 4px;
`;
Container.displayName = 'Container';

export const Title = styled(Text)<TextProps>`
  font-weight: normal;
`;
Title.displayName = 'Title';

export const SubTitle = styled(Text)<TextProps>`
  font-weight: normal;
`;
SubTitle.displayName = 'SubTitle';

export const BottomPadding = styled.View`
  background-color: white;
  border-radius: 4px;
  width: 100%;
`;
BottomPadding.displayName = 'BottomPadding';

export const SimpleContainer = styled.View`
  width: 100%;
`;
SimpleContainer.displayName = 'SimpleContainer';

export const ScrollRow = styled.ScrollView.attrs({
  horizontal: true,
  persistentScrollbar: false,
  showsHorizontalScrollIndicator: false,
})`
  width: 100%;
`;
ScrollRow.displayName = 'ScrollRow';
