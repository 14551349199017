import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { IconStyles } from './types';

export const Moon = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 23 22" style={style}>
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          fill={style?.color || 'currentColor'}
          d="M20.06 18.3275C19.9302 18.3333 19.7996 18.3362 19.6683 18.3362C14.7879 18.3362 10.8316 14.3048 10.8316 9.33184C10.8316 6.86326 11.8065 4.62669 13.3854 3.00009C8.68678 3.20895 4.94043 7.15654 4.94043 11.9957C4.94043 16.9687 8.89675 21.0001 13.7771 21.0001C16.2349 21.0001 18.4583 19.9777 20.06 18.3275Z"
        />
      </G>
    </Svg>
  );
};
