import { getTimeZone } from 'react-native-localize';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { is_session_completed } from '@bighealth/api/SessionProgress/v1';

import { HOUR } from 'lib/durations';

import { useQueryProgram } from './useQueryProgram';

export const useQuerySessionCheck = ({
  sessionNumber,
}: {
  sessionNumber: number;
}): UseQueryResult<is_session_completed.Response, APIErrorResponse> => {
  const programId = useQueryProgram()?.data?.result.id;

  const result = useQuery<is_session_completed.Response, APIErrorResponse>({
    queryKey: is_session_completed.queryKey,
    queryFn: () =>
      is_session_completed({
        program_id: programId as number,
        user_timezone: getTimeZone(),
        session_number: sessionNumber,
      }),
    enabled: !!programId,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    // @TODO refined this based on product feedback
    // An arbitrary number to cover the edge case where someone is using the app across multiple sessions (i.e. mobile
    // and web concurrently)
    cacheTime: 5 * HOUR,
    retry: false,
  });
  return result;
};
