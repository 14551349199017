import React, { ReactElement } from 'react';
import { View } from 'react-native';

export const Triangle = ({
  width,
  height,
  color,
  direction,
}: {
  width: number;
  height: number;
  color: string;
  direction?: string;
}): ReactElement => {
  let rotation = '0deg';
  switch (direction) {
    case 'left':
      rotation = '-90deg';
      break;
    case 'right':
      rotation = '+90deg';
      break;
    default:
      break;
  }

  return (
    <View
      style={{
        width: 0,
        height: 0,
        borderLeftWidth: width / 2,
        borderRightWidth: width / 2,
        borderBottomWidth: height,
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: color,
        transform: [{ rotate: rotation }],
      }}
    />
  );
};
